import { RouteInterface } from "@kinherit/framework/core/route-config";
import {
  KintinMasterListEscalatedBreadCrumb,
  KinvaultKintinMasterListBreadCrumb,
} from "../breadcrumbs";
import { Routes as KintinDetailsRoutes } from "./details";

/** Kintins */
export const KinvaultKintinMasterListRoute = "KinvaultKintinMasterList";
export const KintinMasterListEscalatedRoute = "KintinMasterListEscalated";
export const KintinMasterListQcToCheckRoute = "KintinMasterListQcToCheck";
export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinMasterListRoute,
    path: "/kinvaults",
    component: () =>
      import(
        /* webpackChunkName: "KintinMasterList" */ "./KintinMasterList.vue"
      ),
    meta: {
      breadcrumbs: KinvaultKintinMasterListBreadCrumb,
    },
  },
  {
    name: KintinMasterListEscalatedRoute,
    path: "/kinvaults/escalated",
    component: () =>
      import(
        /* webpackChunkName: "KintinMasterList" */ "./KintinMasterList.Escalated.vue"
      ),
    meta: {
      breadcrumbs: KintinMasterListEscalatedBreadCrumb,
    },
  },
  {
    name: KintinMasterListQcToCheckRoute,
    path: "/kinvaults/qc-to-check",
    component: () =>
      import(
        /* webpackChunkName: "KintinMasterList" */ "./KintinMasterList.QcToCheck.vue"
      ),
  },
  ...KintinDetailsRoutes,
];
