<template>
  <CallScriptSection v-if="kintin" title="Family Situation">
    <b>
      So last meeting we established that you need a [new] Will, as you [don’t
      have one] and it needs to cover:
    </b>
    <br />
    <ul class="has-text-weight-bold">
      <li>[Your Children][ and Guardians]</li>
      <li>Who [else] you want to Benefit from your Assets</li>
      <li>Who you trust with your wishes</li>
      <li>Potentially gifts you want to leave</li>
      <li>[check if other items of relevance and mention if yes]</li>
    </ul>
    <b>And on the topic of your family siutation you...</b>
    <br /><br />
    <ConfirmCheck
      :kintin="kintin"
      step="2.5.0a"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      @update="
        (step, value) => {
          updateCheckValue(step, value);
          guardianSectionRenderKey++;
        }
      "
    />
    <ConfirmCheck
      v-if="checkIsYes('2.5.0a')"
      :kintin="kintin"
      step="2.5.0d"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      @update="
        (step, value) => {
          updateCheckValue(step, value);
          guardianSectionRenderKey++;
        }
      "
    />
    <ConfirmCheck
      v-if="checkIsYes('2.5.0a')"
      :kintin="kintin"
      step="2.5.0b"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      @update="updateCheckValue"
    />
    <ConfirmCheck
      v-if="checkIsYes('2.5.0a')"
      :kintin="kintin"
      step="2.5.0c"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      @update="updateCheckValue"
    />
    <ConfirmCheck
      :kintin="kintin"
      step="2.5.2a"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      :yes-label="'Yes/Maybe'"
      @update="updateCheckValue"
    />
    <ConfirmCheck
      :kintin="kintin"
      step="2.5.0f"
      :kintin-checks="kintinChecks"
      is-reversed
      :checks="checks"
      @update="updateCheckValue"
    />
    <ConfirmCheck
      :kintin="kintin"
      step="2.5.2b"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      :yes-label="'Yes/Maybe'"
      @update="updateCheckValue"
    />
    <h5 class="title is-emphasised mb-0 mt-6">Family &amp; Others</h5>
    <PersonList :kintin="kintin" :people="$data.people" @reload="loadPeople" />
    <Button
      class="mt-4 mb-4"
      text="Add Person"
      color="is-success"
      @click="() => addRelativeHandler().then(loadPeople)"
    />
    <br />
    <b>
      Thank you so much for that. Do you have any questions, or anything you’d
      like to mention about your situation before we move on?
    </b>
    <br /><br />
    <ConfirmCheck
      :kintin="kintin"
      step="2.5.1"
      :kintin-checks="kintinChecks"
      :checks="checks"
      @update="updateCheckValue"
    />
  </CallScriptSection>
</template>

<script lang="ts">
import { CreatePerson } from "@/config/model.config";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { UpdatePersonForm } from "@/module/kinvault.kintin/form/update-person.form";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import Button from "@kinherit/framework/component.input/button";
import { NotIn } from "@kinherit/orm/index";
import { Person } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FamilyTab",
  components: {
    CallScriptSection,
    ConfirmCheck,
    PersonList,
    Button,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  data: () => ({
    guardianSectionRenderKey: 0,
    people: [] as Person[],
    renderKey: 0,
  }),
  mounted() {
    this.loadPeople();
  },
  methods: {
    async addRelativeHandler() {
      if (!this.kintin) {
        return;
      }

      const person = CreatePerson();

      try {
        await UpdatePersonForm({
          person,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create person",
          },
        });
      } catch {
        person.$delete({
          profile: true,
          relationToPrimaryPerson: true,
          relationToSecondaryPerson: true,
        });
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.person.create({
      person.kintin = this.kintin;
      await this.$actionBus.person.CreatePerson(person);

      this.renderKey++;
    },
    loadPeople() {
      if (!this.kintin) {
        return;
      }

      this.people = Person.$findBy({
        id: NotIn(
          [
            this.kintin.$data.primaryPerson,
            this.kintin.$data.secondaryPerson,
          ].filter(Boolean),
        ),
        kintin: {
          id: this.kintin.id,
        },
      });
    },
  },
});
</script>
