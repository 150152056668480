<template>
  <CreditNotesPage
    v-if="query"
    :has-write-access="hasWriteAccess"
    :query="query"
    @create-credit-note="$emit('create-credit-note')"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "UserDetailsCreditNotesWrapper",
  "route": "UserDetailsCreditNotes"
}
</cypress-wrapper>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import CreditNotesPage from "@/shared/component/credit-note/CreditNotesPage.vue";
import { Equal } from "@kinherit/orm";
import { CreditNote, QueryMask } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { AdminUserDetailsCreditNotesRoute } from ".";
import { UserDetailsMixin } from "../../mixin/user-details.mixin";

export default defineComponent({
  name: AdminUserDetailsCreditNotesRoute,
  components: {
    CreditNotesPage,
  },
  mixins: [UserDetailsMixin, AuthService.mixin()],
  emits: ["create-credit-note"],
  data: () => ({
    query: null as null | any,
  }),
  mounted(): void {
    this.query = {
      relatedUser: {
        id: Equal(this.$params.user),
      },
    } satisfies QueryMask<typeof CreditNote>;
  },
});
</script>
