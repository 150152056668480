var u = Object.defineProperty;
var c = (e, n) => u(e, "name", { value: n, configurable: !0 });
import { _ as d } from "../../../component.layout/breadcrumbs/breadcrumbs.vue_vue_type_script_lang.js";
import { resolveComponent as b, createElementBlock as a, createCommentVNode as m, openBlock as s, createElementVNode as o, Fragment as p, renderList as _, normalizeClass as h, createBlock as k, toDisplayString as f } from "vue";
import { _ as I } from "../../../_plugins/_plugin-vue_export-helper.js";
const g = {
  key: 0,
  class: "breadcrumb",
  "aria-label": "breadcrumbs"
}, y = { class: "breadcrumb-list" }, C = ["onClick"], $ = { class: "breadcrumbs-label" }, v = { class: "breadcrumbs-item-text" };
function B(e, n, E, N, V, w) {
  const l = b("Icon");
  return e.computedItems.isEmpty() ? m("", !0) : (s(), a("nav", g, [
    o("ul", y, [
      (s(!0), a(p, null, _(e.computedItems, (t, r) => (s(), a("li", {
        key: `breadcrumb-item-${r}`,
        class: h({
          "breadcrumbs-item": !0,
          "has-text-weight-bold": r === e.computedItems.length - 1,
          "is-active": r === e.computedItems.length - 1
        })
      }, [
        o("a", {
          class: "breadcrumbs-link",
          onClick: /* @__PURE__ */ c((i) => e.open(i, t.route), "onClick")
        }, [
          o("span", $, [
            t.icon !== void 0 ? (s(), k(l, {
              key: 0,
              class: "breadcrumbs-item-icon",
              icon: t.icon
            }, null, 8, ["icon"])) : m("", !0),
            o("span", v, f(t.text), 1)
          ])
        ], 8, C)
      ], 2))), 128))
    ])
  ]));
}
c(B, "_sfc_render");
const S = /* @__PURE__ */ I(d, [["render", B]]);
export {
  S as default
};
