// ContactPartnerPortalPermissions

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { ContactPartnerPortalPermissions } from "@kinherit/sdk";

export const UpdateIntroducerContactPartnerPortal = DefineWriteAction({
  interface: "portal",
  resource:
    "/v2/portal/introducer/contact/partner-portal-permissions/{introducerContact}/partner-portal-permissions/{contactPartnerPortalPermissions}",
  method: "patch",
  parse: (target: ContactPartnerPortalPermissions) => ({
    body: {
      clientsRead: target.$data.clientsRead,
      feesRead: target.$data.feesRead,
      company: target.$data.company,
      contact: target.$data.contact,
      clientsCreate: target.$data.clientsCreate,
      invitationsCreate: target.$data.invitationsCreate,
      allowKinvaultAccessRequest: target.$data.allowKinvaultAccessRequest,
    },
    params: {
      introducerContact: target.$data.contact,
      contactPartnerPortalPermissions: target.$data.id,
    },
  }),
});
