import e from "../../_vue/component.input/date-range-field/date-range-field.js";
import { FormComponentWrapper as o } from "../../form-builder/core/component-wrapper.js";
import { DateItems as n } from "./types.js";
const m = o(e);
export {
  n as DateItems,
  e as DateRangeField,
  m as FormDateRangeField,
  e as default
};
