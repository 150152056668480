<template>
  <div class="introducer-company-details-accounting">
    <PageHeader htag="h2" text="Xero Integration" class="mt-4" />
    <Card>
      <Form
        v-if="!xeroId"
        :config="$data.form"
        is-borderless
        @submit="submit"
      />
      <div v-if="xeroId">
        <h4 v-if="introducerCompany?.xeroContact">
          {{ introducerCompany.xeroContact?.contactName }}
        </h4>
        <h5>{{ xeroId }}</h5>
        <Button
          icon-left="Trash"
          size="is-small"
          color="is-plain"
          class="delete-item"
          aria-label="Remove Linked Xero Account"
          text="Remove Linked Xero Account"
          @click="removeXeroId"
        />
      </div>
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsAccountingWrapper",
  "route": "IntroducerCompanyDetailsAccounting",
  "selector": ".introducer-company-details-accounting",
  "imports": {
    "IntroducerCompanyAccountFormWrapper": "@/module/introducer.company/form/introducer-company-accounting.form.test"
  },
  "methods": {
    "form": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyAccountFormWrapper"
    },
    "removeXeroId": {
      "type": "click",
      "selector": ".delete-item"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { IntroducerCompanyAccountForm } from "@/module/introducer.company/form/introducer-company-accounting.form";

import { Form } from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { Currency } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { IntroducerCompanyDetailsAccountingRoute } from ".";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";

export default defineComponent({
  name: IntroducerCompanyDetailsAccountingRoute,
  components: {
    Card,
    Button,
    Form,
    PageHeader,
  },
  mixins: [IntroducerCompanyDetailsMixin],
  data: () => ({
    Currency,
    form: IntroducerCompanyAccountForm(),
    xeroId: null as string | null,
  }),
  mounted() {
    this.setXeroId();
  },
  methods: {
    setXeroId(): void {
      if (!this.introducerCompany) {
        return;
      }

      this.xeroId = this.introducerCompany.xeroContact?.xeroContactId ?? null;
    },
    formatDate(dateString: string): string {
      const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", options);
    },
    async removeXeroId(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }
      await DangerDialog({
        dialog: {
          title: `Reset Xero ID`,
          message: `Are you sure you want to remove the link to Xero?`,
        },
      });

      this.introducerCompany.xeroContact = null;
      //await window.Kernel.ActionBus.introducerCompany.company.update({
      await this.$actionBus.company.UpdateIntroducerCompany(
        this.introducerCompany,
      );

      this.xeroId = null;
      this.form.localData.contact = null;
    },
    async submit(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      const selectedXeroContact = this.$data.form.localData.contact;

      if (!selectedXeroContact) {
        return;
      }

      this.introducerCompany.xeroContact = selectedXeroContact;

      // await window.Kernel.ActionBus.introducerCompany.company.update({
      //   introducerCompany: this.introducerCompany,
      // });
      await this.$actionBus.company.UpdateIntroducerCompany(
        this.introducerCompany,
      );

      this.setXeroId();
    },
  },
});
</script>
