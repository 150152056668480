var c = Object.defineProperty;
var o = (e, l) => c(e, "name", { value: l, configurable: !0 });
import { _ as i } from "../../../component.display/calendar/calendar-event-modal.vue_vue_type_script_lang.js";
import { createElementBlock as n, openBlock as r, Fragment as _, renderList as m, mergeProps as p, createElementVNode as t, toDisplayString as a, createTextVNode as d } from "vue";
import { _ as f } from "../../../_plugins/_plugin-vue_export-helper.js";
const h = ["onClick"], v = { class: "event-label label" }, u = { class: "event-date" }, $ = { class: "event-start" }, k = { class: "has-text-weight-bold" }, b = { class: "event-end" }, g = { class: "has-text-weight-bold" };
function D(e, l, E, T, w, y) {
  return r(), n("div", null, [
    (r(!0), n(_, null, m(e.events, (s) => (r(), n("div", p({
      key: `calendar-event-${s.id}`,
      class: "calendar__event",
      ref_for: !0
    }, e.$attrs, {
      onClick: /* @__PURE__ */ o((B) => e.clickHandler(s), "onClick")
    }), [
      t("div", v, a(s.label), 1),
      t("div", u, [
        t("div", $, [
          t("span", k, a(e.$t.local.starts) + ": ", 1),
          d(a(s.start.formatDateTime), 1)
        ]),
        t("div", b, [
          t("span", g, a(e.$t.local.ends) + ": ", 1),
          d(a(s.end.formatDateTime), 1)
        ])
      ])
    ], 16, h))), 128))
  ]);
}
o(D, "_sfc_render");
const F = /* @__PURE__ */ f(i, [["render", D]]);
export {
  F as default
};
