// Product, IntroducerCompany, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Product } from "@kinherit/sdk";

export const RecordProduct = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/product/{product}",
  method: "get",
  rootModel: Product,
  parse: (product: Product | string) => ({
    params: {
      product,
    },
  }),
});
