import { Api, Person, WillRevision } from "@kinherit/sdk";

//
export const GeneratePdf = async (message: {
  willContent: string[];
  person: Person;
  draft: boolean;
  hasLetterOfWishes: boolean;
  willRevision: WillRevision | null;
  reference: string;
  mowBusinessContent: string;
  mowIipContent: string;
  mowNrbContent: string;
  mowResContent: string;
}) => {
  const request = Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/will-revision/download",
  )
    .method("put")
    .body({
      watermark: message.draft,
      willContent: message.willContent,
      testator: message.person.profile.fullName ?? "",
      reference: message.reference,
      hasLetterOfWishes: message.hasLetterOfWishes,
      mowBusinessContent: message.mowBusinessContent,
      mowIipContent: message.mowIipContent,
      mowNrbContent: message.mowNrbContent,
      mowResContent: message.mowResContent,
    });

  if (message.willRevision) {
    await request.download({
      encoding: "application/pdf",
      fileName: `${message.person.profile.firstName} ${message.person.profile.lastName} - Will - ${message.willRevision.createdAt.formatDateTime}.pdf`,
    });
    return;
  }

  await request.download({
    encoding: "application/pdf",
    fileName: `${message.person.profile.firstName} ${message.person.profile.lastName} - Will.pdf`,
  });
};
