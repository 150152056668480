<template>
  <div class="dashboard-statistic-widget-group">
    <div class="dashboard-statistic-widget-group__title has-font-weight-bold">
      {{ title }}
      <Icon
        v-if="hint"
        v-tool-tip="hint"
        icon="Info"
        color="is-light"
        secondary-color="is-light-grey"
      />
    </div>
    <div class="dashboard-statistic-widget-group__content">
      <slot name="before" />
      <DashboardStatisticWidget
        v-for="(detail, i) in details"
        :key="'dashboard-statistic-widget-' + i"
        :label="detail.label"
        :value="detail.value"
        :sub-value="detail.subValue"
        :css-class="detail.cssClass"
      />
      <slot name="after" />
    </div>
  </div>
</template>

<script lang="ts">
import { Icon } from "@kinherit/framework/component.display/icon";
import { defineComponent, PropType } from "vue";
import { TDashboardStatisticWidgetGroupProps } from "./dashboard-statistic-widget";
import DashboardStatisticWidget from "./DashboardStatisticWidget.vue";

export default defineComponent({
  name: "DashboardStatisticWidgetGroup",
  components: {
    DashboardStatisticWidget,
    Icon,
  },
  props: {
    title: {
      type: String as PropType<TDashboardStatisticWidgetGroupProps["title"]>,
      required: true,
    },
    hint: {
      type: String as PropType<TDashboardStatisticWidgetGroupProps["hint"]>,
      required: false,
      default: undefined,
    },
    details: {
      type: Array as PropType<TDashboardStatisticWidgetGroupProps["details"]>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard-statistic-widget-group {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  padding: 0.2em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  &__title {
    font-size: 0.9em;
    padding-bottom: 0.2em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid #ccc;
    width: 100%;
  }
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    & > * {
      margin: 0 0.5em;
    }
  }
}
</style>
