// Order, Kintin, AccountReferral, AccountReferralCode, Profile, User

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Between, Equal, In, IsNotNull, Like } from "@kinherit/orm/index";
import { Kintin, QueryMask } from "@kinherit/sdk";
import { Order } from "@kinherit/sdk/model/order";

export interface ReadOrderMessage {
  search?: string;
  type?: "kintin" | "trustReg";
  query?: QueryMask<typeof Order>;
  paymentType: Array<string>;
  referral: Array<string>;
  status: Array<string>;
  assignedUser: Array<string>;
  created: null | [number, number];
  paidWithin: null | [number, number];
  kintin?: string | Kintin;
}

const getKintinParams = (
  message: ReadOrderMessage,
): Array<QueryMask<typeof Order>> => {
  const params1: QueryMask<typeof Order> = {
    kintin: {
      ref: Like(message.search),
      id: IsNotNull(),
      referral: {
        referralCode: {
          id: In(message.referral),
        },
      },
    },
    status: {
      id: In(message.status),
    },
    createdAt: Between(message.created),
    paidAt: Between(message.paidWithin),
    paymentType: { id: In(message.paymentType) },
    createdBy: { id: In(message.assignedUser) },
  };

  const params2: QueryMask<typeof Order> = {
    kintin: {
      friendlyName: Like(message.search),
      id: IsNotNull(),
      referral: { id: In(message.referral) },
    },
    status: {
      id: In(message.status),
    },
    createdAt: Between(message.created),
    paidAt: Between(message.paidWithin),
    paymentType: { id: In(message.paymentType) },
    createdBy: { id: In(message.assignedUser) },
  };

  if (message.search) {
    return [params1, params2];
  }

  return [params1];
};

const getTrustRegParams = (
  message: ReadOrderMessage,
): Array<QueryMask<typeof Order>> => {
  const params1: QueryMask<typeof Order> = {
    isTrustReg: Equal(true),
    status: {
      id: In(message.status),
    },
    createdAt: Between(message.created),
    paidAt: Between(message.paidWithin),
    paymentType: { id: In(message.paymentType) },
  };

  return [params1];
};

export const ReadOrder = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/orders",
  method: "get",
  rootModel: Order,
  parse: (message: {
    search?: string;
    type?: "kintin" | "trustReg";
    paymentType: Array<string>;
    referral: Array<string>;
    status: Array<string>;
    assignedUser: Array<string>;
    created: null | [number, number];
    paidWithin: null | [number, number];
    kintin?: string | Kintin;
  }) => {
    if (undefined === message.type) {
      message.type = "kintin";
    }

    if (message.kintin) {
      return {
        query: {
          kintin: {
            id: Equal(
              "string" === typeof message.kintin
                ? message.kintin
                : message.kintin.id,
            ),
          },
        },
      };
    }

    if (message.type === "kintin") {
      return { query: getKintinParams(message) };
    } else {
      return { query: getTrustRegParams(message) };
    }
  },
});
