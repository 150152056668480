// SignDoc, Person, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { SignDoc } from "@kinherit/sdk/model/sign-doc";

export const ReadSignDoc = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/sign-doc",
  method: "get",
  rootModel: SignDoc,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});
