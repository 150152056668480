import { Api, Lead } from "@kinherit/sdk";

export const ToggleSecondaryProfile = async ({
  lead,
  state,
}: {
  lead: Lead;
  state: "enable" | "disable";
}) => {
  await Api.resource(
    "portal",
    "/v2/portal/lead/{lead}/secondary-profile/{state}",
    {
      lead: lead.id,
      state: state,
    },
  )
    .method("put")
    .result();
};
