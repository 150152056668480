<template>
  <div class="call-log-master-list">
    <PageHeader htag="h1" text="Call Logs" />
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        duration: 'Duration',
        callDate: 'Recieved',
      }"
      @refresh="refresh"
    >
      <template #actions="{ row }">
        <Button text="Details" @click="handleClick(row, $event)" />
        <Button
          v-if="row.kintin"
          text="Profile"
          icon-left="Person"
          @click="viewKintin(row.kintin, $event)"
        />
        <Button
          v-else-if="row.person"
          text="Profile"
          icon-left="Person"
          @click="viewPerson(row.person, $event)"
        />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "CallLogMasterListWrapper",
  "route": "CallLogMasterList",
  "selector": ".call-log-master-list",
  "imports": {
    "ReadCallLogFormWrapper": "@/module/admin.call-log/form/read-call-logs.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadCallLogFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadCallLogForm } from "@/module/admin.call-log/form/read-call-logs.form";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import {
  KinvaultOfficerDetailsParams,
  KinvaultOfficerDetailsRoute,
} from "@/module/kinvault.officer/page/details";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  Between,
  In,
  LessThanOrEqual,
  Like,
  MoreThanOrEqual,
} from "@kinherit/orm/index";
import { CallLog, ICallLog, Kintin, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  CallLogDetailsParams,
  CallLogDetailsRoute,
  CallLogMasterListRoute,
} from ".";

export default defineComponent({
  name: CallLogMasterListRoute,
  components: { MasterListPage, Button, PageHeader },
  mixins: [
    ActionBusMixin(() => window.Kernel.ActionBus2.portal.admin.callLogs),
  ],
  data: () => ({
    filters: ReadCallLogForm(),
    columns: [
      {
        title: "Candidate",
        sort: true,
        map: (v: CallLog) =>
          v.kintin?.friendlyName ?? v.person?.profile.fullName ?? "Unknown",
      },
      {
        title: "Status",
        sort: true,
        map: (v: CallLog) => (v.answered ? "Answered" : "Missed"),
      },
      {
        title: "Username",
        sort: true,
        field: "username",
      },
      {
        title: "Direction",
        sort: true,
        map: (v: CallLog) => v.direction.text,
      },
      {
        title: "Destination",
        sort: true,
        map: (v: CallLog) => v.destination,
      },
      {
        title: "Duration",
        sort: true,
        map: (v: CallLog) => v.duration.toString({ ms: false }),
      },
      {
        title: "Tel",
        sort: true,
        map: (v: CallLog) => v.tel,
      },
      {
        title: "Recieved",
        sort: true,
        map: (v: CallLog) => v.callDate.formatDateTime,
      },
      {
        title: "Actions",
        sort: false,
        slot: "actions",
      },
    ] as Array<TableColumn>,
    rows: Array<CallLog>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof ICallLog,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    handleClick(calllog: CallLog, event: MouseEvent): void {
      const params: CallLogDetailsParams = { id: calllog.id };

      window.Kernel.visitRoute(
        {
          name: CallLogDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadCallLogForm>["localData"],
    ): Promise<void> {
      // await window.Kernel.ActionBus.adminCallLog.read({
      const data = await this.$actionBus.ReadCallLog({
        query: {
          username: Like(formData.username),
          duration:
            formData.minDuration && formData.maxDuration
              ? Between([formData.minDuration, formData.maxDuration])
              : formData.minDuration
                ? LessThanOrEqual(formData.minDuration)
                : formData.maxDuration
                  ? MoreThanOrEqual(formData.maxDuration)
                  : undefined,
          direction: {
            id: In(formData.direction),
          },
          createdAt: Between(formData.created),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.callLog;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    viewKintin(kintin: Kintin, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KinvaultKintinDetailsRoute,
          params: {
            kintin: kintin.id,
          } satisfies KinvaultKintinDetailsParams,
        },
        event.ctrlKey,
      );
    },
    viewPerson(person: Person, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KinvaultOfficerDetailsRoute,
          params: {
            officer: person.id,
          } satisfies KinvaultOfficerDetailsParams,
        },
        event.ctrlKey,
      );
    },
  },
});
</script>
