import { Api, Kintin, Person, User } from "@kinherit/sdk";

//
export const FillLpaFinancial = async (message: {
  kintin: Kintin;
  person: Person;
  user: User;
}) => {
  const { person, user, kintin } = message;

  const primaryPerson = kintin.primaryPerson;
  const secondaryPerson = kintin.secondaryPerson;

  const surname = [
    primaryPerson.profile.lastName,
    secondaryPerson?.profile.lastName,
  ]
    .filter(Boolean)
    .unique()
    .join(" & ");

  await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/process/person/{person}/specialist/{user}/lpa-financial",
    {
      kintin: kintin,
      person: person,
      user: user,
    },
  )
    .method("get")
    .params({
      kintin: kintin.id,
    })
    .download({
      encoding: "application/pdf",
      fileName: ` ${kintin.ref} LPA Financial - ${surname}`,
    });
};
