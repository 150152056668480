import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { OrderRefundRequest } from "@kinherit/sdk";

export const CreateOrderRefundRequest = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/order-refund-request",
  method: "post",
  parse: (orderRefundRequest: OrderRefundRequest) => ({
    body: {
      order: orderRefundRequest.$data.order,
      status: orderRefundRequest.$data.status,
      amount: orderRefundRequest.$data.amount,
      refundReason: orderRefundRequest.$data.refundReason,
      completedAt: orderRefundRequest.$data.completedAt,
    },
  }),
  after: ({ message }) => {
    OrderRefundRequest.$delete(message);
  },
});
