import { Portal2OrdersRefundRequest } from "@/action-bus/portal/orders/refund-request";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadOrder } from "./read-order.action";

export const Portal2Orders = DefineActionBus({
  name: "portal-orders",
  actions: {
    ReadOrder,
    refundRequest: Portal2OrdersRefundRequest,
  },
});
