<template>
  <div v-if="kintin">
    <CallScriptSection title="Reminder">
      <p>
        This call is to set up the detailed planning (with the benefit of the
        “homework” that the clients have been set) and talk through product
        options – and sell.
      </p>
      <p>
        It is expected to be 40 minutes total – split 30 minutes of data-entry
        (ie the ‘old’ call 1), followed by 10 minutes of product-discussion and
        sale (ie the ‘old’ call 2).
      </p>
      <InfoBlock title="Remember">
        <ul class="mt-0">
          <li>
            99% of our clients would get benefits from a Will, LPAs and an EPT,
            … the list of “unsuitables” is very short (eg, no prospects of
            assets / everything to charity / already doolally)
          </li>
          <li>
            Our job is to get them protected – there is never a situation where
            they should not get on with their planning (and rewrite later if
            needed). It is never good advice to wait - we simply don’t know the
            future / when planning will be needed
          </li>
        </ul>
      </InfoBlock>
      <InfoBlock title="How we deliver value to the client">
        <ol class="mt-0">
          <li>On the LA call, we explain the “IFA-like service” we give.</li>
          <li>
            On the “situation” new-call-1, we listen and take notes, confirm we
            can help them, and explain our process.
          </li>
          <li>
            On the “information & sale” new-call-2, we demonstrate the ‘advised
            Will’ value by the thoroughness of the process, and we explain
            clearly why each of our products is of value to them.
          </li>
          <li>
            We further demonstrate our expertise in the handling of client
            questions - by following the “best practice question answering
            toolkit” (to be developed).
          </li>
        </ol>
      </InfoBlock>
      <InfoBlock title="Focus on how you look, listen and talk">
        <ul class="mt-0">
          <li>
            Active listening with eye contact! Everyone’s situation is unique /
            no one is ‘simple.
          </li>
          <li>Tone: professional, warm and attentive.</li>
          <li>
            We offer an “advised” service. We are not order takers! We explain
            how we will handle their situation … and why it’s best for them.
          </li>
        </ul>
      </InfoBlock>
      <InfoBlock title="This is a sales call">
        <ul class="mt-0">
          <li>All our products have real value for families.</li>
          <li>We have a unique until-death service that everyone needs</li>
          <li>
            All our pricing is very competitive relative to financial services /
            legal services / etc
          </li>
        </ul>
      </InfoBlock>
    </CallScriptSection>

    <CallScriptSection title="2mins icebreaker">
      <b>
        Hello {{ primaryProfile?.firstName }}
        {{ isJoint ? ` / ${secondaryProfile?.firstName}` : "" }}?</b
      >
      <br /><br />
      <b>Good to see you again. I trust you are well.</b>
      <br />
      <InfoBlock title="Indirect Rapport Build">
        <b
          >BEFORE getting into the discussion, spend 1 minute with some
          ice-break questions.
        </b>
        <br />
        <ul class="mt-0">
          <li>
            “I can see you’re both present which is great ” … “and keen to talk
            about death?”
          </li>
          <li>
            “I’m curious… / I see … [say something interesting or notable]”
          </li>
        </ul>
      </InfoBlock>
      <br />
      <b>Thank you again for making the time.</b>
    </CallScriptSection>

    <CallScriptSection title="Intro">
      <b
        >So – last time we covered a lot of ground, in a short amount of
        time.</b
      >
      <br /><br />
      <b
        >Today we’ll go through again, but in much more detail – and really get
        into some of the big questions, like who you want to get your
        inheritance, and how we can use the Kinvault to make things go smoothly
        for your family.
      </b>
      <br /><br />
      <b
        >And this meeting is recorded and will be part of your legal record. So
        if there is ever a question about what you intended we’ll have a
        recording of you talking in your own words – it doesn’t get better than
        that.
      </b>
    </CallScriptSection>

    <CallScriptSecuritySection
      start="But first, I need to go through security."
      :kintin="kintin"
      step="6.0.2"
      :checks="checks"
      :kintin-checks="kintinChecks"
    />
    <CallScriptSection v-if="isJoint" title="Person 2 Check">
      <RadioField
        :label="`And I can see you both there / is
        ${secondaryProfile?.firstName} there`"
        class="mt-0"
        :options="['No', 'Yes']"
        direction="is-horizontal"
        :value="null === person2Present ? null : person2Present ? 1 : 0"
        is-button
        is-reversed
        @update:value="savePerson2Handler"
      />
      <b>And is anyone else potentially able to overhear this conversation?</b>
      <br />
      <ConfirmCheck
        label="Confirm that no-one else can overhear"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.0.3"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>

    <CallScriptSection title="Intro / Testamentary Capacity">
      <b
        >And I have a couple of legal checks, which are looking for yes or no
        answers.</b
      >

      <div class="columns mt-4">
        <div class="column">
          <h4>{{ primaryProfile?.fullName }}</h4>
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0d"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0"
            :kintin="kintin"
            is-reversed
            :on="{
              yes: {
                flag: ['Take details put it in a follow-up note.'],
              },
            }"
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0a"
            :kintin="kintin"
            is-reversed
            :on="{
              no: {
                flag: ['Take details put it in a follow-up note.'],
              },
            }"
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0b"
            :kintin="kintin"
            is-reversed
            :on="{
              no: {
                flag: ['Take details put it in a follow-up note.'],
              },
            }"
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0c"
            :kintin="kintin"
            is-reversed
            :on="{
              yes: {
                flag: ['Take details put it in a follow-up note.'],
              },
            }"
            @update="updateCheckValue"
          />
        </div>

        <div v-if="person2Present" class="column">
          <h4>{{ secondaryProfile?.fullName }}</h4>
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0dd"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.00"
            :kintin="kintin"
            is-reversed
            :on="{
              yes: {
                flag: ['Take details put it in a follow-up note.'],
              },
            }"
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0aa"
            :kintin="kintin"
            is-reversed
            :on="{
              no: {
                flag: ['Take details put it in a follow-up note.'],
              },
            }"
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0bb"
            :kintin="kintin"
            is-reversed
            :on="{
              no: {
                flag: ['Take details put it in a follow-up note.'],
              },
            }"
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0cc"
            :kintin="kintin"
            is-reversed
            :on="{
              yes: {
                flag: ['Take details put it in a follow-up note.'],
              },
            }"
            @update="updateCheckValue"
          />
        </div>
      </div>
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="2.2.1"
        :kintin="kintin"
        @update="updateCheckValue"
      />
      <br /><br />
      <b
        >And do <span v-if="isJoint">either of</span> you have an existing
        Will?</b
      >
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="5.2.0"
        :kintin="kintin"
        hide-field-label
        :yes-label="'Has an existing Will, but it needs updating'"
        :no-label="'No existing Will'"
        :on="{
          yes: {
            say: [
              'When your new Will is signed, any old Wills should be destroyed, to make sure they don’t interfere.',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <br />
      <b
        >Great. As you can probably guess, this is for the legal record … I’m
        not trying to catch you out here.</b
      >
    </CallScriptSection>

    <CallScriptSection title="Client Setup">
      <b
        >And just to confirm:
        <span v-if="!isJoint">
          this planning is just for you, so not in conjunction with a
          partner?</span
        >
      </b>
      <br /><br />
      <!--
      <b v-if="isJoint"> And it’s a couple’s Will we’re looking for </b>
      <b v-else> And it’s a single Will you’re looking for </b>
      <br />

      <b> You’re married/civil partners to each other </b> -->

      <!-- Marriage-->
      <ConfirmCheck
        v-if="isJoint"
        :kintin="kintin"
        step="2.4.1b"
        :kintin-checks="kintinChecks"
        :checks="checks"
        is-reversed
        @update="updateCheckValue"
      />
      <ConfirmCheck
        v-if="isJoint"
        :kintin="kintin"
        step="2.4.1c"
        :kintin-checks="kintinChecks"
        :checks="checks"
        is-reversed
        @update="updateCheckValue"
      />
      <mark>TO add any other relationship checkboxes</mark>

      <br /><br />
      <b
        ><br />[Person 1] first - are you Miss/Ms/Mrs, or Dr or something else?
        <br />And can I get your date of birth <br />And can I get your address?
        <br />And same questions for [person 2]
      </b>
      <PersonList
        :key="primaryPersonRenderKey"
        :kintin="kintin"
        :people="[primaryPerson]"
        @reload="primaryPersonRenderKey++"
      />
      <span v-if="isJoint">
        <PersonList
          v-if="secondaryPerson"
          :key="secondaryPersonRenderKey"
          :kintin="kintin"
          :people="[secondaryPerson]"
          @reload="secondaryPersonRenderKey++"
        />
      </span>
      <b>
        Any documents we send will be to your address, so please let us know if
        you're intending to move.
      </b>
      <br /><br />
      <ConfirmCheck
        :kintin="kintin"
        step="2.4.1"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin="kintin"
        step="2.4.2"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin="kintin"
        step="2.4.3"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin="kintin"
        step="2.4.4"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
    </CallScriptSection>

    <CallScriptSection title="Call 1 Summary">
      <b>On our last call we covered a lot of things, including </b>
      <br /><br />

      <InfoBlock title="Pick out 3-4 distinctive features from the list">
        <ul class="mt-0">
          <li>Children, referencing names / ages</li>
          <li>Address, other owned property (with values?)</li>
          <li>Other info on assets or jurisdiction or res&dom</li>
          <li>That there is an IFA / accountant etc</li>
          <li>Other Family details</li>
        </ul>
      </InfoBlock>
      <br />
      <b>Does that all sound correct?</b>
      <br /><br />
      <b
        >I set you homework also - to think about what you want to happen to
        your money, and who you trust to understand your wishes. Did you get a
        chance to think about this?
      </b>
      <br />
      <em>[Don’t take detailed names / DOB’s / relationships at this stage]</em>
      <br /><br />
      <b
        >Excellent, that’ll make it easier / No worries, we can clarify this as
        we go through……</b
      >
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import InfoBlock from "@/module/kinvault.kintin/component/call-script/shared/InfoBlock.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import RadioField from "@kinherit/framework/component.input/radio-field";
import CallScriptSecuritySection from "@/module/kinvault.kintin/component/call-script/shared/SecuritySection.vue";

import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { defineComponent } from "vue";
import { KintinDetailsCallScript2Call2IntroRoute } from ".";

export default defineComponent({
  name: KintinDetailsCallScript2Call2IntroRoute,
  components: {
    CallScriptSection,
    ConfirmCheck,
    RadioField,
    InfoBlock,
    CallScriptSecuritySection,
    PersonList,
  },
  mixins: [AuthService.mixin(), KinvaultKintinDetailsCallScriptMixin],
  emits: ["save-callscript"],
  data: () => ({
    person2Present: null as boolean | null,
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
    tab: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
  methods: {
    async savePerson2Handler(value: number): Promise<void> {
      this.callScript.person2Present = value === 1;

      this.person2Present = value === 1;

      this.$emit("save-callscript");
    },
  },
});
</script>
