var N = Object.defineProperty;
var I = (e, r) => N(e, "name", { value: r, configurable: !0 });
import * as n from "@kinherit/sdk";
const P = /* @__PURE__ */ I((e) => async (r) => {
  var c, p, y, $;
  const m = "message" in r ? r.message : {}, s = e.parse(m, {}), q = "params" in s ? s.params : {}, u = n.Api.resource(
    e.interface,
    e.resource,
    q
  ).method(e.method).body("body" in s ? s.body : {}), f = "rawQuery" in s ? s.rawQuery : {};
  u.params(f), u.paginate(r.pagination ?? !1), r.sort && u.sort(r.sort);
  const i = u.buildQuery(e.rootModel);
  "query" in s && i.where(s.query), "query" in r && i.where(r.query);
  const t = await u.result(), o = {};
  o.$pagination = {
    currentPage: Number.parseInt(((c = t.$pagination) == null ? void 0 : c.currentPage) ?? "1"),
    lastPage: Number.parseInt(((p = t.$pagination) == null ? void 0 : p.lastPage) ?? "0"),
    count: Number.parseInt(((y = t.$pagination) == null ? void 0 : y.count) ?? "0")
  }, t.$rootIds && (o.$rootIds = t.$rootIds);
  const d = Object.values(n).pluck("$name").filter(Boolean).cast(), l = {};
  for (const a of d)
    l[a] = n[a];
  for (const a in t) {
    let b, w;
    a.ucFirst() === e.rootModel.$name && (b = t.$rootIds, w = r.sort), d.includes(a.ucFirst()) ? o[a] = l[a.ucFirst()].$inflate(
      t[a],
      w,
      b
    ) : o[a] = t[a];
  }
  return await (($ = e.after) == null ? void 0 : $.call(e, {
    message: m,
    response: o
  })), e.map ? e.map(t) : o;
}, "DefineCustomAction");
export {
  P as DefineCustomAction
};
