var u = Object.defineProperty;
var i = (s, e) => u(s, "name", { value: e, configurable: !0 });
const n = class n {
  static sleep(e) {
    return new Promise((t) => {
      setTimeout(t, e);
    });
  }
  static get platform() {
    return {
      ios: function() {
        const e = /* @__PURE__ */ i(function() {
          const o = new Audio();
          return o.volume = 0.5, o.volume === 1;
        }, "iosQuirkPresent"), t = /iPad|iPhone|iPod/.test(navigator.userAgent), a = navigator.userAgent.includes("Macintosh"), c = navigator.maxTouchPoints >= 1;
        return t || a && (c || e());
      }()
    };
  }
  static get webApi() {
    return {
      fileReader: function() {
        const e = document.createElement("div");
        return ("draggable" in e || "ondragstart" in e && "ondrop" in e) && "FormData" in window && "FileReader" in window;
      }()
    };
  }
};
i(n, "Environment");
let r = n;
export {
  r as Environment
};
