import { RouteInterface } from "@kinherit/framework/core/route-config";

export const AdminLogIndexRoute = "AdminLogIndex";
export const FailedLoginAttemptsMasterListRoute =
  "FailedLoginAttemptsMasterList";
export const FailedPasswordResetAttemptsMasterListRoute =
  "FailedPasswordResetAttemptsMasterList";

export const EmailLogMasterListRoute = "EmailLogMasterList";
export const NotificationLogMasterListRoute = "NotificationLogMasterList";
export const KinvaultAccessRequestsMasterListRoute =
  "KinvaultAccessRequestsMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: AdminLogIndexRoute,
    path: "/admin/log",
    component: () => import(/* webpackChunkName: "Index" */ "./Index.vue"),
    children: [
      {
        name: FailedLoginAttemptsMasterListRoute,
        path: "/admin/log/failed-login-attempts",
        component: () =>
          import(
            /* webpackChunkName: "FailedLoginAttemptsMasterList" */ "./FailedLoginAttemptsMasterList.vue"
          ),
      },
      {
        name: FailedPasswordResetAttemptsMasterListRoute,
        path: "/admin/log/failed-password-reset-attempts",
        component: () =>
          import(
            /* webpackChunkName: "FailedPasswordResetAttemptsMasterList" */ "./FailedPasswordResetAttemptsMasterList.vue"
          ),
      },
      {
        name: EmailLogMasterListRoute,
        path: "/admin/log/email-log",
        component: () =>
          import(
            /* webpackChunkName: "EmailLogMasterList" */ "./EmailLogMasterList.vue"
          ),
      },
      {
        name: NotificationLogMasterListRoute,
        path: "/admin/log/notification-log",
        component: () =>
          import(
            /* webpackChunkName: "NotificationLogMasterList" */ "./NotificationLogMasterList.vue"
          ),
      },
      {
        name: KinvaultAccessRequestsMasterListRoute,
        path: "/admin/log/kinvault-access-requests",
        component: () =>
          import(
            /* webpackChunkName: "KinvaultAccessRequestsMasterList" */ "./KinvaultAccessRequestsMasterList.vue"
          ),
      },
    ],
  },
];
