// EmailCampaign, Profile

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { EmailCampaign } from "@kinherit/sdk/model/email-campaign";

export const CreateEmailCampaign = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/email-campaigns",
  method: "post",
  parse: (target: EmailCampaign) => ({
    body: {
      name: target.$data.name,
      description: target.$data.description,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
