<template>
  <Card title="Xero Authentication">
    <p>
      Attempts to authorise the API with Xero. Only use if the connection to
      Xero gets lost.
    </p>
    <Button text="Set Xero Token" @click="setXeroToken" />
  </Card>
  <Card title="Xero Contacts">
    <p>
      Syncs the contacts from Xero to the application. This will update all xero
      contacts or insert new entries.
    </p>
    <Button text="Sync Xero Contacts" @click="syncXeroContacts" />
  </Card>
</template>

<script lang="ts">
import { SettingsXeroRoute } from "@/module/settings/page";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: SettingsXeroRoute,
  components: { Card, Button },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.core)],
  methods: {
    async setXeroToken() {
      await this.$actionBus.SetXeroToken();
    },
    async clearCache() {
      await this.$actionBus.ClearCache();
    },
    async syncXeroContacts() {
      await this.$actionBus.SyncXeroContacts();
    },
  },
});
</script>
