// Product

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Currency, DateTime } from "@kinherit/ts-common";

export const UpdateProductPrices = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/admin/product/update-prices",
  method: "put",
  parse: ({
    endDate,
    startDate,
    products,
  }: {
    endDate: DateTime;
    startDate: DateTime;
    products: { id: string; price: Currency }[];
  }) => ({
    body: {
      endDate: endDate.formatMachine,
      startDate: startDate.formatMachine,
      products: products.map(({ id, price }) => ({
        id,
        price: price.amount,
      })),
    },
  }),
});
