var u = Object.defineProperty;
var a = (o, e) => u(o, "name", { value: e, configurable: !0 });
import { _ as r } from "../../../component.input/text-field/text-field.vue_vue_type_script_lang.js";
import { resolveComponent as p, createBlock as d, openBlock as f, mergeProps as m, createSlots as c, withCtx as s, renderSlot as i } from "vue";
import { b as t } from "../../../_assets/component.input/text-field/text-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as g } from "../../../_plugins/_plugin-vue_export-helper.js";
function h(o, e, v, w, b, k) {
  const n = p("BaseInputField");
  return f(), d(n, m(o.$data.$cypress, {
    value: o.computedValue,
    "onUpdate:value": e[0] || (e[0] = (l) => o.computedValue = l),
    class: `${o.wrapperClasses} text-field`,
    label: o.label,
    "input-tag": o.isTextArea ? "textarea" : "input",
    type: "text",
    placeholder: o.placeholder,
    state: o.state,
    validators: o.validators,
    message: o.message,
    "message-color": o.messageColor,
    size: o.size,
    color: o.color,
    "is-loading": o.isLoading,
    "icon-left": o.iconLeft,
    "icon-right-override": o.computedIconRight,
    "show-validation": o.computedShowValidation,
    "left-icon-click": o.leftIconClick,
    "right-icon-click": o.rightIconClick,
    "is-fullwidth": o.isFullwidth,
    "is-grouped": o.isGrouped,
    "show-clear-button": o.showClearButton,
    autofocus: o.autofocus,
    autocomplete: o.autocomplete,
    "auto-collapse-label": o.autoCollapseLabel,
    onClear: o.clear,
    onFocus: o.focus,
    onBlur: o.blur,
    onSubmit: e[1] || (e[1] = (l) => o.isTextArea ? null : o.keydown)
  }), c({ _: 2 }, [
    o.showLeftControl ? {
      name: "left",
      fn: s(() => [
        i(o.$slots, "left", { value: o.computedValue })
      ]),
      key: "0"
    } : void 0,
    o.showRightControl ? {
      name: "right",
      fn: s(() => [
        i(o.$slots, "right", { value: o.computedValue })
      ]),
      key: "1"
    } : void 0
  ]), 1040, ["value", "class", "label", "input-tag", "placeholder", "state", "validators", "message", "message-color", "size", "color", "is-loading", "icon-left", "icon-right-override", "show-validation", "left-icon-click", "right-icon-click", "is-fullwidth", "is-grouped", "show-clear-button", "autofocus", "autocomplete", "auto-collapse-label", "onClear", "onFocus", "onBlur"]);
}
a(h, "_sfc_render");
typeof t == "function" && t(r);
const I = /* @__PURE__ */ g(r, [["render", h]]);
export {
  I as default
};
