<template>
  <div class="staff-master-list">
    <PageHeader htag="h1" text="Staff Users">
      <template #buttons>
        <Button
          v-if="hasWriteAccess"
          class="create"
          size="is-normal"
          @click="create"
        >
          Create User
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      :row-classes="rowClasses"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        createdAt: 'Created',
        'credential.lastLoggedIn': 'Last Login',
        'profile.fullName': 'Name',
        'credential.username': 'Username',
      }"
      @refresh="refresh"
      @row-clicked="handleClick"
    >
      <template #name="{ row }">
        <strong>{{ row.profile.fullName }}</strong
        ><br /><small> {{ row.credential.username }}</small>
      </template>
      <template #disabled="{ row }">
        {{ row.status?.text }}
      </template>
      <template #portal="{ row }">
        <Icon
          v-if="row.displayUserInPortal"
          icon="Tick"
          class="has-text-success"
        />
        <Icon v-else icon="Cross" class="has-text-danger" />
      </template>
      <template #emailNotifications="{ row }">
        <Icon
          v-if="row.sendEmailNotifications"
          icon="Tick"
          class="has-text-success"
        />
        <Icon v-else icon="Cross" class="has-text-danger" />
      </template>
      <template #smsNotifications="{ row }">
        <Icon
          v-if="row.sendSmsNotifications"
          icon="Tick"
          class="has-text-success"
        />
        <Icon v-else icon="Cross" class="has-text-danger" />
      </template>
      <template #isEstatePlanner="{ row }">
        <Icon v-if="row.isEstatePlanner" icon="Tick" class="has-text-success" />
        <Icon v-else icon="Cross" class="has-text-danger" />
      </template>
      <template #isLegalAssistant="{ row }">
        <Icon
          v-if="row.isLegalAssistant"
          icon="Tick"
          class="has-text-success"
        />
        <Icon v-else icon="Cross" class="has-text-danger" />
      </template>
      <template #acuity="{ row }">
        <Icon v-if="row.acuityUserId" icon="Tick" class="has-text-success" />
        <Icon v-else icon="Cross" class="has-text-danger" />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "StaffMasterListWrapper",
  "route": "StaffMasterList",
  "selector": ".staff-master-list",
  "imports": {
    "ReadStaffUserFormWrapper": "@/module/admin.user/form/read-staff-user.form.test",
    "CreateUserFormWrapper": "@/module/admin.user/form/create-user.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadStaffUserFormWrapper"
    },
    "create": {
      "type": "click",
      "selector": ".create"
    },
    "createUserForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "CreateUserFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import Icon from "@kinherit/framework/component.display/icon";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { Between, Equal, In, Like } from "@kinherit/orm/index";
import { IUser, User } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { AdminStaffMasterListRoute } from ".";
import { CreateUserForm } from "../form/create-user.form";
import { ReadStaffUserForm } from "../form/read-staff-user.form";
import { AdminUserDetailsParams, AdminUserDetailsRoute } from "./details";

export const StaffUserTableColumns: Array<TableColumn> = [
  {
    title: "Name",
    sort: true,
    slot: "name",
  },
  {
    title: "Roles",
    map: (user: User) => user.roles.map((role) => role.role).join(", "),
  },
  {
    title: "Status",
    slot: "disabled",
  },
  {
    title: "Display Portal",
    slot: "portal",
  },
  {
    title: "EP",
    slot: "isEstatePlanner",
  },
  {
    title: "LA",
    slot: "isLegalAssistant",
  },
  {
    title: "Has Acuity",
    slot: "acuity",
  },
  {
    title: "Email Notifications",
    slot: "emailNotifications",
  },
  {
    title: "SMS",
    slot: "smsNotifications",
  },
];

export default defineComponent({
  name: AdminStaffMasterListRoute,
  components: { MasterListPage, Button, PageHeader, Icon },
  mixins: [
    ActionBusMixin(() => window.Kernel.ActionBus2.portal.admin.users.staff),
  ],
  data: () => ({
    filters: ReadStaffUserForm(),
    columns: StaffUserTableColumns,
    rows: Array<User>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IUser,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("user:write");
    },
  },
  methods: {
    rowClasses(scope: any): Record<string, boolean> {
      return {
        "has-background-danger-light is-qualified-out":
          scope.row.status?.value === "disabled",
      };
    },
    handleClick(user: User, event: MouseEvent): void {
      const params: AdminUserDetailsParams = { user: user.id };

      window.Kernel.visitRoute(
        {
          name: AdminUserDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadStaffUserForm>["localData"],
    ): Promise<void> {
      // await window.Kernel.ActionBus.adminUser.staff.read(requestData);
      const data = await this.$actionBus.ReadUsers({
        query: [
          {
            profile: {
              fullName: Like(formData.search),
            },
            roles: {
              role: In(["staff", "admin"]),
            },
            createdAt: Between(formData.created),
            isDisabled: Equal(formData.isDisabled),
          },
          {
            profile: {
              emails: {
                email: Like(formData.search),
              },
            },
            roles: {
              role: In(["staff", "admin"]),
            },
            createdAt: Between(formData.created),
            isDisabled: Equal(formData.isDisabled),
          },
        ],
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.user;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async create(): Promise<void> {
      const userData = await CreateUserForm().dialog({
        dialog: {
          title: "Create User",
        },
      });

      // await window.Kernel.ActionBus.adminUser.create(user);
      const result = await this.$actionBus.CreateUsers(userData);

      const user = result.user.first("Failed to create user");

      await OpenAlertDialog({
        dialog: {
          title: "User Created",
          message: `Successfully create ${user.profile.fullName} `,
        },
        button: {
          ok: {
            text: "View User",
            color: "is-positive",
          },
          cancel: {
            text: "Return",
          },
        },
      });

      const params: AdminUserDetailsParams = { user: user.id };

      window.Kernel.visitRoute({
        name: AdminUserDetailsRoute,
        params,
      });
    },
  },
});
</script>
