<template>
  <div v-if="kintin">
    <CallScriptSection title="Business Owners">
      <b> And also on the topic of trusts – your business.</b>
      <br /><br />
      <b>
        Your Will needs to include specific clauses so that you get the maximum
        benefit of what is called Business Relief.
      </b>
      <br /><br />
      <b>
        Having a business in trust takes this further – in some instances, it
        can allow your trustees to control the business but without it being at
        risk of things like divorce or bankruptcy or inheritance tax.

        <br /><br />
        Your company governing documents (like Articles of Association or
        Shareholder Agreement) will take precedence over the Will, so it’s
        important you check what these state.

        <br /><br />
        If the governing documents don’t allow your share to be passed in
        accordance with your Will, the Business Trust is still paramount because
        it enables you to achieve the Business Relief.
      </b>
      <br /><br />
      <em
        >[if asked – your share of the business passes into the trust on death,
        and the way the Will is drafted enables your executors to claim the
        Business Relief. The governing documents then kick in (e.g. say,
        [director name] has first refusal of the share.. they action this, and
        your share (in the trust) essentially switches place with the cash on
        the transaction). The proceeds are then protected for all beneficiaries
        via the usual trust protections, and Business Relief was able to be
        claimed, thus leaving more monies for your intended beneficiaries]
      </em>
      <br /><br />
      <b>
        And the good news is that business planning can be treated as a business
        expense.
        <br /><br />

        [If co-owners:] It's best practice that each owner of the business
        considers this as part of their own planning: I'd be more than happy to
        pick that up with them if you want to pass them my details.
      </b>
    </CallScriptSection>

    <CallScriptSection title="Business Owners LPAs">
      <b>
        Most business owners would want different people managing their personal
        and their business affairs.
      </b>
      <br /><br />
      <b
        >In a company setting, when a director or partner loses capacity, the
        results can be catastrophic for the business if a succession plan or
        lasting power of attorney (LPA) for business hasn't been put in
        place.</b
      >
      <br /><br />
      <b
        >For example, a director would be removed after 3 months of losing
        capacity, unless your company governing documents state otherwise, a
        Business LPA is crucial.
      </b>
      <br /><br />
      <b
        >The LPA’s for Business allow your appointed attorney to manage just as
        you would have, maintaining your position as a director. This again is
        business continuity planning and should go through the business.</b
      >
      <br /><br />
      <b
        >[If co-owners:] You should discuss this with them also to protect
        yourself.</b
      >
      <br /><br />
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { IntroducerCompany, IntroducerContact } from "@kinherit/sdk";
import { Currency } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: "BusinessTab",
  components: {
    CallScriptSection,
  },
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  emits: { save: null },
  data: () => ({
    values: {
      advSavingsValue: null as null | Currency,
      advPropsValue: null as null | Currency,
      advPolicyValue: null as null | Currency,
      advPensionValue: null as null | Currency,
      advOtherAssetValue: null as null | Currency,
      advBusinessAssetValue: null as null | Currency,
      advPotentialValue: null as null | Currency,
      advDebtValue: null as null | Currency,
      advHteStateValue: null as null | Currency,
    },
  }),
  computed: {
    referralContact(): IntroducerContact | null {
      return this.kintin?.referral?.contact ?? null;
    },
    referralCompany(): IntroducerCompany | null {
      return this.kintin?.referral?.referralCode?.company ?? null;
    },
    assetNetTotal(): number {
      return (
        (this.values.advSavingsValue?.amount ?? 0) +
        (this.values.advPropsValue?.amount ?? 0) +
        (this.values.advPolicyValue?.amount ?? 0) +
        (this.values.advPensionValue?.amount ?? 0) +
        (this.values.advOtherAssetValue?.amount ?? 0) +
        (this.values.advBusinessAssetValue?.amount ?? 0) +
        (this.values.advPotentialValue?.amount ?? 0) +
        (this.values.advDebtValue?.amount ?? 0)
      );
    },
  },
  mounted(): void {
    if (!this.kintin || !this.$auth.loggedInUser) {
      return;
    }

    this.values.advSavingsValue =
      this.kintin.callScript?.advSavingsValue ?? null;
    this.values.advPropsValue = this.kintin.callScript?.advPropsValue ?? null;
    this.values.advPolicyValue = this.kintin.callScript?.advPolicyValue ?? null;
    this.values.advPensionValue =
      this.kintin.callScript?.advPensionValue ?? null;
    this.values.advOtherAssetValue =
      this.kintin.callScript?.advOtherAssetValue ?? null;
    this.values.advBusinessAssetValue =
      this.kintin.callScript?.advBusinessAssetValue ?? null;
    this.values.advPotentialValue =
      this.kintin.callScript?.advPotentialValue ?? null;
    this.values.advDebtValue = this.kintin.callScript?.advDebtValue ?? null;
    this.values.advHteStateValue =
      this.kintin.callScript?.advHteStateValue ?? null;
  },
  methods: {
    async saveHandler() {
      this.$emit("save");
    },
  },
});
</script>
