// KnowledgeBaseDocument

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KnowledgeBaseDocument } from "@kinherit/sdk/model/knowledge-base-document";

export const RecordKnowledgeBaseDocument = DefineReadAction({
  interface: "portal",
  resource:
    "/v2/portal/knowledge-base/category/document/{knowledgeBaseDocument}",
  method: "get",
  rootModel: KnowledgeBaseDocument,
  parse: (knowledgeBaseDocument: KnowledgeBaseDocument | string) => ({
    params: {
      knowledgeBaseDocument,
    },
  }),
});
