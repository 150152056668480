<template>
  <MasterListPage
    v-if="$data.filters"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
    class="notification-log-master-list"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    title="Notification Log"
    :sort-by-options="{
      createdAt: 'Created At',
    }"
    @refresh="refresh"
  >
    <template #emails="{ row }: { row: Notification }">
      {{ row.status?.value }}
      <Badge
        :type="row.status?.class"
        :text="row.status?.text"
        size="is-small"
        is-rounded
      />
    </template>

    <template #to="{ row }: { row: Notification }">
      {{ row.user?.profile?.fullName }}
    </template>
  </MasterListPage>
</template>

<cypress-wrapper lang="json">
{
  "name": "NotificationLogMasterListWrapper",
  "route": "NotificationLogMasterList",
  "selector": ".notification-log-master-list",
  "imports": {
    "ReadNotificationLogFormWrapper": "@/module/admin.log/form/read-notification-log.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadNotificationLogFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadNotificationLogForm } from "@/module/admin.log/form/read-notification-log.form";
import { NotificationLogMasterListRoute } from "@/module/admin.log/page";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between } from "@kinherit/orm/index";
import { INotification, Notification } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: NotificationLogMasterListRoute,
  components: { MasterListPage, Badge },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.admin.log)],
  data: () => ({
    filters: ReadNotificationLogForm(),
    columns: [
      {
        map: (row) => row.createdAt.formatDateTime,
        title: "Created At",
      },
      {
        title: "Status",
        map: (row) => row.status?.value,
      },
      {
        title: "To",
        slot: "to",
      },
      {
        map: (row) => row.note?.name,
        title: "Subject",
      },
    ] as Array<TableColumn<Notification>>,
    rows: Array<Notification>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof INotification,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadNotificationLogForm>["localData"],
    ): Promise<void> {
      const { notification, $pagination } =
        // await window.Kernel.ActionBus.adminLog.notificationLog.read({
        //   ...formData,
        //   sort: this.sort,
        //   pagination: this.pagination,
        // });
        await this.$actionBus.ReadNotifications({
          query: {
            createdAt: Between(formData.created),
          },
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = notification;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
