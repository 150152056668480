import {
  LeadDetailsParams,
  LeadDetailsRoute,
} from "@/module/lead/page/details";
import { AuthService } from "@/service/auth.service";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { AccountReferralCode, Lead } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const LeadDetailsMixin = defineComponent({
  name: "LeadDetailsMixin",
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.lead)],
  computed: {
    $params(): LeadDetailsParams {
      return this.$route.params as any;
    },
    lead(): Lead | null {
      return Lead.$findOne(this.$params.lead);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("lead:write");
    },
    sortReferReferralCode(): AccountReferralCode | null {
      return AccountReferralCode.$findOneBy({
        code: "sortrefer",
      });
    },
    // NB, at some point we will move this to the accountReferral entity
    hasSortReferReferralCode(): boolean {
      return (
        this.sortReferReferralCode !== null &&
        this.lead?.referral?.referralCode?.id === this.sortReferReferralCode.id
      );
    },
    externalIntegrations(): string[] {
      if (this.hasSortReferReferralCode && this.lead?.referral?.externalId) {
        return ["sortrefer"];
      }
      return [];
    },
  },
  methods: {
    visitLeadDetails(): void {
      window.Kernel.Router.push({
        name: LeadDetailsRoute,
        params: { lead: this.lead?.id },
      });
    },
  },
});
