// EmailTemplate

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultCta } from "@kinherit/sdk";

export const UpdateBrandedKinvaultCta = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/admin/branded-kinvault-cta/{brandedKinvaultCta}",
  method: "patch",
  parse: (brandedKinvaultCta: BrandedKinvaultCta) => ({
    body: {
      position: brandedKinvaultCta.$data.position,
      template: brandedKinvaultCta.$data.template,
      categories: brandedKinvaultCta.$data.categories as any,
      internalName: brandedKinvaultCta.$data.internalName,
      introducerCompany: brandedKinvaultCta.$data.introducerCompany,
      referralCodes: brandedKinvaultCta.$data.referralCodes,
      title: brandedKinvaultCta.$data.title,
      displayFrom: brandedKinvaultCta.$data.displayFrom,
      displayTo: brandedKinvaultCta.$data.displayTo,
      content: brandedKinvaultCta.$data.content,
      conditions: brandedKinvaultCta.$data.conditions as any,
      country: brandedKinvaultCta.$data.country,
      language: brandedKinvaultCta.$data.language,
    },
    params: {
      brandedKinvaultCta: brandedKinvaultCta,
    },
  }),
});
