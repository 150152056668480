<template>
  <div v-if="kintin">
    <CallScriptSection v-if="kintin" title="Guardians Intro">
      <Message
        :color="hasChildrenOrDependents ? 'is-danger' : 'is-success'"
        :text="
          hasChildrenOrDependents
            ? 'There are children or dependents under 18 or requiring special care'
            : 'No children or dependents under 18 or requiring special care'
        "
      />

      <b v-if="checkIsNo('2.5.0a')">
        <br /><br />As you do not have any children under 18 or dependents you
        do not need to list guardians – however if you know someone with
        children that needs information on the subject of appointing guardians
        please let us know, as we have some great information we can share.
      </b>

      <b v-if="checkIsNo('2.5.0a') && checkIsNo('2.5.2a')">
        <br /><br />If you did have children, you'd need to define a Guardian -
        we do offer a five year complimentary rewrite for this.
      </b>

      <b v-if="checkIsNo('2.5.0a') && checkIsNo('2.5.2a')">
        <br /><br />And if you did become Guardian of someone elses children,
        you might want to have a think if your Will still works.
      </b>

      <b v-if="checkIsYes('2.5.2b') && checkIsNo('2.5.0a')">
        <br /><br />And if you did become Guardian of someone elses children,
        you might want to have a think if your Will still works.
      </b>

      <b v-if="checkIsYes('2.5.0a') && checkIsNo('2.5.0d')">
        <br /><br />As you might know, one of the most important purposes of a
        Will is to define Guardians for Children under 18 or other dependants,
        but as your kids are over 18, we can skip this section
      </b>

      <b v-if="checkIsYes('2.5.0a') && checkIsYes('2.5.0d')">
        <br /><br />As you might know, one of the most important purposes of a
        Will is to define Guardians for Children under 18 or other dependants.
      </b>

      <b v-if="checkIsYes('2.5.0a') && checkIsYes('2.5.0d')">
        <br /><br />It is particularly important in your situation, because you
        are not married and guardianship doesn’t automatically transfer.
      </b>

      <b v-if="checkIsYes('2.5.0a') && checkIsYes('2.5.0b')">
        <br /><br />Children from a prior relationship clearly should also be
        considered.
      </b>

      <b v-if="checkIsYes('2.5.0a') && checkIsYes('2.5.0d')">
        <br /><br />Have you given any thought about who might act as Guardian?
      </b>
    </CallScriptSection>
    <span>
      <CallScriptSection
        v-if="
          kintin &&
          checkIsYes('2.5.0a') &&
          checkIsYes('2.5.0d') &&
          hasChildrenOrDependents
        "
        title="Listing Guardians"
      >
        <GuardiansContainer :kintin="kintin" />
        <span v-if="checkIsYes('2.5.0b')">
          <b>
            Couples usually appoint their own Guardians on second death, but
            where there are children from a prior relationship you can appoint
            on first death.
          </b>
          <br />
          <em>
            [discuss parental responsibility of other parent / having them
            mirror the Guardianship / if don’t want 3rd parent as Guardian
            should write statement in Kinvault].
          </em>
        </span>
        <b v-if="checkIsNo('2.5.0b')">
          As you're a couple, we would appoint Guardians on second death.
        </b>
        <span v-if="!isJoint">
          <b>
            It would be normal for you to name a Guardian upon your death.
          </b>
          <br />
          <em
            >[discuss parental responsibility of other parent / having them
            mirror the Guardianship / if don’t want 3rd parent as Guardian
            should write statement in Kinvault].</em
          >
        </span>
        <br />
        <br />
        <ConfirmCheck
          :kintin="kintin"
          step="4.0.0c"
          :kintin-checks="kintinChecks"
          :checks="checks"
          is-reversed
          :on="{
            yes: {
              action: ['Ensure each guardian has appropriate note, per child'],
            },
          }"
          @update="updateCheckValue"
        />
        <br />
        <em
          >[Specialist to be aware of possible situations: i.e prior
          relationships / abusive ex / seperated / disabled / in jail ?]</em
        >
        <br />
        <br />
        <b>
          If your Guardian is in a long term relationship, it can help if their
          partner is co-guardian (for as long as they're together)
        </b>
        <br />
        <ConfirmCheck
          :kintin="kintin"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="4.0.0a"
          :on="{
            yes: {
              action: [
                'Ensure you list the name of the partner in the notes section',
              ],
            },
          }"
          @update="updateCheckValue"
        />
        <em>[Only to ask if they have two or fewer guardians]</em>
        <br />
        <br />
        <b>
          Should the above guardians not be able to perform their duties or have
          sadly passed away do you have an additional person you could give me
          that could act as a Reserve Guardian?
        </b>
        <br />
        <br /><em>[pause, take details ]</em>
        <ConfirmCheck
          :kintin="kintin"
          step="4.0.2"
          :kintin-checks="kintinChecks"
          :checks="checks"
          @update="updateCheckValue"
        />
      </CallScriptSection>
    </span>
    <CallScriptSection
      v-if="
        kintin &&
        checkIsYes('2.5.0a') &&
        checkIsYes('2.5.0d') &&
        hasChildrenOrDependents
      "
      title="Guardians Financial Assistance"
    >
      <b>
        Have you thought about how your guardians would afford to raise and
        support your children?
      </b>
      <br />
      <br /><em>[PAUSE and count to 5.]</em> <br /><br />
      <b>
        OK, so the UK government gives just &pound;18 per week to Guardians,
        which obviously isn’t much… If you would like, we can allow for
        additional finance to be given to the guardians through the Will. Should
        I put a note for us to come back to this and consider extra financial
        support?
      </b>
      <br />
      <ConfirmCheck
        :kintin="kintin"
        step="4.0.0b"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
      <br />
      <em v-if="checkIsYes('4.0.0b')">
        [Specialist to add details if customer is keen, else leave blank for
        now]
      </em>
      <RichTextField
        v-if="checkIsYes('4.0.0b')"
        v-model:value="callScript.mowGuardians"
        label="Memorandum of Wishes guardian section"
      />
      <!--Button
        v-if="checkIsYes('4.0.0b')"
        text="Pre-populate with placeholder values"
        color="is-plain"
      /-->
      <Button
        v-if="checkIsYes('4.0.0b')"
        text="Save"
        color="is-positive"
        class="mt-4 mb-4"
        @click="saveHandler"
      />
      <ConfirmCheck
        :kintin="kintin"
        step="4.0.3"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <SectionNotes
      v-if="callScript"
      v-model:value="callScript.advGuardianNotes"
      :kintin="kintin"
      has-save-continue
      @save="saveHandler"
      @save-continue="saveAndContinueHandler"
    />
  </div>
</template>

<script lang="ts">
import GuardiansContainer from "@/module/kinvault.kintin/component/address-book/GuardiansContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import SectionNotes from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import Message from "@kinherit/framework/component.display/message";
import Button from "@kinherit/framework/component.input/button";
import RichTextField from "@kinherit/framework/component.input/rich-text-field";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GuardiansTab",
  components: {
    CallScriptSection,
    ConfirmCheck,
    GuardiansContainer,
    Message,
    SectionNotes,
    RichTextField,
    Button,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  emits: ["save", "save-and-continue"],
  methods: {
    saveAndContinueHandler() {
      this.$emit("save-and-continue");
    },
    saveHandler() {
      this.$emit("save");
    },
  },
});
</script>
