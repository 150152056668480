// Subscription, PhysicalStorageFile

import { Api } from "@/service/api.service";
import {
  Kintin,
  PhysicalStorageFile,
  PhysicalStorageSubscription,
} from "@kinherit/sdk";

interface UploadKintinStorageRequestMessage {
  kintin: Kintin;
  subscription: PhysicalStorageSubscription;
  storageRequest: PhysicalStorageFile;
  file: File;
}

interface UploadKintinStorageRequestResponse {
  subscription: PhysicalStorageSubscription;
  physicalStorageFiles: Array<PhysicalStorageFile>;
}

export const UploadStorageRequest = async (
  message: UploadKintinStorageRequestMessage,
): Promise<UploadKintinStorageRequestResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/physical-storage-subscription/{kintin}/subscriptions/{subscription}/storage-request/{storageRequest}",
    {
      kintin: message.kintin,
      subscription: message.subscription,
      storageRequest: message.storageRequest,
    },
  )
    .method("put")
    .files({
      uploadedFile: message.file,
    })

    .result();

  const subscription = PhysicalStorageSubscription.$inflate(
    response.physicalStorageSubscription,
  ).first();

  if (!subscription) {
    throw new Error("Failed to create storage request");
  }

  return {
    subscription,
    physicalStorageFiles: PhysicalStorageFile.$inflate(
      response.physicalStorageFile,
    ),
  };
};
