import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReconcileProfiles } from "./reconcile-profiles.action";
import { ReconcileTypes } from "./reconcile-types.action";
import { Reconcile } from "./reconcile.action";

export const Portal2SettingsAcuity = DefineActionBus({
  name: "portal-settings-acuity",
  actions: {
    ReconcileProfiles,
    ReconcileTypes,
    Reconcile,
  },
});
