var h = Object.defineProperty;
var n = (e, o) => h(e, "name", { value: o, configurable: !0 });
import { _ as p } from "../../../component.input/number-field/number-field.vue_vue_type_script_lang.js";
import { resolveComponent as r, createBlock as s, openBlock as i, mergeProps as g, createSlots as b, withCtx as t, renderSlot as u, createCommentVNode as d } from "vue";
import { b as m } from "../../../_assets/component.input/number-field/number-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as c } from "../../../_plugins/_plugin-vue_export-helper.js";
function C(e, o, v, k, w, y) {
  const a = r("Button"), f = r("BaseInputField");
  return i(), s(f, g(e.$data.$cypress, {
    value: e.computedValue,
    "onUpdate:value": o[2] || (o[2] = (l) => e.computedValue = l),
    class: [e.wrapperClasses, "number-field", e.compClassMaxLength],
    label: e.label,
    "input-tag": "input",
    type: "number",
    placeholder: e.placeholder,
    min: e.min,
    max: e.max,
    step: e.step,
    "icon-left": e.iconLeft,
    "icon-right-override": e.computedIconRight,
    validators: e.validators,
    message: e.message,
    "message-color": e.messageColor,
    size: e.size,
    color: e.color,
    "is-loading": e.isLoading,
    state: e.state,
    "show-validation": e.computedShowValidation,
    "left-icon-click": e.leftIconClick,
    "right-icon-click": e.rightIconClick,
    "is-fullwidth": e.isFullwidth,
    "is-grouped": e.isGrouped,
    "show-clear-button": e.showClearButton,
    autofocus: e.autofocus,
    autocomplete: e.autocomplete,
    onClear: e.clear,
    onFocus: e.focus,
    onBlur: e.blur,
    onSubmit: e.keydown
  }), b({ _: 2 }, [
    !e.hideControls || e.showLeftControl ? {
      name: "left",
      fn: t(() => [
        u(e.$slots, "left", { value: e.computedValue }),
        e.hideControls ? d("", !0) : (i(), s(a, {
          key: 0,
          class: "minus-button",
          size: e.size,
          color: e.minusColor,
          "icon-left": e.icon.chevronLeft,
          disabled: e.min !== null && e.computedValue !== null && e.computedValue.toString().toFloat() <= e.min || e.state === "disabled" || e.state === "readonly",
          "aria-label": "Decrease",
          onClick: o[0] || (o[0] = (l) => e.decrease())
        }, null, 8, ["size", "color", "icon-left", "disabled"]))
      ]),
      key: "0"
    } : void 0,
    !e.hideControls || e.showRightControl ? {
      name: "right",
      fn: t(() => [
        e.hideControls ? d("", !0) : (i(), s(a, {
          key: 0,
          class: "plus-button",
          size: e.size,
          color: e.plusColor,
          "icon-left": e.icon.chevronRight,
          disabled: e.max !== null && e.computedValue !== null && e.computedValue.toString().toFloat() >= e.max || e.state === "disabled" || e.state === "readonly",
          "aria-label": "Increase",
          onClick: o[1] || (o[1] = (l) => e.increase())
        }, null, 8, ["size", "color", "icon-left", "disabled"])),
        u(e.$slots, "right", { value: e.computedValue })
      ]),
      key: "1"
    } : void 0
  ]), 1040, ["value", "class", "label", "placeholder", "min", "max", "step", "icon-left", "icon-right-override", "validators", "message", "message-color", "size", "color", "is-loading", "state", "show-validation", "left-icon-click", "right-icon-click", "is-fullwidth", "is-grouped", "show-clear-button", "autofocus", "autocomplete", "onClear", "onFocus", "onBlur", "onSubmit"]);
}
n(C, "_sfc_render");
typeof m == "function" && m(p);
const S = /* @__PURE__ */ c(p, [["render", C]]);
export {
  S as default
};
