import { Api, IntroducerFeePaymentRun } from "@kinherit/sdk";

// IntroducerFeePaymentRun, IntroducerFeePayment
export const ApproveIntroducerFeePaymentRun = async (
  paymentRun: IntroducerFeePaymentRun,
) => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer/fees/payment-run/{introducerFeePaymentRun}/approve",
    {
      introducerFeePaymentRun: paymentRun.id,
    },
  )
    .method("post")
    .result();
};
