var y = Object.defineProperty;
var n = (e, t) => y(e, "name", { value: t, configurable: !0 });
import { ObjectValidator as S } from "../validation/object-validator.service.js";
import { ListDynamicLayoutElementSlots as V } from "../component.layout/dynamic-layout/helpers.js";
const j = /* @__PURE__ */ n((e, t) => {
  const r = [];
  return (e.formAreas === void 0 ? [] : e.formAreas(t)).forEach((o) => {
    if (o.components === void 0 || o.show !== void 0 && o.show(t) === !1)
      return;
    const l = o.components(t);
    h(
      l,
      o.template ?? { slot: "default" }
    ).map((a) => g(a, t, e)).filter((a) => a !== void 0).forEach((a) => r.push(...a));
  }), new S(r).validate(t);
}, "GenerateFormValidation"), h = /* @__PURE__ */ n((e, t) => {
  const r = V(t), s = [];
  for (const [o, l] of Object.entries(e))
    r.includes(o) && s.push(
      ...l.filter(
        (i) => i != null
      )
    );
  return s.forEach((o) => {
    if (o.slots !== void 0)
      for (const l of Object.values(o.slots))
        Array.isArray(l) ? s.push(
          ...l.filter(
            (i) => i != null
          )
        ) : s.push(
          ...h(
            l.components ?? {},
            l.template
          )
        );
  }), s;
}, "FilterComponentBySlot"), g = /* @__PURE__ */ n((e, t, r) => {
  var p, c, u, f, v, m, b;
  let s = null;
  try {
    s = e.bindings(t, r, {
      incrementTemplateRenderKey: /* @__PURE__ */ n(() => {
      }, "incrementTemplateRenderKey"),
      templateScope: {},
      rebuildTemplateBindings: /* @__PURE__ */ n(() => {
      }, "rebuildTemplateBindings")
    });
  } catch {
    return;
  }
  if (s.vIf === !1 || !("models" in e && e.models !== void 0 && "value" in e.models))
    return;
  let o = null;
  try {
    o = e.getValue(t, r, {
      incrementTemplateRenderKey: /* @__PURE__ */ n(() => {
      }, "incrementTemplateRenderKey"),
      templateScope: {},
      rebuildTemplateBindings: /* @__PURE__ */ n(() => {
      }, "rebuildTemplateBindings")
    });
  } catch {
    return;
  }
  const l = ((u = (c = (p = e.component.mixins) == null ? void 0 : p[0]) == null ? void 0 : c.options) == null ? void 0 : u.validators) ?? [], i = (typeof ((f = e.props) == null ? void 0 : f.validators) == "function" ? e.props.validators(t) : (v = e.props) == null ? void 0 : v.validators) ?? [], d = [
    ...l,
    ...i
  ];
  return [
    {
      label: (typeof ((m = e.props) == null ? void 0 : m.label) == "function" ? e.props.label(t) : (b = e.props) == null ? void 0 : b.label) ?? "",
      value: o,
      validators: d
    }
  ];
}, "GetComponentValidation");
export {
  j as GenerateFormValidation
};
