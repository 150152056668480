<template>
  <PageHeader htag="h1" text="Acuity Links" />
  <MasterListFilters v-if="$data.filters" :filters="$data.filters" />
  <Card v-if="selectedAcuityUserId" :title="'Links for ' + selectedUserName">
    <p v-if="!computedLinks.phone.length && !computedLinks.zoom.length">
      No Links available
    </p>
    <Tabs
      v-if="computedLinks.phone.length || computedLinks.zoom.length"
      v-model:tab="activeTab"
      :config="tabs"
    >
      <template #tab-1>
        <div v-if="computedLinks.phone.length">
          <h3>Phone</h3>
          <table class="table is-fullwidth">
            <tbody>
              <tr v-for="(link, i) in computedLinks.phone" :key="i">
                <td>
                  <a :href="link.link" target="_blank">{{ link.title }}</a>
                </td>
                <td style="display: flex">
                  <input
                    type="text"
                    :value="link.link"
                    class="input"
                    readonly
                    @click="($event.target as HTMLTextAreaElement)?.select()"
                  />
                  <Button
                    icon-left="Copy"
                    aria-label="Copy"
                    @click="copyToClipboard(link)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #tab-2>
        <div v-if="computedLinks.zoom.length">
          <h3>Zoom</h3>
          <table class="table is-fullwidth">
            <tbody>
              <tr v-for="(link, ii) in computedLinks.zoom" :key="ii">
                <td>
                  <a :href="link.link" target="_blank">{{ link.title }}</a>
                </td>
                <td style="display: flex">
                  <input
                    type="text"
                    :value="link.link"
                    class="input"
                    readonly
                    @click="($event.target as HTMLTextAreaElement)?.select()"
                  />
                  <Button
                    icon-left="Copy"
                    aria-label="Copy"
                    @click="copyToClipboard(link)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </Tabs>
  </Card>
  <Card v-else>
    <p>Select a user to view their acuity links</p>
  </Card>
</template>

<script lang="ts">
import { OptionService } from "@/module/core/service/option.service";
import { ReadAcuityForm } from "@/module/dashboard/form/read-acuity.form";
import Tabs, { TabOptions } from "@kinherit/framework/component.display/tabs";
import Button from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { ClipBoardService } from "@kinherit/framework/service/clip-board-service";
import { Option } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { DashboardAcuityRoute } from ".";

type LinkResult = {
  phone: Array<{ title: string; link: string }>;
  zoom: Array<{ title: string; link: string }>;
};

export default defineComponent({
  name: DashboardAcuityRoute,
  components: { PageHeader, Card, MasterListFilters, Tabs, Button },
  data: () => ({
    filters: ReadAcuityForm(),
    appointmentTypes: [] as Option[],
    // Tabs
    activeTab: 0,
    tabs: [
      {
        label: "Phone",
      },
      {
        label: "Zoom",
      },
    ] satisfies Array<TabOptions>,
  }),
  computed: {
    selectedAcuityUserId() {
      return this.$data.filters?.localData?.selected?.acuityUserId;
    },
    selectedUserName() {
      return this.$data.filters?.localData?.selected?.profile?.fullName;
    },
    computedLinks(): LinkResult {
      const { appointmentTypes, selectedAcuityUserId } = this;

      const results: LinkResult = {
        phone: [],
        zoom: [],
      };

      for (let i = 0; i < appointmentTypes.length; i++) {
        const { text, data } = appointmentTypes[i];
        const acuityAppointmentType = data?.acuityAppointmentType ?? "";
        const appointmentCommType = data?.appointmentCommType ?? "";
        const link = `https://kinherit.as.me/?appointmentType=${acuityAppointmentType}&calendarID=${selectedAcuityUserId}`;

        if (appointmentCommType === "phone") {
          results.phone.push({
            title: text,
            link: link,
          });
        } else if (appointmentCommType === "zoom") {
          results.zoom.push({
            title: text,
            link: link,
          });
        }
      }

      return results;
    },
  },
  mounted() {
    this.appointmentTypes = OptionService.getOptions("appointmentType", true);
  },
  methods: {
    copyToClipboard(link: { title: string; link: string }) {
      ClipBoardService.copyTextToClipBoard(link.link, {
        mimeTypes: ["text/plain"],
        showSnackbar: true,
        wrapLinks: false,
      });
    },
  },
});
</script>
