<template>
  <Tabs
    class="kintin-call-script-sidebar"
    size="is-normal"
    :config="items"
    is-vertical
  >
    <template #tab-item-left="{ tab }">
      <div style="min-width: 1.6em; text-align: right">
        <Badge
          is-compact
          size="is-small"
          :color="tab.count > 0 ? 'is-light-grey' : 'is-white'"
          class="m-0"
        >
          {{ tab.count }}
        </Badge>
      </div>
    </template>
  </Tabs>
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsCallScriptSidebarWrapper",
  "methods": {
    "tabs": {
      "type": "tabs",
      "selector": "",
      "items": [
        "Will Review",
        "LPA Only",
        "Intro",
        "Family & Guardians",
        "Property & Assets",
        "Who Gets What",
        "Who Does What",
        "Client Summary",
        "Follow-Up",
        "Kinvault"
      ]
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import Badge from "@kinherit/framework/component.display/badge";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { defineComponent } from "vue";
import { KintinDetailsCallScriptClientSummaryRoute } from "./client-summary";
import { KintinDetailsCallScriptFamilyGuardiansRoute } from "./family-guardians";
import { KintinDetailsCallScriptFollowUpRoute } from "./follow-up";
import { KintinDetailsCallScriptIntroRoute } from "./intro";
import { KintinDetailsCallScriptKinvaultRoute } from "./kinvault";
import { KintinDetailsCallScriptLPAOnlyRoute } from "./lpa-only";
import { KintinDetailsCallScriptPropertyAssetsRoute } from "./property-assets";
import { KintinDetailsCallScriptWhoDoesWhatRoute } from "./who-does-what";
import { KintinDetailsCallScriptWhoGetsWhatRoute } from "./who-gets-what";
import { KintinDetailsCallScriptWillReviewRoute } from "./will-review";

export default defineComponent({
  name: "KinvaultKintinDetailsCallScriptSidebar",
  components: {
    Tabs,
    Badge,
  },
  mixins: [KinvaultKintinDetailsMixin],
  computed: {
    items() {
      const isLap = this.kintin?.process.value === "lpasonly";

      if (isLap) {
        return [
          {
            label: "Will Review",
            count: "1",
            route: {
              name: KintinDetailsCallScriptWillReviewRoute,
              params: this.$params,
            },
          },
          {
            label: "LPA Only",
            count: "1f",
            route: {
              name: KintinDetailsCallScriptLPAOnlyRoute,
              params: this.$params,
            },
          },
        ];
      }

      return [
        {
          label: "Will Review",
          count: "1",
          route: {
            name: KintinDetailsCallScriptWillReviewRoute,
            params: this.$params,
          },
        },
        {
          label: "Intro",
          count: "1a",
          route: {
            name: KintinDetailsCallScriptIntroRoute,
            params: this.$params,
          },
        },
        {
          label: "Family & Guardians",
          count: "1b",
          route: {
            name: KintinDetailsCallScriptFamilyGuardiansRoute,
            params: this.$params,
          },
        },
        {
          label: "Property & Assets",
          count: "1c",
          route: {
            name: KintinDetailsCallScriptPropertyAssetsRoute,
            params: this.$params,
          },
        },
        {
          label: "Who Gets What",
          count: "1d",
          route: {
            name: KintinDetailsCallScriptWhoGetsWhatRoute,
            params: this.$params,
          },
        },
        {
          label: "Who Does What",
          count: "1e",
          route: {
            name: KintinDetailsCallScriptWhoDoesWhatRoute,
            params: this.$params,
          },
        },
        {
          label: "Client Summary",
          count: "1f",
          route: {
            name: KintinDetailsCallScriptClientSummaryRoute,
            params: this.$params,
          },
        },
        {
          label: "Follow-Up",
          count: "2",
          route: {
            name: KintinDetailsCallScriptFollowUpRoute,
            params: this.$params,
          },
        },
        {
          label: "Kinvault",
          count: "4",
          route: {
            name: KintinDetailsCallScriptKinvaultRoute,
            params: this.$params,
          },
        },
      ];
    },
  },
});
</script>

<style lang="scss">
.kintin-call-script-sidebar {
  .tabs__tab-content {
    display: none;
  }
}
</style>
