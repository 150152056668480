var l = Object.defineProperty;
var t = (e, i) => l(e, "name", { value: i, configurable: !0 });
import n from "../../_vue/component.display/icon/icon.js";
import o from "../../_vue/component.form/field/field.js";
import { FieldMixin as r } from "../../component.mixin/field-mixin.js";
import { CypressHelper as s } from "../../cypress/component-helpers.js";
import d from "@tinymce/tinymce-vue";
import { defineComponent as a } from "vue";
import { S as u } from "../../_assets/component.input/rich-text-field/rich-text-field.module.js";
const S = a({
  name: "RichTextFieldComponent",
  components: { Icon: n, Field: o, Editor: d },
  mixins: [r()],
  props: {
    mode: {
      type: String,
      default: "full"
    },
    contentStyle: {
      type: String,
      default: null
    },
    height: {
      type: [Number, String],
      default: 250
    }
  },
  emits: ["blur"],
  data: /* @__PURE__ */ t((e) => ({
    $cypress: s({ ctx: e, labelField: "label" })
  }), "data"),
  computed: {
    kernelMode() {
      return window.Kernel.Mode;
    },
    plugins() {
      return this.mode === "simple" ? ["lists"] : [
        "lists",
        "advlist",
        "searchreplace",
        "visualblocks",
        "code",
        "fullscreen",
        "insertdatetime",
        "media",
        "table",
        "help",
        "wordcount"
      ];
    },
    invalidStyles() {
      return this.mode === "willbuilder" ? {
        "*": "color font-size height width background"
        // Global invalid styles
      } : {};
    },
    toolbar() {
      return this.mode === "simple" ? "undo redo | bold italic underline |            bullist numlist outdent indent | help" : this.mode === "willbuilder" ? "undo redo | formatselect | bold italic |            alignleft aligncenter alignright alignjustify |            bullist numlist outdent indent | removeformat | code help | visualblocks" : "undo redo | formatselect | bold italic backcolor |            alignleft aligncenter alignright alignjustify |            bullist numlist outdent indent | removeformat | code help";
    },
    init() {
      return {
        height: this.height,
        menubar: !1,
        plugins: this.plugins,
        toolbar: this.toolbar,
        content_style: this.contentStyle ?? void 0,
        invalid_styles: this.invalidStyles,
        base_url: "/tinymce",
        license_key: "gpl"
      };
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("rich-text-field", u);
  }
});
export {
  S as _
};
