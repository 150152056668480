// EntityHistory

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EntityHistory } from "@kinherit/sdk/model/entity-history";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const ReadHistory = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/history",
  method: "get",
  rootModel: EntityHistory,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});
