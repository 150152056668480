// Guardian

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Guardian } from "@kinherit/sdk/model/guardian";

export const UpdateGuardian = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/guardian/{guardian}",
  method: "patch",
  parse: (target: Guardian) => ({
    body: {
      sortOrder: target.$data.sortOrder,
      isReserve: target.$data.isReserve,
      includePartner: target.$data.includePartner,
      notes: target.$data.notes,
      person: target.$data.person,
      forPerson: target.$data.forPerson,
      kintin: target.$data.kintin,
    },
    params: {
      guardian: target,
      kintin: target.$data.kintin,
    },
  }),
});
