var w = Object.defineProperty;
var d = (e, n) => w(e, "name", { value: n, configurable: !0 });
import { _ as k } from "../../../component.layout/navbar-item/navbar-item.vue_vue_type_script_lang.js";
import { resolveComponent as $, resolveDirective as y, createElementBlock as r, openBlock as l, createBlock as v, createCommentVNode as a, createTextVNode as z, toDisplayString as m, normalizeClass as b, createElementVNode as f, createVNode as D, withKeys as s, withModifiers as u, renderSlot as S, Transition as C, withCtx as F, withDirectives as M, Fragment as I, renderList as B, vShow as N } from "vue";
import { _ as K } from "../../../_plugins/_plugin-vue_export-helper.js";
const O = ["aria-label", "aria-haspopup", "aria-expanded"], V = { key: 1 }, T = ["onKeydown", "onClick"];
function E(e, n, L, W, A, j) {
  const p = $("Icon"), g = y("tab-index");
  return e.menu === null ? (l(), r("div", {
    key: 0,
    class: "navbar__item is-clickable is-unselectable",
    role: "menuitem",
    onClick: n[0] || (n[0] = (o) => e.clickWrapper(o))
  }, [
    e.icon ? (l(), v(p, {
      key: 0,
      size: e.iconSize,
      icon: e.icon
    }, null, 8, ["size", "icon"])) : a("", !0),
    z(" " + m(e.label), 1)
  ])) : (l(), r("div", {
    key: 1,
    class: b(["navbar__item has-dropdown is-hoverable is-unselectable", {
      "is-open": e.compShowDropdown,
      "is-mobile-fixed-open": e.mobileFixedOpen
    }])
  }, [
    f("div", {
      ref: "refTopLevelButton",
      class: b(["navbar__item__link", {
        "is-arrowless": e.isArrowless
      }]),
      tabindex: 0,
      "aria-label": e.iconOnly ? e.label : void 0,
      role: "menuitem",
      "aria-haspopup": e.menu === null ? "false" : "true",
      "aria-expanded": e.compShowDropdown,
      onMouseover: n[1] || (n[1] = (o) => e.isMobile ? null : e.toggleMenu(!0)),
      onMouseout: n[2] || (n[2] = (o) => e.isMobile ? null : e.toggleMenu(!1)),
      onFocus: n[3] || (n[3] = (o) => e.isMobile ? null : e.toggleMenu(!0)),
      onClick: n[4] || (n[4] = (o) => e.isMobile ? e.toggleMenu(null) : null),
      onKeydown: [
        n[5] || (n[5] = s(u((o) => e.isMobile ? e.toggleMenu(null) : null, ["prevent"]), ["space"])),
        n[6] || (n[6] = s(u((o) => e.isMobile ? e.toggleMenu(null) : null, ["prevent"]), ["enter"])),
        n[8] || (n[8] = s(u((o) => e.setFocusIndex(0), ["prevent"]), ["down"]))
      ],
      onBlur: n[7] || (n[7] = (o) => e.toggleMenu(!1))
    }, [
      S(e.$slots, "default"),
      e.icon ? (l(), v(p, {
        key: 0,
        size: e.iconSize,
        icon: e.icon
      }, null, 8, ["size", "icon"])) : a("", !0),
      !e.iconOnly && e.label ? (l(), r("span", V, m(e.label), 1)) : a("", !0)
    ], 42, O),
    D(C, {
      name: e.isMobile ? "mobile-nav-open-close" : "nav-open-close"
    }, {
      default: F(() => [
        M(f("ul", {
          ref: "refDropDown",
          class: "navbar__item__dropdown",
          role: "menu",
          onMouseover: n[11] || (n[11] = (o) => e.isMobile ? null : e.toggleMenu(!0)),
          onMouseout: n[12] || (n[12] = (o) => e.isMobile ? null : e.toggleMenu(!1, !0))
        }, [
          (l(!0), r(I, null, B(e.menu, (o, t) => M((l(), r("li", {
            ref_for: !0,
            ref: "refDropDownItem",
            key: `navbar__item-${t}`,
            class: b(["navbar__item", {
              "is-clickable": "click" in o
            }]),
            "is-fullwidth": !0,
            role: "menuitem",
            onKeydown: [
              s(u((i) => e.setFocusIndex(t, i), ["prevent"]), ["up"]),
              s(u((i) => e.setFocusIndex(t, i), ["prevent"]), ["down"])
            ],
            onFocus: n[9] || (n[9] = (i) => e.toggleMenu(!0)),
            onBlur: n[10] || (n[10] = (i) => e.toggleMenu(!1)),
            onClick: /* @__PURE__ */ d((i) => e.clickWrapper(i, o), "onClick")
          }, [
            o.icon ? (l(), v(p, {
              key: 0,
              size: o.iconSize,
              icon: o.icon
            }, null, 8, ["size", "icon"])) : a("", !0),
            f("span", null, m(o.label), 1)
          ], 42, T)), [
            [g, t === e.focusIndex ? 0 : -1]
          ])), 128))
        ], 544), [
          [N, e.compShowDropdown || e.isMobile && e.mobileFixedOpen]
        ])
      ]),
      _: 1
    }, 8, ["name"])
  ], 2));
}
d(E, "_sfc_render");
const P = /* @__PURE__ */ K(k, [["render", E]]);
export {
  P as default
};
