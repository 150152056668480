import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultPrice } from "@kinherit/sdk";

export const RecordKinvaultPrice = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvaults/prices/{kinvaultPrice}",
  method: "get",
  parse: (message: string | KinvaultPrice) => ({
    params: {
      kinvaultPrice: message,
    },
  }),
  rootModel: KinvaultPrice,
});
