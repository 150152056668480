var D = Object.defineProperty;
var u = (e, a) => D(e, "name", { value: a, configurable: !0 });
import { _ as H } from "../../../_plugins/home/runner/work/workspace/workspace/library/framework/library/api/component.display/table/table.vue_vue_type_script_lang.tsx.js";
import { resolveComponent as m, resolveDirective as F, createElementBlock as t, openBlock as l, mergeProps as N, createCommentVNode as n, createElementVNode as r, createBlock as p, createTextVNode as B, resolveDynamicComponent as C, toDisplayString as b, renderSlot as k, withDirectives as R, normalizeClass as d, createVNode as f, Fragment as y, renderList as v, normalizeStyle as S, withModifiers as h, vShow as U } from "vue";
import { b as z } from "../../../_assets/component.display/table/table.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as L } from "../../../_plugins/_plugin-vue_export-helper.js";
const O = {
  key: 0,
  class: "table__header"
}, V = { class: "table__header__left" }, W = { class: "table-data-summary" }, A = { class: "table__header__right" }, j = { class: "table-controls" }, q = {
  key: 1,
  class: "table__header"
}, G = { class: "table__header__right" }, J = { class: "table-controls" }, K = {
  key: 0,
  class: "is-narrow"
}, Q = ["onClick"], X = ["title"], Y = { key: 1 }, Z = ["onClick"], I = { key: 0 }, _ = ["data-label"], x = ["innerHTML"], ee = ["innerHTML"], se = { key: 3 }, le = {
  key: 1,
  class: "details-toggle"
}, oe = ["onClick"], te = ["colspan"], ie = { key: 0 }, ae = ["colspan"], ne = { key: 1 }, re = { key: 0 }, de = ["onClick"], pe = { key: 0 }, be = ["innerHTML"], he = { key: 2 }, ue = { key: 1 }, ke = {
  key: 4,
  class: "table-footer-controls"
};
function ye(e, a, ge, me, Ce, ve) {
  var P, E;
  const w = m("Icon"), $ = m("SelectField"), c = m("CheckboxField"), T = m("Pagination"), M = F("tool-tip");
  return l(), t("div", N({
    class: ["table__wrapper", {
      "is-scrollable": e.isScrollable,
      "is-mobile": e.isMobile
    }]
  }, e.$data.$cypress), [
    (!e.computedRows.isEmpty() || e.isTestMode) && (e.totalRecords || e.enablePerPage || e.compEnableSortByOrderBy || e.copyToClipboard) && e.showHeaderControls ? (l(), t("div", O, [
      r("div", V, [
        r("div", W, [
          (l(), p(C(e.$t.local.summary(e.totalRecords, e.page, e.totalPages)))),
          B(" " + b(e.totalRecords && ((P = e.value) != null && P.length) ? " - " : "") + " ", 1),
          (l(), p(C(e.$t.local.selected((E = e.value) == null ? void 0 : E.length))))
        ])
      ]),
      r("div", A, [
        r("div", j, [
          k(e.$slots, "tableControls"),
          e.copyToClipboard ? R((l(), p(w, {
            key: 0,
            icon: "Copy",
            onClick: e.doCopyToClipBoard
          }, null, 8, ["onClick"])), [
            [M, e.$t.local.copyToClipboard]
          ]) : n("", !0),
          e.compEnableSortByOrderBy || e.isTestMode ? (l(), p($, {
            key: 1,
            value: e.computedSortBy,
            "onUpdate:value": a[0] || (a[0] = (s) => e.computedSortBy = s),
            "show-clear-button": !1,
            label: e.$t.local.sortBy,
            "auto-collapse-label": "",
            class: "table-sort-by",
            options: e.sortByOptions,
            size: "is-small",
            "prevent-clear": ""
          }, null, 8, ["value", "label", "options"])) : n("", !0),
          e.compEnableSortByOrderBy ? (l(), p($, {
            key: 2,
            value: e.computedSortDirection,
            "onUpdate:value": a[1] || (a[1] = (s) => e.computedSortDirection = s),
            class: "table-sort-direction",
            label: e.$t.local.orderBy,
            "auto-collapse-label": "",
            "show-clear-button": !1,
            options: { asc: "asc", desc: "desc" },
            size: "is-small",
            "prevent-clear": ""
          }, null, 8, ["value", "label"])) : n("", !0)
        ])
      ])
    ])) : e.$slots.tableControls ? (l(), t("div", q, [
      a[10] || (a[10] = r("div", { class: "table__header__left" }, null, -1)),
      r("div", G, [
        r("div", J, [
          k(e.$slots, "tableControls")
        ])
      ])
    ])) : n("", !0),
    e.title ? (l(), t("div", {
      key: 2,
      class: d(["table__title", { "is-narrow": e.isNarrow }])
    }, b(e.title), 3)) : n("", !0),
    e.title && e.subtitle ? (l(), t("div", {
      key: 3,
      class: d(["table__subtitle", { "is-narrow": e.isNarrow }])
    }, b(e.subtitle), 3)) : n("", !0),
    (l(), t("table", {
      key: `page-${e.page ?? 0}`,
      class: d({
        table: !0,
        "is-bordered": e.isBordered,
        "is-narrow": e.isNarrow,
        "is-hoverable": e.isHoverable && !e.computedRows.isEmpty(),
        "is-fullwidth": e.isFullwidth,
        "is-skeleton": e.isSkeleton,
        "is-fixed": e.isFixed,
        "has-clickable-rows": e.isClickable && !e.computedRows.isEmpty(),
        "is-aligned-middle": e.isAlignedMiddle,
        "is-striped": e.isStriped,
        [e.size]: !0
      })
    }, [
      r("thead", null, [
        r("tr", null, [
          e.showCheckboxColumn ? (l(), t("th", K, [
            f(c, {
              class: "table-select-all table__checkbox",
              size: e.isNarrow ? "is-small" : void 0,
              value: e.isChecked(),
              "onUpdate:value": a[2] || (a[2] = () => e.toggleRow())
            }, null, 8, ["size", "value"])
          ])) : n("", !0),
          (l(!0), t(y, null, v(e.computedColumns, (s, i) => (l(), t("th", {
            key: `header-${i}`,
            class: d({
              "table-is-sort-asc": e.sort.ascending && e.sort.column === i,
              "table-is-sort-desc": !e.sort.ascending && e.sort.column === i,
              "has-text-weight-bold": e.sort.column === i,
              "is-clickable": "sort" in s && s.sort === !0,
              "is-text-align-center": "align" in s && s.align === "center",
              "is-text-align-right": "align" in s && s.align === "right"
            }),
            style: S(s.width ? { width: s.width } : void 0),
            onClick: /* @__PURE__ */ u(() => e.sortColumn(i), "onClick")
          }, [
            s.abbr ? (l(), t("abbr", {
              key: 0,
              class: d(`${e.sort.column === i ? "has-text-weight-bold" : ""}`),
              title: s.title
            }, b(s.abbr), 11, X)) : (l(), t("span", {
              key: 1,
              class: d(`${e.sort.column === i ? "has-text-weight-bold" : ""}`)
            }, b(s.title), 3))
          ], 14, Q))), 128)),
          e.hasDetailsSlot && !e.isExpanded ? (l(), t("th", Y)) : n("", !0)
        ])
      ]),
      (l(!0), t(y, null, v(e.computedRows, (s, i) => (l(), t("tbody", {
        key: `row-${i}`,
        class: d(["table__record", e.getRowClasses(i)]),
        onClick: /* @__PURE__ */ u((o) => e.clickRow(i, s, o), "onClick")
      }, [
        r("tr", {
          class: d({
            "is-selected": e.isChecked(s)
          })
        }, [
          e.showCheckboxColumn ? (l(), t("td", I, [
            f(c, {
              class: d(`table-select-${i} table__checkbox`),
              value: e.isChecked(s),
              size: e.isNarrow ? "is-small" : void 0,
              "onUpdate:value": /* @__PURE__ */ u(() => e.toggleRow(s), "onUpdate:value")
            }, null, 8, ["class", "value", "size", "onUpdate:value"])
          ])) : n("", !0),
          (l(!0), t(y, null, v(e.computedColumns, (o, g) => (l(), t("td", {
            key: `row-${i}-column-${g}`,
            "data-label": `${o.title ?? ""}`,
            style: S(e.getBinding(o == null ? void 0 : o.style, s)),
            class: d([
              e.getBinding(o == null ? void 0 : o.class, s),
              {
                "is-text-align-center": "align" in o && o.align === "center",
                "is-text-align-right": "align" in o && o.align === "right",
                "minimise-width": "minimiseWidth" in o && o.minimiseWidth === !0
              }
            ])
          }, [
            "map" in o ? (l(), t(y, { key: 0 }, [
              B(b(o.map(s)), 1)
            ], 64)) : n("", !0),
            "mapHtml" in o && o.blockClicks === !0 ? (l(), t("span", {
              key: 1,
              onClick: a[3] || (a[3] = h(() => {
              }, ["stop"])),
              innerHTML: o.mapHtml(s)
            }, null, 8, x)) : "mapHtml" in o ? (l(), t("span", {
              key: 2,
              innerHTML: o.mapHtml(s)
            }, null, 8, ee)) : "field" in o ? (l(), t("span", se, b(s[o.field]), 1)) : "slot" in o && o.blockClicks ? (l(), t("span", {
              key: 4,
              onClick: a[4] || (a[4] = h(() => {
              }, ["stop"]))
            }, [
              k(e.$slots, o.slot, {
                index: i,
                row: s
              })
            ])) : "slot" in o ? k(e.$slots, o.slot, {
              key: 5,
              index: i,
              row: s
            }) : "component" in o && o.blockClicks ? (l(), t("span", {
              key: 6,
              onClick: a[5] || (a[5] = h(() => {
              }, ["stop"]))
            }, [
              (l(), p(C(o.component(s))))
            ])) : "component" in o ? (l(), p(C(o.component(s)), { key: 7 })) : n("", !0)
          ], 14, _))), 128)),
          e.hasDetailsSlot && !e.isExpanded ? (l(), t("td", le, [
            e.hasExpendSlot ? (l(), t("span", {
              key: 0,
              onClick: h(
                () => e.expanded.includes(i) ? e.expanded = e.expanded.remove(i) : e.expanded = [...e.expanded, i],
                ["stop"]
              )
            }, [
              k(e.$slots, "expand", {
                index: i,
                row: s
              })
            ], 8, oe)) : e.expanded.includes(i) ? (l(), p(w, {
              key: 2,
              class: "is-clickable is-inline-block pl-5",
              style: {
                width: "100%",
                height: "100%"
              },
              icon: "ChevronUp",
              size: "is-small",
              onClick: h((o) => e.expanded = e.expanded.remove(i), ["stop"])
            }, null, 8, ["onClick"])) : (l(), p(w, {
              key: 1,
              class: "is-clickable is-inline-block pl-5",
              style: {
                width: "100%",
                height: "100%"
              },
              icon: "ChevronDown",
              size: "is-small",
              onClick: h((o) => e.expanded.push(i), ["stop"])
            }, null, 8, ["onClick"]))
          ])) : n("", !0)
        ], 2),
        e.hasDetailsSlot && (e.isExpanded || e.expanded.includes(i)) ? (l(), t("tr", {
          key: 0,
          class: "table__record__details",
          onClick: a[6] || (a[6] = h(() => {
          }, ["stop"]))
        }, [
          r("td", {
            colspan: e.computedColumns.length + 1 + (e.showCheckboxColumn ? 1 : 0)
          }, [
            k(e.$slots, "details", {
              index: i,
              row: s
            })
          ], 8, te)
        ])) : n("", !0)
      ], 10, Z))), 128)),
      e.computedRows.isEmpty() ? (l(), t("tbody", ie, [
        r("tr", null, [
          r("td", {
            colspan: e.computedColumns.length + 1 + (e.showCheckboxColumn ? 1 : 0),
            class: "has-text-centered"
          }, [
            r("p", {
              class: d({
                "my-5": !e.compressEmptyMessage,
                "is-size-5": !0,
                "has-text-centered": !0
              })
            }, b(e.computedEmptyMessage), 3)
          ], 8, ae)
        ])
      ])) : n("", !0),
      !e.computedFooterColumns.isEmpty() && !e.computedRows.isEmpty() ? (l(), t("tfoot", ne, [
        r("tr", null, [
          e.showCheckboxColumn ? (l(), t("td", re, [
            f(c, {
              class: "table-select-all table__checkbox",
              size: e.isNarrow ? "is-small" : void 0,
              value: e.isChecked(),
              "onUpdate:value": a[7] || (a[7] = () => e.toggleRow())
            }, null, 8, ["size", "value"])
          ])) : n("", !0),
          (l(!0), t(y, null, v(e.computedFooterColumns, (s, i) => {
            var o, g;
            return l(), t("td", {
              key: `header-${i}`,
              class: d({
                "is-sort-asc": e.sort.ascending && e.sort.column === i,
                "is-sort-desc": !e.sort.ascending && e.sort.column === i,
                "has-text-weight-bold": e.sort.column === i,
                "is-clickable": "sort" in e.computedColumns[i] && e.computedColumns[i].sort === !0
              }),
              onClick: /* @__PURE__ */ u(() => e.sortColumn(i), "onClick")
            }, [
              R(r("span", {
                class: d(s.class),
                style: S(e.getStyle(s))
              }, [
                "value" in s ? (l(), t("span", pe, b(s.value), 1)) : "mapHtml" in s ? (l(), t("span", {
                  key: 1,
                  innerHTML: (o = s.mapHtml) == null ? void 0 : o.call(s, e.rows)
                }, null, 8, be)) : "map" in s ? (l(), t("span", he, b((g = s.map) == null ? void 0 : g.call(s, e.rows)), 1)) : n("", !0)
              ], 6), [
                [U, !e.shouldHide(s.hidden)]
              ])
            ], 10, de);
          }), 128)),
          e.hasDetailsSlot && !e.isExpanded ? (l(), t("td", ue)) : n("", !0)
        ])
      ])) : n("", !0)
    ], 2)),
    !e.computedRows.isEmpty() && e.showFooterControls ? (l(), t("div", ke, [
      e.enablePerPage && e.compShowPagination ? (l(), p($, {
        key: 0,
        value: e.computedPerPage,
        "onUpdate:value": a[8] || (a[8] = (s) => e.computedPerPage = s),
        class: "table-per-page",
        "show-clear-button": !1,
        label: "Rows Per Page",
        options: {
          15: "15",
          25: "25",
          50: "50",
          100: "100",
          500: "500"
        },
        "is-fullwidth": !1,
        size: "is-small"
      }, null, 8, ["value"])) : n("", !0),
      e.compShowPagination && e.computedPage !== null ? (l(), p(T, {
        key: 1,
        current: e.computedPage,
        "onUpdate:current": a[9] || (a[9] = (s) => e.computedPage = s),
        class: "table-pagination is-small",
        total: e.totalPages
      }, null, 8, ["current", "total"])) : n("", !0)
    ])) : n("", !0)
  ], 16);
}
u(ye, "_sfc_render");
typeof z == "function" && z(H);
const Pe = /* @__PURE__ */ L(H, [["render", ye]]);
export {
  Pe as default
};
