var m = Object.defineProperty;
var o = (d, e) => m(d, "name", { value: e, configurable: !0 });
import { ThemeColor as l } from "../theme/prop/color.js";
import { ThemeFontColor as a } from "../theme/prop/font-color.js";
import { ThemeIconName as r } from "../theme/prop/icon.js";
import { ThemeSide as f } from "../theme/prop/side.js";
import { ThemeSize as p } from "../theme/prop/size.js";
import { ValidationType as i } from "../validation/core/validation-type.js";
import { validate as g } from "../validation/validator.service.js";
import { DateTime as v, Time as b } from "@kinherit/ts-common";
import { defineComponent as y, ref as k } from "vue";
const B = /* @__PURE__ */ o((d) => y({
  options: d,
  props: {
    /**
     * ID for the input field
     */
    inputId: {
      type: String,
      default: null
    },
    /**
     * Autofocus the input field
     */
    autofocus: {
      type: Boolean,
      default: !1,
      value: "checkbox"
    },
    /**
     * If the field is in a dialog, for internal use with `OpenComponentDialog` function.
     */
    isComponentDialog: {
      type: Boolean,
      default: !1,
      value: "checkbox"
    },
    /**
     * Should the select span the full width of its parent
     */
    isFullwidth: {
      type: Boolean,
      default: !0,
      value: "checkbox"
    },
    /**
     * Name of the perdefined icon the display to the user
     */
    color: {
      type: String,
      default: l.isPlain,
      validator: /* @__PURE__ */ o((e) => Object.values(l).includes(e), "validator"),
      value: l
    },
    /**
     * Custom message
     */
    message: {
      type: String,
      default: null,
      value: "text"
    },
    /**
     * Custom message color
     */
    messageColor: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ o((e) => Object.values(a).includes(e), "validator"),
      value: a
    },
    /**
     * Prevent changes to the input box
     *
     * @values disabled, readonly
     */
    state: {
      type: String,
      default: null,
      value: ["disabled", "readonly"]
    },
    /**
     * Field label
     */
    label: {
      type: String,
      default: null,
      value: "text"
    },
    /**
     * List of validation to run when the field is updated
     */
    validators: {
      type: Array,
      default: /* @__PURE__ */ o(() => [], "default"),
      value: ["required", "email", "checked", "phone-number"]
    },
    /**
     * v-model:value
     */
    value: {
      type: [String, v, Date, b, Number, Object, Boolean],
      default: null
    },
    /**
     * Name of theme icon to use
     */
    iconLeft: {
      type: [String, Object],
      default: null,
      value: r
    },
    /**
     * Override the icon used by the validator
     */
    iconRightOverride: {
      type: [String, Object],
      default: null,
      value: r
    },
    /**
     * Text box size
     */
    size: {
      type: String,
      default: "is-normal",
      validator: /* @__PURE__ */ o((e) => Object.values(p).includes(e), "validator"),
      value: p
    },
    /**
     * Display a loading icon
     */
    isLoading: {
      type: Boolean,
      default: !1,
      value: "checkbox"
    },
    /**
     * Enable 'left/right' slots, with margins
     */
    isGrouped: {
      type: Boolean,
      default: !1,
      value: "checkbox"
    },
    /**
     * Float the field to the left or right (requires block as parent)
     */
    side: {
      type: String,
      validator: /* @__PURE__ */ o((e) => Object.values(f).includes(e), "validator"),
      default: null,
      value: f
    },
    /**
     * Force the validation to be show without watching for the field to be focused
     */
    showValidation: {
      type: Boolean,
      default: !1,
      value: "checkbox"
    },
    /**
     * Function to be called when the left icon is clicked
     */
    leftIconClick: {
      type: Function,
      default: null
    },
    /**
     * Function to be called when the right icon is clicked
     */
    rightIconClick: {
      type: Function,
      default: null
    },
    /**
     * Option to show the field's built in clear button
     */
    showClearButton: {
      type: Boolean,
      default: !1,
      value: "checkbox"
    },
    /**
     * Place holder message to be displayed when the field is empty
     */
    placeholder: {
      type: String,
      default: null,
      value: "text"
    },
    /**
     * Cause the validation value to be emitted on mount
     */
    isForm: {
      type: Boolean,
      default: !1,
      value: "checkbox"
    },
    /**
     * Override the touched and dirty state to hide the validation messages
     * This is cosmetic only, the validation will still run
     */
    hideValidation: {
      type: Boolean,
      default: !1,
      value: "checkbox"
    },
    /**
     * HTML5 autocomplete attribute
     */
    autocomplete: {
      type: String,
      default: null,
      value: ["on", "off", "current-password", "new-password", "username"]
    },
    /**
     * Collapses label into the input field
     */
    autoCollapseLabel: {
      type: Boolean,
      default: !1,
      value: "checkbox",
      validator: /* @__PURE__ */ o((e) => [!0, !1].includes(e), "validator")
    }
  },
  emits: {
    /**
     * Emits the new value
     *
     * @property {any}
     */
    "update:value": null,
    /**
     * Emits whether or not the input value has passed its validators
     *
     * @property {boolean}
     */
    "is-valid": null,
    /**
     * Emits when the input field is focused
     */
    focus: null,
    /**
     * Emits when the input field is blurred
     */
    blur: null,
    /**
     * Emits when the input field is cleared
     */
    clear: null,
    /**
     * Emits when the enter key is pressed
     */
    submit: null
  },
  setup() {
    return {
      input: k(null)
    };
  },
  data: /* @__PURE__ */ o(() => ({
    fieldMixin: {
      isGrouped: !1,
      showingComponentDialog: !1
    },
    messages: Array(),
    iconRight: null,
    touched: !1,
    hasFocus: !1
  }), "data"),
  computed: {
    computedShowValidation() {
      return (this.touched || this.showValidation) && !this.hideValidation;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("update:value", e);
      }
    },
    computedIconRight() {
      return this.iconRightOverride !== null ? this.iconRightOverride : this.iconRight;
    },
    computedId() {
      if (this.$attrs.id) return this.$attrs.id;
      if (this.inputId) return this.inputId;
      let e = this.$attrs.name ?? this.label ?? null;
      if (e)
        return e = e.trim().replace(/ +/g, "_").toLocaleLowerCase().replace(/[^a-zA-Z0-9_]+/g, "") + "_" + Math.floor(Math.random() * 1e4), e;
    },
    controlClasses() {
      const e = {
        "is-fullwidth": this.isFullwidth,
        "has-icons-right": this.computedIconRight !== null || this.showClearButton && this.computedValue !== null,
        "has-icons-left": this.iconLeft !== null,
        [this.size]: !0,
        "is-loading": this.isLoading
      };
      return Object.keys(e).filter((s) => e[s]).join(" ");
    },
    colorClass() {
      if (!this.computedShowValidation)
        return null;
      const e = [
        this.color,
        this.messageColor,
        ...this.messages.map((n) => n.class)
      ];
      let s = !1, t = !1, c = !1, u = !1;
      e.forEach((n) => {
        [a.hasTextDanger, l.isDanger].includes(n) && (s = !0), [a.hasTextWarning, l.isWarning].includes(
          n
        ) && (t = !0), [a.hasTextInfo, l.isInfo].includes(n) && (c = !0), [a.hasTextSuccess, l.isSuccess].includes(
          n
        ) && (u = !0);
      });
      let h = this.color;
      switch (!0) {
        case s:
          h = l.isDanger;
          break;
        case t:
          h = l.isWarning;
          break;
        case c:
          h = l.isInfo;
          break;
        case u:
          h = l.isSuccess;
          break;
      }
      return h;
    },
    computedHasValue() {
      return typeof this.computedValue == "string" ? this.computedValue.trim() !== "" : this.computedValue !== null && this.computedValue !== void 0;
    },
    inputClasses() {
      const e = {
        [this.size]: !0,
        [this.colorClass ?? "color-class"]: this.colorClass !== null
      };
      return Object.keys(e).filter((s) => e[s]).join(" ");
    },
    wrapperClasses() {
      const e = {
        [this.side]: this.side !== null,
        "is-component-dialog": this.isComponentDialog,
        "is-readonly": this.state === "readonly",
        "is-disabled": this.state === "disabled"
      };
      return Object.keys(e).filter((s) => e[s]).join(" ");
    },
    computedhasAddons() {
      return (this.showRightControl === !0 || this.showLeftControl === !0) && this.computedIsGrouped === !1;
    },
    computedIsGrouped() {
      return (this.showRightControl === !0 || this.showLeftControl === !0) && (this.isGrouped === !0 || this.fieldMixin.isGrouped === !0);
    },
    showLeftControl() {
      return [Object.keys(this.$slots).includes("left")].includes(!0);
    },
    showRightControl() {
      return [Object.keys(this.$slots).includes("right")].includes(!0);
    },
    computedMessages() {
      return this.message === null ? this.messages : this.messages.concat({
        message: this.message,
        class: this.messageColor ?? l.isPlain
      });
    }
  },
  watch: {
    computedValue: {
      deep: !0,
      handler() {
        const e = this.computedValue;
        this.validate(e);
      }
    },
    showValidation: {
      deep: !1,
      handler() {
        if (this.touched)
          return;
        if (this.showValidation) {
          const t = this.computedValue;
          this.validate(t);
        }
      }
    }
  },
  mounted() {
    if (this.isForm && this.$nextTick(() => this.emitValid(this.computedValue)), this.showValidation && this.validate(this.computedValue), this.autofocus) {
      const e = this.$el.getElementsByTagName("input")[0];
      setTimeout(() => {
        e == null || e.focus();
      }, 0);
    }
  },
  methods: {
    emitValid(e) {
      const s = g({
        label: this.label,
        validator: this.validators,
        value: e,
        input: this.input
      });
      return this.$emit(
        "is-valid",
        s.find(
          (t) => [i.Error].includes(t.type)
        ) === void 0
      ), s;
    },
    async validate(e) {
      await this.$nextTick();
      const s = this.emitValid(e);
      if (!this.computedShowValidation) {
        this.messages = [];
        return;
      }
      const t = Array();
      switch (this.messages = s.map((c) => {
        let u = "";
        switch (c.type) {
          case i.Success:
            u = a.hasTextSuccess, t.push(i.Success);
            break;
          case i.Warning:
            u = a.hasTextWarning, t.push(i.Warning);
            break;
          case i.Error:
            u = a.hasTextDanger, t.push(i.Error);
            break;
          case i.Info:
            u = a.hasTextInfo, t.push(i.Info);
            break;
        }
        return {
          class: u,
          message: c.message
        };
      }), !0) {
        case t.includes(i.Error):
          this.iconRight = r.Danger;
          break;
        case t.includes(i.Warning):
          this.iconRight = r.WarningTriangle;
          break;
        case t.includes(i.Info):
          this.iconRight = r.Info;
          break;
        case t.includes(i.Success):
          this.iconRight = r.Success;
          break;
        default:
          this.iconRight = null;
          break;
      }
    },
    focus(e) {
      this.$emit("focus", e), this.touched = !0, setTimeout(() => this.validate(this.computedValue), 200), this.hasFocus = !0;
    },
    blur(e) {
      this.hasFocus = !1, this.$emit("blur", e);
    },
    clear() {
      this.computedValue = null, this.$emit("clear");
    },
    keydown(e) {
      e.key === "Enter" && this.$emit("submit", e);
    }
  }
}), "FieldMixin");
export {
  B as FieldMixin
};
