var n = Object.defineProperty;
var i = (s, o) => n(s, "name", { value: o, configurable: !0 });
import { _ as d } from "../../../component.layout/grid/grid.vue_vue_type_script_lang.js";
import { createElementBlock as e, openBlock as t, createCommentVNode as a, Fragment as m, renderList as u, normalizeClass as $, renderSlot as l } from "vue";
import { _ as c } from "../../../_plugins/_plugin-vue_export-helper.js";
const p = { class: "grid columns is-multiline" }, f = {
  key: 0,
  class: "has-text-right buttons"
};
function b(s, o, h, k, _, g) {
  return t(), e("div", p, [
    (t(!0), e(m, null, u(s.slots, (r) => (t(), e("div", {
      key: `slot-${r}`,
      class: $(`column is-${s.fullhd}-fullhd is-${s.widescreen}-widescreen is-${s.desktop}-desktop is-${s.tablet}-tablet is-${s.mobile}-mobile`)
    }, [
      l(s.$slots, r)
    ], 2))), 128)),
    s.$slots.buttons ? (t(), e("div", f, [
      l(s.$slots, "buttons")
    ])) : a("", !0)
  ]);
}
i(b, "_sfc_render");
const C = /* @__PURE__ */ c(d, [["render", b]]);
export {
  C as default
};
