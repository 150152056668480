<template>
  <RouterChildView @save-callscript="saveCallscript" />
</template>
<!--
  <cypress-wrapper lang="json">
  {
    "name": "KinvaultKintinDetailsCallScriptWrapper",
    "route": "KinvaultKintinDetailsCallScript",
    "imports": {
      "KinvaultKintinDetailsCallScriptSidebarWrapper": "@/module/kinvault.kintin/page/details/call-script/KinvaultKintinDetails.CallScript.Sidebar.test"
    },
    "methods": {
      "sidebar": {
        "type": "to-one",
        "selector": "",
        "wrapper": "KinvaultKintinDetailsCallScriptSidebarWrapper"
      }
    }
  }
  </cypress-wrapper> -->

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { SnackBarService } from "@/service/snack-bar.service";
import { defineComponent } from "vue";
import {
  KintinDetailsCallScript2Params,
  KintinDetailsCallScript2Route,
} from ".";

export default defineComponent({
  name: KintinDetailsCallScript2Route,
  components: { RouterChildView },
  mixins: [KinvaultKintinDetailsMixin, SnackBarService.mixin],
  async beforeRouteEnter(to): Promise<void> {
    const params = to.params as KintinDetailsCallScript2Params;
    // await window.Kernel.ActionBus.kinvaultKintin.callScript.record(
    await window.Kernel.ActionBus2.portal.kinvault.callScript.RecordCallScriptData(
      {
        message: params.kintin,
      },
    );
  },
  methods: {
    async saveCallscript(): Promise<void> {
      if (!this.kintin) {
        return;
      }

      await this.$actionBus.callScript.UpdateCallScript(this.kintin.callScript);

      this.$snackBar.success.saved();
    },
  },
});
</script>
