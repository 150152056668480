// BrandedKinvaultMembership

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultMembership } from "@kinherit/sdk/model/branded-kinvault-membership";

export const UpdateBrandedKinvaultMembership = DefineWriteAction({
  interface: "portal",
  resource:
    "/v2/portal/admin/user/{user}/branded-kinvault-membership/{brandedKinvaultMembership}",
  method: "patch",
  parse: (target: BrandedKinvaultMembership) => ({
    body: {
      allowAccess: target.$data.allowAccess,
      user: target.$data.user,
      brandedKinvault: target.$data.brandedKinvault,
    },
    params: {
      brandedKinvaultMembership: target,
      user: target.user,
    },
  }),
});
