var k = Object.defineProperty;
var p = (e, u) => k(e, "name", { value: u, configurable: !0 });
import { _ as f } from "../../../component.input/base-input-field/base-input-field.vue_vue_type_script_lang.js";
import { resolveComponent as t, createElementBlock as a, openBlock as o, normalizeClass as l, createBlock as n, withCtx as r, createCommentVNode as s, createElementVNode as m, renderSlot as i, Fragment as y, resolveDynamicComponent as b } from "vue";
import { b as c } from "../../../_assets/component.input/base-input-field/base-input-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as g } from "../../../_plugins/_plugin-vue_export-helper.js";
const v = {
  key: 0,
  class: "input-field__control-left input-field__control"
}, w = {
  key: 1,
  class: "input-field__control-right input-field__control"
}, z = { key: 1 };
function I(e, u, $, F, V, L) {
  const d = t("Icon"), h = t("Field");
  return o(), a("div", {
    class: l(`${e.wrapperClasses} input-field`)
  }, [
    e.dropdownOnly ? (o(), a("div", z, [
      i(e.$slots, "dropdown")
    ])) : (o(), n(h, {
      key: 0,
      label: e.label,
      "has-addons": e.computedhasAddons,
      "is-grouped": e.computedIsGrouped,
      messages: e.computedMessages,
      size: e.size,
      "auto-collapse-label": e.autoCollapseLabel,
      "has-focus": e.hasFocus,
      "has-value": e.computedHasValue,
      "input-id": e.computedId
    }, {
      below: r(() => [
        i(e.$slots, "below")
      ]),
      default: r(() => [
        e.showLeftControl ? (o(), a("div", v, [
          i(e.$slots, "left", { value: e.computedValue })
        ])) : s("", !0),
        m("div", {
          class: l(`input-field__control ${e.controlClasses} is-expanded`)
        }, [
          e.iconLeft && (!e.autoCollapseLabel || e.autoCollapseLabel && e.hasFocus || e.computedHasValue) ? (o(), n(d, {
            key: 0,
            size: e.size,
            icon: e.iconLeft,
            class: l(`is-left ${e.leftIconClick ? "is-clickable" : ""}`),
            onClick: e.leftIconClick
          }, null, 8, ["size", "icon", "class", "onClick"])) : s("", !0),
          e.isLoading ? s("", !0) : (o(), a(y, { key: 1 }, [
            e.showClearButton && e.computedValue !== null && e.computedValue !== "" ? (o(), n(d, {
              key: 0,
              icon: "Clear",
              size: e.size,
              class: "field-clear-button is-right is-clickable",
              onClick: e.clear
            }, null, 8, ["size", "onClick"])) : e.computedIconRight ? (o(), n(d, {
              key: 1,
              icon: e.computedIconRight,
              size: e.size,
              class: l(`is-right ${e.rightIconClick ? "is-clickable" : ""}`),
              onClick: e.rightIconClick
            }, null, 8, ["icon", "size", "class", "onClick"])) : s("", !0)
          ], 64)),
          e.inputTag !== !1 ? (o(), n(b(e.inputTag), {
            key: 2,
            id: e.computedId,
            ref: "input",
            "cy-ref": e.computedCyRef,
            value: e.computedValue,
            class: l([e.inputTag, e.inputClasses, e.compClassMaxLength]),
            type: e.type,
            placeholder: !e.autoCollapseLabel || e.hasFocus ? e.placeholder ?? void 0 : void 0,
            min: e.min,
            max: e.max,
            step: e.step,
            disabled: e.state === "disabled",
            readonly: e.state === "readonly",
            autofocus: e.autofocus,
            autocomplete: e.autocomplete,
            onfocus: e.onFocusString,
            onInput: u[0] || (u[0] = (C) => e.computedValue = C.target.value),
            onFocus: e.computeDropdownPosition,
            onBlur: e.blur,
            onKeydown: e.keydown
          }, null, 40, ["id", "cy-ref", "value", "class", "type", "placeholder", "min", "max", "step", "disabled", "readonly", "autofocus", "autocomplete", "onfocus", "onFocus", "onBlur", "onKeydown"])) : s("", !0),
          m("div", {
            class: l({
              "input-field__dropdown": !0,
              "is-opened-top": e.dropdownPosition === "above"
            })
          }, [
            i(e.$slots, "dropdown")
          ], 2)
        ], 2),
        e.showRightControl ? (o(), a("div", w, [
          i(e.$slots, "right", { value: e.computedValue })
        ])) : s("", !0)
      ]),
      _: 3
    }, 8, ["label", "has-addons", "is-grouped", "messages", "size", "auto-collapse-label", "has-focus", "has-value", "input-id"]))
  ], 2);
}
p(I, "_sfc_render");
typeof c == "function" && c(f);
const H = /* @__PURE__ */ g(f, [["render", I]]);
export {
  H as default
};
