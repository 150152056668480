// Beneficiary

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Beneficiary } from "@kinherit/sdk/model/beneficiary";

export const RecordBeneficiary = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/beneficiary/{beneficiary}",
  method: "get",
  rootModel: Beneficiary,
  parse: (beneficiary: Beneficiary) => ({
    params: {
      beneficiary,
      kintin: beneficiary.$data.kintin,
    },
  }),
});
