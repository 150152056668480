import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { ContactPartnerPortalPermissions } from "@kinherit/sdk";

const permissionOptions = {
  any: "Any",
  own: "Own",
  none: "No Access",
};

export const IntroducerContactPartnerPortalSettingsForm = (
  permissions: ContactPartnerPortalPermissions,
) =>
  defineForm({
    name: "introducer-contact-partner-portal-settings",
    data: () => permissions,
    formAreas: (data) => [
      defineFormArea({
        name: "contact-details",
        data,
        template: GridLayout([
          ["clientsRead"],
          ["clientsCreate"],
          ["feesRead"],
          ["invitationsCreate"],
          ["allowKinvaultAccessRequest"],
        ]),
        components: () => ({
          clientsRead: [
            FormRadioField({
              props: {
                label: "Clients",
                message:
                  "Whether they can access all clients, or just their own",
                options: permissionOptions,
              },
              models: {
                value: "clientsRead",
              },
            }),
          ],
          clientsCreate: [
            FormCheckboxField({
              props: {
                label: "Can Refer Clients",
                message:
                  "Whether they can create new referrals via the main referral form",
              },
              models: {
                value: "clientsCreate",
              },
            }),
          ],
          feesRead: [
            FormRadioField({
              props: {
                label: "Fees",
                message:
                  "Allow access to the fees module: reserved for future use",
                options: permissionOptions,
              },
              models: {
                value: "feesRead",
              },
            }),
          ],
          invitationsCreate: [
            FormCheckboxField({
              props: {
                label: "Can Send Invitations",
                message:
                  "Whether they can send invitations to new clients for standalone kinvault access",
              },
              models: {
                value: "invitationsCreate",
              },
            }),
          ],
          allowKinvaultAccessRequest: [
            FormCheckboxField({
              props: {
                label: "Allow Kinvault Access Request",
                message:
                  "Whether they can request access to a client's Kinvault",
              },
              models: {
                value: "allowKinvaultAccessRequest",
              },
            }),
          ],
        }),
      }),
    ],
  });
