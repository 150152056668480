<template>
  <div
    :class="{
      'sidebar-page': true,
      'is-collapsed': hideSidebar,
    }"
  >
    <slot name="above" />
    <PageHeader
      :has-context-menu="hasContextMenu"
      :name="name"
      :avatar-name="avatarName"
      :title="title"
      :subtitle="subtitle"
      @context-menu="$emit('context-menu')"
    />
    <slot name="below" />
    <Tabs
      v-if="tabs.length > 0"
      v-model:tab="computedTab"
      class="sidebar-page__tabs"
      :config="tabs"
      :is-fullwidth="false"
    />
    <div
      class="sidebar-page__sidebar--content"
      :style="{
        display: showSidebar ? 'grid' : 'block',
      }"
    >
      <div v-if="showSidebar" class="sidebar-page__sidebar">
        <Button
          :icon-left="hideSidebar ? 'ChevronRight' : 'ChevronLeft'"
          :aria-label="hideSidebar ? 'Show sidebar' : 'Hide sidebar'"
          class="sidebar-page__sidebar__hide"
          style="
            background-color: transparent;
            border: none;
            z-index: 1;
            margin-bottom: -40px;
          "
          @click="toggleSidebar"
        />
        <span>
          <slot name="sidebar" />
        </span>
      </div>
      <div class="sidebar-page__content">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PageHeader from "@/shared/component/misc/PageHeader.vue";
import { TabOptions, Tabs } from "@kinherit/framework/component.display/tabs";
import Button from "@kinherit/framework/component.input/button";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "SidebarPage",
  components: {
    PageHeader,
    Tabs,
    Button,
  },
  props: {
    hasContextMenu: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String as PropType<string | null>,
      default: null,
    },
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    subtitle: {
      type: String as PropType<string | null>,
      default: null,
    },
    avatarName: {
      type: String as PropType<string | null>,
      default: null,
    },
    tabs: {
      type: Array as PropType<Array<TabOptions>>,
      default: () => [],
    },
    tab: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  emits: ["context-menu", "update:tab"],
  data: () => ({
    hideSidebar: localStorage.getItem("hideSidebar") === "true",
  }),
  computed: {
    showSidebar(): boolean {
      return !!this.$slots.sidebar;
    },
    computedTab: {
      get(): number | undefined {
        return this.tab ?? undefined;
      },
      set(value: number | null): void {
        this.$emit("update:tab", value);
      },
    },
  },
  methods: {
    toggleSidebar(): void {
      this.hideSidebar = !this.hideSidebar;
      localStorage.setItem("hideSidebar", this.hideSidebar.toString());
    },
  },
});
</script>

<style lang="scss">
.sidebar-page {
  &.is-collapsed {
    @include from($tablet) {
      .sidebar-page__sidebar--content {
        grid-template-columns: minmax(44px, 44px);
      }

      .sidebar-page__sidebar {
        width: 44px;
        > *:not(:first-child) {
          display: none;
        }
      }
    }
  }

  @include until($tablet) {
    .sidebar-page__sidebar__hide {
      display: none;
    }
  }

  &__sidebar--content {
    display: grid;
    gap: $block-spacing;
    grid-template-areas: "sidebar" "content";
    margin-top: 1.5em; // $block-spacing;

    @include from($tablet) {
      grid-template-areas: "sidebar content";
      grid-template-columns: minmax(240px, 1fr) 5fr;
    }
  }

  &__sidebar {
    @include from($tablet) {
      grid-area: sidebar;
      position: sticky;
      top: 0;
      height: fit-content;
    }

    &__hide {
      float: right;
    }
  }

  &__content {
    grid-area: content;
    min-width: 0; //hack for chartjs canvas to not overflow
  }
}
</style>
