// IntroducerCompany, Profile, EmailAddress, PhoneNumber, IntroducerNetwork

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const CreateIntroducerCompany = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/company",
  method: "post",
  parse: (target: IntroducerCompany) => ({
    body: {
      description: target.$data.description,
      kintinXeroContactId: target.$data.kintinXeroContactId,
      lastConversation: target.$data.lastConversation,
      trustRegXeroContactId: target.$data.trustRegXeroContactId,
      kintinCount: target.$data.kintinCount,
      contractNotes: target.$data.contractNotes,
      profile: {
        title: target.profile.title?.id,
        firstName: target.profile.firstName,
        middleNames: target.profile.middleNames,
        lastName: target.profile.lastName,
        knownAs: target.profile.knownAs,
        newsletterCampaigns: target.profile.newsletterCampaigns,
        suffix: target.profile.suffix,
        organisationName: target.profile.organisationName,
        organisationNumber: target.profile.organisationNumber,
        jobTitle: target.profile.jobTitle,
        emails: target.profile.emails.map((email) => ({
          email: email.email,
          primary: email.primary,
        })),
        phoneNumbers: target.profile.phoneNumbers.map((phoneNumber) => ({
          tel: phoneNumber.tel,
          primary: phoneNumber.primary,
        })),
      },
      defaultEstatePlanner: target.$data.defaultEstatePlanner,
      defaultLegalAssistant: target.$data.defaultLegalAssistant,
      type: target.$data.type,
      stage: target.$data.stage,
      network: target.$data.network,
      status: target.$data.status,
      kinvaultStatus: target.$data.kinvaultStatus,
      seniorAdviser: target.$data.seniorAdviser,
      introducedBy: target.$data.introducedBy,
      xeroContact: target.$data.xeroContact,
    },
  }),
  after: ({ message }) => {
    message.$delete({
      profile: {
        emails: true,
        phoneNumbers: true,
      },
    });
  },
});
