var l = Object.defineProperty;
var n = (e, o) => l(e, "name", { value: o, configurable: !0 });
import m from "../../_vue/component.display/icon/icon.js";
import p from "../../_vue/component.form/field/field.js";
import { FieldMixin as r } from "../../component.mixin/field-mixin.js";
import { CypressHelper as c } from "../../cypress/component-helpers.js";
import { defineComponent as u } from "vue";
import d from "../../_vue/component.input/address-lookup-field/Vue3GoogleAddressAutocomplete.js";
const b = u({
  name: "PinFieldComponent",
  components: { Field: p, Icon: m, GoogleAddressAutocomplete: d },
  mixins: [r()],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  emits: ["blur", "update:value"],
  data: /* @__PURE__ */ n((e) => ({
    $cypress: c({ ctx: e, labelField: "label" }),
    address: ""
  }), "data"),
  methods: {
    callbackFunction(e) {
      this.$emit("update:value", {
        line1: [
          this.getComponent(e, "street_number"),
          this.getComponent(e, "route")
        ].filter(Boolean).join(" "),
        line2: "",
        city: this.getComponent(e, "postal_town") ?? this.getComponent(e, "locality"),
        state: this.getComponent(e, "locality"),
        postcode: this.getComponent(e, "postal_code"),
        country: this.getComponent(e, "country", "short")
      });
    },
    getComponent(e, o, i = "long") {
      var t;
      return ((t = e.address_components.find(
        (s) => s.types.includes(o)
      )) == null ? void 0 : t[`${i}_name`]) ?? null;
    }
  }
});
export {
  b as _
};
