// Profile, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Profile } from "@kinherit/sdk/model/profile";

export const ReadProfiles = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/email-campaign/profile",
  method: "get",
  rootModel: Profile,
  parse: () => ({}),
});
