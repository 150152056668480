import {
  CreatedAtField,
  IntroducerCompanyField,
  IntroducerContactField,
  KintinField,
  SharedFilterProps,
} from "@/config/form.config";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";

export const ReadKinvaultAccessRequestsForm = (): FormBuilder<
  () => {
    kintins: string[];
    contacts: string[];
    companies: string[];
    created: null | [number, number];
  },
  string
> => {
  return MasterListFiltersForm({
    name: "read-kinvault-access-requests",
    data: () => ({
      kintins: [] as string[],
      contacts: [] as string[],
      companies: [] as string[],
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      kintins: [
        KintinField({
          props: {
            label: "Kintin",
            isMultiSelect: true,
            vModel: "kintins",
          },
          simplifyData: true,
        }),
      ],
      contacts: [
        IntroducerContactField({
          props: {
            label: "Contact",
            isMultiSelect: true,
            vModel: "contacts",
          },
          simplifyData: true,
        }),
      ],
      companies: [
        IntroducerCompanyField({
          props: {
            label: "Company",
            isMultiSelect: true,
            vModel: "companies",
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
