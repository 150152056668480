// Subscription, PhysicalStorageFile, Product, PhysicalStorageFileLog

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { PhysicalStorageSubscription } from "@kinherit/sdk";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const DeleteSubscription = DefineDeleteAction({
  interface: "portal",
  resource:
    "/v2/portal/kinvault/{kintin}/physical-storage-subscription/{physicalStorageSubscription}",
  method: "delete",
  parse: (message: {
    kintin: Kintin | string;
    physicalStorageSubscription: PhysicalStorageSubscription | string;
  }) => ({
    params: {
      kintin: message.kintin,
      physicalStorageSubscription: message.physicalStorageSubscription,
    },
  }),
  after: ({ message }) => {
    PhysicalStorageSubscription.$delete(message.physicalStorageSubscription);
  },
});
