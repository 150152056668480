// Adviser

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk";
import { Adviser } from "@kinherit/sdk/model/adviser";

export const ReadAdviser = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/adviser",
  method: "get",
  rootModel: Adviser,
  parse: (kintin: Kintin) => ({
    params: {
      kintin,
    },
  }),
});
