import { Portal2CpdIntroducer } from "@/action-bus/portal/cpd/introducer";
import { Portal2CpdRegister } from "@/action-bus/portal/cpd/register";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateEmailCampaign } from "./create-email-campaign.action";
import { DeleteCpdSession } from "./delete-cpd-session.action";
import { ReadIntroducerContacts } from "./read-introducer-contacts.action";
import { RecordCpdSession } from "./record-cpd-session.action";
import { UpdateCpdSession } from "./update-cpd-session.action";

export const Portal2Cpd = DefineActionBus({
  name: "portal-cpd",
  actions: {
    DeleteCpdSession,
    UpdateCpdSession,
    RecordCpdSession,
    ReadIntroducerContacts,
    CreateEmailCampaign,
    introducer: Portal2CpdIntroducer,
    register: Portal2CpdRegister,
  },
});
