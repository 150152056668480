import { defineComponent as n } from "vue";
const t = n({
  name: "DebugMixin",
  props: {
    debug: {
      type: Boolean,
      default: !1
    }
  },
  methods: {
    $log(e, ...o) {
      this.debug && console.log(this.$options.name, e, ...o);
    }
  }
});
export {
  t as DebugMixin
};
