import { OptionService } from "@/module/core/service/option.service";
import { Check, Option, Role, Tag, User } from "@kinherit/sdk";
import { nextTick } from "vue";

interface CompleteSessionResponse {
  [key: string]: never;
}

export const CompleteSessionHandler =
  async (): Promise<CompleteSessionResponse> => {
    Option.$purge();
    Role.$purge();
    Tag.$purge();
    Check.$purge();
    User.$purge();

    await Promise.all([
      OptionService.loadOptions(),
      // window.Kernel.ActionBus.execute("core/select/role/read", {}),
      await window.Kernel.ActionBus2.core.select.ReadRole({}),
      // window.Kernel.ActionBus.execute("core/select/tag/read", {}),
      await window.Kernel.ActionBus2.core.select.ReadTag({}),
      // window.Kernel.ActionBus.execute("core/select/check/read", {}),
      await window.Kernel.ActionBus2.core.select.ReadCheck({}),
      // window.Kernel.ActionBus.execute("core/session/read", {}),
      await window.Kernel.ActionBus2.auth.session.ReadSession(),
    ]);

    nextTick(() => {
      window.Kernel.ComponentOptions.Navbar.callRefresh();
    });

    return {};
  };
