<template>
  <OrdersPage
    v-if="kintin"
    :kintin="kintin"
    :has-write-permission="hasWritePermission"
    @create-order="$emit('create-order')"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsOrderWrapper",
  "route": "KintinDetailsOrder",
  "extends": {
    "name": "OrdersPageWrapper",
    "path": "@/shared/component/order/OrdersPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { AuthService } from "@/service/auth.service";
import OrdersPage from "@/shared/component/order/OrdersPage.vue";
import { defineComponent } from "vue";
import { KintinDetailsOrderRoute } from ".";

export default defineComponent({
  name: KintinDetailsOrderRoute,
  components: {
    OrdersPage,
  },
  mixins: [KinvaultKintinDetailsMixin, AuthService.mixin()],
  emits: ["create-order"],
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("kintin:write");
    },
  },
});
</script>
