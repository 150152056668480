import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { DeleteCpdSessionRegistration } from "./delete-cpd-session-registration.action";
import { ReadIntroducerContact } from "./read-introducer-contact.action";
import { UpdateCpdSessionRegistration } from "./update-cpd-session-registration.action";

export const Portal2CpdRegister = DefineActionBus({
  name: "portal-cpd-register",
  actions: {
    DeleteCpdSessionRegistration,
    ReadIntroducerContact,
    UpdateCpdSessionRegistration,
  },
});
