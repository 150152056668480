import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateFile } from "./create-file.action";
import { DeleteFile } from "./delete-file.action";
import { DownloadFile } from "./download-file.action";
import { ReadFile } from "./read-file.action";
import { UpdateFile } from "./update-file.action";
import { UploadFile } from "./upload-file.action";

export const Portal2KinvaultFileLog = DefineActionBus({
  name: "portal-kinvault-file-log",
  actions: {
    ReadFile,
    CreateFile,
    UpdateFile,
    DeleteFile,
    DownloadFile,
    UploadFile,
  },
});
