<template>
  <div v-if="kintin">
    <CallScriptSection title="Lasting Power of Attorneys">
      <Message title="Reminders" title-size="is-4" class="mb-4">
        <ul class="mt-0 mb-0">
          <li v-if="checkIsYes('3.0.0a')">Customer DOES own family home.</li>
          <li v-else>Customer DOES NOT own family home.</li>
          <li v-if="checkIsYes('2.4.4b')">
            Sole Jurisdiction is England & Wales
          </li>
          <li v-else>Sole Jurisdiction is NOT England & Wales!</li>
          <li v-if="!checkIsYes('2.4.4b')">
            SCOTLAND – powers have different names (and can be combined), with a
            letter via Legacy to set them up (not a form), and potentially pay
            just one lot of registration fees.
            <br />- Key difference is that customer needs to be guided to asking
            their DOCTOR to sign certificate (ie not friend cert provider).
            <br />-
            <a
              href="https://www.publicguardian-scotland.gov.uk/power-of-attorney"
              target="_blank"
              >https://www.publicguardian-scotland.gov.uk/power-of-attorney</a
            >
            <br />
            NORTHERN IRELAND – still using old Enduring Power of Attorney
            (single document, very flexible re wishes in different situations)
            with an archaic framework (1989).
            <br />- Key difference is that notice of intent to put in place an
            EPA needs to be given(posted) to “at least 3 relatives” in the
            order: spouse, kids, parents, siblings etc... <br />-
            <a
              href="https://www.justice-ni.gov.uk/publications/epa-notes-and-forms"
              target="_blank"
              >https://www.justice-ni.gov.uk/publications/epa-notes-and-forms</a
            >
          </li>
        </ul>
      </Message>

      <b
        >So we have talked through what you want in your Will, and how we can
        protect the assets your family inherit - even after death.
      </b>
      <br /><br />
      <b>
        The final risk we need to cover is you being alive, but incapacitated.
        It could be due to an illness like Alzheimer’s, or the simple misfortune
        of say a car accident.
      </b>
      <br /><br />
      <b>
        A Lasting Power of Attorney lets you name the people who will talk on
        your behalf, if you can’t. And this really important because – without
        one, the local authority and courts decide.
      </b>
      <br />
      <br />
      <b
        >There are two types of Lasting Power of Attorney:People normally focus
        on the one that deals with health decisions, because they want to be
        sure their next-of-kin have an input into where care is given, and the
        treatment options.
      </b>
      <br /><br />
      <b
        >But the second type deals with finance decisions, and for the family
        this can be even more critical because otherwise your assets are
        essentially ‘frozen’, other than what the local authority chooses to
        charge on care.
      </b>
      <br /><br />
      <b
        >So these are insurance against an event that could significantly affect
        you during your life.</b
      >
      <br /><br />

      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1d"
        :kintin="kintin"
        :on="{
          yes: {
            say: [
              'Can I double-check, were they set up since the law changed in 2017?',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        label="Setup up LPAs"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1e"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <!-- <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advAttorneyenNotes"
      :has-continue="false"
    /> -->
  </div>
</template>

<script lang="ts">
// import AttorneysContainer from "@/module/kinvault.kintin/component/address-book/AttorneysContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
// import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { KintinDetailsCallScript2Call2LPAsRoute } from "@/module/kinvault.kintin/page/details/call-script-2";
import Message from "@kinherit/framework/component.display/message";
import { defineComponent } from "vue";

export default defineComponent({
  name: KintinDetailsCallScript2Call2LPAsRoute,
  components: {
    ConfirmCheck,
    CallScriptSection,
    Message,
    // CallScriptSpecialistNotesSection,
    // AttorneysContainer,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
});
</script>
