<template>
  <div class="kintin-master-list-qc-to-check">
    <PageHeader htag="h1" text="QC to Check" />
    <KintinMasterList
      v-if="$data.tag"
      hide-title
      :show-filters="false"
      :is-escalated="false"
      :is-active="true"
      :persist-state="false"
      :tag="$data.tag"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinMasterListQcToCheckWrapper",
  "route": "KintinMasterListQcToCheck",
  "selector": ".kintin-master-list-qc-to-check",
  "imports": {
    "ReadKintinFormWrapper": "@/module/kinvault.kintin/form/read-kintin.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadKintinFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import KintinMasterList from "@/module/kinvault.kintin/page/KintinMasterList.vue";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { KintinMasterListQcToCheckRoute } from ".";
import { Tag } from "@kinherit/sdk";

export default defineComponent({
  name: KintinMasterListQcToCheckRoute,
  components: {
    KintinMasterList,
    PageHeader,
  },
  data() {
    return {
      tag: null as Tag | null,
    };
  },
  async mounted() {
    this.tag = Tag.$findOneByOrThrow({
      name: "QC to be checked",
    });
  },
});
</script>
