var y = Object.defineProperty;
var m = (a, l, n) => l in a ? y(a, l, { enumerable: !0, configurable: !0, writable: !0, value: n }) : a[l] = n;
var r = (a, l) => y(a, "name", { value: l, configurable: !0 });
var s = (a, l, n) => m(a, typeof l != "symbol" ? l + "" : l, n);
const o = class o {
  constructor() {
    s(this, "loaded", !1);
    s(this, "types", {
      AUD: { type: "AUD", symbol: "A$", name: "Australian dollar" },
      BGN: { type: "BGN", symbol: "лв", name: "Bulgarian lev" },
      BRL: { type: "BRL", symbol: "R$", name: "Brazilian real" },
      CAD: { type: "CAD", symbol: "C$", name: "Canadian dollar" },
      CHF: { type: "CHF", symbol: "Fr", name: "Swiss Franc" },
      CNY: { type: "CNY", symbol: "¥", name: "Chinese renminbi" },
      CZK: { type: "CZK", symbol: "Kč", name: "Czech koruna" },
      DKK: { type: "DKK", symbol: "kr", name: "Danish krone" },
      EUR: { type: "EUR", symbol: "€", name: "European euro" },
      GBP: { type: "GBP", symbol: "£", name: "British pound" },
      HKD: { type: "HKD", symbol: "HK$", name: "Hong Kong dollar" },
      HRK: { type: "HRK", symbol: "kn", name: "Croatian kuna" },
      HUF: { type: "HUF", symbol: "Ft", name: "Hungarian forint" },
      IDR: { type: "IDR", symbol: "Rp", name: "Indonesian rupiah" },
      ILS: { type: "ILS", symbol: "₪", name: "Israeli new sheqel" },
      ISK: { type: "ISK", symbol: "kr", name: "Icelandic króna" },
      INR: { type: "INR", symbol: "₹", name: "Indian rupee" },
      JPY: { type: "JPY", symbol: "¥", name: "Japanese yen" },
      KRW: { type: "KRW", symbol: "₩", name: "South Korean won" },
      MXN: { type: "MXN", symbol: "$", name: "Mexican peso" },
      MYR: { type: "MYR", symbol: "RM", name: "Malaysian ringgit" },
      NOK: { type: "NOK", symbol: "kr", name: "Norwegian krone" },
      NZD: { type: "NZD", symbol: "NS$", name: "New Zealand dollar" },
      PHP: { type: "PHP", symbol: "₱;", name: "Philippine peso" },
      PLN: { type: "PLN", symbol: "zł", name: "Polish zloty" },
      RON: { type: "RON", symbol: "L", name: "Romanian leu" },
      RUB: { type: "RUB", symbol: "руб", name: "Russian ruble" },
      SEK: { type: "SEK", symbol: "kr", name: "Swedish krona" },
      SGD: { type: "SGD", symbol: "S$", name: "Singapore dollar" },
      THB: { type: "THB", symbol: "฿", name: "Thai baht" },
      TRY: { type: "TRY", symbol: "TL", name: "Turkish lira" },
      USD: { type: "USD", symbol: "$", name: "United States dollar" },
      ZAR: { type: "ZAR", symbol: "R", name: "South African rand" }
    });
    s(this, "_rates", {
      GBP: null,
      EUR: null,
      USD: null,
      AUD: null,
      BGN: null,
      BRL: null,
      CAD: null,
      CHF: null,
      CNY: null,
      CZK: null,
      DKK: null,
      HKD: null,
      HRK: null,
      HUF: null,
      IDR: null,
      ILS: null,
      INR: null,
      ISK: null,
      JPY: null,
      KRW: null,
      MXN: null,
      MYR: null,
      NOK: null,
      NZD: null,
      PHP: null,
      PLN: null,
      RON: null,
      RUB: null,
      SEK: null,
      SGD: null,
      THB: null,
      TRY: null,
      ZAR: null
    });
  }
  get rates() {
    return this._rates;
  }
  convert({
    from: l,
    to: n,
    amount: e
  }) {
    return l === n ? (console.log(1, e), e) : (l !== "GBP" && (e = e * this.rates.GBP, console.log(2, e)), n !== "GBP" && (e = e / this.rates[n], console.log(3, e)), e);
  }
  async load() {
    var n;
    if (this.loaded === !0)
      return;
    (n = (await window.Kernel.Api.resource(
      "core",
      "/v2/core/exchange-rates"
    ).method("get").result()).exchangeRate) == null || n.forEach((e) => {
      this._rates[e.targetCurrency] = e.rate;
    }), this.loaded = !0;
  }
};
r(o, "CurrencyExchange");
let t = o;
export {
  t as CurrencyExchange
};
