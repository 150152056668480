<template>
  <div class="kinvault-subscription-master-list">
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      title="Stripe Subscriptions"
      subtitle="Kinvault Subscriptions"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        createdAt: 'Created At',
      }"
      @refresh="refresh"
    >
      <template #name="{ row }: { row: KinvaultSubscription }">
        {{ getKinvaultSubscriptionName(row) }}<br />
        {{ row.stripeSubscriptionId }}
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { ReadKinvaultSubscriptionForm } from "@/module/kinvault.subscription/form/read-kinvault-subscription.form";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Equal, In, Like } from "@kinherit/orm/index";
import { IKinvaultSubscription, KinvaultSubscription } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultSubscriptionMasterListRoute } from ".";

export default defineComponent({
  name: KinvaultSubscriptionMasterListRoute,
  components: { MasterListPage },
  mixins: [
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal.kinvaults.subscription.subscription,
    ),
  ],
  data: () => ({
    filters: ReadKinvaultSubscriptionForm(),
    columns: [
      {
        title: "Name",
        slot: "name",
      },
      {
        title: "Referral Code",
        map: (val) => val.referralCode?.code,
      },
      {
        title: "Status",
        map: (val) => val.status.ucFirst().replaceAll("_", " "),
      },
      {
        title: "Created",
        map: (val) => val.createdAt.formatYMD,
      },
    ] as Array<TableColumn<KinvaultSubscription>>,
    rows: Array<KinvaultSubscription>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IKinvaultSubscription,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    getKinvaultSubscriptionName(
      kinvaultSubscription: KinvaultSubscription,
    ): string {
      const friendlyName = kinvaultSubscription.kintin?.friendlyName;

      if (friendlyName) {
        return friendlyName;
      }

      const firstName = kinvaultSubscription.firstName;
      const lastName = kinvaultSubscription.lastName;

      if (firstName || lastName) {
        return [firstName, lastName].filter(Boolean).join(" ");
      }

      const email = kinvaultSubscription.email;

      if (email) {
        return email;
      }

      return "Unknown";
    },
    handleClick(
      kinvaultSubscription: KinvaultSubscription,
      event: MouseEvent,
    ): void {
      console.log({
        kinvaultSubscription,
        event,
      });
    },
    async refresh(
      formData: ReturnType<typeof ReadKinvaultSubscriptionForm>["localData"],
    ): Promise<void> {
      const data = await this.$actionBus.ReadKinvaultSubscription({
        query: {
          status: Equal(formData.status),
          stripeSubscriptionId: Like(formData.stripeSubscriptionId),
          referralCode: {
            id: In(formData.referralCode),
          },
          kintin: {
            friendlyName: Like(formData.friendlyName),
          },
          createdAt: Between(formData.created),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.kinvaultSubscription;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
