import { Address, Api, Kintin } from "@kinherit/sdk";

//
export const SendProcessLetter = async (message: {
  kintin: string | Kintin;
  address: string | Address;
}) => {
  const kintin =
    "string" === typeof message.kintin ? message.kintin : message.kintin.id;
  const address =
    "string" === typeof message.address ? message.address : message.address.id;

  await Api.resource("portal", "/v2/portal/kinvault/create-royal-mail-order")
    .method("get")
    .params({
      kintin,
      address,
    })
    .result();

  return {};
};
