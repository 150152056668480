import { External } from "@/action-bus/external";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Auth } from "./auth";
import { Core } from "./core";
import { Portal } from "./portal";

export const ActionBus = DefineActionBus({
  name: "action-bus",
  actions: {
    auth: Auth,
    core: Core,
    portal: Portal,
    external: External,
  },
});
