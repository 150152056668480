type SortReferReconcileMessage = {
  minDate: string;
  maxDate: string;
};

export const SortReferReconcile = async (
  // @todo
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  message: SortReferReconcileMessage,
): Promise<void> => {
  // await Api.resource("portal", "/v2/external/sort-refer/reconcile")
  //   .method("put")
  //   .body({
  //     minDate: message.minDate,
  //     maxDate: message.maxDate,
  //   })
  //   .result();
  throw new Error("Not implemented");
};
