var y = Object.defineProperty;
var S = (i, e, n) => e in i ? y(i, e, { enumerable: !0, configurable: !0, writable: !0, value: n }) : i[e] = n;
var p = (i, e) => y(i, "name", { value: e, configurable: !0 });
var h = (i, e, n) => S(i, typeof e != "symbol" ? e + "" : e, n);
import { setupDevtoolsPlugin as $ } from "@vue/devtools-api";
const v = class v {
  constructor(e) {
    h(this, "pluginId", "framework-form-plugin");
    $(
      {
        id: this.pluginId,
        app: e,
        label: "Framework / Form",
        packageName: "Framework",
        componentStateTypes: [this.pluginId]
      },
      (n) => {
        n.on.inspectComponent((s) => {
          var r;
          if (s.instanceData.name !== "FormComponent")
            return;
          const t = (r = s.instanceData.state.find(
            (o) => o.key === "config"
          )) == null ? void 0 : r.value;
          t === void 0 || t.formAreas === void 0 || t.formAreas(t.localData).forEach((o) => {
            if (o.components === void 0 || o.show !== void 0 && o.show(t.localData) === !1)
              return;
            const c = o.components(t.localData);
            Object.keys(c).forEach((l) => {
              const u = c[l];
              s.instanceData.state.push({
                editable: !1,
                key: `${l} (template slot)`,
                value: u.filter((a) => {
                  if (a == null)
                    return !1;
                  const m = a.bindings(t.localData, t, {
                    incrementTemplateRenderKey: /* @__PURE__ */ p(() => {
                    }, "incrementTemplateRenderKey"),
                    templateScope: {},
                    rebuildTemplateBindings: /* @__PURE__ */ p(() => {
                    }, "rebuildTemplateBindings")
                  });
                  return "vIf" in m ? m.vIf : !0;
                }).map(
                  (a) => this.mapComponent(
                    a,
                    t
                  )
                ),
                type: `Form area - ${o.name}`
              });
            });
          });
        });
      }
    );
  }
  mapComponent(e, n) {
    var o, c, l;
    const s = e.bindings(n.localData, n, {
      incrementTemplateRenderKey: /* @__PURE__ */ p(() => {
      }, "incrementTemplateRenderKey"),
      templateScope: {},
      rebuildTemplateBindings: /* @__PURE__ */ p(() => {
      }, "rebuildTemplateBindings")
    });
    let t = (o = e.props) == null ? void 0 : o.reference;
    t === void 0 && typeof ((c = e.models) == null ? void 0 : c.value) == "string" && (t = (l = e.models) == null ? void 0 : l.value);
    let r = e.component.name;
    return t !== void 0 && (r = `${r} (${t})`), {
      _custom: {
        type: "object",
        display: r,
        value: {
          reference: t,
          type: e.component.name,
          bindings: s,
          components: Object.keys(e.slots ?? {}).map(
            (u) => {
              var f, g;
              const a = (f = e.slots) == null ? void 0 : f[u], m = {};
              for (const d in a.components ?? {}) {
                const D = (g = a.components) == null ? void 0 : g[d];
                m[d] = {
                  _custom: {
                    type: "array",
                    display: `${d} (template slot)`,
                    value: D.map(
                      (k) => this.mapComponent(k, n)
                    )
                  }
                };
              }
              return {
                _custom: {
                  type: "object",
                  display: `${u.toString()} (component slot)`,
                  value: m
                }
              };
            }
          )
        }
      }
    };
  }
  getNestedSlots(e) {
    var s;
    let n = Array();
    return e.slot && n.push(e.slot), (s = e.children) == null || s.forEach((t) => {
      n = [...n, ...this.getNestedSlots(t)];
    }), n;
  }
};
p(v, "FormPlugin");
let b = v;
export {
  b as FormPlugin
};
