// KnowledgeBaseCategory

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { KnowledgeBaseCategory } from "@kinherit/sdk/model/knowledge-base-category";
import { KnowledgeBaseDocument } from "@kinherit/sdk/model/knowledge-base-document";

export const RemoveDocument = DefineDeleteAction({
  interface: "portal",
  resource:
    "/v2/portal/knowledge-base/category/{knowledgeBaseCategory}/documents/{knowledgeBaseDocument}/remove",
  method: "delete",
  parse: ({
    category,
    document,
  }: {
    category: KnowledgeBaseCategory | string;
    document: KnowledgeBaseDocument | string;
  }) => ({
    params: {
      knowledgeBaseCategory: category,
      knowledgeBaseDocument: document,
    },
  }),
});
