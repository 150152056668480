var f = Object.defineProperty;
var r = (o, i) => f(o, "name", { value: i, configurable: !0 });
import { _ as u } from "../../../component.input/button/button.vue_vue_type_script_lang.js";
import { resolveComponent as m, resolveDirective as d, withDirectives as k, createBlock as n, openBlock as e, resolveDynamicComponent as h, mergeProps as y, withKeys as s, withModifiers as b, withCtx as g, createCommentVNode as l, renderSlot as v, createElementBlock as a, toDisplayString as $ } from "vue";
import { b as p } from "../../../_assets/component.input/button/button.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as C } from "../../../_plugins/_plugin-vue_export-helper.js";
const L = { key: 2 }, z = { key: 3 };
function D(o, i, T, w, I, B) {
  const t = m("Icon"), c = d("tool-tip");
  return k((e(), n(h(o.isDisabled || o.compIsLink ? "a" : "button"), y({
    href: o.compIsLink ? o.href : null,
    target: o.compIsLink ? o.target : null
  }, o.$data.$cypress, {
    type: o.type,
    class: `button ${o.buttonClasses}`,
    disabled: o.isDisabled ? !0 : void 0,
    autofocus: o.autofocus,
    "aria-label": o.compHasText ? null : o.ariaLabel || o.compToolTip,
    onClick: o.click,
    onKeydown: i[0] || (i[0] = s(b(() => {
    }, ["prevent"]), ["enter"])),
    onKeyup: [
      s(o.click, ["enter"]),
      s(o.click, ["space"])
    ]
  }), {
    default: g(() => [
      o.iconLeft ? (e(), n(t, {
        key: 0,
        class: "is-icon-left",
        size: o.size,
        icon: o.iconLeft,
        "inherit-color": o.color !== "is-plain",
        count: o.count
      }, null, 8, ["size", "icon", "inherit-color", "count"])) : l("", !0),
      o.$slots.default ? v(o.$slots, "default", { key: 1 }) : o.text !== null ? (e(), a("span", L, $(o.text), 1)) : o.iconLeft === null && o.iconRight === null && o.compHasText === !1 ? (e(), a("div", z, "   ")) : l("", !0),
      o.iconRight ? (e(), n(t, {
        key: 4,
        class: "is-icon-right",
        size: o.size,
        icon: o.iconRight,
        "inherit-color": o.color !== "is-plain"
      }, null, 8, ["size", "icon", "inherit-color"])) : l("", !0)
    ]),
    _: 3
  }, 16, ["href", "target", "type", "class", "disabled", "autofocus", "aria-label", "onClick", "onKeyup"])), [
    [c, o.compToolTip]
  ]);
}
r(D, "_sfc_render");
typeof p == "function" && p(u);
const E = /* @__PURE__ */ C(u, [["render", D]]);
export {
  E as default
};
