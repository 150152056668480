var n = Object.defineProperty;
var t = (o, l) => n(o, "name", { value: l, configurable: !0 });
import a from "../../_vue/component.input/button/button.js";
import { ThemeColor as e } from "../../theme/prop/color.js";
import { ThemeIconName as r } from "../../theme/prop/icon.js";
import { ThemeShade as i } from "../../theme/prop/shade.js";
import { defineComponent as u } from "vue";
import s from "../../_vue/component.display/icon/icon.js";
import { S as m } from "../../_assets/component.display/snackbar/snack-bar.module.js";
const v = u({
  name: "SnackBar",
  components: { Button: a, Icon: s },
  props: {
    /**
     * color variant
     */
    shade: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((o) => Object.values(i).includes(o), "validator"),
      value: i
    },
    icon: {
      type: [String, Object],
      default: null,
      value: r
    },
    color: {
      type: [String, Array],
      default: "is-light-grey",
      value: e
    },
    iconColor: {
      type: String,
      default: "is-primary",
      value: e
    },
    actionText: {
      type: String,
      default: "OK",
      value: "text"
    },
    onAction: {
      type: Function,
      default: /* @__PURE__ */ t(() => null, "default")
    },
    cancelText: {
      type: String,
      default: null,
      value: "text"
    },
    onCancel: {
      type: Function,
      default: /* @__PURE__ */ t(() => null, "default")
    },
    duraction: {
      type: Number,
      default: 8e3
    },
    text: {
      type: String,
      default: null,
      value: "text"
    },
    actionColor: {
      type: String,
      default: "is-primary",
      value: e
    },
    cancelColor: {
      type: String,
      default: "is-danger",
      value: e
    },
    position: {
      type: String,
      default: "is-top",
      value: [
        "is-top",
        "is-top-left",
        "is-top-right",
        "is-bottom",
        "is-bottom-left",
        "is-bottom-right"
      ]
    },
    show: {
      type: Boolean,
      default: !0
    }
  },
  emits: ["update:show"],
  data: /* @__PURE__ */ t(() => ({
    timer: null
  }), "data"),
  beforeMount() {
    window.Kernel.injectStylesheet("snack-bar", m);
  },
  mounted() {
    this.resume();
  },
  methods: {
    action() {
      this.onAction(), this.close();
    },
    close() {
      this.onCancel(), this.$emit("update:show", !1);
    },
    pause() {
      this.timer && (clearTimeout(this.timer), this.timer = null);
    },
    resume() {
      this.timer = setTimeout(
        () => this.close(),
        this.duraction
      );
    }
  }
});
export {
  v as _
};
