import { BrandedKinvault } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

//
export const DownloadInvoice = async (data: {
  brandedKinvault: string | BrandedKinvault;
  startDate: DateTime;
  endDate: DateTime;
}): Promise<void> => {
  const { startDate, endDate } = data;
  const startDateFormatted = startDate.formatYMD;
  const endDateFormatted = endDate.formatYMD;
  await window.Kernel.Api.resource(
    "portal",
    "/v2/portal/admin/branded-kinvault/invitation/fees/{brandedKinvault}/download",
    {
      brandedKinvault: data.brandedKinvault,
    },
  )
    .method("get")
    .params({
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    })
    .download({
      encoding: "application/pdf",
      fileName: `Kinvault Fees - ${startDateFormatted} - ${endDateFormatted}.pdf`,
    });
};
