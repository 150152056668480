var n = Object.defineProperty;
var t = (o, e) => n(o, "name", { value: e, configurable: !0 });
import r from "../../_vue/component.input/button/button.js";
import i from "../../_vue/component.layout/card/card.js";
import { TranslationMixin as m } from "../../component.mixin/translation.mixin.js";
import { KernelModes as s } from "../../core/kernel-mode.js";
import { defineComponent as a } from "vue";
const M = a({
  name: "PageNotFoundComponent",
  components: { Card: i, Button: r },
  mixins: [
    m({
      defaults: {
        title: "Page not found...",
        subtitle: "Sorry, we can't seem to find that."
      }
    })
  ],
  data: /* @__PURE__ */ t(() => ({}), "data"),
  computed: {
    isDevMode: /* @__PURE__ */ t(() => window.Kernel.Mode === s.Dev, "isDevMode"),
    routes() {
      var o;
      return ((o = this.$router) == null ? void 0 : o.getRoutes()) ?? [];
    }
  }
});
export {
  M as _
};
