import { Api } from "@/service/api.service";
import { Address, Kintin, Person, User } from "@kinherit/sdk";

//
export const DownloadOfficerLetter = async (message: {
  kintin: Kintin;
  officer: Person;
  address: Address;
  specialist: User;
}) => {
  const { kintin, officer, address, specialist } = message;

  await Api.resource("portal", "/v2/portal/officer/{officer}/download")
    .method("get")
    .params({
      kintin: kintin.id,
      officer: officer.id,
      address: address.id,
      specialist: specialist.id,
    })
    .download({
      encoding: "application/pdf",
      fileName: `${kintin.ref} Officer Letter - ${
        officer.profile.knownAs ?? officer.profile.fullName
      }`,
    });
};
