// AccountReferralCode

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const RemoveAccountReferralCode = DefineDeleteAction({
  interface: "portal",
  resource:
    "/v2/portal/introducer/company/referral/{introducerCompany}/code/referral-codes/{accountReferralCode}/remove",
  method: "delete",
  parse: (message: {
    introducerCompany: IntroducerCompany | string;
    accountReferralCode: AccountReferralCode | string;
  }) => ({
    params: {
      introducerCompany: message.introducerCompany,
      accountReferralCode: message.accountReferralCode,
    },
  }),
});
