// Gift

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Gift } from "@kinherit/sdk/model/gift";

export const RecordGift = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/gift/{gift}",
  method: "get",
  rootModel: Gift,
  parse: (gift: Gift) => ({
    params: {
      gift,
      kintin: gift.$data.kintin,
    },
  }),
});
