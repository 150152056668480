// KnowledgeBaseCategory

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KnowledgeBaseCategory } from "@kinherit/sdk/model/knowledge-base-category";

export const RecordKnowledgeBaseCategory = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/knowledge-base/category/{knowledgeBaseCategory}",
  method: "get",
  rootModel: KnowledgeBaseCategory,
  parse: (knowledgeBaseCategory: KnowledgeBaseCategory | string) => ({
    params: {
      knowledgeBaseCategory,
    },
  }),
});
