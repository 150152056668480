var s = Object.defineProperty;
var t = (e, r) => s(e, "name", { value: r, configurable: !0 });
import a from "../../_vue/component.input/button/button.js";
import i from "../../_vue/component.input/text-field/text-field.js";
import n from "../../_vue/component.transition/transition-height.js";
import { KernelModes as l } from "../../core/kernel-mode.js";
import { ThemeSize as o } from "../../theme/prop/size.js";
import { defineComponent as d } from "vue";
import { S as m } from "../../_assets/component.layout/search/search.module.js";
const F = d({
  name: "SearchComponent",
  components: { Button: a, TextField: i, TransitionHeight: n },
  props: {
    /**
     * Size of the buttons
     */
    size: {
      type: String,
      default: o.isNormal,
      validator: /* @__PURE__ */ t((e) => Object.values(o).includes(e), "validator"),
      value: o
    },
    /**
     * Hide the reset button
     * @default false
     */
    hideReset: {
      type: Boolean,
      default: !1
    },
    /**
     * Hide the search button
     * @default false
     */
    hideSearch: {
      type: Boolean,
      default: !1
    },
    advFiltersOpen: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["submit", "reset", "update:adv-filters-open"],
  data: /* @__PURE__ */ t(() => ({
    search: "",
    hasFocus: !1
  }), "data"),
  computed: {
    hasAdvancedFilters() {
      return this.$slots["advanced-filters"] !== void 0;
    },
    computedAdvFiltersOpen() {
      return window.Kernel.Mode === l.Test ? !0 : this.advFiltersOpen;
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("search", m);
  }
});
export {
  F as _
};
