// Tag

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Tag } from "@kinherit/sdk/model/tag";

export const UpdateTag = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/admin/tag/{tag}",
  method: "patch",
  parse: (target: Tag) => ({
    body: {
      name: target.$data.name,
      description: target.$data.description,
    },
    params: {
      tag: target,
    },
  }),
});
