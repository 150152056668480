<template>
  <RouterChildView />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsDocumentsWrapper",
  "route": "KinvaultKintinDetailsDocuments",
  "imports": {
    "KinvaultKintinDetailsDocumentsSidebarWrapper": "@/module/kinvault.kintin/page/details/documents/KintinDetails.Documents.Sidebar.test",
    "KinvaultKintinDetailsFileVaultWrapper": "@/module/kinvault.kintin/page/details/documents/file-vault/KintinDetails.FileVault.test",
    "KintinDetailsOrderWrapper": "@/module/kinvault.kintin/page/details/documents/orders/KintinDetails.Orders.test",
    "KintinDetailsProcessWrapper": "@/module/kinvault.kintin/page/details/documents/process/KintinDetails.Process.test",
    "KintinDetailsSubscriptionWrapper": "@/module/kinvault.kintin/page/details/documents/subscriptions/KintinDetails.Subscriptions.test",
    "KintinDetailsUploadFormWrapper": "@/module/kinvault.kintin/page/details/documents/upload-forms/KintinDetails.UploadForms.test",
    "KinvaultKintinDetailsPostPaymentWrapper": "@/module/kinvault.kintin/page/details/documents/post-payment/KintinDetails.PostPayment.test"
  },
  "methods": {
    "sidebar": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsDocumentsSidebarWrapper"
    },
    "process": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KintinDetailsProcessWrapper"
    },
    "orders": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KintinDetailsOrderWrapper"
    },
    "subscriptions": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KintinDetailsSubscriptionWrapper"
    },
    "postPayment": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsPostPaymentWrapper"
    },
    "uploadForms": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KintinDetailsUploadFormWrapper"
    },
    "fileVault": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsFileVaultWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsDocumentsRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsDocumentsRoute,
  components: { RouterChildView },
  mixins: [KinvaultKintinDetailsMixin],
});
</script>
