<template>
  <Message
    v-if="showMfaWarning"
    color="is-warning"
    class="mb-5 is-clickable"
    @click="viewMyProfile"
  >
    <strong
      >Required: setup Multifactor authentication to protect your
      account</strong
    >
    <span class="is-block"
      >All Admin &amp; Staff accounts must have 2FA enabled.</span
    >
  </Message>
  <RouterChildView />
</template>

<script lang="ts">
import {
  AdminUserDetailsManage2FactorAuthRoute,
  AdminUserDetailsParams,
} from "@/module/admin.user/page/details";
import { AuthService } from "@/service/auth.service";
import Message from "@kinherit/framework/component.display/message";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { DashboardIndexRoute } from ".";

export default defineComponent({
  name: DashboardIndexRoute,
  components: { RouterChildView, Message },
  mixins: [AuthService.mixin({ sessionRequired: true })],
  data: () => ({
    showMfaWarning: false,
  }),
  async mounted(): Promise<void> {
    const { hasMultiFactor, enforce } =
      await window.Kernel.ActionBus2.core.EnforceMultiFactor();
    if (!hasMultiFactor && enforce) {
      this.showMfaWarning = true;
    }
  },
  methods: {
    viewMyProfile() {
      const user = this.$auth.loggedInUser;

      if (!user) {
        return;
      }

      this.$router.push({
        name: AdminUserDetailsManage2FactorAuthRoute,
        params: { user: user.id } as AdminUserDetailsParams,
      });
    },
  },
});
</script>
