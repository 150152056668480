<template>
  <CallScriptSection title="Protecting your assets">
    <TickCross
      :kintin="kintin"
      step="2.5.0a"
      yes="There are children"
      no="No children or dependents under 18 or requiring special care"
    />
    <TickCross
      :kintin="kintin"
      step="3.0.0a"
      yes="Owns Main Property"
      no="Does not own primary residence"
    />
    <em>[ONLY USE THIS IF YOU THINK AN EPT IS A CONSIDERATION FOR THEM]</em>
    <br /><br />
    <b>
      OK, so we’ve gone through who gets what…
      <br /><br />
      I just want to explain something quickly here, because it’s a really
      useful thing to know about in case you find it helpful.
      <br /><br />
      We sometimes set up something called an Estate Protection Trust for our
      customers, which is the next step up from a basic Will, so it is a bit
      more expensive, but it does gives you much more control over what happens
      to your estate and helps to protect your money from things like divorce,
      care fees etc So, for example - your assets go to the people you wish, but
      then they get divorced … so half the money you left them could be lost.
      But if it’s in a trust, it’s protected. Similarly if you’re assessed for
      care fees, if your money is in a trust it won’t be taken into account for
      means testing.
      <br /><br />
      It’s just something to think about – I’ll include a bit about it in my
      recommendations so you can look at it in your own time. But it’s entirely
      up to you whether you choose that route.
      <br /><br />
    </b>
  </CallScriptSection>
</template>

<script lang="ts">
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import TickCross from "@/module/kinvault.kintin/component/call-script/shared/TickCross.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "OutroTab",
  components: {
    CallScriptSection,
    TickCross,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
});
</script>
