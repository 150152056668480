import { Api } from "@/service/api.service";
import { User } from "@kinherit/sdk";

export interface ReadDashboardStatisticEPMessage {
  selected: User;
  timeFrame: "4wk" | "8wk" | "12wk";
}

export interface ReadDashboardStatisticEPResponse {
  accounts: {
    inProgress: {
      currentCount: number;
      overdueCount: number;
      overduePercentage: number;
    };
    breakdown: {
      b: number;
      c: number;
      d: number;
      e2: number;
      hold: number;
    };
  };
  orders: {
    cashIn: {
      orderCount: number;
      orderValue: number;
    };
    pipeline: {
      orderCount: number;
      orderValue: number;
    };
    advised: {
      orderCount: number;
      orderValue: number;
      orderAvg: number;
    };
  };
  appointments: {
    totalCount: number;
    enqCount: number;
    call1Count: number;
    call2Count: number;
    cancelledCount: number;
  };
  products: {
    total: number;
    breakdown: {
      wills: number;
      trusts: number;
      lpas: number;
      docstorage: number;
      other: number;
    };
  };
  timeToPayment: number;
  accountValue: number;
  timeToCash: number;
}

export const ReadStatistics = async (
  message: ReadDashboardStatisticEPMessage,
): Promise<ReadDashboardStatisticEPResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/dashboard/statistics/ep",
  )
    .method("get")
    .params({
      userId: message.selected.id,
      timeFrame: message.timeFrame,
    })
    .result();
  return response;
};
