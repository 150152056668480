var m = Object.defineProperty;
var l = (e, t) => m(e, "name", { value: t, configurable: !0 });
import { defineComponent as a } from "vue";
import c from "../../_vue/component.input/base-input-field/base-input-field.js";
import p from "../../_vue/component.input/button/button.js";
import { FieldMixin as y } from "../../component.mixin/field-mixin.js";
import { TranslationMixin as d } from "../../component.mixin/translation.mixin.js";
import { CypressHelper as h } from "../../cypress/component-helpers.js";
import { OpenAutocompleteDialog as C } from "../../global/dialog.js";
import { Currencies as f, Currency as u } from "@kinherit/ts-common/dto/currency";
const F = a({
  name: "CurrencyFieldComponent",
  components: { BaseInputField: c, Button: p },
  mixins: [
    y(),
    d({
      defaults: {}
    })
  ],
  props: {
    /**
     * v-model:value
     */
    value: {
      type: u,
      default: null
    },
    decimalPlaces: {
      type: Number,
      default: 2
    }
  },
  emits: {
    /**
     * Emits when the value is updated
     */
    "update:value": null
  },
  data: /* @__PURE__ */ l((e) => ({
    $cypress: h({ ctx: e, labelField: "label" }),
    defaultCurrency: {
      name: "British Pound",
      symbol: "£",
      type: f.GBP
    },
    selectedCurrency: null,
    amount: null
  }), "data"),
  computed: {
    computedValue: {
      get() {
        var e;
        return this.amount ?? ((e = this.value) == null ? void 0 : e.amount.format(2)) ?? null;
      },
      set(e) {
        if (this.amount = e ?? null, e === null) {
          this.$emit("update:value", null);
          return;
        }
        const t = new u({
          type: this.selectedCurrency ?? this.defaultCurrency.type,
          amount: e.toNumber()
        });
        this.$emit("update:value", t);
      }
    },
    computedCurrency() {
      var e;
      return new u({
        type: this.selectedCurrency ?? this.defaultCurrency.type,
        amount: ((e = this.value) == null ? void 0 : e.amount) ?? void 0
      });
    },
    computedCurrencySymbol() {
      return this.computedCurrency.symbol;
    },
    computedCurrencyName() {
      return this.computedCurrency.name;
    },
    computedMessage() {
      return this.message;
    },
    computedMessageColor() {
      return this.messageColor;
    }
  },
  methods: {
    async exchange() {
      var s;
      await window.Kernel.CurrencyExchange.load();
      const e = [], t = window.Kernel.CurrencyExchange.types;
      Object.keys(t).forEach((i) => {
        const o = t[i];
        o !== null && e.push({
          title: `${o.name} (${o.symbol})`,
          type: i
        });
      });
      const r = {
        title: `${this.computedCurrencyName} (${this.computedCurrencySymbol})`,
        type: this.computedCurrency.type
      }, n = await C({
        value: r,
        dialog: {
          title: this.label
        },
        list: {
          options: /* @__PURE__ */ l(() => e, "options"),
          mapOptions: {
            label: "title",
            value: "type"
          }
        }
      });
      n !== null && (this.computedValue = window.Kernel.CurrencyExchange.convert({
        from: this.computedCurrency.type,
        to: n.type,
        amount: ((s = this.computedValue) == null ? void 0 : s.toNumber()) ?? 0
      }).format(2), this.selectedCurrency = n.type);
    },
    async handleBlur(e) {
      var t, r;
      this.blur(e), await this.$nextTick(), this.amount = ((t = this.computedValue) == null ? void 0 : t.toNumber().format(2)) ?? null, this.$emit(
        "update:value",
        new u({
          type: this.selectedCurrency ?? this.defaultCurrency.type,
          amount: (r = this.amount) == null ? void 0 : r.toNumber()
        })
      );
    }
  }
});
export {
  F as _
};
