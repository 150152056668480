import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerFeePaymentItem } from "./create-introducer-fee-payment-item.action";
import { DeleteIntroducerFeePaymentItem } from "./delete-introducer-fee-payment-item.action";
import { ReadIntroducerFeePaymentItem } from "./read-introducer-fee-payment-item.action";
import { RecordIntroducerFeePaymentItem } from "./record-introducer-fee-payment-item.action";
import { UpdateIntroducerFeePaymentItem } from "./update-introducer-fee-payment-item.action";

export const Portal2IntroducerFeesPaymentItem = DefineActionBus({
  name: "portal-introducer-fees-payment-item",
  actions: {
    RecordIntroducerFeePaymentItem,
    CreateIntroducerFeePaymentItem,
    ReadIntroducerFeePaymentItem,
    UpdateIntroducerFeePaymentItem,
    DeleteIntroducerFeePaymentItem,
  },
});
