var o = Object.defineProperty;
var r = (e, s) => o(e, "name", { value: s, configurable: !0 });
import { _ as a } from "../../../component.display/progress/progress.vue_vue_type_script_lang.js";
import { createElementBlock as l, openBlock as t, normalizeClass as m, createElementVNode as n, normalizeStyle as p } from "vue";
import { _ as i } from "../../../_plugins/_plugin-vue_export-helper.js";
const c = ["value", "max"];
function d(e, s, f, u, _, g) {
  return t(), l("div", {
    class: m(`progress ${e.classes}`),
    value: e.progress,
    max: e.max
  }, [
    n("div", {
      style: p(`--progress: ${Math.min(100 * (e.progress / e.max), 100)}%`),
      class: "progress-bar"
    }, null, 4)
  ], 10, c);
}
r(d, "_sfc_render");
const x = /* @__PURE__ */ i(a, [["render", d]]);
export {
  x as default
};
