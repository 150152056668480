var m = Object.defineProperty;
var s = (t, e) => m(t, "name", { value: e, configurable: !0 });
import { _ as p } from "../../../component.form/element/element.vue_vue_type_script_lang.js";
import { createBlock as o, openBlock as r, resolveDynamicComponent as i, mergeProps as l, toDisplayString as k, withCtx as $, renderSlot as a } from "vue";
import { _ as f } from "../../../_plugins/_plugin-vue_export-helper.js";
function u(t, e, d, C, y, g) {
  return t.html ? (r(), o(i(t.is), l({ key: 0 }, t.$attrs, {
    onClick: e[0] || (e[0] = (n) => t.$emit("click", n)),
    innerHTML: t.html
  }), null, 16, ["innerHTML"])) : t.text ? (r(), o(i(t.is), l({ key: 1 }, t.$attrs, {
    onClick: e[1] || (e[1] = (n) => t.$emit("click", n)),
    textContent: k(t.text)
  }), null, 16, ["textContent"])) : (r(), o(i(t.is), l({ key: 2 }, t.$attrs, {
    onClick: e[2] || (e[2] = (n) => t.$emit("click", n))
  }), {
    default: $(() => [
      a(t.$slots, "default")
    ]),
    _: 3
  }, 16));
}
s(u, "_sfc_render");
const M = /* @__PURE__ */ f(p, [["render", u]]);
export {
  M as default
};
