// EmailTemplate

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvaultCta } from "@kinherit/sdk";

export const RecordBrandedKinvaultCta = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/branded-kinvault-cta/{brandedKinvaultCta}",
  method: "get",
  rootModel: BrandedKinvaultCta,
  parse: (brandedKinvaultCta: string | BrandedKinvaultCta) => ({
    params: {
      brandedKinvaultCta: brandedKinvaultCta,
    },
  }),
});
