// Address, Adviser, Attorney, Beneficiary, CallScript, EmailAddress, Exclusion, Executor, Gift, Guardian, IntroducerCompany, IntroducerContact, Kintin, KintinCheck, KintinCheckFollowUp, KintinAccess, Order, OrderItem, Person, PhoneNumber, Profile, Property, AccountReferral, AccountReferralCode, Trustee, User, Website, Note

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const RecordCallScriptData = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/call-script/data/{kintin}",
  method: "get",
  rootModel: Kintin,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});
