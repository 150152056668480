var s = Object.defineProperty;
var o = (e, t) => s(e, "name", { value: t, configurable: !0 });
import { _ as a } from "../../../component.display/title/title.vue_vue_type_script_lang.js";
import { createBlock as l, openBlock as r, resolveDynamicComponent as n, mergeProps as p, withCtx as i, createElementBlock as m, renderSlot as c, Fragment as f, createTextVNode as d, toDisplayString as u } from "vue";
import { _ as $ } from "../../../_plugins/_plugin-vue_export-helper.js";
function k(e, t, _, g, y, B) {
  return r(), l(n(e.compHtag ?? "div"), p(e.$attrs, { class: e.titleClasses }), {
    default: i(() => [
      e.text ? (r(), m(f, { key: 0 }, [
        d(u(e.text), 1)
      ], 64)) : c(e.$slots, "default", { key: 1 })
    ]),
    _: 3
  }, 16, ["class"]);
}
o(k, "_sfc_render");
const S = /* @__PURE__ */ $(a, [["render", k]]);
export {
  S as default
};
