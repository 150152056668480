var c = Object.defineProperty;
var r = (o, t) => c(o, "name", { value: t, configurable: !0 });
import { DebugMixin as h } from "./debug-mixin.js";
import { DashLoader as a } from "@kinherit/ts-common/service/dash-loader";
import { defineComponent as p } from "vue";
const v = /* @__PURE__ */ r((o) => p({
  name: "OptionsMixin",
  mixins: [h],
  props: {
    noSort: {
      type: Boolean,
      default: !1
    },
    includeSelectedInOptions: {
      type: Boolean,
      default: !0
    },
    options: {
      type: [Array, Object],
      default: /* @__PURE__ */ r(() => [], "default")
    },
    asyncLoad: {
      type: Function,
      default: null
    },
    asyncFilter: {
      type: Function,
      default: null
    },
    mapOptions: {
      type: Object,
      default: null
    },
    isMultiSelect: {
      type: Boolean,
      default: (o == null ? void 0 : o.isMultiSelect) || !1
    },
    value: {
      type: [Array, Object, String, Number],
      default: null
    },
    optionLimit: {
      type: Number,
      default: null
    },
    /**
     * Sort the options
     */
    sort: {
      type: Function,
      default: null
    },
    sortBy: {
      type: Function,
      default: null
    }
  },
  emits: ["update:value"],
  data: /* @__PURE__ */ r(() => ({
    optionsMixin: {
      normalized: [],
      search: null,
      timeout: null,
      loading: !1,
      loadRenderKey: 0
    }
  }), "data"),
  computed: {
    computedSearch: {
      get() {
        return this.optionsMixin.search === "" ? null : this.optionsMixin.search;
      },
      set(t) {
        this.$log("search", t), this.optionsMixin.search = t, this.optionsMixin.timeout && clearTimeout(this.optionsMixin.timeout), this.optionsMixin.timeout = setTimeout(() => {
          this.$loadOptions();
        }, 200);
      }
    },
    computedOptions() {
      var l;
      let t = this.optionsMixin.normalized;
      if (!this.noSort && (this.sortBy || this.sort)) {
        let s = t.map((n) => n.original);
        this.sortBy ? s = s.sortBy(this.sortBy) : this.sort && (s = s.sort(this.sort)), t = t.sortBy(
          (n) => s.indexOf(n.original)
        );
      }
      const e = (l = this.optionsMixin.search) == null ? void 0 : l.toLowerCase();
      ((e == null ? void 0 : e.trim()) ?? "") !== "" && (this.asyncFilter || (t = t.filter(
        (s) => {
          var n, u;
          return s.computed.label.smartIncludes(e) ?? ((n = s.computed.line1) == null ? void 0 : n.smartIncludes(e)) ?? ((u = s.computed.line2) == null ? void 0 : u.smartIncludes(e));
        }
      )), t = t.sortBy(
        (s) => {
          var n, u;
          return Math.min(
            s.computed.label.distance(e),
            ((n = s.computed.line1) == null ? void 0 : n.distance(e)) ?? 1 / 0,
            ((u = s.computed.line2) == null ? void 0 : u.distance(e)) ?? 1 / 0
          );
        }
      ));
      let i = [];
      return Array.isArray(this.computedValue) ? i = this.computedValue : this.computedValue !== null && (i = [this.computedValue]), this.includeSelectedInOptions ? i.concat(t.map((s) => s.computed)).unique("value").slice(
        0,
        this.optionLimit ?? t.length + i.length
      ) : t.map((s) => s.computed).unique("value").slice(0, this.optionLimit ?? t.length + i.length);
    },
    computedValue: {
      get() {
        var e;
        if (this.value === null)
          return this.isMultiSelect ? [] : null;
        if (typeof this.value == "string" || typeof this.value == "number" || Array.isArray(this.value) && this.value.every(
          (i) => typeof i == "string" || typeof i == "number"
        )) {
          const i = (Array.isArray(this.value) ? this.value : [this.value]).map((s) => this.$resolveValue(s)), l = this.optionsMixin.normalized.filter((s) => i.includes(s.computed.value)).pluck("computed");
          return this.isMultiSelect ? l : l[0] ?? null;
        }
        const t = this.$normalizeOptions(
          Array.isArray(this.value) ? this.value : [this.value]
        );
        return this.isMultiSelect ? t.pluck("computed") : ((e = t[0]) == null ? void 0 : e.computed) ?? null;
      },
      set(t) {
        if (t === null) {
          this.$emit("update:value", this.isMultiSelect ? [] : null);
          return;
        }
        const e = (Array.isArray(t) ? t : [t]).map(
          (l) => typeof l == "string" ? l : l.value
        ), i = this.optionsMixin.normalized.filter((l) => e.includes(l.computed.value)).pluck("original");
        this.$emit("update:value", this.isMultiSelect ? i : i[0]);
      }
    }
  },
  async mounted() {
    this.optionsMixin.normalized = this.$normalizeOptions(this.options);
    const t = this.isMultiSelect ? this.value ?? [] : this.value !== null ? [this.value] : [];
    this.asyncLoad && t.length > 0 && (this.optionsMixin.normalized = this.$normalizeOptions(
      await this.asyncLoad(t)
    )), await this.$loadOptions();
  },
  methods: {
    $resolveValue(t) {
      var e;
      if (typeof t == "string" || typeof t == "number")
        return t;
      if (typeof t == "object") {
        const i = (e = this.mapOptions) == null ? void 0 : e.value;
        if (typeof i == "string")
          return a.get(t, i);
        if (typeof i == "function")
          return i(t);
      }
      throw new Error(
        `Invalid option: ${JSON.stringify(t)}, value not found`
      );
    },
    async $loadOptions() {
      this.$log("loading options"), this.optionsMixin.loading = !0;
      const t = this.asyncFilter ? await this.asyncFilter(this.optionsMixin.search) : this.options;
      this.$log("raw options", t);
      const e = this.$normalizeOptions(t), i = (Array.isArray(this.computedValue) ? this.computedValue : [this.computedValue]).pluck("value"), s = [
        ...this.optionsMixin.normalized.filter(
          (n) => i.includes(n.computed.value)
        ),
        ...e
      ].unique("computed.value");
      this.$log("mapped options", s), this.optionsMixin.normalized = s, this.optionsMixin.loading = !1, this.optionsMixin.loadRenderKey++;
    },
    $normalizeOptions(t) {
      const e = [];
      if (Array.isArray(t) && typeof t[0] == "string")
        for (let i = 0; i < t.length; i++) {
          const l = { label: t[i], value: i };
          e.push({
            original: i,
            computed: {
              ...l,
              line1: this.$resolveLine1(l),
              line2: this.$resolveLine2(l),
              iconRight: this.$resolveIconRight(l),
              iconLeft: this.$resolveIconLeft(l)
            }
          });
        }
      else if (Array.isArray(t) && typeof t[0] == "object")
        for (const i of t)
          e.push({
            original: i,
            computed: {
              label: this.$resolveLabel(i),
              value: this.$resolveValue(i),
              line1: this.$resolveLine1(i),
              line2: this.$resolveLine2(i),
              iconRight: this.$resolveIconRight(i),
              iconLeft: this.$resolveIconLeft(i)
            }
          });
      else if (typeof t == "object")
        for (const [i, l] of Object.entries(
          t
        )) {
          const s = { label: l, value: i };
          e.push({
            original: i,
            computed: {
              ...s,
              line1: this.$resolveLine1(s),
              line2: this.$resolveLine2(s),
              iconRight: this.$resolveIconRight(s),
              iconLeft: this.$resolveIconLeft(s)
            }
          });
        }
      return e;
    },
    $resolve(t, e) {
      return typeof e == "string" ? a.get(t, e) : e === void 0 ? null : typeof e == "function" ? e(t) ?? null : null;
    },
    $resolveLabel(t) {
      var i;
      const e = this.$resolve(t, (i = this.mapOptions) == null ? void 0 : i.label);
      return (e == null ? void 0 : e.toString()) ?? "";
    },
    $resolveLine1(t) {
      var e;
      return this.$resolve(t, (e = this.mapOptions) == null ? void 0 : e.line1);
    },
    $resolveLine2(t) {
      var e;
      return this.$resolve(t, (e = this.mapOptions) == null ? void 0 : e.line2);
    },
    $resolveIconRight(t) {
      var e;
      return this.$resolve(t, (e = this.mapOptions) == null ? void 0 : e.iconRight);
    },
    $resolveIconLeft(t) {
      var e;
      return this.$resolve(t, (e = this.mapOptions) == null ? void 0 : e.iconLeft);
    },
    selectOption(t) {
      this.isMultiSelect && this.computedValue.pluck("value").includes(t.value);
    },
    unselectOption(t) {
      if (!this.isMultiSelect) {
        this.computedValue = null;
        return;
      }
      this.computedValue = this.computedValue.filter(
        (e) => e.value !== t.value
      );
    },
    isSelected(t) {
      var e;
      return this.isMultiSelect ? this.computedValue.pluck("value").includes(t.value) : ((e = this.computedValue) == null ? void 0 : e.value) === t.value;
    },
    toggleOption(t) {
      this.isSelected(t) ? this.unselectOption(t) : this.selectOption(t);
    }
  }
}), "OptionsMixin");
export {
  v as OptionsMixin
};
