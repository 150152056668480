import {
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadOrderRefundRequestForm = () => {
  return MasterListFiltersForm({
    name: "filter-order-refund-request-form",
    data: () => ({
      search: "",
      status: Array<string>(),
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Name / Ref",
            reference: "search",
          },
          models: {
            value: {
              get: (data) => data.search ?? null,
              set: (value, data) => (data.search = value ?? undefined),
            },
          },
        }),
      ],
      status: [
        OptionsAutoCompleteField({
          vModel: "status",
          group: "orderRefundRequestStatus",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
