// Tag

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Tag } from "@kinherit/sdk/model/tag";

export const RecordTag = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/tag/{tag}",
  method: "get",
  rootModel: Tag,
  parse: (tag: Tag | string) => ({
    params: {
      tag,
    },
  }),
});
