// WillRevision, Kintin, User, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { WillRevision } from "@kinherit/sdk/model/will-revision";

export const ReadWillRevision = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/will-revision",
  method: "get",
  rootModel: WillRevision,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});
