//

import { Between, Equal, Like } from "@kinherit/orm/index";
import { Api, EmailCampaign, QueryMask } from "@kinherit/sdk";
import { BrandedKinvaultInvitation } from "@kinherit/sdk/model/branded-kinvault-invitation";

export interface CreateEmailCampaignMessage {
  campaignName: string;
  search?: string;
  status?: Array<string>;
  created?: null | [number, number];
  brandedKinvault: string;
  query?: QueryMask<typeof BrandedKinvaultInvitation>;
}

interface CreateEmailCampaignResponse {
  emailCampaign: EmailCampaign;
}

export const CreateEmailCampaign = async (
  message: CreateEmailCampaignMessage,
): Promise<CreateEmailCampaignResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/admin/branded-kinvault/invitation/email-group/{name}",
    {
      name: message.campaignName,
    },
  )
    .method("put")

    .paginate(false);

  request.buildQuery(BrandedKinvaultInvitation).where({
    brandedKinvault: Equal(message.brandedKinvault),
    profile: {
      fullName: Like(message.search),
    },
    createdAt: Between(message.created),
    ...(message.query ?? {}),
  });

  const response = await request.result();

  const emailCampaign = EmailCampaign.$inflate(response.emailCampaign).first();

  if (!emailCampaign) {
    throw new Error("Email campaign not found");
  }

  return {
    emailCampaign,
  };
};
