var s = Object.defineProperty;
var o = (t, e) => s(t, "name", { value: e, configurable: !0 });
import { defineComponent as r } from "vue";
import { FieldMixin as i } from "../../component.mixin/field-mixin.js";
import { CypressHelper as l } from "../../cypress/component-helpers.js";
import p from "../../_vue/component.input/base-input-field/base-input-field.js";
import n from "../../_vue/component.input/select-field/select-field.js";
import { S as m } from "../../_assets/component.input/url-field/url-field.module.js";
const P = r({
  name: "UrlFieldComponent",
  components: { BaseInputField: p, SelectField: n },
  mixins: [i()],
  props: {
    /**
     * v-model:value
     *
     * @values null, string
     */
    value: {
      type: String,
      default: null
    },
    /**
     * Disable the HTTP/S prefix be automatically added
     */
    noProtocol: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["blur", "update:value"],
  data: /* @__PURE__ */ o((t) => ({
    protocolOptions: { "https://": "https://", "http://": "http://" },
    selectedProtocol: "https://",
    $cypress: l({ ctx: t, labelField: "label" })
  }), "data"),
  computed: {
    computedValue: {
      get() {
        const t = this.value;
        return t === null ? null : t.toLowerCase().startsWith("https://") ? t.substring(8) : t.toLowerCase().startsWith("http://") ? t.substring(7) : t;
      },
      set(t) {
        this.emitValue(this.computedProtocol, t);
      }
    },
    computedProtocol: {
      get() {
        return this.selectedProtocol;
      },
      set(t) {
        this.selectedProtocol = t, this.emitValue(t, this.computedValue);
      }
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("url-field", m);
  },
  methods: {
    emitValue(t, e) {
      if (this.noProtocol) {
        e = (e == null ? void 0 : e.replace(/^(https?:\/\/)/, "")) ?? null, this.$emit("update:value", e);
        return;
      }
      if (e === null) {
        this.$emit("update:value", t);
        return;
      }
      if (e.toLowerCase().startsWith("https://") || e.toLowerCase().startsWith("http://")) {
        this.$emit("update:value", e);
        return;
      }
      this.$emit("update:value", `${t}${e}`);
    }
  }
});
export {
  P as _
};
