// IntroducerFeePaymentRun, IntroducerFeePayment, IntroducerFeePaymentItem

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerFeePaymentRun } from "@kinherit/sdk/model/introducer-fee-payment-run";

export const CreateIntroducerFeePaymentRun = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/fees/payment-run",
  method: "post",
  parse: (target: IntroducerFeePaymentRun) => ({
    body: {
      name: target.$data.name,
      completedAt: target.$data.completedAt,
      introducerFeePaymentsCount: target.$data.introducerFeePaymentsCount,
      feeTypeId: target.$data.feeTypeId,
      status: target.$data.status,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
