var i = Object.defineProperty;
var r = (e, s) => i(e, "name", { value: s, configurable: !0 });
import o from "../../_vue/component.form/form/form.js";
import a from "../../_vue/component.layout/sidebar/sidebar.js";
import { defineComponent as d } from "vue";
import { KernelModes as m } from "../kernel-mode.js";
const h = d({
  name: "PragmaticSidebarLoader",
  components: { Sidebar: a, Form: o },
  data: /* @__PURE__ */ r(() => ({
    sidebarRequests: Array()
  }), "data"),
  computed: {
    isUnitTest() {
      var e;
      return ((e = window.Kernel) == null ? void 0 : e.Mode) === m.UnitTest;
    }
  },
  mounted() {
    window.Kernel.Global.setPragmaticSidebarComponentLoader(
      this.openSidebar
    );
  },
  methods: {
    openSidebar(e) {
      this.sidebarRequests.push({
        request: e,
        value: e.config.form.data(),
        originalValue: e.config.form.data()
      });
    },
    close(e, s) {
      e === !1 ? this.sidebarRequests[s].request.resolve(
        this.sidebarRequests[s].originalValue
      ) : this.sidebarRequests[s].request.resolve(
        this.sidebarRequests[s].value
      ), this.sidebarRequests = this.sidebarRequests.filter(
        (n, t) => t !== s
      );
    }
  }
});
export {
  h as _
};
