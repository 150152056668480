import { Api } from "@kinherit/sdk/api";

//
export const ValidateMembersCsv = async (file: File) => {
  return await Api.resource(
    "portal",
    "/v2/portal/email-campaign/members/csv/validate",
  )
    .method("put")

    .files({
      uploadedFile: file,
    })
    .result();
};
