<template>
  <Card
    :class="[
      'note-summary',
      `note-summary-${note.type.toKebabCase()}`,
      note.isPublic ? 'note-summary-public' : '',
      note.pinned ? 'note-summary-pinned' : '',
    ]"
  >
    <template #heading>
      <Title size="is-5" style="display: flex; align-items: center">
        <Icon
          v-if="note.isPublic"
          v-tool-tip="`Public Note`"
          v-bind="$style.icon.personTie"
        /><Icon
          v-if="note.pinned"
          v-tool-tip="`Pinned`"
          v-bind="$style.icon.pinned"
        />{{ note.name }}
      </Title>
      <div class="has-text-grey">
        {{ note.createdAt.formatDateTime }}
      </div>
      <div v-if="note.completedAt" class="has-text-success">
        Completed: {{ note.completedAt.formatDateTime }}
      </div>
      <div v-else-if="note.dueAt" class="has-text-warning">
        Due: {{ note.dueAt.formatDateTime }}
      </div>

      <div style="display: flex; align-items: start">
        <Badge
          v-if="showKintinName && note.kintin?.friendlyName"
          class="has-text-info is-small is-compact"
        >
          For: {{ note.kintin?.friendlyName }}
        </Badge>

        <Badge
          v-if="note?.contact?.profile?.fullName"
          class="is-purple is-small is-compact"
        >
          For: {{ note?.contact?.profile?.fullName }}
        </Badge>
        <Badge
          v-if="note?.communicationNote?.outcome?.text"
          :icon="badgeOutcomeIcon"
          :color="badgeOutcomeColor"
          class="is-small is-compact"
        >
          {{ note?.communicationNote?.outcome?.text }}
        </Badge>

        <Badge
          v-if="showNoteType"
          class="is-small is-compact"
          :class="{
            'is-warning': note?.isPublic,
            'is-light-grey': !note?.isPublic,
          }"
        >
          {{ note.isPublic ? "Public " : "" }}
          {{ note.pinned ? "Pinned " : "" }}
          {{
            {
              note: "Note" as const,
              introducerNote: "Introducer Note" as const,
              kintinCheck: "Kintin Check" as const,
              kintinCheckNote: "Note" as const,
              kintinCheckFollowUp: "Check - Follow-Up" as const,
              creditNote: "Credit Note" as const,
              communicationNote: "Communication Note" as const,
              callLogNote: `Call Log - ${note.callLog?.direction.text} ${
                note.callLog?.answered === false ? ` (Bounced)` : ``
              }` as const,
              kinvaultCheck: "Kinvault Check" as const,
              kintinStatusChange: "Status Change" as const,
              reminderNote: "Reminder Note" as const,
            }[note.type]
          }}
        </Badge>
        <Badge
          v-if="note.statusLog?.stage?.text || note.statusLog?.status?.text"
          class="is-small is-compact is-light-grey"
        >
          {{ note.statusLog?.stage?.text }} /
          {{ note.statusLog?.status?.text }}
        </Badge>
      </div>
    </template>
    <template #buttons>
      <Icon
        v-if="hasContextMenu"
        class="card-context-menu"
        v-bind="$style.icon.options"
        @click="(event) => $emit('context-menu', event)"
      />
    </template>

    <slot name="before" />

    <blockquote
      :style="{
        whiteSpace: isHtml ? 'normal' : 'pre-wrap',
      }"
      class="note__content"
      v-html="note.notes"
    />

    <div v-if="note.files.isNotEmpty()">
      <div class="has-text-grey">Attachments:</div>
      <ul>
        <li v-for="file in note.files" :key="file.id">
          <a @click="downloadFile(file)"
            >{{ file.fileName }} ({{ file.readableFileSize }})</a
          >
        </li>
      </ul>
    </div>

    <slot name="after" />
  </Card>
</template>

<script lang="ts">
import { StyleService } from "@/service/style.service";
import Badge from "@kinherit/framework/component.display/badge";
import { Icon } from "@kinherit/framework/component.display/icon";
import { Title } from "@kinherit/framework/component.display/title";
import { Card } from "@kinherit/framework/component.layout/card";
import { FileLog, Note } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NoteSummary",
  components: {
    Card,
    Title,
    Icon,
    Badge,
  },
  mixins: [StyleService.mixin],
  props: {
    note: {
      type: Object as PropType<Note>,
      required: true,
    },
    hasContextMenu: {
      type: Boolean,
      default: false,
    },
    showNoteType: {
      type: Boolean,
      default: true,
    },
    showKintinName: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    "context-menu": (event: MouseEvent) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    "download-file": (file: FileLog) => true,
  },
  computed: {
    badgeOutcomeIcon() {
      const type = this.note?.communicationNote?.outcome?.$data?.data?.type;
      if (!type) {
        return null;
      }
      return type === "tel"
        ? this.$style.icon.phone.icon
        : type === "sms"
          ? this.$style.icon.file.icon
          : ["lead-email", "email"].includes(type as string)
            ? this.$style.icon.email.icon
            : this.$style.icon.speechBubble.icon;
    },
    badgeOutcomeColor() {
      const outcome = this.note?.communicationNote?.outcome?.value;
      if (!outcome) {
        return "is-info";
      }
      return [
        "tel-converted",
        "tel-not-converted",
        "lead-email-opening",
        "lead-email-closing",
        "tel-busy",
        "officer-email-opening",
        "officer-email-closing",
      ].includes(outcome)
        ? "is-success"
        : [
              "tel-no-answer",
              "tel-left-answerphone",
              "tel-bad-number",
              "officer-email-bounced",
              "lead-email-bounced",
            ].includes(outcome)
          ? "is-danger"
          : "is-info";
    },
    isHtml() {
      if (!this.note?.notes) {
        return false;
      }
      return /<\/?[a-z][\s\S]*>/i.test(this.note?.notes);
    },
  },
  methods: {
    downloadFile(file: FileLog) {
      this.$emit("download-file", file);
    },
  },
});
</script>

<style lang="scss" scoped>
.note__content {
  margin-left: 0;
  margin-right: 0;
  padding: 0 0.7rem;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
}
.note-summary-public {
  outline: 2px solid $warning;
}
.note-summary-pinned {
  border: 2px solid $info;
}
</style>
