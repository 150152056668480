var d = Object.defineProperty;
var p = (s, t, a) => t in s ? d(s, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : s[t] = a;
var u = (s, t) => d(s, "name", { value: t, configurable: !0 });
var e = (s, t, a) => p(s, typeof t != "symbol" ? t + "" : t, a);
const c = class c extends Error {
  constructor({ message: a, data: o }) {
    super(a);
    e(this, "$internal", !0);
    e(this, "data");
    this.data = o;
  }
};
u(c, "InternalError");
let i = c;
export {
  i as InternalError
};
