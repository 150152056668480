// ContactPartnerPortalPermissions

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";

export const ReadIntroducerContactPartnerPortal = DefineReadAction({
  interface: "portal",
  resource:
    "/v2/portal/introducer/contact/partner-portal-permissions/{introducerContact}",
  method: "get",
  rootModel: IntroducerContact,
  parse: (introducerContact: IntroducerContact | string) => ({
    params: {
      introducerContact,
    },
  }),
});
