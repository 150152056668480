var t = Object.defineProperty;
var o = (e, r) => t(e, "name", { value: r, configurable: !0 });
import { FormBuilder as a } from "./form-builder.js";
const p = /* @__PURE__ */ o((e) => e, "defineFormArea"), s = /* @__PURE__ */ o((e) => new a({
  name: e.name,
  options: e.options,
  props: e.props ?? {},
  data: e.data,
  validate: e.validate,
  formAreas: e.formAreas,
  beforeMount: e.beforeMount
}), "defineForm");
export {
  s as defineForm,
  p as defineFormArea
};
