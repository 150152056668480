<template>
  <DataListWidget title="Details" :details="details" />
</template>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import {
  IntroducerFeePaymentRunDetailsParams,
  IntroducerFeePaymentRunDetailsRoute,
} from ".";
import { IntroducerFeePaymentRunDetailsMixin } from "../../mixin/introducer-fee-payment-run-details.mixin";

export default defineComponent({
  name: `${IntroducerFeePaymentRunDetailsRoute}.sidebar`,
  components: {
    DataListWidget,
  },
  mixins: [IntroducerFeePaymentRunDetailsMixin],
  data: () => ({
    key: 0,
  }),
  computed: {
    $params(): IntroducerFeePaymentRunDetailsParams {
      return this.$route.params as IntroducerFeePaymentRunDetailsParams;
    },
    details(): Array<DataListWidgetDetail> {
      if (!this.introducerFeePaymentRun) {
        return [];
      }

      return [
        {
          label: "Status",
          value: this.introducerFeePaymentRun.status.text,
        },
        {
          label: "Items",
          value:
            this.introducerFeePaymentRun.introducerFeePaymentsCount.toString(),
        },
        {
          label: "Created",
          value: this.introducerFeePaymentRun.createdAt.formatDate,
        },
        {
          label: "Completed",
          value: this.introducerFeePaymentRun.completedAt?.formatDate ?? "N/A",
        },
      ];
    },
  },
  methods: {},
});
</script>
