import { Api } from "@kinherit/sdk/api";
import { Kintin, Order } from "@kinherit/sdk";

// Order
export const VoidOutstandingOrders = async (message: {
  kintin: string | Kintin;
}) => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/status/void-outstanding-orders",
    {
      kintin: message.kintin,
    },
  )
    .method("put")
    .result();

  return {
    orders: Order.$inflate(response.order),
  };
};
