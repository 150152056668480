var f = Object.defineProperty;
var t = (e, o) => f(e, "name", { value: o, configurable: !0 });
import { _ as a } from "../../../core/internal-components/context-menu-loader.vue_vue_type_script_lang.js";
import { resolveComponent as s, createElementBlock as r, openBlock as l, normalizeClass as m, createElementVNode as g, withModifiers as u, Fragment as h, renderList as p, createBlock as C, Teleport as k, withDirectives as F, createVNode as b, vShow as v } from "vue";
import { _ as w } from "../../../_plugins/_plugin-vue_export-helper.js";
function M(e, o, $, B, y, L) {
  const c = s("ContextMenu");
  return l(), r("div", {
    class: m(`modal ${e.requests.length > 0 ? "is-active" : ""} pragmatic-context-menu-loader`)
  }, [
    g("div", {
      style: { opacity: "0.3" },
      class: "modal-background",
      onClickCapture: o[0] || (o[0] = u(() => e.reject(e.requests.length - 1), ["self"]))
    }, null, 32),
    (l(!0), r(h, null, p(e.requests, (i, n) => (l(), C(k, {
      key: `context-menu-${n}`,
      to: ".pragmatic-container",
      disabled: e.isUnitTest
    }, [
      F(b(c, {
        ref_for: !0,
        ref: "refContextMenu",
        items: i.config.items,
        "tracking-field": i.config.trackingField,
        "title-field": i.config.titleField,
        "line1-field": i.config.line1Field,
        "line2-field": i.config.line2Field,
        "icon-left-field": i.config.iconLeftField,
        "icon-right-field": i.config.iconRightField,
        "show-more-button": i.config.showMoreButton,
        "hide-cancel-button": i.config.hideCancelButton,
        "hide-field": i.config.hideField,
        onClick: /* @__PURE__ */ t((d) => e.resolve(n, d), "onClick"),
        onClose: /* @__PURE__ */ t(() => e.reject(n), "onClose")
      }, null, 8, ["items", "tracking-field", "title-field", "line1-field", "line2-field", "icon-left-field", "icon-right-field", "show-more-button", "hide-cancel-button", "hide-field", "onClick", "onClose"]), [
        [v, n + 1 === e.requests.length]
      ])
    ], 8, ["disabled"]))), 128))
  ], 2);
}
t(M, "_sfc_render");
const T = /* @__PURE__ */ w(a, [["render", M]]);
export {
  T as default
};
