<template>
  <div class="officer-details-call-script">
    <PageHeader text="Call Script" htag="h2" />
    <CallScriptSection title="Intro/Security">
      <b>
        Hi there, how are you today? [Put them at ease - can you hear me OK? /
        weather chat etc!]
        <br /><br />OK then, so before we start, I just need you to confirm your
        relationship to {{ primaryFirstName }}
        <span v-if="isJoint">/{{ secondaryFirstName }}</span> for security
        purposes. <br /><br />Great. <br /><br />So just as a reminder, I'm
        {{ specialistName }} and this is a quick chat to explain to you about
        the Will planning that we've helped {{ primaryFirstName }}
        <span v-if="isJoint">/{{ secondaryFirstName }}</span> put together,
        which is basically designed to be very helpful for you when the time
        comes. <br /><br />I'd like to reassure you there's really nothing to
        worry about, or anything for you to do at this stage. This is all future
        planning… we just want you to be aware of it.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Kinherit Intro">
      <b>
        So, just to give you a bit of context…
        <br /><br />We're a law firm specializing in Wills &amp; Trusts, but we
        have a very different approach to other Will firms, because our service
        is also designed to support our clients' families with the probate
        process after their death. <br /><br />Are you familiar with probate?
        Essentially, it's the admin process of tying up someone's affairs after
        they've died. <br /><br />So, along with the Will, we also put planning
        in place to help make the probate process much less stressful.
        <br /><br />But we'll get onto that in just a moment.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Explaining their role">
      <b>
        Firstly, let's just go over the role you've been given.
        <br /><br />So, you may already be aware of this, but just to confirm
        that you've been named as:
      </b>
      <p v-for="role in roles" :key="`role-${role.role}-${role.forPerson?.id}`">
        <span class="my-1 badge is-small"> {{ role.role }} </span><br />
        for {{ role.forPerson?.profile.fullName }}
        <strong v-if="role.isCertProv">Certificate Provider</strong>
        <small v-if="role.notes">{{ role.notes }}</small>
      </p>
      <b>
        We recommend our clients appoint more than one person to each role, so
        we talk about the primary position and a reserve position.
        <br /><br /><strong>As the primary</strong>, you're the first point of
        call. <br /><br /><strong>As the reserve</strong>, you're the back up.
        <br /><br />If the primary person is unable to do the role for any
        reason, the reserve person would be called upon. <br /><br />No one is
        obliged to do a role if they don't want to (so if your circumstances
        change for example). <br /><br />We do offer professional support
        options, should you feel you need some help.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Role Descriptions">
      <b>
        Do you want me to run through the role(s) with you?
        <br /><br /><em>[BEFORE YOU GO THROUGH THEM:]</em> <br /><br />Just so
        you know, I'm not legally allowed to discuss anything that's in the
        Will, so I won't be naming beneficiaries or any other people involved in
        the planning. <br /><br /><b>Executor:</b> <br />So, the executor takes
        responsibility for gathering all the assets together, paying off any
        tax/debts, and then carrying out the wishes stated in the will. Which
        basically means dividing up what's left (after debts etc) and passing
        this on to the right people (the beneficiaries). And then as well as the
        legal side of things, there'll be the practical things like funeral
        wishes, other requests to deal with. <br /><br /><b>Trustee:</b>
        <br />So, as a Trustee, you're responsible for managing any assets held
        in a trust, for the benefit of whoever that trust is created for. Your
        job is to carry out the wishes of the person who set up the trust, and
        act in the best interest of whoever are the beneficiaries. I can send
        you some more information on trusts if you'd like me to? <br /><br /><b
          >Guardian:</b
        >
        <br />So, a Guardian has the legal right and responsibility of taking
        care of someone who is under 18, or who is not able to be responsible
        for his or her own care. <br /><br /><b>Attorney:</b> <br />So this
        relates to ‘Lasting Powers of Attorney' which basically gives you the
        power to make decisions on behalf of the person who nominated you,
        should they become unable to decide things for themselves due to
        accident or illness. These decisions may be on their health, their
        finances, or both.
        <blockquote>
          <br /><b>Health & Welfare Attorneys:</b> <br />You'll be able to make
          decisions on things like the medical care they receive, and the
          location where they receive care. <br /><br /><b
            >Finance & Property Attorneys:</b
          >
          <br />You'll be able to do things like pay bills, deal with their
          bank, sell their property and otherwise manage their affairs for them.
        </blockquote>
        <br />Do you have any questions at all?
      </b>
    </CallScriptSection>
    <CallScriptSection title="The Kinvault Explained">
      <b>
        So, now I'm just going to show you something that you'll find really
        helpful when it comes to carrying out your role(s).
        <br /><br />Let me just share my screen with you… <br /><br /><em
          >[SHARE SCREEN - MAKE SURE THEY CAN SEE IT]</em
        >
        <br /><br />OK, so this is what we call our Kinvault. <br /><br />It's
        basically a secure online vault to store your legal, financial and
        practical information in one central place so nothing gets lost.
        <br /><br />
        {{ primaryFirstName }}
        <span v-if="isJoint">/{{ secondaryFirstName }}</span> has one of these,
        which they'll fill with their own information. <br /><br />So, as you
        can see, it's laid out in 8 sections, which helps to guide you through
        all the different types of information you would need for probate.
        <br /><br />[NAME EACH FOLDER AND GIVE AN OUTLINE OF WHAT'S IN IT]
        <br /><br />
        Let me quickly dive into the Assets section to give you an idea of how
        things are laid out. <br /><br />[SHOW ASSETS SECTION] <br /><br />You
        can see this has sub folders so things can be organized easily [RUN
        THROUGH DIFFERENT FOLDERS QUICKLY] <br /><br />It just means everything
        is there to pass on and you won't have to trawl through endless amounts
        of paperwork. <br /><br />Essentially, it's the box under the bed, but
        much better…
      </b>
    </CallScriptSection>
    <CallScriptSection title="Key Message">
      <b>
        As you can see, the Kinvault is going to be really useful to you, but in
        order for you to have access to it, you're going to need to get in touch
        with us.
        <br /><br />So, the ONE THING YOU'LL NEED TO REMEMBER IS TO CALL US IF
        SOMETHING HAPPENS TO {{ primaryFirstName }}
        <span v-if="isJoint">/{{ secondaryFirstName }}</span> so we can pass the
        Kinvault over to you. <br /><br />We have a bereavement team here at
        Kinherit, who will arrange the handover, and they also give you further
        support if you need it at the time. <br /><br />So, just to repeat, the
        key thing to remember is to get in touch with us if something happens.
        <br /><br />Does that all sound okay, did you have any questions at all?
        <br /><br />[STOP SHARING SCREEN TO END DEMO]
      </b>
    </CallScriptSection>
    <CallScriptSection title="Summing Up">
      <b>
        So, I'll be sending a follow up email to you with all our contact
        information so you know how to reach us.
        <br /><br />We also send a letter in the post. <br /><br />Can you just
        confirm your address for me? Great. <br /><br />Last thing - IF YOU ARE
        interested in your own planning, I am happy to send over a little bit
        about Kinherit and how we work with you. <br /><br />Would you like me
        to send this over to you? <br /><br />Well, thank you for your time
        today, and just get in touch if you have any questions. <br /><br />Have
        a great day/afternoon/evening.
      </b>
    </CallScriptSection>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultOfficerDetailsCallScriptWrapper",
  "route": "KinvaultOfficerDetailsCallScript",
  "selector": ".officer-details-call-script"
}
</cypress-wrapper>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { Person } from "@kinherit/sdk";

import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { KinvaultOfficerDetailsCallScriptRoute } from ".";
import { KinvaultOfficerDetailsMixin } from "../../../mixin/kinvault-officer-details.mixin";

export default defineComponent({
  name: KinvaultOfficerDetailsCallScriptRoute,
  components: { PageHeader, CallScriptSection },
  mixins: [
    KinvaultOfficerDetailsMixin,
    StyleService.mixin,
    AuthService.mixin(),
  ],
  computed: {
    specialistName(): string {
      return this.$auth.loggedInUser?.profile.firstName as string;
    },
    primaryFullName(): string {
      return this.officer?.kintin?.primaryPerson?.profile?.fullName as string;
    },
    primaryFirstName(): string {
      return this.officer?.kintin?.primaryPerson?.profile?.firstName as string;
    },
    secondaryFullName(): string {
      return this.officer?.kintin?.secondaryPerson?.profile?.fullName as string;
    },
    secondaryFirstName(): string {
      return this.officer?.kintin?.secondaryPerson?.profile
        ?.firstName as string;
    },
    isJoint(): boolean {
      return this.officer?.kintin?.type.value === "joint";
    },
    officerFullName(): string {
      return this.officer?.profile?.fullName as string;
    },
    roles(): {
      role: string;
      forPerson: Person | null;
      notes: string | null;
      isCertProv: boolean;
    }[] {
      return [
        ...(this.$data.officer?.trusteeFor ?? []),
        ...(this.$data.officer?.executorFor ?? []),
        ...(this.$data.officer?.attorneyFor ?? []),
        ...(this.$data.officer?.guardianFor ?? []),
      ].map((officer) => ({
        role: `${officer.$name}${
          `isReserve` in officer && officer.isReserve === true
            ? ` (Reserve)`
            : ``
        }`,
        forPerson: officer.forPerson,
        notes: "notes" in officer ? officer.notes : null,
        isCertProv:
          "isCertProv" in officer ? true === officer.isCertProv : false,
      }));
    },
  },
});
</script>
