var u = Object.defineProperty;
var i = (t, e) => u(t, "name", { value: e, configurable: !0 });
import { FieldMixin as o } from "../../component.mixin/field-mixin.js";
import { TranslationMixin as l } from "../../component.mixin/translation.mixin.js";
import { CypressHelper as s } from "../../cypress/component-helpers.js";
import { ThemeIconName as a } from "../../theme/prop/icon.js";
import { WorldInfo as n } from "@kinherit/ts-common/service/world-info";
import { AsYouType as f } from "libphonenumber-js/max";
import { defineComponent as h, ref as m } from "vue";
import c from "../../_vue/component.input/auto-complete-field/auto-complete-field.js";
import d from "../../_vue/component.input/base-input-field/base-input-field.js";
import p from "../../_vue/component.input/button/button.js";
import { F as y } from "../../_assets/component.input/phone-number-field/flag.module.js";
const L = h({
  name: "PhoneNumberFieldComponent",
  components: { Button: p, AutoCompleteField: c, BaseInputField: d },
  mixins: [
    o({ validators: ["phone-number"] }),
    l({
      defaults: {
        selectCountry: "Select country",
        finishSelectingCountry: "Finish selecting country"
      }
    })
  ],
  props: {
    /**
     * v-model:value
     *
     * @values null, string
     */
    value: {
      type: String,
      default: null
    },
    /**
     * Hide the country lookup button
     */
    hideCountryLookup: {
      type: Boolean,
      default: !1
    }
  },
  emits: [
    /**
     * @property {boolean}
     */
    "is-valid",
    /**
     * @property {string}
     */
    "update:value",
    "blur"
  ],
  setup() {
    return {
      baseInput: m(null)
    };
  },
  data: /* @__PURE__ */ i((t) => ({
    countries: n.countries,
    selectingCountry: !1,
    overrideCountry: null,
    defaultValidationIsValid: !1,
    $cypress: s({ ctx: t, labelField: "label" })
  }), "data"),
  computed: {
    computedValidators() {
      return this.validators.concat(["phone-number"]);
    },
    iconLeftComputed() {
      return this.iconLeft !== null ? this.iconLeft : a.Phone;
    },
    country: {
      get() {
        return this.overrideCountry !== null ? this.overrideCountry : typeof this.value != "string" ? null : this.guessCountry();
      },
      set(t) {
        let e = this.value;
        if (this.country !== null && e !== null && this.country.dialer.forEach((r) => {
          e.startsWith(`+${r}`) && (e = e.substring(r.length + 1));
        }), this.overrideCountry = t, t === null) {
          this.computedPhoneNumber = e;
          return;
        }
        this.selectingCountry = !1, this.computedPhoneNumber = `${t.dialer[0]}${e ?? ""}`;
      }
    },
    flag() {
      return this.country === null ? "" : this.country.code;
    },
    computedPhoneNumber: {
      get() {
        const t = this.country;
        if (t === null || typeof this.value != "string")
          return this.value;
        let e = this.value;
        return t.dialer.forEach((r) => {
          this.value.startsWith(r) && this.value !== r && (e = `+${r}${this.value.substring(r.length)}`);
        }), e;
      },
      set(t) {
        this.$emit("update:value", t);
      }
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("flag", y);
  },
  methods: {
    formatPhoneNumber(t) {
      if (t === null)
        return null;
      const e = this.guessCountry(t);
      return e === null ? t : new f(e.code.toUpperCase()).input(this.value);
    },
    guessCountry(t = null) {
      return this.overrideCountry !== null ? this.overrideCountry : (t = t === null ? this.value : t, typeof t != "string" || t.startsWith("+") === !1 ? n.getCountry("GB") : (t = t.replace(/\D/g, ""), this.countries.find(
        (e) => e.dialer.filter(
          (r) => t.startsWith(r)
        ).length > 0
      ) ?? null));
    },
    blur() {
      this.$emit("update:value", this.formatPhoneNumber(this.computedValue)), this.$emit("blur");
    }
  }
});
export {
  L as _
};
