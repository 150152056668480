// KintinAccess

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { KintinAccess } from "@kinherit/sdk/model/kintin-access";

export const DeleteKintinAccess = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/contact/kintin-access/{kintinAccess}",
  method: "delete",
  parse: (kintinAccess: KintinAccess | string) => ({
    params: {
      kintinAccess,
    },
  }),
  after: ({ message }) => {
    KintinAccess.$delete(message);
  },
});
