// Profile, EmailAddress

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Profile } from "@kinherit/sdk";
import { EmailCampaign } from "@kinherit/sdk/model/email-campaign";

export const AddProfile = DefineWriteAction({
  interface: "portal",
  resource:
    "/v2/portal/email-campaign/profile/{emailCampaign}/profiles/{profile}/add",
  method: "post",
  parse: (message: { emailCampaign: EmailCampaign; profile: Profile }) => ({
    body: {},
    params: message,
  }),
});
