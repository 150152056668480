var E = Object.defineProperty;
var u = (e, n) => E(e, "name", { value: n, configurable: !0 });
import { _ as b } from "../../../component.display/json/json.vue_vue_type_script_lang.js";
import { resolveComponent as m, createElementBlock as t, openBlock as s, createElementVNode as d, toDisplayString as o, createVNode as A, withModifiers as g, normalizeClass as $, createBlock as p, createTextVNode as a, Fragment as k, renderList as f } from "vue";
import { _ as v } from "../../../_plugins/_plugin-vue_export-helper.js";
const j = ["open"], C = { class: "json" }, h = ["type"], z = { key: 2 }, B = { key: 3 }, J = {
  key: 4,
  type: "array"
}, N = {
  key: 5,
  type: "array"
}, S = { style: {
  verticalAlign: "middle"
} }, V = ["type"], w = {
  key: 7,
  type: "object"
}, I = { style: {
  verticalAlign: "middle"
} }, K = ["type"], L = {
  key: 9,
  type: "function",
  class: "is-block m-3 p-3",
  style: {
    borderLeft: "1px solid var(--color-text)"
  }
}, M = ["type"];
function D(e, n, F, O, P, T) {
  const r = m("JsonComponent"), y = m("Icon");
  return e.nested === 0 ? (s(), t("details", {
    key: 0,
    class: "json-component is-primary is-light is-fullwidth",
    open: e.isOpen
  }, [
    d("summary", null, o(e.title), 1),
    d("pre", C, [
      A(r, {
        title: null,
        data: e.data,
        nested: e.nested + 1
      }, null, 8, ["data", "nested"])
    ])
  ], 8, j)) : (s(), t("span", {
    key: 1,
    type: e.nested === 1 ? "root" : "property",
    class: $({
      "is-clickable": e.isExpandable,
      "is-unclickable": !e.isExpandable
    }),
    onClick: n[0] || (n[0] = g((l) => e.isExpanded = !e.isExpanded, ["stop"]))
  }, [
    e.isExpandable && !e.isExpanded ? (s(), p(y, {
      key: 0,
      icon: "Plus",
      size: "is-small"
    })) : e.isExpandable && e.isExpanded ? (s(), p(y, {
      key: 1,
      size: "is-small",
      icon: "Minus"
    })) : (s(), t("span", z, "  ")),
    a(" " + o(e.depth) + o(e.title ? `${e.title}: ` : ""), 1),
    e.data === null || e.data === void 0 ? (s(), t("span", B, o(e.data === null ? "null" : "undefined"), 1)) : Array.isArray(e.data) && e.isExpanded ? (s(), t("span", J, [
      n[1] || (n[1] = a("[")),
      n[2] || (n[2] = d("br", null, null, -1)),
      (s(!0), t(k, null, f(e.data, (l, i) => (s(), p(r, {
        key: i,
        title: i.toString(),
        data: l,
        nested: e.nested + 1
      }, null, 8, ["title", "data", "nested"]))), 128)),
      a(o(e.depth) + "   ] ", 1)
    ])) : Array.isArray(e.data) && !e.isExpanded ? (s(), t("span", N, [
      n[3] || (n[3] = a("[")),
      d("small", S, "(" + o(e.data.length) + ")", 1),
      n[4] || (n[4] = a("]"))
    ])) : typeof e.data == "object" && e.isExpanded ? (s(), t("span", {
      key: 6,
      type: `${typeof e.data}`
    }, [
      n[5] || (n[5] = a("{")),
      n[6] || (n[6] = d("br", null, null, -1)),
      (s(!0), t(k, null, f(e.computedKeys, (l, i) => (s(), p(r, {
        key: i,
        title: l,
        data: e.data[l],
        nested: e.nested + 1
      }, null, 8, ["title", "data", "nested"]))), 128)),
      a(o(e.depth) + "   } ", 1)
    ], 8, V)) : typeof e.data == "object" && !e.isExpanded ? (s(), t("span", w, [
      n[7] || (n[7] = a("{")),
      d("small", I, "(" + o(e.computedKeys.length) + ")", 1),
      n[8] || (n[8] = a("}"))
    ])) : e.isExpandable && !e.isExpanded ? (s(), t("span", {
      key: 8,
      type: `${typeof e.data}`
    }, o(typeof e.data), 9, K)) : typeof e.data == "function" ? (s(), t("span", L, o(e.data.toString()), 1)) : (s(), t("span", {
      key: 10,
      type: `${typeof e.data}`
    }, o(e.data), 9, M)),
    n[9] || (n[9] = d("br", null, null, -1))
  ], 10, h));
}
u(D, "_sfc_render");
const R = /* @__PURE__ */ v(b, [["render", D]]);
export {
  R as default
};
