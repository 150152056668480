import { Portal2LeadAccountReferral } from "@/action-bus/portal/lead/account-referral";
import { ToggleSecondaryProfile } from "@/action-bus/portal/lead/toggle-secondary-profile.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateKintinFromLead } from "./create-kintin-from-lead.action";
import { DeleteLead } from "./delete-lead.action";
import { ReadHistory } from "./read-history.action";
import { RecordAccountReferralCode } from "./record-account-referral-code.action";
import { RecordIntroducerContact } from "./record-introducer-contact.action";
import { RecordKintin } from "./record-kintin.action";
import { RecordLead } from "./record-lead.action";
import { ReplaceProfile } from "./replace-profile.action";
import { UpdateAccountReferral } from "./update-account-referral.action";
import { UpdateLead } from "./update-lead.action";

export const Portal2Lead = DefineActionBus({
  name: "portal-lead",
  actions: {
    accountReferral: Portal2LeadAccountReferral,
    RecordLead,
    UpdateLead,
    DeleteLead,
    ReadHistory,
    RecordKintin,
    UpdateAccountReferral,
    RecordIntroducerContact,
    RecordAccountReferralCode,
    UpdateLeadProfile: ReplaceProfile,
    CreateKintinFromLead,
    ToggleSecondaryProfile,
  },
});
