<template>
  <Card
    title="Cpd Session Attendance"
    subtitle="Details of attendance for CPD sessions"
    class="introducer-contact-details-cpd-session"
  >
    <Table
      v-model:page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      class="cpd-session-attendance-table"
      is-scrollable
      enable-per-page
      :total-pages="pagination.lastPage"
      :total-records="pagination.count"
      :columns="columns"
      :rows="rows"
      is-fullwidth
      is-narrow
      is-hoverable
      is-clickable
      :sort-by-options="{
        'session.date': 'Date',
        createdAt: 'Created',
      }"
      @update:page="refresh"
      @update:per-page="refresh"
      @update:sort-by="refresh"
      @update:sort-direction="refresh"
    />
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerContactDetailsCpdSessionWrapper",
  "route": "IntroducerContactDetailsCpdSession",
  "selector": ".introducer-contact-details-cpd-session",
  "imports": {
    "TableWrapper": "@kinherit/framework/component.display/table/table.test"
  },
  "methods": {
    "cpdSessionAttendanceTable": {
      "type": "to-one",
      "selector": ".cpd-session-attendance-table",
      "wrapper": "TableWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CpdAttendanceTableColumns } from "@/config/table-columns.config";
import { Table } from "@kinherit/framework/component.display/table";
import { Card } from "@kinherit/framework/component.layout/card";
import { IIntroducerCpd, IntroducerCpd } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerContactDetailsCpdSessionRoute } from ".";
import { IntroducerContactDetailsMixin } from "../../../mixin/introducer-contact-details.mixin";
export default defineComponent({
  name: IntroducerContactDetailsCpdSessionRoute,
  components: {
    Table,
    Card,
  },
  mixins: [IntroducerContactDetailsMixin],
  data: () => ({
    columns: CpdAttendanceTableColumns,
    rows: Array<IntroducerCpd>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "session.date" as any as keyof IIntroducerCpd,
      direction: "desc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      // const { introducerCpds, pagination } =
      //   await window.Kernel.ActionBus.introducerContact.readCpd({
      //     ...this.$params,
      //     pagination: this.pagination,
      //     sort: this.sort,
      //   });
      const { introducerCpd, $pagination } =
        await this.$actionBus.contact.ReadIntroducerCpds({
          message: this.$params.introducerContact,
          pagination: this.pagination,
          sort: this.sort,
        });

      this.rows = introducerCpd;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
