var n = Object.defineProperty;
var t = (e, o) => n(e, "name", { value: o, configurable: !0 });
import { _ as c } from "../../../core/internal-components/loading-overlay-loader.vue_vue_type_script_lang.js";
import { resolveComponent as s, createElementBlock as i, openBlock as a, createBlock as d, createCommentVNode as m, Teleport as p, createVNode as l } from "vue";
import { _ } from "../../../_plugins/_plugin-vue_export-helper.js";
const f = { class: "pragmatic-loading-loader" };
function g(e, o, u, k, L, $) {
  const r = s("Loading");
  return a(), i("div", f, [
    e.show ? (a(), d(p, {
      key: 0,
      to: ".pragmatic-container",
      disabled: e.isUnitTest
    }, [
      l(r)
    ], 8, ["disabled"])) : m("", !0)
  ]);
}
t(g, "_sfc_render");
const C = /* @__PURE__ */ _(c, [["render", g]]);
export {
  C as default
};
