var l = Object.defineProperty;
var t = (e, r) => l(e, "name", { value: r, configurable: !0 });
import { ThemeColor as o } from "../../theme/prop/color.js";
import { ThemeDirection as i } from "../../theme/prop/direction.js";
import { ThemeShade as a } from "../../theme/prop/shade.js";
import { ThemeSize as s } from "../../theme/prop/size.js";
import { defineComponent as n } from "vue";
import { S as u } from "../../_assets/component.display/progress/progress.module.js";
const y = n({
  name: "ProgressComponent",
  props: {
    /**
     * Predefined color to style the component with
     */
    color: {
      type: String,
      default: "is-primary",
      validator: /* @__PURE__ */ t((e) => Object.values(o).includes(e), "validator"),
      value: o
    },
    shade: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((e) => Object.values(a).includes(e), "validator"),
      value: o
    },
    /**
     * current progress
     */
    progress: {
      type: Number,
      default: null
    },
    /**
     * Maxiumim progress
     */
    max: {
      type: Number,
      default: 100
    },
    /**
     * Size of the progress bar
     */
    size: {
      type: String,
      default: s.isNormal,
      validator: /* @__PURE__ */ t((e) => Object.values(s).includes(e), "validator"),
      value: s
    },
    direction: {
      type: String,
      default: i.isHorizontal,
      validator: /* @__PURE__ */ t((e) => Object.values(i).includes(e), "validator"),
      value: i
    }
  },
  computed: {
    classes() {
      const e = {
        [this.size]: !0,
        [this.direction]: !0,
        [this.color]: !0,
        [this.shade]: !!this.shade
      };
      return Object.keys(e).filter((r) => e[r]).join(" ");
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("progress", u);
  }
});
export {
  y as _
};
