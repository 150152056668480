import { AddressField } from "@/config/form.config";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import { Address, Kintin, Profile } from "@kinherit/sdk";
// import { Equal } from "@kinherit/orm";

export const CreateRoyalMailOrderForm = (
  profile: Profile,
  kintin: Kintin,
  data = {
    address: null as null | Address,
  },
) => {
  return defineForm({
    name: "create-royal-mail-order-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "create-royal-mail-order-form-area",
        data,
        template: GridLayout([["intro"], ["address"]]),
        components: () => ({
          intro: [
            FormTitle({
              props: {
                isSubtitle: true,
                text: `Create Royal Mail Order for ${kintin.friendlyName}, REF: ${kintin.ref}, largeLetter, 100 g`,
              },
            }),
          ],
          address: [
            AddressField({
              props: {
                label: "Select Mailing Address",
                vModel: "address",
                message:
                  "Note, this will only select addresses which are marked as primary mailing address",
              },
              query: {
                primaryMailing: Equal(true),
                profile: Equal(profile.id),
              },
            }),
          ],
        }),
      }),
    ],
  });
};
