var n = Object.defineProperty;
var f = (e, a, t) => a in e ? n(e, a, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[a] = t;
var d = (e, a) => n(e, "name", { value: a, configurable: !0 });
var u = (e, a, t) => f(e, typeof a != "symbol" ? a + "" : a, t);
import { ValidationType as g } from "../core/validation-type.js";
const N = "regex", c = /^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/, E = /^\d{5}$/, o = {
  /** Validates a fraction (12/345) */
  FRACTION: {
    regex: /^-?\d+\/\d+$/,
    message: "Please enter a valid fraction. (e.g. 12/345)"
  },
  /** Validates a postcode */
  POSTCODE: {
    regex: [c, E],
    message: "Please enter a valid postcode. (e.g. AB1 2CD or 12345)"
  }
}, y = /* @__PURE__ */ d((e) => {
  var a;
  return a = class {
    constructor(t) {
      this.data = t;
    }
    getResult() {
      if (typeof this.data.value != "string" || this.data.value === "")
        return null;
      if ("regex" in e) {
        if (new RegExp(e.regex).test(this.data.value))
          return null;
      } else if ("buildin" in e) {
        const { message: t, regex: r } = o[e.buildin];
        if (e.buildin === "FRACTION") {
          const [s, m] = this.data.value.split("/"), l = parseInt(s), i = parseInt(m);
          if (isNaN(l) || isNaN(i) || l > i || l < 0 || i < 0)
            return {
              type: e.type ?? g.Error,
              message: e.message ?? t
            };
          if (Array.isArray(r)) {
            if (r.some((A) => A.test(this.data.value)))
              return null;
          } else if (r.test(this.data.value))
            return null;
        } else if (Array.isArray(r)) {
          if (r.some((s) => s.test(this.data.value)))
            return null;
        } else if (r.test(this.data.value))
          return null;
        e.message = e.message ?? t;
      } else
        throw new Error(`Invalid options: ${JSON.stringify(e)}`);
      return {
        type: e.type ?? g.Error,
        message: typeof e == "string" ? e : e.message ?? "Please enter a valid value."
      };
    }
  }, u(a, "validatorName", N), a;
}, "RegexValidatorConstructor");
export {
  y as RegexValidatorConstructor,
  N as RegexValidatorName
};
