// Lead

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Lead } from "@kinherit/sdk/model/lead";

export const UpdateLead = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/leads/{lead}",
  method: "patch",
  parse: (target: Lead) => ({
    body: {
      description: target.$data.description,
      noteCount: target.$data.noteCount,
      primaryProfile: target.$data.primaryProfile,
      secondaryProfile: target.$data.secondaryProfile,
      assignedTo: target.$data.assignedTo,
      ownedBy: target.$data.ownedBy,
      referringKintin: target.$data.referringKintin,
      status: target.$data.status,
      referral: {
        externalId: target.referral.$data.externalId,
      },
    },
    params: {
      lead: target,
    },
  }),
  after: ({ message }) => {
    message.$persist();
  },
});
