import { Api } from "@/service/api.service";
import { User } from "@kinherit/sdk";

export interface ReadDashboardStatisticLAMessage {
  selected: User;
  timeFrame: "4wk" | "8wk" | "12wk";
}

export interface ReadDashboardStatisticLAResponse {
  leads: {
    inProgress: {
      currentCount: number;
      overdueCount: number;
      overduePercentage: number;
    };
    created: {
      volume: number;
      converted: number;
      nurture: number;
      qualifiedOut: number;
    };
  };
  accounts: {
    inProgress: {
      currentCount: number;
      overdueCount: number;
      overduePercentage: number;
    };
    breakdown: { e1: number; f: number; g1: number; g2: number; g3: number };
  };
  calls: {
    total: number;
    outgoing: number;
    answered: number;
    internal: number;
  };
  appointments: {
    total: number;
    kinvault: number;
    officer: number;
    cancelled: number;
  };
}

export const ReadStatistics = async (
  message: ReadDashboardStatisticLAMessage,
): Promise<ReadDashboardStatisticLAResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/dashboard/statistics/la",
  )
    .method("get")
    .params({
      userId: message.selected.id,
      timeFrame: message.timeFrame,
    })
    .result();
  return response;
};
