var i = Object.defineProperty;
var r = (e, t) => i(e, "name", { value: t, configurable: !0 });
import { TranslationMixin as s } from "../../component.mixin/translation.mixin.js";
import { ThemeSize as o } from "../../theme/prop/size.js";
import { findColorInvert as n } from "@kinherit/ts-common/service/colors";
import { defineComponent as l } from "vue";
import a from "../../_vue/component.display/icon/icon.js";
import { S as m } from "../../_assets/component.display/avatar/avatar.module.js";
const C = l({
  name: "AvatarComponent",
  components: { Icon: a },
  mixins: [
    s({
      defaults: {
        tooltips: {
          empty: "Empty"
        }
      }
    })
  ],
  props: {
    /**
     * Change the size
     */
    size: {
      type: String,
      default: o.isNormal,
      validator: /* @__PURE__ */ r((e) => Object.values(o).includes(e), "validator"),
      value: o
    },
    /**
     * The name of the badge
     */
    name: {
      type: String,
      default: "",
      value: "text"
    },
    /**
     * Hide the tooltip
     */
    hideTooltip: {
      type: Boolean,
      default: !1
    },
    /**
     * Show label
     */
    showLabel: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["click"],
  computed: {
    tooltipText() {
      return this.hideTooltip ? null : this.name ?? this.$t.local.tooltips.empty;
    },
    computedName() {
      if (!this.name)
        return "";
      const e = this.name.split(" ").map((t) => t.substring(0, 1));
      switch (e.length) {
        case 0:
          return "";
        case 1:
          return e[0];
        default:
          return `${e[0]}${e.last()}`.toUpperCase();
      }
    },
    classes() {
      let e = "";
      return this.size && (e = `${e} ${this.size}`), e;
    },
    color() {
      return this.name === "" || this.name === null ? "#b5b5b5" : this.makeColor(this.name);
    },
    invertColor() {
      return n(this.color);
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("avatar", m);
  },
  methods: {
    makeColor(e) {
      return window.Kernel.Theme.Colors.stringToColor(e);
    }
  }
});
export {
  C as _
};
