import {
  AccountReferralCodeField,
  IntroducerCompanyField,
} from "@/config/form.config";
import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { BrandedKinvaultCta } from "@kinherit/sdk";

export const UpdateBrandedKinvaultCtaForm = (
  brandedKinvaultCta: BrandedKinvaultCta,
) =>
  defineForm({
    name: "update-branded-kinvault-cta",
    data: () => brandedKinvaultCta,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: GridLayout([
          ["internalName", "title"],
          ["displayFrom", "displayTo"],
          ["introducerCompany", "referralCodes"],
          ["position", "template"],
          ["language", "country"],
          ["categories"],
        ]),
        components: () => ({
          introducerCompany: [
            IntroducerCompanyField({
              props: {
                label: "Introducer Company",
                vModel: "introducerCompany",
              },
            }),
          ],
          title: [
            FormTextField({
              props: {
                label: "Title",
              },
              models: {
                value: "title",
              },
            }),
          ],
          displayFrom: [
            FormDateField({
              props: {
                label: "Display From",
              },
              models: {
                value: "displayFrom",
              },
            }),
          ],
          displayTo: [
            FormDateField({
              props: {
                label: "Display To",
              },
              models: {
                value: "displayTo",
              },
            }),
          ],
          referralCodes: [
            AccountReferralCodeField({
              props: {
                label: "Referral Codes",
                isMultiSelect: true,
                vModel: "referralCodes",
              },
            }),
          ],
          internalName: [
            FormTextField({
              props: {
                label: "Internal Name",
              },
              models: {
                value: "internalName",
              },
            }),
          ],
          position: [
            FormSelectField({
              props: {
                label: "Position",
                options: {
                  top: "Top",
                  bottom: "Bottom",
                },
              },
              models: {
                value: "position",
              },
            }),
          ],
          template: [
            FormSelectField({
              props: {
                label: "Template",
                options: {
                  default: "Default",
                },
              },
              models: {
                value: "template",
              },
            }),
          ],
          categories: [
            FormAutoCompleteField({
              props: {
                label: "Categories",
                isMultiSelect: true,
                options: {
                  "assets-accounts": "Assets - Accounts",
                  other: "Other",
                  "after-death-personal": "After Death - Personal",
                  "after-death-funeral": "After Death - Funeral",
                  contacts: "Contacts",
                  "care-plan": "Care Plan",
                  "admin-other": "Admin - Other",
                  "admin-work": "Admin - Work",
                  "admin-pets": "Admin - Pets",
                  "admin-contractors": "Admin - Contractors",
                  "admin-vehicles": "Admin - Vehicles",
                  "admin-insurance": "Admin - Insurance",
                  "admin-utilities": "Admin - Utilities",
                  "legal-after": "Legal - After",
                  "legal-before": "Legal - Before",
                  "assets-other": "Assets - Other",
                  "after-death-other": "After Death - Other",
                  "legal-other": "Legal - Other",
                  physicalstorage: "Physical Storage",
                  "contacts-uploads": "Contacts - Uploads",
                  "contacts-other-people": "Contacts - Other People",
                  "contacts-trusted-people": "Contacts - Trusted People",
                  "contacts-companies": "Contacts - Companies",
                  "contacts-charities": "Contacts - Charities",
                  "contacts-account-holders": "Contacts - Account Holders",
                  "assets-valuables": "Assets - Valuables",
                  "assets-uploads": "Assets - Uploads",
                  "assets-reports": "Assets - Reports",
                  "assets-properties": "Assets - Properties",
                  "assets-policies": "Assets - Policies",
                  "assets-pensions": "Assets - Pensions",
                  "assets-lifetime-gifts": "Assets - Lifetime Gifts",
                  "assets-investments": "Assets - Investments",
                  "assets-debts": "Assets - Debts",
                  "assets-business-assets": "Assets - Business Assets",
                },
              },
              models: {
                value: "categories",
              },
            }),
          ],
          language: [
            FormSelectField({
              props: {
                label: "Language",
                options: {
                  en: "English",
                } satisfies Record<BrandedKinvaultCta["language"], string>,
              },
              models: {
                value: "language",
              },
            }),
          ],
          country: [
            FormSelectField({
              props: {
                label: "Country",
                options: {
                  gb: "United Kingdom",
                  us: "United States",
                } satisfies Record<BrandedKinvaultCta["country"], string>,
              },
              models: {
                value: "country",
              },
            }),
          ],
        }),
      }),
    ],
  });
