var s = Object.defineProperty;
var a = (e, o) => s(e, "name", { value: o, configurable: !0 });
import { DashLoader as c } from "@kinherit/ts-common";
import { defineComponent as i } from "vue";
const r = {
  "en-GB": {
    foo: {
      bar: "Hello World"
    }
  }
}, d = /* @__PURE__ */ a(() => {
  const e = "en-GB", o = window.Kernel.Locale, n = r[e], t = r[o] || {};
  return c.merge({
    ...n,
    ...t
  });
}, "$t"), f = /* @__PURE__ */ a(({
  defaults: e,
  overrides: o = {}
}) => i({
  name: "TranslationMixin",
  computed: {
    $t() {
      const n = window.Kernel.Locale, t = o[n] || {}, l = c.merge({
        ...e,
        ...t
      });
      return { global: d(), local: l };
    }
  }
}), "TranslationMixin");
export {
  d as $t,
  f as TranslationMixin
};
