<template>
  <MOW trust-name="DISCRETIONARY TRUST OF RESIDUE (including RNRB)" />
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";
import MOW from "../shared/MOW.vue";

export default defineComponent({
  components: { MOW },
  mixins: [WillBuilderService.mixin],
});
</script>
