var m = Object.defineProperty;
var n = (e, s) => m(e, "name", { value: s, configurable: !0 });
import { _ as p } from "../../../component.form/field/field.vue_vue_type_script_lang.js";
import { resolveComponent as f, createElementBlock as a, openBlock as l, normalizeClass as o, createCommentVNode as r, createElementVNode as u, renderSlot as i, createBlock as c } from "vue";
import { _ as g } from "../../../_plugins/_plugin-vue_export-helper.js";
const b = ["for", "innerHTML"];
function k(e, s, C, $, F, M) {
  const t = f("FieldMessage");
  return l(), a("div", {
    class: o(["field", e.compFieldClasses]),
    onClick: s[0] || (s[0] = (d) => e.$emit("click", d))
  }, [
    e.label !== null && !e.hideLabel ? (l(), a("label", {
      key: 0,
      for: e.inputId,
      class: o(["field__label label", e.size]),
      innerHTML: e.label
    }, null, 10, b)) : r("", !0),
    u("div", {
      class: o(["field__content", e.compFieldContentClasses])
    }, [
      i(e.$slots, "default")
    ], 2),
    i(e.$slots, "below"),
    e.messages !== null && e.messages.length > 0 && !e.hideMessage ? (l(), c(t, {
      key: 1,
      messages: e.messages
    }, null, 8, ["messages"])) : r("", !0)
  ], 2);
}
n(k, "_sfc_render");
const y = /* @__PURE__ */ g(p, [["render", k]]);
export {
  y as default
};
