import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultSubscriptionReferralCode } from "@kinherit/sdk";

export const ReadKinvaultSubscriptionReferralCode = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvaults/subscription-referral-codes",
  method: "get",
  parse: () => ({}),
  rootModel: KinvaultSubscriptionReferralCode,
});
