import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateFileLog } from "./create-file-log.action";
import { DeleteFileLog } from "./delete-file-log.action";
import { DownloadFileLog } from "./download-file.action";
import { UpdateFileLog } from "./update-file-log.action";

export const Portal2KnowledgeBaseCategoryDocumentFileLog = DefineActionBus({
  name: "portal-knowledge-base-category-document-file-log",
  actions: {
    CreateFileLog,
    DeleteFileLog,
    UpdateFileLog,
    DownloadFileLog,
  },
});
