// Product, IntroducerCompany, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Product } from "@kinherit/sdk";

export const ReadProduct = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/product",
  method: "get",
  rootModel: Product,
  parse: () => ({}),
});
