var f = Object.defineProperty;
var n = (r, t) => f(r, "name", { value: t, configurable: !0 });
import { PhoneNumberValidatorConstructor as c, PhoneNumberValidatorName as d } from "./validator/phone-number-validator.js";
import { AgeOverValidatorConstructor as p, AgeOverValidatorName as V } from "./validator/age-over-validator.js";
import { CheckedValidatorConstructor as C, CheckedValidatorName as g } from "./validator/checked-validator.js";
import { EmailValidatorConstructor as v, EmailValidatorName as N } from "./validator/email-validator.js";
import { FileLimitValidatorConstructor as b, FileLimitValidatorName as h } from "./validator/file-limit-validator.js";
import { FileSizeValidatorConstructor as y, FileSizeValidatorName as w } from "./validator/file-size-validator.js";
import { RegexValidatorConstructor as R, RegexValidatorName as A } from "./validator/regex-validator.js";
import { RequiredValidatorConstructor as E, RequiredValidatorName as k } from "./validator/required-validator.js";
const i = {
  [k]: E,
  [N]: v,
  [g]: C,
  [d]: c,
  [V]: p,
  [h]: b,
  [w]: y,
  [A]: R
}, B = /* @__PURE__ */ n((r) => {
  r.validator.forEach(
    (e) => {
      if (typeof e == "string" && Object.keys(i).includes(e) === !1)
        throw new Error(`Validator '${e}' not found`);
      if (Array.isArray(e) && Object.keys(i).includes(e[0]) === !1)
        throw new Error(`Validator '${e[0]}' not found`);
    }
  );
  const t = [], a = r.validator, s = a.length;
  let l = 0;
  for (; l < s; l++) {
    const e = a[l];
    let o;
    if (typeof e == "string")
      o = new (i[e]())({
        label: r.label,
        value: r.value,
        input: r.input
      }).getResult();
    else if (Array.isArray(e)) {
      const u = e.filter((x, m) => m > 0);
      o = new (i[e[0]](u.first()))({
        label: r.label,
        value: r.value,
        input: r.input
      }).getResult();
    } else
      o = new e({
        label: r.label,
        value: r.value,
        input: r.input
      }).getResult();
    o !== null && t.push(o);
  }
  return t;
}, "validate");
export {
  B as validate
};
