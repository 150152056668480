import i from "../../_vue/component.input/drop-down-menu/drop-down-menu.js";
import { defineComponent as n } from "vue";
const r = n({
  components: { DropDownMenu: i },
  props: {
    /**
     * Message displayed to the use when there are no options to display
     */
    emptyMessage: {
      type: String,
      default: "No Options Found"
    },
    /**
     * List of items, objects that contains the fields defined in the other properties
     */
    items: {
      type: Array,
      required: !0
    },
    /**
     * The items field to use the items title
     */
    titleField: {
      type: String,
      required: !0
    },
    /**
     * The items field to use the items left icon
     */
    iconLeftField: {
      type: String,
      default: null
    },
    /**
     * The items field to use the items right icon
     */
    iconRightField: {
      type: String,
      default: null
    },
    /**
     * The items field to use the items first line
     */
    line1Field: {
      type: String,
      default: null
    },
    /**
     * The items field to use the items second line
     */
    line2Field: {
      type: String,
      default: null
    },
    /**
     * Should the 'More...' button be displayed
     */
    showMoreButton: {
      type: Boolean,
      default: !1
    },
    /**
     * Field to use to decided if an item has been selected
     */
    trackingField: {
      type: String,
      required: !0
    },
    /**
     * The label of the cancel button (last item)
     */
    cancelButtonLabel: {
      type: String,
      default: "Cancel"
    },
    /**
     * Hide the cancel button
     */
    hideCancelButton: {
      type: Boolean,
      default: !1
    },
    /**
     * The field use to hide an item
     */
    hideField: {
      type: String,
      default: null
    },
    /**
     * Should the menu be static
     */
    isStatic: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["click", "close", "more"],
  computed: {
    computedItems() {
      return this.items.filter((t) => this.hideField ? t[this.hideField] !== !0 : !0);
    }
  },
  mounted() {
    window.Kernel.KeyListener.addListener({
      code: "Escape",
      listener: this.onClose,
      ignoreInput: !1
    });
  },
  beforeUnmount() {
    window.Kernel.KeyListener.removeListener({
      code: "Escape",
      listener: this.onClose
    });
  },
  methods: {
    onClick(e) {
      const t = e[0];
      if (t.__isCancelButton === !0) {
        this.$emit("close");
        return;
      }
      this.$emit("click", t);
    },
    onClose(e) {
      e.preventDefault(), this.$emit("close");
    }
  }
});
export {
  r as _
};
