// IntroducerContract, IntroducerCompany, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerContract } from "@kinherit/sdk/model/introducer-contract";

export const ReadIntroducerContract = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/contract",
  method: "get",
  rootModel: IntroducerContract,
  parse: () => ({}),
});
