// PhysicalStorageFile

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { PhysicalStorageSubscription } from "@kinherit/sdk";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { PhysicalStorageFile } from "@kinherit/sdk/model/physical-storage-file";

export const DeletePhysicalStorageFile = DefineDeleteAction({
  interface: "portal",
  resource:
    "/v2/portal/kinvault/{kintin}/physical-storage-subscription/{subscription}/storage-request/{physicalStorageFile}",
  method: "delete",
  parse: (message: {
    kintin: Kintin | string;
    subscription: PhysicalStorageSubscription | string;
    physicalStorageFile: PhysicalStorageFile | string;
  }) => ({
    params: {
      kintin: message.kintin,
      subscription: message.subscription,
      physicalStorageFile: message.physicalStorageFile,
    },
  }),
  after: ({ message }) => {
    PhysicalStorageFile.$delete(message.physicalStorageFile);
  },
});
