import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const AccountsReportRoute = "AccountsReportRoute";
const AccountsReportUri = "/reports/core/accounts";

export const AppointmentsReportRoute = "AppointmentsReportRoute";
const AppointmentsReportUri = "/reports/core/appointments";

export const AppointmentsBreakdownReportRoute =
  "AppointmentsBreakdownReportRoute";
const AppointmentsBreakdownReportUri =
  "/reports/core/appointments/breakdown/:year/:granularity/:period/";
export type AppointmentsBreakdownReportParams = RouteParamObject<
  typeof AppointmentsBreakdownReportUri
>;

export const CashflowReportRoute = "CashflowReportRoute";
const CashflowReportUri = "/reports/core/cashflow";

export const CashflowByEpReportRoute = "CashflowByEpReportRoute";
const CashflowByEpReportUri = "/reports/core/cashflow/ep";

export const LeadsReportRoute = "LeadsReportRoute";
const LeadsReportUri = "/reports/core/leads";

export const LeadsByEpReportRoute = "LeadsByEpReportRoute";
const LeadsByEpReportUri = "/reports/core/leads/ep";

export const ProductsReportRoute = "ProductsReportRoute";
const ProductsReportUri = "/reports/core/products";

export const SummaryReportRoute = "SummaryReportRoute";
const SummaryReportUri = "/reports/core/summary";

export const DocstorageReportRoute = "DocstorageReportRoute";
const DocstorageReportUri = "/reports/core/docstorage";

export const TimeToFirstCallReportRoute = "TimeToFirstCallReportRoute";
const TimeToFirstCallReportUri = "/reports/core/time-to-first-call";

export const TimeToSecondCallReportRoute = "TimeToSecondCallReportRoute";
const TimeToSecondCallReportUri = "/reports/core/time-to-second-call";

export const AvailabilityReportRoute = "AvailabilityReportRoute";
const AvailabilityReportUri = "/reports/core/availability";

export const Routes: Array<RouteInterface> = [
  {
    name: AccountsReportRoute,
    path: AccountsReportUri,
    component: () => import("./Reports.Accounts.vue"),
  },
  {
    name: AppointmentsReportRoute,
    path: AppointmentsReportUri,
    component: () => import("./Reports.Appointments.vue"),
  },
  {
    name: AppointmentsBreakdownReportRoute,
    path: AppointmentsBreakdownReportUri,
    component: () => import("./Reports.Appointments.Breakdown.vue"),
  },
  {
    name: CashflowReportRoute,
    path: CashflowReportUri,
    component: () => import("./Reports.Cashflow.vue"),
  },
  {
    name: CashflowByEpReportRoute,
    path: CashflowByEpReportUri,
    component: () => import("./Reports.Cashflow.ByEp.vue"),
  },
  {
    name: LeadsReportRoute,
    path: LeadsReportUri,
    component: () => import("./Reports.Leads.vue"),
  },
  {
    name: LeadsByEpReportRoute,
    path: LeadsByEpReportUri,
    component: () => import("./Reports.Leads.ByEp.vue"),
  },
  {
    name: ProductsReportRoute,
    path: ProductsReportUri,
    component: () => import("./Reports.Products.vue"),
  },
  {
    name: SummaryReportRoute,
    path: SummaryReportUri,
    component: () => import("./Reports.Summary.vue"),
  },
  {
    name: DocstorageReportRoute,
    path: DocstorageReportUri,
    component: () => import("./Reports.Docstorage.vue"),
  },
  {
    name: TimeToFirstCallReportRoute,
    path: TimeToFirstCallReportUri,
    component: () => import("./Reports.TimeToFirstCall.vue"),
  },
  {
    name: TimeToSecondCallReportRoute,
    path: TimeToSecondCallReportUri,
    component: () => import("./Reports.TimeToSecondCall.vue"),
  },
  {
    name: AvailabilityReportRoute,
    path: AvailabilityReportUri,
    component: () => import("./Reports.Availability.vue"),
  },
];
