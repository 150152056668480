var h = Object.defineProperty;
var u = (n, s, t) => s in n ? h(n, s, { enumerable: !0, configurable: !0, writable: !0, value: t }) : n[s] = t;
var o = (n, s) => h(n, "name", { value: s, configurable: !0 });
var c = (n, s, t) => u(n, typeof s != "symbol" ? s + "" : s, t);
const e = class e {
  constructor(s) {
    c(this, "observer");
    c(this, "callback");
    this.callback = s, this.observer = new ResizeObserver(this.callback);
  }
  static getInstance(s, t) {
    var a;
    const r = (a = this.instances.find(
      (i) => i.element === s
    )) == null ? void 0 : a.instance;
    if (!r) {
      const i = /* @__PURE__ */ o((v) => {
        t && t(v);
      }, "newCallback"), b = new e(i);
      return this.instances.push({ element: s, instance: b }), b;
    }
    return r;
  }
  observe(s) {
    this.observer.observe(s);
  }
  unobserve(s) {
    this.observer.unobserve(s);
  }
  disconnect() {
    this.observer.disconnect();
  }
};
o(e, "ResizeObserverManager"), c(e, "instances", []);
let l = e;
export {
  l as ResizeObserverManager
};
