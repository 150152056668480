// Order, Kintin, AccountReferral, AccountReferralCode, Profile, OrderItem, User

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Order } from "@kinherit/sdk/model/order";

export const RecordOrder = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/order/{order}",
  method: "get",
  rootModel: Order,
  parse: (order: Order | string) => ({
    params: {
      order,
    },
  }),
});
