// User, Credential

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { User } from "@kinherit/sdk/model/user";

export const UpdateUser = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/admin/user/{user}",
  method: "patch",
  parse: (target: User) => ({
    body: {
      acuityUserId: target.$data.acuityUserId,
      roles: target.$data.roles,
      credential: {
        username: target.credential.$data.username,
      },
      displayUserInPortal: target.$data.displayUserInPortal,
      isEstatePlanner: target.$data.isEstatePlanner,
      isLegalAssistant: target.$data.isLegalAssistant,
      status: target.$data.status,
      sendEmailNotifications: target.$data.sendEmailNotifications,
      sendSmsNotifications: target.$data.sendSmsNotifications,
    },
    params: {
      user: target,
    },
  }),
});
