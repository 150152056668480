var n = Object.defineProperty;
var r = (t, o) => n(t, "name", { value: o, configurable: !0 });
const l = /* @__PURE__ */ r((t) => {
  var o;
  return [...t.slot ? [t.slot] : []].concat(
    ...((o = t.children) == null ? void 0 : o.map(
      (s) => l(s)
    )) ?? []
  );
}, "ListDynamicLayoutElementSlots"), a = /* @__PURE__ */ r((t) => {
  var s;
  let o = Array();
  return t.slot && o.push(t.slot), (s = t.children) == null || s.forEach((c) => {
    o = [...o, ...a(c)];
  }), o;
}, "GetDynamicLayoutSlots");
export {
  a as GetDynamicLayoutSlots,
  l as ListDynamicLayoutElementSlots
};
