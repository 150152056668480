var s = Object.defineProperty;
var i = (e, t) => s(e, "name", { value: t, configurable: !0 });
import r from "../../_vue/component.display/snackbar/snack-bar.js";
import { defineComponent as a } from "vue";
import { KernelModes as c } from "../kernel-mode.js";
const u = a({
  name: "PragmaticSnackBarLoader",
  components: { SnackBar: r },
  data: /* @__PURE__ */ i(() => ({
    requests: Array(),
    showing: Array()
  }), "data"),
  computed: {
    isUnitTest() {
      var e;
      return ((e = window.Kernel) == null ? void 0 : e.Mode) === c.UnitTest;
    }
  },
  mounted() {
    window.Kernel.Global.setPragmaticSnackBarComponentLoader(
      this.openSnackBar
    );
  },
  methods: {
    openSnackBar(e) {
      const t = e.config.uuid;
      if (t && this.requests.find((o) => o.config.uuid === t))
        return;
      window.Kernel.Logger.log(
        "Global",
        "info",
        "Opening snack bar",
        e.config
      );
      const n = this.requests.length;
      this.requests[n] = e, this.showing[n] = !1, this.$nextTick(() => {
        this.showing[n] = !0;
      });
    },
    remove(e) {
      this.requests = this.requests.filter(
        (t, n) => n !== e
      ), this.showing = this.showing.filter(
        (t, n) => n !== e
      );
    }
  }
});
export {
  u as _
};
