var m = Object.defineProperty;
var r = (e, t) => m(e, "name", { value: t, configurable: !0 });
import { _ as p } from "../../../component.page/master-list-page/master-list-filters.vue_vue_type_script_lang.js";
import { resolveComponent as n, createElementBlock as a, createCommentVNode as o, openBlock as s, createVNode as d, createBlock as f, renderSlot as c, withCtx as C, createTextVNode as h } from "vue";
import { _ as k } from "../../../_plugins/_plugin-vue_export-helper.js";
const B = {
  key: 0,
  class: "master-list-filters"
}, $ = {
  key: 0,
  class: "buttons is-right"
};
function b(e, t, _, g, v, y) {
  const i = n("Form"), l = n("Button");
  return e.filters !== null ? (s(), a("div", B, [
    d(i, {
      ref: "form",
      config: e.filters,
      data: e.filters.localData,
      "is-borderless": "",
      "persist-state": !0,
      "persist-method": e.persistMethod,
      onSubmitCapture: e.search,
      onResetCapture: e.resetData
    }, null, 8, ["config", "data", "persist-method", "onSubmitCapture", "onResetCapture"]),
    e.hasCreateButton || e.$slots.buttons ? (s(), a("div", $, [
      e.hasCreateButton ? (s(), f(l, {
        key: 0,
        class: "create",
        size: "is-normal",
        onClick: t[0] || (t[0] = (u) => e.$emit("create", u))
      }, {
        default: C(() => t[1] || (t[1] = [
          h(" Create ")
        ])),
        _: 1
      })) : o("", !0),
      c(e.$slots, "buttons")
    ])) : o("", !0)
  ])) : o("", !0);
}
r(b, "_sfc_render");
const D = /* @__PURE__ */ k(p, [["render", b]]);
export {
  D as default
};
