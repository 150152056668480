<template>
  <Card v-if="$data.primaryForm && lead" class="lead-details-update-profile">
    <Tabs
      v-if="lead.secondaryProfile"
      v-model:tab="tab"
      class="mb-4"
      :config="[
        {
          label: lead.primaryProfile.fullName ?? 'Primary Profile',
        },
        {
          label: lead.secondaryProfile.fullName ?? 'Secondary Profile',
        },
      ]"
    />
    <FormControls
      v-if="tab === 0"
      :form="$data.primaryForm"
      mode="update"
      :title="$data.primaryForm.localData.fullName ?? undefined"
      :delete-button="false"
      @cancel="$router.back"
      @save="save"
      @reset="reset"
      @show-validation="$data.primaryForm!.options.showValidation = true"
    />
    <FormControls
      v-else-if="tab === 1 && $data.secondaryForm"
      :form="$data.secondaryForm"
      mode="update"
      :title="$data.secondaryForm?.localData.fullName ?? undefined"
      :delete-button="false"
      @cancel="$router.back"
      @save="save"
      @reset="reset"
      @show-validation="$data.secondaryForm!.options.showValidation = true"
    />
  </Card>
  <PageNotFound v-else-if="!lead" />
</template>

<cypress-wrapper lang="json">
{
  "name": "LeadDetailsUpdateProfileWrapper",
  "route": "LeadDetailsUpdateProfile",
  "selector": ".lead-details-update-profile",
  "imports": {
    "UpdateProfileFormWrapper": "@/shared/form/profile/update-profile.form.test"
  },
  "methods": {
    "form": {
      "type": "to-one",
      "selector": "form",
      "wrapper": "UpdateProfileFormWrapper"
    },
    "save": {
      "type": "click",
      "selector": ".save-button"
    },
    "cancel": {
      "type": "click",
      "selector": ".cancel-button"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { LeadDetailsMixin } from "@/module/lead/mixin/lead-details.mixin";
import { AuthService } from "@/service/auth.service";
import FormControls from "@/shared/component/misc/FormControls.vue";
import { UpdateProfileForm } from "@/shared/form/profile/update-profile.form";
import PageNotFound from "@kinherit/framework/component.display/page-not-found";
import Tabs from "@kinherit/framework/component.display/tabs";
import { Card } from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { Profile } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { LeadDetailsUpdateProfileRoute } from ".";

export default defineComponent({
  name: LeadDetailsUpdateProfileRoute,
  components: {
    PageNotFound,
    Card,
    FormControls,
    Tabs,
  },
  mixins: [
    AuthService.mixin({ sessionRequired: true, permissions: ["lead:write"] }),
    LeadDetailsMixin,
  ],
  data: () => ({
    tab: 0,
    primaryForm: null as null | FormBuilder<() => Profile, string>,
    secondaryForm: null as null | FormBuilder<() => Profile, string>,
    primaryProfile: null as null | Profile,
    secondaryProfile: null as null | Profile,
  }),
  mounted(): void {
    if (null === this.lead) {
      return;
    }

    this.primaryProfile = this.lead.primaryProfile;
    this.secondaryProfile = this.lead.secondaryProfile;
    this.primaryForm = UpdateProfileForm(this.primaryProfile as Profile);
    this.secondaryForm = this.secondaryProfile
      ? UpdateProfileForm(this.secondaryProfile as Profile)
      : null;
  },
  methods: {
    async save(): Promise<void> {
      if (null === this.lead || null === this.primaryProfile) {
        return;
      }

      this.lead.$persist();
      // await window.Kernel.ActionBus.lead.profile.update({
      //   lead: this.lead,
      // });
      await this.$actionBus.UpdateLeadProfile(this.lead);

      this.$router.back();
    },
    cancel(): void {
      this.$router.back();
    },
    reset(): void {
      this.$data.primaryForm?.localData.$restore();
    },
  },
});
</script>
