import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateWillRevision } from "./create-will-revision.action";
import { DeleteWillRevision } from "./delete-will-revision.action";
import { ReadWillRevision } from "./read-will-revision.action";
import { RecordWillRevision } from "./record-will-revision.action";
import { UpdateWillRevision } from "./update-will-revision.action";

export const Portal2WillRevision = DefineActionBus({
  name: "portal-will-revision",
  actions: {
    CreateWillRevision,
    DeleteWillRevision,
    ReadWillRevision,
    RecordWillRevision,
    UpdateWillRevision,
  },
});
