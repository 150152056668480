<template>
  <Card
    title="Estate Planner Availability"
    class="estate-planner-availability-report"
  >
    <p>
      Shows the number of info and options calls for each estate planner across
      different weeks.
    </p>
    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="columns"
      :footer="footer"
      :rows="results"
      @loaded="load"
    >
      <template #lastWeek="{ row }">
        <SplitBadge
          :info-count="row.lastWeek.infoCallCount"
          :options-count="row.lastWeek.optionsCallCount"
        />
      </template>
      <template #thisWeek="{ row }">
        <SplitBadge
          :info-count="row.thisWeek.infoCallCount"
          :options-count="row.thisWeek.optionsCallCount"
        />
      </template>
      <template #nextWeek="{ row }">
        <SplitBadge
          :info-count="row.nextWeek.infoCallCount"
          :options-count="row.nextWeek.optionsCallCount"
        />
      </template>
      <template #nextWeekPlusOne="{ row }">
        <SplitBadge
          :info-count="row.nextWeekPlusOne.infoCallCount"
          :options-count="row.nextWeekPlusOne.optionsCallCount"
        />
      </template>
      <template #nextWeekPlusTwo="{ row }">
        <SplitBadge
          :info-count="row.nextWeekPlusTwo.infoCallCount"
          :options-count="row.nextWeekPlusTwo.optionsCallCount"
        />
      </template>
    </Table>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "AvailabilityReport",
  "route": "AvailabilityReportRoute",
  "selector": ".estate-planner-availability-report"
}
</cypress-wrapper>

<script lang="ts">
import SplitBadge from "@/module/report/component/SplitBadge.vue";
import Table from "@kinherit/framework/component.display/table";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { defineComponent } from "vue";
import { AvailabilityReportRoute } from ".";

interface WeeklyCount {
  infoCallCount: number;
  optionsCallCount: number;
}

interface AppointmentAvailabilityResponse {
  estatePlannerName: string;
  lastWeek: WeeklyCount;
  thisWeek: WeeklyCount;
  nextWeek: WeeklyCount;
  nextWeekPlusOne: WeeklyCount;
  nextWeekPlusTwo: WeeklyCount;
}

export default defineComponent({
  name: AvailabilityReportRoute,
  components: {
    Card,
    Table,
    SplitBadge,
  },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.reports)],
  data: () => ({
    results: [] as AppointmentAvailabilityResponse[],
    columns: [
      {
        title: "Estate Planner",
        map: (result: AppointmentAvailabilityResponse) =>
          result.estatePlannerName,
      },
      {
        title: "Last Week",
        slot: "lastWeek",
      },
      {
        title: "This Week",
        slot: "thisWeek",
        class: "has-border-left",
      },
      {
        title: "Next Week",
        slot: "nextWeek",
        class: "has-border-left",
      },
      {
        title: "+2 Weeks",
        slot: "nextWeekPlusOne",
      },
      {
        title: "+3 Weeks",
        slot: "nextWeekPlusTwo",
      },
    ],
    footer: [
      {
        title: "Totals",
      },
      {
        mapHtml: (results: AppointmentAvailabilityResponse[]) => {
          const total = results.reduce(
            (acc, result) => {
              acc.infoCallCount += result.lastWeek.infoCallCount;
              acc.optionsCallCount += result.lastWeek.optionsCallCount;
              return acc;
            },
            { infoCallCount: 0, optionsCallCount: 0 } as WeeklyCount,
          );

          return `${total.infoCallCount} / ${total.optionsCallCount}`;
        },
      },
      {
        mapHtml: (results: AppointmentAvailabilityResponse[]) => {
          const total = results.reduce(
            (acc, result) => {
              acc.infoCallCount += result.thisWeek.infoCallCount;
              acc.optionsCallCount += result.thisWeek.optionsCallCount;
              return acc;
            },
            { infoCallCount: 0, optionsCallCount: 0 } as WeeklyCount,
          );

          return `${total.infoCallCount} / ${total.optionsCallCount}`;
        },
        class: "has-border-left",
      },
      {
        mapHtml: (results: AppointmentAvailabilityResponse[]) => {
          const total = results.reduce(
            (acc, result) => {
              acc.infoCallCount += result.nextWeek.infoCallCount;
              acc.optionsCallCount += result.nextWeek.optionsCallCount;
              return acc;
            },
            { infoCallCount: 0, optionsCallCount: 0 } as WeeklyCount,
          );

          return `${total.infoCallCount} / ${total.optionsCallCount}`;
        },
        class: "has-border-left",
      },
      {
        mapHtml: (results: AppointmentAvailabilityResponse[]) => {
          const total = results.reduce(
            (acc, result) => {
              acc.infoCallCount += result.nextWeekPlusOne.infoCallCount;
              acc.optionsCallCount += result.nextWeekPlusOne.optionsCallCount;
              return acc;
            },
            { infoCallCount: 0, optionsCallCount: 0 } as WeeklyCount,
          );

          return `${total.infoCallCount} / ${total.optionsCallCount}`;
        },
      },
      {
        mapHtml: (results: AppointmentAvailabilityResponse[]) => {
          const total = results.reduce(
            (acc, result) => {
              acc.infoCallCount += result.nextWeekPlusTwo.infoCallCount;
              acc.optionsCallCount += result.nextWeekPlusTwo.optionsCallCount;
              return acc;
            },
            { infoCallCount: 0, optionsCallCount: 0 } as WeeklyCount,
          );

          return `${total.infoCallCount} / ${total.optionsCallCount}`;
        },
      },
    ],
  }),
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const result = await this.$actionBus.Availability();
      this.results = result;
    },
  },
});
</script>

<style lang="scss">
.split-badge {
  display: flex;
  gap: 0rem;
  border-radius: 0;
  overflow: hidden; // Ensures the background colors stay within rounded corners

  span {
    display: inline-block;
    width: 2rem;
    text-align: center;
    color: white;
    padding: 3px;
  }

  // Styles for the first span (info color)
  span:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  // Styles for the second span (purple color)
  span:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
