<template>
  <div class="kintin-details-address-book-account-holders">
    <PageHeader v-if="kintin" htag="h2" text="Account Holders" />
    <AccountHolderList v-if="kintin" :kintin="kintin" />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AccountHoldersContainerWrapper",
  "selector": ".kintin-details-address-book-account-holders",
  "imports": {
    "AccountHolderListWrapper": "@/module/kinvault.kintin/component/lists/AccountHolderList.test"
  },
  "methods": {
    "accountHolderList": {
      "type": "to-one",
      "selector": ".account-holder-list",
      "wrapper": "AccountHolderListWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import AccountHolderList from "@/module/kinvault.kintin/component/lists/AccountHolderList.vue";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: `AccountHoldersContainer`,
  components: {
    AccountHolderList,
    PageHeader,
  },
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  data: () => ({
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  methods: {},
});
</script>
