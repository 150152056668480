import { UserField } from "@/config/form.config";
import LeadStatus from "@/shared/component/lead/LeadStatus.vue";
import { FormTable } from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { FormElement } from "@kinherit/framework/component.form/element";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In } from "@kinherit/orm/index";
import { Lead, User } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const ReassignLeadsForm = (leads: Lead[], filterCount: number) =>
  defineForm({
    name: "reassign-leads",
    data: () => ({
      leads,
      assignedTo: null as null | User,
      ownedBy: null as null | User,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "reassign-leads",
        data,
        template: GridLayout([["ownedBy", "assignedTo"], ["leads"]]),
        components: () => ({
          assignedTo: [
            UserField({
              props: {
                label: "New assigned user (LA)",
                vModel: "assignedTo",
                isMultiSelect: false,
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
              simplifyData: true,
            }),
          ],
          ownedBy: [
            UserField({
              props: {
                label: "New owner (Estate Planner)",
                vModel: "ownedBy",
                isMultiSelect: false,
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
              simplifyData: true,
            }),
          ],
          leads: [
            FormTable({
              props: {
                vIf: (data) => data.leads.length > 0,
                columns: [
                  {
                    title: "Name",
                    map: (lead) => lead.friendlyName,
                  },
                  {
                    title: "Owner (Estate Planner)",
                    map: (lead) => lead.ownedBy?.profile.fullName,
                  },
                  {
                    title: "Assigned To (LA)",
                    map: (lead) => lead.assignedTo?.profile.fullName,
                  },
                  {
                    title: "Status",
                    component: (lead) =>
                      defineComponent({
                        // template: `<LeadStatus :key="lead.id" :lead="lead" @click.stop />`,
                        components: {
                          LeadStatus,
                        },
                        data: () => ({
                          lead,
                        }),
                        render() {
                          return (
                            <LeadStatus
                              key={this.lead.id}
                              lead={this.$data.lead}
                              // @ts-ignore
                              onClick={(e: MouseEvent) => e.stopPropagation()}
                            />
                          );
                        },
                      }),
                  },
                ] as TableColumn<Lead>[],
                rows: (data) => data.leads,
              },
            }),
            FormElement({
              props: {
                vIf: (data) => data.leads.length === 0,
                html: `<h3 style='text-align:center' class='mt-5'>Changes will be applied to the <span class="has-text-danger">${filterCount.format()}</span> leads that match the search criteria</h3>`,
              },
            }),
          ],
        }),
      }),
    ],
  });
