var S = Object.defineProperty;
var m = (e, o) => S(e, "name", { value: o, configurable: !0 });
import { _ as U } from "../../../component.layout/header/header.vue_vue_type_script_lang.js";
import { resolveComponent as b, resolveDirective as V, createElementBlock as l, openBlock as i, mergeProps as a, createBlock as t, resolveDynamicComponent as A, createCommentVNode as r, createElementVNode as d, createVNode as w, toDisplayString as E, withDirectives as $, normalizeClass as F, withCtx as h, vShow as I, Transition as H, Fragment as C, renderList as L } from "vue";
import { _ as P } from "../../../_plugins/_plugin-vue_export-helper.js";
const T = {
  key: 0,
  class: "site-header__inner"
}, j = {
  key: 1,
  class: "site-header__inner"
}, q = ["src"], G = { class: "navbar-mobile-buttons is-hidden-from-widescreen" };
function J(e, o, K, Q, R, W) {
  var v, g, f;
  const N = b("Button"), u = b("NavbarItem"), O = b("Avatar"), z = V("click-outside");
  return i(), l("header", a({
    key: e.route,
    class: "site-header"
  }, e.bindings.wrapper), [
    (v = e.$data.content) != null && v.component ? (i(), l("div", T, [
      (i(), t(A(e.$data.content.component)))
    ])) : (i(), l("div", j, [
      (g = e.content) != null && g.title ? (i(), l("h1", a({
        key: 0,
        class: "title has-text-weight-bold"
      }, e.bindings.title), E((f = e.content) == null ? void 0 : f.title), 17)) : r("", !0),
      e.computed.logo !== null ? (i(), l("img", a({ key: 1 }, e.bindings.logo, {
        src: e.computed.logo.url,
        class: "site-header__logo"
      }), null, 16, q)) : r("", !0),
      d("div", G, [
        $(w(N, {
          class: F({
            navbar__burger: !0,
            "is-active": e.mobileMenuOpen
          }),
          style: { background: "transparent" },
          "aria-label": "Menu",
          "aria-haspopup": "true",
          "aria-expanded": e.mobileMenuOpen,
          onClick: o[0] || (o[0] = (p) => e.toggleMobileMenu())
        }, {
          default: h(() => o[4] || (o[4] = [
            d("span", { "aria-hidden": "true" }, null, -1),
            d("span", { "aria-hidden": "true" }, null, -1),
            d("span", { "aria-hidden": "true" }, null, -1)
          ])),
          _: 1
        }, 8, ["class", "aria-expanded"]), [
          [I, e.hasButtons]
        ])
      ]),
      w(H, { name: "mobile-nav-open-close" }, {
        default: h(() => {
          var p, k, M, c, y, D;
          return [
            e.mobileMenuOpen || !e.isMobile ? $((i(), l("nav", a({
              key: 0,
              ref: "refNavBar",
              class: ["navbar", {
                "is-mobile": e.isMobile,
                "is-open": e.mobileMenuOpen
              }],
              role: "navigation",
              "aria-label": "Site Navigation"
            }, e.bindings.container), [
              (i(!0), l(C, null, L(e.filteredMenu, (n, s) => (i(), t(u, {
                key: `navbar__item-${s}`,
                label: n.label,
                click: n.click,
                menu: n.menu,
                icon: n.icon,
                "icon-size": n.iconSize,
                "show-dropdown": e.showDropdown === s,
                "is-mobile": e.isMobile,
                "onUpdate:showDropdown": /* @__PURE__ */ m((B) => e.showDropdown = B ? s : null, "onUpdate:showDropdown"),
                onClick: o[1] || (o[1] = (B) => e.mobileMenuOpen = !1)
              }, null, 8, ["label", "click", "menu", "icon", "icon-size", "show-dropdown", "is-mobile", "onUpdate:showDropdown"]))), 128)),
              e.hasButtons ? (i(), l(C, { key: 0 }, [
                (k = (p = e.content) == null ? void 0 : p.avatar) != null && k.name ? (i(), t(u, {
                  key: 0,
                  "mobile-fixed-open": (c = (M = e.content) == null ? void 0 : M.avatar) == null ? void 0 : c.mobileFixedOpen,
                  menu: e.filteredButtons,
                  "show-dropdown": e.showDropdown === e.filteredMenu.length,
                  "is-mobile": e.isMobile,
                  "onUpdate:showDropdown": o[2] || (o[2] = (n) => e.showDropdown = n ? e.filteredMenu.length : null)
                }, {
                  default: h(() => {
                    var n, s;
                    return [
                      w(O, {
                        name: (s = (n = e.content) == null ? void 0 : n.avatar) == null ? void 0 : s.name,
                        size: "is-normal",
                        "hide-tooltip": "",
                        "show-label": e.isMobile
                      }, null, 8, ["name", "show-label"])
                    ];
                  }),
                  _: 1
                }, 8, ["mobile-fixed-open", "menu", "show-dropdown", "is-mobile"])) : r("", !0),
                (D = (y = e.content) == null ? void 0 : y.avatar) != null && D.name ? r("", !0) : (i(), t(u, {
                  key: 1,
                  menu: e.filteredButtons,
                  "show-dropdown": e.showDropdown === e.filteredMenu.length,
                  "is-mobile": e.isMobile,
                  icon: "Cog",
                  "icon-only": !e.isMobile,
                  label: "Settings",
                  "onUpdate:showDropdown": o[3] || (o[3] = (n) => e.showDropdown = n ? e.filteredMenu.length : null)
                }, null, 8, ["menu", "show-dropdown", "is-mobile", "icon-only"]))
              ], 64)) : r("", !0)
            ], 16)), [
              [z, () => e.showDropdown = null]
            ]) : r("", !0)
          ];
        }),
        _: 1
      })
    ]))
  ], 16);
}
m(J, "_sfc_render");
const x = /* @__PURE__ */ P(U, [["render", J]]);
export {
  x as default
};
