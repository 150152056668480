<template>
  <Tabs
    class="kintin-subscriptions-sidebar"
    size="is-normal"
    :config="items"
    is-vertical
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultSubscriptionsSidebarWrapper",
  "methods": {
    "tabs": {
      "type": "tabs",
      "selector": ".sidebar-page__tabs",
      "items": ["Failed Logins", "Email Log", "Notification Log"]
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import Tabs from "@kinherit/framework/component.display/tabs";
import { defineComponent } from "vue";

import {
  KinvaultCouponMasterListRoute,
  KinvaultPriceMasterListRoute,
  KinvaultSubscriptionMasterListRoute,
  KinvaultSubscriptionReferralCodeMasterListRoute,
} from "@/module/kinvault.subscription/page";

export default defineComponent({
  name: "KinvaultSubscriptionSideBar",
  components: {
    Tabs,
  },
  data: () => ({
    items: [
      {
        label: "Subscriptions",
        route: {
          name: KinvaultSubscriptionMasterListRoute,
        },
      },
      {
        label: "Referral Codes",
        route: {
          name: KinvaultSubscriptionReferralCodeMasterListRoute,
        },
      },
      {
        label: "Prices",
        route: {
          name: KinvaultPriceMasterListRoute,
        },
      },
      {
        label: "Coupons",
        route: {
          name: KinvaultCouponMasterListRoute,
        },
      },
    ],
  }),
});
</script>

<style lang="scss">
.admin-log-sidebar {
  &__title {
    border-right: 1px solid var(--color-border);
    padding: 0.5rem 1rem;
    cursor: default;
    @include font-weight(700);
  }
}
</style>
