<template>
  <div class="kintin-details-address-book-all">
    <CheckboxField
      v-model:value="showDetails"
      style="float: right"
      size="is-small"
      label="Details"
    />
    <PageHeader v-if="kintin" htag="h2" text="All" />
    <PersonList
      v-if="kintin"
      :kintin="kintin"
      :people="$data.filtered.people"
      :hide-summary="!showDetails"
      @reload="filter"
    />
    <Json v-if="isDevMode" :data="filtered" />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AllContainerWrapper",
  "selector": ".kintin-details-address-book-all",
  "imports": {
    "PersonListWrapper": "@/module/kinvault.kintin/component/lists/PersonList.test"
  },
  "methods": {
    "personList": {
      "type": "to-one",
      "selector": ".person-list",
      "wrapper": "PersonListWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { Json } from "@kinherit/framework/component.display/json";
import CheckboxField from "@kinherit/framework/component.input/checkbox-field";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { In } from "@kinherit/orm";
import { Kintin, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: `AllContainer`,
  components: {
    Json,
    PersonList,
    PageHeader,
    CheckboxField,
  },
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  data: () => ({
    filtered: {
      people: Array<Person>(),
    },
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
    showDetails: false,
  }),
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      this.filtered.people = Person.$findBy({
        id: In(
          [
            ...(this.kintin?.people?.pluck("id") ?? []),
            this.kintin?.primaryPerson?.id,
            this.kintin?.secondaryPerson?.id,
          ].filter(Boolean),
        ),
      })
        .filter((person) => person !== undefined && person !== null)
        .cast<Person>();

      this.filtered.people = (this.filtered.people as Array<Person>)
        .unique("id")
        .sortBy("profile.lastName");
    },
  },
});
</script>
