// Executor

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Executor } from "@kinherit/sdk/model/executor";

export const UpdateExecutor = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/executor/{executor}",
  method: "patch",
  parse: (target: Executor) => ({
    body: {
      isReserve: target.$data.isReserve,
      notes: target.$data.notes,
      professionalSupport: target.$data.professionalSupport,
      sortOrder: target.$data.sortOrder,
      person: target.$data.person,
      forPerson: target.$data.forPerson,
      kintin: target.$data.kintin,
    },
    params: {
      executor: target,
      kintin: target.$data.kintin,
    },
  }),
});
