var u = Object.defineProperty;
var l = (t, e) => u(t, "name", { value: e, configurable: !0 });
import { FieldMixin as i } from "../../component.mixin/field-mixin.js";
import { CypressHelper as n } from "../../cypress/component-helpers.js";
import { DateTime as a } from "@kinherit/ts-common/dto/date-time";
import { defineComponent as m } from "vue";
import r from "../../_vue/component.input/base-input-field/base-input-field.js";
const h = m({
  name: "DateFieldComponent",
  components: { BaseInputField: r },
  mixins: [i()],
  props: {
    /**
     * v-model:value
     *
     * @values null, DateTime()
     */
    value: {
      type: Object,
      default: null,
      value: "datetime"
    },
    /**
     * Earliest valid date
     *
     * @values null, DateTime()
     */
    min: {
      type: Object,
      default: null,
      value: "datetime"
    },
    /**
     * Latest valid date
     *
     * @values null, DateTime()
     */
    max: {
      type: Object,
      default: null,
      value: "datetime"
    },
    /**
     * Step between valid dates (in days)
     */
    step: {
      type: Number,
      default: null
    }
  },
  emits: [
    /**
     * Emits the new value
     *
     * @property {any}
     */
    "update:value",
    /**
     * Emits when the user leaves the input box
     */
    "blur"
  ],
  data: /* @__PURE__ */ l((t) => ({
    $cypress: n({ ctx: t, labelField: "label" })
  }), "data"),
  computed: {
    computedValue: {
      get() {
        const t = this.value;
        return t !== null ? this.formatDate(t) : null;
      },
      set(t) {
        try {
          const e = t === null ? null : new a(t);
          if (e !== null && e.getDate().getFullYear() < 1e3)
            return;
          this.$emit("update:value", e), this.validate(e);
        } catch {
          this.$emit("update:value", null), this.validate(null);
        }
      }
    },
    computedMin() {
      const t = this.min;
      return t === null ? null : t.formatYMD;
    },
    computedMax() {
      const t = this.max;
      return t === null ? null : t.formatYMD;
    }
  },
  methods: {
    setNow() {
      this.computedValue = this.formatDate(new a());
    },
    formatDate(t) {
      return t === null ? null : t.formatYMD;
    },
    blur() {
      this.$emit("blur"), this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
  }
});
export {
  h as _
};
