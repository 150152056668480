// SignDoc

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { SignDoc } from "@kinherit/sdk/model/sign-doc";

export const UpdateSignDoc = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/sign-doc/{signDoc}",
  method: "patch",
  parse: (target: SignDoc) => ({
    body: {
      ipAddress: target.$data.ipAddress,
      userAgent: target.$data.userAgent,
      signedAt: target.$data.signedAt,
      data: target.$data.data,
      kintin: target.$data.kintin,
      person: target.$data.person,
      type: target.$data.type,
    },
    params: {
      signDoc: target,
      kintin: target.$data.kintin,
    },
  }),
});
