import {
  AccountReferralCodeField,
  IntroducerContactField,
} from "@/config/form.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const CreateBrandedKinvaultInvitationForm = () => {
  return defineForm({
    name: "create-branded-kinvault-invitation-form",
    data: () => ({
      firstName: "",
      lastName: "",
      emailAddress: "",
      reference: null as null | string,
      referralCode: null as null | string,
      adviser: null as null | string,
      isBillable: true,
      sendInvitationEmail: null as null | boolean,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "referral",
        data,
        template: GridLayout([
          ["firstName", "lastName"],
          ["emailAddress", "reference"],
          ["referralCode", "adviser"],
          ["isBillable"],
          { is: "hr" },
        ]),
        components: () => ({
          firstName: [
            FormTextField({
              props: {
                label: "First Name",
                validators: ["required"],
              },
              models: {
                value: "firstName",
              },
            }),
          ],
          lastName: [
            FormTextField({
              props: {
                label: "Last Name",
                validators: ["required"],
              },
              models: {
                value: "lastName",
              },
            }),
          ],
          emailAddress: [
            FormEmailField({
              props: {
                label: "Email Address",
                validators: ["required"],
              },
              models: {
                value: "emailAddress",
              },
            }),
          ],
          reference: [
            FormTextField({
              props: {
                label: "Reference",
                message: "External reference",
              },
              models: {
                value: "reference",
              },
            }),
          ],
          referralCode: [
            AccountReferralCodeField({
              props: {
                label: "Referral Code",
                vModel: "referralCode",
                validators: ["required"],
              },
              simplifyData: true,
            }),
          ],
          adviser: [
            IntroducerContactField({
              props: {
                label: "Adviser",
                message: "The introducer/adviser",
                vModel: "adviser",
              },
              simplifyData: true,
            }),
          ],
          isBillable: [
            FormCheckboxField({
              props: {
                label: "Is Billable",
                message: "Include this in billing calculations",
              },
              models: {
                value: "isBillable",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
