import { DangerDialog } from "@/config/dialog.config";
import {
  KintinAddressField,
  OptionsAutoCompleteField,
  PersonField,
  SharedFormProps,
} from "@/config/form.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In, NotIn } from "@kinherit/orm/index";
import { Gift, Kintin, Option } from "@kinherit/sdk";
import { reactive } from "vue";
import { AddAddressButton, AddKintinPersonButton } from "./shared.form";

export const UpdateGiftForm = ({
  data,
  kintin,
}: {
  data: Gift;
  kintin: Kintin;
}) => {
  const localData = reactive({
    isClassGift:
      null !== data.classGiftType || null !== data.reserveclassGiftType,
  });

  const ifPredeceasedOtherId = Option.$findOneBy({
    group: "ifPredeceasedTypes",
    value: "other",
  })?.id;

  data.ifPredeceased =
    data.ifPredeceased ??
    Option.$findOneByOrThrow({
      group: "ifPredeceasedTypes",
      value: "theirconcern",
    });

  data.onSecondDeath = null === data.sentBy;

  return defineForm({
    name: "update-gift-form",
    props: SharedFormProps,
    data: () => data,
    validate: (data) => {
      if (
        data.ifPredeceased?.id === ifPredeceasedOtherId &&
        data.reservePerson === null &&
        data.reserveclassGiftType === null
      ) {
        DangerDialog({
          dialog: {
            title: `Error`,
            message: `Please set either a reserve person or reserve class gift type`,
          },
        });
        return false;
      }
      return true;
    },
    formAreas: (data) => [
      defineFormArea({
        name: "gift-form-area",
        data,
        template: GridLayout([
          ["isClassGift", "onSecondDeath"],
          ["sentBy"],
          ["to"],
          ["type", "outputPreference"],
          ["giftOptions"],
          ["ifPredeceased"],
          ["reserveTo"],
          ["notes"],
        ]),
        components: () => ({
          sentBy: [
            PersonField({
              reactive: true,
              props: {
                vIf: () => !data.onSecondDeath,
                label: "Who is this gift from?",
                vModel: "sentBy",
              },
              query: {
                id: In([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
              },
            }),
          ],
          onSecondDeath: [
            FormCheckboxField({
              reactive: true,
              props: {
                reference: "onSecondDeath",
                label: "Joint / To be gifted on second death",
                vIf: () => kintin.type?.value === "joint",
              },
              models: {
                // value: "onSecondDeath",
                value: {
                  get: () => data.onSecondDeath,
                  set: (value, data) => {
                    data.onSecondDeath = value;
                    if (data.onSecondDeath) {
                      data.sentBy = null;
                    }
                  },
                },
              },
            }),
          ],
          isClassGift: [
            FormRadioField({
              reactive: true,
              props: {
                label: "Gift Type",
                options: {
                  "0": "Individual / Charity",
                  "1": "Class Gift",
                },
                reference: "isClassGift",
              },
              models: {
                value: {
                  get: () => (localData.isClassGift ? "1" : "0"),
                  set: (value, data) => {
                    localData.isClassGift = value === "1";

                    if (localData.isClassGift) {
                      data.forPerson = null;
                    } else {
                      data.classGiftType = null;
                    }
                  },
                },
              },
            }),
          ],
          to: [
            PersonField({
              props: {
                label: "Who is this gift for?",
                vModel: "forPerson",
                vIf: () => !localData.isClassGift,
              },
              query: {
                // id: NotIn([data.forPerson]),
                kintin: {
                  id: Equal(kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({
                    kintin,
                    targetProperty: "forPerson",
                  }),
                ],
              },
            }),
            OptionsAutoCompleteField({
              vModel: "classGiftType",
              group: "classGiftTypes",
              props: {
                vIf: () => localData.isClassGift,
                label: "Class Gift To",
              },
            }),
          ],
          type: [
            OptionsAutoCompleteField<Gift>({
              vModel: {
                get: (data) => data.type,
                set: (value, data, controls) => {
                  data.type = value;
                  controls.rebuildForm();
                },
              },
              group: "giftTypes",
              props: {
                reference: "giftType",
                validators: ["required"],
              },
            }),
          ],
          reserveTo: [
            PersonField<Gift>({
              reactive: true,
              props: {
                label: "Who is the reserve gift recipient?",
                reference: "reservePerson",
                vModel: {
                  get: (data) => data.reservePerson,
                  set: (value, data) => {
                    data.reservePerson = value;
                    data.reserveclassGiftType = null;
                  },
                },
                vIf: () => data.ifPredeceased?.id === ifPredeceasedOtherId,
              },
              query: {
                id: NotIn([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
                kintin: {
                  id: Equal("string" === typeof kintin ? kintin : kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
            OptionsAutoCompleteField({
              reactive: true,
              vModel: {
                get: (data) => data.reserveclassGiftType,
                set: (value, data) => {
                  data.reserveclassGiftType = value;
                  data.reservePerson = null;
                },
              },
              group: "classGiftTypes",
              props: {
                reference: "reserveclassGiftType",
                label: "Reserve Class Gift Type",
                vIf: () => data.ifPredeceased?.id === ifPredeceasedOtherId,
                //validators: ["required"],
              },
            }),
          ],
          outputPreference: [
            FormSelectField({
              props: {
                label: "Output Preference",
                placeholder: "Default (Will)",
                options: {
                  will: "Will",
                  mow: "MOW",
                },
                message: "Whether to output in Will or MOW only",
              },
              models: {
                value: "outputPreference",
              },
            }),
          ],
          giftOptions: [
            KintinAddressField({
              props: {
                label: "Address",
                vModel: "address",
                vIf: (data: Gift) =>
                  ["property", "business"].includes(data.type?.value),
              },
              kintin: kintin.id,
              slots: {
                right: [
                  AddAddressButton({
                    kintin,
                    targetProperty: "address",
                  }),
                ],
              },
            }),
            FormCheckboxField({
              props: {
                label: "Gift as Primary Residence",
                vIf: (data) => data.type?.value === "property",
                class: "mt-5",
                size: "is-medium",
              },
              models: {
                value: "asPrimaryResidence",
              },
            }),
            FormCurrencyField({
              props: {
                label: "Amount",
                vIf: (data) => data.type?.value === "fixedamount",
              },
              models: {
                value: "amount",
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Further details about gift - will be output in Will",
                isTextArea: true,
              },
              models: {
                value: "notes",
              },
            }),
          ],
          ifPredeceased: [
            OptionsAutoCompleteField({
              vModel: "ifPredeceased",
              group: "ifPredeceasedTypes",
              props: {
                validators: ["required"],
              },
            }),
          ],
        }),
      }),
    ],
  });
};
