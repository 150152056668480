// KintinAccess

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KintinAccess } from "@kinherit/sdk/model/kintin-access";

export const RecordKintinAccess = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/contact/kintin-access/{kintinAccess}",
  method: "get",
  rootModel: KintinAccess,
  parse: (kintinAccess: KintinAccess | string) => ({
    params: {
      kintinAccess,
    },
  }),
});
