import { Api } from "@kinherit/sdk";

export interface GetSortReferCaseMessage {
  externalId: string;
}

export const GetSortReferCase = async (
  message: GetSortReferCaseMessage,
): Promise<void> => {
  const request = Api.resource(
    "external",
    "/v2/external/sort-refer/get-instruction/{externalId}",
    {
      externalId: message.externalId,
    },
  ).method("get");

  await request.result();
};
