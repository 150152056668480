var a = Object.defineProperty;
var r = (t, e) => a(t, "name", { value: e, configurable: !0 });
function s() {
  var n;
  this.setAttribute("tabindex", "0");
  let t = Array.from(((n = this.parentElement) == null ? void 0 : n.children) ?? []), e = this.parentElement;
  for (; e && !e.classList.contains("tab-index-parent"); )
    e = e.parentElement;
  e && (t = Array.from(e.querySelectorAll("[tabindex]"))), t.filter((i) => i !== this).filter((i) => !i.classList.contains("tab-index-exclude")).forEach((i) => {
    i !== this && i.setAttribute("tabindex", "-1");
  });
}
r(s, "Focus");
const o = {
  created(t, e) {
    const n = e.value ?? 0;
    t.setAttribute("tabindex", n === 0 ? "0" : "-1"), t.addEventListener("focus", s);
  },
  beforeUnmount(t) {
    t.removeEventListener("focus", s);
  }
};
export {
  o as TabIndexDirective
};
