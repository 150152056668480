var s = Object.defineProperty;
var t = (e, i) => s(e, "name", { value: i, configurable: !0 });
import a from "../../_vue/component.display/icon/icon.js";
import { CypressHelper as n } from "../../cypress/component-helpers.js";
import { ThemeColor as o } from "../../theme/prop/color.js";
import { ThemeIconName as d } from "../../theme/prop/icon.js";
import { ThemeShade as r } from "../../theme/prop/shade.js";
import { ThemeSize as l } from "../../theme/prop/size.js";
import { defineComponent as u } from "vue";
const b = u({
  name: "BadgeComponent",
  components: { Icon: a },
  props: {
    /**
     * Display badge text
     */
    text: {
      type: [String, Number],
      default: null,
      value: "text"
    },
    /**
     * Label of button to be used where there is an icon with no text
     */
    ariaLabel: {
      type: String,
      default: void 0
    },
    /**
     * Name of the predefined icon to display to left of text
     */
    icon: {
      type: [String, Object],
      default: null,
      value: d
    },
    /**
     * Name of the predefined color to style the component with
     */
    color: {
      type: String,
      default: "is-grey",
      validator: /* @__PURE__ */ t((e) => Object.values(o).includes(e), "validator"),
      value: o
    },
    /**
     * Shade of color to use
     */
    shade: {
      type: String,
      default: null,
      value: r
    },
    /**
     * Name of the predefined size to scale the component with
     */
    size: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((e) => Object.values(l).includes(e), "validator"),
      value: l
    },
    tooltip: {
      type: String,
      default: null
    },
    /**
     * Whether the color will be primaraly used to style the border of the component
     */
    isOutlined: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether the component will be displayed as a compact version
     */
    isCompact: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether the badge will be rounded
     */
    isRounded: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["click", "click:disabled"],
  data: /* @__PURE__ */ t((e) => ({
    $cypress: n({ ctx: e, labelField: "text" })
  }), "data"),
  computed: {
    compBadgeClasses() {
      const e = {
        "is-outlined": this.isOutlined,
        "is-icon-badge": this.compHasText === !1,
        "is-icon-text-badge": this.compHasText === !0 && this.icon !== null,
        "is-compact": this.isCompact,
        "is-rounded": this.isRounded,
        [this.color ?? ""]: !0,
        [this.size ?? ""]: !0,
        [this.shade ?? ""]: !0
      };
      return Object.keys(e).filter((i) => e[i]).join(" ");
    },
    compHasText() {
      return this.$slots.default !== void 0 || this.text !== null;
    }
  },
  mounted() {
    this.$slots.default === void 0 && this.text === null && this.ariaLabel === void 0 && this.tooltip === null && console.error(
      "Badge has no text. Add text else add tooltip if it's an icon badge.",
      this.$el
    );
  },
  methods: {}
});
export {
  b as _
};
