// KintinCheckFollowUp, Note, Kintin

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KintinCheckFollowUp } from "@kinherit/sdk/model/kintin-check-follow-up";

export const RecordKintinCheckFollowUp = DefineReadAction({
  interface: "portal",
  resource:
    "/v2/portal/kinvault/{kintin}/kintin-check-follow-up/{kintinCheckFollowUp}",
  method: "get",
  rootModel: KintinCheckFollowUp,
  parse: (kintinCheckFollowUp: KintinCheckFollowUp) => ({
    params: {
      kintinCheckFollowUp,
      kintin: kintinCheckFollowUp.$data.kintin,
    },
  }),
});
