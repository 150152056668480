<template>
  <CreateMfAuthMethod v-if="user" :user="user" @back="back" />
</template>

<cypress-wrapper lang="json">
{
  "name": "UserCreate2FactorAuthWrapper",
  "route": "UserCreate2FactorAuth",
  "selector": ".user-create-2fa",
  "imports": {
    "CreateAuthMethodFormWrapper": "@/module/admin.user/form/update-auth-method.form.test"
  },
  "methods": {
    "form": {
      "type": "to-one",
      "wrapper": "CreateAuthMethodFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import CreateMfAuthMethod from "@/shared/component/mf-auth-method/CreateMfAuthMethod.vue";
import { defineComponent } from "vue";
import {
  AdminUserDetailsCreate2FactorAuthRoute,
  AdminUserDetailsManage2FactorAuthRoute,
} from ".";
import { UserDetailsMixin } from "../../mixin/user-details.mixin";

export default defineComponent({
  name: AdminUserDetailsCreate2FactorAuthRoute,
  components: { CreateMfAuthMethod },
  mixins: [UserDetailsMixin],
  data: () => ({
    exitRoute: AdminUserDetailsManage2FactorAuthRoute,
  }),
  methods: {
    back(): void {
      this.$router.push({
        name: AdminUserDetailsManage2FactorAuthRoute,
        params: this.$params,
      });
    },
  },
});
</script>
