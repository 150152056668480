var b = Object.defineProperty;
var V = (n, t, e) => t in n ? b(n, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : n[t] = e;
var m = (n, t) => b(n, "name", { value: t, configurable: !0 });
var a = (n, t, e) => V(n, typeof t != "symbol" ? t + "" : t, e);
import { OpenFormDialog as j } from "../global/dialog.js";
import { ValidationType as y } from "../validation/core/validation-type.js";
import { hideValidFormFields as O } from "./hide-valid-form-fields.js";
import { GenerateFormValidation as S } from "./validate-form.js";
const d = class d {
  constructor(t) {
    a(this, "helpers");
    this.formBuilder = t, this.helpers = {
      if: /* @__PURE__ */ m((e, r, s) => e ? r : s ?? "", "if")
    };
  }
  get data() {
    return this.formBuilder.localData;
  }
};
m(d, "InternalContent");
let h = d;
const f = class f {
  constructor(t) {
    a(this, "name");
    a(this, "options");
    a(this, "data");
    a(this, "localData");
    a(this, "originalData");
    a(this, "beforeMount");
    /** @internal */
    a(this, "formComponent");
    a(this, "components");
    a(this, "_formAreas");
    a(this, "formAreaInstances", []);
    a(this, "injectedFormAreas", {});
    a(this, "disabledFormAreas", []);
    a(this, "props");
    a(this, "validate");
    var e;
    this.name = t.name, this.options = Object.assign({ showValidation: !1 }, t.options ?? {}), this._formAreas = t.formAreas, this.data = t.data, this.props = t.props ?? {}, this.validate = t.validate ?? (() => !0), this.beforeMount = t.beforeMount, this.localData = ((e = t.data) == null ? void 0 : e.call(t)) ?? {}, this.originalData = JSON.stringify(this.localData ?? {});
  }
  registerFormAreaInstance(t, e) {
    this.formAreaInstances.push([t, e]);
  }
  unregisterFormAreaInstance(t) {
    this.formAreaInstances = this.formAreaInstances.filter(([e]) => e !== t);
  }
  formAreaControls(t) {
    var r;
    const e = (r = this.formAreaInstances.find(([s]) => s === t)) == null ? void 0 : r[1];
    return e ? {
      incrementRenderKey: /* @__PURE__ */ m((s = 0) => e.incrementRenderKey(s), "incrementRenderKey"),
      setBindings: /* @__PURE__ */ m((s, i = 0) => e.setBindings(s, i), "setBindings"),
      forceUpdate: /* @__PURE__ */ m(() => e.$forceUpdate(), "forceUpdate")
    } : null;
  }
  isValid(t) {
    var r;
    return ((r = this.validate) == null ? void 0 : r.call(
      this,
      t ?? this.localData
    )) === !1 ? !1 : S(this, t ?? this.localData).filter(
      (s) => s.result.filter(
        (i) => i.type === y.Error
      ).length > 0
    ).length === 0;
  }
  getValidation(t) {
    return S(this, t ?? this.localData).filter(
      (e) => e.result.filter(
        (r) => r.type === y.Error
      ).length > 0
    );
  }
  hideValid(t) {
    O(this, t ?? this.localData);
  }
  setComponentSets(t) {
    this.components = t;
  }
  async dialog({
    button: t,
    dialog: e
  } = {}) {
    return await j({
      dialog: e ?? {
        title: ""
      },
      button: t,
      form: this
    });
  }
  /** @internal */
  setFormComponent(t) {
    this.formComponent = t;
  }
  resetData() {
    var t;
    this.localData = ((t = this.data) == null ? void 0 : t.call(this)) ?? JSON.parse(this.originalData ?? {}), this.formComponent !== void 0 && this.formComponent.setState(
      `${this.name}-data`,
      JSON.stringify(this.localData)
    );
  }
  async updateState() {
    this.formComponent !== void 0 && this.formComponent.setState(
      `${this.name}-data`,
      JSON.stringify(this.localData)
    );
  }
  isDirty() {
    return JSON.stringify(this.localData) !== this.originalData;
  }
  get controls() {
    var t, e, r, s, i;
    return {
      emit: (t = this.formComponent) == null ? void 0 : t.$emit,
      forceUpdate: (e = this.formComponent) == null ? void 0 : e.$forceUpdate,
      rebuildForm: (r = this.formComponent) == null ? void 0 : r.buildForm,
      incrementFormRenderKey: (s = this.formComponent) == null ? void 0 : s.incrementRenderKey,
      updateFormState: (i = this.formComponent) == null ? void 0 : i.updateState
    };
  }
  addFormArea(t, e, r) {
    return e in this.injectedFormAreas || (this.injectedFormAreas[e] = {
      before: [],
      after: []
    }), this.injectedFormAreas[e][t] = [
      ...this.injectedFormAreas[e][t],
      r
    ], this;
  }
  disableFormArea(t) {
    return this.disabledFormAreas = [...this.disabledFormAreas, t].unique(), this;
  }
  enableFormArea(t) {
    return this.disabledFormAreas = this.disabledFormAreas.remove(t), this;
  }
  formAreas(t) {
    var i, c, p, u, A, F, g;
    const e = ((i = this._formAreas) == null ? void 0 : i.call(this, t)) ?? [], r = Array();
    for (const o of e) {
      const D = o.name;
      o.components && (o.components = o.components.bind(
        new h(this)
      )), r.push(
        ...((u = (p = (c = this.injectedFormAreas) == null ? void 0 : c[D]) == null ? void 0 : p.before) == null ? void 0 : u.map(
          (l) => l(t)
        )) ?? []
      ), r.push(o), r.push(
        ...((g = (F = (A = this.injectedFormAreas) == null ? void 0 : A[D]) == null ? void 0 : F.after) == null ? void 0 : g.map(
          (l) => l(t)
        )) ?? []
      );
    }
    const s = Array();
    for (const o of r)
      this.disabledFormAreas.includes(o.name) || s.push(o);
    return s;
  }
  createState(t) {
    return {
      [`${this.name}-data`]: JSON.stringify(t)
    };
  }
};
m(f, "FormBuilder");
let C = f;
export {
  C as FormBuilder
};
