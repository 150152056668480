var h = Object.defineProperty;
var o = (n, t) => h(n, "name", { value: t, configurable: !0 });
import { KernelModes as g } from "../../core/kernel-mode.js";
import { defineForm as p, defineFormArea as f } from "../../form-builder/define-form.js";
import { ThemeCssBridge as s } from "../bridge.js";
import { CssFontNames as a } from "./color.helper.js";
import { FormAutoCompleteField as y } from "../../component.input/auto-complete-field/index.js";
import { FormColorPickerField as F } from "../../component.input/color-picker-field/index.js";
import { GridLayout as b } from "../../component.layout/dynamic-layout/layout.js";
let i = null;
const r = /* @__PURE__ */ o((n) => {
  const t = /* @__PURE__ */ o(() => {
    var e;
    return i !== null || (i = [], (e = window.Kernel.Theme.GoogleFonts.fonts) == null || e.forEach((l) => {
      var d;
      "variants" in l && ((d = l.variants) == null || d.forEach((u) => {
        i == null || i.push({
          name: `${l.family} (${u})`,
          category: l.category,
          fontUrl: `url(${l.files[u]})`
        });
      }));
    })), i;
  }, "fonts");
  return n !== void 0 ? t().find((e) => e.fontUrl === n) || null : t();
}, "GoogleFontList");
let m = null;
const C = /* @__PURE__ */ o(() => (m === null && (m = r().map((n) => ({
  label: n.category,
  filter: /* @__PURE__ */ o((t) => t.filter((e) => e.category === n.category), "filter")
})).filter(
  (n, t, e) => e.findIndex((l) => l.label === n.label) === t
)), m), "GoogleFontCategories"), c = /* @__PURE__ */ o((n) => {
  if (window.Kernel.Mode === g.UnitTest)
    return [];
  let t = [];
  try {
    t = Array.from(document.documentElement.computedStyleMap().keys());
  } catch {
    t = Object.values(getComputedStyle(document.documentElement));
  }
  return t.filter(
    (e) => e.startsWith("--color") && !e.endsWith("-h") && !e.endsWith("-s") && !e.endsWith("-l") && !e.endsWith("-a") && !e.endsWith("-invert") && !e.endsWith("-light") && !e.endsWith("-dark") && !e.endsWith("-opaque") && !e.endsWith("-bis") && !e.endsWith("-ter")
  );
}, "ListCssVars"), O = /* @__PURE__ */ o(async () => (await window.Kernel.Theme.GoogleFonts.load(), p({
  name: "edit-theme-form",
  data: W,
  formAreas: /* @__PURE__ */ o((n) => [
    f({
      name: "colors",
      data: n,
      options: {
        label: "Colors"
      },
      template: b(c().chunk(2)),
      components: /* @__PURE__ */ o(() => Object.fromEntries(
        c().map((t) => [
          t,
          [
            F({
              models: {
                value: {
                  get: /* @__PURE__ */ o(() => window.getComputedStyle(document.body).getPropertyValue(t), "get"),
                  set: /* @__PURE__ */ o((e) => {
                    e !== null && s.applyColor(
                      t,
                      e
                    );
                  }, "set")
                }
              },
              props: {
                label: t,
                outputFormat: "hsla"
              }
            })
          ]
        ])
      ), "components")
    }),
    f({
      name: "fonts",
      data: n,
      options: {
        label: "Fonts"
      },
      template: {
        slot: "default"
      },
      components: /* @__PURE__ */ o(() => ({
        default: Object.values(a).map(
          (t) => y({
            models: {
              value: {
                set: /* @__PURE__ */ o((e) => (e !== null && s.applyFont(
                  t,
                  e.fontUrl,
                  e.name
                ), e), "set"),
                get: /* @__PURE__ */ o((e) => {
                  const l = e.font[t];
                  return typeof l != "string" ? l : r(l);
                }, "get")
              }
            },
            props: {
              label: t.ucFirst(),
              options: r(),
              mapOptions: {
                label: "name",
                value: "name",
                line1: "category"
              },
              class: "mb-4",
              categories: C()
            }
          })
        )
      }), "components")
    })
  ], "formAreas")
})), "EditThemeForm"), W = /* @__PURE__ */ o(() => ({
  font: {
    "family-heading": r(
      s.getFont(a.familyHeading) || ""
    ),
    "family-body": r(
      s.getFont(a.familyBody) || ""
    ),
    "family-code": r(
      s.getFont(a.familyCode) || ""
    )
  }
}), "EditThemeData");
export {
  W as EditThemeData,
  O as EditThemeForm
};
