// Property

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Property } from "@kinherit/sdk/model/property";

export const DeleteProperty = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/property/{property}",
  method: "delete",
  parse: (property: Property) => ({
    params: {
      property,
      kintin: property.$data.kintin,
    },
  }),
  after: ({ message }) => {
    Property.$delete(message);
  },
});
