import { Api } from "@kinherit/sdk/api";

export const EnforceMultiFactor = async (): Promise<{
  hasMultiFactor: boolean;
  enforce: boolean | "recommended";
}> => {
  return await Api.resource("core", "/v2/core/environment/enforce-multi-factor")
    .method("get")
    .result();
};
