<template>
  <Card title="Time to First Call" class="time-to-first-call-report">
    <p>
      From account creation date (i.e lead conversion date) how long till an
      info call gets booked in. Higher figures are expected in December due to
      booking in post christmas. Total will differ to number of created accounts
      as not all appointments can be matched to an account. Any outliers are
      removed from the data (i.e appointment dates before account creation date
      and uses IQR to remove outliers).
    </p>
    <MasterListFilters
      :filters="$data.filters"
      :track-changes="false"
      @submit="load"
    />
    <div
      v-if="datasets.length > 0"
      class="chart-container"
      style="position: relative; height: 40vh"
    >
      <BarChart
        :datasets="datasets"
        :labels="labels"
        :options="{
          maintainAspectRatio: false,
          scales: {
            y: {
              type: 'linear',
              position: 'left',
            },
          },
        }"
      />
    </div>
    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="columns"
      :row-classes="rowClasses"
      :rows="results"
      @loaded="load"
    >
      <!-- Whilst this can be shared amongst report, it feels the wrong place for it-->
      <template #date="{ row }">
        {{ row.year }}
        <span v-if="$data.filters?.localData?.granularity === 'week'">
          W{{ row.week }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'month'">
          <!-- Best way to get month name? -->
          {{ row.month }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'quarter'">
          {{ row.quarter }}
        </span>
      </template>
    </Table>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "TimeToFirstCallReport",
  "route": "TimeToFirstCallReportRoute",
  "selector": ".time-to-first-call-report"
}
</cypress-wrapper>

<script lang="ts">
import BarChart from "@kinherit/framework/component.chart/bar-chart";
import Table from "@kinherit/framework/component.display/table";
import { defineComponent } from "vue";
import { TimeToFirstCallReportRoute } from ".";
import { hasCurrentRowHighlighting } from "../../data/helper-functions";

import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";

import { TTimeToFirstCallReportResult } from "@/action-bus/portal/report/compute-time-to-first-call-report.action";
import { ReadTimeToFirstCallReportFilters } from "../../form/read-time-to-first-call-report.form";
export default defineComponent({
  name: TimeToFirstCallReportRoute,
  components: {
    Card,
    MasterListFilters,
    BarChart,
    Table,
  },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.reports)],
  data: () => ({
    datasets: Array<{
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }>(),
    labels: Array<string>(),
    results: [] as any[],
    filters: ReadTimeToFirstCallReportFilters(),
    columns: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "Date",
        slot: "date",
      },
      {
        title: "Avg",
        class: "has-text-weight-bold has-text-brown",
        map: (results: TTimeToFirstCallReportResult) =>
          results.avgDaysDifference.toFixed(1),
      },
      {
        title: "Median",
        class: "has-text-weight-bold has-text-brown",
        map: (results: TTimeToFirstCallReportResult) =>
          results.medianDaysDifference.toFixed(1),
      },
      {
        title: "Min",
        class: "has-text-weight-bold has-text-brown",
        map: (results: TTimeToFirstCallReportResult) =>
          results.minDaysDifference,
      },
      {
        title: "Max",
        class: "has-text-weight-bold has-text-brown",
        map: (results: TTimeToFirstCallReportResult) =>
          results.maxDaysDifference,
      },
      {
        title: "Total", // the total number of appointments
        class: "has-text-weight-bold",
        map: (results: TTimeToFirstCallReportResult) =>
          results.totalAppointments,
      },
    ],
  }),
  methods: {
    async load() {
      const result = await this.$actionBus.TimeToFirstCall(
        this.filters.localData,
      );
      this.datasets = result.datasets;
      this.labels = result.labels.map((label) => label.toString());
      this.results = result.results;
    },
    rowClasses({
      row,
    }: {
      index: number;
      row: unknown;
    }): Record<string, boolean> {
      return {
        "has-background-highlight-light": hasCurrentRowHighlighting(
          this.filters.localData.granularity,
          row,
        ),
      };
    },
  },
});
</script>
