var o = Object.defineProperty;
var t = (e, i) => o(e, "name", { value: i, configurable: !0 });
import r from "../../_vue/component.display/icon/icon.js";
import m from "../../_vue/component.form/field/field.js";
import { FieldMixin as s } from "../../component.mixin/field-mixin.js";
import { CypressHelper as l } from "../../cypress/component-helpers.js";
import { defineComponent as n } from "vue";
import { S as d } from "../../_assets/component.input/switch-field/switch-field.module.js";
const y = n({
  name: "SwitchFieldComponent",
  components: { Icon: r, Field: m },
  mixins: [s()],
  emits: ["blur"],
  data: /* @__PURE__ */ t((e) => ({
    $cypress: l({ ctx: e, labelField: "label" })
  }), "data"),
  computed: {
    valueGuard: {
      get() {
        return this.value;
      },
      set() {
      }
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("switch-field", d);
  },
  methods: {
    toggle() {
      ["readonly", "disabled"].includes(this.state) || (this.computedValue = !this.computedValue, this.$emit("blur"));
    }
  }
});
export {
  y as _
};
