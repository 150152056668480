import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { KinvaultCoupon } from "@kinherit/sdk";

export const DeleteKinvaultCoupon = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/kinvaults/coupons/{kinvaultCoupon}",
  method: "delete",
  parse: (message: string | KinvaultCoupon) => ({
    params: {
      kinvaultCoupon: message,
    },
  }),
  after: ({ message }) => {
    KinvaultCoupon.$delete(message);
  },
});
