import { UserField } from "@/config/form.config";
import KintinStatus from "@/shared/component/kintin/KintinStatus.vue";
import { FormTable } from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { FormElement } from "@kinherit/framework/component.form/element";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In } from "@kinherit/orm/index";
import { Kintin, User } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const ReassignKintinsForm = (kintins: Kintin[], filterCount: number) =>
  defineForm({
    name: "reassign-kintins",
    data: () => ({
      kintins,
      estatePlanners: [] as User[],
      legalAssistants: [] as User[],
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "reassign-kintins",
        data,
        template: GridLayout([
          ["estatePlanners", "legalAssistants"],
          ["kintins"],
        ]),
        components: () => ({
          estatePlanners: [
            UserField({
              props: {
                label: "Estates Planners",
                vModel: "estatePlanners",
                isMultiSelect: true,
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
              simplifyData: true,
            }),
          ],
          legalAssistants: [
            UserField({
              props: {
                label: "Legal Assistants",
                vModel: "legalAssistants",
                isMultiSelect: true,
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
              simplifyData: true,
            }),
          ],
          kintins: [
            FormTable({
              props: {
                vIf: (data) => data.kintins.length > 0,
                columns: [
                  {
                    title: "Name",
                    map: (kintin) => kintin.friendlyName,
                  },
                  {
                    title: "Estate Planners",
                    map: (kintin) =>
                      kintin.estatePlanners
                        .map((e) => e.profile.fullName)
                        .join(", "),
                  },
                  {
                    title: "Legal Assistants",
                    map: (kintin) =>
                      kintin.legalAssistants
                        .map((e) => e.profile.fullName)
                        .join(", "),
                  },
                  {
                    title: "Status",
                    component: (kintin) =>
                      defineComponent({
                        // template: `<KintinStatus :key="kintin.id" :kintin="kintin" @click.stop />`,
                        components: {
                          KintinStatus,
                        },
                        data: () => ({
                          kintin,
                        }),
                        render() {
                          return (
                            <KintinStatus
                              key={this.kintin.id}
                              kintin={this.$data.kintin}
                              // @ts-ignore
                              onClick={(e: MouseEvent) => e.stopPropagation()}
                            />
                          );
                        },
                      }),
                  },
                ] as TableColumn<Kintin>[],
                rows: (data) => data.kintins,
              },
            }),
            FormElement({
              props: {
                vIf: (data) => data.kintins.length === 0,
                html: `<h3 style='text-align:center' class='mt-5'>Changes will be applied to the <span class="has-text-danger">${filterCount.format()}</span> accounts that match the search criteria</h3>`,
              },
            }),
          ],
        }),
      }),
    ],
  });
