<template>
  <div class="report-sidebar">
    <Tabs
      v-if="filteredOptions.length > 0"
      :tab="currentTabIndex"
      class="sidebar-page__tabs"
      is-vertical
      :config="filteredOptions"
    />
  </div>
</template>

<script lang="ts">
import {
  AccountsReportRoute,
  AppointmentsReportRoute,
  AvailabilityReportRoute,
  CashflowByEpReportRoute,
  CashflowReportRoute,
  DocstorageReportRoute,
  LeadsByEpReportRoute,
  LeadsReportRoute,
  ProductsReportRoute,
  SummaryReportRoute,
  TimeToFirstCallReportRoute,
  TimeToSecondCallReportRoute,
} from "@/module/report/page/core";
import { SignupsReportRoute } from "@/module/report/page/kinvault";
import {
  AgreementsReportRoute,
  CompaniesReportRoute,
  CompanyLeadboardReportRoute,
  DownloadReportRoute,
} from "@/module/report/page/sales";
import Tabs from "@kinherit/framework/component.display/tabs";
import { defineComponent, PropType } from "vue";

import { AuthService } from "@/service/auth.service";
import { RouteTabOptions } from "@kinherit/framework/component.display/tabs/types";

type Section = "core" | "sales" | "kinvault";

export default defineComponent({
  name: "ReportSidebar",
  components: {
    Tabs,
  },
  mixins: [AuthService.mixin()],
  props: {
    section: {
      type: String as PropType<Section>,
      required: true,
    },
  },
  computed: {
    coreOptions(): RouteTabOptions[] {
      const options = [
        {
          label: "Summary",
          route: {
            name: SummaryReportRoute,
          },
        },
        {
          label: "Leads",
          route: {
            name: LeadsReportRoute,
          },
        },
        {
          label: "Cashflow",
          route: {
            name: CashflowReportRoute,
          },
        },
        {
          label: "Accounts",
          route: {
            name: AccountsReportRoute,
          },
        },
        {
          label: "Appointments",
          route: {
            name: AppointmentsReportRoute,
          },
        },
        {
          label: "Availability",
          route: {
            name: AvailabilityReportRoute,
          },
        },
        {
          label: "Time to First Call",
          route: {
            name: TimeToFirstCallReportRoute,
          },
        },
        {
          label: "Time to Second Call",
          route: {
            name: TimeToSecondCallReportRoute,
          },
        },
        {
          label: "Products",
          route: {
            name: ProductsReportRoute,
          },
        },
        {
          label: "Doc Storage",
          route: {
            name: DocstorageReportRoute,
          },
        },
      ];
      if (this.$auth.hasRole("admin")) {
        options.push(
          {
            label: "Cashflow by EP",
            route: {
              name: CashflowByEpReportRoute,
            },
          },
          {
            label: "Leads by EP",
            route: {
              name: LeadsByEpReportRoute,
            },
          },
        );
      }
      return options;
    },
    salesOptions(): RouteTabOptions[] {
      return [
        {
          label: "Leaderboard",
          route: {
            name: CompanyLeadboardReportRoute,
          },
        },
        {
          label: "Agreements",
          route: {
            name: AgreementsReportRoute,
          },
        },
        {
          label: "Companies",
          route: {
            name: CompaniesReportRoute,
          },
        },
        {
          label: "Downloads",
          route: {
            name: DownloadReportRoute,
          },
        },
      ];
    },
    kinvaultOptions(): RouteTabOptions[] {
      return [
        {
          label: "Signups",
          route: {
            name: SignupsReportRoute,
          },
        },
      ];
    },
    allOptions(): Record<Section, RouteTabOptions[]> {
      return {
        core: this.coreOptions,
        sales: this.salesOptions,
        kinvault: this.kinvaultOptions,
      };
    },
    filteredOptions(): RouteTabOptions[] {
      return this.allOptions[this.section as Section] || [];
    },
    currentTabIndex(): number {
      const currentRoute = this.$route;
      const path = currentRoute?.path || "";
      const routeName = currentRoute?.name?.toString() || "";

      return this.filteredOptions.findIndex((tab) => {
        const tabRoute = tab.route as { name: string };
        return (
          tabRoute.name === routeName ||
          path.endsWith(tabRoute.name.toLowerCase().replace("route", ""))
        );
      });
    },
  },
  watch: {
    section: {
      immediate: true,
      handler(newSection: Section) {
        const options = this.allOptions[newSection];
        if (options && options.length > 0) {
          const currentRouteName = this.$route?.name?.toString() || "";
          const isCurrentRouteInSection = options.some((opt) => {
            const optRoute = opt.route as { name: string };
            return optRoute.name === currentRouteName;
          });
          if (!isCurrentRouteInSection) {
            const firstRoute = options[0].route as { name: string };
            this.$router.push({ name: firstRoute.name });
          }
        }
      },
    },
  },
});
</script>

<style lang="scss">
.report-sidebar {
  border-right: 1px solid var(--color-border);
  padding: 0.5rem 0;
}
</style>
