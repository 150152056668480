var $ = Object.defineProperty;
var m = (e, t) => $(e, "name", { value: t, configurable: !0 });
import { _ as g } from "../../../component.page/master-list-page/master-list-page.vue_vue_type_script_lang.js";
import { resolveComponent as i, createElementBlock as b, openBlock as o, createBlock as a, Fragment as w, createCommentVNode as p, createSlots as d, withCtx as r, renderSlot as l, createVNode as P, renderList as S } from "vue";
import { b as f } from "../../../_assets/component.page/master-list-page/master-list-page.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as h } from "../../../_plugins/_plugin-vue_export-helper.js";
const v = {
  style: { display: "contents" },
  class: "master-list-page"
};
function B(e, t, M, F, T, L) {
  const k = i("PageHeader"), u = i("MasterListFilters"), y = i("Table"), C = i("Card");
  return o(), b("div", v, [
    e.isNested ? (o(), a(C, {
      key: 1,
      "is-nested": e.isNested,
      title: e.computedTitle,
      subtitle: e.subtitle,
      "is-plain": e.isPlain
    }, d({
      default: r(() => [
        e.showFilters ? (o(), a(u, {
          key: 0,
          class: "masster-list-filters",
          filters: e.$props.filters,
          "has-context-menu": e.hasContextMenu,
          "has-create-button": e.hasCreateButton,
          "persist-method": e.persistMethod,
          onSubmit: e.search,
          onContextMenu: t[2] || (t[2] = (s) => e.$emit("context-menu", s)),
          onCreate: t[3] || (t[3] = (s) => e.$emit("create", s))
        }, null, 8, ["filters", "has-context-menu", "has-create-button", "persist-method", "onSubmit"])) : p("", !0),
        P(y, {
          page: e.computedCurrentPage,
          "onUpdate:page": t[4] || (t[4] = (s) => e.computedCurrentPage = s),
          "per-page": e.computedPerPage,
          "onUpdate:perPage": t[5] || (t[5] = (s) => e.computedPerPage = s),
          "sort-by": e.computedSortBy,
          "onUpdate:sortBy": t[6] || (t[6] = (s) => e.computedSortBy = s),
          "sort-direction": e.computedSortDirection,
          "onUpdate:sortDirection": t[7] || (t[7] = (s) => e.computedSortDirection = s),
          value: e.computedValue,
          "onUpdate:value": t[8] || (t[8] = (s) => e.computedValue = s),
          "persist-state": e.persistState,
          "is-scrollable": "",
          "enable-per-page": e.enablePerPage,
          "total-pages": e.lastPage,
          "total-records": e.count,
          columns: e.columns,
          rows: e.rows,
          "row-classes": e.rowClasses,
          "is-fullwidth": "",
          "is-narrow": "",
          "is-hoverable": "",
          "is-fixed": e.isFixed,
          "is-clickable": e.isClickable,
          "sort-by-options": e.sortByOptions,
          "is-expanded": e.isExpanded,
          "click-to-expand": e.clickToExpand,
          "checkbox-field": e.trackingField !== null,
          "tracking-field": e.trackingField,
          "empty-message": e.emptyMessage,
          onClick: t[9] || (t[9] = (s, n) => e.$emit("row-clicked", s, n)),
          onLoaded: t[10] || (t[10] = (s) => e.loading = !1)
        }, d({
          tableControls: r(() => [
            l(e.$slots, "tableControls")
          ]),
          _: 2
        }, [
          S(e.slots, (s) => ({
            name: `${s}`,
            fn: r((n) => [
              l(e.$slots, s, {
                row: n.row,
                index: n.index
              })
            ])
          }))
        ]), 1032, ["page", "per-page", "sort-by", "sort-direction", "value", "persist-state", "enable-per-page", "total-pages", "total-records", "columns", "rows", "row-classes", "is-fixed", "is-clickable", "sort-by-options", "is-expanded", "click-to-expand", "checkbox-field", "tracking-field", "empty-message"])
      ]),
      _: 2
    }, [
      e.$slots.buttons ? {
        name: "buttons",
        fn: r(() => [
          l(e.$slots, "buttons")
        ]),
        key: "0"
      } : void 0
    ]), 1032, ["is-nested", "title", "subtitle", "is-plain"])) : (o(), b(w, { key: 0 }, [
      e.computedTitle ? (o(), a(k, {
        key: 0,
        htag: "h1",
        text: e.computedTitle
      }, d({ _: 2 }, [
        e.$slots.buttons ? {
          name: "buttons",
          fn: r(() => [
            l(e.$slots, "buttons")
          ]),
          key: "0"
        } : void 0
      ]), 1032, ["text"])) : p("", !0),
      e.showFilters ? (o(), a(u, {
        key: 1,
        class: "masster-list-filters",
        title: e.computedTitle,
        subtitle: e.subtitle,
        filters: e.$props.filters,
        "has-context-menu": e.hasContextMenu,
        "has-create-button": e.hasCreateButton,
        "persist-method": e.persistMethod,
        onSubmit: e.search,
        onContextMenu: t[0] || (t[0] = (s) => e.$emit("context-menu", s)),
        onCreate: t[1] || (t[1] = (s) => e.$emit("create", s))
      }, null, 8, ["title", "subtitle", "filters", "has-context-menu", "has-create-button", "persist-method", "onSubmit"])) : p("", !0)
    ], 64))
  ]);
}
m(B, "_sfc_render");
typeof f == "function" && f(g);
const H = /* @__PURE__ */ h(g, [["render", B]]);
export {
  H as default
};
