// AccountReferral

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk";
import { AccountReferral } from "@kinherit/sdk/model/account-referral";

export const ReadAccountReferral = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/account-referral",
  method: "get",
  rootModel: AccountReferral,
  parse: (target: Kintin) => ({
    params: {
      kintin: target,
    },
  }),
});
