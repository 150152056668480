var go = Object.defineProperty;
var n = (e, p) => go(e, "name", { value: p, configurable: !0 });
import { _ as bo } from "../../../core/internal-components/dialog-loader.vue_vue_type_script_lang.js";
import { resolveComponent as c, createElementBlock as i, openBlock as l, Fragment as ho, renderList as vo, createBlock as r, Teleport as ko, createVNode as co, normalizeClass as f, withCtx as _o, createElementVNode as io, createCommentVNode as a, toDisplayString as yo } from "vue";
import { _ as wo } from "../../../_plugins/_plugin-vue_export-helper.js";
const Co = { class: "pragmatic-dialog-loader" }, So = { class: "modal-card__content" }, To = {
  key: 0,
  class: "modal-card__content__icon--message"
}, Fo = {
  key: 0,
  class: "modal-card__content__icon"
}, $o = { class: "modal-card__content__message" }, Io = { key: 0 }, Do = ["innerHTML"];
function Uo(e, p, Vo, Bo, qo, Lo) {
  const ro = c("Icon"), ao = c("TextField"), uo = c("AutoCompleteField"), fo = c("Panel"), po = c("Form"), mo = c("Dialog");
  return l(), i("div", Co, [
    (l(!0), i(ho, null, vo(e.$data.dialogRequests, (o, t) => {
      var m, g, b, d, h, v, k, _, y, w, C, S, T, F, $, I, D, U;
      return l(), r(ko, {
        key: `dialog-${t}`,
        to: ".pragmatic-container",
        disabled: e.isUnitTest
      }, [
        co(mo, {
          class: f({
            [o.request.config.contentType]: !0
          }),
          show: !0,
          title: o.request.config.dialog.title,
          "ok-button-color": (g = (m = o.request.config.button) == null ? void 0 : m.ok) == null ? void 0 : g.color,
          "ok-button-label": (d = (b = o.request.config.button) == null ? void 0 : b.ok) == null ? void 0 : d.text,
          "cancel-button-color": (v = (h = o.request.config.button) == null ? void 0 : h.cancel) == null ? void 0 : v.color,
          "cancel-button-label": (_ = (k = o.request.config.button) == null ? void 0 : k.cancel) == null ? void 0 : _.text,
          "show-cancel-button": (w = (y = o.request.config.button) == null ? void 0 : y.cancel) == null ? void 0 : w.show,
          "show-ok-button": (S = (C = o.request.config.button) == null ? void 0 : C.ok) == null ? void 0 : S.show,
          "left-button-color": (F = (T = o.request.config.button) == null ? void 0 : T.left) == null ? void 0 : F.color,
          "left-button-label": (I = ($ = o.request.config.button) == null ? void 0 : $.left) == null ? void 0 : I.text,
          "show-left-button": (U = (D = o.request.config.button) == null ? void 0 : D.left) == null ? void 0 : U.show,
          "ok-button-disabled": o.okButtonDisabled,
          type: o.request.config.dialog.type,
          "show-footer": o.request.config.dialog.showFooter,
          "is-above-loading": o.request.config.dialog.isAboveLoading,
          "fill-screen": o.request.config.dialog.fillScreen,
          onResolve: /* @__PURE__ */ n(() => e.resolve(t), "onResolve"),
          onReject: /* @__PURE__ */ n(() => e.reject(t), "onReject"),
          "onClick:left": /* @__PURE__ */ n((u) => e.leftClick(u, t), "onClick:left"),
          "onClick:background": /* @__PURE__ */ n(() => e.backgroundClick(t), "onClick:background")
        }, {
          default: _o(() => {
            var u, V, B, q, L, M, j, A, N, O, P, z, E, H, G, J, K, Q, W, X, Y, Z, x, R, oo, eo, to, no, lo, so;
            return [
              io("div", So, [
                o.request.config.dialog.message || o.request.config.dialog.html ? (l(), i("div", To, [
                  o.request.config.dialog.icon ? (l(), i("div", Fo, [
                    co(ro, {
                      size: "is-large",
                      icon: o.request.config.dialog.icon
                    }, null, 8, ["icon"])
                  ])) : a("", !0),
                  io("div", $o, [
                    o.request.config.dialog.message ? (l(), i("p", Io, yo(o.request.config.dialog.message), 1)) : a("", !0),
                    o.request.config.dialog.html ? (l(), i("div", {
                      key: 1,
                      innerHTML: o.request.config.dialog.html
                    }, null, 8, Do)) : a("", !0)
                  ])
                ])) : a("", !0),
                o.request.config.contentType === "dialog-text" ? (l(), r(ao, {
                  key: 1,
                  value: e.dialogRequests[t].value,
                  "onUpdate:value": /* @__PURE__ */ n((s) => e.dialogRequests[t].value = s, "onUpdate:value"),
                  class: f(`dialog-input-${t}`),
                  "left-icon": (V = (u = o.request.config) == null ? void 0 : u.input) == null ? void 0 : V.leftIcon,
                  "right-icon-override": (q = (B = o.request.config) == null ? void 0 : B.input) == null ? void 0 : q.rightIconOverride,
                  validators: (M = (L = o.request.config) == null ? void 0 : L.input) == null ? void 0 : M.validation,
                  placeholder: (A = (j = o.request.config) == null ? void 0 : j.input) == null ? void 0 : A.placeholder,
                  message: (O = (N = o.request.config) == null ? void 0 : N.input) == null ? void 0 : O.message,
                  autofocus: "",
                  onIsValid: /* @__PURE__ */ n((s) => o.okButtonDisabled = !s, "onIsValid"),
                  onSubmit: /* @__PURE__ */ n(() => e.resolve(t), "onSubmit")
                }, null, 8, ["value", "onUpdate:value", "class", "left-icon", "right-icon-override", "validators", "placeholder", "message", "onIsValid", "onSubmit"])) : o.request.config.contentType === "dialog-autocomplete" ? (l(), r(uo, {
                  key: 2,
                  value: e.dialogRequests[t].value,
                  "onUpdate:value": /* @__PURE__ */ n((s) => e.dialogRequests[t].value = s, "onUpdate:value"),
                  label: "",
                  class: f(`dialog-input-${t}`),
                  "show-search-button": !1,
                  "show-more-button": !1,
                  "show-drop-down": !0,
                  options: (z = (P = o.request.config) == null ? void 0 : P.list) == null ? void 0 : z.options(),
                  "map-options": (H = (E = o.request.config) == null ? void 0 : E.list) == null ? void 0 : H.mapOptions,
                  message: (J = (G = o.request.config) == null ? void 0 : G.input) == null ? void 0 : J.message,
                  sort: (Q = (K = o.request.config) == null ? void 0 : K.list) == null ? void 0 : Q.sort,
                  "async-filter": o.request.config.asyncFilter,
                  "is-multi-select": (X = (W = o.request.config) == null ? void 0 : W.list) == null ? void 0 : X.isMultiSelect,
                  placeholder: (Z = (Y = o.request.config) == null ? void 0 : Y.input) == null ? void 0 : Z.placeholder,
                  autofocus: "",
                  onIsValid: /* @__PURE__ */ n((s) => o.okButtonDisabled = !s, "onIsValid"),
                  onSubmit: /* @__PURE__ */ n(() => e.resolve(t), "onSubmit")
                }, null, 8, ["value", "onUpdate:value", "class", "options", "map-options", "message", "sort", "async-filter", "is-multi-select", "placeholder", "onIsValid", "onSubmit"])) : o.request.config.contentType === "dialog-panel" ? (l(), r(fo, {
                  key: 3,
                  value: e.dialogRequests[t].value,
                  "onUpdate:value": /* @__PURE__ */ n((s) => e.dialogRequests[t].value = s, "onUpdate:value"),
                  class: f(`dialog-input-${t}`),
                  categories: o.request.config.categories,
                  options: (R = (x = o.request.config) == null ? void 0 : x.list) == null ? void 0 : R.options(),
                  "map-options": (eo = (oo = o.request.config) == null ? void 0 : oo.list) == null ? void 0 : eo.mapOptions,
                  "is-multi-select": (no = (to = o.request.config) == null ? void 0 : to.list) == null ? void 0 : no.isMultiSelect,
                  placeholder: (so = (lo = o.request.config) == null ? void 0 : lo.input) == null ? void 0 : so.placeholder,
                  autofocus: "",
                  onSubmit: /* @__PURE__ */ n(() => e.resolve(t), "onSubmit")
                }, null, 8, ["value", "onUpdate:value", "class", "categories", "options", "map-options", "is-multi-select", "placeholder", "onSubmit"])) : o.request.config.contentType === "dialog-form" ? (l(), r(po, {
                  key: 4,
                  config: o.request.config.form,
                  "is-dialog": "",
                  onSubmit: /* @__PURE__ */ n(() => e.resolve(t), "onSubmit")
                }, null, 8, ["config", "onSubmit"])) : a("", !0)
              ])
            ];
          }),
          _: 2
        }, 1032, ["class", "title", "ok-button-color", "ok-button-label", "cancel-button-color", "cancel-button-label", "show-cancel-button", "show-ok-button", "left-button-color", "left-button-label", "show-left-button", "ok-button-disabled", "type", "show-footer", "is-above-loading", "fill-screen", "onResolve", "onReject", "onClick:left", "onClick:background"])
      ], 8, ["disabled"]);
    }), 128))
  ]);
}
n(Uo, "_sfc_render");
const Oo = /* @__PURE__ */ wo(bo, [["render", Uo]]);
export {
  Oo as default
};
