import {
  OrderDetailsParams,
  OrderDetailsRoute,
} from "@/module/order/page/details";
import { AuthService } from "@/service/auth.service";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { Order } from "@kinherit/sdk";
import { OrderCalculator } from "@kinherit/ts-common";
import { defineComponent } from "vue";

export const OrderDetailsMixin = defineComponent({
  name: "OrderDetailsMixin",
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.order)],
  computed: {
    $params(): OrderDetailsParams {
      return this.$route.params as any;
    },
    order(): Order | null {
      return Order.$findOne(this.$params.order);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("order:write");
    },
    orderTotals() {
      if (!this.order) {
        return null;
      }
      return OrderCalculator.getOrderValues(this.order);
    },
  },
  methods: {
    visitOrderDetails(): void {
      window.Kernel.Router.push({
        name: OrderDetailsRoute,
        params: { order: this.order?.id },
      });
    },
  },
});
