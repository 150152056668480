import { Model } from "@kinherit/orm";
import {
  Api,
  BrandedKinvault,
  EmailLog,
  FileLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";
type Message = {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  fileLog: FileLog | string;
};

export const DeleteEmailLogAttachmentHandler = async (
  message: Message,
): Promise<void> => {
  const routes = {
    [IntroducerCompany.$name]:
      "/v2/portal/introducer/company/{owner}/email-log/{emailLog}/attachment/{fileLog}",
    [IntroducerContact.$name]:
      "/v2/portal/introducer/contact/{owner}/email-log/{emailLog}/attachment/{fileLog}",
    [Kintin.$name]:
      "/v2/portal/kinvault/{owner}/email-log/{emailLog}/attachment/{fileLog}",
    [Lead.$name]:
      "/v2/portal/lead/{owner}/email-log/{emailLog}/attachment/{fileLog}",
    [Person.$name]:
      "/v2/portal/officer/{owner}/email-log/{emailLog}/attachment/{fileLog}",
    [BrandedKinvault.$name]:
      "/v2/portal/admin/branded-kinvault/{owner}/email-log/{emailLog}/attachment/{fileLog}",
  } as const;

  const route = routes[message.owner.$name];

  await Api.resource("portal", route, message).method("delete").result();
};
