var s = Object.defineProperty;
var e = (t, a) => s(t, "name", { value: a, configurable: !0 });
import r from "../../_vue/component.form/form/form.js";
import o from "../../_vue/component.input/button/button.js";
import { defineComponent as n, ref as i } from "vue";
const p = n({
  name: "MasterListFiltersComponent",
  components: { Button: o, Form: r },
  props: {
    persistMethod: {
      type: String,
      default: "query"
    },
    filters: {
      type: Object,
      default: null
    },
    hasContextMenu: {
      type: Boolean,
      default: !1
    },
    hasCreateButton: {
      type: Boolean,
      default: !1
    },
    isNested: {
      type: Boolean,
      default: !1
    }
  },
  emits: {
    submit: null,
    change: null,
    "context-menu": null,
    create: /* @__PURE__ */ e((t) => t instanceof MouseEvent, "create")
  },
  setup() {
    return {
      form: i()
    };
  },
  data: /* @__PURE__ */ e(() => ({
    rawData: null,
    timer: null
  }), "data"),
  beforeMount() {
    this.rawData = this.getRawData();
  },
  methods: {
    resetData() {
      this.filters && (this.filters.resetData(), this.filters.controls.rebuildForm(), this.search());
    },
    getRawData() {
      var t;
      return JSON.stringify(((t = this.filters) == null ? void 0 : t.localData) ?? {});
    },
    search() {
      this.$emit("submit");
    }
  }
});
export {
  p as _
};
