var o = Object.defineProperty;
var w = (s, t, e) => t in s ? o(s, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : s[t] = e;
var a = (s, t) => o(s, "name", { value: t, configurable: !0 });
var i = (s, t, e) => w(s, typeof t != "symbol" ? t + "" : t, e);
import f from "axios";
const n = class n {
  constructor() {
    i(this, "_fonts", null);
  }
  get fonts() {
    return this._fonts ?? [];
  }
  async load() {
    if (this._fonts !== null || window.Kernel.Mode === "Test" || window.Kernel.Mode === "UnitTest")
      return;
    const { data: t } = await f.request({
      url: "https://www.googleapis.com/webfonts/v1/webfonts",
      method: "get",
      params: {
        key: "AIzaSyADm3z1n1YJt4-7ozfLiRalwKmVbDFr-kc"
      }
    });
    this._fonts = t.items;
  }
};
a(n, "GoogleFonts");
let r = n;
export {
  r as GoogleFonts
};
