<template>
  <div class="kintin-details-address-book-guardians">
    <PageHeader v-if="kintin" htag="h2" text="Guardians">
      <template #buttons>
        <Button
          text="Add Guardian"
          class="add-button"
          @click="createGuardian"
        />
        <Button
          v-if="!isPeopleTab"
          :text="sorting ? 'Done' : 'Sort'"
          class="sort-button"
          @click="togglingSorting"
        />
        <!-- If more than one child -->
        <Button
          v-if="dependents.length > 1"
          text="Copy To"
          class="copy-button"
          @click="copy"
        />
      </template>
    </PageHeader>
    <div v-if="kintin && dependents.length > 0">
      <Tabs
        v-if="!sorting"
        v-model:tab="computedTab"
        :config="tabConfig"
        size="is-normal"
        :is-fullwidth="false"
        class="mb-4 person-tabs"
      />
      <div v-if="!isPeopleTab">
        <Title size="is-6" is-emphasised> Main Guardians </Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.guardians.main"
        />
        <GuardianList
          v-else
          class="main-guardian-list"
          :kintin="kintin"
          :guardians="$data.filtered.guardians.main"
          @reload="filter"
        />
        <Title size="is-6" is-emphasised> Reserve Guardians </Title>
        <SortRoles
          v-if="sorting"
          :kintin="kintin"
          :roles="$data.filtered.guardians.reserve"
        />
        <GuardianList
          v-else
          class="reserve-guardian-list"
          :kintin="kintin"
          :guardians="$data.filtered.guardians.reserve"
          @reload="filter"
        />
      </div>
      <div v-else>
        <PersonList
          v-if="isPeopleTab"
          :key="renderKey"
          :kintin="kintin"
          :people="$data.filtered.people"
          @reload="filter"
        />
      </div>
      <Json v-if="isDevMode" :data="filtered" />
    </div>
    <div v-else>
      <Message title="Unable to display" title-size="is-5" color="is-warning">
        No dependents / children under 18 found: ensure at least one person has
        either <em>Is under 18 for planning purposes</em> or
        <em>Requires specialist care</em> ticked.
      </Message>
    </div>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "GuardiansContainerWrapper",
  "selector": ".kintin-details-address-book-guardians",
  "imports": {
    "PersonListWrapper": "@/module/kinvault.kintin/component/lists/PersonList.test",
    "GuardianListWrapper": "@/module/kinvault.kintin/component/lists/GuardianList.test",
    "UpdateGuardianFormWrapper": "@/module/kinvault.kintin/form/update-guardian.form.test"
  },
  "methods": {
    "tabs": {
      "type": "indexed-tabs",
      "selector": ".person-tabs"
    },
    "mainGuardianList": {
      "type": "to-one",
      "selector": ".main-guardian-list",
      "wrapper": "GuardianListWrapper"
    },
    "reserveGuardianList": {
      "type": "to-one",
      "selector": ".reserve-guardian-list",
      "wrapper": "GuardianListWrapper"
    },
    "personList": {
      "type": "to-one",
      "selector": ".person-list",
      "wrapper": "PersonListWrapper"
    },
    "addGuardian": {
      "type": "click",
      "selector": ".add-button"
    },
    "addGuardianForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateGuardianFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateGuardian } from "@/config/model.config";
import GuardianList from "@/module/kinvault.kintin/component/lists/GuardianList.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { CopyRolesForm } from "@/module/kinvault.kintin/form/copy-roles.form";
import { UpdateGuardianForm } from "@/module/kinvault.kintin/form/update-guardian.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { StyleService } from "@/service/style.service";
import { Json } from "@kinherit/framework/component.display/json";
import Message from "@kinherit/framework/component.display/message";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { Title } from "@kinherit/framework/component.display/title";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { OpenAutocompleteDialog } from "@kinherit/framework/global/dialog";
import { In } from "@kinherit/orm";
import { Guardian, Kintin, Person } from "@kinherit/sdk";
import { Uuid } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import SortRoles from "./SortRoles.vue";

export default defineComponent({
  name: `GuardiansContainer`,
  components: {
    Tabs,
    Json,
    GuardianList,
    Button,
    PageHeader,
    PersonList,
    Message,
    Title,
    SortRoles,
  },
  mixins: [KinvaultKintinDetailsMixin],
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  emits: ["reload"],
  data: () => ({
    filtered: {
      guardians: {
        main: Array<Guardian>(),
        reserve: Array<Guardian>(),
      },
      people: Array<Person>(),
    },
    selectedTab: 0,
    sorting: false,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
    renderKey: 0,
  }),
  computed: {
    dependents(): Array<Person> {
      return (
        this.kintin?.people.filter((person) =>
          [person.under18ForPlanningPurposes, person.requiresCare].includes(
            true,
          ),
        ) ?? []
      );
    },
    tabConfig(): Array<{ label: string; person?: Person; icon: any }> {
      if (!this.kintin) {
        return [];
      }

      return [
        ...this.kintin.people
          .filter((person) =>
            [person.under18ForPlanningPurposes, person.requiresCare].includes(
              true,
            ),
          )
          .map((person) => ({
            label: person.profile.fullName as string,
            person,
            icon: StyleService.icon.person.icon,
          })),
        {
          label: "People",
          icon: StyleService.icon.person.icon,
        },
      ];
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });
      const guardians = Guardian.$findBy({
        forPerson: {
          id:
            selectedPerson?.id ??
            In(
              [
                this.kintin.primaryPerson.id,
                this.kintin.secondaryPerson?.id,
              ].filter(Boolean),
            ),
        },
      });

      this.filtered.guardians = {
        main: guardians.filter((g) => !g.isReserve),
        reserve: guardians.filter((g) => g.isReserve),
      };

      this.filtered.guardians.main =
        this.filtered.guardians.main.sortBy("sortOrder");
      this.filtered.guardians.reserve =
        this.filtered.guardians.reserve.sortBy("sortOrder");

      this.filtered.people = Person.$findBy({
        kintin: {
          id: this.kintin?.id,
        },
      }).filter((person) => person.guardianFor.isNotEmpty());

      this.renderKey++;

      this.$emit("reload");
    },
    async createGuardian() {
      if (!this.kintin) {
        return;
      }

      const guardian = CreateGuardian({
        kintin: this.kintin.id,
      });

      try {
        await (
          await UpdateGuardianForm({
            data: guardian,
            kintin: this.kintin,
          })
        ).dialog({
          dialog: {
            title: "Create guardian",
          },
        });
      } catch {
        guardian.$delete();
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.guardian.create({
      //   guardian,
      //   kintin: this.kintin,
      // });
      await this.$actionBus.guardian.CreateGuardian(guardian);

      this.filter();
    },
    async togglingSorting() {
      if (this.sorting) {
        await [
          ...this.$data.filtered.guardians.main,
          ...this.$data.filtered.guardians.reserve,
        ].forEachAsync((guardian) =>
          // window.Kernel.ActionBus.kinvaultKintin.guardian.update({
          //   guardian,
          //   kintin: this.kintin,
          // }),
          this.$actionBus.guardian.UpdateGuardian(guardian),
        );
      }

      this.sorting = !this.sorting;
    },
    async copy() {
      const fromPerson = this.tabConfig[this.selectedTab].person;

      console.log(fromPerson);

      let name: string = "";

      if (fromPerson?.profile.fullName) {
        name = fromPerson?.profile.fullName;
      }

      const copyTo = await OpenAutocompleteDialog({
        dialog: {
          title: "Copy to",
        },
        list: {
          options: () => this.dependents.filter((p) => p.id !== fromPerson?.id),
          mapOptions: {
            label: "profile.fullName",
            value: "id",
          },
        },
      });

      const { rolesToCopy } = await CopyRolesForm(
        [
          ...this.$data.filtered.guardians.main,
          ...this.$data.filtered.guardians.reserve,
        ].filter((a) => a.forPerson.id === fromPerson?.id),
        this.kintin,
      ).dialog({
        dialog: {
          title: `Copy from ${name}`,
        },
      });

      await rolesToCopy.forEachAsync(async (guardian) => {
        const newGuardian = CreateGuardian({
          ...guardian.$data,
          forPerson: copyTo.id,
          kintin: this.kintin.id,
          id: Uuid.generate(),
        });

        // await window.Kernel.ActionBus.kinvaultKintin.guardian.create({
        //   guardian: newGuardian,
        //   kintin: this.kintin,
        // });
        await this.$actionBus.guardian.CreateGuardian(newGuardian);
      });
      this.filter();
    },
  },
});
</script>
