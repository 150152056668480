import { Api } from "@/service/api.service";

interface Message {
  file: File;
}

interface Response {
  firstNameColumnTitle: string | null;
  lastNameColumnTitle: string | null;
  emailColumnTitle: string | null;
  adviserEmailColumnTitle: string | null;
  referenceColumnTitle: string | null;
  referralCodeColumnTitle: string | null;
  duplicateEmails: string[];
}

export const ValidateMembersCsv = async (
  message: Message,
): Promise<Response> => {
  const { file } = message;

  return await Api.resource(
    "portal",
    "/v2/portal/admin/branded-kinvault/invitation/csv/validate",
  )
    .method("put")
    .files({
      uploadedFile: file,
    })
    .result();
};
