import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateKintinCheck } from "./create-kintin-check.action";
import { DeleteKintinCheck } from "./delete-kintin-check.action";
import { ReadKintinCheck } from "./read-kintin-check.action";
import { RecordKintinCheck } from "./record-kintin-check.action";
import { UpdateKintinCheck } from "./update-kintin-check.action";

export const Portal2KinvaultKintinCheck = DefineActionBus({
  name: "portal-kinvault-kintin-check",
  actions: {
    CreateKintinCheck,
    DeleteKintinCheck,
    ReadKintinCheck,
    UpdateKintinCheck,
    RecordKintinCheck,
  },
});
