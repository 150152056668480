// KnowledgeBaseCategory

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { KnowledgeBaseCategory } from "@kinherit/sdk/model/knowledge-base-category";

export const DeleteKnowledgeBaseCategory = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/knowledge-base/category/{knowledgeBaseCategory}",
  method: "delete",
  parse: (knowledgeBaseCategory: KnowledgeBaseCategory | string) => ({
    params: {
      knowledgeBaseCategory,
    },
  }),
  after: ({ message }) => {
    KnowledgeBaseCategory.$delete(message);
  },
});
