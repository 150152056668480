var i = Object.defineProperty;
var r = (e, o, n) => o in e ? i(e, o, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[o] = n;
var t = (e, o) => i(e, "name", { value: o, configurable: !0 });
var a = (e, o, n) => r(e, typeof o != "symbol" ? o + "" : o, n);
const d = class d {
  constructor(o) {
    a(this, "_mousePosition", { x: 0, y: 0 });
    /** @internal */
    a(this, "loadContextMenuComponent", /* @__PURE__ */ t(() => window.Kernel.Logger.log(
      "Global",
      "debug",
      "Context menu loader not set. Failed to load context menu."
    ), "loadContextMenuComponent"));
    /** @internal */
    a(this, "loadSnackBarComponent", /* @__PURE__ */ t(() => window.Kernel.Logger.log(
      "Global",
      "debug",
      "Snackbar loader not set. Failed to load snackbar."
    ), "loadSnackBarComponent"));
    /** @internal */
    a(this, "loadDialogComponent", /* @__PURE__ */ t(() => window.Kernel.Logger.log(
      "Global",
      "debug",
      "Dialog loader not set. Failed to load dialog."
    ), "loadDialogComponent"));
    /** @internal */
    a(this, "loadSidebarComponent", /* @__PURE__ */ t(() => window.Kernel.Logger.log(
      "Global",
      "debug",
      "Sidebar loader not set. Failed to load sidebar."
    ), "loadSidebarComponent"));
    /** @internal */
    a(this, "showLoadingComponent", /* @__PURE__ */ t(() => window.Kernel.Logger.log(
      "Global",
      "debug",
      "Loading loader not set. Failed to show loading component."
    ), "showLoadingComponent"));
    this.dialogTypes = o, window.addEventListener(
      "mousemove",
      (n) => {
        this._mousePosition = { x: n.clientX, y: n.clientY };
      },
      { passive: !0 }
    );
  }
  get mousePosition() {
    return this._mousePosition;
  }
  /** @internal */
  setPragmaticContextMenuComponentLoader(o) {
    this.loadContextMenuComponent = o;
  }
  /** @internal */
  setPragmaticSnackBarComponentLoader(o) {
    this.loadSnackBarComponent = o;
  }
  /** @internal */
  setPragmaticDialogComponentLoader(o) {
    this.loadDialogComponent = o;
  }
  /** @internal */
  setPragmaticSidebarComponentLoader(o) {
    this.loadSidebarComponent = o;
  }
  /** @internal */
  setPragmaticLoadingComponentLoader(o) {
    this.showLoadingComponent = o;
  }
  // /** @internal */
  // private appContainerMethods!: AppContainerMethods;
  // /** @internal */
  // private setAppContainerMethods(methods: AppContainerMethods): void {
  //   this.appContainerMethods = methods;
  // }
};
t(d, "Global");
let l = d;
export {
  l as Global
};
