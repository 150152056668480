var l = Object.defineProperty;
var i = (t, e) => l(t, "name", { value: e, configurable: !0 });
import { FieldMixin as m } from "../../component.mixin/field-mixin.js";
import { CypressHelper as n } from "../../cypress/component-helpers.js";
import { DateTime as o } from "@kinherit/ts-common/dto/date-time";
import { Time as u } from "@kinherit/ts-common/dto/time";
import { defineComponent as s } from "vue";
import a from "../../_vue/component.input/base-input-field/base-input-field.js";
const v = s({
  name: "TimeFieldComponent",
  components: { BaseInputField: a },
  mixins: [m()],
  props: {
    /**
     * Show clear button
     */
    showClearNowButton: {
      type: Boolean,
      default: !0
    },
    value: {
      type: Object,
      default: null
    },
    /**
     * Minimum time
     */
    min: {
      type: Object,
      default: null,
      value: "time"
    },
    /**
     * Maximum time
     */
    max: {
      type: Object,
      default: null,
      value: "time"
    },
    /**
     * Intervals between allowed times (seconds)
     */
    step: {
      type: Object,
      default: null
    }
  },
  emits: ["update:value", "blur"],
  data: /* @__PURE__ */ i((t) => ({
    $cypress: n({ ctx: t, labelField: "label" })
  }), "data"),
  computed: {
    computedValue: {
      get() {
        const t = this.value;
        return t === null ? null : t.toString();
      },
      set(t) {
        let e = t === null ? null : u.fromString(t);
        if (e === null) {
          this.$emit("update:value", e), this.validate(e);
          return;
        }
        this.min && e.isBefore(this.min) && (e = this.min), this.max && e.isAfter(this.max) && (e = this.max), this.$emit("update:value", e), this.validate(e);
      }
    },
    computedMin() {
      const t = this.min;
      return t === null ? null : t.toString();
    },
    computedMax() {
      const t = this.max;
      return t === null ? null : t.toString();
    },
    computedStep() {
      const t = this.step;
      return t === null ? 60 : t.toMilliseconds() / 1e3;
    }
  },
  methods: {
    setNow() {
      this.computedValue = new o().formatTime;
    }
  }
});
export {
  v as _
};
