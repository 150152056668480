var V = Object.defineProperty;
var l = (o, i) => V(o, "name", { value: i, configurable: !0 });
import { _ as B } from "../../../component.form/form/form.vue_vue_type_script_lang.js";
import { resolveComponent as s, resolveDirective as E, createElementBlock as b, openBlock as t, mergeProps as F, withModifiers as M, withDirectives as g, createCommentVNode as k, Fragment as S, renderList as I, createBlock as a, withCtx as J, createVNode as N } from "vue";
import { _ as U } from "../../../_plugins/_plugin-vue_export-helper.js";
const h = ["cy-label", "cy-component", "cy-ref"];
function K(o, i, L, O, P, T) {
  var p, m;
  const w = s("FormInner"), C = s("FormArea"), r = s("Json"), d = E("tool-tip");
  return t(), b("form", F({
    key: o.renderKey,
    class: o.classes
  }, (m = (p = o.config) == null ? void 0 : p.options) == null ? void 0 : m.element, {
    "cy-label": o.$data.$cypress["cy-label"],
    "cy-component": o.$data.$cypress["cy-component"],
    "cy-ref": o.$data.$cypress["cy-ref"],
    onSubmit: i[3] || (i[3] = M(() => {
    }, ["prevent"]))
  }), [
    (t(!0), b(S, null, I(o.filteredFormAreas, (e, D) => {
      var u, c, f, y, v, $;
      return t(), a(C, F({ ref_for: !0 }, (u = e == null ? void 0 : e.options) == null ? void 0 : u.element, {
        key: `fom-area-${D}`,
        "form-area-name": e.name,
        collapsed: (c = e.options) == null ? void 0 : c.collapsed,
        label: (f = e.options) == null ? void 0 : f.label,
        description: (y = e.options) == null ? void 0 : y.description,
        direction: ((v = e.options) == null ? void 0 : v.direction) ?? "is-vertical",
        "is-collapsible": o.castCallbackToValue(($ = e.options) == null ? void 0 : $.isCollapsible),
        "onUpdate:collapsed": /* @__PURE__ */ l((n) => e.options ? e.options.collapsed = n : e.options = { collapsed: n }, "onUpdate:collapsed")
      }), {
        default: J(() => [
          N(w, {
            form: o.config,
            "form-area": e,
            components: o.components[e.name],
            layout: e.template,
            "form-data": o.config.localData,
            uuid: o.uuid,
            "show-validation": o.showValidation || o.showValidationOverride,
            "is-dialog": o.isDialog,
            onFieldBlur: i[0] || (i[0] = (n) => o.$emit("field-blur")),
            onFieldFocus: i[1] || (i[1] = (n) => o.$emit("field-focus")),
            onFieldClear: i[2] || (i[2] = (n) => o.$emit("field-clear")),
            onSubmit: o.submit
          }, null, 8, ["form", "form-area", "components", "layout", "form-data", "uuid", "show-validation", "is-dialog", "onSubmit"])
        ]),
        _: 2
      }, 1040, ["form-area-name", "collapsed", "label", "description", "direction", "is-collapsible", "onUpdate:collapsed"]);
    }), 128)),
    o.devMode ? g((t(), a(r, {
      key: 0,
      title: "Form Data",
      data: o.config.localData
    }, null, 8, ["data"])), [
      [d, o.tooltip.devmode, "is-info:is-large"]
    ]) : k("", !0),
    o.devMode ? g((t(), a(r, {
      key: 1,
      title: "Form Errors",
      data: o.formErrors
    }, null, 8, ["data"])), [
      [d, o.tooltip.devmode, "is-info:is-large"]
    ]) : k("", !0)
  ], 16, h);
}
l(K, "_sfc_render");
const H = /* @__PURE__ */ U(B, [["render", K]]);
export {
  H as default
};
