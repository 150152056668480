import { defineComponent as e } from "vue";
const l = e({
  name: "LevelComponent",
  props: {
    isMobile: {
      type: Boolean,
      default: !1
    }
  },
  computed: {
    leftSlots() {
      return Object.keys(this.$slots).filter((t) => t.startsWith("left"));
    },
    rightSlots() {
      return Object.keys(this.$slots).filter((t) => t.startsWith("right"));
    },
    middleSlots() {
      return Object.keys(this.$slots).filter(
        (t) => ![...this.leftSlots, ...this.rightSlots].includes(t)
      );
    }
  }
});
export {
  l as _
};
