// CompanyPartnerPortalPermissions

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { CompanyPartnerPortalPermissions } from "@kinherit/sdk/model/company-partner-portal-permissions";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const ReadIntroducerCompanyPartnerPortal = DefineReadAction({
  interface: "portal",
  resource:
    "/v2/portal/introducer/company/partner-portal-permissions/{introducerCompany}",
  method: "get",
  rootModel: CompanyPartnerPortalPermissions,
  parse: (introducerCompany: IntroducerCompany | string) => ({
    params: {
      introducerCompany,
    },
  }),
});
