import {
  AccountReferralCodeField,
  IntroducerContactField,
  UserField,
} from "@/config/form.config";
import { CreateAccountReferral, CreateLead } from "@/config/model.config";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormPhoneNumberField } from "@kinherit/framework/component.input/phone-number-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In } from "@kinherit/orm";
import {
  AccountReferralCode,
  EmailAddress,
  IntroducerContact,
  Lead,
  PhoneNumber,
} from "@kinherit/sdk";
import { DashLoader, DateTime, Uuid } from "@kinherit/ts-common";
import { reactive } from "vue";

export const CreateLeadForm = (lead: Lead = CreateLead({})) => {
  const localData = reactive({
    referralCode: null as null | AccountReferralCode,
  });
  return defineForm({
    name: "create-lead-form",
    data: () => lead,
    formAreas: (data) => [
      defineFormArea({
        name: "create-lead-form-area",
        data,
        template: GridLayout([
          ["subtitle1"],
          ["firstName", "lastName"],
          ["email", "phone"],
          ["subtitle3"],
          ["referralCode", "introducerContact"],
          ["externalId"],
          ["subtitle2"],
          ["ownedBy", "assignedTo"],
          ["notes"],
        ]),
        components: () => ({
          subtitle1: [
            FormTitle({
              props: {
                text: "Lead Details",
                class: "mt-3",
                size: "is-5",
                isEmphasised: true,
              },
            }),
            FormTitle({
              props: {
                text: "If more than one person, create first then add second profile",
                class: "mt-3",
                size: "is-6",
                isEmphasised: false,
              },
            }),
          ],
          firstName: [
            FormTextField({
              props: {
                label: "First Name",
                validators: ["required"],
                reference: "firstName",
              },
              models: {
                value: "primaryProfile.firstName",
              },
            }),
          ],
          lastName: [
            FormTextField({
              props: {
                label: "Last Name",
                validators: ["required"],
                reference: "lastName",
              },
              models: {
                value: "primaryProfile.lastName",
              },
            }),
          ],
          email: [
            FormEmailField({
              reactive: true,
              props: {
                label: "Email",
                validators: ["required"],
                showGenerateButton: true,
                generatedPrefix: (data) => data.primaryProfile.lastName?.trim(),
                reference: "email",
              },
              models: {
                value: {
                  get: (data) =>
                    data.primaryProfile.emails.first()?.email ?? "",
                  set: (value, data) => {
                    const email = data.primaryProfile.emails.first();
                    if (["", null, undefined].includes(value)) {
                      if (email) {
                        data.primaryProfile.removeEmails(email);
                        email.$delete();
                      }
                    } else if (email) {
                      email.email = value;
                      email.$persist();
                    } else {
                      data.primaryProfile.addEmails(
                        new EmailAddress({
                          id: Uuid.generate(),
                          email: value,
                          primary: true,
                          createdAt: new DateTime().formatMachine,
                        }).$persist(),
                      );
                      data.primaryProfile.$persist();
                    }
                  },
                },
              },
            }),
          ],
          phone: [
            FormPhoneNumberField({
              reactive: true,
              props: {
                label: "Phone",
                reference: "phone",
              },
              models: {
                value: {
                  get: (data) =>
                    data.primaryProfile.phoneNumbers.first()?.tel ?? "",
                  set: (value, data) => {
                    const phone = data.primaryProfile.phoneNumbers.first();

                    if (["", null, undefined].includes(value)) {
                      if (phone) {
                        data.primaryProfile.removePhoneNumbers(phone);
                        phone.$delete();
                      }
                    } else if (phone) {
                      phone.tel = value;
                      phone.$persist();
                    } else {
                      data.primaryProfile.addPhoneNumbers(
                        new PhoneNumber({
                          id: Uuid.generate(),
                          tel: value,
                          primary: true,
                          createdAt: new DateTime().formatMachine,
                        }).$persist(),
                      );
                      data.primaryProfile.$persist();
                    }
                  },
                },
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                placeholder: "Lead Description if appropriate",
                reference: "notes",
                isTextArea: true,
              },
              models: {
                value: "description",
              },
            }),
          ],
          subtitle2: [
            FormTitle({
              props: {
                text: "Assignments",
                class: "mt-3",
                size: "is-5",
                isEmphasised: true,
              },
            }),
          ],
          ownedBy: [
            UserField({
              props: {
                label: "Estate Planner",
                message: () =>
                  localData.referralCode?.company?.defaultEstatePlanner
                    ? `i.e Estate Planner, Recommended: ${localData.referralCode?.company?.defaultEstatePlanner?.profile.fullName}`
                    : "i.e Estate Planner",
                vModel: "ownedBy",
                reference: "ownedBy",
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
            }),
          ],
          assignedTo: [
            UserField({
              props: {
                vModel: "assignedTo",
                label: "Legal Assistant",
                message: () =>
                  localData.referralCode?.company?.defaultLegalAssistant
                    ? `Who's dealing with this lead?, Recommended: ${localData.referralCode?.company?.defaultLegalAssistant?.profile.fullName}`
                    : "Who's dealing with this lead?",
                reference: "assignedTo",
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
            }),
          ],
          subtitle3: [
            FormTitle({
              props: {
                text: "Referral",
                size: "is-5",
                class: "mt-3",
                isEmphasised: true,
              },
            }),
          ],
          referralCode: [
            AccountReferralCodeField({
              props: {
                size: "is-normal",
                reference: "referralCode",
                message:
                  "Main Cost Code: Use <b>Organic</b> when existing user referral",
                vModel: {
                  get: (data) => data.referral?.referralCode,
                  set: async (
                    value: null | AccountReferralCode,
                    data,
                    controls,
                  ) => {
                    if (value && !data.referral) {
                      data.referral = CreateAccountReferral({
                        id: Uuid.generate(),
                        contact: null as any,
                        referralCode: value.id,
                        createdAt: new DateTime().formatMachine,
                      });
                      // .$persist();
                    }

                    if (data.referral) {
                      data.referral.referralCode = value as AccountReferralCode;
                    }

                    localData.referralCode = value;

                    controls.rebuildForm();

                    const { defaultEstatePlanner, defaultLegalAssistant } =
                      value?.company?.$data ?? {};

                    if (defaultEstatePlanner || defaultLegalAssistant) {
                      await window.Kernel.ActionBus2.core.select.ReadUser(
                        {
                          query: {
                            id: In(
                              [
                                defaultEstatePlanner,
                                defaultLegalAssistant,
                              ].filter(Boolean),
                            ),
                          },
                        },
                        {
                          hideLoading: true,
                        },
                      );

                      controls.rebuildForm();
                    }
                  },
                },
              },
              query: {},
            }),
          ],
          introducerContact: [
            IntroducerContactField({
              props: {
                size: "is-normal",
                reference: "contact",
                vModel: {
                  get: (data) => data.referral?.contact,
                  set: (value: null | IntroducerContact, data, controls) => {
                    DashLoader.set(data, ["referral", "contact"], value);

                    controls.rebuildForm();
                  },
                },
              },
              query: {
                companies: {
                  referralCodes: {
                    id: Equal(localData.referralCode?.id),
                  },
                },
              },
            }),
          ],
          externalId: [
            FormTextField({
              props: {
                label: "External ID",
                message: "Used for SortRefer etc",
              },
              models: {
                value: "referral.externalId",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
