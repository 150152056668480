import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Portal2IntroducerBillingContacts } from "./billing-contacts";
import { Portal2IntroducerCompany } from "./company";
import { Portal2IntroducerContact } from "./contact";
import { Portal2IntroducerContract } from "./contract";
import { Portal2IntroducerFees } from "./fees";
import { Portal2IntroducerNetworks } from "./networks";
import { Portal2IntroducerOutsources } from "./outsources";

export const Portal2Introducer = DefineActionBus({
  name: "portal-introducer",
  actions: {
    billingContacts: Portal2IntroducerBillingContacts,
    company: Portal2IntroducerCompany,
    contact: Portal2IntroducerContact,
    contract: Portal2IntroducerContract,
    fees: Portal2IntroducerFees,
    networks: Portal2IntroducerNetworks,
    outsources: Portal2IntroducerOutsources,
  },
});
