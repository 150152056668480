import { defineComponent as t } from "vue";
import { S as o } from "../../_assets/component.layout/sidebar/sidebar.module.js";
const i = t({
  name: "SidebarComponent",
  props: {
    show: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["update:show"],
  computed: {
    computedShow: {
      get() {
        return this.show;
      },
      set(e) {
        this.$emit("update:show", e);
      }
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("sidebar", o);
  }
});
export {
  i as _
};
