<template>
  <SidebarPage title="Logs">
    <template #sidebar>
      <AdminLogSidebar />
    </template>

    <template #default>
      <RouterChildView />
    </template>
  </SidebarPage>
</template>

<cypress-wrapper lang="json">
{
  "name": "AdminLogIndexWrapper",
  "route": "AdminLogIndex",
  "imports": {
    "AdminLogSidebarWrapper": "@/module/admin.log/page/AdminLog.Sidebar.test",
    "EmailLogMasterListWrapper": "@/module/admin.log/page/EmailLogMasterList.test",
    "FailedLoginAttemptsMasterListWrapper": "@/module/admin.log/page/FailedLoginAttemptsMasterList.test",
    "NotificationLogMasterListWrapper": "@/module/admin.log/page/NotificationLogMasterList.test"
  },
  "methods": {
    "sidebar": {
      "type": "to-one",
      "wrapper": "AdminLogSidebarWrapper"
    },
    "emailLog": {
      "type": "to-one",
      "wrapper": "EmailLogMasterListWrapper"
    },
    "failedLoginAttempts": {
      "type": "to-one",
      "wrapper": "FailedLoginAttemptsMasterListWrapper"
    },
    "notificationLog": {
      "type": "to-one",
      "wrapper": "NotificationLogMasterListWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { AdminLogIndexRoute } from ".";
import AdminLogSidebar from "./AdminLog.Sidebar.vue";

export default defineComponent({
  name: AdminLogIndexRoute,
  components: {
    AdminLogSidebar,
    SidebarPage,
    RouterChildView,
  },
});
</script>
