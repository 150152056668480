var e = Object.defineProperty;
var l = (a, t, r) => t in a ? e(a, t, { enumerable: !0, configurable: !0, writable: !0, value: r }) : a[t] = r;
var i = (a, t) => e(a, "name", { value: t, configurable: !0 });
var s = (a, t, r) => l(a, typeof t != "symbol" ? t + "" : t, r);
import { ValidationType as o } from "../core/validation-type.js";
const u = "required", c = /* @__PURE__ */ i((a = o.Error) => {
  var t;
  return t = class {
    constructor(r) {
      this.data = r;
    }
    getResult() {
      const r = typeof this.data.value == "string" ? this.data.value.replace(/ /g, "") : this.data.value;
      return ["", null, void 0].includes(r) || Array.isArray(r) && r.isEmpty() ? {
        message: "This field is required",
        type: a
      } : null;
    }
  }, s(t, "validatorName", u), t;
}, "RequiredValidatorConstructor");
export {
  c as RequiredValidatorConstructor,
  u as RequiredValidatorName
};
