var j = Object.defineProperty;
var t = (r, s) => j(r, "name", { value: s, configurable: !0 });
import { FormSearch as d } from "../../component.layout/search/index.js";
import { defineForm as A, defineFormArea as S } from "../../form-builder/define-form.js";
import { reactive as g } from "vue";
import { ColumnLayout as h } from "../../component.layout/dynamic-layout/layout.js";
const b = /* @__PURE__ */ t(({
  name: r,
  data: s,
  props: a,
  updateUrlQuery: f
}) => ({
  hideReset: F,
  hideSearch: o,
  basicComponents: i,
  advancedComponents: e
}) => A({
  name: `${r}-form`,
  props: a,
  data: /* @__PURE__ */ t(() => s(), "data"),
  formAreas: /* @__PURE__ */ t((u) => {
    const m = i == null ? void 0 : i(u), v = e == null ? void 0 : e(u);
    function n() {
      const l = {
        default: Object.values(m ?? {}).flat()
      };
      return v && (l["advanced-filters"] = Object.values(
        v
      ).flat()), l;
    }
    t(n, "getSlots");
    const O = g({
      advFiltersOpen: !1
    });
    return [
      S({
        name: `${r}-basic-form-area`,
        data: u,
        template: h(["search-slot"]),
        components: /* @__PURE__ */ t(() => ({
          "search-slot": [
            d({
              props: {
                hideReset: F,
                hideSearch: o,
                reference: "filter"
              },
              slots: n(),
              emits: {
                submit: /* @__PURE__ */ t((l, p, c) => {
                  f ? c.updateFormState() : c.emit("submit");
                }, "submit"),
                reset: /* @__PURE__ */ t((l, p, c) => {
                  c.emit("reset");
                }, "reset")
              },
              models: {
                "adv-filters-open": {
                  get: /* @__PURE__ */ t(() => O.advFiltersOpen, "get"),
                  set: /* @__PURE__ */ t((l) => {
                    O.advFiltersOpen = l;
                  }, "set")
                }
              }
            })
          ]
        }), "components")
      })
    ];
  }, "formAreas")
}), "MasterListFiltersForm"), x = /* @__PURE__ */ t(({
  hideReset: r,
  hideSearch: s,
  basicComponents: a,
  advancedComponents: f
}) => {
  const F = a == null ? void 0 : a(), o = f == null ? void 0 : f();
  function i() {
    const e = {
      default: Object.values(F ?? {}).flat()
    };
    return o && (e["advanced-filters"] = Object.values(
      o
    ).flat()), e;
  }
  return t(i, "getSlots"), d({
    props: {
      hideReset: r,
      hideSearch: s,
      size: "is-normal"
    },
    slots: i(),
    emits: {
      submit: /* @__PURE__ */ t((e, u, m) => {
        m.emit("submit");
      }, "submit"),
      reset: /* @__PURE__ */ t((e, u, m) => {
        m.emit("reset");
      }, "reset")
    }
  });
}, "MasterListFiltersComponent");
export {
  x as MasterListFiltersComponent,
  b as MasterListFiltersForm
};
