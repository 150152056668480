var i = Object.defineProperty;
var e = (o, t) => i(o, "name", { value: t, configurable: !0 });
import s from "../../_vue/component.display/icon/icon.js";
import { ThemeSize as n } from "../../theme/prop/size.js";
import { defineComponent as l } from "vue";
const a = l({
  components: { Icon: s },
  props: {
    /**
     * Navbar item label
     */
    label: {
      type: String,
      default: ""
    },
    /**
     * Callback function execute when the navbar item is clicked
     */
    click: {
      type: Function,
      default: null
    },
    /**
     * Sub menu for nesting navbar items inside of an item
     */
    menu: {
      type: Array,
      default: null
    },
    /**
     * If true, the menu will always be open on mobile
     */
    mobileFixedOpen: {
      type: Boolean,
      default: !1
    },
    /**
     * Name of the preconfigured icon you want to use
     */
    icon: {
      type: [String, Object],
      default: null
    },
    /**
     * If true, the navbar item will only show the icon
     */
    iconOnly: {
      type: Boolean,
      default: !1
    },
    /**
     * Name of the predefined size to scale the component with
     */
    iconSize: {
      type: String,
      default: "is-normal",
      validator: /* @__PURE__ */ e((o) => Object.values(n).includes(o), "validator")
    },
    isMobile: {
      type: Boolean,
      default: !1
    },
    /**
     * If true, the navbar item will not have an arrow
     */
    isArrowless: {
      type: Boolean,
      default: !1
    },
    /**
     * If true, the navbar item will show the dropdown menu
     */
    showDropdown: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["click", "update:showDropdown"],
  data: /* @__PURE__ */ e(() => ({
    focusIndex: 0,
    timeout: null
  }), "data"),
  computed: {
    /**
     * If true, the navbar item will show the dropdown menu
     */
    compShowDropdown: {
      get() {
        return this.showDropdown;
      },
      set(o) {
        this.$emit("update:showDropdown", o);
      }
    }
  },
  watch: {
    showDropdown(o) {
      o ? this.positionDropdown() : this.focusIndex = 0;
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    clickWrapper(o, t) {
      typeof t == "function" ? t(o) : typeof t == "object" && typeof t.click == "function" ? t.click(o) : this.click(o), this.$emit("click", o), this.compShowDropdown = !1;
    },
    async positionDropdown() {
      await this.$nextTick();
      const o = this.$refs.refDropDown, t = o.clientWidth;
      o.style.left = `calc(50% - ${t / 2}px)`, await this.$nextTick(), o.getBoundingClientRect().right > window.innerWidth && (o.style.left = `calc(100% - ${t}px)`), this.isMobile && (o.style.height = `${o.scrollHeight}px`);
    },
    setFocusIndex(o, t) {
      t && t.key === "ArrowDown" ? this.focusIndex = Math.min(o + 1, this.menu.length - 1) : t && t.key === "ArrowUp" && (this.focusIndex = Math.max(o - 1, -1)), this.focusIndex > -1 ? this.$refs.refDropDownItem[this.focusIndex].focus() : (this.$refs.refTopLevelButton.focus(), this.focusIndex = 0);
    },
    async toggleMenu(o, t) {
      o === null && (o = !this.compShowDropdown), clearTimeout(this.timeout), this.timeout = setTimeout(
        async () => {
          this.compShowDropdown !== o && (this.compShowDropdown = o);
        },
        t ? 1e3 : 0
      );
    }
  }
});
export {
  a as _
};
