import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KinvaultSubscriptionReferralCode } from "@kinherit/sdk/model/kinvault-subscription-referral-code";

export const CreateKinvaultSubscriptionReferralCode = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/kinvaults/subscription-referral-codes",
  method: "post",
  parse: (message: KinvaultSubscriptionReferralCode) => ({
    body: {
      code: message.$data.code,
      coupon: message.$data.coupon,
      price: message.$data.price,
      introducerCompany: message.$data.introducerCompany,
    },
  }),
  after: ({ message }) => {
    KinvaultSubscriptionReferralCode.$delete(message);
  },
});
