var w = Object.defineProperty;
var T = (n, t, e) => t in n ? w(n, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : n[t] = e;
var k = (n, t) => w(n, "name", { value: t, configurable: !0 });
var a = (n, t, e) => T(n, typeof t != "symbol" ? t + "" : t, e);
import { DashLoader as S } from "@kinherit/ts-common/service/dash-loader";
import { markRaw as $, toRaw as x } from "vue";
const b = class b {
  constructor({
    component: t,
    props: e,
    emits: c,
    models: o,
    slots: s,
    directives: v,
    reactive: f
  }) {
    a(this, "internalSettings", {
      hideOverride: !1,
      reactive: !1
    });
    a(this, "emits");
    a(this, "props");
    a(this, "models");
    a(this, "slots");
    a(this, "directives");
    a(this, "component");
    var r, p;
    this.props = e, this.emits = c, this.models = o, this.slots = s, this.directives = v, this.component = $(t), this.internalSettings.reactive = f ?? this.internalSettings.reactive, !(!this.props || !this.models) && "value" in this.models && typeof ((r = this.models) == null ? void 0 : r.value) == "string" && ((p = this.props) == null ? void 0 : p.reference) === void 0 && (this.props.reference = this.models.value);
  }
  bindings(t, e, c, o) {
    var p;
    const s = {
      ...(e == null ? void 0 : e.props) ?? {}
    }, v = this.props ?? {};
    for (const l in v) {
      const i = v[l];
      typeof i == "function" ? s[l] = i(t ?? e.localData, c) : i !== void 0 && (s[l] = i);
    }
    const f = this.emits ?? {};
    for (const l in f) {
      const i = f[l];
      s[`on${l.ucFirst()}`] = (h) => {
        try {
          i == null || i(h, t ?? e.localData, {
            ...e.controls,
            ...c
          });
        } catch (D) {
          console.log(D);
        }
      };
    }
    const r = this.models ?? {};
    for (const l in this.models) {
      const i = r[l];
      if (i != null) {
        if (typeof i != "string") {
          const { get: h, set: D } = i;
          try {
            s[l] = h(t ?? e.localData, {
              ...e.controls,
              ...c
            });
          } catch (g) {
            console.log(g);
          }
          s[`onUpdate:${l}`] = (g) => {
            try {
              const y = D(g, t ?? e.localData, {
                ...e.controls,
                ...c
              });
              y instanceof Promise ? y.then(() => {
                e.controls.emit(
                  "update:data",
                  t ?? e.localData
                ), this.internalSettings.reactive ? c.rebuildTemplateBindings(0) : o == null || o();
              }) : (e.controls.emit(
                "update:data",
                t ?? e.localData
              ), this.internalSettings.reactive ? c.rebuildTemplateBindings(0) : o == null || o());
            } catch (y) {
              console.log(y);
            }
          };
          continue;
        }
        s[l] = S.get(
          t ?? e.localData,
          i.split(".")
        ), s[`onUpdate:${l}`] = (h) => {
          S.set(
            t ?? e.localData,
            i.split("."),
            h
          ), e.controls.emit("update:data", t ?? e.localData), this.internalSettings.reactive ? c.rebuildTemplateBindings(0) : o == null || o();
        };
      }
    }
    return typeof ((p = this.props) == null ? void 0 : p.reference) == "string" ? s.reference = this.props.reference : "value" in r && typeof r.value == "string" && (s.reference = r.value), e.options.state !== void 0 && (s.state = e.options.state), s.key === void 0 && (s.key = s.reference), s;
  }
  getValue(t, e, c) {
    const { value: o } = this.models;
    let s;
    return typeof o == "string" ? s = S.get(t, o.split(".")) : o !== void 0 && (s = o.get(t, {
      ...e.controls,
      ...c
    })), s ?? null;
  }
};
k(b, "FormComponent");
let m = b;
const M = /* @__PURE__ */ k((n) => (t) => new class extends m {
}({
  component: x(n),
  ...t
}), "FormComponentWrapper");
export {
  m as FormComponent,
  M as FormComponentWrapper
};
