var u = Object.defineProperty;
var r = (e, t) => u(e, "name", { value: t, configurable: !0 });
import o from "../../_vue/component.display/icon/icon.js";
import m from "../../_vue/component.form/field/field.js";
import d from "../../_vue/component.input/number-field/number-field.js";
import { FieldMixin as h } from "../../component.mixin/field-mixin.js";
import { TranslationMixin as y } from "../../component.mixin/translation.mixin.js";
import { CypressHelper as p } from "../../cypress/component-helpers.js";
import { DateTime as i } from "@kinherit/ts-common/dto/date-time";
import { defineComponent as c } from "vue";
import { S as f } from "../../_assets/component.input/select-field/select-field.module.js";
import { S as b } from "../../_assets/component.input/date-of-birth-field/date-of-birth-field.module.js";
const I = c({
  name: "DateOfBirthFieldComponent",
  components: { Icon: o, Field: m, NumberField: d },
  mixins: [
    h(),
    y({
      defaults: {
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ]
      }
    })
  ],
  props: {
    /**
     * Option to hide the fields built in clear button
     */
    showClearButton: {
      type: Boolean,
      default: !0
    }
  },
  emits: {
    /**
     * Emits the new value
     */
    "update:value": null,
    /**
     * Emits when the input field is blurred
     */
    blur: null,
    /**
     * Emits when the input field is cleared
     */
    clear: /* @__PURE__ */ r(() => !0, "clear")
  },
  data: /* @__PURE__ */ r((e) => ({
    calendar: [
      { number: "01", days: "31" },
      { number: "02", days: "29" },
      { number: "03", days: "31" },
      { number: "04", days: "30" },
      { number: "05", days: "31" },
      { number: "06", days: "30" },
      { number: "07", days: "31" },
      { number: "08", days: "31" },
      { number: "09", days: "30" },
      { number: "10", days: "31" },
      { number: "11", days: "30" },
      { number: "12", days: "31" }
    ],
    currentYear: new i().getDate().getFullYear(),
    dayMax: 31,
    $cypress: p({ ctx: e, labelField: "label" }),
    values: {
      year: null,
      month: null,
      day: null
    }
  }), "data"),
  computed: {
    iconRightClass() {
      return this.fieldsNotEmpty ? "has-icon-right" : "";
    },
    computedDay: {
      get() {
        return this.values.day ? Number.parseInt(this.values.day) : null;
      },
      set(e) {
        this.values.day = e, this.emitValue();
      }
    },
    computedMonth: {
      get() {
        return this.values.month ?? "";
      },
      set(e) {
        this.values.month = e;
        let { day: t } = this.values;
        t && (e !== "" ? (this.dayMax = Number.parseInt(
          this.calendar[Number.parseInt(e) - 1].days
        ), t !== "" && Number.parseInt(t) > this.dayMax && (t = `${this.dayMax}`)) : this.dayMax = 31, this.emitValue());
      }
    },
    computedYear: {
      get() {
        return this.values.year ? Number.parseInt(this.values.year) : null;
      },
      set(e) {
        this.values.year = e, this.emitValue();
      }
    },
    partControlClasses() {
      return this.controlClasses.replace("has-icons-right", "").replace("has-icons-left", "");
    },
    fieldsNotEmpty() {
      return [this.values.year, this.values.month, this.values.day].some(
        (e) => e !== null && e !== ""
      );
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("date-of-birth-field", b), window.Kernel.injectStylesheet("select-field", f);
  },
  mounted() {
    const { value: e } = this.$props;
    if (e === null) {
      this.values = {
        year: null,
        month: null,
        day: null
      };
      return;
    }
    const t = e.getDate();
    this.values = {
      year: t.getFullYear().toString(),
      month: (t.getMonth() + 1).toString().padStart(2, "0"),
      day: t.getDate().toString().padStart(2, "0")
    };
  },
  methods: {
    emitValue() {
      var s, l;
      let { month: e, day: t } = this.values;
      const { year: a } = this.values;
      if ([a, e, t].intersection([null, "", "0", "00"]).isNotEmpty()) {
        this.$emit("update:value", null), this.$nextTick(() => this.$forceUpdate());
        return;
      }
      e = ((s = e == null ? void 0 : e.toString()) == null ? void 0 : s.length) === 1 ? `0${e}` : e, t = ((l = t == null ? void 0 : t.toString()) == null ? void 0 : l.length) === 1 ? `0${t}` : t;
      try {
        const n = i.fromString(`${a}-${e}-${t}`);
        if (n.formatYMD !== `${a}-${e}-${t}`)
          throw new Error("Incomplete date");
        this.$emit("update:value", n);
      } catch {
        this.$emit("update:value", null);
      }
      this.$nextTick(() => this.$forceUpdate());
    },
    clear() {
      this.computedValue = null, this.values = {
        year: null,
        month: null,
        day: null
      }, this.$emit("clear");
    }
  }
});
export {
  I as _
};
