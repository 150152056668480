import { AuthService } from "@/service/auth.service";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { Profile, User } from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";
import { AdminUserDetailsParams, AdminUserDetailsRoute } from "../page/details";

export const UserDetailsMixin = defineComponent({
  name: "UserDetailsMixin",
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.admin.user)],
  props: {
    userId: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  computed: {
    $params(): AdminUserDetailsParams {
      const params = this.$route.params as AdminUserDetailsParams;
      return {
        ...params,
        user: this.userId ?? params.user,
      };
    },
    user(): User | null {
      return User.$findOne(this.$params.user);
    },
    profile(): Profile | null {
      return this.user?.profile ?? null;
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("user:write");
    },
  },
  methods: {
    visitUserDetails(): void {
      window.Kernel.Router.push({
        name: AdminUserDetailsRoute,
        params: { user: this.user?.id },
      });
    },
  },
});
