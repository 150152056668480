var n = Object.defineProperty;
var i = (e, t) => n(e, "name", { value: t, configurable: !0 });
import { _ as p } from "../../../component.display/badge/badge.vue_vue_type_script_lang.js";
import { resolveComponent as c, resolveDirective as d, withDirectives as m, createElementBlock as r, openBlock as o, normalizeClass as u, createBlock as f, createCommentVNode as l, renderSlot as h, toDisplayString as k } from "vue";
import { _ } from "../../../_plugins/_plugin-vue_export-helper.js";
const $ = ["aria-label"], b = { key: 2 };
function v(e, t, B, g, y, z) {
  const s = c("Icon"), a = d("tool-tip");
  return m((o(), r("span", {
    class: u(["badge", e.compBadgeClasses]),
    "aria-label": e.ariaLabel ?? e.tooltip
  }, [
    e.icon !== null ? (o(), f(s, {
      key: 0,
      size: e.size,
      icon: e.icon,
      "inherit-color": e.color !== "is-plain"
    }, null, 8, ["size", "icon", "inherit-color"])) : l("", !0),
    e.$slots.default ? h(e.$slots, "default", { key: 1 }) : e.text !== null ? (o(), r("span", b, k(e.text), 1)) : l("", !0)
  ], 10, $)), [
    [a, e.tooltip ?? e.ariaLabel]
  ]);
}
i(v, "_sfc_render");
const S = /* @__PURE__ */ _(p, [["render", v]]);
export {
  S as default
};
