<template>
  <DataListWidget v-if="order" title="About" :details="details">
    <template #below>
      <hr />
      <div class="has-text-right has-text-grey">
        Created {{ order.createdAt.formatDate }}
        <span v-if="order.$data.createdBy">
          by
          <span
            class="has-text-link is-clickable is-block"
            @click="viewCreatedBy"
          >
            {{ order.createdBy?.profile.fullName }}
          </span>
        </span>
      </div>
    </template>
  </DataListWidget>
</template>

<cypress-wrapper lang="json">
{
  "name": "OrderDetailsSidebarWrapper",
  "imports": {
    "DataListWidgetWrapper": "@/shared/component/widget/DataListWidget.test"
  },
  "methods": {
    "about": {
      "type": "to-one",
      "selector": "",
      "wrapper": "DataListWidgetWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import {
  AdminUserDetailsParams,
  AdminUserDetailsRoute,
} from "@/module/admin.user/page/details";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import { OrderDetailsMixin } from "../../mixin/order-details.mixin";

export default defineComponent({
  name: "OrderDetailsSidebar",
  components: { DataListWidget },
  mixins: [OrderDetailsMixin],
  computed: {
    details(): Array<DataListWidgetDetail> {
      const order = this.order;

      if (!order) {
        return [];
      }

      const details: Array<DataListWidgetDetail> = [
        {
          label: "Status",
          value: order.status?.text,
        },
      ];

      if (order.$data.paymentType) {
        details.push({
          label: "Payment Type: ",
          value: order.paymentType?.text,
        });
      }

      if (order.$data.invoiceNumber) {
        details.push({
          label: "Invoice Number",
          value: order.invoiceNumber,
        });
      }

      if (order.$data.paidAt) {
        details.push({
          label: "Paid At",
          value: order.paidAt?.formatDate,
        });
      }

      details.push({
        break: true,
      });

      if (this.orderTotals && this.orderTotals?.discount?.amount < 0) {
        details.push({
          label: "Discount",
          value: this.orderTotals?.discount.format,
        });

        if (order.$data.discountReason) {
          details.push({
            label: "Reason",
            value: order.discountReason?.text,
          });
        }

        details.push({
          break: true,
        });
      }

      details.push(
        {
          label: "EX VAT",
          value: this.orderTotals?.modified.exVat.format,
        },
        {
          label: "VAT",
          value: this.orderTotals?.modified.vat.format,
        },
        {
          label: "Total",
          value: this.orderTotals?.modified.price.format,
        },
      );
      // - No longer required as payment runs take precedence
      // details.push({
      //   break: true,
      // });

      // details.push({
      //   label: "Fee",
      //   value: order.feesPayOutIntro?.format ?? "£0.00",
      // });

      // if (order.$data.feesPayOutIntroDate) {
      //   details.push({
      //     label: "Paid",
      //     value: order.feesPayOutIntroDate?.formatDate,
      //   });
      // }

      // details.push({
      //   label: "Parent Fee",
      //   value: order.feesPayOutParentAgent?.format ?? "£0.00",
      // });

      // if (order.$data.feesPayOutParentAgentDate) {
      //   details.push({
      //     label: "Paid",
      //     value: order.feesPayOutParentAgentDate?.formatDate,
      //   });
      // }

      // details.push({
      //   label: "Agent Fee",
      //   value: order.feesPayoutAgent?.format ?? "£0.00",
      // });

      // if (order.$data.feesPayOutAgentDate) {
      //   details.push({
      //     label: "Paid",
      //     value: order.feesPayOutAgentDate?.formatDate,
      //   });
      // }

      return details;
    },
  },
  methods: {
    viewCreatedBy({ ctrlKey }: MouseEvent) {
      window.Kernel.visitRoute(
        {
          name: AdminUserDetailsRoute,
          params: {
            user: this.order?.createdBy?.id,
          } as AdminUserDetailsParams,
        },
        ctrlKey,
      );
    },
  },
});
</script>
