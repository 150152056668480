var u = Object.defineProperty;
var f = (m, e, n) => e in m ? u(m, e, { enumerable: !0, configurable: !0, writable: !0, value: n }) : m[e] = n;
var l = (m, e) => u(m, "name", { value: e, configurable: !0 });
var r = (m, e, n) => f(m, typeof e != "symbol" ? e + "" : e, n);
import { DateTime as w, Uuid as $ } from "@kinherit/ts-common";
import { nextTick as o } from "vue";
const N = Array(), b = Array(), T = /* @__PURE__ */ l((m) => {
  if (N.includes(m.name))
    throw new Error(`ActionBus ${m.name} already defined`);
  N.push(m.name);
  const e = Proxy.revocable(
    {},
    {
      get: /* @__PURE__ */ l((n, a) => {
        const i = {
          $busName: /* @__PURE__ */ l(() => m.name, "$busName"),
          $addListener: /* @__PURE__ */ l(() => d.addListener(m.name), "$addListener"),
          $removeListener: /* @__PURE__ */ l(() => d.removeListener(m.name), "$removeListener"),
          $waitUntilIdle: /* @__PURE__ */ l(() => d.waitUntilIdle.bind(d), "$waitUntilIdle"),
          $waitTillActive: /* @__PURE__ */ l(() => d.waitTillActive.bind(d), "$waitTillActive"),
          $instances: /* @__PURE__ */ l(() => b, "$instances")
        };
        if (a in i)
          return i[a]();
        const s = m.actions[a];
        if (!s)
          throw new Error(`Action ${a} not found`);
        return typeof s != "function" ? s : d.wrapAction({
          busName: m.name,
          methodName: a,
          action: s
        });
      }, "get")
    }
  ).proxy;
  return b.push(e), e;
}, "DefineActionBus"), t = class t {
  static async waitUntilIdle() {
    for (; this._queue.length !== 0 || new w().sub(0.25, "second").isBefore(this._lastExecutionTime); )
      await new Promise((e) => setTimeout(e, 100));
  }
  static async waitTillActive() {
    for (; this._queue.length === 0 && new w().sub(0.25, "second").isAfter(this._lastExecutionTime); )
      await new Promise((e) => setTimeout(e, 100));
  }
};
l(t, "ActionBusExecutor"), r(t, "_queue", Array()), r(t, "_lastExecutionTime", new w()), r(t, "_listeners", Array()), r(t, "addListener", /* @__PURE__ */ l((e) => (n, a) => {
  const i = t._listeners.find(
    (s) => s.busName === e && s.methodName === n
  );
  return i ? i.listener.push(a) : t._listeners.push({
    busName: e,
    methodName: n,
    listener: [a]
  }), a;
}, "addListener")), r(t, "removeListener", /* @__PURE__ */ l((e) => (n, a) => {
  const i = t._listeners.find(
    (s) => s.busName === e && s.methodName === n
  );
  i && (i.listener = i.listener.filter((s) => s !== a));
}, "removeListener")), r(t, "wrapAction", /* @__PURE__ */ l((e) => async (n, a) => {
  const i = $.generate();
  a = t.setupOptions(a), await t.setupAction({
    busName: e.busName,
    methodName: e.methodName,
    uuid: i,
    options: a
  });
  let s;
  try {
    s = await e.action(n);
  } catch (h) {
    throw t.tearDownAction({
      busName: e.busName,
      methodName: e.methodName,
      uuid: i,
      success: !1,
      options: a
    }), h;
  }
  return await Promise.all(
    t._listeners.filter(
      (h) => h.busName === e.busName && h.methodName === e.methodName
    ).map(
      (h) => h.listener.map(
        (g) => g({
          busName: e.busName,
          methodName: e.methodName,
          input: n,
          output: s
        })
      )
    ).flat()
  ), t.tearDownAction({
    busName: e.busName,
    methodName: e.methodName,
    uuid: i,
    success: !0,
    options: a
  }), s;
}, "wrapAction")), r(t, "setupOptions", /* @__PURE__ */ l((e) => ({
  hideLoading: !1,
  message: null,
  ...e
}), "setupOptions")), r(t, "setupAction", /* @__PURE__ */ l(async (e) => {
  var i, s;
  t._queue.push(e.uuid);
  const n = e.options.message ? `: ${e.options.message}` : "", a = `${e.busName}.${e.methodName}${n}`;
  t._queue.length === 1 && e.options.hideLoading === !1 && ((i = window.Kernel) == null || i.Global.showLoadingComponent(!0), await o()), (s = window.Kernel) == null || s.Logger.log("ActionBus", "info", `Starting ${a}`), t._lastExecutionTime = new w();
}, "setupAction")), r(t, "tearDownAction", /* @__PURE__ */ l((e) => {
  var i, s;
  t._queue = t._queue.remove(e.uuid);
  const n = e.options.message ? `: ${e.options.message}` : "", a = `${e.busName}.${e.methodName}${n}`;
  t._queue.isEmpty() && console.sleep(100).then(() => {
    var h;
    t._queue.isEmpty() && ((h = window.Kernel) == null || h.Global.showLoadingComponent(!1));
  }), e.success ? (i = window.Kernel) == null || i.Logger.log("ActionBus", "info", `Finished ${a}`) : (s = window.Kernel) == null || s.Logger.log("ActionBus", "error", `Failed ${a}`);
}, "tearDownAction"));
let d = t;
export {
  d as ActionBusExecutor,
  T as DefineActionBus
};
