import { ComputeAccountsReportHandler } from "@/action-bus/portal/report/compute-accounts-report.action";
import { ComputeDownloadTimeSinceLeadReportHandler } from "@/action-bus/portal/report/compute-download-time-since-lead.action";
import { ComputeLeadsByEpReportHandler } from "@/action-bus/portal/report/compute-leads-by-ep.action";
import { ComputeSummaryReportHandler } from "@/action-bus/portal/report/compute-summary-report.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ComputeAgreementsReportHandler } from "./compute-agreements-report.action";
import { ComputeAppointmentAvailabilityReportHandler } from "./compute-appointment-availability-report.action";
import { ComputeAppointmentsBreakdownReportHandler } from "./compute-appointments-breakdown-report.action";
import { ComputeAppointmentsReportHandler } from "./compute-appointments-report.action";
import { ComputeCashflowByEpReportHandler } from "./compute-cashflow-by-ep-report.action";
import { ComputeCashflowReportHandler } from "./compute-cashflow-report.action";
import { ComputeCompaniesLeaderboardReportHandler } from "./compute-companies-leaderboard-report.action";
import { ComputeCompaniesReportHandler } from "./compute-companies-report.action";
import { ComputeDocstorageReportHandler } from "./compute-docstorage-report.action";
import { ComputeDownloadReportHandler } from "./compute-download-report.action";
import { ComputeLeadsReportHandler } from "./compute-leads-report.action";
import { ComputeProductsReportHandler } from "./compute-products-report.action";
import { ComputeSignupsReportHandler } from "./compute-signups-report.action";
import { ComputeTimeToFirstCallReportHandler } from "./compute-time-to-first-call-report.action";
import { ComputeTimeToSecondCallReportHandler } from "./compute-time-to-second-call-report.action";

export const Portal2Reports = DefineActionBus({
  name: "portal-report",
  actions: {
    Accounts: ComputeAccountsReportHandler,
    Leads: ComputeLeadsReportHandler,
    LeadsByEp: ComputeLeadsByEpReportHandler,
    Cashflow: ComputeCashflowReportHandler,
    CashflowByEp: ComputeCashflowByEpReportHandler,
    Companies: ComputeCompaniesReportHandler,
    CompaniesLeaderboard: ComputeCompaniesLeaderboardReportHandler,
    Agreements: ComputeAgreementsReportHandler,
    Appointments: ComputeAppointmentsReportHandler,
    AppointmentsBreakdown: ComputeAppointmentsBreakdownReportHandler,
    Products: ComputeProductsReportHandler,
    Download: ComputeDownloadReportHandler,
    Signups: ComputeSignupsReportHandler,
    Summary: ComputeSummaryReportHandler,
    Docstorage: ComputeDocstorageReportHandler,
    TimeSinceLead: ComputeDownloadTimeSinceLeadReportHandler,
    TimeToFirstCall: ComputeTimeToFirstCallReportHandler,
    TimeToSecondCall: ComputeTimeToSecondCallReportHandler,
    Availability: ComputeAppointmentAvailabilityReportHandler,
  },
});
