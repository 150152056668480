var h = Object.defineProperty;
var t = (i, n) => h(i, "name", { value: n, configurable: !0 });
var k = /* @__PURE__ */ ((i) => (i.primary = "primary", i.positive = "positive", i.info = "info", i.success = "success", i.warning = "warning", i.danger = "danger", i.highlight = "highlight", i.grey = "grey", i["light-grey"] = "light-grey", i["dark-grey"] = "dark-grey", i["scheme-main"] = "scheme-main", i["scheme-invert"] = "scheme-invert", i.background = "background", i.border = "border", i["border-hover"] = "border-hover", i.text = "text", i["text-strong"] = "text-strong", i.link = "link", i["link-visited"] = "link-visited", i["link-hover"] = "link-hover", i["link-hover-border"] = "link-hover-border", i["link-focus"] = "link-focus", i["link-active"] = "link-active", i))(k || {}), g = /* @__PURE__ */ ((i) => (i.familyHeading = "family-heading", i.familyBody = "family-body", i.familyCode = "family-code", i))(g || {});
const d = /* @__PURE__ */ t((i, n = "has") => {
  const r = {};
  return Object.values([
    "primary",
    "positive",
    "info",
    "success",
    "warning",
    "danger",
    "highlight",
    "grey",
    "light-grey",
    "dark-grey",
    "plain",
    "red",
    "green",
    "blue",
    "pink",
    "purple",
    "brown",
    "teal",
    "link",
    "white",
    "kinherit-navy",
    "kinherit-aqua",
    "kinherit-ochre",
    "kinherit-air-force",
    "kinherit-pebble",
    "kinherit-lime",
    "kinherit-petrol",
    "kinherit-sienna"
  ]).forEach((e) => {
    r[`${n}${i.ucFirst()}${e.ucFirst()}`] = `${n}-${i}-${e}`, r[`${n}${i.ucFirst()}${e.ucFirst()}Light`] = `${n}-${i}-${e}-light`, r[`${n}${i.ucFirst()}${e.ucFirst()}Dark`] = `${n}-${i}-${e}-dark`, r[`${n}${i.ucFirst()}${e.ucFirst()}Invert`] = `${n}-${i}-${e}-invert`;
  }), r;
}, "CreateColorEnum");
export {
  d as CreateColorEnum,
  k as CssColorNames,
  g as CssFontNames
};
