// IntroducerCpd

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { IntroducerCpd } from "@kinherit/sdk/model/introducer-cpd";

export const DeleteIntroducerCpd = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/cpd/introducer/introducer-cpd/{introducerCpd}",
  method: "delete",
  parse: (introducerCpd: IntroducerCpd | string) => ({
    params: {
      introducerCpd,
    },
  }),
  after: ({ message }) => {
    IntroducerCpd.$delete(message);
  },
});
