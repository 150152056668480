// EmailCampaign, Profile

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { EmailCampaign } from "@kinherit/sdk/model/email-campaign";

export const UpdateEmailCampaign = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/email-campaign/{emailCampaign}",
  method: "patch",
  parse: (target: EmailCampaign) => ({
    body: {
      name: target.$data.name,
      description: target.$data.description,
    },
    params: {
      emailCampaign: target,
    },
  }),
});
