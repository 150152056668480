import { Api } from "@/service/api.service";
import { IntroducerCompany } from "@kinherit/sdk";

interface DownloadIntroducerCompanyStatsMessage {
  introducerCompany: IntroducerCompany;
}

export const DownloadIntroducerCompanyStats = async (
  message: DownloadIntroducerCompanyStatsMessage,
): Promise<void> => {
  const { introducerCompany } = message;
  await Api.resource(
    "portal",
    "/v2/portal/introducer/company/{introducerCompany}/stats/download",
    {
      introducerCompany:
        "string" === typeof message.introducerCompany
          ? message.introducerCompany
          : message.introducerCompany.id,
    },
  )
    .method("get")
    .download({
      encoding: "application/pdf",
      fileName: `Referral Report - ${introducerCompany.profile?.organisationNumber}.pdf`,
    });
};
