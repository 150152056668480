// DefaultBrandedKinvaultReferralCode

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk/model/default-branded-kinvault-referral-code";

export const UpdateDefaultBrandedKinvaultReferralCode = DefineWriteAction({
  interface: "portal",
  resource:
    "/v2/portal/admin/branded-kinvault/default-branded-kinvault-referral-code/{defaultBrandedKinvaultReferralCode}",
  method: "patch",
  parse: (target: DefaultBrandedKinvaultReferralCode) => ({
    body: {
      code: target.$data.code,
      name: target.$data.name,
      brandedKinvault: target.$data.brandedKinvault,
    },
    params: {
      defaultBrandedKinvaultReferralCode: target,
    },
  }),
});
