// Lead, Profile, AccountReferral

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";
import { Lead } from "@kinherit/sdk/model/lead";

export const ReadAccountReferralCodeLeads = DefineReadAction({
  interface: "portal",
  resource:
    "/v2/portal/introducer/contact/referral/{introducerContact}/code/{accountReferralCode}/leads",
  method: "get",
  rootModel: Lead,
  parse: (message: {
    introducerContact: IntroducerContact | string;
    accountReferralCode: AccountReferralCode | string;
  }) => ({
    params: {
      introducerContact: message.introducerContact,
      accountReferralCode: message.accountReferralCode,
    },
  }),
});
