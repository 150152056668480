var f = Object.defineProperty;
var p = (e, t) => f(e, "name", { value: t, configurable: !0 });
import { _ as v } from "../../../../core/internal-components/app/app.vue_vue_type_script_lang.js";
import { resolveComponent as o, createElementBlock as s, openBlock as a, mergeProps as i, createCommentVNode as r, createVNode as n, createElementVNode as d, createBlock as c } from "vue";
import { _ as w } from "../../../../_plugins/_plugin-vue_export-helper.js";
const B = {
  key: 0,
  class: "cypress-helper"
}, N = {
  key: 1,
  class: "breadcrumb-wrapper"
}, C = { class: "site-main-wrapper" };
function F(e, t, S, V, $, M) {
  var m;
  const l = o("Header"), g = o("Breadcrumbs"), u = o("RouterView"), _ = o("PageNotFound"), k = o("Footer"), y = o("PragmaticSidebarLoader"), P = o("PragmaticDialogLoader"), b = o("PragmaticContextMenuLoader"), L = o("PragmaticLoadingLoader"), h = o("PragmaticSnackBarLoader");
  return a(), s("div", i({
    class: [
      {
        "has-animation-disabled": e.kernelMode === "Test",
        "is-touch-screen-only": e.isTouchScreenOnly
      }
    ]
  }, e.bindings.wrapper), [
    e.kernelMode === "Test" ? (a(), s("div", B)) : r("", !0),
    !e.loading && e.isReady ? (a(), s("div", i({ key: 1 }, e.bindings.page, {
      key: e.renderKey,
      class: "header--main--footer"
    }), [
      e.showNavbar ? (a(), c(l, { key: 0 })) : r("", !0),
      ((m = e.content) == null ? void 0 : m.showBreadcrumbs) !== !1 ? (a(), s("div", N, [
        n(g)
      ])) : r("", !0),
      d("div", C, [
        d("main", i({
          class: `site-main content page ${e.pageClasses}`
        }, e.bindings.content), [
          e.hasRoute ? (a(), c(u, { key: 0 })) : (a(), c(_, { key: 1 }))
        ], 16)
      ]),
      e.showFooter ? (a(), c(k, { key: 2 })) : r("", !0)
    ], 16)) : r("", !0),
    n(y),
    n(P),
    n(b),
    n(L),
    n(h),
    t[0] || (t[0] = d("div", { class: "pragmatic-container" }, null, -1))
  ], 16);
}
p(F, "_sfc_render");
const H = /* @__PURE__ */ w(v, [["render", F]]);
export {
  H as default
};
