import {
  OptionsSelectField,
  ProductField,
  SharedFormProps,
} from "@/config/form.config";
import { CreatePhysicalStorageSubscription } from "@/config/model.config";
import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { FormSwitchField } from "@kinherit/framework/component.input/switch-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm/index";
import { Option, PhysicalStorageSubscription, Product } from "@kinherit/sdk";

export const UpdateSubscriptionForm = (data?: PhysicalStorageSubscription) => {
  if (!data) {
    data = CreatePhysicalStorageSubscription();
    data.status = Option.$findOneByOrThrow({
      group: "subscriptionStatus",
      value: "new",
    });
    data.provider = Option.$findOneByOrThrow({
      group: "storageProviders",
      value: "kinherit",
    });
  }

  const form = defineForm({
    name: "update-subscription-form",
    props: SharedFormProps,
    data: () => data as PhysicalStorageSubscription,
    formAreas: (data) => [
      defineFormArea({
        name: "subscription-form-area",
        data,
        template: GridLayout([
          ["productSku", "subscriptionValue"],
          ["provider", "status"],
          ["billingDate", "billingEndDate"],
          ["procDd", "procXero"],
          ["notes"],
        ]),
        components: () => ({
          status: [
            OptionsSelectField({
              vModel: "status",
              group: "subscriptionStatus",
              props: {
                validators: ["required"],
              },
            }),
          ],
          productSku: [
            ProductField({
              productDate: data.createdAt,
              reactive: true,
              props: {
                label: "Product SKU",
                reference: "productSku",
                vModel: {
                  get: (data) => data.productSku,
                  set: (value: Product, data) => {
                    data.productSku = value;
                    data.subscriptionValue = value?.price;
                  },
                },
                validators: ["required"],
              },
              query: {
                type: Equal("subscriptions"),
              },
            }),
          ],
          provider: [
            OptionsSelectField({
              vModel: "provider",
              group: "storageProviders",
              props: {
                validators: ["required"],
              },
            }),
          ],
          billingDate: [
            FormDateField({
              props: {
                label: "Billing Start Date",
              },
              models: {
                value: "billingDate",
              },
            }),
          ],
          billingEndDate: [
            FormDateField({
              props: {
                label: "Billing End Date",
              },
              models: {
                value: "billingEndDate",
              },
            }),
          ],
          //@todo add billing enddate for if a subscription finishes
          procXero: [
            FormSwitchField({
              props: {
                label: "Repeating Invoice on Xero Setup",
              },
              models: {
                value: "procXero",
              },
            }),
          ],
          procDd: [
            FormSwitchField({
              props: {
                label: "Direct Debit Mandate is Setup",
              },
              models: {
                value: "procDd",
              },
            }),
          ],
          notes: [
            FormRichTextField({
              props: {
                label: "Notes",
              },
              models: {
                value: "notes",
              },
            }),
          ],
          subscriptionValue: [
            FormCurrencyField({
              props: {
                label: "Subscription Value",
              },
              models: {
                value: "subscriptionValue",
              },
            }),
          ],
        }),
      }),
    ],
  });

  return form;
};
