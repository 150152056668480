<template>
  <AllContainer v-if="kintin" :kintin="kintin" @reload="$emit('reload')" />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsAddressBookAllWrapper",
  "route": "KinvaultKintinDetailsAddressBookAll",
  "extends": {
    "name": "AllContainerWrapper",
    "path": "@/module/kinvault.kintin/component/address-book/AllContainer.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import AllContainer from "@/module/kinvault.kintin/component/address-book/AllContainer.vue";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookAllRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookAllRoute,
  components: {
    AllContainer,
  },
  mixins: [KinvaultKintinDetailsMixin],
  emits: ["reload"],
  data: () => ({
    filtered: {
      people: Array<Person>(),
    },
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
    showDetails: false,
  }),
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const people = this.kintin?.people ?? [];

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      this.filtered.people = [
        ...people,
        this.kintin?.primaryPerson,
        this.kintin?.secondaryPerson,
      ]
        .filter((person) => person !== undefined && person !== null)
        .cast<Person>();

      this.filtered.people = (this.filtered.people as Array<Person>)
        .unique("id")
        .sortBy("profile.lastName");
    },
  },
});
</script>
