// IntroducerOutsource, User, IntroducerCompany, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerOutsource } from "@kinherit/sdk/model/introducer-outsource";

export const RecordIntroducerOutsource = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/outsources/{introducerOutsource}",
  method: "get",
  rootModel: IntroducerOutsource,
  parse: (introducerOutsource: IntroducerOutsource | string) => ({
    params: {
      introducerOutsource,
    },
  }),
});
