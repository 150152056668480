var B = Object.defineProperty;
var c = (e, o) => B(e, "name", { value: o, configurable: !0 });
import { D as N } from "../../../component.input/date-range-field/date-range-field.vue_vue_type_script_lang.js";
import { resolveComponent as r, resolveDirective as D, withDirectives as b, createElementBlock as t, openBlock as s, mergeProps as L, createVNode as d, withCtx as n, withKeys as w, normalizeClass as g, withModifiers as y, createSlots as M, createCommentVNode as C, createElementVNode as l, createBlock as k, Fragment as $, renderList as F, toDisplayString as i, createTextVNode as p, renderSlot as S } from "vue";
import { b as V } from "../../../_assets/component.input/date-range-field/date-range-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as T } from "../../../_plugins/_plugin-vue_export-helper.js";
const E = {
  key: 0,
  class: "date-range__dropdown"
}, K = { class: "date-range__predefined--calendars--dates" }, G = {
  key: 1,
  class: "buttons is-vertical",
  style: { gap: "0" }
}, O = { class: "date-range__button_group__label" }, P = { class: "date-range__button_group__items" }, Y = { class: "date-range__calendars--selected-dates" }, j = { class: "date-range__calendars" }, q = { class: "date-range__buttons" }, A = { class: "date-range__selected-dates" }, J = { class: "date-range__selected-dates__dates" }, Q = {
  key: 0,
  class: "date-range__num-of-days"
}, W = { class: "buttons is-right" };
function X(e, o, Z, _, x, ee) {
  const R = r("SelectField"), u = r("Button"), f = r("Calendar"), H = r("BaseInputField"), z = r("Field"), I = D("keep-on-screen"), U = D("click-outside");
  return b((s(), t("div", L(e.$data.$cypress, {
    ref: "refDateRange",
    class: `${e.wrapperClasses} input-field date-range`
  }), [
    d(z, {
      class: "date-range-field",
      label: e.label,
      messages: e.computedMessages,
      "auto-collapse-label": e.autoCollapseLabel,
      "has-focus": e.hasFocus,
      "has-value": e.computedHasValue,
      size: e.size,
      "input-id": e.computedId,
      "hide-label": e.isComponentDialog,
      "hide-message": e.isComponentDialog
    }, {
      default: n(() => [
        d(H, {
          ref: "refInput",
          "dropdown-only": e.isComponentDialog,
          value: e.inputFieldText,
          class: g(`${e.wrapperClasses} text-field ${e.state === "readonly" ? "" : "func-readonly"}`),
          type: "text",
          "input-id": e.computedId,
          placeholder: e.placeholder,
          size: e.size,
          color: e.color,
          "is-loading": e.isLoading,
          "icon-left": e.iconLeft,
          "icon-right-override": e.computedIconRight,
          "show-validation": e.computedShowValidation,
          "left-icon-click": e.leftIconClick,
          "right-icon-click": e.rightIconClick,
          "is-fullwidth": e.isFullwidth,
          "is-grouped": e.isGrouped,
          "show-clear-button": e.compHasValue,
          autofocus: e.autofocus,
          autocomplete: e.autocomplete,
          "auto-collapse-label": e.autoCollapseLabel,
          "has-value": e.computedHasValue,
          state: "readonly",
          onClear: e.clearDateRange,
          onFocus: e.showCalendars,
          onKeydown: w(y(e.closeDropDown, ["shift"]), ["tab"])
        }, M({
          dropdown: n(() => {
            var v, h;
            return [
              (e.showCalendarDropdown || e.isComponentDialog) && !e.fieldMixin.showingComponentDialog ? b((s(), t("div", E, [
                l("div", K, [
                  e.isComponentDialog ? (s(), k(R, {
                    key: 0,
                    value: e.selectValue,
                    "onUpdate:value": [
                      o[0] || (o[0] = (a) => e.selectValue = a),
                      o[1] || (o[1] = (a) => {
                        a && e.itemSelected([a]);
                      })
                    ],
                    class: "mt-2",
                    options: e.compDateRanges,
                    "map-options": {
                      label: "label",
                      text: "label",
                      value: "order"
                    },
                    label: "Predefined Dates",
                    "auto-collapse-label": "",
                    "show-clear-button": !1
                  }, null, 8, ["value", "options"])) : (s(), t("div", G, [
                    (s(!0), t($, null, F(e.dateRangeGroups, (a) => (s(), t("div", {
                      key: a.id,
                      class: "date-range__button_group"
                    }, [
                      l("small", O, i(e.$t.local.dateRangeGroupLabels[a.id]), 1),
                      l("div", P, [
                        (s(!0), t($, null, F(a.items, (m) => (s(), k(u, {
                          key: m.id,
                          class: "date-range__predefined--calendars--date",
                          size: "is-small",
                          color: "is-plain",
                          onClick: /* @__PURE__ */ c((oe) => e.itemSelected([m]), "onClick")
                        }, {
                          default: n(() => [
                            p(i(m.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))), 128))
                      ])
                    ]))), 128))
                  ])),
                  l("div", Y, [
                    l("div", j, [
                      d(f, {
                        month: e.firstCalendar.month,
                        "onUpdate:month": o[2] || (o[2] = (a) => e.firstCalendar.month = a),
                        year: e.firstCalendar.year,
                        "onUpdate:year": o[3] || (o[3] = (a) => e.firstCalendar.year = a),
                        class: "date-range__calendars__calendar",
                        "is-widget": "",
                        "is-date-picker": "",
                        "select-from": e.compFromDateSelectHover,
                        "select-to": e.compToDateSelectHover,
                        onMouseleave: o[4] || (o[4] = (a) => e.hover = null),
                        onDateHovered: o[5] || (o[5] = (a) => e.calendarEvent(a, "hover")),
                        "onUpdate:value": o[6] || (o[6] = (a) => e.calendarEvent(a, "select"))
                      }, null, 8, ["month", "year", "select-from", "select-to"]),
                      d(f, {
                        month: e.compNextMonth,
                        "onUpdate:month": o[7] || (o[7] = (a) => e.compNextMonth = a),
                        year: e.compNextYear,
                        "onUpdate:year": o[8] || (o[8] = (a) => e.compNextYear = a),
                        class: "date-range__calendars__calendar",
                        "is-widget": "",
                        "is-date-picker": "",
                        "hide-previous-button": "",
                        "hide-next-button": "",
                        "select-from": e.compFromDateSelectHover,
                        "select-to": e.compToDateSelectHover,
                        "enable-view-switcher": !1,
                        onMouseleave: o[9] || (o[9] = (a) => e.hover = null),
                        onDateHovered: o[10] || (o[10] = (a) => e.calendarEvent(a, "hover")),
                        "onUpdate:value": o[11] || (o[11] = (a) => e.calendarEvent(a, "select"))
                      }, null, 8, ["month", "year", "select-from", "select-to"])
                    ])
                  ])
                ]),
                l("div", q, [
                  l("div", A, [
                    l("div", J, [
                      l("div", {
                        class: g(["date-range__selected-dates__date", {
                          "is-highlighted": e.compSelectFromDate === null
                        }])
                      }, i(e.compSelectFromDate ? (v = e.compSelectFromDate) == null ? void 0 : v.formatDate : e.$t.local.fromDate), 3),
                      o[14] || (o[14] = p(" - ")),
                      l("div", {
                        class: g(["date-range__selected-dates__date", {
                          "is-highlighted": e.compSelectToDate === null && e.compSelectFromDate !== null
                        }])
                      }, [
                        p(i(e.compSelectToDate ? (h = e.compSelectToDate) == null ? void 0 : h.formatDate : e.$t.local.toDate) + " ", 1),
                        e.compNumberOfDays ? (s(), t("small", Q, "(" + i(e.compNumberOfDays) + ")", 1)) : C("", !0)
                      ], 2)
                    ])
                  ]),
                  l("div", W, [
                    d(u, {
                      onClick: o[12] || (o[12] = (a) => {
                        e.closeDropDown(), e.$emit("submit");
                      })
                    }, {
                      default: n(() => [
                        p(i(e.$t.local.cancel), 1)
                      ]),
                      _: 1
                    }),
                    d(u, {
                      color: "is-positive",
                      class: "js-update-button",
                      "is-disabled": !e.compDateRangeSelected,
                      onClick: o[13] || (o[13] = (a) => e.btnUpdate()),
                      onKeydown: w(y(e.closeDropDown, ["exact"]), ["tab"])
                    }, {
                      default: n(() => [
                        p(i(e.$t.local.update), 1)
                      ]),
                      _: 1
                    }, 8, ["is-disabled", "onKeydown"])
                  ])
                ])
              ])), [
                [I, !e.isComponentDialog]
              ]) : C("", !0)
            ];
          }),
          _: 2
        }, [
          e.showLeftControl ? {
            name: "left",
            fn: n(() => [
              S(e.$slots, "left", { value: e.computedValue })
            ]),
            key: "0"
          } : void 0,
          e.showRightControl ? {
            name: "right",
            fn: n(() => [
              S(e.$slots, "right", { value: e.computedValue })
            ]),
            key: "1"
          } : void 0
        ]), 1032, ["dropdown-only", "value", "class", "input-id", "placeholder", "size", "color", "is-loading", "icon-left", "icon-right-override", "show-validation", "left-icon-click", "right-icon-click", "is-fullwidth", "is-grouped", "show-clear-button", "autofocus", "autocomplete", "auto-collapse-label", "has-value", "onClear", "onFocus", "onKeydown"])
      ]),
      _: 3
    }, 8, ["label", "messages", "auto-collapse-label", "has-focus", "has-value", "size", "input-id", "hide-label", "hide-message"])
  ], 16)), [
    [U, e.closeDropDown]
  ]);
}
c(X, "_sfc_render");
typeof V == "function" && V(N);
const ie = /* @__PURE__ */ T(N, [["render", X]]);
export {
  ie as default
};
