import { Api } from "@kinherit/sdk";

export interface UpdateSortReferStatusMessage {
  externalId: string;
  newStatusId: string;
}

export const UpdateSortReferStatus = async (
  message: UpdateSortReferStatusMessage,
): Promise<void> => {
  const request = Api.resource("external", "/v2/external/sort-refer/status")
    .method("put")
    .body({
      externalId: message.externalId,
      newStatusId: message.newStatusId,
    });

  await request.result();
};
