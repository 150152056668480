<template>
  <div class="page-2 columns">
    <div :key="key" class="page column">
      <div class="grey">
        <h1>2. Your current risks</h1>
        <p class="lead">
          Based on the information you&rsquo;ve given me, the following are the
          key concerns (and associated risks) that apply to your current
          situation.
        </p>
      </div>
      <div class="white">
        <div v-if="checkIsNo('5.2.0')" class="risk">
          <h4>You don&rsquo;t have an existing Will</h4>
          <p>
            <strong class="risk-header"
              >Risk: The courts divide your estate, leading to stress and high
              costs for your family</strong
            >
            <br />
            It&rsquo;s worth pointing out that until you have a Will in place,
            the probate courts would decide who gets what if you die. With no
            instructions, untangling your affairs and wishes can lead to delays,
            higher legal costs and a huge amount of uncertainty, adding to the
            personal distress of those you leave behind.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.0')" class="risk">
          <h4>You have an existing Will, but it needs updating</h4>
          <p>
            <strong class="risk-header"
              >Risk: Your previous wishes will be carried out</strong
            >
            <br />
            Although it may no longer reflect your wishes, your existing Will is
            a legal document and will still determine what happens after your
            death until it&rsquo;s replaced with a new one. Even if your family
            know of your new wishes, it won&rsquo;t change what is legally
            recognised. It&rsquo;s therefore important to update this as soon as
            possible.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.4')" class="risk">
          <h4>You don&rsquo;t have end-of-life planning</h4>
          <p>
            <strong class="risk-header"
              >Risk: Lack of information for executors can lead to lost assets
              and stressful probate</strong
            >
            <br />
            A Will says what you want, but it doesn&rsquo;t give your executors
            and family any practical help with how to actually carry out your
            wishes. What do you own? Where is it all? How should they take over
            your home? How should they notify people? Are there any particular
            instructions? There is more than just probate for your next of kin
            to work through, and this can be stressful and time-consuming. First
            step is to ensure your assets can not be overlooked – which would
            means nobody will inherit them.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.1')" class="risk">
          <h4>You have children under 18 and need to name a guardian</h4>
          <p>
            <strong class="risk-header"
              >Risk: Children become the responsibility of the local authority
              and could go into care</strong
            >
            <br />
            If you die without appointing a guardian, things can become
            complicated and upsetting. Your local authority may place your
            children with ‘sensible&rsquo; relatives, but the process is not
            instant and can cause distress, and in the worst scenario your
            children could end up in care. Even with custody granted, the local
            authority will continue to have involvement in their finances and
            welfare until their 18th birthday. Naming guardians in a legal Will
            is therefore crucial.
          </p>
        </div>

        <div v-if="checkIsNo('5.2.1')" class="risk">
          <h4>Your guardians need updating</h4>
          <p>
            <strong class="risk-header"
              >Risk: Failure to change guardians could risk children going into
              care</strong
            >
            <br />
            Aging relatives named as guardians obviously leave your children at
            risk of not having guardians at all. Your local authority may place
            your children with alternative ‘sensible&rsquo; relatives, but the
            process is not instant and can cause substantial distress, and in
            the worst scenario your children could end up in care. Even with
            custody granted, the local authority legally continues to have
            involvement in finances and welfare until their 18th birthday.
            Updating guardians in a legal Will is therefore crucial.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.5')" class="risk">
          <h4>You are unmarried but want your partner to benefit</h4>
          <p>
            <strong class="risk-header"
              >Risk: Your partner could be excluded from inheriting
              anything</strong
            >
            <br />
            If you have a partner but are not married and you die without a
            Will, your partner will likely not inherit anything. This is because
            a partner does not have the same automatic rights as a spouse and
            the courts apply only limited discretion. The process of a court
            resolution would also be slow, expensive, and essentially uncertain,
            and likely cause substantial distress to your partner. Naming them
            as a beneficiary in a valid Will is necessary for peace of mind.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.2')" class="risk">
          <h4>
            You don&rsquo;t have a Lasting Power of Attorney for Health or
            Finance
          </h4>
          <p>
            <strong class="risk-header"
              >Risk: If you&rsquo;re incapacitated, the local authority makes
              your decisions, not your family</strong
            >
            <br />
            If you become unable to make your own decisions because of accident
            or illness, not having this in place means decisions will be taken
            by your local authority - even if you have a family. On the health
            side, you can&rsquo;t guarantee they&rsquo;ll consider your
            family&rsquo;s perspective (which could lead to decisions you and
            your family may not want). On the finance side it can mean every
            small thing (from accessing bank accounts to dealing with an
            electricity bill) becomes an administrational nightmare. An already
            difficult situation is made even harder for your family and friends
            trying to help you. Appoint someone you trust to prevent this from
            happening.
          </p>
        </div>

        <div v-if="checkIsNo('5.2.2')" class="risk">
          <h4>You don&rsquo;t have a Lasting Power of Attorney for Health</h4>
          <p>
            <strong class="risk-header"
              >Risk: If you&rsquo;re incapacitated, the local authority makes
              your decisions, not your family</strong
            >
            <br />
            If you become unable to make your own decisions because of accident
            or illness, not having this in place means decisions on your welfare
            will be taken by your local authority - even if you have a family.
            You can&rsquo;t guarantee they&rsquo;ll consider your family&rsquo;s
            perspective, which could cause distress for everyone involved and
            lead to decisions you and your family may not want. An already
            difficult situation is made even harder for family or friends trying
            to help you. Appoint someone you trust to prevent this from
            happening.
          </p>
        </div>

        <div v-if="checkIsMaybe('5.2.2')" class="risk">
          <h4>You don&rsquo;t have a Lasting Power of Attorney for Finance</h4>
          <p>
            <strong class="risk-header"
              >Risk: If you&rsquo;re incapacitated, the local authority makes
              your decisions, not your family</strong
            >
            <br />
            If this isn&rsquo;t in place and you become unable to make your own
            decisions because of accident or illness, your financial decisions
            are not down to your partner, spouse or family. They would need to
            apply to the court in order to deal with your financial decisions on
            your behalf, which can be a distressing, lengthy and costly process.
            In practice, this can mean every small thing (from accessing bank
            accounts to dealing with an electricity bill) becomes an
            administrational nightmare. An already difficult situation is made
            even harder for family or friends trying to help you. Appoint
            someone you trust to prevent this from happening.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.6')" class="risk">
          <h4>You have children from a prior relationship</h4>
          <p>
            <strong class="risk-header"
              >Risk: These children could go unrecognised and inherit
              nothing</strong
            >
            <br />
            Wills can get complicated if either you or your partner have
            children from previous relationships. Depending on how you want to
            divide up your estate, whether your partner inherits from you first
            and whether you both have the same beneficiaries, it can be a bit of
            a minefield trying to figure out how you achieve what you want to
            do. We'll make sure on our next chat that everything is set up
            properly, to ensure your children inherit your money as you would
            wish.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.7')" class="risk">
          <h4>You&rsquo;re divorced or separated and have a previous Will</h4>
          <p>
            <strong class="risk-header"
              >Risk: Inheritance for the rest of your family (or a new partner)
              could be in jeopardy</strong
            >
            <br />
            Divorce or separation can make Wills rather complicated. Re-marrying
            invalidates a Will, but divorce doesn&rsquo;t, and neither does
            separation. So, until you re-write your Will, your previous
            relationship can materially affect what you would wish. Rather than
            trying to guess how the law would interpret it, or risk the
            uncertainty affecting your family, it&rsquo;s much better to write a
            new Will that properly reflects your change in situation.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.8')" class="risk">
          <h4>You want loved ones to stay in your house</h4>
          <p>
            <strong class="risk-header"
              >Risk: Without the proper planning in place, a loved one can be
              evicted on death</strong
            >
            <br />
            When you die, your assets including your family home pass to your
            beneficiaries. If you wish for a third party to continue to live in
            the family home, for a period of time or until their death, then you
            need to take extra steps in your planning. It's not sufficient to
            simply write this as a wish in your Will, as any such instructions
            can simply be ignored, which can cause additional distress. We can
            help you set this up properly.
          </p>
        </div>
        <div />
        <div v-if="checkIsYes('5.2.3')" class="risk">
          <h4>
            You have business or agricultural assets that may qualify for tax
            relief
          </h4>
          <p>
            <strong class="risk-header"
              >Risk: Without planning, you could pay much more tax than you need
              to</strong
            >
            <br />
            If business or agricultural assets get lumped together with your
            personal assets in your Will, you could miss out on the potential
            tax allowances available to you. Separately acknowledging them makes
            it easier to pass them on smoothly and could also lead to
            substantial savings. We can help you set this up properly.
          </p>
        </div>

        <div v-if="checkIsNo('5.2.3')" class="risk">
          <h4>You have business assets that may qualify for tax relief</h4>
          <p>
            <strong class="risk-header"
              >Risk: Without planning, you could pay much more tax than you need
              to</strong
            >
            <br />
            If business assets get lumped together with your personal assets in
            your Will, you could miss out on the potential tax allowances
            available to you. Separately acknowledging them as business related
            makes it easier to pass them on smoothly and could also lead to
            substantial savings. We can help you set this up properly.
          </p>
        </div>

        <div v-if="checkIsMaybe('5.2.3')" class="risk">
          <h4>You have agricultural assets may that qualify for tax relief</h4>
          <p>
            <strong class="risk-header"
              >Risk: Without planning, you could pay much more tax than you need
              to</strong
            >
            <br />
            If agricultural assets get lumped together with your personal assets
            in your Will, you could miss out on the potential tax allowances
            available to you. Separately acknowledging them as business related
            makes it easier to pass them on smoothly and could also lead to
            substantial savings. We can help you set this up properly.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.9')" class="risk">
          <h4>You have nothing in place to protect the family home</h4>
          <p>
            <strong class="risk-header"
              >Risk: Your home has to be sold to pay for things like care fees
              or inheritance tax</strong
            >
            <br />
            As a part of your overall estate, the value of your home is taken
            into consideration if you are assessed on your ability to pay for
            care fees. If it isn&rsquo;t protected, you could end up having to
            sell it to afford to pay for any care and other demands. There are
            ways you can build protection into your planning, which are included
            in my recommendations.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.10')" class="risk">
          <h4>You want to keep your money in the family</h4>
          <p>
            <strong class="risk-header"
              >Risk: Inheritance can easily be lost through divorce, tax,
              re-marriage and care fees</strong
            >
            <br />
            It&rsquo;s worth being aware that money passed on to the next
            generation is always vulnerable to external threats like a
            beneficiary getting divorced. There are ways of ringfencing the
            money you leave behind, so only your beneficiaries can access it and
            ‘use it&rsquo; (as if it was their money), but it isn't considered
            part of their 'owned assets' (which would be at risk if they
            divorced, for example). A Trust is an excellent way of achieving
            long-lasting powerful protections, which I explain in my
            recommendations.
          </p>
        </div>

        <div v-if="checkIsYes('5.2.11')" class="risk">
          <h4>You fall into the higher inheritance tax bracket</h4>
          <p>
            <strong class="risk-header"
              >Risk: Your family pays more inheritance tax than they need
              to</strong
            >
            <br />
            The value of your estate means your family may have to pay
            substantial inheritance tax bills after you die. It's therefore
            particularly important that we look to your wider planning, and take
            full advantage of the various tax allowances available to you. We
            can also look at ways to protect future generations from paying
            inheritance tax, so your legacy can be passed on (see my
            recommendations).
          </p>
        </div>

        <div v-if="null === checkValueByStep('5.2.3')" class="risk">
          <h4>Business and Agricultural Assets</h4>
          <p>
            <strong class="risk-header"
              >Risk: Without planning, you could pay more tax than you need
              to</strong
            >
            <br />
            You have indicated that you do not have business or agricultural
            assets, so by default we would not recommend specific
            business-clause or business-trust language. If you think you may
            invest in business assets (which can be private shares or shares in
            AIM listed businesses), for investment or tax-planning or other
            purposes, let us know so that we can discuss what you would like
            building into the Will (important to ensure full applicability of
            the inheritance tax exemption).
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="kintin"
      class="help-content box column"
      style="height: fit-content"
    >
      <!-- Has existing will -->
      <ConfirmCheck
        label="Existing Will"
        :options="[
          'No existing Will',
          'Has an existing Will, but it needs updating',
        ]"
        :kintin="kintin"
        :step="'5.2.0'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        @reload="key++"
        @update="updateCheckValue"
      />
      <!-- EOL Planning -->
      <ConfirmCheck
        label="You don't have end-of-life planning"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.2.4'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        @reload="key++"
        @update="updateCheckValue"
      />
      <!-- Guardians -->
      <ConfirmCheck
        label="Guardians"
        :options="[
          'Your guardians need updating',
          'You have children under 18 and need to name a guardian',
          'You don’t have any children under 18',
        ]"
        :kintin="kintin"
        :step="'5.2.1'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        is-reversed
        allow-reset
        @reload="key++"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        label="You are unmarried but want your partner to benefit"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.2.5'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        @reload="key++"
        @update="updateCheckValue"
      />
      <!-- LPA -->
      <ConfirmCheck
        label="LPAs"
        :options="[
          'No Lasting Power of Attorney for Health',
          'No Lasting Power of Attorney for Health or Finance',
          'No Lasting Power of Attorney for Finance',
        ]"
        :kintin="kintin"
        :step="'5.2.2'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        allow-reset
        @reload="key++"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        label="You have children from a prior relationship"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.2.6'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        @reload="key++"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        label="You’re divorced or separated and have a previous Will"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.2.7'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        @reload="key++"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        label="You want loved ones to stay in your house"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.2.8'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        @reload="key++"
        @update="updateCheckValue"
      />
      <!-- Business -->
      <ConfirmCheck
        label="Business Assets"
        :options="[
          'Has business assets that may qualify for tax relief',
          'Has business and agricultural assets that may qualify for tax relief',
          'Has agricultural assets that may qualify for tax relief',
        ]"
        :kintin="kintin"
        :step="'5.2.3'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        allow-reset
        @reload="key++"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        label="You have nothing in place to protect the family home"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.2.9'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        @reload="key++"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        label="You want to keep your money in the family"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.2.10'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        @reload="key++"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        label="You fall into the higher inheritance tax bracket"
        :options="['No', 'Yes']"
        :kintin="kintin"
        :step="'5.2.11'"
        :checks="checks"
        :kintin-checks="kintinChecks"
        @reload="key++"
        @update="updateCheckValue"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";
import { ClientSummaryMixin } from "../../../mixin/client-summary.mixin";
import ConfirmCheck from "../shared/ConfirmCheck.vue";

import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";

export default defineComponent({
  name: "ClientSummaryPage2",
  components: { ConfirmCheck },
  mixins: [ClientSummaryMixin, KinvaultKintinDetailsCallScriptMixin],
  data: () => ({
    key: 0,
    ThemeIconName,
  }),
  methods: {
    resetRisks() {},
  },
});
</script>
