import { defineComponent as o } from "vue";
const n = o({
  computed: {
    hasRoute() {
      var e;
      return ((e = this.$route) == null ? void 0 : e.name) !== void 0;
    }
  }
});
export {
  n as default
};
