// BrandedKinvaultMembership

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { BrandedKinvaultMembership } from "@kinherit/sdk/model/branded-kinvault-membership";

export const DeleteBrandedKinvaultMembership = DefineDeleteAction({
  interface: "portal",
  resource:
    "/v2/portal/admin/user/{user}/branded-kinvault-membership/{brandedKinvaultMembership}",
  method: "delete",
  parse: (brandedKinvaultMembership: BrandedKinvaultMembership) => ({
    params: {
      brandedKinvaultMembership,
      user: brandedKinvaultMembership.user,
    },
  }),
  after: ({ message }) => {
    BrandedKinvaultMembership.$delete(message);
  },
});
