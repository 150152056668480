import { Api } from "@/service/api.service";

interface TransferIntroducerContactMessage {
  contact: string;
  company: string;
}

export const ChangeCompany = async (
  message: TransferIntroducerContactMessage,
): Promise<void> => {
  const { contact, company } = message;

  if (!contact || !company) {
    throw new Error("No contact or company found");
  }

  await Api.resource("portal", "/v2/portal/introducer/contact/change-company")
    .method("put")
    .body({
      contact: contact,
      company: company,
    })

    .result();
};
