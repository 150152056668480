// IntroducerFeePaymentItem, IntroducerFeePaymentRun, IntroducerFeePayment

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerFeePaymentItem } from "@kinherit/sdk/model/introducer-fee-payment-item";

export const CreateIntroducerFeePaymentItem = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/fees/payment-item",
  method: "post",
  parse: (target: IntroducerFeePaymentItem) => ({
    body: {
      feeTypeId: target.$data.feeTypeId,
      amount: target.$data.amount,
      vat: target.$data.vat,
      name: target.$data.name,
      xeroBillId: target.$data.xeroBillId,
      introducerFeePayment: target.$data.introducerFeePayment,
      order: target.$data.order,
    },
  }),
  after: ({ message }) => {
    IntroducerFeePaymentItem.$delete(message);
  },
});
