import { DangerDialog } from "@/config/dialog.config";
import {
  OptionsAutoCompleteField,
  SharedFormProps,
} from "@/config/form.config";
import { CreateCpdSession } from "@/config/model.config";
import { StyleService } from "@/service/style.service";
import { UpdateAddressForm } from "@/shared/form/address/update-address.form";
import { UpdateWebsiteForm } from "@/shared/form/website/update-website.form";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormDateTimeField } from "@kinherit/framework/component.input/date-time-field";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { FormTimeField } from "@kinherit/framework/component.input/time-field";
import { FormCard } from "@kinherit/framework/component.layout/card";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { CpdSession, Option } from "@kinherit/sdk";
import { Time } from "@kinherit/ts-common/dto/time";

export const UpdateCpdSessionForm = (data: CpdSession = CreateCpdSession()) =>
  defineForm({
    name: "update-cpd-session-form",
    props: SharedFormProps,
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "cpd-session-type-form-area",
        data,
        components: () => ({
          default: [
            OptionsAutoCompleteField({
              group: "introducerCpdType",
              vModel: {
                get: (data) => data.type,
                set: (value: Option, data) => {
                  data.type = value;

                  const optionData = value.data;

                  if (null === optionData) {
                    data.title = "";
                    data.duration = null as any as Time;
                    data.outcomes = null;
                    return;
                  }

                  data.title = value.text;
                  data.duration = Time.fromMinutes(
                    optionData.duration as number,
                  );

                  let outcomes = optionData.outcomes as string[];

                  if (!Array.isArray(outcomes)) {
                    outcomes = [];
                  }

                  console.log(outcomes, typeof outcomes);
                  data.outcomes = `<ul>${outcomes
                    .map((outcome) => `<li>${outcome}</li>`)
                    .join("")}</ul>`;
                },
              },
              props: {
                validators: ["required"],
                reference: "type",
              },
            }),
          ],
        }),
      }),
      defineFormArea({
        name: "cpd-session-details-form-area",
        data,
        show: (data) => data.type !== null,
        template: GridLayout([
          ["datetime", "duration"],
          ["isPrivate"],
          ["title"],
          ["address", "websites"],
          ["outcomes"],
          ["allowBookings"],
          ["inviteDescription"],
        ]),
        components: (data) => ({
          datetime: [
            FormDateTimeField({
              props: {
                label: "Date and time",
                validators: ["required"],
              },
              models: {
                value: "date",
              },
            }),
          ],
          duration: [
            FormTimeField({
              props: {
                label: "Duration",
                validators: ["required"],
              },
              models: {
                value: "duration",
              },
            }),
          ],
          isPrivate: [
            FormCheckboxField({
              props: {
                label: "Private",
              },
              models: {
                value: "isPrivate",
              },
            }),
          ],
          title: [
            FormTextField({
              props: {
                label: "Title",
                validators: ["required"],
              },
              models: {
                value: "title",
              },
            }),
          ],
          address: [
            FormCard({
              props: {
                title: "Address",
                titleSize: "is-4",
                reference: "address",
              },
              slots: {
                default: [
                  ...(data.address
                    ? [
                        FormTextField<CpdSession>({
                          props: {
                            state: "readonly",
                            class: "mb-2",
                          },
                          models: {
                            value: `address.summary`,
                          },
                          slots: {
                            right: [
                              FormButton({
                                props: {
                                  iconRight: StyleService.icon.delete.icon,
                                  color: "is-danger",
                                  reference: "removeAddress",
                                },
                                emits: {
                                  click: async (value, data, controls) => {
                                    await DangerDialog({
                                      dialog: {
                                        title: "Remove address",
                                        message:
                                          "Are you sure you want to remove this address?",
                                      },
                                    });

                                    data.address?.$delete();
                                    data.address = null;

                                    controls.rebuildForm();
                                  },
                                },
                              }),
                            ],
                          },
                        }),
                      ]
                    : []),
                  FormButton({
                    props: {
                      text: (data) =>
                        `${data.address ? "Change" : "Add"} Address`,
                      color: "is-success",
                      reference: "changeAddress",
                    },
                    emits: {
                      click: async (value, data, controls) => {
                        try {
                          const address = await UpdateAddressForm({
                            data: data.address ?? undefined,
                          }).dialog({
                            dialog: {
                              title: "Address",
                            },
                          });

                          data.address = address;
                          controls.rebuildForm();
                        } catch (e) {
                          data.address?.$restore();
                          throw e;
                        }
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          outcomes: [
            FormRichTextField({
              props: {
                label: "Outcomes",
              },
              models: {
                value: "outcomes",
              },
            }),
          ],
          allowBookings: [
            FormCheckboxField({
              props: {
                label: "Allow bookings",
              },
              models: {
                value: "allowBookings",
              },
            }),
          ],
          websites: [
            FormCard({
              props: {
                title: "Websites",
                titleSize: "is-4",
                reference: "websites",
              },
              slots: {
                default: [
                  ...data.websites.map((website, index) =>
                    FormTextField<CpdSession>({
                      props: {
                        state: "readonly",
                        class: "mb-2",
                      },
                      models: {
                        value: `websites.${index}.url`,
                      },
                      slots: {
                        right: [
                          FormButton({
                            props: {
                              iconRight: StyleService.icon.delete.icon,
                              color: "is-danger",
                              reference: "removeWebsite",
                            },
                            emits: {
                              click: async (value, data, controls) => {
                                await DangerDialog({
                                  dialog: {
                                    title: "Remove Website",
                                    message:
                                      "Are you sure you want to remove this website?",
                                  },
                                  button: {
                                    ok: { text: "Remove" },
                                  },
                                });

                                data.removeWebsites(website);
                                website.$delete();

                                controls.rebuildForm();
                              },
                            },
                          }),
                        ],
                      },
                    }),
                  ),
                  FormButton({
                    props: {
                      text: "Add Website",
                      color: "is-success",
                      reference: "addWebsite",
                    },
                    emits: {
                      click: async (value, data, controls) => {
                        const website = await UpdateWebsiteForm().dialog({
                          dialog: {
                            title: "Website",
                          },
                        });

                        data.addWebsites(website);

                        controls.rebuildForm();
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          inviteDescription: [
            FormRichTextField({
              props: {
                label: "Invite description",
              },
              models: {
                value: "inviteDescription",
              },
            }),
          ],
        }),
      }),
    ],
  });
