import { TAccountsReportResult } from "@/action-bus/portal/report/compute-accounts-report.action";
import { DateTime } from "@kinherit/ts-common/dto/date-time";

/**
 * There's probably a better place to put this...
 * @param count
 * @param total
 * @returns string in the format of "count (percentage%)"
 */
export function quickPercentage(count: number, total: number) {
  return count > 0
    ? count + `<small> (${((count / total) * 100).toFixed(0)}%)</small>`
    : "0";
}

export function totalWithOverdue(
  results: TAccountsReportResult,
  stage: string,
) {
  const result = results.kintinStages.find((s) => s.name === stage) ?? {
    count: 0,
    overdueCount: 0,
  };

  const total = result.count;
  const overdue = `<span class='ml-2 has-text-danger'>(${result.overdueCount})</span>`;
  if (result.overdueCount > 0) {
    return total + overdue;
  }
  if (total > 0) {
    return total;
  }
  return "-";
}
/**
 * Takes two arrays and merges them into one array with one record after the other.
 * @param datasets - An array of arrays.
 * @returns An array containing the merged records.
 */
export function mergeArrays<T>(datasets: T[][]): T[] {
  const merged: T[] = [];
  const arr1 = datasets[0];
  const arr2 = datasets[1];

  if (arr2.length > 0) {
    const l = Math.max(arr1.length, arr2.length);

    for (let i = 0; i < l; i++) {
      if (i < arr1.length) {
        merged.push(arr1[i]);
      }
      if (i < arr2.length) {
        merged.push(arr2[i]);
      }
    }
  }

  return merged;
}

/**
 * Calculate start and end dates and compareTo dates for filters
 * @param year - The year selected in the filter
 * @param created - The date range selected in the filter
 * @param compareTo - The compareTo year selected in the filter
 */
export function calculateStartEndDates(
  year: string,
  created: Array<number> | null,
  compareTo: string | null = null,
) {
  // Assuming DateTime.formatYMD returns a date string in 'YYYY-MM-DD' format,
  // we append 'T00:00' for the start date and 'T23:59' for the end date to include time.
  const startDate: string =
    year !== "custom"
      ? `${year}-01-01T00:00`
      : `${new DateTime(created?.[0]).formatYMD}T00:00`;
  const endDate: string =
    year !== "custom"
      ? `${year}-12-31T23:59`
      : `${new DateTime(created?.[1]).formatYMD}T23:59`;

  let compareToStartDate: string | null = null;
  let compareToEndDate: string | null = null;

  if (compareTo) {
    compareToStartDate = `${compareTo}-01-01T00:00`;
    compareToEndDate = `${compareTo}-12-31T23:59`;
  }

  return {
    startDate,
    endDate,
    compareToStartDate,
    compareToEndDate,
  };
}

/*
 * Get current dates for row highlighting
 */
export function getCurrentDatesForRowHighlighting() {
  const currentWeek = new DateTime().getWeekNumber;
  const currentYear = new DateTime().format("YYYY");
  const currentMonth = new DateTime().format("MM");
  const currentQuarter = Math.ceil(Number(currentMonth) / 3);
  return {
    currentWeek,
    currentYear,
    currentMonth,
    currentQuarter,
  };
}

/*
 * return a boolean dependent on current date
 * @granularity - the granularity of the report
 * @row - the row of the report
 */
export function hasCurrentRowHighlighting(
  granularity: "day" | "week" | "month" | "quarter" | "year",
  row: any,
) {
  const { currentYear, currentMonth, currentWeek, currentQuarter } =
    getCurrentDatesForRowHighlighting();

  return granularity === "week"
    ? row?.week === currentWeek && row?.year === Number(currentYear)
    : granularity === "month"
      ? row?.month === Number(currentMonth) && row?.year === Number(currentYear)
      : granularity === "quarter"
        ? row?.quarter === currentQuarter && row?.year === Number(currentYear)
        : false;
}

/*
 From a given year, period and granularity, return the start and end dates
  i.e 2022, 4, "week" => would return ISO week 4 of 2022
  --  2022, 8, "month" => would return 1st August 2022 to 31st August 2022
  --  2022, 3, "quarter" => would return 1st July 2022 to 30th September 2022
  The start and end are return as timestamps
 */
export function getPeriodStartAndEndDates(
  granularity: "week" | "month" | "quarter" | "year",
  year: number | string,
  period: number | string,
): [number, number] {
  let startDate;
  let endDate;
  const currentYear = Number(year);
  const currentPeriod = Number(period);
  if (granularity === "week") {
    // Create a date representing the first day of the year
    startDate = new DateTime(Date.UTC(currentYear as number, 0, 1));
    const firstDayOfYearDayOfWeek = startDate.getDate().getUTCDay();

    // Calculate the number of days to add to reach the first week's Monday
    // Note: Adjusting to the first Monday (ISO 8601 assumes weeks start on Monday)
    const daysToAdd =
      (firstDayOfYearDayOfWeek <= 4 ? 1 : 8) - firstDayOfYearDayOfWeek;
    startDate = startDate.add(daysToAdd, "days");

    // Calculate the start date of the specified week
    const daysFromFirstWeek = (currentPeriod - 1) * 7; // -1 because we are already at the first week
    startDate = startDate.add(daysFromFirstWeek, "days");

    // Calculate the end date of the week
    endDate = startDate.clone().add(6, "days"); // 6 days later to get to the end of the week
  } else if (granularity === "month") {
    startDate = new DateTime(Date.UTC(currentYear, currentPeriod - 1, 1));
    endDate = startDate.clone().add(1, "month").add(-1, "day");
  } else if (granularity === "quarter") {
    startDate = new DateTime(Date.UTC(currentYear, (currentPeriod - 1) * 3, 1));
    endDate = startDate.clone().add(3, "month").add(-1, "day");
  } else {
    startDate = new DateTime(Date.UTC(currentYear, 0, 1));
    endDate = startDate.clone().add(1, "year").add(-1, "day");
  }
  startDate.setTime(0, 0, 0, 0);
  endDate.setTime(23, 59, 59, 999);
  return [startDate.timestamp, endDate.timestamp];
}
