var p = Object.defineProperty;
var o = (t, n) => p(t, "name", { value: n, configurable: !0 });
import { _ as m } from "../../../component.display/page-not-found/page-not-found.vue_vue_type_script_lang.js";
import { resolveComponent as s, createElementBlock as a, openBlock as e, createVNode as _, createBlock as i, createCommentVNode as f, withCtx as c, createElementVNode as $, Fragment as b, renderList as v, createTextVNode as h, toDisplayString as k } from "vue";
import { _ as C } from "../../../_plugins/_plugin-vue_export-helper.js";
const B = { class: "page-not-found-component" }, N = { class: "buttons" };
function g(t, n, y, V, D, E) {
  const r = s("Card"), u = s("Button");
  return e(), a("div", B, [
    _(r, {
      title: t.$t.local.title,
      subtitle: t.$t.local.subtitle
    }, null, 8, ["title", "subtitle"]),
    t.isDevMode ? (e(), i(r, {
      key: 0,
      title: "Available routes",
      subtitle: "Only visible in dev mode"
    }, {
      default: c(() => [
        $("div", N, [
          (e(!0), a(b, null, v(t.routes, (l, d) => (e(), i(u, {
            key: `route-${d}`,
            class: "button",
            onClick: /* @__PURE__ */ o((F) => t.$router.push(l.path), "onClick")
          }, {
            default: c(() => [
              h(k(l.path), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]))), 128))
        ])
      ]),
      _: 1
    })) : f("", !0)
  ]);
}
o(g, "_sfc_render");
const O = /* @__PURE__ */ C(m, [["render", g]]);
export {
  O as default
};
