import {
  IntroducerCompanyField,
  OptionsSelectField,
  UserField,
} from "@/config/form.config";
import {
  CreateIntroducerCompany,
  CreateIntroducerContact,
} from "@/config/model.config";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormPhoneNumberField } from "@kinherit/framework/component.input/phone-number-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import {
  EmailAddress,
  IntroducerCompany,
  IntroducerContact,
  Option,
  PhoneNumber,
} from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common";

export const CreateIntroducerCompanyForm = ({
  company = CreateIntroducerCompany(),
  contact = CreateIntroducerContact(),
}: {
  company?: IntroducerCompany;
  contact?: IntroducerContact;
} = {}) => {
  return defineForm({
    name: "create-introducer-company-form",
    data: () => ({
      company,
      contact,
    }),
    beforeMount: () => {
      company.status = Option.$findOneByOrThrow({
        group: "introducerStatus",
        value: "new",
      }) as Option;

      company.stage = Option.$findOneByOrThrow({
        group: "introducerStage",
        value: "packet",
      }) as Option;

      contact.callStatus = Option.$findOneByOrThrow({
        group: "introducerCpdCallStatus",
        value: "tbc",
      }) as Option;

      contact.status = Option.$findOneByOrThrow({
        group: "introducerContactStatus",
        value: "new",
      }) as Option;

      contact.addCompanies(company);
    },
    formAreas: (data) => [
      defineFormArea({
        name: "create-introducer-company-form-area",
        data,
        template: GridLayout([
          ["subtitle1"],
          ["organisationName", "type"],
          ["companyEmail", "companyPhone"],
          { is: "hr", attr: { class: "is-dashed" } } as never,
          [
            "introducedBy",
            "seniorAdviser",
            "assignedTo",
            "defaultLegalAssistant",
          ],
          { is: "hr" } as never,
          ["subtitle2"],
          ["firstName", "lastName"],
          ["email", "phone"],
        ]),
        components: () => ({
          subtitle1: [
            FormTitle({
              props: {
                text: "Introducer Company Details",
                size: "is-3",
              },
            }),
          ],
          organisationName: [
            FormTextField({
              props: {
                label: "Organisation Name",
                validators: ["required"],
              },
              models: {
                value: "company.profile.organisationName",
              },
            }),
          ],
          companyEmail: [
            FormEmailField({
              reactive: true,
              props: {
                label: "Organisation Email",
                message:
                  "The primary email address for the organisation, i.e info@",
                showGenerateButton: false,
                generatedPrefix: (data) =>
                  data.company.profile.organisationName
                    ?.trim()
                    .replace(/\s/g, "-")
                    .toLowerCase(),
                reference: "companyEmail",
              },
              models: {
                value: {
                  get: (data) =>
                    data.company.profile.emails.first()?.email ?? "",
                  set: (value, data) => {
                    const email = data.company.profile.emails.first();

                    if (["", null, undefined].includes(value)) {
                      if (email) {
                        data.company.profile.removeEmails(email);
                        email.$delete();
                      }
                    } else if (email) {
                      email.email = value;
                      email.$persist();
                    } else {
                      data.company.profile.addEmails(
                        new EmailAddress({
                          id: Uuid.generate(),
                          email: value,
                          primary: true,
                          createdAt: new DateTime().formatMachine,
                        }).$persist(),
                      );
                      data.company.profile.$persist();
                    }
                  },
                },
              },
            }),
          ],
          companyPhone: [
            FormPhoneNumberField({
              reactive: true,
              props: {
                label: "Organisation Phone Number",
                message: "The main office phone number",
                reference: "companyPhone",
              },
              models: {
                value: {
                  get: (data) =>
                    data.company.profile.phoneNumbers.first()?.tel ?? "",
                  set: (value, data) => {
                    const phone = data.company.profile.phoneNumbers.first();

                    if (["", null, undefined].includes(value)) {
                      if (phone) {
                        data.company.profile.removePhoneNumbers(phone);
                        phone.$delete();
                      }
                    } else if (phone) {
                      phone.tel = value;
                      phone.$persist();
                    } else {
                      data.company.profile.addPhoneNumbers(
                        new PhoneNumber({
                          id: Uuid.generate(),
                          tel: value,
                          primary: true,
                          createdAt: new DateTime().formatMachine,
                        }).$persist(),
                      );
                      data.company.profile.$persist();
                    }
                  },
                },
              },
            }),
          ],
          type: [
            OptionsSelectField({
              group: "introducerType",
              vModel: "company.type",
              props: {
                label: "Type",
                validators: ["required"],
                placeholder: "--Please Select--",
              },
            }),
          ],
          seniorAdviser: [
            UserField({
              props: {
                label: "Sales Team",
                vModel: "company.seniorAdviser",
                message: "Main Sales Team Lead",
              },
              query: {
                displayUserInPortal: Equal(true),
              },
            }),
          ],
          assignedTo: [
            UserField({
              props: {
                label: "Assign Estate Planner",
                vModel: "company.defaultEstatePlanner",
                message: "The assigned EP",
              },
              query: {
                displayUserInPortal: Equal(true),
                isEstatePlanner: Equal(true),
              },
            }),
          ],
          defaultLegalAssistant: [
            UserField({
              props: {
                label: "Assigned Legal Assistant",
                vModel: "company.defaultLegalAssistant",
                message: "The assigned Legal Assistant",
              },
              query: {
                displayUserInPortal: Equal(true),
                isLegalAssistant: Equal(true),
              },
            }),
          ],
          introducedBy: [
            IntroducerCompanyField({
              props: {
                label: "Introduced By",
                vModel: "company.introducedBy",
                message: "Agent Introducer if appropriate",
              },
              query: {
                contracts: {
                  type: {
                    value: Equal("agentintroducer"),
                  },
                },
              },
            }),
          ],

          subtitle2: [
            FormTitle({
              props: {
                text: "Initial Contact Details",
                size: "is-3",
              },
            }),
          ],
          firstName: [
            FormTextField({
              props: {
                label: "First Name",
                validators: ["required"],
              },
              models: {
                value: "contact.profile.firstName",
              },
            }),
          ],
          lastName: [
            FormTextField({
              props: {
                label: "Last Name",
                validators: ["required"],
              },
              models: {
                value: "contact.profile.lastName",
              },
            }),
          ],
          email: [
            FormEmailField({
              reactive: true,
              props: {
                label: "Email",
                reference: "email",
              },
              models: {
                value: {
                  get: (data) =>
                    data.contact.profile.emails.first()?.email ?? "",
                  set: (value, data) => {
                    const email = data.contact.profile.emails.first();

                    if (["", null, undefined].includes(value)) {
                      if (email) {
                        data.contact.profile.removeEmails(email);
                        email.$delete();
                      }
                    } else if (email) {
                      email.email = value;
                      email.$persist();
                    } else {
                      data.contact.profile.addEmails(
                        new EmailAddress({
                          id: Uuid.generate(),
                          email: value,
                          primary: true,
                          createdAt: new DateTime().formatMachine,
                        }).$persist(),
                      );
                      data.contact.profile.$persist();
                    }
                  },
                },
              },
            }),
          ],
          phone: [
            FormPhoneNumberField({
              reactive: true,
              props: {
                label: "Phone Number",
                reference: "phone",
              },
              models: {
                value: {
                  get: (data) =>
                    data.contact.profile.phoneNumbers.first()?.tel ?? "",
                  set: (value, data) => {
                    const phone = data.contact.profile.phoneNumbers.first();

                    if (["", null, undefined].includes(value)) {
                      if (phone) {
                        data.contact.profile.removePhoneNumbers(phone);
                        phone.$delete();
                      }
                    } else if (phone) {
                      phone.tel = value;
                      phone.$persist();
                    } else {
                      data.contact.profile.addPhoneNumbers(
                        new PhoneNumber({
                          id: Uuid.generate(),
                          tel: value,
                          primary: true,
                          createdAt: new DateTime().formatMachine,
                        }).$persist(),
                      );
                      data.contact.profile.$persist();
                    }
                  },
                },
              },
            }),
          ],
          callStatus: [
            OptionsSelectField({
              group: "introducerCpdCallStatus",
              vModel: "contact.callStatus",
              props: {
                label: "Call Status",
                validators: ["required"],
              },
            }),
          ],
        }),
      }),
    ],
  });
};
