// BrandedKinvaultInvitation, AccountReferralCode, BrandedKinvaultInviteReferral, Profile, IntroducerContact, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvaultInvitation } from "@kinherit/sdk/model/branded-kinvault-invitation";

export const RecordBrandedKinvaultInvitation = DefineReadAction({
  interface: "portal",
  resource:
    "/v2/portal/admin/branded-kinvault/invitation/{brandedKinvaultInvitation}",
  method: "get",
  rootModel: BrandedKinvaultInvitation,
  parse: (brandedKinvaultInvitation: BrandedKinvaultInvitation | string) => ({
    params: {
      brandedKinvaultInvitation,
    },
  }),
});
