// Address, Beneficiary, CallScript, Exclusion, Executor, Gift, Guardian, Kintin, Person, Profile, Property, Trustee, User

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const ReadWillData = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/will-revision/data/{kintin}",
  method: "get",
  rootModel: Kintin,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});
