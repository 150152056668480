// Kintin, Profile, AccountReferral, AccountReferralCode, KintinAccess, User, Person, KintinCheck, PhoneNumber, EmailAddress, Website, CallScript, Address, IntroducerCompany, IntroducerContact, Property, Lead, BrandedKinvault, BrandedKinvaultMembership, IntroducerNetwork

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { Lead } from "@kinherit/sdk/model/lead";

export const RecordKintin = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/lead/lead/{lead}/kintin/{kintin}",
  method: "get",
  rootModel: Kintin,
  parse: (message: { lead: Lead | string; kintin: Kintin | string }) => ({
    params: {
      lead: message.lead,
      kintin: message.kintin,
    },
  }),
});
