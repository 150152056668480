var u = Object.defineProperty;
var l = (o, e) => u(o, "name", { value: e, configurable: !0 });
import { _ as t } from "../../../component.input/password-field/password-field.vue_vue_type_script_lang.js";
import { resolveComponent as d, createBlock as p, openBlock as m, mergeProps as f, createSlots as c, withCtx as s, renderSlot as r } from "vue";
import { b as i } from "../../../_assets/component.input/password-field/password-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as g } from "../../../_plugins/_plugin-vue_export-helper.js";
function h(o, e, w, v, b, k) {
  const n = d("BaseInputField");
  return m(), p(n, f(o.$data.$cypress, {
    value: o.computedValue,
    "onUpdate:value": e[0] || (e[0] = (a) => o.computedValue = a),
    class: `${o.wrapperClasses} text-field`,
    label: o.label,
    "input-tag": o.isTextArea ? "textarea" : "input",
    type: o.revealPassword ? "text" : "password",
    placeholder: o.placeholder,
    state: o.state,
    validators: o.validators,
    message: o.message,
    "message-color": o.messageColor,
    size: o.size,
    color: o.color,
    "is-loading": o.isLoading,
    "icon-left": o.revealPassword ? "Unlock" : "Lock",
    "icon-right-override": o.computedIconRight,
    "show-validation": o.computedShowValidation,
    "left-icon-click": /* @__PURE__ */ l(() => o.revealPassword = !o.revealPassword, "left-icon-click"),
    "right-icon-click": o.rightIconClick,
    "auto-collapse-label": o.autoCollapseLabel,
    "is-fullwidth": o.isFullwidth,
    "is-grouped": o.isGrouped,
    "show-clear-button": o.showClearButton,
    autofocus: o.autofocus,
    autocomplete: o.autocomplete ?? "current-password",
    onClear: o.clear,
    onFocus: o.focus,
    onBlur: e[1] || (e[1] = (a) => o.$emit("blur")),
    onSubmit: o.keydown
  }), c({ _: 2 }, [
    o.showLeftControl ? {
      name: "left",
      fn: s(() => [
        r(o.$slots, "left", { value: o.computedValue })
      ]),
      key: "0"
    } : void 0,
    o.showRightControl ? {
      name: "right",
      fn: s(() => [
        r(o.$slots, "right", { value: o.computedValue })
      ]),
      key: "1"
    } : void 0
  ]), 1040, ["value", "class", "label", "input-tag", "type", "placeholder", "state", "validators", "message", "message-color", "size", "color", "is-loading", "icon-left", "icon-right-override", "show-validation", "left-icon-click", "right-icon-click", "auto-collapse-label", "is-fullwidth", "is-grouped", "show-clear-button", "autofocus", "autocomplete", "onClear", "onFocus", "onSubmit"]);
}
l(h, "_sfc_render");
typeof i == "function" && i(t);
const P = /* @__PURE__ */ g(t, [["render", h]]);
export {
  P as default
};
