var r = Object.defineProperty;
var u = (e, t, a) => t in e ? r(e, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[t] = a;
var o = (e, t) => r(e, "name", { value: t, configurable: !0 });
var n = (e, t, a) => u(e, typeof t != "symbol" ? t + "" : t, a);
import { ValidationType as i } from "../core/validation-type.js";
import { isValidPhoneNumber as l } from "libphonenumber-js/max";
const s = "phone-number", c = /* @__PURE__ */ o((e = i.Error) => {
  var t;
  return t = class {
    constructor(a) {
      this.data = a;
    }
    getResult() {
      return [null, void 0, ""].includes(this.data.value) ? null : !l(this.data.value, {
        defaultCountry: "GB"
      }) && !l(this.data.value, {}) ? {
        message: "Please enter a valid phone number.",
        type: e
      } : null;
    }
  }, n(t, "validatorName", s), t;
}, "PhoneNumberValidatorConstructor");
export {
  c as PhoneNumberValidatorConstructor,
  s as PhoneNumberValidatorName
};
