var t = Object.defineProperty;
var o = (n, i) => t(n, "name", { value: i, configurable: !0 });
import { defineComponent as e } from "vue";
const r = /* @__PURE__ */ o((n) => e({
  name: "ActionBusMixin",
  computed: {
    $actionBus() {
      return n();
    }
  }
}), "ActionBusMixin");
export {
  r as ActionBusMixin
};
