import {
  CreatedAtField,
  IntroducerCompanyField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadBrandedKinvaultCtaForm = () =>
  MasterListFiltersForm({
    name: "filter-branded-kinvault-cta",
    data: () => ({
      name: "",
      created: null as null | [number, number],
      introducerCompany: [] as string[],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Name",
            placeholder: "Name",
          },
          models: {
            value: "name",
          },
        }),
      ],
      introducerCompany: [
        IntroducerCompanyField({
          props: {
            vModel: "introducerCompany",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
    advancedComponents: () => ({}),
  });
