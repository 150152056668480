// Order
import { Api } from "@/service/api.service";
import { Order } from "@kinherit/sdk";

export const CreateOrder = async (data: Order) => {
  const request = Api.resource("portal", "/v2/portal/kinvault/{kintin}/order")
    .method("post")
    .body({
      kintin: data.$data.kintin,
      status: data.$data.status,
      paymentType: data.$data.paymentType,
      extrasPrice: data.$data.extrasPrice,
      discountValue: data.$data.discountValue,
      discountPercentage: data.$data.discountPercentage,
      coolingOff: data.$data.coolingOff,
      paidAt: data.$data.paidAt,
      invoiceNumber: data.$data.invoiceNumber,
      notes: data.$data.notes,
      paymentSignKey: data.$data.paymentSignKey,
      stripePaymentIntentId: data.$data.stripePaymentIntentId,
      overrideListPrice: data.$data.overrideListPrice,
      feesPayOutIntro: data.$data.feesPayOutIntro,
      feesPayoutAgent: data.$data.feesPayoutAgent,
      feesPayOutIntroDate: data.$data.feesPayOutIntroDate,
      feesPayOutAgentDate: data.$data.feesPayOutAgentDate,
      feesPayOutParentAgent: data.$data.feesPayOutParentAgent,
      feesPayOutParentAgentDate: data.$data.feesPayOutParentAgentDate,
      feesConfirmed: data.$data.feesConfirmed,
      discountReason: data.$data.discountReason,
      xeroIntroducerBillId: data.$data.xeroIntroducerBillId,
      xeroAgentBillId: data.$data.xeroAgentBillId,
      billingName: data.$data.billingName,
      billingAddress1: data.$data.billingAddress1,
      billingAddress2: data.$data.billingAddress2,
      billingCity: data.$data.billingCity,
      billingRegion: data.$data.billingRegion,
      billingPostalCode: data.$data.billingPostalCode,
      xeroParentAgentBillId: data.$data.xeroParentAgentBillId,
      createdBy: data.$data.createdBy,
    });

  const response = await request.result();

  const orderItems = data.orderItems;

  const order = Order.$inflate(response.order).first("Failed to create order");

  if (!order) {
    throw new Error("Failed to create order");
  }

  await orderItems.forEachAsync(async (orderItem) => {
    orderItem.order = order;
    orderItem.$persist();
    await window.Kernel.ActionBus2.portal.kinvault.order.CreateOrderItem(
      orderItem,
    );
  });

  const result = await window.Kernel.ActionBus2.portal.order.RecordOrder({
    message: order,
  });

  data.$delete({
    orderItems: true,
  });

  return result;
};
