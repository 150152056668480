import { Api } from "@/service/api.service";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { FileLog, UploadForm } from "@kinherit/sdk";

type DownloadUploadFormSubmissionMessage = {
  uploadForm: UploadForm;
  submission: FileLog;
};

interface DownloadUploadFormSubmissionResponse {
  [key: string]: never;
}

export const DownloadUploadFormSubmission = async (
  message: DownloadUploadFormSubmissionMessage,
): Promise<DownloadUploadFormSubmissionResponse> => {
  const { url } = await Api.resource(
    "portal",
    "/v2/portal/upload-form/{accountReferral}/submission/{submission}/download",
    {
      accountReferral: message.uploadForm.accountReferral,
      submission: message.submission,
    },
  )
    .method("get")

    .result();

  OpenAlertDialog({
    dialog: {
      title: "Download Ready",
      html: `
            <a href="${url}" target="_blank" class="button is-themed is-positive is-normal confirm-button is-block mb-2">Download File</a>
            <code>${message.submission.fileName}</code>
          `,
    },
    button: {
      ok: {
        text: "Close",
      },
      cancel: {
        show: false,
      },
    },
  });

  return {};
};
