// IntroducerFeePayment, IntroducerFeePaymentItem, XeroContact

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { IntroducerFeePayment } from "@kinherit/sdk/model/introducer-fee-payment";

export const DeleteIntroducerFeePayment = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/fees/payment/{introducerFeePayment}",
  method: "delete",
  parse: (introducerFeePayment: IntroducerFeePayment | string) => ({
    params: {
      introducerFeePayment,
    },
  }),
  after: ({ message }) => {
    IntroducerFeePayment.$delete(message);
  },
});
