<template>
  <p>
    <strong>Funds</strong><br />
    I would ask my Trustees to consider utilising funds of this Trust as
    follows:
  </p>
  <ol v-if="data" type="i">
    <li v-for="gift in data.cashGiftsMOW" :key="gift.id">
      to
      <span v-html="helpers.var(helpers.formatRecipient(gift, false))" />
      consider allocating the sum of
      <span v-html="helpers.var(gift.amount?.format)" />
      <span v-html="helpers.var(helpers.beneficiaryTheirConcern(gift))" />
      <span v-if="gift.onSecondDeath">
        only if my
        <span
          v-html="helpers.var(helpers.formatOfficer(data.partner, false))"
        />
        has predeceased me.
      </span>
    </li>
  </ol>
  <p>
    I would ask the Trustees to consider utilising the funds as listed above,
    once, and in the most tax efficient manner across all of my Trusts.
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FundsOutput",
  mixins: [WillBuilderService.mixin],
});
</script>
