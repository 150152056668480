import { Api } from "@/service/api.service";
import { Kintin, Person } from "@kinherit/sdk";

interface DownloadWillReviewMessage {
  content: string;
  person: Person;
  kintin: Kintin;
  withPassword: boolean;
}

interface DownloadWillReviewResponse {
  [key: string]: never;
}

export const DownloadWillReview = async (
  message: DownloadWillReviewMessage,
): Promise<DownloadWillReviewResponse> => {
  const { person, kintin, content } = message;

  const ref = kintin.ref;
  const name = person.profile.fullName;

  await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/call-script/will-review",
  )
    .method("post")
    .body({
      content,
      kintin: kintin.id,
      person: person.id,
      passwordProtected: message.withPassword,
    })

    .download({
      encoding: "application/pdf",
      fileName: `${ref} Will Review - ${name}`,
    });

  return {};
};
