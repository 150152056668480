<template>
  <Card
    v-if="$data.form"
    title="Assign Kinvault Access"
    class="user-details-assign-kintin-access"
  >
    <FormControls
      v-if="$data.form"
      :form="$data.form"
      :delete-dialog="deleteDialog"
      :mode="kintinAccess ? 'update' : 'create'"
      delete-button="Revoke"
      @cancel="visitUserDetails"
      @save="save"
      @remove="remove"
      @reset="reset"
      @show-validation="$data.form!.options.showValidation = true"
    />
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "UserDetailsAssignKintinAccessWrapper",
  "route": "UserDetailsAssignKintinAccess",
  "selector": ".user-details-assign-kintin-access"
}
</cypress-wrapper>

<script lang="ts">
import { UpdateUserKintinAccessFrom } from "@/module/admin.user/form/update-user-kintin-access.form";
import { UserDetailsMixin } from "@/module/admin.user/mixin/user-details.mixin";
import { AuthService } from "@/service/auth.service";
import FormControls from "@/shared/component/misc/FormControls.vue";
import { Card } from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { AlertDialogRequest } from "@kinherit/framework/global/dialog";
import { KintinAccess } from "@kinherit/sdk";
import { DeepPartial } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import {
  AdminUserDetailsAssignKintinAccessParams,
  AdminUserDetailsAssignKintinAccessRoute,
} from ".";

export default defineComponent({
  name: AdminUserDetailsAssignKintinAccessRoute,
  components: {
    FormControls,
    Card,
  },
  mixins: [
    AuthService.mixin({
      sessionRequired: true,
      permissions: {
        some: ["user:write", "kintin:write"],
      },
    }),
    UserDetailsMixin,
  ],
  beforeRouteLeave(): void {
    if (this.form) {
      this.form.localData.$delete?.();
    }
  },
  data: () => ({
    form: null as null | FormBuilder<() => Partial<KintinAccess>, string>,
  }),
  computed: {
    $localParams(): AdminUserDetailsAssignKintinAccessParams {
      return this.$route.params as any;
    },
    kintinAccess(): KintinAccess | null {
      if (!this.$localParams.kintinAccess) {
        return null;
      }

      return KintinAccess.$findOne(this.$localParams.kintinAccess);
    },
    deleteDialog(): DeepPartial<AlertDialogRequest> {
      return {
        dialog: {
          title: "Revoke Kinvault Access",
          message: `Are you sure you want to revoke '${this.user?.profile.fullName}' access to '${this.kintinAccess?.kintin.friendlyName}' kinvault?`,
        },
        button: {
          ok: {
            text: "Revoke",
          },
        },
      };
    },
  },
  async mounted(): Promise<void> {
    this.form = await UpdateUserKintinAccessFrom(
      this.kintinAccess ?? undefined,
    );
  },
  methods: {
    async save(data: KintinAccess): Promise<void> {
      if (!this.form || !this.user) {
        return;
      }

      data.user = this.user;

      if (this.kintinAccess) {
        // await window.Kernel.ActionBus.adminUser.kintinAccess.update({
        //   kintinAccess: this.kintinAccess,
        // });
        await this.$actionBus.kintinAccess.UpdateKintinAccess(data);
      } else {
        // await window.Kernel.ActionBus.adminUser.kintinAccess.create({
        //   user: this.user,
        //   kintin: data.kintin as KintinAccess["kintin"],
        //   accessType: data.accessType as KintinAccess["accessType"],
        // });
        await this.$actionBus.kintinAccess.CreateKintinAccess(data);
      }

      this.visitUserDetails();
    },
    async remove(): Promise<void> {
      if (!this.kintinAccess) {
        return;
      }

      // await window.Kernel.ActionBus.adminUser.kintinAccess.delete({
      //   kintinAccess: this.kintinAccess,
      // });
      await this.$actionBus.kintinAccess.DeleteKintinAccess(this.kintinAccess);

      this.visitUserDetails();
    },
    reset(): void {
      if (!this.form) {
        return;
      }

      if (this.kintinAccess) {
        this.form.localData.$restore?.();
      } else {
        this.form.resetData();
      }
    },
  },
});
</script>
