<template>
  <CreditNotesPage
    v-if="query"
    :has-write-access="hasWriteAccess"
    :query="query"
    @create-credit-note="$emit('create-credit-note')"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsCreditNotesWrapper",
  "route": "KintinDetailsCreditNotes",
  "extends": {
    "name": "CreditNotesPageWrapper",
    "path": "@/shared/component/credit-note/CreditNotesPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { AuthService } from "@/service/auth.service";
import CreditNotesPage from "@/shared/component/credit-note/CreditNotesPage.vue";
import { Equal } from "@kinherit/orm";
import { CreditNote, QueryMask } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KintinDetailsCreditNotesRoute } from ".";

export default defineComponent({
  name: KintinDetailsCreditNotesRoute,
  components: {
    CreditNotesPage,
  },
  mixins: [KinvaultKintinDetailsMixin, AuthService.mixin()],
  emits: ["create-credit-note"],
  data: () => ({
    query: null as null | any,
  }),
  mounted(): void {
    this.query = {
      relatedKintin: {
        id: Equal(this.$params.kintin),
      },
    } satisfies QueryMask<typeof CreditNote>;
  },
});
</script>
