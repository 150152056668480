import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateBrandedKinvaultMembership } from "./create-branded-kinvault-membership.action";
import { DeleteBrandedKinvaultMembership } from "./delete-branded-kinvault-membership.action";
import { RecordBrandedKinvaultMembership } from "./record-branded-kinvault-membership.action";
import { UpdateBrandedKinvaultMembership } from "./update-branded-kinvault-membership.action";

export const Portal2AdminUserBrandedKinvaultMembership = DefineActionBus({
  name: "portal-admin-user-branded-kinvault-membership",
  actions: {
    CreateBrandedKinvaultMembership,
    DeleteBrandedKinvaultMembership,
    RecordBrandedKinvaultMembership,
    UpdateBrandedKinvaultMembership,
  },
});
