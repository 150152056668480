// Kintin, Profile, AccountReferral, AccountReferralCode, User

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const ReadKintin = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvaults/escalated",
  method: "get",
  rootModel: Kintin,
  parse: () => ({}),
});
