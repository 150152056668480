var $ = Object.defineProperty;
var l = (e, r) => $(e, "name", { value: r, configurable: !0 });
import { KernelModes as b } from "../core/kernel-mode.js";
import { ThemeAlign as g } from "../theme/prop/align.js";
import { ThemeBackgroundColor as h } from "../theme/prop/background-color.js";
import { ThemeColor as C } from "../theme/prop/color.js";
import { ThemeDirection as v } from "../theme/prop/direction.js";
import { ThemeFontAlign as j } from "../theme/prop/font-align.js";
import { ThemeFontColor as O } from "../theme/prop/font-color.js";
import { ThemeFontSize as T } from "../theme/prop/font-size.js";
import { ThemeMargin as A } from "../theme/prop/margin.js";
import { ThemePadding as w } from "../theme/prop/padding.js";
import { ThemeSide as z } from "../theme/prop/side.js";
import { ThemeSize as u } from "../theme/prop/size.js";
import { defineComponent as L } from "vue";
const M = [
  ...Object.values(C),
  ...Object.values(u),
  ...Object.values(u),
  ...Object.values(g),
  ...Object.values(h),
  ...Object.values(v),
  ...Object.values(j),
  ...Object.values(O),
  ...Object.values(T),
  ...Object.values(A),
  ...Object.values(w),
  ...Object.values(z),
  "mt-0",
  "mt-1",
  "mt-2",
  "mt-3",
  "mt-4",
  "mt-5",
  "mt-6",
  "mt-7",
  "mt-8",
  "mb-0",
  "mb-1",
  "mb-2",
  "mb-3",
  "mb-4",
  "mb-5",
  "mb-6",
  "mb-7",
  "mb-8",
  "ml-0",
  "ml-1",
  "ml-2",
  "ml-3",
  "ml-4",
  "ml-5",
  "ml-6",
  "ml-7",
  "ml-8",
  "mr-0",
  "mr-1",
  "mr-2",
  "mr-3",
  "mr-4",
  "mr-5",
  "mr-6",
  "mr-7",
  "mr-8",
  "mx-0",
  "mx-1",
  "mx-2",
  "mx-3",
  "mx-4",
  "mx-5",
  "mx-6",
  "mx-7",
  "mx-8",
  "my-0",
  "my-1",
  "my-2",
  "my-3",
  "my-4",
  "my-5",
  "my-6",
  "my-7",
  "my-8",
  "pt-0",
  "pt-1",
  "pt-2",
  "pt-3",
  "pt-4",
  "pt-5",
  "pt-6",
  "pt-7",
  "pt-8",
  "pb-0",
  "pb-1",
  "pb-2",
  "pb-3",
  "pb-4",
  "pb-5",
  "pb-6",
  "pb-7",
  "pb-8",
  "pl-0",
  "pl-1",
  "pl-2",
  "pl-3",
  "pl-4",
  "pl-5",
  "pl-6",
  "pl-7",
  "pl-8",
  "pr-0",
  "pr-1",
  "pr-2",
  "pr-3",
  "pr-4",
  "pr-5",
  "pr-6",
  "pr-7",
  "pr-8",
  "px-0",
  "px-1",
  "px-2",
  "px-3",
  "px-4",
  "px-5",
  "px-6",
  "px-7",
  "px-8",
  "py-0",
  "py-1",
  "py-2",
  "py-3",
  "py-4",
  "py-5",
  "py-6",
  "py-7",
  "py-8",
  "func-readonly"
], i = /* @__PURE__ */ l((e) => e.toString().replace(/[^a-zA-Z0-9]/g, " ").replace(/([a-z])([A-Z])/g, "$1 $2").replace(/\s+/g, " ").toLowerCase().replace(/ /g, "-").replace(
  /-[a-z]/g,
  (r) => `${r.toUpperCase()}`.replace("-", "")
).replaceAll("-", " "), "CleanLabel"), S = /* @__PURE__ */ l(({
  ctx: e,
  labelField: r,
  refField: n
}) => {
  var y, c;
  if (b.Live === ((y = window.Kernel) == null ? void 0 : y.Mode))
    return {};
  const t = (c = e.$attrs["cy-ref"] ?? e.$attrs.reference ?? e.$attrs.class ?? null) == null ? void 0 : c.split(" ").filter((s) => !M.includes(s)).join(" ");
  let p = "";
  const a = typeof r == "function" ? r(e) : null;
  "cyLabel" in e.$props && e.$props.cyLabel !== "" ? p = e.$props.cyLabel : r && typeof r == "string" && r in e.$props ? p = e.$props[r] : r && Array.isArray(r) ? r.forEach((s) => {
    p === "" && s in e.$props && typeof e.$props[s] == "string" && (p = e.$props[s]);
  }) : a !== null ? p = a : "cy-label" in e.$attrs && e.$attrs["cy-label"] !== "" && (p = e.$attrs["cy-label"]), p && (p = i(p));
  const o = {};
  let m = e.$options.name;
  m != null && m.endsWith("Component") && (m = m.substring(
    0,
    m.length - 9
  )), m && (o["cy-component"] = m);
  const f = typeof n == "function" ? n(e) : null;
  return f !== null ? o["cy-ref"] = f : t && (o["cy-ref"] = t), p ? o["cy-label"] = p : t ? o["cy-label"] = i(t) : e.$options.name && (o["cy-label"] = i(m)), o;
}, "CypressHelper"), d = /* @__PURE__ */ l(({
  ref: e,
  label: r
}) => {
  var t;
  if (b.Live === ((t = window.Kernel) == null ? void 0 : t.Mode))
    return {};
  const n = {
    "cy-component": "Element"
  };
  return e && (n["cy-ref"] = e), r && (n["cy-label"] = r.toCamelCase()), n;
}, "CypressElement"), I = L({
  computed: {
    $cypress() {
      return {
        CypressElement: d,
        CypressHelper: S
      };
    }
  }
});
export {
  S as CypressHelper,
  I as CypressMixin
};
