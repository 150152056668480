var n = Object.defineProperty;
var r = (t, e) => n(t, "name", { value: e, configurable: !0 });
import a from "../../_vue/component.input/button/button.js";
import i from "../../_vue/component.input/date-field/date-field.js";
import s from "../../_vue/component.input/number-field/number-field.js";
import { TranslationMixin as o } from "../../component.mixin/translation.mixin.js";
import { defineComponent as d } from "vue";
import u from "../../_vue/component.display/progress/progress.js";
const p = d({
  name: "DateRangeProcess",
  components: { NumberField: s, Button: a, Progress: u, DateField: i },
  mixins: [
    o({
      defaults: {
        run: /* @__PURE__ */ r((t, e) => t === void 0 || e === void 0 ? "Run" : `Run (${t}/${e})`, "run"),
        pause: "Pause",
        resume: "Resume",
        reset: "Reset",
        start: "Start",
        interval: "Interval (In days)",
        startDate: "Start Date",
        endDate: "End Date"
      }
    })
  ],
  props: {
    callback: {
      type: Function,
      required: !0
    }
  },
  data: /* @__PURE__ */ r(() => ({
    form: {
      dateRange: {
        start: null,
        end: null
      },
      interval: null
    },
    isRunning: !1,
    currentInterval: 0
  }), "data"),
  computed: {
    isValid() {
      return ![
        this.form.dateRange.start,
        this.form.dateRange.end,
        this.form.interval
      ].includes(null);
    },
    numberOfIntervals() {
      if (!this.$data.form.dateRange.start || !this.$data.form.dateRange.end || !this.form.interval)
        return 0;
      const t = this.$data.form.dateRange.end.difference(
        this.$data.form.dateRange.start
      );
      return Math.ceil(t.hours / (24 * this.form.interval));
    }
  },
  methods: {
    start() {
      this.isRunning = !0, this.run();
    },
    stop() {
      this.isRunning = !1;
    },
    async run() {
      if (this.form.dateRange.start === null || this.form.dateRange.end === null || this.form.interval === null || !this.isRunning)
        return;
      if (this.currentInterval >= this.numberOfIntervals) {
        this.stop();
        return;
      }
      const t = this.form.dateRange.start.clone().add({
        day: this.currentInterval * this.form.interval
      }), e = t.clone().add({
        day: this.form.interval
      });
      await this.callback(t, e), this.currentInterval++, this.run();
    }
  }
});
export {
  p as _
};
