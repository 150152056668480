<template>
  <div v-if="lead" class="lead-details-ifa-call-script-2025">
    <Tabs
      v-if="lead.secondaryProfile"
      v-model:tab="selectedTab"
      :config="[
        { label: lead.primaryProfile.fullName as string },
        { label: lead.secondaryProfile?.fullName as string },
      ]"
      class="mb-4"
    />
    <PageHeader text="Call Script (2025)" htag="h2" />
    <CallScriptSection title="Pre-call Notes">
      Tone: Professional and warm/attentive/engaged … not too friendly or
      familiar. <br /><br />
      <b class="is-block">Do’s:</b>
      - Be confident in approach …
      <br />- Take time to listen to them / be interested (eg in the names of
      their kids) <br />- SLOW DOWN … remember there are older people on the
      other end… <br /><br />
      <b class="is-block"> Don’ts: </b>
      - Apologise, or say “sorry” … we’re not delivering bad news
      <br />- Use the word “simple” … every client deserves careful attention /
      … is unique <br />- Never say “free” … if asked, say “you only pay when
      you decide what you want to do” as a much better way to give reassurance.
    </CallScriptSection>
    <CallScriptSection title="Initial Answer">
      <b>Hello is this {{ leadTitle }} {{ leadLastName }}?</b><br />
      <em>[Pause and wait for confirmation: avoid bland filler phrases]</em
      ><br />
      <br />
      <b>
        My name is {{ firstName }}, and I’m one of the Legal Assistants at
        Kinherit, the will-writing firm.
      </b>
      <span v-if="introducerContactFullName || referralSource">
        <b>
          I was given your name by {{ introducerContactFullName }} at
          {{ referralSource }}. He said he’d spoken to you about your Will –
          does that ring a bell?
        </b>
        <br />
        <em>[Pause and wait for reply]</em>
      </span>
      <span v-else>
        <b
          >It looks like you’ve [completed the online form] / [ been introduced
          to us].
        </b>
      </span>
      <br />
      <br />
      <b>
        I was hoping to book you in for a call with one of our estate planners.
        Do you have a couple of minutes now to take some details? </b
      ><br />
      <em>[Short pause and wait for reply]</em><br />
      <em
        >[IF NO – take details / etc. <b>Be aware</b> if they’re just not up for
        a call… try to identify the block and deal with it in this call if
        possible….</em
      >
      <br />
      <br />
      <b>Great.</b>
      <br />
      <br />
      <b>First things first - can I ask what is the best name to call you?</b>
      <br />
      <em
        >[Be aware it could be {{ leadFirstName }}, or {{ leadTitle }}
        {{ leadLastName }}<br />Check if a Tom or Thomas etc<br />Check
        pronounciation]</em
      >
      <br />
      <br />
      <b>Great</b>
      <br /><br />
      <b>
        And is there anything that makes your planning urgent and time-critical?
      </b>
      <br /><em
        >[Pause and wait for a reply, and **Add Note if Urgent** and say you’ll
        be sure to prioritise.]</em
      >
    </CallScriptSection>
    <CallScriptSection title="About Us">
      <b v-if="introducerContactFirstName"
        >As mentioned, {{ introducerContactFirstName }} you’d benefit from a
        call
        <span v-if="estatePlannerFirstName"
          >with {{ estatePlannerFirstName }}</span
        >
        about your Will and estate planning.</b
      >
      <b v-else>
        Hopefully today we can discuss your situation and set you up with a call
        with
        <span v-if="estatePlannerFirstName">{{ estatePlannerFirstName }}</span
        ><span v-else>one of our Estate Planners</span>.
      </b>
      <br />
      <br />
      <b
        >{{ estatePlannerFirstName }} is one of our STEP qualified estate
        planners, so you can ask him pretty much anything about your situation,
        and he will be able to help.</b
      >
      <br /><br />
      <b
        >They will also explain the best ways to protect your wishes and help
        your family.
      </b>
      <br /><br />
      <b>
        We provided an advised process, which means you get advice that is
        specific to your personal situation. So like you receive from
        {{ introducerContactFirstName }} .
      </b>
      <br /><br />
      <b>Does this all make sense?</b>
      <em>[Take response if forthcoming, else continue…]</em>
    </CallScriptSection>
    <CallScriptSection title="Book Call">
      <b> To book you in, I’ve a couple of questions: </b>
      <br />
      <br />
      <em>[If New Will / LPA ]</em><br />
      <b>
        You were referred to us for a [new Will] - do you have an existing
        [Will] in place currently?</b
      >
      <br /><em
        >[Lead with Will or LPA mentioned by introducer … only raise the other
        if client raises it]</em
      >
      <br />
      <br />
      <em>[ If Will Review]</em><br />
      <b>
        You were referred to us for a Will Review - are you looking to just
        review your current Will or do you know you want to start from
        scratch?</b
      >
      <br />
      <br />

      <b
        >I can see [one slot on][+1-2 days out] and another slot on [+2-4 days
        out]. Could either of those work?</b
      >
      <br /><em>[Pause and wait for reply]</em> <br /><br />
      <b>Our preferred method of meeting is Zoom - does that work for you?</b>
      <br /><em>[Pause and wait for reply]</em> <br /><br />
      <b
        >I’ll send a confirmation of all this to your email... can I
        double-check the address I have is right: {{ email }}</b
      >
      <br /><em>[Pause and wait for reply]</em> <br /><br />

      <b>OK thanks, that is now in their diary.</b>
      <br /><br />
      <b
        >If you need to change the appointment if you could possibly give us a
        couple of days’ notice that’d be great. <br /><br />
        And to confirm also – our client calls and Zoom meetings are recorded
        and form part of your legal records, to protect you and your estate.
        It’s just about being very thorough.
      </b>
    </CallScriptSection>

    <CallScriptSection title="Follow on Questions">
      <b>If you’ve two more minutes, I’ve a couple of other questions:</b>
      <br />
      <em>[Short pause]</em>
      <br /><br />
      <b>- Is the planning for you alone, or you and a partner? </b>
      <br />
      - <em>[IF JOINT]</em> <b>And can I take your partner’s name?</b
      ><em>[Add note, repeat the name.]</em> <br />
      - <em>[If unclear] - Can I check your marital status?</em> <br />
      <b>- And it’s a [Will/LPA] you’re principally interested in?</b
      ><em>[Add note]</em>
      <b v-if="!introducerContactFullName"
        ><br />- [IF website lead - can I double-check how you heard about
        Kinherit?]</b
      >
      <br />
      <b
        >- Are you a homeowner? <br />
        - Do you own any other properties? <br />
        - Are you a business owner or own any business assets such as shares?
        <br />
        - Do you own any Agricultural assets? <br />
        - Lastly, Do you have children? And are any of these under the age of
        18?
      </b>
      <em>[can I get their names?]</em><br />
      <em>[If yes get note, repeat the all the names.]</em>
      <br /><br />
      <b>
        Thanks; {{ estatePlannerFirstName }} will go through everything in much
        more detail, but it just helps if he has some basic information.
      </b>
      <br /><br />
    </CallScriptSection>
    <CallScriptSection title="Set Expectations">
      <em>[Remember – pace this … don’t rush]</em>
      <br /><br />
      <b
        >After this call, I’ll send you an email that will include our Letter of
        Engagement. This says what you can expect from us, and some example
        pricing. Do let me know if it raises anything. <br /><br />
        Your initial call with {{ estatePlannerFirstName }} is likely to be
        about 20-minutes, and is very much about exploring what you may want to
        achieve.

        <br /><br />
        They'll explain what happens after that, but everything we do is about
        giving you the best possible protection and really helping your family.

        <br /><br />
        Our support even continues until death or incapacitation – so it is very
        comprehensive.

        <br /><br />
        I have everything I need, but do you have any questions for me?
      </b>
      <br /><em>[Pause and wait for a reply]</em>
      <br />
      <br />
      <b
        >Fantastic – you should get a confirmation email shortly – [it includes
        the Zoom link to follow at the time of your appointment]. <br /><br />
        Do let us know if something comes up, but otherwise
        {{ estatePlannerFirstName }} will speak to you on [Date/time].
        <br />
        <br />Thank you</b
      >
    </CallScriptSection>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "LeadDetailsIfaCallScriptWrapper",
  "route": "LeadDetailsIfaCallScript",
  "selector": ".lead-details-ifa-call-script"
}
</cypress-wrapper>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { IntroducerContact, Profile } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { LeadDetailsIfaCallScript2025Route } from ".";
import { LeadDetailsMixin } from "../../../mixin/lead-details.mixin";

import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import Tabs from "@kinherit/framework/component.display/tabs";

export default defineComponent({
  name: LeadDetailsIfaCallScript2025Route,
  components: { PageHeader, CallScriptSection, Tabs },
  mixins: [LeadDetailsMixin, StyleService.mixin, AuthService.mixin()],
  data: () => ({
    selectedTab: 0,
  }),
  computed: {
    firstName(): string {
      return this.$auth.loggedInUser?.profile.firstName || "[FIRST NAME]";
    },
    fullName(): string {
      return this.$auth.loggedInUser?.profile.fullName || "[Full NAME]";
    },
    leadTitle(): string {
      return this.profile?.title?.text || "[Mr/Mrs/Ms]";
    },
    leadFirstName(): string {
      return this.profile?.firstName || "[FIRST NAME]";
    },
    leadLastName(): string {
      return this.profile?.lastName || "[LAST NAME]";
    },
    leadFullName(): string {
      return this.profile?.fullName || "[Full NAME]";
    },
    email(): string {
      return this.profile?.emails.first()?.email || "[EMAIL]";
    },
    estatePlannerProfile(): Profile | null {
      return this.lead?.ownedBy?.profile || null;
    },
    estatePlannerFirstName(): string {
      return this.estatePlannerProfile?.firstName || "[EP FIRST NAME]";
    },
    estatePlannerFullName(): string {
      return this.estatePlannerProfile?.fullName || "[EP FULL NAME]";
    },
    introducerContact(): IntroducerContact | null {
      return this.lead?.referral?.contact || null;
    },
    introducerContactFirstName(): string {
      return (
        this.introducerContact?.profile.firstName || "[INTRODUCER FIRST NAME]"
      );
    },
    introducerContactFullName(): string {
      return (
        this.introducerContact?.profile.fullName || "[INTRODUCER FULL NAME]"
      );
    },
    referralSource(): string {
      return this.lead?.referral?.referralCode?.name ?? "[REFERRAL SOURCE]";
    },
    profile(): Profile | undefined {
      if (this.selectedTab === 1) {
        return this.lead?.secondaryProfile ?? undefined;
      }

      return this.lead?.primaryProfile;
    },
  },
});
</script>
