import { RecordBrandedKinvaultCta } from "@/action-bus/portal/admin/branded-kinvaults/cta/record-branded-kinvault-cta.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateBrandedKinvaultCta } from "./create-branded-kinvault-cta.action";
import { DeleteBrandedKinvaultCta } from "./delete-branded-kinvault-cta.action";
import { ReadBrandedKinvaultCta } from "./read-branded-kinvault-cta.action";
import { UpdateBrandedKinvaultCta } from "./update-branded-kinvault-cta.action";

export const Portal2AdminBrandedKinvaultCta = DefineActionBus({
  name: "portal-admin-branded-kinvault-cta",
  actions: {
    ReadBrandedKinvaultCta,
    UpdateBrandedKinvaultCta,
    CreateBrandedKinvaultCta,
    DeleteBrandedKinvaultCta,
    RecordBrandedKinvaultCta,
  },
});
