// IntroducerContact, Profile, IntroducerCompany, Address, EmailAddress, PhoneNumber, Website, IntroducerNote, Note, ContactPartnerPortalPermissions

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";

export const RecordIntroducerContact = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/contact/{introducerContact}",
  method: "get",
  rootModel: IntroducerContact,
  parse: (introducerContact: IntroducerContact | string) => ({
    params: {
      introducerContact,
    },
  }),
});
