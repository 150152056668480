var p = Object.defineProperty;
var f = (o, e) => p(o, "name", { value: e, configurable: !0 });
import { _ as k } from "../../../component.layout/dialog/dialog.vue_vue_type_script_lang.js";
import { resolveComponent as b, createElementBlock as a, openBlock as t, mergeProps as $, createCommentVNode as l, createElementVNode as r, withModifiers as B, createVNode as C, toDisplayString as i, renderSlot as v, createBlock as d, withCtx as u, createTextVNode as c } from "vue";
import { b as m } from "../../../_assets/component.layout/dialog/dialog.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as y } from "../../../_plugins/_plugin-vue_export-helper.js";
const g = { class: "modal-card" }, w = { class: "modal-card-head" }, h = ["textContent"], L = { class: "modal-card-body" }, N = {
  key: 0,
  class: "modal-card-foot buttons is-right"
};
function V(o, e, D, S, j, E) {
  const n = b("Button");
  return t(), a("div", $(o.$data.$cypress, {
    class: ["modal dialog-component", {
      "is-active": o.show,
      [`is-${o.type}`]: !0,
      "is-above-loading": o.isAboveLoading,
      "fill-screen": o.fillScreen && o.type === "fullscreen"
    }]
  }), [
    o.showModalBackground ? (t(), a("div", {
      key: 0,
      class: "modal-background",
      onClick: e[0] || (e[0] = B((s) => o.$emit("click:background"), ["self"]))
    })) : l("", !0),
    r("div", g, [
      r("header", w, [
        r("div", {
          class: "modal-card-title",
          textContent: i(o.title)
        }, null, 8, h),
        C(n, {
          "icon-left": "Close",
          "aria-label": "Close",
          color: "is-plain",
          onClick: e[1] || (e[1] = (s) => o.$emit("reject"))
        })
      ]),
      r("section", L, [
        v(o.$slots, "default")
      ]),
      o.showFooter ? (t(), a("footer", N, [
        o.showLeftButton ? (t(), d(n, {
          key: 0,
          class: "left-button",
          color: o.leftButtonColor,
          onClick: e[2] || (e[2] = (s) => o.$emit("click:left"))
        }, {
          default: u(() => [
            c(i(o.leftButtonLabel), 1)
          ]),
          _: 1
        }, 8, ["color"])) : l("", !0),
        o.showCancelButton ? (t(), d(n, {
          key: 1,
          class: "cancel-button",
          color: o.cancelButtonColor,
          onClick: e[3] || (e[3] = (s) => o.$emit("reject"))
        }, {
          default: u(() => [
            c(i(o.cancelButtonLabel), 1)
          ]),
          _: 1
        }, 8, ["color"])) : l("", !0),
        o.showOkButton ? (t(), d(n, {
          key: 2,
          "is-disabled": o.okButtonDisabled,
          class: "confirm-button",
          color: o.okButtonColor,
          onClick: e[4] || (e[4] = (s) => o.$emit("resolve"))
        }, {
          default: u(() => [
            c(i(o.okButtonLabel), 1)
          ]),
          _: 1
        }, 8, ["is-disabled", "color"])) : l("", !0)
      ])) : l("", !0)
    ])
  ], 16);
}
f(V, "_sfc_render");
typeof m == "function" && m(k);
const T = /* @__PURE__ */ y(k, [["render", V]]);
export {
  T as default
};
