<template>
  <div v-if="kintin">
    <CallScriptSection title="Pre-call">
      Prepare in advance what you will say in your opening sections to put them
      at ease (based on note or otherwise). Suggest you focus on pre-preparing
      <span class="strong">one</span> opening line and
      <span class="strong">two</span> follow-up lines. Ie how the call was set
      up, followed by a closed statement ending “… get a Will sorted”, and
      finally a more open line that encourages engagement.
    </CallScriptSection>
    <CallScriptSection title="Preamble">
      <b>
        Hello there. Is that
        {{ primaryProfile?.fullName }}
        {{ isJoint ? ` / ${secondaryProfile?.fullName}` : "" }}?
      </b>

      <br /><em>[Pause]</em> <br /><br />
      <b>
        It's {{ $auth.loggedInUser?.profile.firstName }} calling from Kinherit.
      </b>
      <br /><br />
      <b
        >I have a call booked with you to chat about potentially getting a Will
        &amp; planning set up for you? Is this still a good time?</b
      >
      <br /><em>[Pause]</em>

      <span v-if="isJoint">
        <br /><br />
        <b> And is {{ secondarySalutation }} there too? </b>

        <RadioField
          class="mt-0"
          :options="['No', 'Yes']"
          direction="is-horizontal"
          :value="null === person2Present ? null : person2Present ? 1 : 0"
          is-button
          is-reversed
          @update:value="saveCallscript"
        />

        <b class="mt-4">
          That’s fine I assume they are aware of our call today, I will need to
          speak with them at some point, but I can always pick that up at
          another time
        </b>
      </span>

      <b>
        For privacy reasons can I check, Is anyone else able to overhear the
        conversation?
      </b>
      <br />
      <em>[If other than partner, make note]</em>
      <br /><br />
      <b>
        Thank you. To protect you and your plan all of our calls are recorded is
        that ok?
      </b>
      <br />
      <br />

      <ConfirmCheck
        v-if="isJoint"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="2.1.2"
        :kintin="kintin"
        @update="updateCheckValue"
      />

      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="2.1.3"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import RadioField from "@kinherit/framework/component.input/radio-field";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IntroTab",
  components: { CallScriptSection, ConfirmCheck, RadioField },
  mixins: [AuthService.mixin(), KinvaultKintinDetailsCallScriptMixin],
  emits: ["save-callscript"],
  data: () => ({
    person2Present: null as boolean | null,
    tab: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
  methods: {
    async saveCallscript(value: number): Promise<void> {
      this.callScript.person2Present = value === 1;

      this.person2Present = value === 1;

      this.$emit("save-callscript");
    },
  },
});
</script>
