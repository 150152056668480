var d = Object.defineProperty;
var D = (e, o) => d(e, "name", { value: o, configurable: !0 });
import { OpenComponentDialog as S } from "../../component.config/component-dialog.js";
import T from "../../_vue/component.display/calendar/calendar.js";
import f from "../../_vue/component.form/field/field.js";
import g from "../../_vue/component.input/base-input-field/base-input-field.js";
import w from "../../_vue/component.input/button/button.js";
import { S as F } from "../../_assets/component.input/date-range-field/date-range-field.module.js";
import { DateGroup as s, DateItems as a } from "./types.js";
import b from "../../_vue/component.input/select-field/select-field.js";
import { FieldMixin as M } from "../../component.mixin/field-mixin.js";
import { TranslationMixin as y } from "../../component.mixin/translation.mixin.js";
import { CypressHelper as v } from "../../cypress/component-helpers.js";
import { DateTime as t } from "@kinherit/ts-common/dto/date-time";
import { defineComponent as C, ref as Q } from "vue";
const R = C({
  name: "DateRangeField",
  components: {
    BaseInputField: g,
    SelectField: b,
    Calendar: T,
    Button: w,
    Field: f
  },
  mixins: [
    M(),
    y({
      defaults: {
        fromDate: "From Date",
        toDate: "To Date",
        cancel: "Cancel",
        update: "Update",
        dateRangeGroupLabels: {
          [s.Recent]: "Recent",
          [s.ToDate]: "To Date",
          [s.LastXMonths]: "Last X Months",
          [s.Months]: "Months",
          [s.Quarters]: "Quarters"
        }
      }
    })
  ],
  props: {
    value: {
      type: Array,
      default: null
    },
    customDateRanges: {
      type: Array,
      default: null
    }
  },
  emits: ["submit"],
  setup() {
    return {
      input: Q(null)
    };
  },
  data: /* @__PURE__ */ D((e) => ({
    firstCalendar: {
      year: new t().getDate().getFullYear(),
      month: new t().getDate().getMonth()
    },
    showCalendarDropdown: !1,
    selectFrom: null,
    selectTo: null,
    hover: null,
    inputFieldText: "",
    selectValue: void 0,
    dateRangeGroups: [
      {
        id: s.Recent,
        items: []
      },
      {
        id: s.ToDate,
        items: []
      },
      {
        id: s.LastXMonths,
        items: []
      },
      {
        id: s.Months,
        items: []
      },
      {
        id: s.Quarters,
        items: []
      }
    ],
    dateRanges: [
      {
        id: a.Today,
        label: "Today",
        group: s.Recent
      },
      {
        id: a.Yesterday,
        label: "Yesterday",
        group: s.Recent
      },
      {
        id: a.WTD,
        label: "WTD",
        group: s.ToDate
      },
      {
        id: a.MTD,
        label: "MTD",
        group: s.ToDate
      },
      {
        id: a.QTD,
        label: "QTD",
        type: "quarter",
        group: s.ToDate
      },
      {
        id: a.YTD,
        label: "YTD",
        group: s.ToDate
      },
      // { id: DateItems.Last7Days, label: "-7 Days", group: DateGroup.Days },
      // {
      //   id: DateItems.Last28Days,
      //   label: "-28 Days",
      //   group: DateGroup.Days,
      // },
      // {
      //   id: DateItems.Last90Days,
      //   label: "-90 Days",
      //   group: DateGroup.Days,
      // },
      {
        id: a.Last3Months,
        label: "-3M",
        group: s.LastXMonths
      },
      {
        id: a.Last6Months,
        label: "-6M",
        group: s.LastXMonths
      },
      {
        id: a.Last12Months,
        label: "-12M",
        group: s.LastXMonths
      },
      {
        id: a.Last15Months,
        label: "-15M",
        group: s.LastXMonths
      },
      // { id: DateItems.LastWeek, label: "-1 Week", group: DateGroup.Weeks },
      // { id: DateItems.ThisWeek, label: "This Week", group: DateGroup.Weeks },
      // { id: DateItems.NextWeek, label: "+1 Week", group: DateGroup.Weeks },
      { id: a.LastMonth, label: "-1 Month", group: s.Months },
      { id: a.ThisMonth, label: "This month", group: s.Months },
      { id: a.NextMonth, label: "+1 Month", group: s.Months },
      {
        id: a.Quarter1,
        label: "Q1",
        type: "quarter",
        group: s.Quarters
      },
      {
        id: a.Quarter2,
        label: "Q2",
        type: "quarter",
        group: s.Quarters
      },
      {
        id: a.Quarter3,
        label: "Q3",
        type: "quarter",
        group: s.Quarters
      },
      {
        id: a.Quarter4,
        label: "Q4",
        type: "quarter",
        group: s.Quarters
      },
      {
        id: a.Since2022,
        label: "All",
        type: "quarter",
        group: s.Quarters
      }
    ],
    $cypress: v({ ctx: e })
  }), "data"),
  computed: {
    compDateRangeSelected() {
      return !!this.compSelectFromDate && !!this.compSelectToDate;
    },
    compFromDate() {
      var e;
      return ((e = this.value) == null ? void 0 : e.first()) ?? null;
    },
    compToDate() {
      var e;
      return ((e = this.value) == null ? void 0 : e.last()) ?? null;
    },
    compHasValue() {
      return this.compFromDate !== null || this.compToDate !== null;
    },
    compSelectFromDate: {
      get() {
        return this.selectFrom;
      },
      set(e) {
        this.selectFrom = e !== null ? e == null ? void 0 : e.setTime(0, 0, 0) : null;
      }
    },
    compSelectToDate: {
      get() {
        return this.selectTo;
      },
      set(e) {
        this.selectTo = e !== null ? e.setTime(23, 59, 59) : null;
      }
    },
    compFromDateSelectHover() {
      return this.compSelectFromDate ?? this.hover;
    },
    compToDateSelectHover() {
      return this.compSelectToDate ?? this.hover;
    },
    compDateRangeGroups() {
      return this.dateRangeGroups;
    },
    compDateRanges() {
      return this.customDateRanges === null ? this.dateRanges : this.customDateRanges.map((e) => typeof e == "string" ? this.dateRanges.find((o) => o.id === a[e]) : e);
    },
    compNumberOfDays() {
      var r, l;
      if (this.compSelectToDate === null || this.compSelectFromDate === null)
        return null;
      const e = (r = this.compSelectFromDate) == null ? void 0 : r.clone().setTime(0, 0, 0);
      return ((l = this.compSelectToDate) == null ? void 0 : l.clone().setTime(0, 0, 0)).add(1, "day").since({
        datetime: e,
        format: "day",
        short: !0
      }).trim();
    },
    compNextMonth: {
      get() {
        return this.firstCalendar.month === 11 ? 0 : this.firstCalendar.month + 1;
      },
      set(e) {
        this.firstCalendar.month = e === 0 ? 11 : e - 1;
      }
    },
    compNextYear: {
      get() {
        return this.firstCalendar.month === 11 ? this.firstCalendar.year + 1 : this.firstCalendar.year;
      },
      set(e) {
        this.firstCalendar.year = this.firstCalendar.month === 11 ? e - 1 : e;
      }
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("date-range-field", F), this.dateRanges.sort((e, o) => e.fromDate && o.fromDate ? e.fromDate.timestamp - o.fromDate.timestamp : 0), this.dateRanges.forEach((e, o) => {
      e.order = o;
    }), this.dateRanges.forEach((e) => {
      if (e.group) {
        const o = this.dateRangeGroups.find(
          (i) => i.id === e.group
          // Ensure consistent type comparison
        );
        o && o.items.push(e);
      }
    });
  },
  mounted() {
    this.setIntialSelectedDates(), this.updateValue(!1), this.isComponentDialog || window.Kernel.KeyListener.addListener({
      code: "Escape",
      listener: this.closeDropDown
    });
  },
  beforeUnmount() {
    this.isComponentDialog || window.Kernel.KeyListener.removeListener({
      code: "Escape",
      listener: this.closeDropDown
    });
  },
  methods: {
    btnUpdate() {
      this.updateValue(!0), this.closeDropDown(), this.$emit("submit");
    },
    clearDateRange() {
      this.compSelectFromDate = null, this.compSelectToDate = null, this.updateValue(!0);
    },
    setIntialSelectedDates() {
      Array.isArray(this.computedValue) && (this.compSelectFromDate === null && (this.compSelectFromDate = this.value.first() ?? null), this.compSelectToDate === null && (this.compSelectToDate = this.value.last() ?? null));
    },
    async showCalendars(e) {
      if (this.setIntialSelectedDates(), this.focus(e), !this.isComponentDialog)
        if (console.isTouchScreen(!0) && !this.fieldMixin.showingComponentDialog)
          try {
            this.fieldMixin.showingComponentDialog = !0;
            const o = await S({
              component: {
                component: R,
                props: {
                  ...this.$props,
                  isComponentDialog: !0
                }
              },
              dialog: {
                type: "width-auto",
                showFooter: !1
              },
              value: this.computedValue
            });
            this.compSelectFromDate = (o == null ? void 0 : o.first()) ?? null, this.compSelectToDate = (o == null ? void 0 : o.last()) ?? null, this.updateValue(!0), this.fieldMixin.showingComponentDialog = !1;
          } catch {
            this.fieldMixin.showingComponentDialog = !1;
          }
        else
          this.showCalendarDropdown = !0;
    },
    closeDropDown(e) {
      var o;
      this.isComponentDialog || !this.showCalendarDropdown || (this.showCalendarDropdown = !1, this.compSelectFromDate = null, this.compSelectToDate = null, e && e.type.indexOf("key") > -1 && e.preventDefault(), (o = this.$refs.refInput) == null || o.$el.blur(), this.blur(e));
    },
    calendarEvent(e, o) {
      if (o === "hover") {
        this.hover = e;
        return;
      }
      if (this.hover = null, this.selectValue = void 0, this.compSelectFromDate === null) {
        this.compSelectToDate !== null && new t(e.timestamp).isAfter(
          this.compSelectToDate
        ) ? (this.compSelectFromDate = this.compSelectToDate.clone(), this.compSelectToDate = e) : this.compSelectFromDate = e;
        return;
      }
      if (this.compSelectToDate === null) {
        this.compSelectFromDate !== null && new t(e.timestamp).isBefore(
          this.compSelectFromDate
        ) ? (this.compSelectToDate = this.compSelectFromDate.clone(), this.compSelectFromDate = e) : this.compSelectToDate = e;
        return;
      }
      if (new t(e.timestamp).sameDayAs(
        this.compSelectFromDate
      )) {
        this.compSelectFromDate = null, this.hover = e;
        return;
      }
      if (new t(e.timestamp).sameDayAs(
        this.compSelectToDate
      )) {
        this.compSelectToDate = null, this.hover = e;
        return;
      }
      if (!new t(e.timestamp).sameDayAs(
        this.compSelectFromDate
      ) && !new t(e.timestamp).sameDayAs(
        this.compSelectToDate
      )) {
        this.compSelectFromDate = e, this.compSelectToDate = null;
        return;
      }
    },
    itemSelected(e) {
      var i;
      const o = (i = this.customDateRanges) == null ? void 0 : i.find(
        (r) => typeof r != "string" && r.id === e[0].id
      );
      switch (e[0].id) {
        case a.Today:
          this.compSelectFromDate = new t(), this.compSelectToDate = new t().setTime(23, 59, 59);
          break;
        case a.Yesterday:
          this.compSelectFromDate = new t().sub(1, "day"), this.compSelectToDate = new t().sub(1, "day").setTime(23, 59, 59);
          break;
        case a.WTD:
          this.compSelectFromDate = t.dateRange("this-week")[0], this.compSelectToDate = new t().setTime(23, 59, 59);
          break;
        case a.MTD:
          this.compSelectFromDate = new t().moveTo("monthStart"), this.compSelectToDate = new t().setTime(23, 59, 59);
          break;
        case a.QTD:
          this.compSelectFromDate = new t().moveTo("quarterStart"), this.compSelectToDate = new t().setTime(23, 59, 59);
          break;
        case a.YTD:
          this.compSelectFromDate = new t().moveTo("quarter1Start"), this.compSelectToDate = new t().setTime(23, 59, 59);
          break;
        // case DateItems.LastWeek:
        //   this.compSelectFromDate = DateTime.dateRange("last-week")[0];
        //   this.compSelectToDate = DateTime.dateRange("last-week")[1];
        //   break;
        // case DateItems.ThisWeek:
        //   this.compSelectFromDate = DateTime.dateRange("this-week")[0];
        //   this.compSelectToDate = DateTime.dateRange("this-week")[1];
        //   break;
        // case DateItems.NextWeek:
        //   this.compSelectFromDate = DateTime.dateRange("next-week")[0];
        //   this.compSelectToDate = DateTime.dateRange("next-week")[1];
        //   break;
        case a.Last3Months:
          this.compSelectFromDate = new t().sub(3, "month"), this.compSelectToDate = new t().setTime(23, 59, 59);
          break;
        case a.Last6Months:
          this.compSelectFromDate = new t().sub(6, "month"), this.compSelectToDate = new t().setTime(23, 59, 59);
          break;
        case a.Last12Months:
          this.compSelectFromDate = new t().sub(12, "month"), this.compSelectToDate = new t().setTime(23, 59, 59);
          break;
        case a.Last15Months:
          this.compSelectFromDate = new t().sub(15, "month"), this.compSelectToDate = new t().setTime(23, 59, 59);
          break;
        case a.LastMonth:
          this.compSelectFromDate = new t().sub(1, "month").moveTo("monthStart"), this.compSelectToDate = new t().sub(1, "month").moveTo("monthEnd");
          break;
        case a.ThisMonth:
          this.compSelectFromDate = new t().moveTo("monthStart"), this.compSelectToDate = new t().moveTo("monthEnd");
          break;
        case a.NextMonth:
          this.compSelectFromDate = new t().moveTo("monthEnd").add(1, "day"), this.compSelectToDate = new t().add(1, "month").moveTo("monthEnd");
          break;
        case a.Quarter1:
          this.compSelectFromDate = new t().moveTo(
            "relativeQuarter1Start"
          ), this.compSelectToDate = new t().moveTo("relativeQuarter1End");
          break;
        case a.Quarter2:
          this.compSelectFromDate = new t().moveTo(
            "relativeQuarter2Start"
          ), this.compSelectToDate = new t().moveTo("relativeQuarter2End");
          break;
        case a.Quarter3:
          this.compSelectFromDate = new t().moveTo(
            "relativeQuarter3Start"
          ), this.compSelectToDate = new t().moveTo("relativeQuarter3End");
          break;
        case a.Quarter4:
          this.compSelectFromDate = new t().moveTo(
            "relativeQuarter4Start"
          ), this.compSelectToDate = new t().moveTo("relativeQuarter4End");
          break;
        case a.Since2022:
          this.compSelectFromDate = new t().setDate(
            /* @__PURE__ */ new Date("2022-01-01")
          ), this.compSelectToDate = new t().setTime(23, 59, 59);
          break;
        default:
          o && (this.compSelectFromDate = o == null ? void 0 : o.start(), this.compSelectToDate = o == null ? void 0 : o.end());
          break;
      }
      this.compSelectFromDate && (this.firstCalendar.month = this.compSelectFromDate.getDate().getMonth(), this.firstCalendar.year = this.compSelectFromDate.getDate().getFullYear());
    },
    getValues(e) {
      const o = Array(), i = e || this.compSelectFromDate ? this.compSelectFromDate : this.compFromDate;
      let r = e || this.compSelectToDate ? this.compSelectToDate : this.compToDate;
      return i !== null && o.push(i), r !== null && (r = r.setTime(23, 59, 59), o.push(r)), o;
    },
    updateValue(e = !0) {
      var r, l, n, m, c, h, p;
      const o = this.getValues(e);
      this.inputFieldText = o.map((u) => u.formatDate).join(" - "), !this.firstCalendar.month.between(
        ((r = this.compSelectFromDate) == null ? void 0 : r.getDate().getMonth()) ?? -1,
        (((l = this.compSelectToDate) == null ? void 0 : l.getDate().getMonth()) ?? -2) + 1
      ) && !(this.firstCalendar.month + 1).between(
        ((n = this.compSelectFromDate) == null ? void 0 : n.getDate().getMonth()) ?? -1,
        (((m = this.compSelectToDate) == null ? void 0 : m.getDate().getMonth()) ?? -2) + 1
      ) && this.firstCalendar.month !== ((c = this.compSelectFromDate) == null ? void 0 : c.getDate().getMonth()) && this.firstCalendar.month !== ((h = this.compSelectToDate) == null ? void 0 : h.getDate().getMonth()) && (this.firstCalendar.month = (p = this.compSelectFromDate ?? this.compSelectToDate ?? new t()) == null ? void 0 : p.getDate().getMonth());
      const i = o.length === 2 ? o : null;
      this.validate(i), this.$forceUpdate(), e && (this.computedValue = i);
    }
  }
});
export {
  R as D
};
