// IntroducerBillingContact, Profile, Address, PhoneNumber, EmailAddress, Website

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerBillingContact } from "@kinherit/sdk/model/introducer-billing-contact";

export const CreateIntroducerBillingContact = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/billing-contacts",
  method: "post",
  parse: (target: IntroducerBillingContact) => ({
    body: {
      notes: target.$data.notes,
      isGlobal: target.$data.isGlobal,
      profile: {
        firstName: target.profile.$data.firstName,
        lastName: target.profile.$data.lastName,
        emails: target.profile.emails.map((email) => ({
          email: email.email,
          primary: email.primary,
        })),
        phoneNumbers: target.profile.phoneNumbers.map((phoneNumber) => ({
          tel: phoneNumber.tel,
          primary: phoneNumber.primary,
        })),
        websites: target.profile.websites.map((website) => ({
          url: website.url,
        })),
        addresses: target.profile.addresses.map((address) => ({
          city: address.city,
          line1: address.line1,
          line2: address.line2,
          postcode: address.postcode,
          state: address.state,
          country: address.country,
          primaryMailing: address.primaryMailing,
          primaryResidential: address.primaryResidential,
        })),
      },
      company: target.$data.company,
      introducerRemittanceType: target.$data.introducerRemittanceType,
      agentRemittanceType: target.$data.agentRemittanceType,
    },
  }),
  after: ({ message }) => {
    message.$delete({
      profile: {
        emails: true,
        phoneNumbers: true,
        websites: true,
        addresses: true,
      },
    });
  },
});
