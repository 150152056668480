<template>
  <div class="introducer-network-master-list">
    <PageHeader htag="h1" text="Networks">
      <template #buttons>
        <Button class="create" @click="createIntroducerNetwork">
          Create Introducer Network
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        createdAt: 'Created',
      }"
      @refresh="refresh"
    >
      <template #actions="{ row }">
        <div class="buttons is-right">
          <Button class="edit-button" @click="updateNetwork(row)">
            Edit
          </Button>
          <Button class="delete-button" @click="deleteNetwork(row)">
            Delete
          </Button>
        </div>
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerNetworkMasterListWrapper",
  "route": "IntroducerNetworkMasterList",
  "selector": ".introducer-network-master-list",
  "imports": {
    "ReadIntroducerNetworkFormWrapper": "@/module/introducer.network/form/read-introducer-network.form.test",
    "CreateIntroducerNetworkFormWrapper": "@/module/introducer.network/form/create-introducer-network.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadIntroducerNetworkFormWrapper"
    },
    "createIntroducerNetwork": {
      "type": "click",
      "selector": ".create"
    },
    "createIntroducerNetworkForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "CreateIntroducerNetworkFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { ReadIntroducerNetworkForm } from "@/module/introducer.network/form/read-introducer-network.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Like } from "@kinherit/orm/index";
import { IIntroducerNetwork, IntroducerNetwork } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerNetworkMasterListRoute } from ".";
import { CreateIntroducerNetworkForm } from "../form/create-introducer-network.form";

export default defineComponent({
  name: IntroducerNetworkMasterListRoute,
  components: { MasterListPage, PageHeader, Button },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal.introducer.networks),
  ],
  data: () => ({
    filters: ReadIntroducerNetworkForm(),
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v) => v.name,
      },
      {
        title: "Companies",
        sort: true,
        map: (v) => v.companyCount,
      },
      {
        title: "Created",
        sort: true,
        map: (v) => v.createdAt.formatDate,
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn<IntroducerNetwork>>,
    rows: Array<IntroducerNetwork>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerNetwork,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("introducer-network:write");
    },
  },
  methods: {
    async updateNetwork(introducerNetwork: IntroducerNetwork): Promise<void> {
      const form = CreateIntroducerNetworkForm(introducerNetwork);

      try {
        await form.dialog({
          dialog: {
            title: "Edit Introducer Network",
          },
        });

        // await window.Kernel.ActionBus.introducerNetwork.update({
        //   introducerNetwork: form.localData,
        // });
        await this.$actionBus.UpdateIntroducerNetwork(form.localData);

        this.refresh(this.filters.localData);
      } catch {
        introducerNetwork.$restore();
      }
    },
    async refresh(
      formData: ReturnType<typeof ReadIntroducerNetworkForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.introducerNetwork.read({
      //   ...formData,
      //   sort: this.sort,
      //   pagination: this.pagination,
      // });
      const data = await this.$actionBus.ReadIntroducerNetwork({
        query: {
          name: Like(`%${formData.name}%`),
          createdAt: Between(formData.created),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.introducerNetwork;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async createIntroducerNetwork(): Promise<void> {
      const form = CreateIntroducerNetworkForm();

      try {
        await form.dialog({
          dialog: {
            title: "Create Introducer Network",
          },
        });

        // await window.Kernel.ActionBus.introducerNetwork.create({
        //   introducerNetwork: form.localData,
        // });
        await this.$actionBus.CreateIntroducerNetwork(form.localData);

        this.refresh(this.filters.localData);
      } catch {
        form.localData.$delete();
      }
    },
    async deleteNetwork(network: IntroducerNetwork): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Introducer Network",
          message: `Are you sure you want to delete ${network.name}?`,
        },
      });

      // await window.Kernel.ActionBus.introducerNetwork.delete({
      //   introducerNetwork: network,
      // });
      await this.$actionBus.DeleteIntroducerNetwork(network);

      this.refresh(this.filters.localData);
    },
  },
});
</script>
