import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { ReadIntroducerCompanyProfileComponent } from "@/module/admin.email-campaign/form/profile/read-introducer-company-profile.form";
import { ReadIntroducerContactProfileComponent } from "@/module/admin.email-campaign/form/profile/read-introducer-contact-profile.form";
import { ReadKinvaultProfileComponent } from "@/module/admin.email-campaign/form/profile/read-kinvault-profile.form";
import { ReadLeadProfileComponent } from "@/module/admin.email-campaign/form/profile/read-lead-profile.form";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { IsNotNull, MoreThan } from "@kinherit/orm";
import { Profile, QueryMask } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";
import { reactive } from "vue";

type QuickFilterType = {
  id: string;
  label: string;
  group?: "appointment";
  query: QueryMask<typeof Profile>;
};

const QuickFilterOptions: QuickFilterType[] = [
  {
    id: "appointment",
    label: "Appointments",
    query: {
      appointments: {
        id: IsNotNull(),
      },
    },
  },
  {
    id: "futureAppointment",
    label: "Appointments - Future",
    group: "appointment",
    query: {
      appointments: {
        id: IsNotNull(),
        appointmentAt: MoreThan(new DateTime()),
      },
    },
  },
  {
    id: "cpdSessionRegistration",
    label: "Cpd Session Registrations",
    query: {
      cpdSessionRegistration: {
        id: IsNotNull(),
      },
    },
  },
  {
    id: "introducerBillingContact",
    label: "Introducer Billing Contacts",
    query: {
      introducerBillingContact: {
        id: IsNotNull(),
      },
    },
  },
  {
    id: "introducerCompany",
    label: "Introducer Companies",
    query: {
      introducerCompany: {
        id: IsNotNull(),
      },
    },
  },
  {
    id: "introducerContact",
    label: "Introducer Contacts",
    query: {
      introducerContact: {
        id: IsNotNull(),
      },
    },
  },
  {
    id: "introducerContactAssistant",
    label: "Introducer Contact Assistants",
    query: {
      introducerContactAssistant: {
        id: IsNotNull(),
      },
    },
  },
  {
    id: "lead",
    label: "Leads",
    query: [
      {
        leadPrimary: {
          id: IsNotNull(),
        },
      },
      {
        leadSecondary: {
          id: IsNotNull(),
        },
      },
    ],
  },
  {
    id: "brandedKinvault",
    label: "Branded Kinvaults",
    query: {
      brandedKinvault: {
        id: IsNotNull(),
      },
    },
  },
  {
    id: "person",
    label: "People",
    query: {
      person: {
        id: IsNotNull(),
      },
    },
  },
  {
    id: "user",
    label: "Users",
    query: {
      user: {
        id: IsNotNull(),
      },
    },
  },
];

const QuickFilterCategories = [
  {
    label: "General",
    filter: (option: QuickFilterType) => undefined === option["group"],
  },
  {
    label: "Appointments",
    filter: (option: QuickFilterType) => "appointment" === option["group"],
  },
];

type PredefinedFilterType = {
  id: string;
  label: string;
  options: Partial<ReturnType<typeof FormData>>;
};

const PredefinedFilterOptions: PredefinedFilterType[] = [
  {
    label: "People called john",
    id: "john",
    options: {
      name: "John",
    },
  },
  {
    label: "People called jack",
    id: "jack",
    options: {
      name: "Jack",
    },
  },
];

const FormData = () => ({
  name: ``,
  emailAddress: ``,
  created: null as null | [number, number],
  query: {} as QueryMask<typeof Profile>,
  newsletterCampaigns: null as null | boolean,
  // introducer contact options
  introducerContactStatus: [] as string[],
  introducerContactCompanyStatus: [] as string[],
  introducerContactCompanyStage: [] as string[],
  introducerContactCompanyType: [] as string[],
  introducerContactCompanyNetwork: [] as string[],
  introducerContactTags: [] as string[],
  introducerContactCompanyTags: [] as string[],
  introducerContactCpdSessionType: [] as string[],
  introducerContactCompany: [] as string[],
  // introducer company options
  introducerCompanyStatus: [] as string[],
  introducerCompanyStage: [] as string[],
  introducerCompanyType: [] as string[],
  introducerCompanyNetwork: [] as string[],
  introducerCompanyTags: [] as string[],
  // kinvault
  kinvaultNoRecentLogins: null as null | boolean,
  // lead
  leadStatus: [] as string[],
  leadReferralCode: [] as string[],
});

export const ReadProfileForm = () => {
  const localData = reactive({
    quickFilter: null as null | string,
    predefinedFilter: null as null | string,
  });

  return MasterListFiltersForm({
    name: "filter-profile-form",
    data: FormData,
    props: SharedFilterProps,
    updateUrlQuery: false,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Name",
          },
          models: {
            value: "name",
          },
        }),
      ],
      emailAddress: [
        FormTextField({
          props: {
            label: "Email Address",
          },
          models: {
            value: "emailAddress",
          },
        }),
      ],
      quickFilter: [
        FormAutoCompleteField({
          props: {
            label: "Quick Filters",
            options: QuickFilterOptions,
            mapOptions: {
              label: "label",
              value: "id",
            },
            categories: QuickFilterCategories,
            reference: "quickFilter",
          },
          models: {
            value: {
              get: () =>
                QuickFilterOptions.findBy("id", localData.quickFilter) ?? null,
              set: (value: QuickFilterType | null, data) => {
                data.query = value?.query ?? {};
                localData.quickFilter = value?.id ?? null;
              },
            },
          },
        }),
      ],
      predefinedFilter: [
        FormAutoCompleteField({
          props: {
            label: "Predefined Filters",
            options: PredefinedFilterOptions,
            mapOptions: {
              label: "label",
              value: "id",
            },
            reference: "predefinedFilter",
          },
          models: {
            value: {
              get: () =>
                PredefinedFilterOptions.findBy(
                  "id",
                  localData.predefinedFilter,
                ) ?? null,
              set: (value: PredefinedFilterType | null, data, controls) => {
                Object.assign(data, value?.options ?? {});
                localData.predefinedFilter = value?.id ?? null;

                controls.rebuildTemplateBindings();
              },
            },
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
      newsletterCampaign: [
        FormCheckboxField({
          props: {
            reference: "introducerNewsletterCampaigns",
            label: `Newsletter Campaign`,
          },
          models: {
            value: {
              get: (data) => data.newsletterCampaigns ?? false,
              set: (value, data) => {
                data.newsletterCampaigns = value || null;
              },
            },
          },
        }),
      ],
    }),
    advancedComponents: () => ({
      default: [
        FormElement({
          props: {
            is: "div",
            reference: "",
          },
          slots: {
            default: [
              ReadIntroducerContactProfileComponent(),
              ReadIntroducerCompanyProfileComponent(),
              ReadLeadProfileComponent(),
              ReadKinvaultProfileComponent(),
            ],
          },
        }),
      ],
    }),
  });
};
