var b = Object.defineProperty;
var r = (e, i) => b(e, "name", { value: i, configurable: !0 });
import { _ as c } from "../../../component.display/pagination/pagination.vue_vue_type_script_lang.js";
import { resolveComponent as C, createElementBlock as o, openBlock as n, mergeProps as f, createElementVNode as u, normalizeClass as a, createVNode as p, renderSlot as y, Fragment as m, renderList as $, createCommentVNode as g, toDisplayString as v } from "vue";
import { b as d } from "../../../_assets/component.display/pagination/pagination.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as h } from "../../../_plugins/_plugin-vue_export-helper.js";
const k = ["aria-label"], B = ["aria-label"], _ = {
  key: 0,
  class: "pagination__slot"
}, P = {
  key: 1,
  class: "pagination__pages"
}, L = { key: 0 }, N = ["aria-label", "onClick"], V = {
  key: 1,
  class: "pagination__ellipsis"
}, E = ["aria-label"];
function I(e, i, S, z, D, F) {
  const s = C("Icon");
  return n(), o("nav", f({
    class: "pagination",
    role: "navigation",
    "aria-label": e.$t.local.containerLabel
  }, e.$data.$cypress), [
    u("button", {
      type: "button",
      class: a(["button pagination__previous", { "is-invisible": e.hidePreviousButton }]),
      "aria-label": e.$t.local.previousButtonLabel,
      onClick: i[0] || (i[0] = (t) => e.computedCurrent = e.computedCurrent - e.incrementBy)
    }, [
      p(s, { icon: "ChevronLeft" })
    ], 10, B),
    e.$slots.default ? (n(), o("span", _, [
      y(e.$slots, "default", { page: e.computedCurrent })
    ])) : (n(), o("ul", P, [
      (n(!0), o(m, null, $(e.compPages, (t, l) => (n(), o(m, { key: l }, [
        !e.hideOtherPages || e.computedCurrent === t ? (n(), o("li", L, [
          t !== null ? (n(), o("button", {
            key: 0,
            type: "button",
            class: a(["button pagination__page", {
              "is-current": e.computedCurrent === t,
              "is-previous": e.computedCurrent === t + e.incrementBy,
              "is-next": e.computedCurrent === t - e.incrementBy,
              "is-first": t === 1,
              "is-last": l === e.compPages.length - 1
            }]),
            "aria-label": e.$t.local.goToPageLabel(t),
            onClick: /* @__PURE__ */ r((O) => e.computedCurrent = t, "onClick")
          }, v(t), 11, N)) : (n(), o("span", V, " … "))
        ])) : g("", !0)
      ], 64))), 128))
    ])),
    u("button", {
      type: "button",
      class: a(["button pagination__next", { "is-invisible": e.hideNextButton }]),
      "aria-label": e.$t.local.nextButtonLabel,
      onClick: i[1] || (i[1] = (t) => e.computedCurrent = e.computedCurrent + e.incrementBy)
    }, [
      p(s, { icon: "ChevronRight" })
    ], 10, E)
  ], 16, k);
}
r(I, "_sfc_render");
typeof d == "function" && d(c);
const A = /* @__PURE__ */ h(c, [["render", I]]);
export {
  A as default
};
