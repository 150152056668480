// Profile, EmailAddress

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { EmailCampaign } from "@kinherit/sdk/model/email-campaign";
import { Profile } from "@kinherit/sdk/model/profile";

export const RemoveProfile = DefineDeleteAction({
  interface: "portal",
  resource:
    "/v2/portal/email-campaign/profile/{emailCampaign}/profiles/{profile}/remove",
  method: "delete",
  parse: (message: {
    emailCampaign: EmailCampaign | string;
    profile: Profile | string;
  }) => ({
    params: {
      emailCampaign: message.emailCampaign,
      profile: message.profile,
    },
  }),
});
