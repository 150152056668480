// ThemeKinvault, ThemeCssVar, ThemeKinvaultFooter, ThemeKinvaultHeader, ThemeKinvaultLogin

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvault } from "@kinherit/sdk";
import { ThemeKinvault } from "@kinherit/sdk/model/theme-kinvault";

export const RecordThemeKinvault = DefineReadAction({
  interface: "portal",
  resource:
    "/v2/portal/admin/branded-kinvault/{brandedKinvault}/theme/{themeKinvault}",
  method: "get",
  rootModel: ThemeKinvault,
  parse: ({
    themeKinvault,
    brandedKinvault,
  }: {
    themeKinvault: ThemeKinvault | string;
    brandedKinvault: BrandedKinvault | string;
  }) => ({
    params: {
      themeKinvault,
      brandedKinvault,
    },
  }),
});
