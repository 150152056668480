<template>
  <Card
    v-if="show && !noCard"
    v-bind="$attrs"
    class="contact-details-widget"
    :title="customTitle"
    title-size="is-5"
    @context-menu="$emit('context-menu')"
  >
    <DataListWidget
      no-card
      is-compact
      :details="addressDetails"
      :icon="'AddressBook'"
    />
    <DataListWidget
      no-card
      is-compact
      :details="emailDetails"
      :icon="'Email'"
    />
    <DataListWidget
      no-card
      is-compact
      :details="phoneDetails"
      :icon="'Phone'"
    />
    <DataListWidget no-card is-compact :details="websiteDetails" />
  </Card>
  <span v-else-if="show">
    <DataListWidget
      no-card
      is-compact
      :details="addressDetails"
      :icon="'AddressBook'"
    />
    <DataListWidget
      no-card
      is-compact
      :details="emailDetails"
      :icon="'Email'"
    />
    <DataListWidget
      no-card
      is-compact
      :details="phoneDetails"
      :icon="'Phone'"
    />
    <DataListWidget no-card is-compact :details="websiteDetails" />
  </span>
</template>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { Profile } from "@kinherit/sdk";
import { defineComponent, type PropType } from "vue";
import DataListWidget from "../widget/DataListWidget.vue";
export default defineComponent({
  name: "ContactDetailsWidget",
  components: {
    Card,
    DataListWidget,
  },
  props: {
    noCard: {
      type: Boolean,
      default: false,
    },
    profileId: {
      type: String,
      required: true,
    },
    customTitle: {
      type: String as PropType<null | string>,
      default: null,
    },
  },
  emits: ["context-menu"],
  computed: {
    profile(): Profile {
      return Profile.$findOneOrThrow(this.profileId);
    },
    show(): boolean {
      return ![
        ...this.addressDetails,
        ...this.emailDetails,
        ...this.phoneDetails,
        ...this.websiteDetails,
      ].isEmpty();
    },
    addressDetails() {
      return this.profile.addresses
        .filter((address) => address.summary !== null)
        .sort((a, b) => {
          if (a.primaryMailing && !b.primaryMailing) {
            return -1;
          }
          if (b.primaryMailing && !a.primaryMailing) {
            return 1;
          }
          if (a.primaryResidential && !b.primaryResidential) {
            return -1;
          }
          if (b.primaryResidential && !a.primaryResidential) {
            return 1;
          }
          return 0;
        })
        .map((address) => {
          const mailingLabel = address.primaryMailing ? "Mailing" : "";
          const residentialLabel = address.primaryResidential
            ? "Residential"
            : "";
          const andLabel =
            address.primaryMailing && address.primaryResidential ? " & " : "";

          const label = `${mailingLabel}${andLabel}${residentialLabel} Address`;

          return {
            label: label.trim(),
            value: `${address.summary}, ${address.country}`,
          };
        });
    },
    emailDetails() {
      return this.profile.emails.map((email) => ({
        label: `${email.primary ? "Primary Email" : "Email"}`,
        value: {
          text: email.email,
          href: `mailto:${email.email}`,
        },
      }));
    },
    phoneDetails() {
      return this.profile.phoneNumbers.map((phone) => ({
        label: `${phone.primary ? "Primary Phone" : "Phone"}`,
        value: {
          text: phone.tel,
          href: `tel:${phone.tel}`,
        },
      }));
    },
    websiteDetails() {
      return this.profile.websites.map((website) => ({
        label: "Website",
        value: {
          text: new URL(website.url).hostname,
          href: website.url,
        },
      }));
    },
  },
});
</script>
