// IntroducerFeePaymentRun, IntroducerFeePayment, IntroducerFeePaymentItem

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerFeePaymentRun } from "@kinherit/sdk/model/introducer-fee-payment-run";

export const RecordIntroducerFeePaymentRun = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/fees/payment-run/{introducerFeePaymentRun}",
  method: "get",
  rootModel: IntroducerFeePaymentRun,
  parse: (introducerFeePaymentRun: IntroducerFeePaymentRun | string) => ({
    params: {
      introducerFeePaymentRun,
    },
  }),
});
