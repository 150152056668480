import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerBillingContact } from "./create-introducer-billing-contact.action";
import { DeleteIntroducerBillingContact } from "./delete-introducer-billing-contact.action";
import { ReadIntroducerBillingContacts } from "./read-introducer-billing-contacts.action";
import { RecordIntroducerBillingContact } from "./record-introducer-billing-contact.action";
import { ReplaceProfile } from "./replace-profile.action";
import { UpdateIntroducerBillingContact } from "./update-introducer-billing-contact.action";

export const Portal2IntroducerBillingContacts = DefineActionBus({
  name: "portal-introducer-billing-contacts",
  actions: {
    ReadIntroducerBillingContacts,
    RecordIntroducerBillingContact,
    UpdateIntroducerBillingContact,
    DeleteIntroducerBillingContact,
    UpdateIntroducerBillingContactProfile: ReplaceProfile,
    CreateIntroducerBillingContact,
  },
});
