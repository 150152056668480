// FileLog

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { FileLog } from "@kinherit/sdk/model/file-log";
import { KnowledgeBaseDocument } from "@kinherit/sdk/model/knowledge-base-document";

export const DeleteFileLog = DefineDeleteAction({
  interface: "portal",
  resource:
    "/v2/portal/knowledge-base/category/document/file-log/{knowledgeBaseDocument}/document/{fileLog}",
  method: "delete",
  parse: (message: {
    knowledgeBaseDocument: KnowledgeBaseDocument | string;
    fileLog: FileLog | string;
  }) => ({
    params: {
      knowledgeBaseDocument: message.knowledgeBaseDocument,
      fileLog: message.fileLog,
    },
  }),
});
