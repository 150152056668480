// BrandedKinvaultPricingSchema

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultPricingSchema } from "@kinherit/sdk/model/branded-kinvault-pricing-schema";

export const UpdateBrandedKinvaultPricingSchema = DefineWriteAction({
  interface: "portal",
  resource:
    "/v2/portal/admin/branded-kinvault/kinvault-pricing-schema/{brandedKinvaultPricingSchema}",
  method: "patch",
  parse: (target: BrandedKinvaultPricingSchema) => ({
    body: {
      schema: target.$data.schema,
      startDate: target.$data.startDate,
      endDate: target.$data.endDate,
      isDefault: target.$data.isDefault,
      name: target.$data.name,
      brandedKinvaults: target.$data.brandedKinvaults,
    },
    params: {
      brandedKinvaultPricingSchema: target,
    },
  }),
});
