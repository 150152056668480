// DefaultBrandedKinvaultReferralCode

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk/model/default-branded-kinvault-referral-code";

export const RecordDefaultBrandedKinvaultReferralCode = DefineReadAction({
  interface: "portal",
  resource:
    "/v2/portal/admin/branded-kinvault/default-branded-kinvault-referral-code/{defaultBrandedKinvaultReferralCode}",
  method: "get",
  rootModel: DefaultBrandedKinvaultReferralCode,
  parse: (
    defaultBrandedKinvaultReferralCode:
      | DefaultBrandedKinvaultReferralCode
      | string,
  ) => ({
    params: {
      defaultBrandedKinvaultReferralCode,
    },
  }),
});
