import { defineComponent as s } from "vue";
const t = s({
  name: "FieldMessageComponent",
  props: {
    /**
     * A list of validation messages and a class to be applied to that message
     */
    messages: {
      type: Array,
      required: !0
    }
  },
  computed: {
    filteredMessages() {
      return this.messages.filter((e) => e.message);
    }
  }
});
export {
  t as _
};
