<template>
  <CallScriptSection v-if="kintin" title="Customers">
    <b>
      <span v-if="isJoint"> So for {{ primarySalutation }}, </span>
      Can I take your date of birth and address?
      <br /><br />
    </b>
    <PersonList
      :key="primaryPersonRenderKey"
      :kintin="kintin"
      :people="[primaryPerson]"
      @reload="primaryPersonRenderKey++"
    />
    <span v-if="isJoint">
      <br /><br />
      <b
        >And for {{ secondarySalutation ?? "your partner" }}, I assume you're at
        the same address - can I just get your date of birth, email,
        telephone</b
      >
      <br /><br />
      <PersonList
        v-if="secondaryPerson"
        :key="secondaryPersonRenderKey"
        :kintin="kintin"
        :people="[secondaryPerson]"
        @reload="secondaryPersonRenderKey++"
      />
    </span>
    <b>
      This is probably obvious, but as you live in [England] it would be an
      [English] law Will that we are talking about
    </b>
    <br /><em>[nb Wales=English law / Scotland=Scottish law]</em> <br /><em
      >[Specialist to add note if Scotland or Northern Ireland]</em
    >
    <br /><br />
    <ConfirmCheck
      :kintin="kintin"
      step="2.4.4b"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      @update="updateCheckValue"
    />
    <br />
    <b>
      And any documents we send will be to your address, so please let us know
      if you're intending to move.
    </b>
    <br /><br />
    <ConfirmCheck
      :kintin="kintin"
      step="2.4.1"
      :kintin-checks="kintinChecks"
      :checks="checks"
      @update="updateCheckValue"
    />
    <ConfirmCheck
      :kintin="kintin"
      step="2.4.2"
      :kintin-checks="kintinChecks"
      :checks="checks"
      @update="updateCheckValue"
    />
    <ConfirmCheck
      :kintin="kintin"
      step="2.4.3"
      :kintin-checks="kintinChecks"
      :checks="checks"
      @update="updateCheckValue"
    />
  </CallScriptSection>
  <CallScriptSection v-if="kintin" title="Res & Dom">
    <ConfirmCheck
      :kintin="kintin"
      step="2.4.4a"
      :kintin-checks="kintinChecks"
      :checks="checks"
      :no-label="'No/Unsure'"
      is-reversed
      :on="{
        no: {
          say: [
            'No problem. We do get customers from outside the UK, although obviously we cannot vouch for how a UK Will is going to be treated in other territories.',
          ],
        },
      }"
      @update="updateCheckValue"
    />
    <ConfirmCheck
      :kintin="kintin"
      step="2.4.4"
      :kintin-checks="kintinChecks"
      :checks="checks"
      @update="updateCheckValue"
    />
  </CallScriptSection>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ClientsTab",
  components: {
    CallScriptSection,
    ConfirmCheck,
    PersonList,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  data: () => ({
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
  }),
});
</script>
