var L = Object.defineProperty;
var a = (t, o) => L(t, "name", { value: o, configurable: !0 });
const w = /* @__PURE__ */ a((t, o) => {
  var d, p, h;
  const n = typeof o.arg == "string" ? (d = o.arg) == null ? void 0 : d.split(":").join(" ") : "", e = n.includes("is-below"), r = n.includes("is-absolute");
  t.dataset.tooltipPosition = e ? "below" : "above";
  const l = e && ((p = t.children.item(t.children.length - 1)) == null ? void 0 : p.classList.contains("tool-tip"));
  if (!e && ((h = t.children.item(0)) == null ? void 0 : h.classList.contains("tool-tip")) || l)
    return;
  const s = document == null ? void 0 : document.createElement("div");
  s.setAttribute("class", `tool-tip is-themed ${n}`), s.style.display = "none", r && (s.style.position = "absolute", e ? s.style.transform = "translate(0%, 100%)" : s.style.transform = "translate(0%, -100%)", setTimeout(() => {
    t.style.position = "relative";
  }, 0)), s.innerText = o.value;
  const c = document == null ? void 0 : document.createElement("div");
  c.setAttribute("class", "tool-tip__indicator"), s.appendChild(c), e ? t.appendChild(s) : t.prepend(s);
}, "AppendToolTip"), T = /* @__PURE__ */ a((t, o) => {
  var i, s, c, d, p;
  const e = (((i = o.arg) == null ? void 0 : i.split(":").join(" ")) ?? "").includes("is-below"), r = e && ((s = t.children.item(t.children.length - 1)) == null ? void 0 : s.classList.contains("tool-tip"));
  if (!e && ((c = t.children.item(0)) == null ? void 0 : c.classList.contains("tool-tip"))) {
    (d = t.children.item(0)) == null || d.remove();
    return;
  }
  if (r) {
    (p = t.children.item(t.children.length - 1)) == null || p.remove();
    return;
  }
}, "RemoveToolTip"), v = /* @__PURE__ */ a((t) => {
  var n, e;
  return [
    ...Array.from(((n = t.currentTarget) == null ? void 0 : n.classList) ?? []),
    ...Array.from(((e = t.relatedTarget) == null ? void 0 : e.classList) ?? [])
  ].intersection(["tool-tip", "tool-tip__indicator"]).length > 0;
}, "IsOverTooltip"), m = /* @__PURE__ */ a((t, o) => {
  if (!t || !t.parentElement || !o || !o.currentTarget)
    return;
  const { width: n, left: e } = o.currentTarget.getBoundingClientRect(), { left: r } = t.getBoundingClientRect(), l = t.querySelector(
    ".tool-tip__indicator"
  );
  if (!l) return;
  const { width: i } = l.getBoundingClientRect();
  l.style.left = `${e - r + (n - i) / 2}px`;
}, "CenterToolTipIndicator"), f = /* @__PURE__ */ a((t) => {
  const o = t.currentTarget, n = o.dataset.tooltipPosition === "below", e = n ? o.children[o.children.length - 1] : o.children[0];
  if (!(e != null && e.classList.contains("tool-tip")))
    return;
  if (e.style.display = "inline-flex", e.classList.contains("is-absolute") && e.parentElement) {
    const i = e.parentElement.getBoundingClientRect(), s = window.getComputedStyle(e.parentElement), c = parseInt(
      s.getPropertyValue("border-left-width")
    ), d = parseInt(
      s.getPropertyValue("border-top-width")
    );
    e.style.top = n ? `${i.height - e.getBoundingClientRect().height}px` : `-${d}px`, e.style.left = `${(i.width - e.getBoundingClientRect().width) / 2 - c}px`;
  } else {
    const l = t.currentTarget.getBoundingClientRect(), i = e.getBoundingClientRect().width;
    let s = l.left + l.width / 2;
    s - i / 2 < 0 ? (s = i / 2, m(e, t)) : s + i > window.innerWidth && (s = window.innerWidth - i / 2, m(e, t)), e.style.top = `${n ? l.bottom : l.top}px`, e.style.left = `${s}px`;
  }
  e.classList.add("-animation");
}, "ShowToolTip"), g = /* @__PURE__ */ a((t) => {
  var l, i;
  if (v(t))
    return;
  const o = t.currentTarget;
  let n = null;
  const e = (l = o.children.item(o.children.length - 1)) == null ? void 0 : l.classList.contains("tool-tip");
  if ((i = o.children.item(0)) == null ? void 0 : i.classList.contains("tool-tip")) {
    n = o.children.item(0), n.classList.remove("-animation"), n.style.display = "none";
    return;
  }
  if (e) {
    n = o.children.item(
      o.children.length - 1
    ), n.classList.remove("-animation"), n.style.display = "none";
    return;
  }
}, "HideToolTip"), y = /* @__PURE__ */ a(() => {
  document == null || document.querySelectorAll(".tool-tip").forEach((t) => {
    t.style.display = "none";
  });
}, "HideOnScroll");
window.onclick = () => {
  document == null || document.querySelectorAll(".tool-tip").forEach((t) => {
    t.style.display = "none";
  });
};
const u = {
  mounted(t, o) {
    o.value === "" || typeof o.value != "string" || (w(t, o), t.addEventListener("mouseover", f, { passive: !0 }), t.addEventListener("mouseout", g, { passive: !0 }), document == null || document.addEventListener("scroll", y, { passive: !0 }));
  },
  beforeUnmount(t, o) {
    T(t, o), t.removeEventListener("mouseover", f), t.removeEventListener("mouseout", g), document == null || document.removeEventListener("scroll", y);
  },
  updated(t, o, n) {
    var e, r;
    (e = u.beforeUnmount) == null || e.call(u, t, o, n, null), (r = u.mounted) == null || r.call(u, t, o, n, null);
  }
};
export {
  u as ToolTipDirective
};
