var m = Object.defineProperty;
var s = (e, r) => m(e, "name", { value: r, configurable: !0 });
import { Api as u } from "@kinherit/sdk/api";
const l = /* @__PURE__ */ s((e) => async (r) => {
  var a;
  const t = e.parse(r, {}), p = "params" in t ? t.params : {};
  await u.resource(
    e.interface,
    e.resource,
    p
  ).method(e.method).result(), await ((a = e.after) == null ? void 0 : a.call(e, {
    message: r
  }));
}, "DefineDeleteAction");
export {
  l as DefineDeleteAction
};
