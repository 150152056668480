// Notification

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Notification } from "@kinherit/sdk/model/notification";

export const DeleteNotification = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/dashboard/notifications/{notification}",
  method: "delete",
  parse: (notification: Notification | string) => ({
    params: {
      notification,
    },
  }),
});
