import { Api } from "@/service/api.service";
import { Between, Equal, Like } from "@kinherit/orm";
import {
  CallLog,
  CommunicationNote,
  FileLog,
  INote,
  IntroducerCompany,
  IntroducerContact,
  IntroducerNote,
  Kintin,
  Lead,
  Note,
  Person,
  QueryMask,
  StatusLog,
} from "@kinherit/sdk";

type Controllers =
  | "/v2/portal/kinvault/{owner}/notes"
  | "/v2/portal/lead/{owner}/notes"
  | "/v2/portal/introducer/company/note/{owner}"
  | "/v2/portal/introducer/contact/note/{owner}"
  | "/v2/portal/officer/{owner}/notes"
  | "/v2/portal/introducer/outsources/note/{owner}";

export type ReadNoteMessage = {
  pinned?: boolean | null;
  name?: string | null;
  notes?: string | null;
  createdAt?: [number, number] | null;
  query?: QueryMask<typeof Note>;
  type?: string | null;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof INote;
    direction: "asc" | "desc";
  };
} & (
  | {
      kintin: Kintin | string;
    }
  | {
      lead: Lead | string;
    }
  | {
      introducerCompany: IntroducerCompany | string;
    }
  | {
      introducerContact: IntroducerContact | string;
    }
  | {
      person: string | Person;
    }
  | {
      introducerOutsource: string;
    }
  | {
      statusLog: StatusLog | string;
    }
);

export interface ReadNotesResponse {
  notes: Array<Note>;
  communicationNotes: Array<CommunicationNote>;
  introducerNotes: Array<IntroducerNote>;
  callLogs: Array<CallLog>;
  statusLogs: Array<StatusLog>;
  fileLog: Array<FileLog>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadNoteHandler = async (
  message: ReadNoteMessage,
): Promise<ReadNotesResponse> => {
  let path = "" as Controllers;
  const params = {} as any;
  if ("kintin" in message && message.kintin) {
    path = "/v2/portal/kinvault/{owner}/notes";
    params.owner = message.kintin;
  } else if ("lead" in message && message.lead) {
    path = "/v2/portal/lead/{owner}/notes";
    params.owner = message.lead;
  } else if ("introducerCompany" in message && message.introducerCompany) {
    path = "/v2/portal/introducer/company/note/{owner}";
    params.owner = message.introducerCompany;
  } else if ("introducerContact" in message && message.introducerContact) {
    path = "/v2/portal/introducer/contact/note/{owner}";
    params.owner = message.introducerContact;
  } else if ("person" in message && message.person) {
    path = "/v2/portal/officer/{owner}/notes";
    params.owner = message.person;
  } else if ("introducerOutsource" in message && message.introducerOutsource) {
    path = "/v2/portal/introducer/outsources/note/{owner}";
    params.owner = message.introducerOutsource;
  } else {
    throw new Error("Invalid message");
  }

  const request = Api.resource("portal", path as Controllers, params)
    .method("get")

    .paginate({
      currentPage: message.pagination?.currentPage ?? 1,
      perPage: message.pagination?.perPage ?? 10,
    })
    .sort(message.sort);

  request.buildQuery(Note).where({
    pinned: Equal(message.pinned),
    name: Like(message.name),
    notes: Like(message.notes),
    createdAt: Between(message.createdAt),
    ...message.query,
    type: Equal(message.type),
  });

  const response = await request.result();

  return {
    notes: Note.$inflate(response.note, message.sort, response.$rootIds),
    communicationNotes: CommunicationNote.$inflate(response.communicationNote),
    introducerNotes: IntroducerNote.$inflate(response.introducerNote),
    statusLogs: StatusLog.$inflate(response.statusLog),
    callLogs: CallLog.$inflate(response.callLog),
    fileLog: FileLog.$inflate(response.fileLog),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
