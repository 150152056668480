<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
      'call-script-kinvault': true,
    }"
  >
    <PageHeader htag="h2" text="Kinvault">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <Button text="Save" color="is-positive" @click="saveHandler" />
      </template>
    </PageHeader>
    <CallScriptSection title="Preamble">
      <b>
        Good morning/afternoon. It's
        {{ $auth.loggedInUser?.profile.fullName }} from Kinherit. Is that
        {{ primarySalutation }} {{ isJoint ? `/ ${secondarySalutation}` : `` }}?
      </b>
      <br /><em>[Await acknowledgement]</em> <br /><br />
      <b>
        Great. I wanted to check you received your End-of-Life Planning
        documents ok&hellip; was it relatively straightforward to get these
        signed?
      </b>
      <br /><em>[Await acknowledgement]</em> <br /><br />
      <b>
        With your Will now signed, it is time to move on to the more practical
        considerations that can really help your loved ones.
      </b>
      <br /><br />
      <b> Before we start, can I quickly go through security questions: </b>
      <br /><br />
      <CallScriptPersonDetails
        v-if="primaryPerson"
        :person="primaryPerson"
        :hide-empty="true"
      />
    </CallScriptSection>
    <CallScriptSection title="Kinvault Access">
      <b>
        First thing I need to check is whether you have been able to access your
        kinvault.
      </b>
      <br />
      <em>
        [discuss / prompt login username = email / encourage pw reset if needed]
      </em>
    </CallScriptSection>
    <CallScriptSection title="Kinvault run-through">
      <b>
        So hopefully after the password screen you see a screen that says
        Welcome to your kinvault?
      </b>
      <br /><em>[await acknowledgement]</em> <br /><br />Great. It's laid out in
      8 sections to help guide you through helpful areas of end-of-life
      planning.
    </CallScriptSection>
    <CallScriptSection title="Legal">
      <b>
        The first section is for your legal documents – split ‘before death’
        (LPAs etc) and ‘at-death’ (Will, etc). Your documents with us are
        already there, and you can upload documents, but you shouldn’t need to
        make too many changes to this.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Assets">
      <b>
        If you look across the top and click on the second icon – Assets – that
        takes you to the asset vault where you can list your assets.
        <br /><br />
        I suggest you go down the 9 categories listed on the left, and complete
        as appropriate – they should be fairly self-explanatory.
        <br /><br />
        I do want to emphasise here… I am not able to see your information as it
        is not relevant to us, but it is critical your Executor knows exactly
        where everything is, so that they don’t miss anything – as missed assets
        are essentially lost and will not go to your beneficiaries. So really
        think about things like online accounts or investments, and anything
        else that may be less obvious.
        <br /><br />
        You may have spotted the bottom entry “life time gifts” this is for
        people over the inheritance tax threshold who are doing what we call
        7-year gifting. It avoids the Executor potentially needing to trawl
        through years’ worth of bank accounts, so is an invaluable tool.
      </b>
      <br /><br />
      <em>[check customer can see how to create entries]</em>
    </CallScriptSection>
    <CallScriptSection title="Contacts">
      <b>
        Next section is for your contacts. It may help to think of this as
        having two purposes: firstly, so you can update the contact information
        of people named in your Will, to make it easier for your Executor to
        comply with money laundering regulations and distribute money.
        <br /><br />
        And secondly, you can add people or even entire address books, to help
        your Executor and trusted people notify your friends and make practical
        arrangements about things like funerals.
      </b>
      <br /><br />
      <em>[check customer can see how to update contacts]</em>
    </CallScriptSection>
    <CallScriptSection title="Life Administration">
      <b>
        Next section is for life administration. Clearly this is much more
        practical information, and everyone’s affairs are different.
        <br /><br />
        Like with other sections, it is designed to follow the left-side
        sub-menu down, and provide what is readily available. But particularly
        with things like contractors, it is far easier for your to list work you
        have had done than it is for your Executor to identify and evidence
        works when it comes to selling a property for example.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Care Plan">
      <b>
        The next section is for Care Plans, which some people choose to write to
        give detailed guidance on how they would like to be looked after.
        <br /><br />
        As this is a very personal area of planning, we don’t tend to get
        involved in these, but if you wanted a bit more information there is
        loads on the web and if you wanted some specific help there is link to
        Dead Organised, one of our partner organisations.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Death Plan">
      <b>
        Moving on to death planning… It is up to you how much guidance you give
        your Executors. As a general guide, we think everyone should state their
        funeral wishes – even if just to say you have no strong preferences.
        Personal notes can be anything you want, and other requests are again
        entirely for you.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Permissions">
      <b>
        Finally, you have a permissions tab. This is to give you some control of
        who gets what and when. I should caution, the defaults are set to be
        applicable to most people most of the time…. But there is some
        flexibility to change should you so wish.
        <br /><br />
        If I can just touch on one thing. Our industry is designed around doing
        nothing until there is a death certificate – and this is commonly
        delayed 6 or more months (eg where cause of death is uncertain) and this
        can be hugely distressing for families. We use proof of death instead…
        it doesn’t change the Executor’s legal duty to you, but it simply avoids
        the uncertainty.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Kinvault Closing">
      <b>
        That brings us to the end of the kinvault sections. I know it is quite a
        lot to take in, but does it seem relatively straightforward?
      </b>
      <br />
      <em>[Pause]</em>
    </CallScriptSection>
    <CallScriptSection title="Lifetime Planning">
      <b>
        OK, so that’s everything on the Kinvault.
        <br /><br />
        So, we’ve reached the stage where you’ve got your Will documents and you
        now know how to gather your key information together to make that
        handover easier for your family.
        <br /><br />
        One last thing…
        <br /><br />
        It’s about inheritance tax, which is the tax that’s payable on your
        wealth at the time of death. Clearly things can change between now and
        then, but if you wanted to do some tax planning now would be a good
        time.
        <br /><br />
        We’re not allowed to give you tax advice, but there are a couple of
        things that people sometimes consider at this stage. Would you like me
        to go through a brief summary?
        <br /><br />
      </b>
      <em>[if yes]</em>
      <br /><br />
      <b>
        Firstly - If you’ve got life insurance or pension policies, it’s worth
        checking how these are set up, and if there is a more efficient option
        like paying into trust. It can be easy to set up with a simple form from
        your provider.
        <br /><br />
        Secondly there’s gifting – so giving things away before death… the
        Kinvault has a section for recording gifts.
        <br /><br />
        And finally, some types of assets get better tax-treatment on death
        <br /><br />
        All of these are things your financial adviser or tax accountant can
        help you with, and if I can help set you up with a conversation do let
        me know.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Closing">
      <b>
        We are aware that planning is a little tedious, but I do want to
        emphasise that it is one of the best investments of time you could make…
        to avoid your Executor wasting time tracking down info, spending costs
        unnecessarily, or even worse – missing your assets.
        <br /><br />
        That is everything from me. I hope you have found the process
        informative and reassuring.
        <br /><br />
        Can I ask – if I send you a feedback and reviews form would you mind
        looking at it?
        <br /><br />
        Also – if you have any friends &amp; family that you could refer, that
        would be great, as we are leading the industry and the more people who
        hear about us the more people we can protect.
        <br /><br />
        Otherwise, remember we are here to help in case of any issues, and
        meanwhile I wish you all the best with your planning.
        <br /><br />
        Many thanks, bye
      </b>
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="8.0.0"
        :kintin="kintin"
        @update="updateCheckValue"
      />
      <Button text="Save" color="is-positive" @click="saveHandler" />
    </CallScriptSection>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsCallScriptKinvaultWrapper",
  "route": "KintinDetailsCallScriptKinvault",
  "seletor": ".call-script-kinvault"
}
</cypress-wrapper>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptPersonDetails from "@/module/kinvault.kintin/component/call-script/shared/PersonDetails.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { KintinDetailsCallScriptKinvaultRoute } from "@/module/kinvault.kintin/page/details/call-script/kinvault";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: KintinDetailsCallScriptKinvaultRoute,
  components: {
    PageHeader,
    Button,
    CallScriptSection,
    CallScriptPersonDetails,
    ConfirmCheck,
  },
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  data: () => ({
    isFocused: false,
  }),
  methods: {
    saveHandler() {
      // @todo
      console.log("saveHandler");
      this.$snackBar.success.saved();
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
