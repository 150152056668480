// BrandedKinvaultPricingSchema

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvaultPricingSchema } from "@kinherit/sdk/model/branded-kinvault-pricing-schema";

export const ReadBrandedKinvaultPricingSchema = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/branded-kinvault/kinvault-pricing-schema",
  method: "get",
  rootModel: BrandedKinvaultPricingSchema,
  parse: () => ({}),
});
