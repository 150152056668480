var s = Object.defineProperty;
var o = (e, i) => s(e, "name", { value: i, configurable: !0 });
import { ActionSheetButtonStyle as a, ActionSheet as m } from "@capacitor/action-sheet";
import { Capacitor as r } from "@capacitor/core";
const u = /* @__PURE__ */ o((e) => new Promise((i, l) => {
  if (r.isNativePlatform()) {
    const n = e.items.filter((t) => !e.hideField || !t[e.hideField]).map((t) => ({
      title: t[e.titleField]
    }));
    e.hideCancelButton !== !0 && n.push({
      title: e.cancelButtonLabel ?? "Cancel",
      style: a.Destructive
    }), m.showActions({
      options: n
    }).then((t) => {
      t.index < e.items.length ? i(e.items[t.index]) : l();
    });
  } else
    window.Kernel.Global.loadContextMenuComponent({
      config: e,
      resolve: i,
      reject: l
    });
}), "OpenContextMenu");
export {
  u as OpenContextMenu
};
