import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";

export const ReadProductForm = (): FormBuilder<
  () => {
    description: string;
    created: null | [number, number];
  },
  string
> => {
  return MasterListFiltersForm({
    name: "filter-products",
    data: () => ({
      description: "",
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Description",
            placeholder: "Description",
          },
          models: {
            value: "description",
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
