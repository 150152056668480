<template>
  <MasterListPage
    v-if="$data.filters"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
    class="kinvault-access-requests-master-list"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    title="Kinvault Access Requests"
    :sort-by-options="{
      createdAt: 'Created At',
    }"
    @refresh="refresh"
  >
    <template #actions="{ row }: { row: KinvaultAccessRequest }">
      <p
        v-if="row.status === 'accepted'"
        class="badge has-background-success is-rounded"
      >
        Accepted
      </p>
      <p
        v-else-if="row.status === 'declined'"
        class="badge has-background-danger is-rounded"
      >
        Declined
      </p>
      <p
        v-else-if="row.isExpired"
        class="badge has-background-warning is-rounded"
      >
        Expired
      </p>
      <p v-else class="badge has-background-info is-rounded">Pending</p>
    </template>
    <template #contact="{ row }: { row: KinvaultAccessRequest }">
      <p class="mb-0">
        {{ row.contact.profile.fullName }}
      </p>
      <p style="font-size: small" class="has-text-grey mt-0">
        {{ row.company.profile.fullName }}
      </p>
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { ReadKinvaultAccessRequestsForm } from "@/module/admin.log/form/read-kinvault-access-requests.form";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, In } from "@kinherit/orm/index";
import { IKinvaultAccessRequest, KinvaultAccessRequest } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultAccessRequestsMasterListRoute } from ".";

export default defineComponent({
  name: KinvaultAccessRequestsMasterListRoute,
  components: { MasterListPage },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.admin.log)],
  data: () => ({
    filters: ReadKinvaultAccessRequestsForm(),
    columns: [
      {
        title: "Account",
        mapHtml: (v) => {
          return (
            "<strong>" +
            v.kintin.friendlyName +
            "</strong><small class='is-block'>" +
            v.kintin.ref +
            "</small>"
          );
        },
      },
      {
        title: "Contact",
        slot: "contact",
      },
      {
        title: "Created At",
        map: (v) => v.createdAt.formatYMD,
      },
      {
        title: "Expires At",
        map: (v) => v.expiresAt.formatYMD,
      },
      {
        title: "Status",
        slot: "actions",
        class: "minimise-width",
      },
    ] as Array<TableColumn<KinvaultAccessRequest>>,
    rows: Array<KinvaultAccessRequest>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IKinvaultAccessRequest,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadKinvaultAccessRequestsForm>["localData"],
    ): Promise<void> {
      const { kinvaultAccessRequest, $pagination } =
        await this.$actionBus.ReadKinvaultAccessRequests({
          query: {
            kintin: {
              id: In(formData.kintins),
            },
            contact: {
              id: In(formData.contacts),
            },
            company: {
              id: In(formData.companies),
            },
            createdAt: Between(formData.created),
          },
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = kinvaultAccessRequest;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
