var n = Object.defineProperty;
var o = (i, t, s) => t in i ? n(i, t, { enumerable: !0, configurable: !0, writable: !0, value: s }) : i[t] = s;
var l = (i, t) => n(i, "name", { value: t, configurable: !0 });
var d = (i, t, s) => o(i, typeof t != "symbol" ? t + "" : t, s);
import { ValidationType as c } from "../core/validation-type.js";
const f = "email", g = /* @__PURE__ */ l((i = c.Error) => {
  var t;
  return t = class {
    constructor(s) {
      this.data = s;
    }
    getResult() {
      var r, a;
      let s = this.data.value;
      (r = s == null ? void 0 : s.includes) != null && r.call(s, "<") && ((a = s == null ? void 0 : s.includes) != null && a.call(s, ">")) && (s = s.substring(s.indexOf("<") + 1, s.indexOf(">")));
      const e = {
        message: "Please enter a valid email address.",
        type: i
      };
      return s && ([null, ""].includes(s) === !1 && /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        s
      ) === !1 || s.includes("?")) ? e : null;
    }
  }, d(t, "validatorName", f), t;
}, "EmailValidatorConstructor");
export {
  g as EmailValidatorConstructor,
  f as EmailValidatorName
};
