var F = Object.defineProperty;
var v = (e, s) => F(e, "name", { value: s, configurable: !0 });
import { _ as c } from "../../../component.layout/search/search.vue_vue_type_script_lang.js";
import { resolveComponent as d, resolveDirective as $, createElementBlock as u, openBlock as i, createElementVNode as n, createVNode as k, renderSlot as f, createBlock as o, createCommentVNode as l, withDirectives as p, Fragment as y, withCtx as b } from "vue";
import { _ as z } from "../../../_plugins/_plugin-vue_export-helper.js";
const w = { class: "search-with-filters__top" }, C = { class: "search-with-filters__buttons" }, A = { class: "search-with-filters__advanced-filters__inner" };
function S(e, s, B, O, g, R) {
  const m = d("TextField"), r = d("Button"), h = d("TransitionHeight"), a = $("tool-tip");
  return i(), u("div", {
    class: "search-with-filters",
    onFocusout: s[9] || (s[9] = (t) => e.hasFocus = !1)
  }, [
    n("div", w, [
      n("div", {
        class: "search-with-filters__primary-filters",
        onFocusin: s[1] || (s[1] = (t) => e.hasFocus = !0)
      }, [
        e.$slots.default ? f(e.$slots, "default", { key: 0 }) : (i(), o(m, {
          key: 1,
          value: e.search,
          "onUpdate:value": s[0] || (s[0] = (t) => e.search = t),
          label: "Search",
          "auto-collapse-label": !0
        }, null, 8, ["value"]))
      ], 32),
      n("div", C, [
        e.hideSearch ? l("", !0) : (i(), o(r, {
          key: 0,
          type: "submit",
          size: e.size,
          "icon-left": "Search",
          class: "search-with-filters__search-button",
          color: e.hasFocus ? "is-positive" : "is-dark-grey",
          "aria-label": "Search",
          "cy-label": "search",
          onClick: s[2] || (s[2] = (t) => e.$emit("submit", e.search)),
          onFocusin: s[3] || (s[3] = (t) => e.hasFocus = !0)
        }, null, 8, ["size", "color"])),
        e.hideReset ? l("", !0) : p((i(), o(r, {
          key: 1,
          type: "reset",
          size: e.size,
          "cy-label": "reset",
          "icon-left": "Reset",
          class: "search-with-filters__reset-button",
          color: "is-plain",
          "aria-label": "Reset",
          onClick: s[4] || (s[4] = (t) => e.$emit("reset"))
        }, null, 8, ["size"])), [
          [a, "Reset"]
        ]),
        e.hasAdvancedFilters ? (i(), u(y, { key: 2 }, [
          e.advFiltersOpen ? l("", !0) : p((i(), o(r, {
            key: 0,
            size: e.size,
            "icon-left": "Filter",
            color: "is-plain",
            "aria-label": "Open Additional Filters",
            onClick: s[5] || (s[5] = (t) => e.$emit("update:adv-filters-open", !0))
          }, null, 8, ["size"])), [
            [a, "Open Additional Filters"]
          ]),
          e.advFiltersOpen ? p((i(), o(r, {
            key: 1,
            size: e.size,
            "icon-left": "Close",
            color: "is-plain",
            "aria-label": "Close Additional Filters",
            onClick: s[6] || (s[6] = (t) => e.$emit("update:adv-filters-open", !1))
          }, null, 8, ["size"])), [
            [a, "Close Additional Filters"]
          ]) : l("", !0)
        ], 64)) : l("", !0)
      ])
    ]),
    k(h, null, {
      default: b(() => [
        e.computedAdvFiltersOpen ? (i(), u("div", {
          key: 0,
          class: "search-with-filters__advanced-filters",
          onFocusin: s[7] || (s[7] = (t) => e.hasFocus = !0),
          onFocusout: s[8] || (s[8] = (t) => e.hasFocus = !1)
        }, [
          n("div", A, [
            f(e.$slots, "advanced-filters")
          ])
        ], 32)) : l("", !0)
      ]),
      _: 3
    })
  ], 32);
}
v(S, "_sfc_render");
const E = /* @__PURE__ */ z(c, [["render", S]]);
export {
  E as default
};
