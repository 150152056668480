var a = Object.defineProperty;
var l = (e, t) => a(e, "name", { value: t, configurable: !0 });
import r from "../../_vue/component.display/icon/icon.js";
import { OptionsMixin as d } from "../../component.mixin/options-mixin.js";
import { TranslationMixin as c } from "../../component.mixin/translation.mixin.js";
import { CypressHelper as f } from "../../cypress/component-helpers.js";
import { defineComponent as p } from "vue";
import h from "../../_vue/component.input/button/button.js";
import m from "../../_vue/component.input/checkbox-field/checkbox-field.js";
const S = p({
  name: "DropDownMenuComponent",
  components: { Icon: r, Button: h, CheckboxField: m },
  mixins: [
    d({
      isMultiSelect: !0
    }),
    c({
      defaults: {
        closeButtonLabel: "Close",
        moreButtonLabel: "More...",
        emptyMessage: "No Options Found"
      }
    })
  ],
  props: {
    /**
     * Show a checkbox next to each item
     */
    showCheckbox: {
      type: Boolean,
      default: !1
    },
    /**
     * Message displayed to the use when there are no options to display
     */
    emptyMessage: {
      type: String,
      default: null
    },
    /**
     * Force the background color to be shown
     */
    forceBackground: {
      type: Boolean,
      default: !1
    },
    /**
     * Should the 'More...' button be displayed
     */
    showMoreButton: {
      type: Boolean,
      default: !0
    },
    showCloseButton: {
      type: Boolean,
      default: !1
    },
    size: {
      type: String,
      default: null
    },
    /**
     * Show the dropdown menu
     */
    show: {
      type: Boolean,
      default: !1
    },
    /**
     * Remove the position:absolute property
     */
    isStatic: {
      type: Boolean,
      default: !1
    },
    /**
     * Make the overflow scrollable
     */
    isScrollable: {
      type: Boolean,
      default: !1
    },
    /**
     * The class to apply to the dropdown menu items
     */
    rowClasses: {
      type: Function,
      default: null
    }
  },
  emits: {
    /**
     * Emits the item that was selected
     */
    "update:value": null,
    /**
     * Emits when the more button was selected
     */
    more: null,
    /**
     * Emits when the dropdown has been dismissed
     */
    "update:show": null,
    close: null
  },
  data: /* @__PURE__ */ l((e) => ({
    $cypress: f({ ctx: e }),
    focused: null,
    focusedIndex: 0
  }), "data"),
  computed: {
    computedEmptyMessage() {
      return this.emptyMessage || this.$t.local.emptyMessage;
    },
    compFocusedMenuItem() {
      if (this.focusedIndex === null)
        return null;
      const e = this.$refs.refMenuItem;
      return (e == null ? void 0 : e[this.focusedIndex]) ?? null;
    }
  },
  watch: {
    show(e) {
      e || (this.focused = null);
    }
  },
  mounted() {
    this.isStatic || window.Kernel.KeyListener.addListener({
      code: "Escape",
      listener: this.onEscape,
      ignoreInput: !1
    }), window.Kernel.KeyboardNavigation && this.setFocus("next");
  },
  beforeUnmount() {
    this.isStatic || window.Kernel.KeyListener.removeListener({
      code: "Escape",
      listener: this.onEscape
    });
  },
  methods: {
    keyboardNavigation() {
      return window.Kernel.KeyboardNavigation;
    },
    onClick(e) {
      var t;
      this.isMultiSelect ? this.computedValue = this.computedValue.toggle(
        e,
        "value"
      ) : this.computedValue = ((t = this.computedValue) == null ? void 0 : t.value) === e.value ? null : e, window.Kernel.KeyboardNavigation = !1;
    },
    onUpArrow(e) {
      this.show && this.setFocus("previous", e);
    },
    onDownArrow(e) {
      this.show && this.setFocus("next", e);
    },
    onSpaceEnter(e) {
      var t;
      if (this.show && (this.focused === null && (this.focusedIndex = 0, this.focused = this.computedOptions.first()), this.compFocusedMenuItem)) {
        const o = (t = this.compFocusedMenuItem) == null ? void 0 : t.getAttribute("data-tracking"), i = this.computedOptions.find((n) => {
          var u;
          let s = n.value;
          return s = ((u = s == null ? void 0 : s.toString) == null ? void 0 : u.call(s)) || s, s === o;
        });
        i && (this.computedValue = i, window.Kernel.KeyboardNavigation = !0, e.preventDefault());
      }
    },
    onEscape(e) {
      !this.show || this.isStatic || (e.preventDefault(), this.$emit("update:show", !1));
    },
    setFocus(e, t) {
      if (this.focused === null)
        this.focusedIndex = 0, this.focused = this.computedOptions.first();
      else {
        const i = this.computedOptions.findIndex((n) => JSON.stringify(n) === JSON.stringify(this.focused));
        this.focusedIndex = e === "next" ? Math.min(i + 1, this.computedOptions.length - 1) : Math.max(i - 1, 0), this.focused = this.computedOptions[this.focusedIndex];
      }
      document.activeElement === this.compFocusedMenuItem && this.$refs.refMenuItem.length > 1 && this.focusedIndex === 0 && e === "next" && (this.focusedIndex = 1, this.focused = this.computedOptions[this.focusedIndex]);
      const o = this.compFocusedMenuItem;
      o && (o.focus(), o.scrollIntoView({ block: "nearest" }), t == null || t.preventDefault());
    }
  }
});
export {
  S as _
};
