<template>
  <CreditNotesPage
    v-if="query"
    :has-write-access="hasWriteAccess"
    :query="query"
    @create-credit-note="$emit('create-credit-note')"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "OrderDetailsCreditNotesWrapper",
  "route": "OrderDetailsCreditNotes",
  "extends": {
    "name": "CreditNotesPageWrapper",
    "path": "@/shared/component/credit-note/CreditNotesPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import { OrderDetailsMixin } from "@/module/order/mixin/order-details.mixin";
import { AuthService } from "@/service/auth.service";
import CreditNotesPage from "@/shared/component/credit-note/CreditNotesPage.vue";
import { Equal } from "@kinherit/orm";
import { CreditNote, QueryMask } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { OrderDetailsCreditNotesRoute } from ".";

export default defineComponent({
  name: OrderDetailsCreditNotesRoute,
  components: {
    CreditNotesPage,
  },
  mixins: [OrderDetailsMixin, AuthService.mixin()],
  emits: ["create-credit-note"],
  data: () => ({
    query: null as null | any,
  }),
  mounted(): void {
    this.query = {
      relatedOrder: {
        id: Equal(this.$params.order),
      },
    } satisfies QueryMask<typeof CreditNote>;
  },
});
</script>
