<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
      'call-script-intro': true,
    }"
  >
    <PageHeader htag="h2" text="Planning Call">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
      </template>
    </PageHeader>

    <RouterChildView
      :key="renderKey"
      @save-callscript="saveCallscript"
      @create-order="$emit('create-order')"
    />
  </div>
</template>

<!-- <cypress-wrapper lang="json">
{
  "name": "KintinDetailsCallScriptIntroWrapper",
  "route": "KintinDetailsCallScriptIntro",
  "seletor": ".call-script-intro"
}
</cypress-wrapper> -->

<script lang="ts">
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../mixin/kintin-details.mixin";

import { KintinDetailsCallScript2Call2Route } from "@/module/kinvault.kintin/page/details/call-script-2";

import RouterChildView from "@kinherit/framework/component.misc/router-child-view";

export default defineComponent({
  name: KintinDetailsCallScript2Call2Route,
  components: {
    PageHeader,
    Button,
    RouterChildView,
  },
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  emits: ["save-callscript", "create-order"],
  data: () => ({
    isFocused: false,
    renderKey: 0,
  }),
  methods: {
    async saveCallscript(): Promise<void> {
      this.$emit("save-callscript");
      this.renderKey++;
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
