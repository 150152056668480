// Kintin, Profile, IntroducerContact, AccountReferral

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const ReadAccountReferralCodeKintins = DefineReadAction({
  interface: "portal",
  resource:
    "/v2/portal/introducer/company/referral/{introducerCompany}/code/{accountReferralCode}/kintins",
  method: "get",
  rootModel: Kintin,
  parse: (message: {
    introducerCompany: IntroducerCompany | string;
    accountReferralCode: AccountReferralCode | string;
  }) => ({
    params: {
      introducerCompany: message.introducerCompany,
      accountReferralCode: message.accountReferralCode,
    },
  }),
});
