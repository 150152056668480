var m = Object.defineProperty;
var o = (e, r) => m(e, "name", { value: r, configurable: !0 });
import p from "../../_vue/component.display/calendar/calendar.js";
import t from "../../_vue/component.display/icon/icon.js";
import i from "../../_vue/component.form/field/field.js";
import { FieldMixin as l } from "../../component.mixin/field-mixin.js";
import { CypressHelper as n } from "../../cypress/component-helpers.js";
import { defineComponent as a } from "vue";
const y = a({
  name: "CalendarFieldComponent",
  components: { Icon: t, Field: i, Calendar: p },
  mixins: [l()],
  props: {
    mode: {
      type: String,
      default: "full"
    },
    propsCalendar: {
      type: Object,
      default: /* @__PURE__ */ o(() => ({}), "default")
    }
  },
  emits: ["blur"],
  data: /* @__PURE__ */ o((e) => ({
    $cypress: n({ ctx: e, labelField: "label" })
  }), "data")
});
export {
  y as _
};
