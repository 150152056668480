// KintinAccess

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KintinAccess } from "@kinherit/sdk/model/kintin-access";

export const UpdateKintinAccess = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/admin/user/{user}/kintin-access/{kintinAccess}",
  method: "patch",
  parse: (target: KintinAccess) => ({
    body: {
      accessType: target.$data.accessType,
      legal: target.$data.legal,
      assets: target.$data.assets,
      contacts: target.$data.contacts,
      admin: target.$data.admin,
      care: target.$data.care,
      death: target.$data.death,
      misc: target.$data.misc,
      settings: target.$data.settings,
      allowAccess: target.$data.allowAccess,
      kintin: target.$data.kintin,
      user: target.$data.user,
      introducerContact: target.$data.introducerContact,
      brandedKinvault: target.$data.brandedKinvault,
    },
    params: {
      kintinAccess: target,
      user: target.$data.user!,
    },
  }),
});
