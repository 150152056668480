<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
      'call-script-lpa-only': true,
    }"
  >
    <PageHeader htag="h2" text="LPA Only">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <Button text="Save" color="is-primary" @click="saveHandler" />
      </template>
    </PageHeader>

    <Tabs
      v-model:tab="tab"
      position="both"
      is-lazy
      :config="
        [
          'Intro',
          'Security',
          'Coercion',
          'Client Details',
          'LPA',
          'IFA',
          'Storage',
          'Order',
        ].map((x) => ({ label: x }))
      "
    >
      <template #intro>
        <IntroTab />
      </template>
      <template #security>
        <SecurityTab />
      </template>
      <template #coercion>
        <CoercionTab />
      </template>
      <template #client-details>
        <ClientDetailsTab />
      </template>
      <template #lpa>
        <LpaTab />
      </template>
      <template #ifa>
        <IfaTab />
      </template>
      <template #storage>
        <StorageTab />
      </template>
      <template #order>
        <OrderTab
          @create-order="$emit('create-order')"
          @send-order-payment-email="sendOrderPaymentEmail"
          @save="saveHandler"
        />
      </template>
    </Tabs>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsCallScriptLPAOnlyWrapper",
  "route": "KintinDetailsCallScriptLPAOnly",
  "seletor": ".call-script-lpa-only"
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { KintinDetailsCallScriptParams } from "..";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";
import { KintinDetailsCallScriptClientSummaryRoute } from "../client-summary";
import ClientDetailsTab from "./ClientDetailsTab.vue";
import CoercionTab from "./CoercionTab.vue";
import IfaTab from "./IfaTab.vue";
import IntroTab from "./IntroTab.vue";
import LpaTab from "./LpaTab.vue";
import OrderTab from "./OrderTab.vue";
import SecurityTab from "./SecurityTab.vue";
import StorageTab from "./StorageTab.vue";

import { SendOrderPaymentEmailForm } from "@/module/kinvault.kintin/form/call-script.form/send-order-payment-email.form";
import { KintinDetailsCallScriptLPAOnlyRoute } from "@/module/kinvault.kintin/page/details/call-script/lpa-only";
export default defineComponent({
  name: KintinDetailsCallScriptLPAOnlyRoute,
  components: {
    PageHeader,
    Button,
    Tabs,
    IntroTab,
    SecurityTab,
    CoercionTab,
    ClientDetailsTab,
    LpaTab,
    IfaTab,
    StorageTab,
    OrderTab,
  },
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  emits: { "create-order": null },
  data: () => ({
    isFocused: false,
    renderKey: 0,
    person2Present: null as boolean | null,
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
    tab: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
  methods: {
    async saveAndContinueHandler() {
      if (!this.kintin) {
        return;
      }

      await this.saveHandler();

      this.$snackBar.success.saved();
      window.Kernel.visitRoute({
        name: KintinDetailsCallScriptClientSummaryRoute,
        params: { kintin: this.kintin.id } as KintinDetailsCallScriptParams,
      });
    },
    async saveHandler() {
      if (!this.kintin) {
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.person.update({
      await this.$actionBus.person.UpdatePerson(this.kintin.primaryPerson);

      if (this.kintin.secondaryPerson) {
        // await window.Kernel.ActionBus.kinvaultKintin.person.update({
        await this.$actionBus.person.UpdatePerson(this.kintin.secondaryPerson);
      }

      this.$snackBar.success.saved();
    },
    async sendOrderPaymentEmail() {
      if (!this.kintin) {
        return;
      }

      const result = await SendOrderPaymentEmailForm(this.kintin).dialog({
        dialog: {
          title: "Send Order Payment Email",
        },
      });

      await this.$actionBus.order.SendOrderPaymentEmail({
        order: result.order as string,
        person: result.person as string,
        kintin: this.$params.kintin,
      });
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
