import { Api } from "@kinherit/sdk";

export interface CreateSortReferNoteMessage {
  externalId: string;
  note: string;
}

export const CreateSortReferNote = async (
  message: CreateSortReferNoteMessage,
): Promise<void> => {
  const request = Api.resource("external", "/v2/external/sort-refer/note")
    .method("post")
    .body({
      externalId: message.externalId,
      note: message.note,
    });

  await request.result();
};
