<template>
  <Message
    :title-heading-tag="'h6'"
    :title-size="'is-5'"
    :title="computedTitle"
    :color="color"
    class="collapsable-warning-widget__content"
  >
    <slot />
    <div v-for="(warning, i) in displayedItems" :key="'warning' + i">
      <span class="ml-2">{{ warning }}</span>
      <div v-if="isLastVisibleItem(i)" class="fade-effect" />
    </div>
    <Button
      v-if="shouldShowToggle"
      size="is-small"
      :icon-left="expanded ? 'ChevronUp' : 'ChevronDown'"
      is-compact
      :color="color"
      class="show-all-toggle mt-1"
      @click="toggleExpanded"
    >
      {{ expanded ? "Show Less" : "Show All" }}
    </Button>
  </Message>
</template>

<script lang="ts">
import Message from "@kinherit/framework/component.display/message";
import Button from "@kinherit/framework/component.input/button";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "CollapsableWarningWidget",
  components: {
    Message,
    Button,
  },
  props: {
    items: {
      type: Array as () => string[],
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "Warning",
    },
    numberOfItemsToShow: {
      type: Number,
      required: false,
      default: 3,
    },
    color: {
      type: String as PropType<"is-warning" | "is-danger">,
      required: false,
      default: "is-warning",
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    computedTitle(): string {
      return this.items.length > 1
        ? `${this.title}s (${this.items.length})`
        : this.title;
    },
    shouldShowToggle(): boolean {
      return this.items.length > this.numberOfItemsToShow;
    },
    displayedItems(): string[] {
      return this.expanded
        ? this.items
        : this.items.slice(0, this.numberOfItemsToShow);
    },
  },
  methods: {
    toggleExpanded(): void {
      this.expanded = !this.expanded;
    },
    isLastVisibleItem(index: number): boolean {
      return index === this.numberOfItemsToShow - 1 && !this.expanded;
    },
  },
});
</script>

<style scoped>
.collapsable-warning-widget__content.is-warning {
  border: 1px solid var(--color-warning);
}
.collapsable-warning-widget__content.is-danger {
  border: 1px solid var(--color-danger);
}

.fade-effect {
  /* Gradient styles */
  background: linear-gradient(to bottom, transparent, white);
  height: 80px; /* Adjust height as needed */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
}

.show-all-toggle {
  position: absolute;
  /* Set z-index to ensure it's above the gradient */
  z-index: 10;
  cursor: pointer;
  /* Rest of the styles */
  transition: all 0.3s ease;
}
</style>
