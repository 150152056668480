<template>
  <table v-if="data" class="table-box">
    <tbody>
      <tr v-for="(trustee, index) in data.trustees" :key="trustee.id">
        <td>
          <strong>Trustee No. <span v-html="helpers.var(index + 1)" /></strong>
          <br />
          <span class="ucase"
            ><span
              v-html="
                helpers.var(trustee.person.profile.fullName?.toUpperCase())
              "
          /></span>
          of
          <span
            v-html="
              helpers.formatAddress(
                trustee.person.profile.addresses.findBy(
                  'primaryResidential',
                  true,
                ),
              )
            "
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: `TrusteesOutput`,
  mixins: [WillBuilderService.mixin],
});
</script>

<style lang="scss" scoped>
.table-box {
  border: thick double black;
  width: 15cm;
  margin: auto;
  tbody {
    tr {
      td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
      }
    }
  }
}
</style>
