import { Api } from "@/service/api.service";
import { IntroducerCpd } from "@kinherit/sdk";

export const GenerateCpdCertificate = async (
  introducerCpd: IntroducerCpd,
): Promise<void> => {
  const name = introducerCpd.contact.profile.fullName;
  await Api.resource(
    "portal",
    "/v2/portal/cpd/introducer/introducer-cpd/{introducerCpd}/certificate",
    {
      introducerCpd: introducerCpd,
    },
  )
    .method("get")

    .download({
      encoding: "application/pdf",
      fileName: `CPD Certificate - ${name}`,
    });
};
