var h = Object.defineProperty;
var d = (e, o) => h(e, "name", { value: o, configurable: !0 });
import { _ as g } from "../../../component.display/message/message.vue_vue_type_script_lang.js";
import { resolveComponent as m, createElementBlock as a, openBlock as s, normalizeClass as r, createCommentVNode as l, createElementVNode as t, createBlock as i, toDisplayString as p, renderSlot as f } from "vue";
import { _ as k } from "../../../_plugins/_plugin-vue_export-helper.js";
const b = { class: "columns" }, v = { class: "column" }, B = { key: 0 }, y = { key: 1 }, C = {
  class: "column",
  style: { "flex-grow": "0" }
};
function $(e, o, z, S, w, T) {
  const u = m("Title"), n = m("Button");
  return s(), a("div", {
    class: r(["message", [
      e.color,
      e.shade,
      e.size,
      {
        "has-shadow": e.dropShadow,
        "has-border-left": e.hasBorderLeft,
        "is-borderless": e.isBorderless
      }
    ]])
  }, [
    e.title ? (s(), a("div", {
      key: 0,
      class: r(["message-header", [
        e.headerClasses,
        e.color,
        e.shade,
        {
          "has-background-color": e.hasHeadingBackgroundColor
        }
      ]])
    }, [
      e.title ? (s(), i(u, {
        key: 0,
        htag: e.titleHeadingTag,
        size: e.titleSize ?? "is-4",
        class: "is-marginless",
        "is-spaced": "",
        "is-html": !0,
        text: e.title
      }, null, 8, ["htag", "size", "text"])) : l("", !0),
      e.closeButton ? (s(), i(n, {
        key: 1,
        class: "message-close-button",
        "icon-left": "Close",
        color: e.color,
        shade: e.shade,
        "aria-label": "close",
        onClick: o[0] || (o[0] = (c) => e.$emit("close"))
      }, null, 8, ["color", "shade"])) : l("", !0)
    ], 2)) : l("", !0),
    t("div", {
      class: r(["message-body", {
        "is-borderless": e.isBorderless,
        [e.bodyClasses]: !0
      }])
    }, [
      t("div", b, [
        t("div", v, [
          e.text ? (s(), a("div", B, p(e.text), 1)) : e.defaultSlot ? (s(), a("div", y, [
            f(e.$slots, "default")
          ])) : l("", !0)
        ]),
        t("div", C, [
          e.closeButton && !e.title ? (s(), i(n, {
            key: 0,
            "icon-left": "Close",
            color: e.color,
            shade: e.shade,
            class: "message-close-button",
            "aria-label": e.$t.local.close,
            onClick: o[1] || (o[1] = (c) => e.$emit("close"))
          }, null, 8, ["color", "shade", "aria-label"])) : l("", !0)
        ])
      ])
    ], 2)
  ], 2);
}
d($, "_sfc_render");
const D = /* @__PURE__ */ k(g, [["render", $]]);
export {
  D as default
};
