import { CreateOrderRefundRequest } from "@/action-bus/portal/orders/refund-request/create-order-refund-request.action";
import { DeleteOrderRefundRequest } from "@/action-bus/portal/orders/refund-request/delete-order-refund-request.action";
import { UpdateOrderRefundRequest } from "@/action-bus/portal/orders/refund-request/update-order-refund-request.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadOrderRefundRequest } from "./read-order-refund-request.action";

export const Portal2OrdersRefundRequest = DefineActionBus({
  name: "portal-orders-refund-request",
  actions: {
    ReadOrderRefundRequest,
    UpdateOrderRefundRequest,
    CreateOrderRefundRequest,
    DeleteOrderRefundRequest,
  },
});
