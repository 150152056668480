// Gift

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Gift } from "@kinherit/sdk/model/gift";

export const DeleteGift = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/gift/{gift}",
  method: "delete",
  parse: (gift: Gift) => ({
    params: {
      gift,
      kintin: gift.$data.kintin,
    },
  }),
  after: ({ message }) => {
    Gift.$delete(message);
  },
});
