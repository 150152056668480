import { Api } from "@kinherit/sdk/api";
import { Kintin, KintinAccess } from "@kinherit/sdk";

// KintinAccess
export const RevokeAccess = async (message: { kintin: string | Kintin }) => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/status/revoke-assigned-users-access",
    {
      kintin: message.kintin,
    },
  )
    .method("put")
    .result();

  return {
    kintinAccess: KintinAccess.$inflate(response.kintinAccess),
  };
};
