// CreditNote, Note

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { CreditNote } from "@kinherit/sdk/model/credit-note";

export const DeleteCreditNote = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/credit-note/{creditNote}",
  method: "delete",
  parse: (creditNote: CreditNote | string) => ({
    params: {
      creditNote,
    },
  }),
});
