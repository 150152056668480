var o = Object.defineProperty;
var c = (e, t, i) => t in e ? o(e, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : e[t] = i;
var f = (e, t) => o(e, "name", { value: t, configurable: !0 });
var n = (e, t, i) => c(e, typeof t != "symbol" ? t + "" : t, i);
import { ValidationType as m } from "../core/validation-type.js";
const d = "file-size", z = /* @__PURE__ */ f(({
  maxMegaBites: e,
  type: t = m.Error
}) => {
  var i;
  return i = class {
    constructor(s) {
      this.data = s;
    }
    getResult() {
      if (this.data.input === void 0)
        return null;
      const r = Array.from(this.data.input.files || []).filter(
        (l) => l.size > e * 1048576
      );
      if (r.length > 0) {
        let l = "";
        return r.forEach((u, a) => {
          r.length - 1 === a && a !== 0 ? l += " & " : a !== 0 && (l += ", "), l += `${u.name}`;
        }), {
          message: `Maximum upload is ${Math.floor(
            e
          )}MB, please remove ${l}.`,
          type: t
        };
      }
      return null;
    }
  }, n(i, "validatorName", d), i;
}, "FileSizeValidatorConstructor");
export {
  z as FileSizeValidatorConstructor,
  d as FileSizeValidatorName
};
