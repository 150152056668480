<template>
  <ProfileSummary
    v-if="introducerCompany && !loading"
    :key="key"
    class="introducer-company-details"
    :profile-id="introducerCompany.profile.id"
    :tabs="tabs"
    :has-context-menu="showContextMenu"
    @context-menu="contextMenu"
  >
    <template #sidebar-above>
      <RouterChildView name="sidebar">
        <IntroducerCompanyDetailsDefaultSidebar />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView
        name="default"
        @create-billing-contact="createBillingContact"
        @create-contract="createContract"
        @create-contact="createIntroducerContact"
        @update-company-partner-portal="editPartnerPortalSettings"
      >
        <IntroducerCompanyDetailsSummary />
      </RouterChildView>
    </template>
  </ProfileSummary>
  <PageNotFound v-else-if="!loading" />
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsWrapper",
  "route": "IntroducerCompanyDetails",
  "selector": ".introducer-company-details",
  "imports": {
    "IntroducerCompanyDetailsSummaryWrapper": "@/module/introducer.company/page/details/summary/IntroducerCompanyDetails.Summary.test",
    "IntroducerCompanyDetailsNotesWrapper": "@/module/introducer.company/page/details/notes/IntroducerCompanyDetails.Notes.test",
    "IntroducerCompanyDetailsEmailLogWrapper": "@/module/introducer.company/page/details/email-log/IntroducerCompanyDetails.EmailLog.test",
    "IntroducerCompanyDetailsContactsWrapper": "@/module/introducer.company/page/details/contacts/IntroducerCompanyDetails.Contacts.test",
    "IntroducerCompanyDetailsReferralsWrapper": "@/module/introducer.company/page/details/referrals/IntroducerCompanyDetails.Referrals.test",
    "IntroducerCompanyDetailsContractsWrapper": "@/module/introducer.company/page/details/contracts/IntroducerCompanyDetails.Contracts.test",
    "IntroducerCompanyDetailsAccountingIndexWrapper": "@/module/introducer.company/page/details/accounting/Index.test",
    "IntroducerCompanyDetailsPartnerPortalWrapper": "@/module/introducer.company/page/details/partner-portal/IntroducerCompanyDetails.PartnerPortal.test",
    "IntroducerCompanyDetailsSidebarWrapper": "@/module/introducer.company/page/details/IntroducerCompanyDetails.Sidebar.test"
  },
  "methods": {
    "contextMenu": {
      "type": "context-menu",
      "selector": ".js-page-header-context-menu",
      "items": [
        "Edit Profile",
        "Create Note",
        "Create Contract",
        "Create Referral Code",
        "Create Contact",
        "Create Billing Contact",
        "Settings",
        "Partner Portal",
        "Delete"
      ]
    },
    "tabs": {
      "type": "tabs",
      "items": [
        "Summary",
        "Notes",
        "Emails",
        "Contacts",
        "Referrals",
        "Contracts",
        "Accounting",
        "Portal"
      ]
    },
    "summary": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsSummaryWrapper"
    },
    "notes": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsNotesWrapper"
    },
    "emails": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsEmailLogWrapper"
    },
    "contacts": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsContactsWrapper"
    },
    "referrals": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsReferralsWrapper"
    },
    "contracts": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsContractsWrapper"
    },
    "accounting": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsAccountingIndexWrapper"
    },
    "portal": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsPartnerPortalWrapper"
    },
    "sidebar": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsSidebarWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import {
  CreateCompanyPartnerPortalPermissions,
  CreateIntroducerContact,
} from "@/config/model.config";
import { IntroducerCompanyDetailsMixin } from "@/module/introducer.company/mixin/introducer-company-details.mixin";
import { CreateIntroducerContactForm } from "@/module/introducer.contact/form/create-introducer-contact.form";
import { UpdateIntroducerContractForm } from "@/module/introducer.contract/form/update-introducer-contract.form";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import ProfileSummary from "@/shared/component/profile/ProfileSummary.vue";
import { UpdateNoteForm } from "@/shared/form/note/update-note.form";
import { UpdateProfileForm } from "@/shared/form/profile/update-profile.form";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { TabOptions } from "@kinherit/framework/component.display/tabs";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { IntroducerBillingContact } from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import {
  IntroducerCompanyDetailsParams,
  IntroducerCompanyDetailsRoute,
} from ".";
import { IntroducerCompanyMasterListRoute } from "..";
import { AddReferralCodeForm } from "../../form/add-referral-code.form";
import { IntroducerCompanyPartnerPortalSettingsForm } from "../../form/introducer-company-partner-portal-settings.form";
import { IntroducerCompanySettingsForm } from "../../form/introducer-company-settings.form";
import IntroducerCompanyDetailsDefaultSidebar from "./IntroducerCompanyDetails.Sidebar.vue";
import { IntroducerCompanyDetailsAccountingRoute } from "./accounting";
import { IntroducerCompanyDetailsContactsRoute } from "./contacts";
import { IntroducerCompanyDetailsContractsRoute } from "./contracts";
import { IntroducerCompanyDetailsEmailLogRoute } from "./email-log";
import { IntroducerCompanyDetailsNotesRoute } from "./notes";
import { IntroducerCompanyDetailsPartnerPortalRoute } from "./partner-portal";
import {
  IntroducerCompanyDetailsReferralCodesRoute,
  IntroducerCompanyDetailsReferralFormRoute,
  IntroducerCompanyDetailsReferralsRoute,
} from "./referrals";
import { IntroducerCompanyDetailsSummaryRoute } from "./summary";
import IntroducerCompanyDetailsSummary from "./summary/IntroducerCompanyDetails.Summary.vue";
import { IntroducerCompanyDetailsUpdateProfileRoute } from "./update-profile";

export default defineComponent({
  name: IntroducerCompanyDetailsRoute,
  components: {
    PageNotFound,
    RouterChildView,
    IntroducerCompanyDetailsSummary,
    IntroducerCompanyDetailsDefaultSidebar,
    ProfileSummary,
  },
  mixins: [IntroducerCompanyDetailsMixin, StyleService.mixin],
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    const params = to.params as IntroducerCompanyDetailsParams;
    //     await window.Kernel.ActionBus.introducerCompany.company.record(params);
    await window.Kernel.ActionBus2.portal.introducer.company.RecordIntroducerCompany(
      { message: params.introducerCompany },
    );
  },
  data: (ctx) => ({
    key: 0,
    loading: true,
    tabs: [
      {
        label: "Summary",
        route: {
          name: IntroducerCompanyDetailsSummaryRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
      {
        label: "Notes",
        route: {
          name: IntroducerCompanyDetailsNotesRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.notes.icon,
      },
      {
        label: "Emails",
        route: {
          name: IntroducerCompanyDetailsEmailLogRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.email.icon,
      },
      {
        label: "Contacts",
        route: {
          name: IntroducerCompanyDetailsContactsRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.contact.icon,
      },
      {
        label: "Referrals",
        route: {
          name: IntroducerCompanyDetailsReferralsRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.referral.icon,
        nestedRoutes: [
          IntroducerCompanyDetailsReferralCodesRoute,
          IntroducerCompanyDetailsReferralsRoute,
          IntroducerCompanyDetailsReferralFormRoute,
        ],
      },
      {
        label: "Contracts",
        route: {
          name: IntroducerCompanyDetailsContractsRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.contract.icon,
      },
      {
        label: "Accounting",
        route: {
          name: IntroducerCompanyDetailsAccountingRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.accounting.icon,
      },
      {
        label: "Portal",
        route: {
          name: IntroducerCompanyDetailsPartnerPortalRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.organisation.icon,
      },
    ] as TabOptions[],
    tab: 0,
  }),
  computed: {
    showContextMenu(): boolean {
      return AuthService.hasPermission("introducer-company:write");
    },
  },
  async mounted(): Promise<void> {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      await AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Edit Profile",
              line1: `Edit ${this.introducerCompany.profile.fullName} profile`,
              iconLeft: this.$style.icon.profile.icon,
              permissions: "introducer-company:write",
              action: this.editProfile,
            },
            {
              title: "Create Note",
              line1: `Add a note to ${this.introducerCompany.profile.fullName}`,
              iconLeft: this.$style.icon.notes.icon,
              permissions: "introducer-company:write",
              action: this.createIntroducerNote,
            },
            {
              title: "Create Contract",
              line1: `Create a contract for ${this.introducerCompany.profile.fullName}`,
              iconLeft: this.$style.icon.contract.icon,
              permissions: "introducer-company:write",
              action: this.createContract,
            },
            {
              title: "Create Referral Code",
              line1: `Create a referral code for ${this.introducerCompany.profile.fullName}`,
              iconLeft: this.$style.icon.referral.icon,
              permissions: "introducer-company:write",
              action: this.createReferral,
            },
            {
              title: "Create Contact",
              line1: `Create a contact for ${this.introducerCompany.profile.fullName}`,
              iconLeft: this.$style.icon.contact.icon,
              permissions: "introducer-company:write",
              action: this.createIntroducerContact,
            },
            {
              title: "Create Billing Contact",
              line1: `Create a billing contact for ${this.introducerCompany.profile.fullName}`,
              iconLeft: this.$style.icon.contact.icon,
              permissions: "introducer-company:write",
              action: this.createBillingContact,
            },
            {
              title: "Settings",
              line1: `Edit ${this.introducerCompany.profile.fullName} settings`,
              iconLeft: this.$style.icon.settings.icon,
              permissions: "introducer-company:write",
              action: this.editSettings,
            },
            {
              title: "Partner Portal",
              line1: `Configure Partner Portal settings`,
              iconLeft: this.$style.icon.settings.icon,
              permissions: "introducer-company:write",
              action: this.editPartnerPortalSettings,
            },
            {
              title: "Delete",
              line1: `Delete ${this.introducerCompany.profile.fullName}`,
              iconLeft: this.$style.icon.delete.icon,
              permissions: "introducer-company:write",
              action: this.deleteIntroducerCompany,
            },
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "iconLeft",
        },
        path: "permissions",
        filter: "all",
        callback: (item) => item.action(),
      })();
    },
    async deleteIntroducerCompany(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Introducer Company",
          message: `Are you sure you want to delete ${this.introducerCompany.profile.fullName}?`,
        },
        button: {
          ok: {
            text: "Delete",
          },
        },
      });

      await this.$actionBus.company.DeleteIntroducerCompany(
        this.introducerCompany,
      );

      window.Kernel.Router.push({
        name: IntroducerCompanyMasterListRoute,
      });
    },
    editProfile(): void {
      window.Kernel.Router.push({
        name: IntroducerCompanyDetailsUpdateProfileRoute,
        params: this.$params,
      });
    },
    async createContract(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      const introducerContract = await UpdateIntroducerContractForm().dialog({
        dialog: {
          title: "Create Contract",
        },
      });

      introducerContract.company = this.introducerCompany;

      // await window.Kernel.ActionBus.introducerCompany.contracts.create({
      await this.$actionBus.contract.CreateIntroducerContract(
        introducerContract,
      );
      this.key++;
    },
    async createIntroducerNote(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      const { note, files } = await UpdateNoteForm({
        data: "create-introducer-note",
      }).dialog({
        dialog: {
          title: "Create note",
        },
        button: {
          ok: {
            text: "Save",
          },
        },
      });

      if (!note.introducerNote) {
        throw new Error("Introducer note is not defined");
      }

      note.company = this.introducerCompany;

      await window.Kernel.ActionBus2.core.notes.CreateNote({
        note,
        files,
        introducerCompany: this.introducerCompany,
      });
      this.key++;
    },
    async createReferral(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      const referralCode = await AddReferralCodeForm().dialog({
        dialog: {
          title: "Create Referral Code",
        },
      });

      referralCode.company = this.introducerCompany;

      // await window.Kernel.ActionBus.adminAccountReferralCode.create({
      //   accountReferralCode: referralCode as AccountReferralCode,
      // });
      await window.Kernel.ActionBus2.portal.admin.accountReferralCodes.CreateAccountReferralCode(
        referralCode,
      );
      this.key++;
    },
    async createBillingContact(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      const profile = await UpdateProfileForm().dialog({
        dialog: {
          title: "Create Billing Contact",
        },
      });

      const introducerBillingContact = new IntroducerBillingContact({
        id: Uuid.generate(),
        profile: profile.id,
        company: this.introducerCompany.id,
        createdAt: new DateTime().formatMachine,
        isGlobal: false,
      });

      await this.$actionBus.billingContacts.CreateIntroducerBillingContact(
        introducerBillingContact,
      );

      this.key++;
    },
    async createIntroducerContact(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      const introducerContact = CreateIntroducerContact({});

      introducerContact.addCompanies(this.introducerCompany);

      await CreateIntroducerContactForm(introducerContact).dialog({
        dialog: {
          title: "Create Introducer Contact",
        },
      });

      await this.$actionBus.contact.CreateIntroducerContact(introducerContact);

      this.key++;
    },
    async editSettings(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      try {
        await IntroducerCompanySettingsForm(this.introducerCompany).dialog({
          dialog: {
            title: "Edit Introducer Company Settings",
          },
        });
      } catch {
        this.introducerCompany.$restore();
        return;
      }

      await this.$actionBus.company.UpdateIntroducerCompany(
        this.introducerCompany,
      );
    },
    async editPartnerPortalSettings(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      let permissions = null;
      let isCreating = false;

      if (null === this.introducerCompany.companyPartnerPortalPermissions) {
        permissions = CreateCompanyPartnerPortalPermissions({});
        permissions.company = this.introducerCompany;
        isCreating = true;
      } else {
        permissions = this.introducerCompany.companyPartnerPortalPermissions;
      }

      const companyPartnerPortalPermissions =
        await IntroducerCompanyPartnerPortalSettingsForm(permissions)
          .dialog({
            dialog: {
              title: "Partner Portal Permissions",
            },
          })
          .catch((error) => {
            permissions.$restore({});
            throw error;
          });

      if (isCreating) {
        // await window.Kernel.ActionBus.introducerCompany.company.partnerPortalPermissions.create(
        //   {
        //     companyPartnerPortalPermissions,
        //   },
        // );
        await this.$actionBus.company.partnerPortalPermissions.CreateIntroducerCompanyPartnerPortal(
          companyPartnerPortalPermissions,
        );
      } else {
        // await window.Kernel.ActionBus.introducerCompany.company.partnerPortalPermissions.update(
        //   {
        //     companyPartnerPortalPermissions,
        //   },
        // )
        await this.$actionBus.company.partnerPortalPermissions.UpdateIntroducerCompanyPartnerPortal(
          companyPartnerPortalPermissions,
        );
      }
      this.key++;
    },
  },
});
</script>
