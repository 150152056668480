<template>
  <div class="kinvault-price-master-list">
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      title="Stripe Prices"
      subtitle="As synced from Stripe"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        createdAt: 'Created At',
      }"
      @refresh="refresh"
    >
      <template #title="{ row }: { row: KinvaultPrice }">
        {{ row.title }}<br />
        {{ row.stripePriceId }}
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { ReadKinvaultPriceForm } from "@/module/kinvault.subscription/form/read-kinvault-price.form";
import { KinvaultPriceMasterListRoute } from "@/module/kinvault.subscription/page";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Equal, Like } from "@kinherit/orm/index";
import { IKinvaultPrice, KinvaultPrice } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: KinvaultPriceMasterListRoute,
  components: { MasterListPage },
  mixins: [
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal.kinvaults.subscription.price,
    ),
  ],
  data: () => ({
    filters: ReadKinvaultPriceForm(),
    columns: [
      {
        title: "Title",
        slot: "title",
      },
      {
        title: "Description",
        field: "description",
      },
      {
        title: "Price",
        map: (val) => val.price?.toFormattedString(),
      },
      {
        title: "Active",
        mapHtml: (val) =>
          val.active
            ? `<span class="badge badge-success">Active</span>`
            : `<span class="badge badge-danger">Inactive</span>`,
      },
      {
        title: "Created",
        map: (val) => val.createdAt.formatYMD,
      },
    ] as Array<TableColumn<KinvaultPrice>>,
    rows: Array<KinvaultPrice>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IKinvaultPrice,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    handleClick(kinvaultPrice: KinvaultPrice, event: MouseEvent): void {
      console.log({
        kinvaultPrice,
        event,
      });
    },
    async refresh(
      formData: ReturnType<typeof ReadKinvaultPriceForm>["localData"],
    ): Promise<void> {
      const data = await this.$actionBus.ReadKinvaultPrice({
        query: {
          title: Like(formData.title),
          stripePriceId: Like(formData.stripePriceId),
          active: Equal(formData.active),
          createdAt: Between(formData.created),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.kinvaultPrice;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
