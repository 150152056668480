//

import { Api } from "@/service/api.service";
import { Equal } from "@kinherit/orm";
import { CpdSession, IntroducerCpd } from "@kinherit/sdk";

export const DownloadIntroducerCpdCsv = async (
  message: CpdSession,
): Promise<void> => {
  const request = Api.resource("portal", "/v2/portal/cpd/introducer/csv")
    .method("get")
    .paginate({
      currentPage: 1,
      perPage: 1000,
    });

  request.buildQuery(IntroducerCpd).where({
    session: {
      id: Equal(message.id),
    },
  });

  await request.download({
    fileName: "introducer-cpd.csv",
    encoding: "text/csv",
  });
};
