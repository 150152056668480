// IntroducerNetwork

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { IntroducerNetwork } from "@kinherit/sdk/model/introducer-network";

export const DeleteIntroducerNetwork = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/networks/{introducerNetwork}",
  method: "delete",
  parse: (introducerNetwork: IntroducerNetwork | string) => ({
    params: {
      introducerNetwork,
    },
  }),
});
