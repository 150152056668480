<template>
  <div class="email-campaign-details-logs">
    <PageHeader text="Emails">
      <template #buttons>
        <Button text="Create" @click="handleCreate()" />
      </template>
    </PageHeader>
    <MasterListPage
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      is-nested
      :columns="columns"
      :rows="rows"
      :filters="$data.filters"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :is-clickable="true"
      :sort-by-options="{
        scheduledToSendAt: 'Scheduled',
        createdAt: 'Created',
      }"
      @refresh="refresh"
    >
      <template #actions="{ row }">
        <div class="buttons is-right">
          <Button text="Edit" @click="handleEdit(row as EmailCampaignLog)" />
          <Button
            v-if="(row as EmailCampaignLog).canEdit"
            text="Delete"
            @click="handleDelete(row as EmailCampaignLog)"
          />
        </div>
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "EmailCampaignDetailsLogsWrapper",
  "route": "EmailCampaignDetailsLogs",
  "selector": ".email-campaign-details-logs",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "imports": {
    "ReadEmailCampaignLogFormWrapper": "@/module/admin.email-campaign/form/read-email-campaign-logs.form.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadEmailCampaignLogFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { CreateEmailCampaignLog } from "@/config/model.config";
import { ReadEmailCampaignLogForm } from "@/module/admin.email-campaign/form/read-email-campaign-logs.form";
import { UpdateEmailCampaignLogForm } from "@/module/admin.email-campaign/form/update-campaign-log.form";
import { EmailCampaignDetailsMixin } from "@/module/admin.email-campaign/mixin/email-campaign-details.mixin";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { EmailCampaignLog, IEmailCampaignLog } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { EmailCampaignDetailsLogsRoute } from ".";

export default defineComponent({
  name: EmailCampaignDetailsLogsRoute,
  components: {
    MasterListPage,
    Button,
    PageHeader,
  },
  mixins: [EmailCampaignDetailsMixin],
  data: () => ({
    filters: ReadEmailCampaignLogForm(),
    columns: [
      {
        title: "Title",
        map: (log) => log.title,
      },
      {
        title: "Scheduled",
        map: (log) => log.scheduledToSendAt?.formatDate,
      },
      {
        title: "Status",
        map: (log) => log.status.ucFirst(),
      },
      {
        title: "Created",
        map: (log) => log.createdAt.formatDate,
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn<EmailCampaignLog>>,
    rows: Array<EmailCampaignLog>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "scheduledToSendAt" as keyof IEmailCampaignLog,
      direction: "asc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(
      _?: any,
      by?: string,
      direction?: "desc" | "asc",
    ): Promise<void> {
      this.sort.by = (by as keyof IEmailCampaignLog) ?? this.sort.by;
      this.sort.direction = direction ?? this.sort.direction;

      const data = await this.$actionBus.log.ReadEmailCampaignLog({
        ...(this.filters.localData as any),
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.emailCampaignLog;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async handleEdit(emailCampaignLog: EmailCampaignLog): Promise<void> {
      await this.$actionBus.log.RecordEmailCampaignLog({
        message: emailCampaignLog,
      });

      try {
        const { files } = await UpdateEmailCampaignLogForm({
          emailCampaignLog: emailCampaignLog,
          isNew: false,
        }).dialog({
          dialog: {
            title: "Update Campaign Email Log",
          },
        });

        // await window.Kernel.ActionBus.adminEmailCampaign.campaignLog.update({
        await this.$actionBus.log.UpdateEmailCampaignLog({
          emailCampaignLog,
          files,
        });
      } catch {
        emailCampaignLog.$restore();
        return;
      }

      await this.refresh();
    },
    async handleDelete(emailCampaignLog: EmailCampaignLog): Promise<void> {
      await DangerDialog({
        dialog: {
          title: `Delete ${emailCampaignLog.title}`,
          message: `Are you sure you want to delete this entry?`,
        },
      });

      // await window.Kernel.ActionBus.adminEmailCampaign.campaignLog.delete({
      await this.$actionBus.log.DeleteEmailCampaignLog(emailCampaignLog);

      await this.refresh();
    },
    async handleCreate(): Promise<void> {
      const emailCampaignLog = CreateEmailCampaignLog({
        campaign: this.emailCampaign?.id,
        status: "draft",
      });

      try {
        const { files } = await UpdateEmailCampaignLogForm({
          emailCampaignLog: emailCampaignLog,
          isNew: true,
        }).dialog({
          dialog: {
            title: "Create Campaign Email Log",
          },
        });

        // await window.Kernel.ActionBus.adminEmailCampaign.campaignLog.create({
        await this.$actionBus.log.CreateEmailCampaignLog({
          emailCampaignLog,
          files,
        });
      } catch {
        emailCampaignLog.$restore();
        return;
      }

      await this.refresh();
    },
  },
});
</script>
