// FailedLoginAttempt

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { FailedLoginAttempt } from "@kinherit/sdk/model/failed-login-attempt";

export const ReadFailedLoginAttempt = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/log/failed-login-attempt",
  method: "get",
  rootModel: FailedLoginAttempt,
  parse: () => ({}),
});
