import { Api } from "@/service/api.service";
import { KnowledgeBaseDocument } from "@kinherit/sdk";

interface UpdateKnowledgeBaseDocumentResponse {
  knowledgeBaseDocument: KnowledgeBaseDocument;
}

export const UpdateKnowledgeBaseDocument = async (
  message: KnowledgeBaseDocument,
): Promise<UpdateKnowledgeBaseDocumentResponse> => {
  const localCategories = message.$data.categories;

  const response = await Api.resource(
    "portal",
    "/v2/portal/knowledge-base/category/document/{knowledgeBaseDocument}",
    {
      knowledgeBaseDocument: message.id,
    },
  )
    .method("patch")

    .body({
      title: message.title,
      content: message.content,
    })
    .result();

  const knowledgeBaseDocuments = KnowledgeBaseDocument.$inflate(
    response.knowledgeBaseDocument,
  );

  const knowledgeBaseDocument = knowledgeBaseDocuments.first();

  if (!knowledgeBaseDocument) {
    throw new Error("KnowledgeBaseDocument not found");
  }

  const remoteCategories = knowledgeBaseDocument.$data.categories ?? [];

  const existngCategories = localCategories.intersection(remoteCategories);
  const newCategories = remoteCategories.filter(
    (category) => !existngCategories.includes(category),
  );
  const removedCategories = localCategories.filter(
    (category) => !existngCategories.includes(category),
  );

  console.log("existngCategories", existngCategories);
  console.log("newCategories", newCategories);

  await newCategories.forEachAsync(async (category) => {
    await window.Kernel.ActionBus2.portal.knowledgeBase.category.AddDocument({
      category,
      document: knowledgeBaseDocument,
    });
  });

  await removedCategories.forEachAsync(async (category) => {
    await window.Kernel.ActionBus2.portal.knowledgeBase.category.RemoveDocument(
      {
        category,
        document: knowledgeBaseDocument,
      },
    );
  });

  return {
    knowledgeBaseDocument,
  };
};
