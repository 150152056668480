import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultSubscription } from "@kinherit/sdk";

export const ReadKinvaultSubscription = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvaults/subscriptions",
  method: "get",
  parse: () => ({}),
  rootModel: KinvaultSubscription,
});
