import { Api } from "@kinherit/sdk";

export interface WeeklyCount {
  infoCallCount: number;
  optionsCallCount: number;
}

export interface AppointmentAvailabilityResponse {
  estatePlannerName: string;
  lastWeek: WeeklyCount;
  thisWeek: WeeklyCount;
  nextWeek: WeeklyCount;
  nextWeekPlusOne: WeeklyCount;
  nextWeekPlusTwo: WeeklyCount;
}

export const ComputeAppointmentAvailabilityReportHandler = async (): Promise<
  AppointmentAvailabilityResponse[]
> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/report/appointments/availability",
    {},
  )
    .method("get")
    .result();

  return response;
};
