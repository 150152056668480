var l = Object.defineProperty;
var t = (e, i) => l(e, "name", { value: i, configurable: !0 });
import { defineComponent as p } from "vue";
const n = p({
  name: "ChartMixin",
  props: {
    title: {
      type: String,
      default: ""
    },
    titleOptions: {
      type: Object,
      default: /* @__PURE__ */ t(() => ({}), "default")
    },
    subtitle: {
      type: String,
      default: ""
    },
    subtitleOptions: {
      type: Object,
      default: /* @__PURE__ */ t(() => ({}), "default")
    },
    tooltipOptions: {
      type: Object,
      default: /* @__PURE__ */ t(() => ({}), "default")
    },
    legendOptions: {
      type: Object,
      default: /* @__PURE__ */ t(() => ({}), "default")
    },
    decimation: {
      type: Object,
      default: /* @__PURE__ */ t(() => ({}), "default")
    },
    filler: {
      type: Object,
      default: /* @__PURE__ */ t(() => ({}), "default")
    }
  },
  computed: {
    computedTitleOptions() {
      return {
        ...this.titleOptions,
        text: this.title,
        display: this.title.length > 0
      };
    },
    computedSubtitleOptions() {
      return {
        ...this.subtitleOptions,
        text: this.subtitle,
        display: this.subtitle.length > 0
      };
    },
    computedPluginOptions() {
      return {
        tooltip: this.tooltipOptions,
        title: this.computedTitleOptions,
        subtitle: this.computedSubtitleOptions,
        legend: this.legendOptions,
        decimation: this.decimation,
        filler: this.filler
      };
    }
  }
});
export {
  n as default
};
