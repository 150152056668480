var l = Object.defineProperty;
var t = (e, o) => l(e, "name", { value: o, configurable: !0 });
import i from "../../_vue/component.input/base-input-field/base-input-field.js";
import { FieldMixin as n } from "../../component.mixin/field-mixin.js";
import { CypressHelper as p } from "../../cypress/component-helpers.js";
import { defineComponent as r } from "vue";
const u = r({
  name: "TextFieldComponent",
  components: { BaseInputField: i },
  mixins: [n()],
  props: {
    value: {
      type: String,
      default: null,
      value: "text"
    },
    /**
     * Use a textarea instead of an input box
     */
    isTextArea: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["blur"],
  data: /* @__PURE__ */ t((e) => ({
    $cypress: p({ ctx: e, labelField: "label" })
  }), "data")
});
export {
  u as _
};
