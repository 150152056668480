import { KintinTypeField, OptionsSelectField } from "@/config/form.config";
import { FormCalendarField } from "@kinherit/framework/component.display/calendar";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { TableLayout } from "@kinherit/framework/component.layout/dynamic-layout/layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Lead, Option } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";
export const ConvertLeadToKintinForm = (lead: Lead) =>
  defineForm({
    name: "convert-lead-to-kintin-form",
    data: () => ({
      type: Option.$findOneBy({
        group: "kintinTypes",
        value: "single",
      }) as never as Option,
      status: Option.$findOneBy({
        group: "kintinStatus",
        value: "booked",
      }) as never as Option,
      nextAction: new DateTime(),
      notify: true,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "convert-lead-to-kintin-form-area",
        data,
        template: {
          children: [
            {
              text: "This will attempt to create an account for this lead:",
            },
            TableLayout({
              slots: [
                [{ text: "Name" }, { text: lead.friendlyName ?? undefined }],
                [{ text: "Email Addresses" }],
                ...lead.emailAddresses.map((emailAddress) => [
                  {},
                  { text: emailAddress.email },
                ]),
                [{ text: "Phone Numbers" }],
                ...lead.phoneNumbers.map((phoneNumber) => [
                  {},
                  { text: phoneNumber.tel },
                ]),
                [
                  { text: "Referral Code" },
                  {
                    html: (() => {
                      const referralCode = lead.referral?.referralCode;

                      if (!referralCode) {
                        return undefined;
                      }

                      const { name, code } = referralCode;

                      if (name) {
                        return `<code>${code}</code> (${name})`;
                      }

                      return `<code>${code}</code>`;
                    })(),
                  },
                ],
                [
                  { text: "Referral" },
                  {
                    text: ((): string | undefined => {
                      const referral = lead.referral;

                      if (!referral) {
                        return undefined;
                      }

                      const { contact } = referral;
                      const referralCode = referral.referralCode;
                      const company = referralCode?.company;

                      const contactName =
                        contact?.profile?.fullName ??
                        contact?.profile?.organisationName;
                      const companyName =
                        company?.profile?.organisationName ??
                        company?.profile?.fullName;

                      if (contact && company) {
                        return `${contactName} (${companyName})`;
                      }

                      return contactName ?? companyName ?? undefined;
                    })(),
                  },
                ],
                [
                  { text: "Assigned To" },
                  { text: lead.assignedTo?.profile?.fullName ?? undefined },
                ],
                [{ text: "Type" }, "type"],
                [{ text: "Status" }, "status"],
                [{ text: "Next Action" }, "nextAction"],
                [{ text: "Notify" }, "notify"],
              ],
            }),
          ],
        },
        components: () => ({
          type: [
            KintinTypeField({
              selectField: true,
              props: {
                label: undefined,
                validators: ["required"],
                vModel: "type",
                isFullwidth: false,
                showClearButton: false,
              },
            }),
          ],
          status: [
            OptionsSelectField({
              props: {
                label: undefined,
                validators: ["required"],
                isFullwidth: false,
                showClearButton: false,
              },
              group: "kintinStatus",
              vModel: "status",
            }),
          ],
          nextAction: [
            FormCalendarField({
              props: {
                validators: ["required"],
                isFullwidth: false,
              },
              models: {
                value: "nextAction",
              },
            }),
          ],
          notify: [
            FormCheckboxField({
              props: {
                label: "Notify the specialist",
              },
              models: {
                value: "notify",
              },
            }),
          ],
        }),
      }),
    ],
  });
