// KnowledgeBaseDocument, KnowledgeBaseCategory

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KnowledgeBaseDocument } from "@kinherit/sdk/model/knowledge-base-document";

export const ReadKnowledgeBaseDocument = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/knowledge-base/category",
  method: "get",
  rootModel: KnowledgeBaseDocument,
  parse: () => ({}),
});
