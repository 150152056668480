// WillRevision, Kintin, User, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { WillRevision } from "@kinherit/sdk/model/will-revision";

export const RecordWillRevision = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/will-revision/{willRevision}",
  method: "get",
  rootModel: WillRevision,
  parse: (willRevision: WillRevision | string) => ({
    params: {
      willRevision,
    },
  }),
});
