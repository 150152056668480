var l = Object.defineProperty;
var o = (e, s) => l(e, "name", { value: s, configurable: !0 });
import { _ as p } from "../../../core/internal-components/snack-bar-loader.vue_vue_type_script_lang.js";
import { resolveComponent as m, createElementBlock as n, openBlock as a, normalizeClass as f, Fragment as u, renderList as d, createBlock as g, Teleport as h, withDirectives as k, createVNode as w, mergeProps as v, vShow as B } from "vue";
import { _ } from "../../../_plugins/_plugin-vue_export-helper.js";
function $(e, s, b, S, q, U) {
  const c = m("SnackBar");
  return a(), n("div", {
    class: f(`${e.requests.length > 0 ? "is-active" : ""} pragmatic-snack-bar-loader`)
  }, [
    (a(!0), n(u, null, d(e.requests, (i, r) => (a(), g(h, {
      key: `snack-bar-${r}`,
      to: ".pragmatic-container",
      disabled: e.isUnitTest
    }, [
      k(w(c, v({ ref_for: !0 }, i.config, {
        show: e.showing[r],
        "onUpdate:show": /* @__PURE__ */ o((t) => {
          e.showing[r] = t, t || e.remove(r);
        }, "onUpdate:show")
      }), null, 16, ["show", "onUpdate:show"]), [
        [B, r + 1 === e.requests.length]
      ])
    ], 8, ["disabled"]))), 128))
  ], 2);
}
o($, "_sfc_render");
const y = /* @__PURE__ */ _(p, [["render", $]]);
export {
  y as default
};
