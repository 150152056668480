import { Api } from "@kinherit/sdk/api";
import { Kintin } from "@kinherit/sdk";

export const SwitchAccountHolders = async (data: {
  kintin: Kintin;
}): Promise<void> => {
  await Api.resource("portal", "/v2/portal/kinvault/switch-account-holders")
    .method("put")
    .body({
      kintin: data.kintin.id,
    })
    .result();
};
