var o = Object.defineProperty;
var t = (e, i) => o(e, "name", { value: i, configurable: !0 });
import a from "../../_vue/component.display/icon/icon.js";
import r from "../../_vue/component.display/title/title.js";
import { ThemeDirection as l } from "../../theme/prop/direction.js";
import { ThemeSize as d } from "../../theme/prop/size.js";
import { defineComponent as n } from "vue";
const y = n({
  name: "FormAreaComponent",
  components: { Icon: a, Title: r },
  props: {
    is: {
      type: String,
      default: "fieldset"
    },
    direction: {
      type: String,
      default: l.isVertical,
      validator: /* @__PURE__ */ t((e) => Object.values(l).includes(e), "validator")
    },
    /**
     * HTML5 property to set the state of a fieldset as disabled or readonly
     */
    state: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((e) => ["disabled", "readonly"].includes(e), "validator")
    },
    /**
     * The label to use for this area of the form
     */
    label: {
      type: String,
      default: null
    },
    /**
     * The description to use for this area of the form
     */
    description: {
      type: String,
      default: null
    },
    /**
     * The size of the form area's label
     */
    labelSize: {
      type: String,
      default: "is-large",
      validator: /* @__PURE__ */ t((e) => Object.values(d).includes(e), "validator")
    },
    /**
     * Make this field area collapsible
     */
    isCollapsible: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether or not the form area is collapsed (v-model:collapsed)
     */
    collapsed: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["update:collapsed"],
  computed: {
    computedCollapsed: {
      get() {
        return this.collapsed;
      },
      set(e) {
        this.$emit("update:collapsed", e);
      }
    }
  }
});
export {
  y as _
};
