import { DefineCustomAction } from "@kinherit/framework/action-bus/actions/custom";
import {
  Between,
  Equal,
  FilterModelInternals,
  In,
  Like,
} from "@kinherit/orm/index";
import {
  DeepPartialArrayApiQuery,
  DeepPartialObjetApiQuery,
  Kintin,
  User,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

export const ReassignKintin = DefineCustomAction({
  interface: "portal",
  resource: "/v2/portal/kinvaults/reassign",
  method: "put",
  rootModel: Kintin,
  parse: (message: {
    search?: string;
    created?: null | [number, number];
    status: Array<string>;
    stage: Array<string>;
    assignedUser: Array<string>;
    tags: Array<string>;
    brandedKinvault?: Array<string>;
    dueBy?: null | "today" | "tomorrow" | "overdue";
    referral?: Array<string>;
    process?: Array<string>;
    isActive?: null | boolean;
    isEscalated?: null | boolean;
    kintins?: Array<string | Kintin>;
    body: {
      estatePlanners: Array<string | User>;
      legalAssistants: Array<string | User>;
    };
  }) => {
    const { dueBy } = message;

    const today = new DateTime();

    const todayDateRange: [number, number] = [
      today.setTime(0, 0, 0, 0).timestamp,
      today.setTime(23, 59, 59, 999).timestamp,
    ];

    const tomorrow = new DateTime().add(1, "day");

    const tomorrowDateRange: [number, number] = [
      tomorrow.setTime(0, 0, 0, 0).timestamp,
      tomorrow.setTime(23, 59, 59, 999).timestamp,
    ];
    const yesterday = new DateTime().sub(1, "day");

    const overdueDateRange: [number, number] = [
      0,
      yesterday.setTime(23, 59, 59, 999).timestamp,
    ];

    const nextActionAtDateRange =
      dueBy === "today"
        ? todayDateRange
        : dueBy === "tomorrow"
          ? tomorrowDateRange
          : dueBy === "overdue"
            ? overdueDateRange
            : null;

    const sharedCondition: DeepPartialObjetApiQuery<
      FilterModelInternals<Kintin>
    > = {
      referral: {
        nextActionAt: Between(nextActionAtDateRange),
        referralCode: {
          id: In(message.referral),
        },
      },
      status: {
        id: In(message.status),
      },
      stage: {
        id: In(message.stage),
      },
      tags: {
        id: In(message.tags),
      },
      process: {
        id: In(message.process),
      },
      assignedUsers: {
        user: {
          brandedKinvaultMemberships: {
            brandedKinvault: {
              id: In(message.brandedKinvault),
            },
          },
        },
      },
      isActive: Equal(message.isActive),
      isEscalated: Equal(message.isEscalated),
      createdAt: Between(message.created),
    };

    const uniqueConditions: DeepPartialArrayApiQuery<
      FilterModelInternals<Kintin>
    > = [];

    if ((message.assignedUser.length ?? 0) > 0) {
      uniqueConditions.push({
        estatePlanners: {
          id: In(message.assignedUser),
        },
        ...sharedCondition,
      });

      uniqueConditions.push({
        legalAssistants: {
          id: In(message.assignedUser),
        },
        ...sharedCondition,
      });
    }

    if (message.search) {
      uniqueConditions.push({
        friendlyName: Like(message.search),
        ...sharedCondition,
      });

      uniqueConditions.push({
        ref: Like(message.search),
        ...sharedCondition,
      });

      uniqueConditions.push({
        primaryPerson: {
          profile: {
            emails: {
              email: Like(message.search),
            },
          },
        },
        ...sharedCondition,
      });

      uniqueConditions.push({
        secondaryPerson: {
          profile: {
            emails: {
              email: Like(message.search),
            },
          },
        },
        ...sharedCondition,
      });
    }

    if (message.kintins?.isNotEmpty()) {
      return {
        query: {
          id: In(message.kintins.map((kintin) => kintin.toString())),
        },
        body: {
          estatePlanners: message.body.estatePlanners.map((estatePlanner) =>
            estatePlanner.toString(),
          ),
          legalAssistants: message.body.legalAssistants.map((legalAssistant) =>
            legalAssistant.toString(),
          ),
        },
      };
    } else if (uniqueConditions.length > 0) {
      return {
        query: uniqueConditions,
        body: {
          estatePlanners: message.body.estatePlanners.map((estatePlanner) =>
            estatePlanner.toString(),
          ),
          legalAssistants: message.body.legalAssistants.map((legalAssistant) =>
            legalAssistant.toString(),
          ),
        },
      };
    } else {
      return {
        query: sharedCondition,
        body: {
          estatePlanners: message.body.estatePlanners.map((estatePlanner) =>
            estatePlanner.toString(),
          ),
          legalAssistants: message.body.legalAssistants.map((legalAssistant) =>
            legalAssistant.toString(),
          ),
        },
      };
    }
  },
});
