var c = Object.defineProperty;
var u = (i, l, n) => l in i ? c(i, l, { enumerable: !0, configurable: !0, writable: !0, value: n }) : i[l] = n;
var s = (i, l) => c(i, "name", { value: l, configurable: !0 });
var d = (i, l, n) => u(i, typeof l != "symbol" ? l + "" : l, n);
import { setupDevtoolsPlugin as I } from "@vue/devtools-api";
import { Logger as o } from "../logger.js";
const t = class t {
  constructor(l) {
    I(
      {
        id: t.pluginId,
        app: l,
        label: "Framework / Timeline",
        packageName: "Framework",
        componentStateTypes: [t.pluginId]
      },
      (n) => {
        n.addTimelineLayer({
          id: t.pluginId,
          label: "Framework / Timeline",
          color: 2254009
        }), t.events.forEach(
          (e) => n.addTimelineEvent({
            layerId: t.pluginId,
            event: e
          })
        ), n.addInspector({
          id: t.pluginId,
          label: "Framework / Timeline"
        }), n.on.getInspectorTree((e) => {
          e.inspectorId === t.pluginId && (e.rootNodes.push({
            id: "shared",
            label: "- shared"
          }), Object.values(o.Modules).forEach((r) => {
            e.rootNodes.push({
              id: r,
              label: r,
              tags: [
                {
                  label: "Module",
                  backgroundColor: 2254009,
                  textColor: 16777215
                }
              ]
            });
          }));
        }), n.on.getInspectorState((e) => {
          if (e.inspectorId === t.pluginId) {
            if (e.state === null && (e.state = {}), !Object.values(o.Modules).includes(e.nodeId)) {
              e.state.shared = [
                {
                  key: "Global logging enabled",
                  value: {
                    _custom: {
                      value: !o.silent,
                      actions: [
                        {
                          icon: o.silent ? "check" : "cancel",
                          tooltip: o.silent ? "Enable" : "Disable",
                          action: /* @__PURE__ */ s(() => {
                            o.silent = !o.silent, n.sendInspectorState(t.pluginId);
                          }, "action")
                        }
                      ],
                      readOnly: !0
                    }
                  }
                }
              ];
              return;
            }
            e.state.Module = [
              {
                key: "Module name",
                value: e.nodeId
              },
              {
                key: "Logging enabled",
                value: {
                  _custom: {
                    value: `${!o.moduleSilent(e.nodeId)}${o.silent ? " (global logging disabled)" : ""}`,
                    actions: [
                      {
                        icon: o.moduleSilent(e.nodeId) ? "check" : "cancel",
                        tooltip: o.moduleSilent(e.nodeId) ? "Enable" : "Disable",
                        action: /* @__PURE__ */ s(() => {
                          o.moduleSilent(
                            e.nodeId,
                            !o.moduleSilent(e.nodeId)
                          ), n.sendInspectorState(t.pluginId);
                        }, "action")
                      }
                    ],
                    readOnly: !0
                  }
                }
              }
            ];
          }
        }), t.api = n;
      }
    );
  }
  static createEvent(l, n) {
    const e = {
      title: l,
      data: n,
      time: Date.now()
    };
    t.api === void 0 ? t.events.push(e) : t.api.addTimelineEvent({
      layerId: this.pluginId,
      event: e
    });
  }
};
s(t, "TimelinePlugin"), d(t, "pluginId", "framework-timeline-plugin"), d(t, "api"), d(t, "events", Array());
let a = t;
export {
  a as TimelinePlugin
};
