import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerNetwork } from "./create-introducer-network.action";
import { DeleteIntroducerNetwork } from "./delete-introducer-network.action";
import { ReadIntroducerNetwork } from "./read-introducer-network.action";
import { UpdateIntroducerNetwork } from "./update-introducer-network.action";

export const Portal2IntroducerNetworks = DefineActionBus({
  name: "portal-introducer-networks",
  actions: {
    ReadIntroducerNetwork,
    CreateIntroducerNetwork,
    DeleteIntroducerNetwork,
    UpdateIntroducerNetwork,
  },
});
