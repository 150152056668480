var o = Object.defineProperty;
var e = (t, n) => o(t, "name", { value: n, configurable: !0 });
import r from "../../_vue/component.input/button/button.js";
import { defineComponent as i } from "vue";
const a = i({
  name: "BrandedKinvaultCtaDefaultComponent",
  components: {
    Button: r
  },
  props: {
    content: {
      type: Object,
      required: !0
    },
    title: {
      type: String,
      required: !0
    }
  },
  emits: {
    "button-clicked": /* @__PURE__ */ e((t) => !!t, "button-clicked")
  }
});
export {
  a as _
};
