// KinvaultPrice

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultPrice } from "@kinherit/sdk";

export const ReadKinvaultPrice = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/kinvault-price",
  method: "get",
  rootModel: KinvaultPrice,
  parse: () => ({}),
});
