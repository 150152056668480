// UploadForm

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { UploadForm } from "@kinherit/sdk/model/upload-form";

export const DeleteUploadForm = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/upload-forms/{uploadForm}",
  method: "delete",
  parse: (message: {
    kintin: Kintin | string;
    uploadForm: UploadForm | string;
  }) => ({
    params: {
      kintin: message.kintin,
      uploadForm: message.uploadForm,
    },
  }),
  after: ({ message }) => {
    UploadForm.$delete(message.uploadForm);
  },
});
