// Adviser

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Adviser } from "@kinherit/sdk/model/adviser";

export const DeleteAdviser = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/adviser/{adviser}",
  method: "delete",
  parse: (adviser: Adviser) => ({
    params: {
      adviser,
      kintin: adviser.$data.kintin,
    },
  }),
  after: ({ message }) => {
    Adviser.$delete(message);
  },
});
