var C = Object.defineProperty;
var w = (e, o) => C(e, "name", { value: o, configurable: !0 });
import M from "../../_vue/component.input/context-menu/context-menu.js";
import { defineComponent as b } from "vue";
import { KernelModes as p } from "../kernel-mode.js";
import { InternalError as v } from "./internal-error.js";
const j = b({
  name: "PragmaticContextMenuLoader",
  components: { ContextMenu: M },
  data: /* @__PURE__ */ w(() => ({
    requests: Array()
  }), "data"),
  computed: {
    isUnitTest() {
      var e;
      return ((e = window.Kernel) == null ? void 0 : e.Mode) === p.UnitTest;
    }
  },
  mounted() {
    var e, o;
    window.Kernel.Global.setPragmaticContextMenuComponentLoader(
      this.openContextMenu
    ), (o = (e = this.$router) == null ? void 0 : e.beforeEach) == null || o.call(e, (n, u, r) => {
      const l = this.requests.reverse();
      for (let t = l.length - 1; t >= 0; t--)
        this.reject(t);
      r();
    });
  },
  methods: {
    async openContextMenu(e) {
      window.Kernel.Logger.log(
        "Global",
        "info",
        "Opening context menu",
        e.config
      ), this.requests.push(e), await this.$nextTick(), this.positionMenu();
    },
    remove(e) {
      this.requests = this.requests.filter(
        (o, n) => n !== e
      );
    },
    resolve(e, o) {
      const n = this.requests[e];
      window.Kernel.Logger.log("Global", "info", "Resolving context menu", {
        menu: n.config,
        item: o
      }), this.remove(e), n.resolve(o);
    },
    reject(e) {
      const o = this.requests[e];
      window.Kernel.Logger.log(
        "Global",
        "info",
        "Rejecting context menu",
        o.config
      ), this.remove(e), o.reject(
        new v({
          message: "Context menu rejected"
        })
      );
    },
    async positionMenu() {
      var a, h;
      if (((a = window.Kernel) == null ? void 0 : a.Mode) === p.UnitTest)
        return;
      const e = window.innerWidth, o = window.innerHeight, n = document.documentElement.scrollHeight, u = document.documentElement.scrollTop, r = window.Kernel.Global.mousePosition, l = document.querySelectorAll(
        ".pragmatic-container .context-menu"
      ), t = l[l.length - 1];
      if (!t)
        return;
      const c = t.getBoundingClientRect().height, m = t.getBoundingClientRect().width;
      let i, s;
      window.Kernel.KeyboardNavigation ? (i = e / 2 - m / 2, s = o / 2 - c / 2) : (s = r.y + u, i = r.x), t.style.top = s + "px", t.style.left = `${i}px`, t.style.position = "absolute", await this.$nextTick();
      const d = s + c - n, f = ((h = t.querySelector(".dropdown-menu__close-button")) == null ? void 0 : h.getBoundingClientRect().width) ?? 0, x = m + f, y = i + x;
      if (d > 0 && (t.style.top = `${Math.max(s - d, 0)}px`), c > n) {
        const g = t.querySelector(
          ".dropdown-menu__content"
        );
        g && (g.style.maxHeight = `max(100vh, ${n}px)`);
      }
      y >= e && (t.style.left = `${Math.max(i - m, 0)}px`);
    }
  }
});
export {
  j as _
};
