import { Api } from "@kinherit/sdk/api";
import { FileLog, Kintin } from "@kinherit/sdk";

// Kintin, FileLog
export const UploadFile = async (message: {
  kintin: Kintin | string;
  file: FileLog | string;
  uploadedFile: File;
}) => {
  await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/file-log/{file}/upload",
    {
      kintin: message.kintin,
      file: message.file,
    },
  )
    .method("put")

    .files({
      uploadedFile: message.uploadedFile,
    })
    .result();
};
