import {
  IntroducerCompanyField,
  KinvaultCouponField,
  KinvaultPriceField,
} from "@/config/form.config";
import { CreateKinvaultSubscriptionReferralCode } from "@/config/model.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const UpdateKinvaultSubscriptionReferralCodeForm = (
  referralCode = CreateKinvaultSubscriptionReferralCode(),
) =>
  defineForm({
    name: "update-kinvault-subscription-referral-code",
    data: () => referralCode,
    formAreas: (data) => [
      defineFormArea({
        name: "code",
        template: GridLayout([
          ["code"],
          ["price"],
          ["coupon"],
          ["introducerCompany"],
        ]),
        data,
        components: () => ({
          code: [
            FormTextField({
              props: {
                label: "Code",
                validators: ["required"],
              },
              models: {
                value: "code",
              },
            }),
          ],
          coupon: [
            KinvaultCouponField({
              props: {
                label: "Coupon",
                vModel: "coupon",
              },
            }),
          ],
          price: [
            KinvaultPriceField({
              props: {
                label: "Price",
                validators: ["required"],
                vModel: "price",
              },
            }),
          ],
          introducerCompany: [
            IntroducerCompanyField({
              props: {
                label: "Introducer Company",
                validators: ["required"],
                vModel: "introducerCompany",
              },
            }),
          ],
        }),
      }),
    ],
  });
