import { Api } from "@kinherit/sdk/api";
import { MfAuthMethod } from "@kinherit/sdk";

//
export const ResendMfAuthMethodCode = async (message: MfAuthMethod) => {
  await Api.resource(
    "core",
    "/v2/core/user/{user}/mf-auth-methods/{mfAuthMethod}/resend",
    {
      mfAuthMethod: message,
      user: message.credential.user!,
    },
  )
    .method("put")

    .result();

  return {};
};
