import { BrandedKinvaultField } from "@/config/form.config";
import { CreateBrandedKinvaultPricingSchema } from "@/config/model.config";
import { FormTable } from "@kinherit/framework/component.display/table";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormNumberField } from "@kinherit/framework/component.input/number-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Currency } from "@kinherit/ts-common";

export const UpdateBrandedKinvaultPricingSchemaForm = (
  data = CreateBrandedKinvaultPricingSchema(),
) =>
  defineForm({
    name: "update-branded-kinvault-ult-kinvault-pricing-schema-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "branded-kinvault-kinvault-pricing-schema",
        data,
        template: GridLayout([
          ["name"],
          ["schema"],
          ["startDate", "endDate"],
          ["brandedKinvault", "isDefault"],
        ]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: "Name",
              },
              models: {
                value: "name",
              },
            }),
          ],
          schema: [
            FormTable({
              props: {
                rows: (data) => data.schema,
                columns: [
                  {
                    title: "Users",
                    slot: "users",
                  },
                  {
                    title: "Price",
                    slot: "price",
                  },
                  {
                    slot: "action",
                  },
                ],
                reference: "table",
              },
              slots: {
                users: [
                  FormNumberField({
                    reactive: true,
                    props: {
                      reference: "users",
                    },
                    models: {
                      value: {
                        get: (data, controls) => {
                          return controls.templateScope.row.users;
                        },
                        set: (value, data, controls) => {
                          const schema = data.schema;
                          const { index } = controls.templateScope;

                          controls.templateScope.row.users = value;
                          schema[index].users = value;
                          data.schema = schema;
                        },
                      },
                    },
                  }),
                ],
                price: [
                  FormCurrencyField({
                    reactive: true,
                    props: {
                      reference: "price",
                    },
                    models: {
                      value: {
                        get: (data, controls) => {
                          return new Currency({
                            amount: controls.templateScope.row.price,
                          });
                        },
                        set: (value: Currency, data, controls) => {
                          const schema = data.schema;
                          const { index } = controls.templateScope;

                          controls.templateScope.row.price = value?.amount ?? 0;
                          schema[index].price = value?.amount ?? 0;
                          data.schema = schema;
                        },
                      },
                    },
                  }),
                ],
                action: [
                  FormButton({
                    reactive: true,
                    props: {
                      iconLeft: "Trash",
                      color: "is-danger",
                      ariaLabel: "Delete",
                      reference: "delete",
                    },
                    emits: {
                      click: (_, data, controls) => {
                        const { index } = controls.templateScope;
                        data.schema = data.schema.filter((_, i) => i !== index);
                        controls.rebuildTemplateBindings();
                        controls.incrementFormRenderKey();
                      },
                    },
                  }),
                ],
              },
            }),
            FormButton({
              props: {
                text: "Add Row",
                reference: "addRow",
              },
              emits: {
                click: (_, data, controls) => {
                  data.schema = [
                    ...data.schema,
                    {
                      users: 0,
                      price: 0,
                    },
                  ];

                  controls.rebuildTemplateBindings();
                },
              },
            }),
          ],
          isDefault: [
            FormCheckboxField({
              props: {
                label: "Is Default",
                reference: "isDefault",
              },
              models: {
                value: "isDefault",
              },
            }),
          ],
          startDate: [
            FormDateField({
              props: {
                label: "Start Date",
                reference: "startDate",
              },
              models: {
                value: "startDate",
              },
            }),
          ],
          endDate: [
            FormDateField({
              props: {
                label: "End Date",
                reference: "endDate",
              },
              models: {
                value: "endDate",
              },
            }),
          ],
          brandedKinvault: [
            BrandedKinvaultField({
              props: {
                vModel: "brandedKinvaults",
                isMultiSelect: true,
              },
            }),
          ],
        }),
      }),
    ],
  });
