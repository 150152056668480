<template>
  <CallScriptSection title="Salutation">
    <b>
      So before we dive in, can I check what I should call you? Is it
      {{ primarySalutation }} or mr/mrs
      {{ kintin.primaryPerson.profile.lastName }}?
    </b>
    <b v-if="isJoint"> ( And {{ secondarySalutation ?? "[Person2]" }}? )</b>
    <br />
    <em>[Await Response]</em>
    <br />
    <br />
    <div class="columns">
      <div class="column">
        <TextField
          v-model:value="primarySalutation"
          label="Person 1 Salutation"
          message="Salutation for the main account holder"
          :validators="['required']"
        />
      </div>
      <div v-if="isJoint" class="column">
        <TextField
          v-model:value="secondarySalutation"
          label="Person 2 Salutation"
          message="Salutation for the secondary account holder"
          :validators="['required']"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <ConfirmCheck
          step="2.0.2"
          :kintin="kintin"
          :checks="checks"
          :kintin-checks="kintinChecks"
          @update="(step, value) => $emit('update', step, value)"
        />
      </div>
      <div class="column">
        <Button text="Save" color="is-positive" @click="save" />
      </div>
    </div>
  </CallScriptSection>
</template>

<script lang="ts">
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import Button from "@kinherit/framework/component.input/button";
import TextField from "@kinherit/framework/component.input/text-field";
import { Check, Kintin, KintinCheck } from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";
import ConfirmCheck from "./ConfirmCheck.vue";

export default defineComponent({
  name: "SalutationSection",
  components: {
    CallScriptSection,
    ConfirmCheck,
    Button,
    TextField,
  },
  mixins: [KinvaultKintinDetailsMixin],
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
    checks: {
      type: Array as PropType<Check[]>,
      required: true,
    },
    kintinChecks: {
      type: Array as PropType<KintinCheck[]>,
      required: true,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update: (step: string, value: number) => true,
  },
  data: () => ({
    primarySalutation: null as string | null,
    secondarySalutation: null as string | null,
  }),
  computed: {
    isJoint(): boolean {
      return this.kintin.type.value === "joint";
    },
  },
  mounted() {
    this.primarySalutation = this.kintin.primaryPerson.profile.knownAs;
    this.secondarySalutation =
      this.kintin.secondaryPerson?.profile.knownAs ?? null;
  },
  methods: {
    async save(): Promise<void> {
      const primaryPerson = this.kintin.primaryPerson;
      primaryPerson.profile.knownAs = this.primarySalutation ?? null;
      primaryPerson.profile.$persist();

      // await window.Kernel.ActionBus.kinvaultKintin.person.updateProfile({
      //   kintin: this.kintin,
      //   person: primaryPerson,
      // });
      await this.$actionBus.person.UpdateProfile(primaryPerson);

      if (this.kintin.secondaryPerson) {
        const secondaryPerson = this.kintin.secondaryPerson;
        secondaryPerson.profile.knownAs = this.secondarySalutation ?? null;
        secondaryPerson.profile.$persist();

        // await window.Kernel.ActionBus.kinvaultKintin.person.updateProfile({
        //   kintin: this.kintin,
        //   person: secondaryPerson,
        // });
        await this.$actionBus.person.UpdateProfile(secondaryPerson);
      }
    },
  },
});
</script>
