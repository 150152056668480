var m = Object.defineProperty;
var s = (e, t) => m(e, "name", { value: t, configurable: !0 });
import { defineComponent as a } from "vue";
import u from "../../_vue/component.layout/dynamic-layout/dynamic-layout.js";
const g = a({
  name: "FormInner",
  components: { DynamicLayout: u },
  props: {
    form: {
      type: Object,
      required: !0
    },
    formArea: {
      type: Object,
      required: !0
    },
    components: {
      type: Object,
      required: !0
    },
    formData: {
      type: Object,
      required: !0
    },
    uuid: {
      type: String,
      required: !0
    },
    showValidation: {
      type: Boolean,
      required: !0
    },
    layout: {
      type: Object,
      default: /* @__PURE__ */ s(() => ({ slot: "default" }), "default")
    },
    scope: {
      type: Object,
      default: /* @__PURE__ */ s(() => ({}), "default")
    },
    isDialog: {
      type: Boolean,
      default: !1
    }
  },
  emits: [
    "field-blur",
    "field-focus",
    "field-clear",
    "submit",
    "validate",
    "increment-render-key",
    "rebuild-template-bindings"
  ],
  data: /* @__PURE__ */ s(() => ({
    templateRenderKey: 0,
    toolTips: [],
    bindings: {},
    renderedComponents: {}
  }), "data"),
  beforeMount() {
    this.setComponents(), this.setBindings(), this.form.registerFormAreaInstance(this.formArea.name, this);
  },
  beforeUnmount() {
    this.form.unregisterFormAreaInstance(this.formArea.name);
  },
  methods: {
    incrementRenderKey(e = 0) {
      if (e > 0) {
        this.$emit("increment-render-key", e - 1);
        return;
      }
      this.templateRenderKey++;
    },
    mapNestedComponents(e) {
      if (e === void 0)
        return {};
      const t = {};
      for (const [r, i] of Object.entries(e))
        Array.isArray(i) ? t[r] = {
          components: {
            default: i
          },
          template: { slot: "default" }
        } : t[r] = i;
      return t;
    },
    getToolTipConfig(e, t) {
      var f;
      let r = (f = e.directives) == null ? void 0 : f.tooltip;
      if (typeof r == "function" && (r = r(this.formData)), r === void 0 || typeof r == "string")
        return "";
      const { color: i, size: l, position: n } = r, o = [i, l, n].filter((d) => d !== void 0).join(":");
      return o === "" ? "" : (this.toolTips[t] = this.getToolTipText(e), `:${o}`);
    },
    getToolTipText(e) {
      var r;
      let t = (r = e.directives) == null ? void 0 : r.tooltip;
      return typeof t == "function" && (t = t(this.formData)), t === void 0 || typeof t == "string" ? t ?? "" : t.text ?? "";
    },
    setComponents() {
      const e = {};
      for (const [t, r] of Object.entries(this.components))
        e[t] = [], r.filter((i) => i != null).forEach((i) => {
          if (i === void 0 || i === null)
            return null;
          e[t].push(i);
        });
      this.renderedComponents = e;
    },
    setBindings(e, t = 0) {
      if (t > 0) {
        this.$emit("rebuild-template-bindings", t - 1);
        return;
      }
      for (const [r, i] of Object.entries(this.renderedComponents))
        (e == null ? void 0 : e.slot) !== void 0 && r !== (e == null ? void 0 : e.slot) || i.forEach((l, n) => {
          var o, f;
          if ((e == null ? void 0 : e.index) !== void 0 && n !== (e == null ? void 0 : e.index))
            return ((f = (o = this.bindings) == null ? void 0 : o[r]) == null ? void 0 : f[n]) ?? {};
          if (!l)
            return {};
          this.bindings[r] === void 0 && (this.bindings[r] = []), this.bindings[r][n] = l.bindings(
            this.formData,
            this.form,
            {
              incrementTemplateRenderKey: /* @__PURE__ */ s((d) => this.incrementRenderKey(d), "incrementTemplateRenderKey"),
              templateScope: this.scope,
              rebuildTemplateBindings: /* @__PURE__ */ s((d) => this.setBindings(void 0, d), "rebuildTemplateBindings")
            },
            () => this.setBindings({ slot: r, index: n })
          );
        });
      this.$forceUpdate();
    }
  }
});
export {
  g as _
};
