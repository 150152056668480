import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateTrustee } from "./create-trustee.action";
import { DeleteTrustee } from "./delete-trustee.action";
import { ReadTrustee } from "./read-trustee.action";
import { RecordTrustee } from "./record-trustee.action";
import { UpdateTrustee } from "./update-trustee.action";

export const Portal2KinvaultTrustee = DefineActionBus({
  name: "portal-kinvault-trustee",
  actions: {
    CreateTrustee,
    DeleteTrustee,
    ReadTrustee,
    UpdateTrustee,
    RecordTrustee,
  },
});
