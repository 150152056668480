<template>
  <Card :title="title" title-heading-tag="h6" is-plain class="column">
    <div class="outcome-group">
      <span v-for="outcome in outcomes" :key="`${outcome.id}`">
        <span>{{ outcome.name }}</span>
        <span>
          <strong>{{ outcome.count }}</strong>
          <span v-if="outcome.percentage"> ({{ outcome.percentage }}%) </span>
        </span>
      </span>
    </div>
  </Card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import Card from "@kinherit/framework/component.layout/card";
import { AppointmentOutcomeResultParsed } from "@/module/report/page/core/Reports.Appointments.Breakdown.vue";
export default defineComponent({
  name: "AppointmentBreakdownDetail",
  components: { Card },
  props: {
    title: {
      type: String,
      required: true,
    },
    outcomes: {
      type: Array as PropType<AppointmentOutcomeResultParsed[]>,
      required: true,
    },
  },
  computed: {},
});
</script>

<style lang="scss" scoped>
.outcome-group {
  display: flex;
  flex-direction: column;
  span {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
  }
}
</style>
