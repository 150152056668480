var m = Object.defineProperty;
var r = (p, t) => m(p, "name", { value: t, configurable: !0 });
import { OpenSnackBar as l } from "../global/snack-bar.js";
const o = class o {
  static wrapLinks(t, e) {
    if (e.wrapLinks === !1)
      return t;
    const i = /https?:\/\/[^\s]+/g;
    return t.replace(i, (a) => `<a href="${a}">${a}</a>`);
  }
  static removeHtmlTags(t) {
    const e = document.createElement("span");
    return e.innerHTML = t, e.textContent || e.innerText;
  }
  static async copyTextToClipBoard(t, e = {}) {
    var s, n;
    if (location.protocol !== "https:") {
      l({
        text: "Copy to clipboard is only available on https sites",
        actionText: "Dismiss",
        actionColor: "is-danger"
      });
      return;
    }
    const i = this.removeHtmlTags(t);
    t = this.wrapLinks(t, e);
    const a = {};
    (((s = e.mimeTypes) == null ? void 0 : s.includes("text/html")) ?? !0) && (a["text/html"] = new Blob([t], { type: "text/html" })), (((n = e.mimeTypes) == null ? void 0 : n.includes("text/plain")) ?? !0) && (a["text/plain"] = new Blob([i], { type: "text/plain" })), await navigator.clipboard.write([new ClipboardItem(a)]), e.showSnackbar !== !1 && l({
      text: "Copied to clipboard",
      actionText: "Dismiss",
      actionColor: "is-success"
    });
  }
  static async copyTableToClipBoard(t, e = {}) {
    const a = `<table>${t.map(
      (s) => `<tr>${s.map((n) => `<td>${n ?? ""}</td>`).join("")}</tr>`
    ).join("")}</table>`;
    await this.copyTextToClipBoard(a, e);
  }
};
r(o, "ClipBoardService");
let c = o;
export {
  c as ClipBoardService
};
