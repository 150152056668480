// WillRevision

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { WillRevision } from "@kinherit/sdk/model/will-revision";

export const DeleteWillRevision = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/will-revision/{willRevision}",
  method: "delete",
  parse: (willRevision: WillRevision | string) => ({
    params: {
      willRevision,
    },
  }),
});
