<template>
  <MasterListPage
    v-if="$data.filters"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
    class="failed-password-reset-attempts-master-list"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    title="Failed Password Resets"
    :sort-by-options="{
      createdAt: 'Created At',
    }"
    @refresh="refresh"
  >
    <template #reason="{ row }">
      <Badge
        v-if="row.reason === 'invalid-password'"
        type="danger"
        text="Incorrect Password"
        size="is-small"
        color="is-danger"
        is-rounded
      />
      <Badge
        v-else-if="row.reason === 'invalid-username'"
        type="danger"
        text="Incorrect Username"
        size="is-small"
        color="is-kinherit-ochre"
        is-rounded
      />
      <Badge v-else type="warning" text="Unknown" size="is-small" is-rounded />
    </template>
    <template #details="{ row }: { row: FailedPasswordResetAttempt }">
      {{ row.userAgent }}
    </template>
  </MasterListPage>
</template>

<cypress-wrapper lang="json">
{
  "name": "FailedPasswordResetAttemptsMasterListWrapper",
  "route": "FailedPasswordResetAttemptsMasterList",
  "selector": ".failed-password-reset-attempts-master-list",
  "imports": {
    "ReadFailedPasswordResetAttemptsFormWrapper": "@/module/admin.log/form/read-failed-password-reset-attempts.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadFailedPasswordResetAttemptsFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadFailedPasswordResetAttemptsForm } from "@/module/admin.log/form/read-failed-password-reset-attempts.form";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Like } from "@kinherit/orm/index";
import {
  FailedPasswordResetAttempt,
  IFailedPasswordResetAttempt,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { FailedPasswordResetAttemptsMasterListRoute } from ".";

export default defineComponent({
  name: FailedPasswordResetAttemptsMasterListRoute,
  components: { MasterListPage, Badge },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.admin.log)],
  data: () => ({
    filters: ReadFailedPasswordResetAttemptsForm(),
    columns: [
      {
        map: (row) => row.createdAt.formatDateTime,
        title: "Created At",
      },
      {
        field: "ipAddress",
        title: "IP Address",
      },
      {
        field: "origin",
        title: "Origin",
      },
      {
        field: "username",
        title: "Username",
      },
      {
        title: "Reason",
        slot: "reason",
      },
    ] as Array<TableColumn<FailedPasswordResetAttempt>>,
    rows: Array<FailedPasswordResetAttempt>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IFailedPasswordResetAttempt,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    async refresh(
      formData: ReturnType<
        typeof ReadFailedPasswordResetAttemptsForm
      >["localData"],
    ): Promise<void> {
      const data = await this.$actionBus.ReadFailedPasswordResetAttempt({
        query: {
          createdAt: Between(formData.created),
          origin: Like(formData.origin),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.failedPasswordResetAttempt;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
