<template>
  <SidebarPage
    v-if="emailTemplate && !loading"
    :key="key"
    class="admin-referral-details"
    :has-context-menu="hasContextMenu"
    :name="`${emailTemplate.name}`"
    :tabs="tabs"
    @context-menu="contextMenu"
  >
    <template #default>
      <RouterChildView name="default">
        <EmailTemplateDetailsSummary />
      </RouterChildView>
    </template>
    <template #sidebar>
      <RouterChildView name="sidebar">
        <EmailTemplateDetailsSidebar />
      </RouterChildView>
    </template>
  </SidebarPage>
  <PageNotFound v-else-if="!loading" />
</template>

<cypress-wrapper lang="json">
{
  "name": "EmailTemplateDetailsWrapper",
  "route": "EmailTemplateDetails",
  "selector": ".admin-referral-details",
  "imports": {
    "UpdateEmailTemplateFormWrapper": "@/module/admin.email-template/form/update-email-template.form.test"
  },
  "methods": {
    "contextMenu": {
      "type": "context-menu",
      "selector": ".js-page-header-context-menu",
      "items": ["Edit", "Delete"]
    },
    "tabs": {
      "type": "tabs",
      "items": ["Summary"]
    },
    "emailTemplateForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateEmailTemplateFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { EmailTemplateDetailsMixin } from "@/module/admin.email-template/mixin/email-template-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { TabOptions } from "@kinherit/framework/component.display/tabs";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { EmailTemplateDetailsParams, EmailTemplateDetailsRoute } from ".";
import { EmailTemplateMasterListRoute } from "..";
import { UpdateEmailTemplateForm } from "../../form/update-email-template.form";
import EmailTemplateDetailsSidebar from "./EmailTemplateDetails.Sidebar.vue";
import { EmailTemplateDetailsSummaryRoute } from "./summary";
import EmailTemplateDetailsSummary from "./summary/EmailTemplateDetails.Summary.vue";

export default defineComponent({
  name: EmailTemplateDetailsRoute,
  components: {
    PageNotFound,
    SidebarPage,
    RouterChildView,
    EmailTemplateDetailsSummary,
    EmailTemplateDetailsSidebar,
  },
  mixins: [AuthService.mixin(), EmailTemplateDetailsMixin],
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    const params = to.params as EmailTemplateDetailsParams;
    await window.Kernel.ActionBus2.portal.admin.emailTemplate.RecordEmailTemplate(
      { message: params.emailTemplate },
    );
  },
  data: (ctx) => ({
    key: 0,
    loading: true,
    tabs: [
      {
        label: "Summary",
        route: {
          name: EmailTemplateDetailsSummaryRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
    ] satisfies TabOptions[],
    tab: 0,
  }),
  computed: {
    hasContextMenu(): boolean {
      return this.$auth.hasRole("admin");
    },
  },
  mounted(): void {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      if (!this.emailTemplate) {
        return;
      }

      await AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Edit",
              line1: "Edit this email template",
              icon: StyleService.icon.edit.icon,
              permission: "email-template:write",
              action: this.edit,
            },
            {
              title: "Delete",
              line1: "Delete this email template",
              icon: StyleService.icon.delete.icon,
              permission: "email-template:write",
              action: this.delete,
            },
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
        },
        path: "permissions",
        filter: "all",
        callback: (item) => item.action?.(),
      })();
    },
    async delete(): Promise<void> {
      if (!this.emailTemplate) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Email Template",
          message: "Are you sure you want to delete this email template?",
        },
      });

      // await window.Kernel.ActionBus.adminEmailTemplate.delete({
      await this.$actionBus.DeleteEmailTemplate(this.emailTemplate);

      window.Kernel.visitRoute({
        name: EmailTemplateMasterListRoute,
      });
    },
    async edit(): Promise<void> {
      if (!this.emailTemplate) {
        return;
      }

      try {
        await UpdateEmailTemplateForm(this.emailTemplate).dialog({
          dialog: {
            title: "Edit Email Template",
            type: "fullscreen",
            fillScreen: true,
          },
          button: {
            ok: {
              text: "Save",
            },
          },
        });
      } catch (e) {
        this.emailTemplate.$restore();
        throw e;
      }

      // await window.Kernel.ActionBus.adminEmailTemplate.update({
      await this.$actionBus.UpdateEmailTemplate(this.emailTemplate);

      this.key++;
    },
  },
});
</script>
