import { DangerDialog } from "@/config/dialog.config";
import { PersonField, SharedFormProps } from "@/config/form.config";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { Equal, In, NotEqual } from "@kinherit/orm/index";
import { Adviser, Kintin } from "@kinherit/sdk";
import { AddKintinPersonButton } from "./shared.form";

export const UpdateAdviserForm = ({
  data,
  kintin,
}: {
  data: Adviser;
  kintin: Kintin;
}): FormBuilder<() => Adviser, "default"> => {
  return defineForm({
    name: "update-adviser-form",
    props: SharedFormProps,
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: GridLayout([
          ["person", "forPerson"],
          { is: "hr" },
          ["defaultTrustIfa"],
          ["notes"],
        ]),
        components: () => ({
          person: [
            PersonField({
              props: {
                vIf: (data) =>
                  data.forPerson?.id === kintin.$data.primaryPerson,
                label: "Who is the adviser?",
                validators: ["required"],
                vModel: "person",
              },
              query: {
                id: NotEqual(kintin.$data.primaryPerson),
                kintin: {
                  id: Equal(kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
                below: [
                  FormElement({
                    props: {
                      vIf: !!kintin.referral?.contact,
                      is: "div",
                      class: "is-size-7 mt-1",
                    },
                    slots: {
                      default: [
                        FormElement({
                          props: {
                            is: "span",
                            text: "Import ",
                          },
                        }),
                        FormElement({
                          props: {
                            is: "span",
                            class:
                              "has-text-weight-bold has-text-link is-clickable",
                            text:
                              kintin.referral?.contact?.profile?.fullName ?? "",
                          },
                          emits: {
                            click: async (_, data, controls) => {
                              const contact = kintin.referral?.contact;

                              if (!contact) {
                                return;
                              }

                              const contactProfile = contact.profile;

                              await DangerDialog({
                                dialog: {
                                  message: `Are you sure you want to create a new person using ${contactProfile.fullName}'s details?`,
                                },
                              });

                              const { person } =
                                await window.Kernel.ActionBus2.portal.kinvault.CreatePersonFromIntroducerContact(
                                  {
                                    introducerContact: contact,
                                    kintin,
                                  },
                                );

                              data.person = person;

                              controls.rebuildForm();
                            },
                          },
                        }),
                        FormElement({
                          props: {
                            is: "span",
                            text: " as a contact",
                          },
                        }),
                      ],
                    },
                  }),
                ],
              },
            }),
            PersonField({
              props: {
                vIf: (data) =>
                  data.forPerson?.id === kintin.$data.secondaryPerson,
                label: "Who is the adviser?",
                validators: ["required"],
                vModel: "person",
              },
              query: {
                id: NotEqual(kintin.$data.secondaryPerson),
                kintin: {
                  id: Equal(kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
          ],
          forPerson: [
            PersonField({
              reactive: true,
              props: {
                label: (data) =>
                  `Who is ${
                    data.person?.profile?.fullName ?? `this person`
                  } an adviser for?`,
                validators: ["required"],
                reference: "forPerson",
                vModel: {
                  get: (data) => data.forPerson,
                  set: (value, data) => {
                    data.forPerson = value;

                    if (data.forPerson?.id === data.person?.id) {
                      data.person = null as any;
                    }
                  },
                },
              },
              query: {
                id: In([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Notes",
                isTextArea: true,
              },
              models: {
                value: "notes",
              },
            }),
          ],
          defaultTrustIfa: [
            FormCheckboxField({
              props: {
                label: "Default trust IFA",
              },
              models: {
                value: "defaultTrustIfa",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
