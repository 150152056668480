import { Portal2KinvaultSubscriptionCoupon } from "@/action-bus/portal/kinvaults/subscription/kinvault-coupon";
import { Portal2KinvaultSubscriptionPrice } from "@/action-bus/portal/kinvaults/subscription/kinvault-price";
import { Portal2KinvaultSubscriptionSubscription } from "@/action-bus/portal/kinvaults/subscription/kinvault-subscription";
import { Portal2KinvaultSubscriptionReferralCode } from "@/action-bus/portal/kinvaults/subscription/kinvault-subscription-referral-code";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

export const Portal2KinvaultSubscription = DefineActionBus({
  name: "portal-kinvaults-subscription",
  actions: {
    subscription: Portal2KinvaultSubscriptionSubscription,
    price: Portal2KinvaultSubscriptionPrice,
    coupon: Portal2KinvaultSubscriptionCoupon,
    referralCode: Portal2KinvaultSubscriptionReferralCode,
  },
});
