// FileLog

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { FileLog } from "@kinherit/sdk/model/file-log";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const DeleteFile = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/file-log/{fileLog}",
  method: "delete",
  parse: (message: { kintin: Kintin | string; fileLog: FileLog | string }) => ({
    params: {
      kintin: message.kintin,
      fileLog: message.fileLog,
    },
  }),
  after: ({ message }) => {
    FileLog.$delete(message.fileLog);
  },
});
