import { ActiveSession } from "@/module/auth/model/active-session.model";
import { AuthLoginRoute, AuthRequestMfRoute } from "@/module/auth/page";
import { DashboardIndexRoute } from "@/module/dashboard/page";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { Store } from "@kinherit/orm";
import { User } from "@kinherit/sdk";
import { RouteLocationRaw, createRouter, createWebHistory } from "vue-router";
import { SnackBarService } from "../service/snack-bar.service";
import { Module } from "./module.config";

const Router = createRouter({
  history: createWebHistory(),
  routes: Module.routes(),
  scrollBehavior: (to, from, savedPosition) => {
    if (to.name === from.name) {
      return;
    }

    return savedPosition ?? { left: 0, top: 0 };
  },
});

Router.beforeEach(async (to, from, next) => {
  if (Store.hasChanges()) {
    await OpenAlertDialog({
      dialog: {
        title: "Unsaved changes",
        message:
          "Some unsaved changes have been detected on this page, do you wish to continue?",
      },
      button: {
        ok: {
          text: "Discard Changes",
          color: "is-danger",
        },
        cancel: {
          text: "Stay on page",
        },
      },
    });
    Store.purge("changes");
  }

  const module = Module.findModuleByRoute(to.name as string);

  let message: undefined | (() => Promise<unknown>) = undefined;
  let route: undefined | RouteLocationRaw = undefined;

  if (module) {
    const session = ActiveSession.$getInstance();

    const requiresSession = module.session;
    const requiresAllPermissions = Array.isArray(module.permissions)
      ? module.permissions
      : (module.permissions.all ?? []);
    const requiresSomePermissions = Array.isArray(module.permissions)
      ? []
      : (module.permissions.some ?? []);

    const hasSession = null !== session;
    const user = User.$findOne(session?.user ?? null);
    const hasPermissions = user?.roles?.pluck("permissions").flat();

    const isAuthenticated = false === session?.mfaRequired;
    const isMfaRoute = to.name === AuthRequestMfRoute;

    const mfaRoute = {
      name: AuthRequestMfRoute,
      params: {
        username: session?.username,
      },
    };

    const loginRoute = {
      name: AuthLoginRoute,
    };

    const dashboardRoute = {
      name: DashboardIndexRoute,
    };

    switch (true) {
      // Session required but no session found
      case requiresSession === true && !hasSession:
        message = SnackBarService.errors.mustBeLoggedIn;
        route = loginRoute;
        break;

      // Session required, is not authenticated and is not a MFA route
      case requiresSession === true && !isAuthenticated && !isMfaRoute:
        message = SnackBarService.errors.mustBeAuthenticated;
        route = mfaRoute;
        break;

      // Session required, is authenticated and is a MFA route
      case requiresSession === true && isAuthenticated && isMfaRoute:
        message = undefined;
        route = dashboardRoute;
        break;

      // Has valid roles (all)
      case requiresAllPermissions.intersection(hasPermissions ?? []).length !==
        requiresAllPermissions.length:
        message = SnackBarService.errors.notEnoughPermissions;
        break;

      // Has valid roles (some)
      case 0 < requiresSomePermissions.length &&
        0 === requiresSomePermissions.intersection(hasPermissions ?? []).length:
        message = SnackBarService.errors.notEnoughPermissions;
        break;

      // Has session, does not require session
      case hasSession && isAuthenticated && requiresSession === false:
        message = undefined;
        route = dashboardRoute;
        break;

      // Has session, requires authentication, is not authentication route
      case hasSession &&
        !isAuthenticated &&
        requiresSession === false &&
        to.name !== AuthRequestMfRoute:
        route = mfaRoute;
        break;

      default:
        break;
    }
  }

  message?.();

  if (route && route.name === DashboardIndexRoute) {
    next(route);
  } else if (route) {
    next({
      ...route,
      query: {
        ...(route.query ?? {}),
        redirect: to.path,
      },
    });
  } else {
    next();
  }
});

export { Router };
