import { FormComponentWrapper as t } from "../../form-builder/core/component-wrapper.js";
import a from "../../_vue/component.display/branded-kinvault-cta/default.js";
const e = t(
  a
);
export {
  a as BrandedKinvaultCtaDefault,
  e as FormBrandedKinvaultCtaDefault,
  a as default
};
