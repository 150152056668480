import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateGift } from "./create-gift.action";
import { DeleteGift } from "./delete-gift.action";
import { ReadGift } from "./read-gift.action";
import { RecordGift } from "./record-gift.action";
import { UpdateGift } from "./update-gift.action";

export const Portal2KinvaultGift = DefineActionBus({
  name: "portal-kinvault-gift",
  actions: {
    CreateGift,
    DeleteGift,
    ReadGift,
    UpdateGift,
    RecordGift,
  },
});
