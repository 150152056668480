import { Api } from "@kinherit/sdk/api";
import { Kintin } from "@kinherit/sdk";

//
export const FillStageEClosingLetter = async (message: { kintin: Kintin }) => {
  const primaryPerson = message.kintin.primaryPerson;
  const secondaryPerson = message.kintin.secondaryPerson;

  const surname = [
    primaryPerson.profile.lastName,
    secondaryPerson?.profile.lastName,
  ]
    .filter(Boolean)
    .unique()
    .join(" & ");

  await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/process/stage-e-closing-letter",
    {
      kintin: message.kintin,
    },
  )
    .method("get")
    .params({
      ...message,
      kintin: message.kintin.id,
    })
    .download({
      encoding: "application/pdf",
      fileName: `${message.kintin.ref} Stage E Closing Letter - ${surname}`,
    });
};
