import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KinvaultSubscriptionReferralCode } from "@kinherit/sdk";

export const UpdateKinvaultSubscriptionReferralCode = DefineWriteAction({
  interface: "portal",
  resource:
    "/v2/portal/kinvaults/subscription-referral-codes/{kinvaultSubscriptionReferralCode}",
  method: "patch",
  parse: (message: KinvaultSubscriptionReferralCode) => ({
    params: {
      kinvaultSubscriptionReferralCode: message,
    },
    body: {
      code: message.$data.code,
      coupon: message.$data.coupon,
      price: message.$data.price,
      introducerCompany: message.$data.introducerCompany,
    },
  }),
});
