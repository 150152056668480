<template>
  <div v-if="!loading" class="will-builder">
    <WillBuilderPersonTabs v-model:tab="computedTab" class="mb-5 person-tabs" />
    <slot />
    <WillBuilderDocumentTabs class="document-tabs" />
    <Warnings class="mt-3" />
    <DocumentContainer class="document-container" @edit-section="editSection" />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "WillBuilderWrapper",
  "imports": {
    "DocumentContainerWrapper": "@/module/kinvault.kintin/component/will-builder/output/DocumentContainer.test"
  },
  "methods": {
    "personTabs": {
      "type": "indexed-tabs",
      "selector": ".person-tabs"
    },
    "documentTabs": {
      "type": "tabs",
      "selector": ".document-tabs",
      "items": ["Will", "MOW Business", "MOW NRB", "MOW RES", "MOW IIP"]
    },
    "document": {
      "type": "to-one",
      "selector": ".document-container",
      "wrapper": "DocumentContainerWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import DocumentContainer from "@/module/kinvault.kintin/component/will-builder/output/DocumentContainer.vue";
import Warnings from "@/module/kinvault.kintin/component/will-builder/output/Warnings.vue";
import WillBuilderDocumentTabs from "@/module/kinvault.kintin/component/will-builder/output/WillBuilderDocumentTabs.vue";
import WillBuilderPersonTabs from "@/module/kinvault.kintin/component/will-builder/output/WillBuilderPersonTabs.vue";
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderSectionChangesForm } from "@/module/kinvault.kintin/form/will-builder/will-builder-section-changes.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { WillRevision } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WillBuilder",
  components: {
    WillBuilderPersonTabs,
    DocumentContainer,
    Warnings,
    WillBuilderDocumentTabs,
  },
  mixins: [KinvaultKintinDetailsMixin, WillBuilderService.mixin],
  props: {
    willRevision: {
      type: WillRevision,
      default: null,
    },
    tab: {
      type: Number,
      required: true,
    },
  },
  emits: {
    "update:tab": (value: number) => "number" === typeof value,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    computedTab: {
      get() {
        return this.tab;
      },
      set(value: number) {
        this.$emit("update:tab", value);
      },
    },
  },
  mounted() {
    if (!this.kintin) {
      return;
    }

    this.loading = false;
  },
  methods: {
    async editSection({
      section,
      custom,
      generated,
    }: {
      section: WillBuilderSections;
      custom?: string;
      generated: string;
    }) {
      if (!this.overrides) {
        return;
      }

      const result = await WillBuilderSectionChangesForm({
        custom,
        generated,
      }).dialog({
        dialog: {
          title: `Edit ${this.sections.titles[section]}`,
        },
      });

      const overrides = {
        ...this.overrides,
        [section.toString()]: result.currentContent,
      };

      this.overrides = overrides;
    },
  },
});
</script>

<style lang="scss">
.will-highlight {
  .is-will-builder-inject {
    background-color: #f6f6c4;
  }
}
.will {
  .section-container {
    section {
      padding: 2rem 0.5rem;
      margin: 1rem 0;
      border-bottom: rgba(0, 0, 0, 0.1) solid 2px;
      min-height: 10rem;
    }

    &.is-custom {
      box-shadow: 0 0 10px 1px #eecf1f;
      border-radius: 5px;
    }

    .ucase {
      text-transform: uppercase;
    }

    &:hover {
      .is-will-builder-section-controls {
        visibility: visible;
      }
    }
  }
}
</style>
