var $ = Object.defineProperty;
var h = (e, l) => $(e, "name", { value: l, configurable: !0 });
import { _ as c } from "../../../component.layout/card/card.vue_vue_type_script_lang.js";
import { resolveComponent as a, createElementBlock as o, openBlock as s, mergeProps as f, createCommentVNode as t, normalizeClass as u, createElementVNode as m, createBlock as n, withCtx as k, createTextVNode as v, toDisplayString as g, renderSlot as i } from "vue";
import { _ as y } from "../../../_plugins/_plugin-vue_export-helper.js";
const C = {
  key: 0,
  class: "level-left"
}, z = { class: "level-item" }, H = {
  key: 0,
  class: "card-title-container"
}, T = {
  key: 1,
  class: "card-header__heading"
}, B = {
  key: 1,
  class: "level-right"
}, I = { class: "level-item" }, M = {
  key: 2,
  class: /* @__PURE__ */ u({
    "card-header__buttons": !0,
    "is-right": !0,
    buttons: !0
  })
}, N = {
  key: 1,
  class: "card-content"
}, S = {
  key: 2,
  class: "card-footer"
}, V = {
  key: 0,
  class: /* @__PURE__ */ u({
    "card-footer__buttons": !0,
    "is-right": !0,
    buttons: !0
  })
};
function E(e, l, P, R, w, D) {
  const d = a("Title"), p = a("IconText"), b = a("Icon");
  return s(), o("div", f({
    class: {
      card: !0,
      "is-plain": e.isPlain,
      "has-responsive-buttons": e.hasResponsiveButtons,
      "has-small-header": e.title === null && e.subtitle !== null && e.hasHelp === !1 && e.hasContextMenu === !1 && e.$slots.buttons !== void 0
    }
  }, e.$data.$cypress), [
    e.title || e.subtitle || e.hasHelp || e.hasContextMenu || e.$slots.buttons || e.$slots.heading ? (s(), o("header", {
      key: 0,
      class: u({
        "card-header level mb-0": !0,
        "card-header-titleless": e.title === null && e.subtitle === null && e.$slots.heading === void 0
      })
    }, [
      e.title || e.subtitle || e.$slots.heading ? (s(), o("div", C, [
        m("div", z, [
          e.title || e.subtitle ? (s(), o("div", H, [
            e.title ? (s(), n(d, {
              key: 0,
              htag: e.titleHeadingTag,
              size: e.titleSize ?? "is-3",
              class: "is-marginless",
              "is-spaced": "",
              "is-html": !0,
              text: e.title
            }, null, 8, ["htag", "size", "text"])) : t("", !0),
            e.subtitle ? (s(), n(d, {
              key: 1,
              size: "is-5",
              "is-subtitle": ""
            }, {
              default: k(() => [
                v(g(e.subtitle), 1)
              ]),
              _: 1
            })) : t("", !0)
          ])) : (s(), o("div", T, [
            i(e.$slots, "heading")
          ]))
        ])
      ])) : t("", !0),
      e.hasHelp || e.hasContextMenu || e.$slots.buttons ? (s(), o("div", B, [
        m("div", I, [
          e.hasHelp ? (s(), n(p, {
            key: 0,
            "is-hoverable": "",
            "is-clickable": "",
            size: "is-normal",
            class: "is-marginless card-help-icon",
            color: "is-info",
            "icon-right": "Info",
            text: "Help",
            onClick: l[0] || (l[0] = (r) => e.$emit("help", r))
          })) : t("", !0),
          e.hasContextMenu ? (s(), n(b, {
            key: 1,
            size: "is-normal",
            class: "is-clickable card-context-menu",
            icon: "Horizontalellipsis",
            onClick: l[1] || (l[1] = (r) => e.$emit("context-menu", r))
          })) : t("", !0),
          e.$slots.buttons ? (s(), o("div", M, [
            i(e.$slots, "buttons")
          ])) : t("", !0)
        ])
      ])) : t("", !0)
    ], 2)) : t("", !0),
    e.$slots.default ? (s(), o("div", N, [
      i(e.$slots, "default")
    ])) : t("", !0),
    e.$slots.footer || e.$slots.buttons ? (s(), o("footer", S, [
      e.$slots.buttons && e.hasResponsiveButtons ? (s(), o("div", V, [
        e.$slots.buttons ? i(e.$slots, "buttons", { key: 0 }) : t("", !0)
      ])) : t("", !0),
      e.$slots.footer ? i(e.$slots, "footer", { key: 1 }) : t("", !0)
    ])) : t("", !0)
  ], 16);
}
h(E, "_sfc_render");
const G = /* @__PURE__ */ y(c, [["render", E]]);
export {
  G as default
};
