import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KinvaultCoupon } from "@kinherit/sdk";

export const CreateKinvaultCoupon = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/kinvaults/coupons/{kinvaultCoupon}",
  method: "patch",
  parse: (message: KinvaultCoupon) => ({
    params: {
      kinvaultCoupon: message,
    },
    body: {
      amountOff: message.$data.amountOff,
      currency: message.$data.currency,
      duration: message.$data.duration,
      durationInMonths: message.$data.durationInMonths,
      maxRedemptions: message.$data.maxRedemptions,
      name: message.$data.name,
      stripeCouponId: message.$data.stripeCouponId,
      percentOff: message.$data.percentOff,
    },
  }),
  after: ({ message }) => {
    KinvaultCoupon.$delete(message);
  },
});
