// CallScript

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { CallScript } from "@kinherit/sdk/model/call-script";

export const DeleteCallScript = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/call-script/{callScript}",
  method: "delete",
  parse: (callScript: CallScript) => ({
    params: {
      callScript,
      kintin: callScript.kintin,
    },
  }),
  after: ({ message }) => {
    CallScript.$delete(message);
  },
});
