import { RouteInterface } from "@kinherit/framework/core/route-config";
export const KinvaultSubscriptionIndexRoute = "KinvaultSubscriptionIndex";
export const KinvaultCouponMasterListRoute = "KinvaultCouponMasterList";
export const KinvaultPriceMasterListRoute = "KinvaultPriceMasterList";
export const KinvaultSubscriptionMasterListRoute =
  "KinvaultSubscriptionMasterList";
export const KinvaultSubscriptionReferralCodeMasterListRoute =
  "KinvaultSubscriptionReferralCodeMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultSubscriptionIndexRoute,
    path: "/kinvaults/subscriptions",
    component: () => import("./KinvaultSubscriptions.Index.vue"),
    meta: {
      breadcrumbs: () => [
        {
          text: "Kinvault Subscriptions",
          route: {
            name: KinvaultSubscriptionIndexRoute,
          },
        },
      ],
    },
    redirect: {
      name: KinvaultSubscriptionMasterListRoute,
    },
    children: [
      {
        name: KinvaultSubscriptionMasterListRoute,
        path: "/kinvaults/subscriptions/list",
        component: () => import("./KinvaultSubscriptions.MasterList.vue"),
        meta: {
          breadcrumbs: () => [
            {
              text: "Kinvault Subscriptions",
              route: {
                name: KinvaultSubscriptionMasterListRoute,
              },
            },
          ],
        },
      },
      {
        name: KinvaultCouponMasterListRoute,
        path: "/kinvaults/subscriptions/coupons",
        component: () => import("./KinvaultSubscriptions.Coupons.vue"),
        meta: {
          breadcrumbs: () => [
            {
              text: "Kinvault Subscriptions",
              route: {
                name: KinvaultSubscriptionMasterListRoute,
              },
            },
            {
              text: "Coupons",
              route: {
                name: KinvaultCouponMasterListRoute,
              },
            },
          ],
        },
      },
      {
        name: KinvaultPriceMasterListRoute,
        path: "/kinvaults/subscriptions/prices",
        component: () => import("./KinvaultSubscriptions.Prices.vue"),
        meta: {
          breadcrumbs: () => [
            {
              text: "Kinvault Subscriptions",
              route: {
                name: KinvaultSubscriptionMasterListRoute,
              },
            },
            {
              text: "Prices",
              route: {
                name: KinvaultPriceMasterListRoute,
              },
            },
          ],
        },
      },
      {
        name: KinvaultSubscriptionReferralCodeMasterListRoute,
        path: "/kinvaults/subscriptions/referral-codes",
        component: () => import("./KinvaultSubscriptions.ReferralCodes.vue"),
        meta: {
          breadcrumbs: () => [
            {
              text: "Kinvault Subscriptions",
              route: {
                name: KinvaultSubscriptionMasterListRoute,
              },
            },
            {
              text: "Referral Codes",
              route: {
                name: KinvaultSubscriptionReferralCodeMasterListRoute,
              },
            },
          ],
        },
      },
    ],
  },
];
