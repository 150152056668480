var s = Object.defineProperty;
var e = (r, t) => s(r, "name", { value: t, configurable: !0 });
import { _ as a } from "../../component.transition/transition-height.vue_vue_type_script_lang.js";
import { createBlock as i, openBlock as f, Transition as p, withCtx as m, renderSlot as d } from "vue";
import { _ as l } from "../../_plugins/_plugin-vue_export-helper.js";
function u(r, t, $, _, g, k) {
  return f(), i(p, {
    name: "transition-height",
    onEnter: t[0] || (t[0] = (n, o) => r.transEnter(n, o)),
    onLeave: t[1] || (t[1] = (n, o) => r.transLeave(n, o))
  }, {
    default: m(() => [
      d(r.$slots, "default")
    ]),
    _: 3
  });
}
e(u, "_sfc_render");
const T = /* @__PURE__ */ l(a, [["render", u]]);
export {
  T as default
};
