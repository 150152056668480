<template>
  <CreateMfAuthMethod
    v-if="$auth.loggedInUser"
    :user="$auth.loggedInUser"
    :show-cancel="false"
    :show-back="true"
    @back="done"
  />
</template>

<script lang="ts">
import { ActiveSession } from "@/module/auth/model/active-session.model";
import { AuthCreateMfRoute } from "@/module/auth/page";
import { DashboardIndexRoute } from "@/module/dashboard/page";
import { AuthService } from "@/service/auth.service";
import CreateMfAuthMethod from "@/shared/component/mf-auth-method/CreateMfAuthMethod.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: AuthCreateMfRoute,
  components: { CreateMfAuthMethod },
  mixins: [
    AuthService.mixin({
      sessionRequired: true,
    }),
  ],
  methods: {
    done(): void {
      const activeSession = ActiveSession.$getInstance();

      if (!activeSession) {
        return;
      }

      ActiveSession.$create({
        ...activeSession.$data,
        mfaSetupRequired: false,
      }).$persist();

      this.$router.push({
        name: DashboardIndexRoute,
      });
    },
  },
});
</script>
