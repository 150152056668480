var k = Object.defineProperty;
var r = (e, s) => k(e, "name", { value: s, configurable: !0 });
import { _ as f } from "../../../component.input/switch-field/switch-field.vue_vue_type_script_lang.js";
import { resolveComponent as u, createElementBlock as t, openBlock as l, mergeProps as h, createVNode as C, withCtx as $, createCommentVNode as n, createElementVNode as a, renderSlot as p, normalizeClass as i, createBlock as c, withKeys as b } from "vue";
import { b as m } from "../../../_assets/component.input/switch-field/switch-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as y } from "../../../_plugins/_plugin-vue_export-helper.js";
const w = {
  key: 0,
  class: "input-field__control"
}, z = ["modelValue", "disabled", "readonly"], I = {
  key: 1,
  class: "input-field__control"
};
function v(e, s, V, B, F, G) {
  const d = u("Icon"), g = u("Field");
  return l(), t("div", h(e.$data.$cypress, {
    class: `${e.wrapperClasses} input-field`
  }), [
    C(g, {
      class: "switch-field",
      label: e.label,
      "has-addons": e.computedhasAddons,
      "is-grouped": e.computedIsGrouped,
      messages: e.computedMessages,
      size: e.size
    }, {
      default: $(() => [
        e.showLeftControl ? (l(), t("div", w, [
          p(e.$slots, "left", { value: e.computedValue })
        ])) : n("", !0),
        a("div", {
          class: i(`input-field__control ${e.controlClasses} is-expanded`)
        }, [
          e.iconLeft ? (l(), c(d, {
            key: 0,
            size: e.size,
            icon: e.iconLeft,
            class: i(`is-left ${e.leftIconClick ? "is-clickable" : ""}`),
            onClick: e.leftIconClick
          }, null, 8, ["size", "icon", "class", "onClick"])) : n("", !0),
          e.computedIconRight ? (l(), c(d, {
            key: 1,
            icon: e.computedIconRight,
            size: e.size,
            class: i(`is-right ${e.rightIconClick ? "is-clickable" : ""}`),
            onClick: e.rightIconClick
          }, null, 8, ["icon", "size", "class", "onClick"])) : n("", !0),
          a("label", {
            class: i(`switch-wrapper ${e.inputClasses}`)
          }, [
            a("input", {
              class: i(`${e.valueGuard ? "checked" : ""}`),
              modelValue: e.valueGuard,
              type: "checkbox",
              disabled: e.state === "disabled",
              readonly: e.state === "readonly",
              role: "switch",
              onInput: s[0] || (s[0] = (...o) => e.focus && e.focus(...o)),
              onKeyup: s[1] || (s[1] = b((...o) => e.toggle && e.toggle(...o), ["space"]))
            }, null, 42, z),
            a("span", {
              class: "switch-button",
              onClick: s[2] || (s[2] = (...o) => e.toggle && e.toggle(...o))
            })
          ], 2)
        ], 2),
        e.showRightControl ? (l(), t("div", I, [
          p(e.$slots, "right", { value: e.computedValue })
        ])) : n("", !0)
      ]),
      _: 3
    }, 8, ["label", "has-addons", "is-grouped", "messages", "size"])
  ], 16);
}
r(v, "_sfc_render");
typeof m == "function" && m(f);
const S = /* @__PURE__ */ y(f, [["render", v]]);
export {
  S as default
};
