import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateCreditNote } from "./create-credit-note.action";
import { DeleteCreditNote } from "./delete-credit-note.action";
import { ReadCreditNote } from "./read-credit-note.action";
import { RecordCreditNote } from "./record-credit-note.action";
import { UpdateCreditNote } from "./update-credit-note.action";

export const Portal2CreditNote = DefineActionBus({
  name: "portal-credit-note",
  actions: {
    CreateCreditNote,
    DeleteCreditNote,
    UpdateCreditNote,
    ReadCreditNote,
    RecordCreditNote,
  },
});
