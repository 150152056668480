// KinvaultCoupon

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultCoupon } from "@kinherit/sdk";

export const ReadKinvaultCoupon = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/kinvault-coupon",
  method: "get",
  rootModel: KinvaultCoupon,
  parse: () => ({}),
});
