import { CreateProduct } from "@/config/model.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Product } from "@kinherit/sdk";

const options = [
  { value: "single", label: "Single" },
  { value: "joint", label: "Joint" },
  { value: "subscriptions", label: "Subscriptions" },
  { value: "trustreg", label: "Trustreg" },
];

export const UpdateProductForm = (product: Product = CreateProduct()) =>
  defineForm({
    name: "update-product",
    data: () => product,
    formAreas: (data) => [
      defineFormArea({
        name: "product",
        data,
        template: GridLayout([
          ["text", "publicText"],
          ["value", "price"],
          ["allowPackingSlip", "type"],
          ["startDate", "endDate"],
        ]),
        components: () => ({
          text: [
            FormTextField({
              props: {
                label: "Text",
                placeholder: "Text",
                validators: ["required"],
                message: "Internal product name",
              },
              models: {
                value: "text",
              },
            }),
          ],
          publicText: [
            FormTextField({
              props: {
                label: "Public Text",
                placeholder: "Public Text",
                validators: ["required"],
                message:
                  "Displayed to the end user, i.e invoices and payment forms",
              },
              models: {
                value: "publicText",
              },
            }),
          ],
          value: [
            FormTextField({
              props: {
                label: "SKU",
                placeholder: "somevalue",
                validators: ["required"],
                message: "Internal SKU value",
              },
              models: {
                value: "value",
              },
            }),
          ],
          price: [
            FormCurrencyField({
              props: {
                label: "Price",
                placeholder: "Price",
                validators: ["required"],
                message: "Price of the product, VAT included",
              },
              models: {
                value: "price",
              },
            }),
          ],
          allowPackingSlip: [
            FormCheckboxField({
              props: {
                label: "Allow Packing Slip",
                message: "Whether to include on packing slips",
              },
              models: {
                value: "allowPackingSlip",
              },
            }),
          ],
          type: [
            FormSelectField({
              props: {
                label: "Type",
                placeholder: "Type",
                options,
                reference: "type",
                mapOptions: {
                  value: "value",
                  label: "label",
                },
                validators: ["required"],
                message: "Apply on single or joint accounts",
              },
              models: {
                value: {
                  get: (data) =>
                    options.find((o) => o.value === data.type) || null,
                  set: (value, data) => {
                    data.type = value?.value || null;
                  },
                },
              },
            }),
          ],
          startDate: [
            FormDateField({
              props: {
                label: "Start Date",
                placeholder: "Start Date",
              },
              models: {
                value: "startDate",
              },
            }),
          ],
          endDate: [
            FormDateField({
              props: {
                label: "End Date",
                placeholder: "End Date",
              },
              models: {
                value: "endDate",
              },
            }),
          ],
        }),
      }),
    ],
  });
