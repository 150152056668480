var i = Object.defineProperty;
var s = (o, e) => i(o, "name", { value: e, configurable: !0 });
import { _ as n } from "../../../component.layout/sidebar/sidebar.vue_vue_type_script_lang.js";
import { withDirectives as d, createElementBlock as l, openBlock as p, createElementVNode as t, withModifiers as a, renderSlot as c, vShow as m } from "vue";
import { _ as u } from "../../../_plugins/_plugin-vue_export-helper.js";
const f = { class: "close-button" };
function w(o, e, S, v, $, h) {
  return d((p(), l("div", {
    class: "sidebar",
    onClick: e[1] || (e[1] = (r) => o.computedShow = !o.computedShow)
  }, [
    t("div", {
      class: "sidebar-container",
      onClick: a(() => null, ["stop"])
    }, [
      t("div", f, [
        t("button", {
          class: "delete",
          onClick: e[0] || (e[0] = (r) => o.computedShow = !o.computedShow)
        })
      ]),
      c(o.$slots, "default")
    ])
  ], 512)), [
    [m, o.computedShow]
  ]);
}
s(w, "_sfc_render");
const E = /* @__PURE__ */ u(n, [["render", w]]);
export {
  E as default
};
