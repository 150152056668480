import { Api } from "@/service/api.service";

interface ChangeTokenPasswordMessage {
  username: string;
  newPassword: string;
  token: string;
}

interface ChangeTokenPasswordResponse {
  [key: string]: never;
}

export const ChangeTokenPassword = async (
  message: ChangeTokenPasswordMessage,
): Promise<ChangeTokenPasswordResponse> => {
  await Api.resource("auth", "/v2/auth/change-password/token/{token}", {
    token: message.token,
  })
    .method("put")
    .body({
      newPassword: message.newPassword,
    })

    .result();

  return {};
};
