// Lead

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Lead } from "@kinherit/sdk/model/lead";

export const DeleteLead = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/lead/{lead}",
  method: "delete",
  parse: (lead: Lead | string) => ({
    params: {
      lead,
    },
  }),
  after: ({ message }) => {
    Lead.$delete(message);
  },
});
