var u = Object.defineProperty;
var a = (t, e) => u(t, "name", { value: e, configurable: !0 });
import l from "../../_vue/component.display/icon/icon.js";
import { CypressHelper as c } from "../../cypress/component-helpers.js";
import { ThemeFontAlign as d } from "../../theme/prop/font-align.js";
import { ThemeSize as f } from "../../theme/prop/size.js";
import { defineComponent as h } from "vue";
const v = h({
  name: "TabsComponent",
  components: { Icon: l },
  props: {
    /**
     * Show the active tab
     */
    showActive: {
      type: Boolean,
      default: !0
    },
    /**
     * Display an icon on the right side of the tabs (last tab item)
     */
    iconRight: {
      type: [String, Object],
      default: null
    },
    /**
     * Display an icon on the left side of the tabs (first tab item)
     */
    iconLeft: {
      type: [String, Object],
      default: null
    },
    /**
     * Configuration settings for the tabs
     */
    config: {
      type: Array,
      required: !0
    },
    /**
     * The position of the tabs
     * @default top
     */
    position: {
      type: String,
      default: "top",
      validator: /* @__PURE__ */ a((t) => ["top", "bottom", "both", "none"].includes(t), "validator")
    },
    /**
     * Size of the tabs
     */
    size: {
      type: String,
      default: "is-normal",
      validator: /* @__PURE__ */ a((t) => Object.values(f).includes(t), "validator")
    },
    /**
     * Display a box around the tab buttons
     */
    isBoxed: {
      type: Boolean,
      default: !1
    },
    /**
     * Display the tab buttons as toggles
     */
    isToggle: {
      type: Boolean,
      default: !1
    },
    /**
     * Display the tab buttons as toggles
     */
    isToggleRounded: {
      type: Boolean,
      default: !1
    },
    /**
     * Should the tabs span the entire width of its parent
     */
    isFullwidth: {
      type: Boolean,
      default: !0
    },
    /**
     * Alignmet of the tab buttons text
     */
    tabsFontAlign: {
      type: String,
      default: "has-text-centered",
      validator: /* @__PURE__ */ a((t) => Object.values(d).includes(t), "validator")
    },
    /**
     * Display the tabs vertically
     */
    isVertical: {
      type: Boolean,
      default: !1
    },
    /**
     * v-model for the current tab
     */
    tab: {
      type: Number,
      default: 0
    },
    /**
     * v-model for the value of current tab
     */
    value: {
      type: [Number, String, Object],
      default: null
    },
    /**
     * Only create the active tab
     */
    isLazy: {
      type: Boolean,
      default: !1
    },
    /**
     * Display the tabs in a compact style
     */
    isCompact: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["update:tab", "click:right", "click:left", "update:value"],
  data: /* @__PURE__ */ a((t) => ({
    currentTab: 0,
    focusedTab: null,
    $cypress: c({ ctx: t })
  }), "data"),
  computed: {
    classes() {
      const t = {
        [this.size]: !0,
        "is-boxed": this.isBoxed,
        "is-toggle": this.isToggle || this.isToggleRounded,
        "is-toggle-rounded": this.isToggleRounded,
        "is-fullwidth": this.isFullwidth,
        "is-vertical": this.isVertical,
        "is-compact": this.isCompact
      };
      return Object.keys(t).filter((e) => t[e]).join(" ");
    },
    slots() {
      const t = ["tab-item-left"], e = Object.keys(this.$slots).filter((s) => !s.startsWith("_") && !t.includes(s)).map((s) => s);
      return this.isLazy ? [e[this.currentTab]] : e;
    },
    computedCurrentTab: {
      get() {
        var s, i;
        const t = (i = (s = window.Kernel.Router) == null ? void 0 : s.currentRoute.value) == null ? void 0 : i.name;
        if (t) {
          const o = this.config.findIndex((r) => {
            if ("nestedRoutes" in r && r.nestedRoutes && r.nestedRoutes.includes(t.toString()))
              return !0;
            if ("route" in r) {
              const n = r.route;
              if (typeof n == "string" && n === t.toString())
                return !0;
              if (n !== void 0 && typeof n != "string" && "name" in n)
                return n.name === t.toString();
            }
            return !1;
          });
          if (o !== -1)
            return o;
        }
        const e = this.config.findIndex((o) => {
          if (JSON.stringify(o) === JSON.stringify(this.value))
            return !0;
        });
        return e !== -1 ? e : this.value !== null ? null : this.tab;
      },
      set(t) {
        this.currentTab = t, this.$emit("update:tab", t);
        const e = this.config[t];
        "route" in e && window.Kernel.visitRoute(e.route);
      }
    },
    compFocusedTab: {
      get() {
        return this.focusedTab ?? this.computedCurrentTab ?? 0;
      },
      set(t) {
        this.focusedTab = t;
      }
    },
    compValue: {
      get() {
        return this.computedCurrentTab ? this.config[this.computedCurrentTab] : null;
      },
      set(t) {
        const e = this.config.findIndex((s) => s === t);
        e > -1 && (this.$emit("update:value", t), this.computedCurrentTab = e);
      }
    }
  },
  watch: {
    tab(t) {
      this.currentTab = t;
    }
  },
  mounted() {
    this.currentTab = this.tab;
  },
  methods: {
    onKeyDown(t, e) {
      var o;
      const s = t.code.toLowerCase();
      if (this.compFocusedTab === null) return;
      let i = 0;
      switch (s) {
        case "arrowright":
        case "arrowdown":
          i = Math.min(this.compFocusedTab + 1, this.config.length - 1), t.preventDefault(), t.stopPropagation();
          break;
        case "arrowleft":
        case "arrowup":
          i = Math.max(this.compFocusedTab - 1, 0), t.preventDefault(), t.stopPropagation();
          break;
        case "enter":
        case "space":
          this.handleClick(
            this.compFocusedTab,
            new MouseEvent("click"),
            e
          ), t.preventDefault(), t.stopPropagation();
          return;
        case "home":
          i = 0;
          break;
        case "end":
          i = this.config.length - 1;
          break;
        default:
          return;
      }
      this.compFocusedTab = i, (o = this.$refs.refTab[i]) == null || o.focus();
    },
    async handleClick(t, e, s) {
      var o;
      const i = this.config[t];
      if ((o = window.Kernel) != null && o.Router && "route" in i) {
        window.Kernel.visitRoute(i.route, e.ctrlKey), this.currentTab = t;
        return;
      }
      if (s && this.compValue !== i) {
        const r = this.$el;
        (r.children.item(0) ?? r).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start"
        });
      }
      this.compValue = i;
    }
  }
});
export {
  v as _
};
