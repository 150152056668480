var s = Object.defineProperty;
var t = (o, e) => s(o, "name", { value: e, configurable: !0 });
import { _ as r } from "../../../component.display/qr-code/qr-code.vue_vue_type_script_lang.js";
import { resolveComponent as c, createElementBlock as a, openBlock as m, createVNode as d, mergeProps as i } from "vue";
import { _ as p } from "../../../_plugins/_plugin-vue_export-helper.js";
const _ = { class: "qr-code" };
function l(o, e, f, u, g, C) {
  const n = c("QRCode");
  return m(), a("div", _, [
    d(n, i(o.bindings, {
      imgclass: "",
      myclass: "",
      "download-button": "",
      "-button-name": "",
      "file-ext": "",
      image: ""
    }), null, 16)
  ]);
}
t(l, "_sfc_render");
const R = /* @__PURE__ */ p(r, [["render", l]]);
export {
  R as default
};
