var p = Object.defineProperty;
var f = (t, e) => p(t, "name", { value: e, configurable: !0 });
const u = /* @__PURE__ */ f((t) => {
  var e;
  if (t && t.offsetParent) {
    const n = document.body.clientWidth, s = document.body.clientHeight, d = t.offsetTop, o = (e = t.parentElement) == null ? void 0 : e.getBoundingClientRect();
    if (!o)
      return;
    t.style.left = "unset", t.style.right = "unset", t.style.top = "unset";
    const i = t.getBoundingClientRect(), c = i.right, r = i.bottom;
    o.left <= n - o.right || o.width >= i.width ? t.style.left = c > n ? n - c + "px" : "0" : t.style.right = "0", r > s && (t.style.top = s - r + d + "px");
  }
}, "MoveElement"), g = {
  created(t, e) {
    var n;
    e.value !== !1 && ((n = e.instance) == null || n.$nextTick(() => {
      u(t);
    }));
  },
  updated(t, e) {
    var n;
    e.value !== !1 && ((n = e.instance) == null || n.$nextTick(() => {
      u(t);
    }));
  }
};
export {
  g as KeepOnScreenDirective
};
