var v = Object.defineProperty;
var A = (a, r, i) => r in a ? v(a, r, { enumerable: !0, configurable: !0, writable: !0, value: i }) : a[r] = i;
var h = (a, r) => v(a, "name", { value: r, configurable: !0 });
var b = (a, r, i) => A(a, typeof r != "symbol" ? r + "" : r, i);
import { FooterOptions as F } from "../component.layout/footer/types.js";
import { NavbarOptions as N } from "../component.layout/header/types.js";
import { AppOptions as l } from "./internal-components/app/types.js";
const f = class f {
  constructor(r) {
    b(this, "Navbar");
    b(this, "Footer");
    b(this, "App");
    this.Navbar = new N(r == null ? void 0 : r.navbar), this.Footer = new F(r == null ? void 0 : r.footer), this.App = new l(r == null ? void 0 : r.app);
  }
};
h(f, "ComponentOptions");
let w = f;
export {
  w as ComponentOptions
};
