var r = Object.defineProperty;
var i = (e, t) => r(e, "name", { value: t, configurable: !0 });
import n from "../../_vue/component.display/json/json.js";
import a from "../../_vue/component.form/form-area/form-area.js";
import l from "../../component.mixin/route-storage-mixin.js";
import { KernelModes as f } from "../../core/kernel-mode.js";
import { CypressHelper as m } from "../../cypress/component-helpers.js";
import { Uuid as d } from "@kinherit/ts-common/service/uuid";
import { defineComponent as c } from "vue";
import h from "../../_vue/component.form/form/form-inner.js";
import { S as p } from "../../_assets/component.form/form/form.module.js";
const M = c({
  name: "FormComponent",
  components: { Json: n, FormArea: a, FormInner: h },
  mixins: [l(!1)],
  props: {
    /**
     * The form builder class (defineForm)
     */
    config: {
      type: Object,
      required: !0
    },
    /**
     * The forms data property (v-model:data)
     */
    data: {
      type: Object,
      default: null
    },
    /**
     * Show a loading animation over the top of the component
     */
    isSkeleton: {
      type: Boolean,
      default: !1
    },
    /**
     * Force the validation to be show without watching for the fields to be focused
     */
    showValidation: {
      type: Boolean,
      default: !1
    },
    /**
     * Hide the border on the left side of the fieldsets
     */
    isBorderless: {
      type: Boolean,
      default: !1
    },
    /**
     * @internal
     */
    isDialog: {
      type: Boolean,
      default: !1
    }
  },
  emits: void 0,
  data: /* @__PURE__ */ i((e) => ({
    $cypress: m({
      ctx: e,
      labelField: /* @__PURE__ */ i((t) => t.config.name, "labelField"),
      refField: /* @__PURE__ */ i((t) => t.config.name, "refField")
    }),
    renderKey: 0,
    formErrors: {},
    showValidationOverride: !1,
    uuid: d.generate(),
    formAreas: Array(),
    components: {},
    tooltip: {
      devmode: "Disable DevMode to hide"
    },
    loading: !0
  }), "data"),
  computed: {
    filteredFormAreas() {
      return this.loading ? [] : this.formAreas.filter(
        (e) => (e.show ? e.show(this.config.localData) : !0) !== !1
      );
    },
    devMode() {
      return window.Kernel.Mode === f.Dev;
    },
    classes() {
      const e = {
        form: !0,
        "is-skeleton": this.isSkeleton,
        [this.config.name]: !0,
        [`form-${this.uuid}`]: !0,
        "is-borderless": this.isBorderless
      };
      return Object.keys(e).filter((t) => e[t]).join(" ");
    }
  },
  async beforeMount() {
    var t, o, s;
    window.Kernel.injectStylesheet("form", p);
    const e = this.getState(`${this.config.name}-data`);
    Object.assign(((t = this.config) == null ? void 0 : t.localData) ?? {}, JSON.parse(e ?? "{}")), await ((s = (o = this.config) == null ? void 0 : o.beforeMount) == null ? void 0 : s.call(o, this.config.localData)), this.buildForm(), this.loading = !1;
  },
  methods: {
    updateState() {
      this.setState(`${this.config.name}-data`, JSON.stringify(this.data));
    },
    incrementRenderKey() {
      this.renderKey++;
    },
    submit() {
      this.config.isValid() && this.config.options.enterToSubmit !== !1 ? this.$emit("submit") : this.showValidationOverride = !0;
    },
    buildForm() {
      this.config.setFormComponent(this), this.config.formAreas !== void 0 && (this.formAreas = this.config.formAreas(this.config.localData)), this.formAreas.forEach((e) => {
        this.components[e.name] = e.components ? e.components(this.config.localData) : [];
      }), this.config.setComponentSets(this.components), this.incrementRenderKey();
    },
    castCallbackToValue(e) {
      return typeof e == "function" ? e(this.config.localData) : e;
    }
  }
});
export {
  M as _
};
