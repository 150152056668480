<template>
  <div v-if="kintin">
    <CallScriptSection title="Recommendations Report and contact details">
      <b>
        OK, so you’ll be pleased to know that’s pretty much everything we need
        to cover today.
        <br /><br />
        I appreciate we’ve gone through quite a lot, but I’ll send you my
        recommendations Report shortly and the prices relevant to your
        situation, which will lay out all the things we’ve talked about and give
        you my thoughts on what I think you should do.
        <br /><br />
        It would be great if you did get the chance to read it – but we will go
        through it together on our next call so I can answer your questions.
        <br /><br />
        I’ll send it as password-protected, and the password will be set as your
        date of birth … but I’ll reconfirm all that in the email.
        <br /><br />
        Just to flag... the personal information in the report isn’t set in
        stone – it can be changed later – so don’t worry if you change your mind
        on anything at this stage.
        <br /><br />
        I’ll include a couple of other attachments in my email, which will give
        you a bit more information on various things, including the roles in
        your Will, and how to talk to the people you’ve named as executors etc.
        <br /><br />
      </b>
      <em>[If they still haven’t watched the Kinvault video:]</em><br />
      <b>
        One final thing - I’m also going to send you the link to the Kinvault
        video I mentioned earlier – that’s your 60 second homework if you don’t
        mind, before the next call, just so you can visualise what it is.
        <em
          >[gently encourage them to ‘commit’ to watching the video if they
          haven’t already seen it…]</em
        >
        <br /><br />
        So before we book your next call, did you have any other questions for
        me today? Have I covered what you expected?
      </b>
      <br /><em>[Pause]</em> <br /><br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="5.0.2"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSection title="Setup next call">
      <span v-if="!callScript.person2Present">
        <b>
          As {{ secondarySalutation }} is not on this call, we need to pick a
          time when both of you are available, so they can confirm the
          verification checks. <br /><br />
          Do you know their diary?
          <br /><em>[If no]</em><br />
          OK, that is not a problem, I will email straight after this call with
          a link to my diary, you can book a convenient time for you both for in
          a couple of days.
        </b>
        <br /><em>[If yes]</em><br />
        <b>
          Could the [suggest day/time 2 days forward] be convenient? [agree
          time/date]
        </b>
        <br /><em>[Pause: await answer]</em> <br /><br />
        <b>
          Fantastic. That is everything from me, thank you again and I will
          speak to you on [REPEAT AGREED TIME AND DATE].”
        </b>
      </span>
      <span v-if="callScript.person2Present">
        <b>
          Could the [suggest day/time 2 days forward] be convenient? [agree
          time/date]
        </b>
        <br /><em>[Pause: await answer]</em> <br /><br />
        <b>
          Fantastic. That is everything from me, thank you again and I will
          speak to you on [REPEAT AGREED TIME AND DATE].”
        </b>
      </span>
      <br /><br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="5.1.2"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSection title="Post Call Followups" :speech="false">
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="5.1.4a"
        :kintin="kintin"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="5.1.4b"
        :kintin="kintin"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="5.1.4c"
        :kintin="kintin"
        @update="updateCheckValue"
      />
      <Message class="is-shadowless mt-4">
        <mark>
          Question to specialist: is IHT likely to be a consideration? YES /
          MAYBE / UNLIKELY <br />Question to specialist: for illustrative EPT
          calc, estate value to use? [default: total assets with cap at £1m]
          <br />Question to specialist: for illustrative EPT calc, how many
          Beneficiaries of trust? [default: number Children] <br />REVIEW
          follow-on notes for anything to mention in email. Draft email
          (referencing items being checked if appropriate) and generate 4.1+send
          <br />Question to specialist: are there follow-up items for
          super-specialist Y/N - If Y, have questions been solved? [checkbox
          when done]
        </mark>
      </Message>
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import Message from "@kinherit/framework/component.display/message";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: "OutroTab",
  components: {
    ConfirmCheck,
    CallScriptSection,
    Message,
  },
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  emits: { "save-and-continue": null, save: null },
  data: () => ({
    isFocused: false,
    tab: 0,
  }),
  methods: {
    async saveAndContinueHandler() {
      this.$emit("save-and-continue");
    },
    async saveHandler() {
      this.$emit("save");
    },
  },
});
</script>
