<template>
  <NotePage
    :has-write-permission="hasWriteAccess"
    :person="$data.officer"
    :note-types="['note', 'communicationNote']"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultOfficerDetailsNoteWrapper",
  "route": "KinvaultOfficerDetailsNote",
  "extends": {
    "name": "NotePageWrapper",
    "path": "@/module/core/component/note/NotePage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import NotePage from "@/module/core/component/note/NotePage.vue";
import { defineComponent } from "vue";
import { KinvaultOfficerDetailsNoteRoute } from ".";
import { KinvaultOfficerDetailsMixin } from "../../../mixin/kinvault-officer-details.mixin";

export default defineComponent({
  name: KinvaultOfficerDetailsNoteRoute,
  components: { NotePage },
  mixins: [KinvaultOfficerDetailsMixin],
});
</script>
