import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const BrandedKinvaultCtaMasterListRoute =
  "AdminBrandedKinvaultCtaMasterList";
export const AdminBrandedKinvaultCtaCreateRoute =
  "AdminBrandedKinvaultCtaCreate";

export const AdminBrandedKinvaultCtaUpdateRoute =
  "AdminBrandedKinvaultCtaUpdate";
const AdminBrandedKinvaultCtaUpdateUri =
  "/admin/branded-kinvault-cta/:brandedKinvaultCta/update";
export type AdminBrandedKinvaultCtaUpdateRouteParams = RouteParamObject<
  typeof AdminBrandedKinvaultCtaUpdateUri
>;

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultCtaMasterListRoute,
    path: "/admin/branded-kinvault-cta",
    component: () =>
      import(
        /* webpackChunkName: "BrandedKinvaultCtaMasterList" */ "./AdminBrandedKinvaultCtaMasterList.vue"
      ),
  },
  {
    name: AdminBrandedKinvaultCtaCreateRoute,
    path: "/admin/branded-kinvault-cta/create",
    component: () =>
      import(
        /* webpackChunkName: "AdminBrandedKinvaultCtaCreate" */ "./AdminBrandedKinvaultCtaCreate.vue"
      ),
  },
  {
    name: AdminBrandedKinvaultCtaUpdateRoute,
    path: AdminBrandedKinvaultCtaUpdateUri,
    component: () =>
      import(
        /* webpackChunkName: "AdminBrandedKinvaultCtaCreate" */ "./AdminBrandedKinvaultCtaCreate.vue"
      ),
  },
];
