var I = Object.defineProperty;
var g = (e, i) => I(e, "name", { value: i, configurable: !0 });
import { _ as $ } from "../../../component.input/select-field/select-field.vue_vue_type_script_lang.js";
import { resolveComponent as v, createElementBlock as s, openBlock as l, mergeProps as V, createVNode as F, withCtx as L, createCommentVNode as t, createElementVNode as u, renderSlot as b, normalizeClass as n, createBlock as c, Fragment as d, toDisplayString as m, renderList as f } from "vue";
import { b as y } from "../../../_assets/component.input/select-field/select-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as B } from "../../../_plugins/_plugin-vue_export-helper.js";
const S = {
  key: 0,
  class: "input-field__control-left input-field__control"
}, w = ["id", "cy-ref", "value", "placeholder", "disabled", "readonly", "autofocus", "autocomplete"], N = ["selected"], G = ["selected", "value"], O = ["label"], R = ["selected", "value"], E = {
  key: 1,
  class: "input-field__control-right input-field__control"
}, P = { class: "field has-addons" };
function A(e, i, D, H, M, j) {
  const r = v("Icon"), z = v("Field");
  return l(), s("div", V(e.$data.$cypress, {
    class: `${e.wrapperClasses} input-field`
  }), [
    F(z, {
      label: e.label,
      "has-addons": e.computedhasAddons || e.showClearButton,
      "is-grouped": e.computedIsGrouped,
      messages: e.computedMessages,
      size: e.size,
      "auto-collapse-label": e.autoCollapseLabel,
      "has-focus": e.hasFocus,
      "has-value": e.computedHasValue,
      "input-id": e.computedId
    }, {
      default: L(() => {
        var h, k;
        return [
          e.showLeftControl ? (l(), s("div", S, [
            b(e.$slots, "left", { value: e.computedValue })
          ])) : t("", !0),
          u("div", {
            class: n(`input-field__control ${e.controlClasses} is-expanded`)
          }, [
            e.iconLeft ? (l(), c(r, {
              key: 0,
              size: e.size,
              icon: e.iconLeft,
              class: n(`is-left ${e.leftIconClick ? "is-clickable" : ""}`),
              onClick: e.leftIconClick
            }, null, 8, ["size", "icon", "class", "onClick"])) : t("", !0),
            e.isLoading ? t("", !0) : (l(), s(d, { key: 1 }, [
              e.showClearButton && e.computedValue !== null ? (l(), c(r, {
                key: 0,
                icon: "Clear",
                size: e.size,
                class: "is-right is-clickable field-clear-button",
                onClick: e.clear
              }, null, 8, ["size", "onClick"])) : e.computedIconRight ? (l(), c(r, {
                key: 1,
                icon: e.computedIconRight,
                size: e.size,
                class: n(`is-right ${e.rightIconClick ? "is-clickable" : ""}`),
                onClick: e.rightIconClick
              }, null, 8, ["icon", "size", "class", "onClick"])) : t("", !0)
            ], 64)),
            u("div", {
              class: n(`select ${e.inputClasses}`)
            }, [
              u("select", {
                id: e.computedId,
                ref: "input",
                class: n(e.selectClass),
                "cy-ref": `${e.$data.$cypress["cy-ref"]}-select`,
                value: ((h = e.computedValue) == null ? void 0 : h.value) ?? "",
                placeholder: !e.autoCollapseLabel || e.hasFocus ? e.placeholder ?? void 0 : void 0,
                disabled: e.state === "disabled",
                readonly: e.state === "readonly",
                autofocus: e.autofocus,
                autocomplete: e.autocomplete ?? void 0,
                onChange: i[0] || (i[0] = (o) => e.computedValue = e.computedOptions.find(
                  (p) => {
                    var a, C;
                    return ((a = p.value) == null ? void 0 : a.toString()) === ((C = o.target.value) == null ? void 0 : C.toString());
                  }
                ) ?? null),
                onFocus: i[1] || (i[1] = (...o) => e.focus && e.focus(...o)),
                onBlur: i[2] || (i[2] = (...o) => e.blur && e.blur(...o))
              }, [
                e.includePlaceholder ? (l(), s("option", {
                  key: 0,
                  value: "",
                  selected: e.computedValue === null
                }, m(!e.autoCollapseLabel || e.hasFocus ? e.placeholder ?? void 0 : void 0), 9, N)) : t("", !0),
                (l(!0), s(d, null, f(((k = e.computedGroupedOptions.findBy(
                  "groupName",
                  void 0
                )) == null ? void 0 : k.items) ?? [], (o) => (l(), s("option", {
                  key: `select-option-${o}`,
                  selected: e.isSelected(o),
                  value: o.value
                }, m(o.label), 9, G))), 128)),
                (l(!0), s(d, null, f(e.computedGroupedOptions.filter(
                  (o) => o.groupName !== void 0
                ), (o, p) => (l(), s("optgroup", {
                  key: `${p}-option-group`,
                  label: o.groupName
                }, [
                  (l(!0), s(d, null, f(o.items, (a) => (l(), s("option", {
                    key: `select-option-${a}`,
                    selected: e.isSelected(a),
                    value: a.value
                  }, m(a.label), 9, R))), 128))
                ], 8, O))), 128))
              ], 42, w)
            ], 2)
          ], 2),
          e.showRightControl ? (l(), s("div", E, [
            u("div", P, [
              b(e.$slots, "right", { value: e.computedValue })
            ])
          ])) : t("", !0)
        ];
      }),
      _: 3
    }, 8, ["label", "has-addons", "is-grouped", "messages", "size", "auto-collapse-label", "has-focus", "has-value", "input-id"])
  ], 16);
}
g(A, "_sfc_render");
typeof y == "function" && y($);
const U = /* @__PURE__ */ B($, [["render", A]]);
export {
  U as default
};
