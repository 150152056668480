import { Api } from "@kinherit/sdk/api";
import {
  IntroducerCompany,
  IntroducerContact,
  IntroducerOutsource,
  Kintin,
  Lead,
  Note,
  Person,
} from "@kinherit/sdk";

type Controllers =
  | "/v2/portal/kinvault/{owner}/notes/{note}/file/upload"
  | "/v2/portal/lead/{owner}/notes/{note}/file/upload"
  | "/v2/portal/introducer/company/note/{owner}/{note}/file/upload"
  | "/v2/portal/introducer/contact/note/{owner}/{note}/file/upload"
  | "/v2/portal/officer/{owner}/notes/{note}/file/upload"
  | "/v2/portal/introducer/outsources/note/{owner}/{note}/file/upload";

type UploadNoteFileMessage = {
  note: Note;
  file: File;
} & (
  | {
      kintin: Kintin | string;
    }
  | {
      lead: Lead | string;
    }
  | {
      introducerCompany: IntroducerCompany | string;
    }
  | {
      introducerContact: IntroducerContact | string;
    }
  | { introducerOutsource: string | IntroducerOutsource }
  | {
      person: string | Person;
    }
);

export const UploadNoteFile = async (message: UploadNoteFileMessage) => {
  let path = "" as Controllers;
  const params = {
    note: message.note.id,
  } as any;
  if ("kintin" in message && message.kintin) {
    path = "/v2/portal/kinvault/{owner}/notes/{note}/file/upload";
    params.owner = message.kintin;
  } else if ("lead" in message && message.lead) {
    path = "/v2/portal/lead/{owner}/notes/{note}/file/upload";
    params.owner = message.lead;
  } else if ("introducerCompany" in message && message.introducerCompany) {
    path = "/v2/portal/introducer/company/note/{owner}/{note}/file/upload";
    params.owner = message.introducerCompany;
  } else if ("introducerContact" in message && message.introducerContact) {
    path = "/v2/portal/introducer/contact/note/{owner}/{note}/file/upload";
    params.owner = message.introducerContact;
  } else if ("person" in message && message.person) {
    path = "/v2/portal/officer/{owner}/notes/{note}/file/upload";
    params.owner = message.person;
  } else if ("introducerOutsource" in message && message.introducerOutsource) {
    path = "/v2/portal/introducer/outsources/note/{owner}/{note}/file/upload";
    params.owner = message.introducerOutsource;
  } else {
    throw new Error("Invalid message");
  }

  await Api.resource("portal", path as Controllers, params)
    .method("put")
    .files({
      uploadedFile: message.file,
    })
    .result();

  return {};
};
