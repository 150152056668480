import {
  IntroducerCompanyField,
  KintinField,
  OptionsSelectField,
  UserField,
} from "@/config/form.config";
import { CreateIntroducerOutsource } from "@/config/model.config";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import { IntroducerOutsource, Option } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";

export const CreateIntroducerOutsourceForm = (
  introducerOutsource: IntroducerOutsource = CreateIntroducerOutsource({
    datedAt: new DateTime().formatMachine,
    status: Option.$findOneByOrThrow({
      group: "introducerOutsourceStatuses",
      value: "new",
    }).id,
  }),
) => {
  return defineForm({
    name: "create-introducer-outsource-form",
    data: () => introducerOutsource,
    formAreas: (data) => [
      defineFormArea({
        name: "create-introducer-outsource-form-area",
        data,
        template: GridLayout([
          ["clientName"],
          ["sourceKintin", "postcode"],
          ["datedAt", "introducerCompany", "type"],
          ["assignedTo", "status", "nextActionAt"],
          { is: "hr" },
          ["subtitle1"],
          ["expectedValue", "paidValue", "paidAt"],
        ]),
        components: () => ({
          clientName: [
            FormTextField({
              props: {
                label: "Case Name",
                validators: ["required"],
                message: "Client or Case Name",
              },
              models: {
                value: "clientName",
              },
            }),
          ],
          sourceKintin: [
            KintinField<any>({
              props: {
                label: "Related Account",
                vModel: "sourceKintin",
                message: "Referral was created by this account",
              },
              query: {},
            }),
          ],
          postcode: [
            FormTextField({
              props: {
                label: "Related Postcode",
                message: "If this is related to a property",
              },
              models: {
                value: "postcode",
              },
            }),
          ],
          datedAt: [
            FormDateField({
              props: {
                label: "Dated At",
                validators: ["required"],
                message: "When was this case referred?",
              },
              models: {
                value: "datedAt",
              },
            }),
          ],
          introducerCompany: [
            IntroducerCompanyField({
              props: {
                size: "is-normal",
                vModel: "company",
                label: "Referring to",
                message: "Who are we passing work to?",
                validators: ["required"],
                isMultiSelect: false,
              },
            }),
          ],
          type: [
            OptionsSelectField({
              group: "introducerOutsourceTypes",
              vModel: "type",
              props: {
                label: "Type of introduction",
                placeholder: "Select a type",
                validators: ["required"],
                message: "What type of work are we passing?",
              },
            }),
          ],
          status: [
            OptionsSelectField({
              group: "introducerOutsourceStatuses",
              vModel: "status",
              props: {
                label: "Status",
                validators: ["required"],
                placeholder: "Select a status",
              },
            }),
          ],
          assignedTo: [
            UserField({
              props: {
                vModel: "assignedTo",
                label: "Assigned To",
                message: "Who's dealing with this case?",
                reference: "assignedTo",
                placeholder: "Select a user",
                validators: ["required"],
              },
              query: {
                displayUserInPortal: Equal(true),
              },
            }),
          ],
          nextActionAt: [
            FormDateField({
              props: {
                label: "Next Action Date",
              },
              models: {
                value: "nextActionAt",
              },
            }),
          ],
          subtitle1: [
            FormTitle({
              props: {
                isSubtitle: true,
                text: "Valuation & Payments",
              },
            }),
          ],
          expectedValue: [
            FormCurrencyField({
              props: {
                label: "Expected Value",
                validators: ["required"],
              },
              models: {
                value: "expectedValue",
              },
            }),
          ],
          paidValue: [
            FormCurrencyField({
              props: {
                label: "Paid Value",
              },
              models: {
                value: "paidValue",
              },
            }),
          ],
          paidAt: [
            FormDateField({
              props: {
                label: "Paid At",
              },
              models: {
                value: "paidAt",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
