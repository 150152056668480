<template>
  <Wizard
    v-model:step="wizardStepStatus"
    :size="'is-small'"
    :config="wizardConfig"
    :color="'is-primary'"
  />
  <MasterListPage
    v-if="$data.filters"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    is-nested
    :count="pagination.count"
    :last-page="pagination.lastPage"
    :sort-by-options="{
      createdAt: 'Created',
      'xeroContact.contactName': 'Name',
    }"
    :persist-state="false"
    @refresh="refresh"
  >
    <template #tableControls>
      <div class="buttons">
        <Button
          v-if="showSubmitPaymentRun"
          color="is-kinherit-petrol"
          text="Submit For Approval"
          @click="submitPaymentRun"
        />
        <Button
          v-if="showApprovePaymentRun"
          color="is-primary"
          text="Approve Payment Run"
          @click="approvePaymentRun"
        />
        <Button
          v-if="showPushToXeroPaymentRun"
          color="is-purple"
          text="Push to Xero"
          @click="publishPaymentRun"
        />
        <Button
          color="is-warning"
          text="Send Remittance Emails"
          @click="sendEmail"
        />
        <Button
          v-if="showCompletePaymentRun || showSendRemittanceEmails"
          color="is-primary"
          text="Mark Complete"
          @click="completePaymentRun"
        />
      </div>
    </template>
    <template #details="{ row }: { row: IntroducerFeePayment }">
      <div
        v-for="item in row.introducerFeePaymentItems"
        :key="item.id"
        class="columns"
      >
        <div class="column">
          <span class="is-size-7">{{ item.name }}</span>
          <span v-if="item.xeroBillId" class="is-block">{{
            item.xeroBillId
          }}</span>
        </div>
        <div class="column">
          <span class="is-size-7">
            {{ item.feeTypeId?.toSentenceCase().ucFirst() }}
            <span class="is-block has-text-weight-bold">
              {{ item.amount.format }} ({{ item.vat?.ucFirst() }})</span
            >
          </span>
        </div>
        <div class="column has-text-right">
          <Button
            v-if="showEditButton(row)"
            color="is-plain"
            size="is-small"
            aria-label="Edit Payment"
            tooltip="Edit Payment"
            icon-left="Edit"
            @click="editPaymentItem(item)"
          />
          <Button
            v-if="showDeleteButton(row)"
            color="is-plain"
            class="has-text-danger"
            tooltip="Delete Payment Item"
            size="is-small"
            aria-label="Delete"
            icon-left="Trash"
            @click="deletePaymentItem(item)"
          />
        </div>
      </div>
    </template>
    <template #status="{ row }: { row: IntroducerFeePayment }">
      <span
        v-if="row.status?.value === 'awaitingApproval'"
        class="badge is-info is-rounded"
      >
        Awaiting Approval
      </span>
      <span v-if="row.status?.value === 'approved'" class="badge is-success">
        Approved
      </span>
      <span v-if="row.status?.value === 'onXero'" class="badge is-purple">
        On Xero
      </span>
      <span
        v-if="row.status?.value === 'remittanceSent'"
        class="badge is-success is-rounded"
      >
        Remittance Sent
      </span>
      <span
        v-if="row.status?.value === 'paid'"
        class="badge is-success is-rounded"
      >
        Paid
      </span>
    </template>
    <template #actions="{ row }: { row: IntroducerFeePayment }">
      <div class="buttons">
        <Button
          color="is-plain"
          size="is-small"
          aria-label="Download Remittance"
          tooltip="Download Remittance"
          icon-left="PDF"
          @click="downloadRemittance(row)"
        />
        <Button
          v-if="showDeleteButton(row)"
          color="is-plain"
          size="is-small"
          aria-label="Delete"
          icon-left="Trash"
          tooltip="Delete All Payments"
          @click="deletePayment(row)"
        />
        <Button
          v-if="showApprovePaymentButton(row)"
          color="is-plain"
          size="is-small"
          aria-label="Approve"
          :icon-left="{
            icon: `fa-check`,
            color: `is-plain`,
            style: `Solid`,
          }"
          tooltip="Approve Payment"
          @click="approvePayment(row)"
        />

        <Button
          v-if="showMarkAsPaidButton(row)"
          color="is-plain"
          size="is-small"
          icon-left="CreditCard"
          aria-label="Mark as Paid"
          tooltip="Mark as Paid"
          @click="paid(row)"
        />
      </div>
    </template>
  </MasterListPage>
</template>

<script lang="ts">
import { DownloadRemittanceForm } from "@/module/introducer.fees/form/download-remittance.form";
import { PatchIntroducerFeePaymentItemForm } from "@/module/introducer.fees/form/patch-introducer-fee-payment-item.form";
import { ReadIntroducerFeePaymentForm } from "@/module/introducer.fees/form/read-introducer-fee-payment.form";
import { selectOverrideEmailAddressForm } from "@/module/introducer.fees/form/select-override-email-address.form";
import { IntroducerFeePaymentRunDetailsMixin } from "@/module/introducer.fees/mixin/introducer-fee-payment-run-details.mixin";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Wizard } from "@kinherit/framework/component.display/wizard";
import { WizardConfig } from "@kinherit/framework/component.display/wizard/types";
import Button from "@kinherit/framework/component.input/button";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  OpenAlertDialog,
  OpenDangerDialog,
  OpenFormDialog,
} from "@kinherit/framework/global/dialog";
import { Between, Equal, Like } from "@kinherit/orm/index";
import {
  IIntroducerFeePayment,
  IntroducerFeePayment,
  IntroducerFeePaymentItem,
  Option,
} from "@kinherit/sdk";
import { Currency } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { IntroducerFeePaymentRunDetailsItemsRoute } from ".";
export default defineComponent({
  name: IntroducerFeePaymentRunDetailsItemsRoute,
  components: { MasterListPage, Button, Wizard },
  mixins: [AuthService.mixin(), IntroducerFeePaymentRunDetailsMixin],
  data: () => ({
    filters: ReadIntroducerFeePaymentForm(),
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v) =>
          `${v.xeroContact?.contactName} (${v.introducerFeePaymentItems.length})`,
        class: "has-text-weight-bold",
      },
      {
        title: "Total",
        mapHtml: (v) => {
          const total = v.introducerFeePaymentItems.reduce(
            (acc, item) => acc + item.amount.amount,
            0,
          );
          const totalFormatted = new Currency({ amount: total }).format;
          return `<span class="has-text-weight-bold">${totalFormatted}</span>`;
        },
      },
      {
        title: "Status",
        sort: true,
        slot: "status",
      },
      {
        title: "Completed At",
        sort: true,
        map: (v) => v.completedAt?.formatDate,
      },
      {
        title: "Created At",
        sort: true,
        map: (v) => v.createdAt.formatDate,
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn<IntroducerFeePayment>>,
    rows: Array<IntroducerFeePayment>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerFeePayment,
      direction: "asc" as "asc" | "desc",
    },
    wizardConfig: [
      { title: "Draft" },
      { title: "Awaiting Approval" },
      { title: "Process" },
      { title: "Completed" },
    ] as WizardConfig,
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("introducer-fee-payment-run:write");
    },
    showSubmitPaymentRun(): boolean {
      return (
        this.$data.introducerFeePaymentRun?.status.value === "draft" &&
        this.hasWritePermission &&
        this.rows.length > 0
      );
    },
    showApprovePaymentRun(): boolean {
      return (
        this.$data.introducerFeePaymentRun?.status.value ===
          "awaitingApproval" && this.hasWritePermission
      );
    },
    showPushToXeroPaymentRun(): boolean {
      return (
        this.$data.introducerFeePaymentRun?.status.value === "approved" &&
        this.hasWritePermission
      );
    },
    showSendRemittanceEmails(): boolean {
      return (
        this.$data.introducerFeePaymentRun?.status.value === "approved" &&
        this.hasWritePermission
      );
    },
    showCompletePaymentRun(): boolean {
      return (
        this.$data.introducerFeePaymentRun?.status.value === "approved" &&
        this.hasWritePermission
      );
    },
    wizardStepStatus(): number {
      if (!this.$data.introducerFeePaymentRun) {
        return 0;
      }

      switch (this.$data.introducerFeePaymentRun.status.value) {
        case "draft":
          return 0;
        case "awaitingApproval":
          return 1;
        case "approved":
          return 2;
        case "completed":
          return 3;
        default:
          return 0;
      }
    },
  },
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadIntroducerFeePaymentForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.introducerFees.payment.read({
      //   introducerFeePaymentRun: this.$params.introducerFeePaymentRun,
      //   ...formData,
      //   sort: this.sort,
      //   pagination: this.pagination,
      // });
      const data = await this.$actionBus.payment.ReadIntroducerFeePayment({
        query: {
          introducerFeePaymentRun: {
            id: Equal(this.$params.introducerFeePaymentRun),
          },
          introducerFeePaymentItems: {
            order: {
              kintin: {
                friendlyName: Like(formData.client),
              },
            },
          },
          xeroContact: {
            contactName: Like(formData.xeroContact),
          },
          createdAt: Between(formData.createdAt),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.introducerFeePayment;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    showDeleteButton(payment: IntroducerFeePayment): boolean {
      return (
        payment.status?.value === "awaitingApproval" &&
        ["draft", "awaitingApproval"].includes(
          this.$data.introducerFeePaymentRun?.status.value as string,
        )
      );
    },
    showMarkAsPaidButton(payment: IntroducerFeePayment): boolean {
      return payment.status?.value === "onXero";
    },
    showApprovePaymentButton(payment: IntroducerFeePayment): boolean {
      return payment.status?.value === "awaitingApproval";
    },
    showEditButton(payment: IntroducerFeePayment): boolean {
      return payment.status?.value === "awaitingApproval";
    },
    async deletePayment(payment: IntroducerFeePayment): Promise<void> {
      await OpenDangerDialog({
        dialog: {
          title: "Delete Payment",
          message: "Are you sure you want to delete this payment?",
        },
      });

      // await window.Kernel.ActionBus.introducerFees.payment.delete({
      await this.$actionBus.payment.DeleteIntroducerFeePayment(payment);

      await this.refresh(this.filters.localData);
    },
    async editPaymentItem(payment: IntroducerFeePaymentItem): Promise<void> {
      if (!payment) {
        return;
      }

      const introducerFeePaymentItem = await PatchIntroducerFeePaymentItemForm(
        payment,
      ).dialog({
        dialog: {
          title: "Edit Payment Item",
        },
      });

      // await window.Kernel.ActionBus.introducerFees.paymentItem.patch({
      //   introducerFeePaymentItem: introducerFeePaymentItem,
      // });
      await this.$actionBus.paymentItem.UpdateIntroducerFeePaymentItem(
        introducerFeePaymentItem,
      );

      payment.$persist();

      await this.refresh(this.filters.localData);
    },
    async deletePaymentItem(payment: IntroducerFeePaymentItem): Promise<void> {
      await OpenDangerDialog({
        dialog: {
          title: "Delete Payment Item",
          message: "Are you sure you want to delete this payment item?",
        },
      });

      // await window.Kernel.ActionBus.introducerFees.paymentItem.delete({
      //   introducerFeePaymentItem: payment,
      // });
      await this.$actionBus.paymentItem.DeleteIntroducerFeePaymentItem(payment);

      await this.refresh(this.filters.localData);
    },
    async downloadRemittance(payment: IntroducerFeePayment): Promise<void> {
      if (!this.$data.introducerFeePaymentRun) {
        return;
      }

      const { type } = await DownloadRemittanceForm(payment).dialog({
        dialog: {
          title: "Download Remittance",
          type: "alert",
        },
      });

      if (!type) {
        return;
      }

      // await window.Kernel.ActionBus.introducerFees.payment.downloadRemittance({
      await this.$actionBus.remittance.DownloadRemittance({
        downloadType: type,
        introducerFeePayment: payment,
        introducerFeePaymentRun: this.$data.introducerFeePaymentRun,
      });
    },
    async approvePayment(payment: IntroducerFeePayment): Promise<void> {
      await OpenDangerDialog({
        dialog: {
          title: "Approve Payment",
          message: "Are you sure you want to approve this payment?",
        },
      });

      payment.status = Option.$findOneByOrThrow({
        group: "introducerFeePaymentStatus",
        value: "approved",
      });
      payment.$persist();

      await this.$actionBus.payment.UpdateIntroducerFeePayment(payment);

      await this.refresh(this.filters.localData);
    },
    async approvePaymentRun(): Promise<void> {
      await OpenAlertDialog({
        dialog: {
          title: "Approve All",
          icon: "WarningTriangle",
          message:
            "Are you sure you want to approve all payments in this payment run? Once approved they will no longer be editable.",
        },
        button: {
          ok: {
            text: "Yes, Approve",
            color: "is-danger",
          },
          cancel: {
            text: "Cancel",
          },
        },
      });

      if (!this.$data.introducerFeePaymentRun) {
        return;
      }

      // await window.Kernel.ActionBus.introducerFees.paymentRun.approve({
      //   introducerFeePaymentRun: this.$params.introducerFeePaymentRun,
      // });
      await this.$actionBus.paymentRun.ApproveIntroducerFeePaymentRun(
        this.$data.introducerFeePaymentRun,
      );

      window.location.reload();
    },
    async submitPaymentRun(): Promise<void> {
      await OpenDangerDialog({
        dialog: {
          title: "Submit Payment Run",
          message:
            "Are you sure you want to submit this payment run for approval?",
        },
      });

      const paymentRun = this.$data.introducerFeePaymentRun;

      if (!paymentRun) {
        return;
      }

      paymentRun.status = Option.$findOneByOrThrow({
        group: "introducerFeePaymentRunStatus",
        value: "awaitingApproval",
      });
      paymentRun.$persist();

      await this.$actionBus.paymentRun.UpdateIntroducerFeePaymentRun(
        paymentRun,
      );

      window.location.reload();
    },
    async publishPaymentRun(): Promise<void> {
      await OpenDangerDialog({
        dialog: {
          title: "Publish Payment Run",
          message: "Are you sure you want to publish this payment run to Xero?",
        },
      });

      const introducerFeePaymentRun = this.$data.introducerFeePaymentRun;

      if (!introducerFeePaymentRun) {
        return;
      }

      await this.$actionBus.paymentRun.PublishIntroducerFeePaymentRun(
        introducerFeePaymentRun,
      );

      window.location.reload();
    },
    async paid(payment: IntroducerFeePayment): Promise<void> {
      await OpenDangerDialog({
        dialog: {
          title: "Mark as Paid",
          message: "Are you sure you want to mark this payment as paid?",
        },
      });

      payment.status = Option.$findOneByOrThrow({
        group: "introducerFeePaymentStatus",
        value: "paid",
      });
      payment.$persist();

      await this.$actionBus.payment.UpdateIntroducerFeePayment(payment);

      await this.refresh(this.filters.localData);
    },
    async completePaymentRun(): Promise<void> {
      await OpenDangerDialog({
        dialog: {
          title: "Complete Payment Run",
          message: "Are you sure you want to complete this payment run?",
        },
      });

      const paymentRun = this.$data.introducerFeePaymentRun;

      if (!paymentRun) {
        return;
      }

      paymentRun.status = Option.$findOneByOrThrow({
        group: "introducerFeePaymentRunStatus",
        value: "completed",
      });

      paymentRun.$persist();

      await this.$actionBus.paymentRun.UpdateIntroducerFeePaymentRun(
        paymentRun,
      );

      window.location.reload();
    },
    async sendEmail(): Promise<void> {
      const { email } = await OpenFormDialog({
        dialog: {
          title: "Send Introducer Remittance Email",
          message:
            "Are you sure you want to send the introducer remittance email?",
        },
        form: selectOverrideEmailAddressForm(),
      });

      const introducerFeePaymentRun = this.$data.introducerFeePaymentRun;

      if (!introducerFeePaymentRun) {
        return;
      }

      // await window.Kernel.ActionBus.introducerFees.paymentRun.send({
      await this.$actionBus.remittance.SendIntroducerRemittanceEmails({
        introducerFeePaymentRun: introducerFeePaymentRun,
        overrideEmailAddress: email,
      });
    },
  },
});
</script>
