<template>
  <div v-if="kintin">
    <CallScriptSection title="Pensions">
      <b>
        Right, so now we come to pensions – I don’t need to know the value here,
        just the type of pension you have. Do you have a personal one, or one
        you pay into through work? Or both?
      </b>
      <br /><br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="3.0.6b"
        :kintin="kintin"
        is-reversed
        :on="{
          yes: {
            say: [
              'There may be some things to check on these, but we’ll remind you about them later',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="3.0.6"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSection title="Policies">
      <b>Lastly on the personal stuff:</b>
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="3.0.5a"
        :kintin="kintin"
        is-reversed
        :on="{
          yes: {
            say: [
              '[As with the pensions] there may be some steps to check - don’t worry we’ll remind you about them later',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="3.0.5"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSection title="IFA">
      <Message v-if="referralContact" color="is-warning" class="mb-4">
        <strong>Important:</strong> user has been introduced by: <br />
        <strong>
          {{ referralContact?.profile?.fullName }} @
          {{
            referralCompany?.profile.organisationName ??
            referralCompany?.profile.fullName
          }}
        </strong>
      </Message>
      <em>[If Referrer is IFA, check yes and skip question:]</em><br />
      <b>
        {{
          kintinChecks.find((c) => c.step === "3.0.3b")?.note.name ??
          `Do you have an Independent Financial Adviser?`
        }}
      </b>
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="3.0.3b"
        :kintin="kintin"
        hide-field-label
        is-reversed
        :on="{
          yes: {
            say: [
              '[As with the pensions] there may be some steps to check - don’t worry we’ll remind you about them later',
            ],
          },
          no: {
            say: [
              'Would you like to have a free audit carried out of your financial planning? If so we can introduce you to one of our partners local to you.',
            ],
          },
        }"
        @update="updateCheckValue"
      />
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import Message from "@kinherit/framework/component.display/message";
import { IntroducerCompany, IntroducerContact } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: "PensionsTab",
  components: {
    CallScriptSection,
    ConfirmCheck,
    Message,
  },
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  computed: {
    referralContact(): IntroducerContact | null {
      return this.kintin?.referral?.contact ?? null;
    },
    referralCompany(): IntroducerCompany | null {
      return this.kintin?.referral?.referralCode?.company ?? null;
    },
  },
});
</script>
