<template>
  <div v-if="kintin" class="columns">
    <div class="column">
      <CallScriptSection title="UK Property">
        <b
          >Let's move on to your assets, and I’m particularly interested in how
          they are owned so we can understand your Inheritance Tax Situation.</b
        >
        <br /><br />
        <b> You said you live at: </b>
        <ul>
          <li
            v-for="address in primaryPerson.profile.addresses.filter(
              (a) => a.primaryResidential === true,
            )"
            :key="address.id"
          >
            {{ address.summary }}
          </li>
        </ul>
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.0a"
          :kintin="kintin"
          is-reversed
          @update="updateCheckValue"
        />
        <ConfirmCheck
          v-if="isJoint"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.0b"
          :kintin="kintin"
          is-reversed
          :yes-label="'Yes/Unsure'"
          @update="updateCheckValue"
        />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.0c"
          :kintin="kintin"
          is-reversed
          @update="updateCheckValue"
        />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.0d"
          :kintin="kintin"
          is-reversed
          @update="updateCheckValue"
        />

        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.2a"
          :kintin="kintin"
          is-reversed
          @update="updateCheckValue"
        />

        <mark
          >@Todo add checkbox: Have you done an equity release or could you see
          yourself doing one?</mark
        >
        <br />
        <br />
        <span v-if="checkIsYes('3.0.2a') || checkIsNo('3.0.2a')">
          <b>
            In rough numbers, do you have an approximate value of the property
            that you own?
          </b>
          <br /><em>[add property value] </em> <br /><br />
          <b
            >And are there mortgages? Again very rough number is OK as we know
            they go down</b
          >
          <br /><em
            >[TO DISCUSS – not including mortgages that are expected to be
            repaid]</em
          >
          <br /><br />
        </span>

        <PropertiesContainer class="mt-4" :kintin="kintin" />

        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.1"
          :kintin="kintin"
          @update="updateCheckValue"
        />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.2"
          :kintin="kintin"
          @update="updateCheckValue"
        />
      </CallScriptSection>
      <CallScriptSection title="Savings">
        <b>
          Moving on to your savings &amp; investments, do you have a rough
          number?
        </b>
        <br /><br />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.3"
          :kintin="kintin"
          @update="updateCheckValue"
        />
      </CallScriptSection>
      <CallScriptSection title="Foreign Assets">
        <b> And do you have any properties outside of the UK? </b><br /><br />
        <ConfirmCheck
          label="Has property outside the UK"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.2b"
          :kintin="kintin"
          is-reversed
          :on="{
            yes: {
              say: [
                'You may want to just check what happens to property in those countries.',
              ],
            },
          }"
          @update="updateCheckValue"
        />
        <br />
        <b>And any savings in foreign countries?</b>
        <br /><br />
        <ConfirmCheck
          label="Has savings or assets outside the UK"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.2c"
          :kintin="kintin"
          is-reversed
          :on="{
            yes: {
              say: [
                'You may want to just check what happens to property in those countries.',
              ],
            },
          }"
          @update="updateCheckValue"
        />
      </CallScriptSection>
      <CallScriptSection title="IFA">
        <Message v-if="referralContact" color="is-warning" class="mb-4">
          <strong>Important:</strong> user has been introduced by: <br />
          <strong>
            {{ referralContact?.profile?.fullName }} @
            {{
              referralCompany?.profile.organisationName ??
              referralCompany?.profile.fullName
            }}
          </strong>
        </Message>
        <ConfirmCheck
          label="Has a Financial Adviser"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.3b"
          :kintin="kintin"
          is-reversed
          @update="updateCheckValue"
        />

        <AdvisersContainer :kintin="kintin" />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.3d"
          :kintin="kintin"
          is-reversed
          @update="updateCheckValue"
        />
        <mark>question re confirming if there is a branded Kinvault?</mark>
      </CallScriptSection>
      <CallScriptSection title="Pensions">
        <b>
          On to pensions - do you have a personal one, pay through work, or
          both?
          <!-- Right, so now we come to pensions – I don’t need to know the value here,
        just the type of pension you have. Do you have a personal one, or one
        you pay into through work? Or both? -->
        </b>
        <br /><br />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.6b"
          :kintin="kintin"
          is-reversed
          :on="{
            yes: {
              say: [
                'It’s worth checking [with your IFA] if these can be directed to pay to trust or to your beneficiaries, and not into your estate. ',
              ],
            },
          }"
          @update="updateCheckValue"
        /><br />
        <b>
          We're expecting pensions to be considered as part of your estate from
          2027, but it hasn't happened yet. </b
        ><br /><br />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.6"
          :kintin="kintin"
          @update="updateCheckValue"
        />
      </CallScriptSection>
      <CallScriptSection title="Policies">
        <b>Lastly on the personal stuff:</b><br /><br />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.5a"
          :kintin="kintin"
          is-reversed
          :on="{
            yes: {
              say: [
                'It’s worth checking [with your IFA] these policies pay to trust or to your beneficiaries, and not into your estate which can be very tax efficient.',
              ],
            },
          }"
          @update="updateCheckValue"
        />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.5"
          :kintin="kintin"
          @update="updateCheckValue"
        />
      </CallScriptSection>
      <CallScriptSection title="Business Assets / Agricultural Assets">
        <b>
          So, just to finish on your assets, I need to check, do you have any
          business assets…?
        </b>
        <br /><br />
        <b>
          When I say business assets, this covers quite a lot of things - so an
          owned business, but also most unlisted shares, and even some
          qualifying listed shares and agricultural assets.
        </b>
        <br /><br />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.8a"
          :kintin="kintin"
          is-reversed
          @update="updateCheckValue"
        />
        <ConfirmCheck
          v-if="checkIsYes('3.0.8a')"
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.8b"
          :kintin="kintin"
          is-reversed
          :on="{
            yes: {
              say: [
                `You will need more planning to cover this, but some good news – the Will, trusts and business LPA can all be put through as a business expense ….  You’d still need to pay your personal LPAs yourself. `,
              ],
            },
          }"
          @update="updateCheckValue"
        />
        <ConfirmCheck
          :kintin-checks="kintinChecks"
          :checks="checks"
          step="3.0.8e"
          :kintin="kintin"
          is-reversed
          @update="updateCheckValue"
        />
        <br />
        <b v-if="checkIsYes('3.0.8a')">What do you think the rough value is?</b>
        <br /><mark
          >Add CHeck for assets outside the UK; Add breakdown of BR vs Agr
          Assets</mark
        ><br />
      </CallScriptSection>
      <CallScriptSection title="Other">
        <b
          >For your inheritance tax calculation, are you expecting to receive
          inheritance?</b
        >
      </CallScriptSection>
      <CallScriptSection title="Discuss IHT">
        <b>
          So as mentioned, how assets are owned can make a big difference with
          inheritance tax.
          <br />- Joint properties go to the other owner, regardless of what is
          in the Will. <br />- And bank accounts freeze when an account holder
          dies, which is really problematic if money is in joint accounts, or if
          the partner manages the finances.
        </b>
        <br /><br />
        <b>In terms of the overall value of your estate </b>
        <br /><em
          >[recap / gather info / discuss total amount … remember assets
          accumulate during employed pore retirement, and typically go down post
          ]</em
        >
        <br /><br />
        <b
          >What we are going to do is draft your Will so that it suits your
          situation today and has flexibility for future changes in your
          situation.
        </b>
      </CallScriptSection>
    </div>

    <div class="column is-narrow">
      <Card
        title="Estate Value Calculator"
        title-size="is-4"
        class="estate-value-calculator"
      >
        <Form
          v-if="$data.propertyTotalsSectionForm"
          :config="$data.propertyTotalsSectionForm"
          is-borderless
        />
        <Button
          text="Save"
          color="is-positive"
          is-fullwidth
          @click="saveAssetCalculatorValues"
        />
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import PropertiesContainer from "@/module/kinvault.kintin/component/address-book/PropertiesContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import Button from "@kinherit/framework/component.input/button";
import { Currency } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../mixin/kintin-details.mixin";

import AdvisersContainer from "@/module/kinvault.kintin/component/address-book/AdvisersContainer.vue";
import { PropertyTotalsSectionForm } from "@/module/kinvault.kintin/form/call-script.form/property-totals-section.form";
import { KintinDetailsCallScript2Call2AssetsRoute } from "@/module/kinvault.kintin/page/details/call-script-2";
import Message from "@kinherit/framework/component.display/message";
import Form from "@kinherit/framework/component.form/form";
import Card from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { IntroducerCompany, IntroducerContact } from "@kinherit/sdk";
export default defineComponent({
  name: KintinDetailsCallScript2Call2AssetsRoute,
  components: {
    CallScriptSection,
    ConfirmCheck,
    PropertiesContainer,
    Button,
    Message,
    Form,
    Card,
    AdvisersContainer,
  },
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  emits: ["save-callscript"],
  data: () => ({
    propertyTotalsSectionForm: null as null | FormBuilder<any, any>,
    values: {
      advSavingsValue: null as null | Currency,
      advPropsValue: null as null | Currency,
      advPolicyValue: null as null | Currency,
      advPensionValue: null as null | Currency,
      advOtherAssetValue: null as null | Currency,
      advBusinessAssetValue: null as null | Currency,
      advPotentialValue: null as null | Currency,
      advDebtValue: null as null | Currency,
      advHteStateValue: null as null | Currency,
    },
  }),
  computed: {
    referralContact(): IntroducerContact | null {
      return this.kintin?.referral?.contact ?? null;
    },
    referralCompany(): IntroducerCompany | null {
      return this.kintin?.referral?.referralCode?.company ?? null;
    },
    assetNetTotal(): number {
      return (
        (this.values.advSavingsValue?.amount ?? 0) +
        (this.values.advPropsValue?.amount ?? 0) +
        (this.values.advPolicyValue?.amount ?? 0) +
        (this.values.advPensionValue?.amount ?? 0) +
        (this.values.advOtherAssetValue?.amount ?? 0) +
        (this.values.advBusinessAssetValue?.amount ?? 0) +
        (this.values.advPotentialValue?.amount ?? 0) +
        (this.values.advDebtValue?.amount ?? 0)
      );
    },
  },
  mounted(): void {
    if (!this.kintin || !this.$auth.loggedInUser) {
      return;
    }
    const { form, updateAssetNetTotal } = PropertyTotalsSectionForm(
      this.values,
    );
    this.propertyTotalsSectionForm = form;
    this.$watch(
      () => this.assetNetTotal,
      () => {
        updateAssetNetTotal();
      },
    );

    this.values.advSavingsValue =
      this.kintin.callScript?.advSavingsValue ?? null;
    this.values.advPropsValue = this.kintin.callScript?.advPropsValue ?? null;
    this.values.advPolicyValue = this.kintin.callScript?.advPolicyValue ?? null;
    this.values.advPensionValue =
      this.kintin.callScript?.advPensionValue ?? null;
    this.values.advOtherAssetValue =
      this.kintin.callScript?.advOtherAssetValue ?? null;
    this.values.advBusinessAssetValue =
      this.kintin.callScript?.advBusinessAssetValue ?? null;
    this.values.advPotentialValue =
      this.kintin.callScript?.advPotentialValue ?? null;
    this.values.advDebtValue = this.kintin.callScript?.advDebtValue ?? null;
    this.values.advHteStateValue =
      this.kintin.callScript?.advHteStateValue ?? null;
  },
  methods: {
    async saveAssetCalculatorValues() {
      if (!this.kintin) {
        return;
      }

      this.kintin.callScript.advSavingsValue = this.values.advSavingsValue;
      this.kintin.callScript.advPropsValue = this.values.advPropsValue;
      this.kintin.callScript.advPolicyValue = this.values.advPolicyValue;
      this.kintin.callScript.advPensionValue = this.values.advPensionValue;
      this.kintin.callScript.advOtherAssetValue =
        this.values.advOtherAssetValue;
      this.kintin.callScript.advBusinessAssetValue =
        this.values.advBusinessAssetValue;
      this.kintin.callScript.advPotentialValue = this.values.advPotentialValue;
      this.kintin.callScript.advDebtValue = this.values.advDebtValue;
      this.kintin.callScript.advHteStateValue = this.values.advHteStateValue;
      this.$emit("save-callscript");
    },
  },
});
</script>

<style lang="scss">
.estate-value-calculator {
  position: sticky;
  height: fit-content;
  top: 5rem;
}
</style>
