import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { DeleteEmailTemplate } from "./delete-email-template.action";
import { RecordEmailTemplate } from "./record-email-template.action";
import { UpdateEmailTemplate } from "./update-email-template.action";

export const Portal2AdminEmailTemplate = DefineActionBus({
  name: "portal-admin-email-template",
  actions: {
    DeleteEmailTemplate,
    UpdateEmailTemplate,
    RecordEmailTemplate,
  },
});
