<template>
  <div v-if="kintin">
    <CallScriptSection title="Right to Reside and Ownership complexity">
      <p v-if="checkIsYes('3.0.0d')">
        [Note]: Someone else lives in the main property
      </p>
      <p v-if="checkIsNo('3.0.0d')">
        [Note]: No one else lives in the main property
      </p>
      <p v-if="checkIsYes('2.5.0b')">
        [Note]: Has children from prior relationship.
      </p>
      <p v-if="checkIsNo('2.5.0b')">
        [Note]: No children from prior relationships
      </p>
      <br />
      <b
        >One of the more common things we're seeing in wills is a Right to
        Reside Clause.</b
      >
      <b v-if="checkIsYes('3.0.0d') || checkIsYes('2.5.0b')">
        You have mentioned someone is living at your home/you have children from
        a previous relationship. If you were to die, would you want them to stay
        in the family home?
      </b>
      <br />
      <br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        is-reversed
        step="4.3.1a"
        :on="{
          yes: {
            action: [
              'Add Note detailing how R2R clause might work (eg live there for X years / get £Y or Z% of property value if/when its sold)',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <b v-if="checkIsNo('4.3.1a')">
        <br />
        <br />
        It sounds like this isn't relevant, so we can probably skip this - but
        do shout if there's anything we've missed.
      </b>
      <span v-if="checkIsYes('4.3.1a')">
        <b>
          So just to confirm, a right to reside clause can be used to allow a
          non-beneficiary to stay in a property after the owner dies.
          <br /><br />
          It needs a trust but can be really quite flexible – depending how long
          you want it to exist, and maybe allow things like downsizing, and with
          a conditions like the payment of bills.
          <br /><br />
          So thinking about your situation – how would you like it to work?
        </b>
        <br /><em
          >[Discuss Situation and add notes] <br />[Legacy Requires:]
          <br /><br />A. Is the property the client's main residence <br />B.
          Who is the tenant <br />C. Is it a <br />
          - a. Right for life [DEFAULT] <br />
          - b. or defined years <br />
          - c. or until specified age [NB this seems a weird one as don't know
          when testator will die] <br />D. Should tenant be allowed to
          move/downsize <br />
          - a. If Yes – how does excess get treated? Ie for use by tenant or
          tobeneficiaries per will [DEFAULT=YES] <br />E. Does right end if
          cohabiting or remarry? [DEFAULT NO] <br />F. When right ends, is
          property to be treated <br />
          - a. as per original Will [DEFAULT] <br />
          - b. go to newly specified people</em
        >
        <br /><br />
      </span>
      <b v-if="checkIsYes('3.0.0c') && checkIsYes('4.3.1a')">
        Regarding the co-owners of your home, have you thought about what might
        happen?
      </b>
      <b v-if="!checkIsYes('3.0.0c') && checkIsYes('4.3.1a')">
        And just to confirm, you said there were no other owners.
      </b>
      <ConfirmCheck
        v-if="checkIsYes('4.3.1a')"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        is-reversed
        step="4.3.1d"
        :on="{
          yes: {
            action: [
              'Explain they need to think It through and get comfortable with the risks. Add notes',
            ],
          },
        }"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSpecialistNotesSection
      v-model:value="callScript.advR2RNotes"
      :kintin="kintin"
      :has-continue="false"
      @save-continue="saveAndContinueHandler"
    />
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "R2RTab",
  components: {
    CallScriptSection,
    ConfirmCheck,
    CallScriptSpecialistNotesSection,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  emits: ["save-and-continue"],
  methods: {
    async saveAndContinueHandler() {
      this.$emit("save-and-continue");
    },
  },
});
</script>
