var g = Object.defineProperty;
var v = (e, n) => g(e, "name", { value: n, configurable: !0 });
import { _ as f } from "../../../component.display/date-range-process/date-range-process.vue_vue_type_script_lang.js";
import { resolveComponent as a, createElementBlock as m, openBlock as s, createElementVNode as l, createCommentVNode as r, createVNode as i, createBlock as u } from "vue";
import { _ as b } from "../../../_plugins/_plugin-vue_export-helper.js";
const k = { class: "date-range-process" }, I = { class: "columns" }, R = { class: "column" }, $ = { class: "column" }, C = { class: "column" }, y = { class: "columns" }, B = { class: "column" }, D = { class: "buttons is-right" }, N = {
  key: 0,
  class: "columns"
}, h = { class: "column" };
function F(e, n, V, q, O, P) {
  const d = a("DateField"), c = a("NumberField"), o = a("Button"), p = a("Progress");
  return s(), m("div", k, [
    l("div", I, [
      l("div", R, [
        i(d, {
          value: e.form.dateRange.start,
          "onUpdate:value": n[0] || (n[0] = (t) => e.form.dateRange.start = t),
          validators: ["required"],
          label: e.$t.local.startDate,
          state: e.isRunning || e.currentInterval !== 0 ? "disabled" : void 0
        }, null, 8, ["value", "label", "state"])
      ]),
      l("div", $, [
        i(d, {
          value: e.form.dateRange.end,
          "onUpdate:value": n[1] || (n[1] = (t) => e.form.dateRange.end = t),
          validators: ["required"],
          label: e.$t.local.endDate,
          state: e.isRunning || e.currentInterval !== 0 ? "disabled" : void 0
        }, null, 8, ["value", "label", "state"])
      ]),
      l("div", C, [
        i(c, {
          value: e.form.interval,
          "onUpdate:value": n[2] || (n[2] = (t) => e.form.interval = t),
          validators: ["required"],
          label: e.$t.local.interval,
          min: 1,
          state: e.isRunning || e.currentInterval !== 0 ? "disabled" : void 0
        }, null, 8, ["value", "label", "state"])
      ])
    ]),
    l("div", y, [
      l("div", B, [
        l("div", D, [
          e.isRunning ? (s(), u(o, {
            key: 0,
            text: e.$t.local.pause,
            onClick: e.stop
          }, null, 8, ["text", "onClick"])) : r("", !0),
          !e.isRunning && e.currentInterval === 0 ? (s(), u(o, {
            key: 1,
            "is-disabled": !e.isValid,
            text: e.form.interval === null ? e.$t.local.run() : e.$t.local.run(e.currentInterval, e.numberOfIntervals),
            onClick: e.start
          }, null, 8, ["is-disabled", "text", "onClick"])) : r("", !0),
          !e.isRunning && e.currentInterval > 0 && e.currentInterval < e.numberOfIntervals ? (s(), u(o, {
            key: 2,
            text: e.$t.local.resume,
            onClick: e.start
          }, null, 8, ["text", "onClick"])) : r("", !0),
          !e.isRunning && e.currentInterval > 0 ? (s(), u(o, {
            key: 3,
            text: e.$t.local.reset,
            onClick: n[3] || (n[3] = (t) => e.currentInterval = 0)
          }, null, 8, ["text"])) : r("", !0)
        ])
      ])
    ]),
    e.isRunning || e.currentInterval !== 0 ? (s(), m("div", N, [
      l("div", h, [
        i(p, {
          max: e.numberOfIntervals,
          progress: e.currentInterval
        }, null, 8, ["max", "progress"])
      ])
    ])) : r("", !0)
  ]);
}
v(F, "_sfc_render");
const z = /* @__PURE__ */ b(f, [["render", F]]);
export {
  z as default
};
