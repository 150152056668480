import { Api, Kintin, SignDoc } from "@kinherit/sdk";

export interface PreviewKintinSignDocDisclaimerMessage {
  signDoc: SignDoc;
  kintin: Kintin | string;
}

export interface PreviewKintinSignDocDisclaimerResponse {
  [key: string]: never;
}

export const PreviewDisclaimer = async (
  message: PreviewKintinSignDocDisclaimerMessage,
): Promise<PreviewKintinSignDocDisclaimerResponse> => {
  const response = (await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/sign-doc/{kintin}/sign-docs/{signDoc}/preview",
    {
      kintin:
        "string" === typeof message.kintin ? message.kintin : message.kintin.id,
      signDoc: message.signDoc.id,
    },
  )
    .method("get")
    .result()) as never as string;

  const win = window.open("", "_blank");
  if (win) {
    win.document.write(response);
  }

  return {};
};
