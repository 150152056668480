var r = Object.defineProperty;
var o = (e, t) => r(e, "name", { value: t, configurable: !0 });
import n from "../../_vue/component.display/icon/icon.js";
import m from "../../_vue/component.form/field/field.js";
import { FieldMixin as l } from "../../component.mixin/field-mixin.js";
import { CypressHelper as a } from "../../cypress/component-helpers.js";
import { ThemeDirection as i } from "../../theme/prop/direction.js";
import { Uuid as p } from "@kinherit/ts-common";
import { defineComponent as s } from "vue";
import { S as d } from "../../_assets/component.input/radio-field/radio-field.module.js";
const x = s({
  name: "RadioFieldComponent",
  components: { Icon: n, Field: m },
  mixins: [l()],
  props: {
    options: {
      type: [Array, Object],
      required: !0
    },
    direction: {
      type: String,
      default: i.isHorizontal,
      validator: /* @__PURE__ */ o((e) => Object.values(i).includes(e), "validator"),
      value: i
    },
    isButton: {
      type: Boolean,
      default: !1
    }
  },
  emits: [
    /**
     * Emits whether or not the input value has passed its validators
     *
     * @property {boolean}
     */
    "is-valid",
    /**
     * When the input field in blured
     */
    "blur"
  ],
  data: /* @__PURE__ */ o((e) => ({
    $cypress: a({ ctx: e, labelField: "label" }),
    name: p.generate()
  }), "data"),
  beforeMount() {
    window.Kernel.injectStylesheet("radio-field", d);
  }
});
export {
  x as _
};
