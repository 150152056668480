var _ = Object.defineProperty;
var a = (e, r) => _(e, "name", { value: r, configurable: !0 });
import { _ as i } from "../../../component.misc/router-child-view/router-child-view.vue_vue_type_script_lang.js";
import { resolveComponent as c, createBlock as o, openBlock as t, withCtx as u, renderSlot as d, resolveDynamicComponent as m } from "vue";
import { _ as f } from "../../../_plugins/_plugin-vue_export-helper.js";
function $(e, r, h, k, v, y) {
  const l = c("PageNotFound"), p = c("router-view");
  return t(), o(p, null, {
    default: u(({ Component: n, route: s }) => [
      n ? (t(), o(m(n), {
        key: s.meta.usePathKey ? s.path : void 0
      })) : e.$slots.default ? d(e.$slots, "default", { key: 1 }) : (t(), o(l, { key: 2 }))
    ]),
    _: 3
  });
}
a($, "_sfc_render");
const x = /* @__PURE__ */ f(i, [["render", $]]);
export {
  x as default
};
