import { Api } from "@kinherit/sdk/api";
import { Order, Person } from "@kinherit/sdk";

//
export const SendOrderPaymentEmail = async (message: {
  kintin: string;
  order: string | Order;
  person: string | Person;
}) => {
  await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/order/order/{order}/send-order-payment-email/{person}",
    {
      kintin: message.kintin,
      order: message.order,
      person: message.person,
    },
  )
    .method("put")
    .result();

  return {};
};
