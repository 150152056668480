import { DownloadNoteFile } from "@/action-bus/core/note/download-note-file.action";
import { UploadNoteFile } from "@/action-bus/core/note/upload-note-file.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateNoteHandler } from "./create-note.action";
import { DeleteNoteHandler } from "./delete-note.action";
import { ReadNoteHandler } from "./read-note.action";
import { RecordNoteHandler } from "./record-note.action";
import { UpdateNoteHandler } from "./update-note.action";
import { DeleteNoteFile } from "@/action-bus/core/note/delete-note-file.action";

export const CoreNote = DefineActionBus({
  name: "core-note",
  actions: {
    CreateNote: CreateNoteHandler,
    DeleteNote: DeleteNoteHandler,
    ReadNote: ReadNoteHandler,
    RecordNote: RecordNoteHandler,
    UpdateNote: UpdateNoteHandler,
    UploadNoteFile: UploadNoteFile,
    DownloadNoteFile: DownloadNoteFile,
    DeleteNoteFile: DeleteNoteFile,
  },
});
