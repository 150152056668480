var s = Object.defineProperty;
var n = (e, t) => s(e, "name", { value: t, configurable: !0 });
import i from "../../_vue/component.display/icon/icon.js";
import { defineComponent as u } from "vue";
import { S as p } from "../../_assets/component.layout/breadcrumbs/breadcrumb.module.js";
const l = u({
  name: "BreadcrumbsComponent",
  components: { Icon: i },
  props: {
    items: {
      type: Array,
      default: /* @__PURE__ */ n(() => [], "default")
    }
  },
  computed: {
    computedItems() {
      var e, t, o, r, m;
      return !this.items.isEmpty() || !this.$route ? this.items : ((m = (r = (o = (t = (e = window.Kernel.Router) == null ? void 0 : e.currentRoute) == null ? void 0 : t.value) == null ? void 0 : o.meta) == null ? void 0 : r.breadcrumbs) == null ? void 0 : m.call(r, this.$route.params)) ?? [];
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("breadcrumb", p);
  },
  methods: {
    open(e, t) {
      window.Kernel.visitRoute(t, e.ctrlKey);
    }
  }
});
export {
  l as _
};
