<template>
  <span>
    <strong>Distribution of the Residue</strong>
    <p>
      SUBJECT to the trusts DECLARED above my Executors SHALL HOLD my Estate as
      follows:
    </p>
    <Distribution />
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";
import Distribution from "../shared/Distribution.vue";

export default defineComponent({
  components: { Distribution },
  mixins: [WillBuilderService.mixin],
});
</script>
