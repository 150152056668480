import { EmailAddressField, EmailTemplateField } from "@/config/form.config";
import MarkDownEditor from "@/module/core/component/markdown-editor/MarkDownEditor.vue";
import { AuthService } from "@/service/auth.service";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormDateTimeField } from "@kinherit/framework/component.input/date-time-field";
import { FormFileField } from "@kinherit/framework/component.input/file-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import { FormComponentWrapper } from "@kinherit/framework/form-builder/core/component-wrapper";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, JsonIn } from "@kinherit/orm";
import {
  EmailAddress,
  EmailCampaignLog,
  EmailTemplate,
  FileLog,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";

type Data = {
  emailCampaignLog: EmailCampaignLog;
  files: Array<File>;
  requiredFiles: Record<string, File>;
};

export const UpdateEmailCampaignLogForm = ({
  emailCampaignLog,
  isNew,
}: {
  emailCampaignLog: EmailCampaignLog;
  isNew: boolean;
}) => {
  const user = AuthService.loggedInUser;

  const templateGroups = [
    "kinvault",
    "officer",
    "leads",
    "officer",
    "introducers",
    "trustreg",
    "organisation",
  ];

  const localData = {
    leadfirstname: null as null | string,
    estateplanner: null as null | string,
    outreach: null as null | string,
    recipients: null as null | string,
    aboutkinherit: null as null | string,
    trname: null as null | string,
    outreachnumber: null as null | string,
    introducername: null as null | string,
    introducercompany: null as null | string,
    officername: null as null | string,
    introducerfirstname: null as null | string,
    ktref: null as null | string,
    leadname: null as null | string,
    testatorfullname: null as null | string,
    yourOrName: null as null | string,
    trref: null as null | string,
    trpin: null as null | string,
    primaryfirstname: null as null | string,
    currentuser: user?.profile.fullName ?? null,
    estateplannerfirstname: null as null | string,
    kinvaultloginurl: null as null | string,
  };

  if (isNew) {
    emailCampaignLog.data = localData;
  }

  return defineForm({
    name: "create-email-campaign-log-form",
    props: {
      state: emailCampaignLog.canEdit ? undefined : "disabled",
    },
    data: () =>
      ({
        emailCampaignLog: emailCampaignLog as EmailCampaignLog,
        files: Array<File>(),
        requiredFiles: {} as Record<string, File>,
      }) as Data,
    formAreas: (data) => [
      defineFormArea({
        name: "create-email-campaign-log-form-area",
        data,
        template: GridLayout([
          ["from", "status"],
          ["template", "scheduledToSendAt"],
          {
            is: "hr",
          },
          ["attachments"],
          ["body"],
        ]),
        components: (data) => ({
          from: [
            EmailAddressField({
              props: {
                label: "From",
                validators: ["required"],
                isMultiSelect: false,
                forceDialog: false,
                reference: "emailAddress",
                vModel: {
                  get: (data) => data.emailCampaignLog.from,
                  set: (value: EmailAddress | null, data, controls) => {
                    data.emailCampaignLog.from = value as EmailAddress;

                    controls.incrementFormRenderKey();
                  },
                },
              },
              query: {
                profile: {
                  user: {
                    displayUserInPortal: Equal(true),
                  },
                },
              },
            }),
          ],
          status: [
            FormSelectField({
              props: {
                label: "Status",
                options: {
                  draft: "Draft",
                  queued: "Queued",
                },
                validators: ["required"],
              },
              models: {
                value: "emailCampaignLog.status",
              },
            }),
          ],
          scheduledToSendAt: [
            FormDateTimeField({
              props: {
                label: "Scheduled",
                message:
                  "Set a time and date for when this email should be sent",
                validators: ["required"],
              },
              models: {
                value: "emailCampaignLog.scheduledToSendAt",
              },
              slots: {
                right: [
                  FormButton({
                    props: {
                      iconRight: "Clock",
                      ariaLabel: "Now",
                      reference: "scheduleNow",
                    },
                    emits: {
                      click: (_, data, controls) => {
                        data.emailCampaignLog.scheduledToSendAt =
                          new DateTime();
                        controls.rebuildForm();
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          template: [
            EmailTemplateField({
              props: {
                label: "Template",
                validators: ["required"],
                reference: "template",
                vModel: {
                  get: (data) => data.emailCampaignLog.template,
                  set: (value: EmailTemplate, data, controls) => {
                    data.emailCampaignLog.template = value;

                    data.emailCampaignLog.subject = null;
                    data.emailCampaignLog.title = value.name;
                    data.emailCampaignLog.body = null;

                    data.emailCampaignLog.attachments.forEach((attachment) =>
                      data.emailCampaignLog.removeAttachments(attachment),
                    );

                    controls.rebuildForm();
                    controls.incrementFormRenderKey();
                  },
                },
              },
              query: {
                type: {
                  data: JsonIn({
                    search: templateGroups,
                    path: "module",
                  }),
                },
              },
              limit: null,
            }),
          ],
          attachments: [
            FormFileField({
              props: {
                vIf: (data) => null !== data.emailCampaignLog.template,
                placeholder: `${
                  data.emailCampaignLog.template?.requiredAttachments.length !==
                  0
                    ? "Optional: Additional"
                    : "Add"
                } Attachments`,
                isCompact: true,
                reference: "attachments",
              },
              models: {
                value: {
                  get: (data) => {
                    return [
                      ...emailCampaignLog.attachments.map((attachment) =>
                        attachment.toBrowserFile(),
                      ),
                      ...data.files,
                    ];
                  },
                  set: async (value: Array<File>, data) => {
                    const missing = Array<FileLog>();
                    const providedFiles = Array<FileLog>();

                    for (const file of value) {
                      const fileLog = await FileLog.fromBrowserFile(file);

                      if (fileLog) {
                        providedFiles.push(fileLog);
                      }
                    }

                    for (const fileLog of data.emailCampaignLog.attachments) {
                      if (!providedFiles.pluck("id").includes(fileLog.id)) {
                        missing.push(fileLog);
                      }
                    }

                    missing.forEach((fileLog) =>
                      data.emailCampaignLog.removeAttachments(fileLog),
                    );

                    data.files = await value.filterAsync(
                      async (file) =>
                        (await FileLog.fromBrowserFile(file)) === null,
                    );
                  },
                },
              },
            }),
          ],
          body: [
            FormComponentWrapper(MarkDownEditor)({
              props: {
                vIf: (data) => !!data?.emailCampaignLog.template,
                subjectTemplate: (data: Data) =>
                  data.emailCampaignLog.subject ??
                  data.emailCampaignLog.template?.subject,
                bodyTemplate: (data: Data) =>
                  data.emailCampaignLog.body ??
                  data.emailCampaignLog.template?.body,
                params: () => localData,
              },
              models: {
                title: "emailCampaignLog.subject",
                body: "emailCampaignLog.body",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
