import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateProperty } from "./create-property.action";
import { DeleteProperty } from "./delete-property.action";
import { ReadProperty } from "./read-property.action";
import { RecordProperty } from "./record-property.action";
import { UpdateProperty } from "./update-property.action";

export const Portal2KinvaultProperty = DefineActionBus({
  name: "portal-kinvault-property",
  actions: {
    CreateProperty,
    DeleteProperty,
    ReadProperty,
    UpdateProperty,
    RecordProperty,
  },
});
