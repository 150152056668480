import { Api } from "@/service/api.service";
import {
  CommunicationNote,
  FileLog,
  IntroducerCompany,
  IntroducerContact,
  IntroducerNote,
  IntroducerOutsource,
  Kintin,
  Lead,
  Note,
  Person,
} from "@kinherit/sdk";

type UpdateNoteMessage = {
  note: Note;
  files: Array<File>;
} & (
  | {
      kintin: Kintin | string;
    }
  | {
      lead: Lead | string;
    }
  | {
      introducerCompany: IntroducerCompany | string;
    }
  | {
      introducerContact: IntroducerContact | string;
    }
  | {
      person: string | Person;
    }
  | {
      introducerOutsource: string | IntroducerOutsource;
    }
);

type Controllers =
  | "/v2/portal/kinvault/{owner}/notes/{note}"
  | "/v2/portal/lead/{owner}/notes/{note}"
  | "/v2/portal/introducer/company/note/{owner}/{note}"
  | "/v2/portal/introducer/contact/note/{owner}/{note}"
  | "/v2/portal/officer/{owner}/notes/{note}"
  | "/v2/portal/introducer/outsources/note/{owner}/{note}";

interface UpdateNoteResponse {
  notes: Array<Note>;
  communicationNotes: Array<CommunicationNote>;
  introducerNotes: Array<IntroducerNote>;
}

export const UpdateNoteHandler = async (
  message: UpdateNoteMessage,
): Promise<UpdateNoteResponse> => {
  let path = "" as Controllers;
  const params = {
    note: message.note,
  } as any;

  if ("kintin" in message && message.kintin) {
    path = "/v2/portal/kinvault/{owner}/notes/{note}";
    params.owner = message.kintin;
  } else if ("lead" in message && message.lead) {
    path = "/v2/portal/lead/{owner}/notes/{note}";
    params.owner = message.lead;
  } else if ("introducerCompany" in message && message.introducerCompany) {
    path = "/v2/portal/introducer/company/note/{owner}/{note}";
    params.owner = message.introducerCompany;
  } else if ("introducerContact" in message && message.introducerContact) {
    path = "/v2/portal/introducer/contact/note/{owner}/{note}";
    params.owner = message.introducerContact;
  } else if ("person" in message && message.person) {
    path = "/v2/portal/officer/{owner}/notes/{note}";
    params.owner = message.person;
  } else if ("introducerOutsource" in message && message.introducerOutsource) {
    path = "/v2/portal/introducer/outsources/note/{owner}/{note}";
    params.owner = message.introducerOutsource;
  }

  const response = await Api.resource("portal", path as Controllers, params)
    .method("patch")
    .body({
      notes: message.note.$data.notes,
      dueAt: message.note.$data.dueAt,
      name: message.note.$data.name,
      pinned: message.note.$data.pinned,
      isPublic: message.note.$data.isPublic,
      completedAt: message.note.$data.completedAt,
      type: message.note.$data.type,
      lead: message.note.$data.lead,
      person: message.note.$data.person,
      communicationNote: message.note.communicationNote
        ? {
            communicationType:
              message.note.communicationNote.$data.communicationType,
            reference: message.note.communicationNote.$data.reference,
            outcome: message.note.communicationNote.$data.outcome,
          }
        : undefined,
      company: message.note.$data.company,
      contact: message.note.$data.contact,
      kintin: message.note.$data.kintin,
      introducerNote: message.note.introducerNote
        ? {
            dueAtNotifySales:
              message.note.introducerNote.$data.dueAtNotifySales,
            dueAtNotifySpecialist:
              message.note.introducerNote.$data.dueAtNotifySpecialist,
          }
        : undefined,
    })

    .result();

  const newFiles = message.files.filter((file) => {
    return !FileLog.isFromFileLog(file);
  });

  const keepFileLogs = await message.files
    .filter((file) => {
      return FileLog.isFromFileLog(file);
    })
    .mapAsync(async (file) => {
      const fileLog = await FileLog.fromBrowserFile(file);

      if (!fileLog) {
        throw new Error("Failed to find file log");
      }

      return fileLog;
    });

  const deletedFileLogs = message.note.files.filter((file) => {
    return !keepFileLogs.pluck("id").includes(file.id);
  });

  console.log({
    newFiles,
    keepFileLogs,
    deletedFileLogs,
  });

  const note = Note.$inflate(response.note).first("Failed to update note");

  for (const file of newFiles) {
    await Api.resource("portal", `${path as Controllers}/file/upload`, {
      note,
      owner: params.owner,
    })
      .method("put")
      .files({
        uploadedFile: file,
      })

      .result();
  }

  for (const file of deletedFileLogs) {
    await Api.resource("portal", `${path as Controllers}/file/{fileLog}`, {
      note,
      owner: params.owner,
      fileLog: file,
    })
      .method("delete")
      .result();
    file.$delete();
  }

  return {
    notes: Note.$inflate(response.note),
    communicationNotes: CommunicationNote.$inflate(response.communicationNote),
    introducerNotes: IntroducerNote.$inflate(response.introducerNote),
  };
};
