import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerFeePayment } from "./create-introducer-fee-payment.action";
import { DeleteIntroducerFeePayment } from "./delete-introducer-fee-payment.action";
import { ReadIntroducerFeePayment } from "./read-introducer-fee-payment.action";
import { RecordIntroducerFeePayment } from "./record-introducer-fee-payment.action";
import { UpdateIntroducerFeePayment } from "./update-introducer-fee-payment.action";

export const Portal2IntroducerFeesPayment = DefineActionBus({
  name: "portal-introducer-fees-payment",
  actions: {
    DeleteIntroducerFeePayment,
    CreateIntroducerFeePayment,
    UpdateIntroducerFeePayment,
    RecordIntroducerFeePayment,
    ReadIntroducerFeePayment,
  },
});
