<template>
  <div v-if="!isLoading" class="email-log-page">
    <PageHeader htag="h2" text="Emails">
      <template #buttons>
        <Button class="create" @click="handleCreateEmailClick">
          Create Email
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="type === 'emailLog'"
      v-model:current-page="emailLog.pagination.currentPage"
      v-model:per-page="emailLog.pagination.perPage"
      v-model:sort-by="emailLog.sort.by"
      v-model:sort-direction="emailLog.sort.direction"
      :persist-state="false"
      :columns="emailLog.columns"
      :rows="emailLog.rows"
      is-nested
      :count="emailLog.pagination.count"
      :last-page="emailLog.pagination.lastPage"
      subtitle="Email Log"
      :sort-by-options="{
        createdAt: 'Created',
      }"
      :empty-message="`No emails found`"
      @refresh="refreshEmailLog"
      @row-clicked="handleEmailLogClick"
    >
      <template #buttons>
        <SelectField
          v-model:value="endpoint"
          :options="{
            'filter-by-email-address': 'Filter by Email Address',
            'filter-by-owner': 'Filter by Owner',
          }"
          size="is-small"
          :show-clear-button="false"
          @update:value="refreshEmailLog"
        />
        <Button v-if="!noLegacyEmails" size="is-small" @click="changeType">
          Legacy Email Log
        </Button>
      </template>
    </MasterListPage>
    <MasterListPage
      v-else
      v-model:current-page="legacyEmailLog.pagination.currentPage"
      v-model:per-page="legacyEmailLog.pagination.perPage"
      v-model:sort-by="legacyEmailLog.sort.by"
      v-model:sort-direction="legacyEmailLog.sort.direction"
      :persist-state="false"
      :columns="legacyEmailLog.columns"
      :rows="legacyEmailLog.rows"
      is-nested
      :count="legacyEmailLog.pagination.count"
      :last-page="legacyEmailLog.pagination.lastPage"
      subtitle="Legacy Email Log"
      :sort-by-options="{
        createdAt: 'Created',
      }"
      @refresh="refreshLegacyEmailLog"
      @row-clicked="handleLegacyEmailLogClick"
    >
      <template #buttons>
        <Button size="is-small" @click="changeType"> Email Log </Button>
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "EmailLogPageWrapper",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "imports": {
    "UpdateEmailLogFormWrapper": "@/module/core/forms/update-email-log.form.test"
  },
  "methods": {
    "createEmail": {
      "type": "click",
      "selector": ".create"
    },
    "createEmailForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateEmailLogFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { CreateEmailLog } from "@/config/model.config";
import {
  EmailLogTableColumns,
  LegacyEmailLogTableColumns,
} from "@/config/table-columns.config";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { Button } from "@kinherit/framework/component.input/button";
import SelectField from "@kinherit/framework/component.input/select-field";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import {
  BrandedKinvault,
  EmailAddress,
  EmailLog,
  IEmailLog,
  ILegacyEmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  LegacyEmailLog,
  Person,
} from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { UpdateEmailLogForm } from "../forms/update-email-log.form";
import { UpdateLegacyEmailLogForm } from "../forms/update-legacy-email-log.form";

export default defineComponent({
  name: "EmailLogPage",
  components: {
    MasterListPage,
    Button,
    PageHeader,
    SelectField,
  },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.core.emailLog),
  ],
  props: {
    owner: {
      type: Object as PropType<
        | IntroducerCompany
        | IntroducerContact
        | Kintin
        | Lead
        | Person
        | BrandedKinvault
      >,
      required: true,
    },
    noLegacyEmails: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    type: "emailLog" as "emailLog" | "legacyEmailLog",
    endpoint: "filter-by-owner" as
      | "filter-by-email-address"
      | "filter-by-owner",
    emailAddresses: Array<EmailAddress>(),
    emailLog: {
      columns: EmailLogTableColumns,
      rows: Array<EmailLog>(),
      pagination: {
        currentPage: 1,
        lastPage: 0,
        perPage: 15,
        count: 0,
      },
      sort: {
        by: "createdAt" as keyof IEmailLog,
        direction: "desc" as "desc" | "asc",
      },
    },
    legacyEmailLog: {
      columns: LegacyEmailLogTableColumns,
      rows: Array<LegacyEmailLog>(),
      pagination: {
        currentPage: 1,
        lastPage: 0,
        perPage: 15,
        count: 0,
      },
      sort: {
        by: "createdAt" as keyof ILegacyEmailLog,
        direction: "desc" as "desc" | "asc",
      },
    },
    isLoading: true,
  }),
  async mounted(): Promise<void> {
    const { emailAddresses } = await this.$actionBus.ReadEmailLogEmailAddress({
      owner: this.owner,
    });

    this.emailAddresses = emailAddresses;

    this.isLoading = false;
  },
  methods: {
    async refreshEmailLog(): Promise<void> {
      const { emailLogs, pagination } = await this.$actionBus.ReadEmailLog({
        owner: this.owner,
        sort: this.emailLog.sort,
        pagination: this.emailLog.pagination,
        endpoint: this.endpoint,
      });

      this.emailLog.rows = emailLogs;
      this.emailLog.pagination.currentPage = pagination.currentPage;
      this.emailLog.pagination.lastPage = pagination.lastPage;
      this.emailLog.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    async handleEmailLogClick(emailLog: EmailLog): Promise<void> {
      if (emailLog.status === "draft" || emailLog.status === "failed") {
        await this.$auth.openContextMenu({
          request: {
            items: [
              {
                title: "Edit Draft",
                click: async () => {
                  await this.updateEmailLog(emailLog);
                },
              },
              {
                title: "Delete Draft",
                click: async () => {
                  await this.deleteEmailLog(emailLog);
                },
              },
            ],
            titleField: "title",
            trackingField: "title",
          },
          filter: "all",
          path: "permissions",
          callback: async (item) => item.click(),
        })();
        return;
      }

      await this.updateEmailLog(emailLog);
    },
    async deleteEmailLog(emailLog: EmailLog): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Draft",
          message: "Are you sure you want to delete this draft?",
        },
      });

      await this.$actionBus.DeleteEmailLog({
        owner: this.owner,
        emailLog,
      });

      await this.refreshEmailLog();
    },
    async updateEmailLog(emailLog: EmailLog): Promise<void> {
      const form = await UpdateEmailLogForm({
        emailLog,
        emailAddresses: this.emailAddresses as Array<EmailAddress>,
        owner: this.owner,
        isNew: false,
      });

      try {
        const result = await form.dialog({
          dialog: {
            title: "Email Log",
          },
          button: {
            left:
              emailLog.status === "draft"
                ? {
                    text: "Save Draft",
                    show: true,
                    click: async () => {
                      const result = await this.saveEmailLog(form);

                      if (false === result) {
                        return;
                      }

                      form.localData.emailLog.$sync(result);
                      form.controls.rebuildForm();
                    },
                  }
                : undefined,
            ok: {
              text: "Send",
              show: emailLog.status === "draft",
            },
            cancel: {
              text: "Close",
              show: emailLog.status !== "sent",
            },
          },
        });

        if (emailLog.status === "draft") {
          await this.saveEmailLog(form);

          if (emailLog.hasMissingParameters) {
            await OpenAlertDialog({
              dialog: {
                title: "Missing Parameters",
                message:
                  "This email log is missing parameters. Please fill them in before sending.",
              },
              button: {
                cancel: {
                  show: false,
                },
              },
            });

            return;
          }

          await this.$actionBus.SendEmailLog({
            ...result,
            owner: this.owner,
          });

          await this.refreshEmailLog();
        }
      } catch {
        emailLog.$restore({
          attachments: true,
          namedAttachments: true,
        });
      }
    },
    async refreshLegacyEmailLog(): Promise<void> {
      const { legacyEmailLogs, pagination } =
        await this.$actionBus.ReadLegacyEmailLog({
          owner: this.owner,
          sort: this.legacyEmailLog.sort,
          pagination: this.legacyEmailLog.pagination,
        });

      this.legacyEmailLog.rows = legacyEmailLogs;
      this.legacyEmailLog.pagination.currentPage = pagination.currentPage;
      this.legacyEmailLog.pagination.lastPage = pagination.lastPage;
      this.legacyEmailLog.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    handleLegacyEmailLogClick(legacyEmailLog: LegacyEmailLog): void {
      UpdateLegacyEmailLogForm(legacyEmailLog).dialog({
        dialog: {
          title: "Legacy Email Log",
        },
        button: {
          ok: {
            text: "Close",
          },
          cancel: {
            show: false,
          },
        },
      });
    },
    async handleCreateEmailClick(): Promise<void> {
      const emailLog = CreateEmailLog();
      const form = await UpdateEmailLogForm({
        emailAddresses: this.emailAddresses as Array<EmailAddress>,
        owner: this.owner,
        emailLog: emailLog,
        isNew: true,
      });

      let saved = false;

      const result = await form
        .dialog({
          dialog: {
            title: "Create Email",
            type: "fullscreen",
          },
          button: {
            left: {
              text: "Save Draft",
              show: true,
              click: async () => {
                const result = await this.saveEmailLog(form, saved);
                if (false === result) {
                  return;
                }

                saved = true;
                emailLog.$sync(result);
                form.controls.rebuildForm();
              },
            },
            ok: {
              text: "Send",
            },
            cancel: {
              text: "Close",
            },
          },
        })
        .catch(async (error) => {
          emailLog.$delete({
            attachments: true,
            namedAttachments: true,
          });
          await this.refreshEmailLog();
          throw error;
        });

      const savedEmailLog = await this.saveEmailLog(form, saved);

      if (false === savedEmailLog) {
        return;
      }

      if (savedEmailLog.hasMissingParameters) {
        await OpenAlertDialog({
          dialog: {
            title: "Missing Parameters",
            message:
              "This email log is missing parameters. Please fill them in before sending.",
          },
          button: {
            cancel: {
              show: false,
            },
          },
        });

        return;
      }

      await this.$actionBus.SendEmailLog({
        files: result.files,
        requiredFiles: result.requiredFiles,
        emailLog: savedEmailLog,
        owner: this.owner,
      });

      await this.refreshEmailLog();
    },
    async saveEmailLog(
      form: FormBuilder<any, any>,
      savedOnServer = true,
    ): Promise<false | EmailLog> {
      if (!form.isValid()) {
        form.options.showValidation = true;
        form.controls.incrementFormRenderKey();
        SnackBarService.errors.invalidForm();
        return false;
      }

      let emailLog: EmailLog;

      if (!savedOnServer) {
        const result = await this.$actionBus.CreateEmailLog({
          ...form.localData,
          owner: this.owner,
        });
        emailLog = result.emailLog;
      } else {
        const result = await this.$actionBus.UpdateEmailLog({
          ...form.localData,
          owner: this.owner,
        });
        emailLog = result.emailLog;
      }

      form.controls.rebuildForm();

      SnackBarService.success.saved();

      await this.refreshEmailLog();

      return emailLog;
    },
    async changeType(): Promise<void> {
      this.isLoading = true;
      await this.$nextTick();
      this.type =
        this.type === "legacyEmailLog" ? "emailLog" : "legacyEmailLog";
      this.emailLog.pagination.currentPage = 1;
      this.legacyEmailLog.pagination.currentPage = 1;
      this.isLoading = false;
    },
  },
});
</script>
