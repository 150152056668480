var m = Object.defineProperty;
var n = (o, e) => m(o, "name", { value: e, configurable: !0 });
import { _ as u } from "../../../component.input/auto-complete-field/auto-complete-field.vue_vue_type_script_lang.js";
import { resolveComponent as a, resolveDirective as w, withDirectives as h, createElementBlock as f, openBlock as t, mergeProps as g, createVNode as v, withKeys as b, withModifiers as k, createSlots as C, withCtx as l, createBlock as D, renderSlot as s } from "vue";
import { b as r } from "../../../_assets/component.input/auto-complete-field/auto-complete-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as S } from "../../../_plugins/_plugin-vue_export-helper.js";
function y(o, e, M, $, V, F) {
  const d = a("DropDownMenu"), p = a("BaseInputField"), c = w("click-outside");
  return h((t(), f("div", g(o.$data.$cypress, {
    class: `${o.wrapperClasses} input-field autocomplete-field ${o.isMultiSelect ? "is-multi-select" : ""}`
  }), [
    v(p, {
      ref: "input",
      value: o.computedSearchValue,
      "onUpdate:value": e[1] || (e[1] = (i) => o.computedSearchValue = i),
      label: o.label,
      "input-tag": "input",
      type: "text",
      placeholder: o.placeholder,
      "icon-left": o.computedLeftIcon,
      "icon-right-override": o.autocompleteRightIcon,
      validators: o.validators,
      message: o.message,
      "is-grouped": o.computedIsGrouped,
      "message-color": o.messageColor,
      size: o.size,
      color: o.color,
      "is-loading": o.optionsMixin.loading || o.isLoading,
      state: o.state,
      "show-validation": o.computedShowValidation,
      "left-icon-click": o.leftIconClick,
      "right-icon-click": o.autocompleteRightIconClick,
      "show-clear-button": !1,
      "is-fullwidth": o.isFullwidth,
      autofocus: o.autofocus,
      autocomplete: o.autocomplete,
      "auto-collapse-label": o.autoCollapseLabel,
      onClear: o.clearSelected,
      onFocus: o.localFocus,
      onSubmit: o.keydown,
      onKeydown: b(k(o.focusDropDown, ["prevent"]), ["down"])
    }, C({
      below: l(() => [
        s(o.$slots, "below", { value: o.dropdownComputedValue })
      ]),
      dropdown: l(() => [
        (t(), D(d, {
          key: o.optionsMixin.loadRenderKey,
          ref: "dropdown",
          value: o.dropdownComputedValue,
          "onUpdate:value": e[0] || (e[0] = (i) => o.dropdownComputedValue = i),
          show: o.showDropDown || o.hasFocus,
          options: o.computedOptions,
          "map-options": {
            label: "label",
            value: "value",
            iconLeft: "iconLeft",
            iconRight: "iconRight",
            line1: "line1",
            line2: "line2"
          },
          "show-more-button": o.showMoreButton,
          "is-scrollable": o.isScrollable,
          "is-static": o.showDropDown,
          "show-checkbox": o.isMultiSelect,
          size: o.size,
          debug: o.debugDropdown,
          "is-multi-select": o.isMultiSelect,
          onMore: o.openDialog
        }, null, 8, ["value", "show", "options", "show-more-button", "is-scrollable", "is-static", "show-checkbox", "size", "debug", "is-multi-select", "onMore"]))
      ]),
      _: 2
    }, [
      o.showLeftControl ? {
        name: "left",
        fn: l(() => [
          s(o.$slots, "left", { value: o.dropdownComputedValue })
        ]),
        key: "0"
      } : void 0,
      o.showRightControl ? {
        name: "right",
        fn: l(() => [
          s(o.$slots, "right", { value: o.dropdownComputedValue })
        ]),
        key: "1"
      } : void 0
    ]), 1032, ["value", "label", "placeholder", "icon-left", "icon-right-override", "validators", "message", "is-grouped", "message-color", "size", "color", "is-loading", "state", "show-validation", "left-icon-click", "right-icon-click", "is-fullwidth", "autofocus", "autocomplete", "auto-collapse-label", "onClear", "onFocus", "onSubmit", "onKeydown"])
  ], 16)), [
    [c, o.blur]
  ]);
}
n(y, "_sfc_render");
typeof r == "function" && r(u);
const K = /* @__PURE__ */ S(u, [["render", y]]);
export {
  K as default
};
