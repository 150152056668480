var d = Object.defineProperty;
var u = (e) => {
  throw TypeError(e);
};
var l = (e, r) => d(e, "name", { value: r, configurable: !0 });
var i = (e, r, t) => r.has(e) || u("Cannot " + t);
var s = (e, r, t) => (i(e, r, "read from private field"), t ? t.call(e) : r.get(e)), m = (e, r, t) => r.has(e) ? u("Cannot add the same private member more than once") : r instanceof WeakSet ? r.add(e) : r.set(e, t);
import { ComponentOptionsBase as n } from "../../../component.config/component-options-base.js";
import { reactive as v } from "vue";
var a;
const o = class o extends n {
  constructor(t = () => ({})) {
    super(t, "App");
    m(this, a, v({
      value: !1
    }));
  }
  get ready() {
    return s(this, a).value;
  }
  setReady() {
    s(this, a).value = !0;
  }
};
a = new WeakMap(), l(o, "AppOptions");
let c = o;
export {
  c as AppOptions
};
