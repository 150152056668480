var f = Object.defineProperty;
var r = (e, l) => f(e, "name", { value: l, configurable: !0 });
import { _ as C } from "../../../component.form/form-area/form-area.vue_vue_type_script_lang.js";
import { resolveComponent as t, createBlock as s, openBlock as o, resolveDynamicComponent as k, normalizeClass as a, withCtx as d, createElementBlock as $, createCommentVNode as i, createElementVNode as p, createTextVNode as m, toDisplayString as c, renderSlot as y } from "vue";
import { _ as v } from "../../../_plugins/_plugin-vue_export-helper.js";
function z(e, l, B, D, N, S) {
  const n = t("Icon"), b = t("Title");
  return o(), s(k(e.is), {
    class: a([`form-area field ${e.direction}`, "form-area"]),
    disabled: e.state === "disabled",
    readonly: e.state === "readonly"
  }, {
    default: d(() => [
      e.label !== null || e.isCollapsible ? (o(), $("div", {
        key: 0,
        class: a(`field-label ${e.labelSize}`)
      }, [
        p("label", {
          class: a(`label ${e.description ? "mb-0" : ""}`)
        }, [
          m(c(e.label) + " ", 1),
          e.isCollapsible && e.computedCollapsed ? (o(), s(n, {
            key: 0,
            class: "mr-2 mt-1 collapse-button is-clickable",
            icon: "ChevronDown",
            size: "is-small",
            onClick: l[0] || (l[0] = (u) => e.computedCollapsed = !1)
          })) : e.isCollapsible ? (o(), s(n, {
            key: 1,
            class: "mr-2 mt-1 collapse-button is-clickable",
            icon: "ChevronUp",
            size: "is-small",
            onClick: l[1] || (l[1] = (u) => e.computedCollapsed = !0)
          })) : i("", !0)
        ], 2),
        e.description ? (o(), s(b, {
          key: 0,
          class: "mb-5",
          "is-subtitle": ""
        }, {
          default: d(() => [
            m(c(e.description), 1)
          ]),
          _: 1
        })) : i("", !0)
      ], 2)) : i("", !0),
      p("div", {
        class: a([`${e.computedCollapsed ? "is-collapsed" : ""}`, "field-body"])
      }, [
        y(e.$slots, "default")
      ], 2)
    ]),
    _: 3
  }, 8, ["class", "disabled", "readonly"]);
}
r(z, "_sfc_render");
const I = /* @__PURE__ */ v(C, [["render", z]]);
export {
  I as default
};
