var a = Object.defineProperty;
var y = (n, o, t) => o in n ? a(n, o, { enumerable: !0, configurable: !0, writable: !0, value: t }) : n[o] = t;
var c = (n, o) => a(n, "name", { value: o, configurable: !0 });
var r = (n, o, t) => y(n, typeof o != "symbol" ? o + "" : o, t);
import { stringToColor as d } from "@kinherit/ts-common";
var p = /* @__PURE__ */ ((n) => (n.ActionBus = "action-bus", n.ComponentForm = "component.form", n.ComponentPage = "component.page", n.DomainFile = "domain.file", n.Http = "http", n.Api = "api", n.ComponentInput = "component.input", n.Core = "core", n.DomainIdentity = "domain.identity", n.Orm = "orm", n.ComponentChart = "component.chart", n.ComponentLayout = "component.layout", n.Cypress = "cypress", n.DomainPerson = "domain.person", n.Validation = "validation", n.Prototype = "prototype", n.ComponentConfig = "component.config", n.ComponentMisc = "component.misc", n.DomainAddress = "domain.address", n.FormBuilder = "form-builder", n.Reporter = "reporter", n.ComponentDisplay = "component.display", n.ComponentMixin = "component.mixin", n.DomainDisclaimer = "domain.disclaimer", n.Global = "global", n.Service = "service", n))(p || {}), l = /* @__PURE__ */ ((n) => (n.debug = "debug", n.info = "info", n.warn = "warn", n.error = "error", n))(l || {});
const i = class i {
  static moduleSilent(o, t) {
    if (t === void 0)
      return i.silentModules.includes(o);
    t ? i.silentModules.push(o) : i.silentModules = i.silentModules.remove(o);
  }
  static log(o, t, b, e) {
    const f = d(o, -40);
    let s = "white";
    switch (t) {
      case "warn":
        s = "lightgoldenrodyellow";
        break;
      case "error":
        s = "indianred";
        break;
      case "debug":
        s = "lightblue";
        break;
    }
    if (![void 0, null, ""].includes(e))
      try {
        e = JSON.parse(JSON.stringify(e));
      } catch {
      }
    console[t === "debug" ? "debug" : "log"](
      `%c[${o}]%c %c${b}`,
      `background: ${f}; color: white; font-weight: bold;`,
      "",
      `background: ${s}; color: black; font-weight: bold;`,
      ...[e].filter((C) => C !== void 0)
    );
  }
};
c(i, "Logger"), r(i, "Modules", p), r(i, "Levels", l), r(i, "silent", !1), r(i, "silentModules", []);
let m = i;
export {
  m as Logger
};
