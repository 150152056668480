<template>
  <div v-if="kintin">
    <h5 class="title is-emphasised mb-5 mt-6">Orders</h5>
    <OrderList :kintin="kintin" />
    <br />
    <div class="buttons">
      <Button
        text="Add Order"
        color="is-success"
        class="mb-4"
        @click="$emit('create-order')"
      />
      <Button
        text="Send Order Payment Email"
        color="is-positive"
        class="mb-4 ml-2"
        @click="sendOrderPaymentEmail"
      />
    </div>
    <CallScriptSection title="Closing">
      <b>
        Great I think that is everything I need.
        <br /><br />
        The process from here: I will send you a [document][link] that lets you
        check the information we have taken down, and provide additional info –
        like emails for your Attorneys, that sort of thing. There is also a link
        that allows you to confirm our disclaimer.
        <br /><br />After that we will produce your documents and typically that
        takes a couple of days, but we should have you sorted in no time. LPA
        forms involve quite a lot of signing. You need to sign bits, your
        certificate provider signs, your Attorneys all sign, you need to sign
        again. And all these signings need witnessing which is a bit of a pain.
        <br /><br />
        We give detailed signing instructions to follow, but we have found a bit
        of guidance directly to Attorneys can help.
        <br /><br />
        So assuming you are happy with this, when your LPA docs get posted to
        you, we will also send your Attorneys some basic info to help with their
        signing bit.
        <ConfirmCheck
          :kintin="kintin"
          step="4.7.1i"
          :kintin-checks="kintinChecks"
          :checks="checks"
          @update="updateCheckValue"
        />
        <br /><br />
        Is that clear or is there anything else for me today?
      </b>
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import OrderList from "@/module/kinvault.kintin/component/call-script/shared/OrderList.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { Button } from "@kinherit/framework/component.input/button";
import { defineComponent } from "vue";

export default defineComponent({
  name: "OrderTab",
  components: {
    Button,
    CallScriptSection,
    ConfirmCheck,
    OrderList,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  emits: ["create-order", "send-order-payment-email"],
  data: () => ({
    renderKey: 0,
    person2Present: null as boolean | null,
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
  methods: {
    async sendOrderPaymentEmail() {
      this.$emit("send-order-payment-email");
    },
  },
});
</script>
