// Subscription

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { PhysicalStorageSubscription } from "@kinherit/sdk";

export const DeleteSubscription = DefineDeleteAction({
  interface: "portal",
  resource:
    "/v2/portal/physical-storage-subscriptions/{physicalStorageSubscription}",
  method: "delete",
  parse: (
    physicalStorageSubscription: PhysicalStorageSubscription | string,
  ) => ({
    params: {
      physicalStorageSubscription,
    },
  }),
});
