// Order, Kintin, AccountReferral, AccountReferralCode, Profile, User

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { Order } from "@kinherit/sdk/model/order";

export const ReadOrder = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/order",
  method: "get",
  rootModel: Order,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});
