import { defineComponent as i } from "vue";
const h = i({
  name: "TransitionHeightComponent",
  methods: {
    transEnter(t, e) {
      const n = getComputedStyle(t).height;
      t.style.height = "0", t.style.overflow = "hidden", getComputedStyle(t).height, requestAnimationFrame(() => {
        t.style.height = n;
      }), this.animationEnd(t, e);
    },
    transLeave(t, e) {
      const n = getComputedStyle(t).height;
      t.style.height = n, t.style.overflow = "hidden", getComputedStyle(t).height, requestAnimationFrame(() => {
        t.style.height = "0";
      }), this.animationEnd(t, e);
    },
    animationEnd(t, e) {
      t.addEventListener("transitionend", () => {
        t.style.height = "", t.style.overflow = "", e();
      });
    }
  }
});
export {
  h as _
};
