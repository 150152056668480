<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
      'call-script-intro': true,
    }"
  >
    <PageHeader htag="h2" text="Discovery Call">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <!--Button text="Save" @click="saveHandler" color="is-positive" /-->
      </template>
    </PageHeader>
    <div class="columns">
      <div class="column">
        <CallScriptSection title="Pre Call">
          <p>
            This call is to listen, explain our services, and hook them into a
            longer second call. It should take about 20 minutes, with 10+
            minutes the interactive discussion.
          </p>
          <InfoBlock title="Read the LA notes">
            <ul class="mt-0">
              <li>
                Familiarise yourself with the names (clients, kids and advisers)
              </li>
              <li>Don’t make assumptions!</li>
            </ul>
          </InfoBlock>
          <InfoBlock title="Focus on how you look, listen and talk">
            <ul class="mt-0">
              <li>
                Active listening with eye contact! Everyone’s situation is
                unique / no one is ‘simple.
              </li>
              <li>Tone: professional, warm and attentive.</li>
              <li>
                Follow best-practice question answering: Lead the customer /
                know "best practice" answers.
              </li>
              <li>
                We offer an "advised" service. We are not order takers! But
                note… this isn’t the advice call.
              </li>
            </ul>
          </InfoBlock>
          <InfoBlock
            title="Remember: This is a sales call but it is NOT for advice."
          >
            <ul class="mt-0">
              <li>
                All our products have real value for families. Our job is to
                explain the value, so they can make an informed decision
              </li>
              <li>We don’t need to be pushy or sales-y (counter-productive)</li>
              <li>
                The enemy: is indecision, and delay. We need to guide to action
                … no one should wait!
              </li>
            </ul>
          </InfoBlock>
        </CallScriptSection>

        <CallScriptSection title="Intro (3+ mins)">
          <b> Hello – can you hear me ok? </b>
          <br /><br />
          <b>
            I’m guessing it’s {{ primaryProfile?.firstName }}
            {{ isJoint ? ` / ${secondaryProfile?.firstName}` : "" }}?</b
          >
          <br /><br />
          <b>
            Good to meet you, I’m {{ $auth.loggedInUser?.profile.firstName }},
          </b>
          <InfoBlock title="30 second ice-breaker">
            <b
              >Ask an ice-breaker question (so everyone feels more relaxed).
            </b>
            <br />
            <ul class="mt-0">
              <li>
                "I can see you’re both present which is great " … "and keen to
                talk about death?"
              </li>
              <li>"Did you manage to get the Zoom link to work ok?"</li>
              <li>
                "I’m curious… / I see … [say something interesting or notable]"
              </li>
              <li>
                "You were introduced by
                <b>{{
                  undefined !== kintin.referral?.contact
                    ? (kintin.referral?.contact?.profile?.fullName ?? ``)
                    : (kintin.referral?.referralCode?.company?.profile
                        ?.fullName ?? ``)
                }}</b>
                … is that right?
              </li>
              <li>
                "Did you raise Wills planning with your IFA, or did it just come
                up as part of the review?"
              </li>
            </ul>
          </InfoBlock>
          <br />
          <b>Well, thank you for making the time. </b>
          <br /><br />
          <b
            >The purpose of today is to discuss your situation, and start to
            think about how to best to protect you and your family.
          </b>
          <br /><em>[pause]</em>
        </CallScriptSection>

        <CallScriptSecuritySection
          start="Before we start, can I just go through security checks with you?"
          :kintin="kintin"
          step="2.1.1"
          :checks="checks"
          :kintin-checks="kintinChecks"
        />
        <CallScriptSection
          title="ACTIVE LISTENING/DISCUSSION SECTION – 10 MINUTES"
        >
          <b>
            I’ve been through the notes that
            {{
              kintin.legalAssistants?.first()?.profile.firstName ??
              `our legal assistant`
            }}
            took, so I have some information already.
            <br /><br />
            But can we start with you giving me an overview of your situation?
          </b>
          <br /><br />
          <em
            >[listen / push on who’d benefit if not forthcoming / confirm the
            relevance of products they mention they are interested in]</em
          >
          <br />
          <br />
          <b>
            So if I play it back to you, and try to pick some core objectives:
          </b>
          <br />
          <ul>
            <li>
              <b
                >Your looking to get organised, so your money goes to your
                [family/partner then kids] [and your children are looked
                after].</b
              >
            </li>
            <li>
              <b
                >And we want to give your family some help with the
                administrative challenges of death or incapacitation also.</b
              >
            </li>
            <li>
              <b
                >And all done in a tax-efficiently manner, that maximises what
                you’ll leave behind.
              </b>
            </li>
          </ul>
          <br />
          <br />
          <b>Maybe to start, if we go through the key people?</b>
          <br />
          <em>
            [Reference names in LA notes, and take details, and go from there.
            Steer the discussion so it covers Focus areas below, and note Tier-2
            areas if they come up]
          </em>
          <br />

          <h3>Focus Areas</h3>

          <h5 class="title is-emphasised mb-0 mt-6">Family &amp; Others</h5>
          <PersonList
            :kintin="kintin"
            :people="$data.people"
            @reload="loadPeople"
          />
          <Button
            class="mt-4 mb-4"
            text="Add Person"
            color="is-success"
            @click="() => addRelativeHandler().then(loadPeople)"
          />
          <!-- Children u 18-->
          <ConfirmCheck
            :kintin="kintin"
            step="2.5.0a"
            :kintin-checks="kintinChecks"
            :checks="checks"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            v-if="checkIsYes('2.5.0a')"
            :kintin="kintin"
            step="2.5.0d"
            :kintin-checks="kintinChecks"
            :checks="checks"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            v-if="checkIsYes('2.5.0a')"
            :kintin="kintin"
            step="2.5.0b"
            :kintin-checks="kintinChecks"
            :checks="checks"
            is-reversed
            @update="updateCheckValue"
          />
          <!-- Marriage-->
          <ConfirmCheck
            v-if="isJoint"
            :kintin="kintin"
            step="2.4.1b"
            :kintin-checks="kintinChecks"
            :checks="checks"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            v-if="isJoint"
            :kintin="kintin"
            step="2.4.1c"
            :kintin-checks="kintinChecks"
            :checks="checks"
            is-reversed
            @update="updateCheckValue"
          />
          <!-- Property -->
          <ConfirmCheck
            label="Do you own your own house?"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="3.0.0a"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            v-if="checkIsYes('3.0.0a')"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="3.0.0c"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            v-if="checkIsYes('3.0.0a')"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="3.0.0d"
            :kintin="kintin"
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin="kintin"
            step="2.4.4b"
            :kintin-checks="kintinChecks"
            :checks="checks"
            is-reversed
            @update="updateCheckValue"
          />
          <b class="check-confirm is-block p-2"
            >Do you have an approximate value of house?</b
          >
          <b class="check-confirm is-block p-2"
            >Do you have a very rough idea of the value of ‘other assets’?</b
          >
          <ConfirmCheck
            label="Is residental and domiciled in the UK"
            :kintin="kintin"
            step="2.4.4a"
            :kintin-checks="kintinChecks"
            :checks="checks"
            :no-label="'No/Unsure'"
            is-reversed
            :on="{
              no: {
                say: [
                  'No problem. We do get customers from outside the UK, although obviously we cannot vouch for how a UK Will is going to be treated in other territories.',
                ],
              },
            }"
            @update="updateCheckValue"
          />

          <!--- 2 -->
          <h3>Tier 2</h3>

          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.5.0c"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="3.0.3b"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="3.0.3d"
            :kintin="kintin"
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.5.0e"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.5.0f"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.5.2a"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="5.2.11"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />

          <!--ConfirmCheck
            label="Has business assets"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="3.0.8a"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            v-if="checkIsYes('3.0.8a')"
            label="Owns own business"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="3.0.8b"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          />
          <ConfirmCheck
            label="Owns agricultural assets"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="3.0.8e"
            :kintin="kintin"
            is-reversed
            @update="updateCheckValue"
          /-->
          <br />
          <b>
            Do you have any planning in place already, like LPAs?
            <br /><br />
            And has anyone mentioned Estate Protection Trusts, or things like
            that?
            <br /><br />
            is there any other complexity in your situation that we haven’t
            covered?
          </b>
          <InfoBlock title="Prompts">
            For each – the focus is to take simple notes. Respond simply to
            questions / avoid in-depth explanations if possible, and clear
            product-hooks are OK but no hard sell. (the ‘advice to take
            products’ will come later).
          </InfoBlock>
          <br />
        </CallScriptSection>

        <CallScriptSection title="Positioning (up to 5 mins)">
          <b
            >Thanks – it’s super helpful to have an understanding your
            situation.</b
          >
          <br /><br />
          <b>
            I’ll come to next steps in a moment – but first I want to give a
            basic overview of what we do.
          </b>
          <br /><br />
          <b>Essentially, we provide help in four key areas.</b>
          <br /><br />
          <b>FIRSTLY – we help people with their Wills.</b>
          <br />
          <b
            >- As you know these are legal documents that say who will get your
            money if you die.</b
          >
          <br />
          <b>- They also say who should be in charge of the process …</b>
          <br />
          <b
            >- [… and given you’ve children under 18, you’ll also need to name
            Guardians.]</b
          >
          <br />
          <em>[Pause]</em>
          <br /><br />
          <b>THE SECOND main thing we do is Lasting Powers of Attorney.</b>
          <br />
          <b
            >- These put decision making the in the hands of people you trust,
            in the event you are incapacitated. Which means – if you are alive,
            but unable to speak for yourself
          </b>
          <br />
          <em>[Pause]</em>
          <br /><br />
          <b
            >THE THIRD thing we do is Estate Protection Trusts, and other forms
            of enhanced protection.</b
          >
          <br />
          <b
            >- These are designed to protect your wealth after death – and they
            are also much more flexible than Will in some ways.
          </b>
          <br />
          <em>[Pause]</em>
          <br /><br />
          <b
            >AND FINALLY – the fourth area is what we call handover planning.</b
          >
          <br />
          <b
            >- This gets your legal, financial and practical information to your
            family when it’s needed.</b
          >
          <br />
          <br />
          <b>
            So of those four things that we do, the first three are legal
            products. But the handover planning is different, and it’s very
            specific to Kinherit.
            <br />- Put simply, it helps solve the administrative challenges
            your family will face on death, which is why we think it’s so
            valuable. <br />- And it’s included in the price of the Will, so no
            extra cost.
          </b>
          <br />
          <br />
          <b
            >Based on what you have told me today - there is no doubt that we
            can help you with your objectives. <br /><br />
            The next step would be to find a time for us to go through things
            like your testamentary capacity checks, and other questions that can
            affect your document structuring, and I’d also give you specific
            recommendations for your situation.
            <br /><br />
            And when you’re ready to proceed, we’d take payment and move to
            information verification and sign-off.
          </b>
          <br /><br />
        </CallScriptSection>

        <CallScriptSection title="Pricing (up to 5 mins)">
          <b>
            Briefly on pricing – all our prices include VAT, and are essentially
            fixed fee and listed on our website.
          </b>
          <br /><br />
          <b
            >{{ isJoint }} But to give you an idea, its &pound;{{
              isJoint ? prices.mirrorWill : prices.singleWill
            }}
            for a {{ isJoint ? "mirror" : "single" }} Will, and this includes
            our handover services
          </b>
          <br /><br />
          <b>
            Lasting Powers of Attorney and anything else you want are on top.
          </b>
        </CallScriptSection>
        <CallScriptSection title="Things to think about">
          <b
            >To prepare for the next call, it’d be great if you can have a think
            about a couple of areas.
          </b>
          <ul>
            <li>
              <b
                >Double check who gets what, including any gifts of specific
                items - like your golf clubs to your golf buddy.</b
              >
            </li>

            <li>
              <b
                >And think about roles - who would you trust to make practical
                decisions on your behalf.
              </b>
            </li>

            <li>
              <b
                >[And Guardians for your children. It is hard to pick them so do
                be selfish … we can always add financial support]
              </b>
            </li>

            <li>
              <b
                >And we also need to think through the “what-if” scenarios. So
                who is in reserve, if the main people you pick are not able to
                do the role?
              </b>
            </li>
          </ul>
          <br /><br />
          <b
            >When we meet again I’ll also explain why it’s important to have
            secure storage of the paper originals – we can help with that if
            needed – and other things that are just sensible steps to get you
            protected.
          </b>
          <br /><br />
          <b> Does this all make sense? </b>
          <br /><em>[Pause]</em>
        </CallScriptSection>

        <CallScriptSection title="Business Owner">
          <b>
            [As you are a business/agricultural asset owner - you may want
            different people looking after that side of your planning.
          </b>
          <br /><br />
          <b>
            is There also something called Business Relief [Agricultural Relief]
            which we’d want to structure into your legal documents.
          </b>
        </CallScriptSection>

        <CallScriptSection title="Wrap up">
          <b>
            Do you have any other questions for me today or shall we look at
            booking the follow up call?
          </b>
          <br /><br />
          <b
            >Most people need a few days to think about the roles, but maybe we
            look at something like [time] on [+5 day forward] – is that a good
            time?</b
          >
          <br /><em>[ Book time ]</em> <br /><br />
          <b>Fantastic, I think all done!</b>
          <br /><br />
          <b>
            So I will send you an email after this call with confirmation, and
            otherwise I will see you again on [date] at [time].
          </b>
        </CallScriptSection>
      </div>
      <div class="column is-narrow">
        <Card
          title="Estate Value Calculator"
          title-size="is-4"
          class="estate-value-calculator"
        >
          <Form
            v-if="$data.propertyTotalsSectionForm"
            :config="$data.propertyTotalsSectionForm"
            is-borderless
          />
          <Button
            text="Save"
            color="is-positive"
            is-fullwidth
            @click="saveAssetCalculatorValues"
          />
        </Card>
      </div>
    </div>
  </div>
</template>
<!--
<cypress-wrapper lang="json">
{
  "name": "KintinDetailsCallScriptIntroWrapper",
  "route": "KintinDetailsCallScriptIntro",
  "seletor": ".call-script-intro"
}
</cypress-wrapper> -->

<script lang="ts">
import { CreatePerson } from "@/config/model.config";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import InfoBlock from "@/module/kinvault.kintin/component/call-script/shared/InfoBlock.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSecuritySection from "@/module/kinvault.kintin/component/call-script/shared/SecuritySection.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { PropertyTotalsSectionForm } from "@/module/kinvault.kintin/form/call-script.form/property-totals-section.form";
import { UpdatePersonForm } from "@/module/kinvault.kintin/form/update-person.form";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { KintinDetailsCallScript2Call1Route } from "@/module/kinvault.kintin/page/details/call-script-2";
import { AuthService } from "@/service/auth.service";
import Form from "@kinherit/framework/component.form/form";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { NotIn } from "@kinherit/orm/index";
import { Person, Product } from "@kinherit/sdk";
import { Currency } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: KintinDetailsCallScript2Call1Route,
  components: {
    PageHeader,
    Button,
    CallScriptSection,
    ConfirmCheck,
    InfoBlock,
    CallScriptSecuritySection,
    PersonList,
    Form,
    Card,
  },
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    KinvaultKintinDetailsCallScriptMixin,
  ],
  emits: ["save-callscript"],
  data: () => ({
    isFocused: false,
    renderKey: 0,
    person2Present: null as boolean | null,
    tab: 0,
    products: [] as Product[],
    people: [] as Person[],
    propertyTotalsSectionForm: null as null | FormBuilder<any, any>,
    values: {
      advSavingsValue: null as null | Currency,
      advPropsValue: null as null | Currency,
      advPolicyValue: null as null | Currency,
      advPensionValue: null as null | Currency,
      advOtherAssetValue: null as null | Currency,
      advBusinessAssetValue: null as null | Currency,
      advPotentialValue: null as null | Currency,
      advDebtValue: null as null | Currency,
      advHteStateValue: null as null | Currency,
    },
  }),
  computed: {
    prices(): {
      singleWill: number;
      mirrorWill: number;
    } {
      return {
        singleWill:
          this.products
            .filterBy("value", "standardwill")
            .findBy("type", "single")?.price.amount ?? 0,
        mirrorWill:
          this.products
            .filterBy("value", "standardwill")
            .findBy("type", "joint")?.price.amount ?? 0,
      };
    },

    assetNetTotal(): number {
      return (
        (this.values.advSavingsValue?.amount ?? 0) +
        (this.values.advPropsValue?.amount ?? 0) +
        (this.values.advPolicyValue?.amount ?? 0) +
        (this.values.advPensionValue?.amount ?? 0) +
        (this.values.advOtherAssetValue?.amount ?? 0) +
        (this.values.advBusinessAssetValue?.amount ?? 0) +
        (this.values.advPotentialValue?.amount ?? 0) +
        (this.values.advDebtValue?.amount ?? 0)
      );
    },
  },
  async mounted() {
    if (!this.kintin || !this.$auth.loggedInUser) {
      return;
    }
    this.loadPeople();
    this.person2Present = this.callScript.person2Present;

    const { product } = await window.Kernel.ActionBus2.core.select.ReadProduct({
      message: {},
      pagination: false,
    });
    this.products = product;
    const { form, updateAssetNetTotal } = PropertyTotalsSectionForm(
      this.values,
    );
    this.propertyTotalsSectionForm = form;
    this.$watch(
      () => this.assetNetTotal,
      () => {
        updateAssetNetTotal();
      },
    );
    this.values.advSavingsValue =
      this.kintin.callScript?.advSavingsValue ?? null;
    this.values.advPropsValue = this.kintin.callScript?.advPropsValue ?? null;
    this.values.advPolicyValue = this.kintin.callScript?.advPolicyValue ?? null;
    this.values.advPensionValue =
      this.kintin.callScript?.advPensionValue ?? null;
    this.values.advOtherAssetValue =
      this.kintin.callScript?.advOtherAssetValue ?? null;
    this.values.advBusinessAssetValue =
      this.kintin.callScript?.advBusinessAssetValue ?? null;
    this.values.advPotentialValue =
      this.kintin.callScript?.advPotentialValue ?? null;
    this.values.advDebtValue = this.kintin.callScript?.advDebtValue ?? null;
    this.values.advHteStateValue =
      this.kintin.callScript?.advHteStateValue ?? null;
  },
  methods: {
    async saveAssetCalculatorValues() {
      if (!this.kintin) {
        return;
      }

      this.kintin.callScript.advSavingsValue = this.values.advSavingsValue;
      this.kintin.callScript.advPropsValue = this.values.advPropsValue;
      this.kintin.callScript.advPolicyValue = this.values.advPolicyValue;
      this.kintin.callScript.advPensionValue = this.values.advPensionValue;
      this.kintin.callScript.advOtherAssetValue =
        this.values.advOtherAssetValue;
      this.kintin.callScript.advBusinessAssetValue =
        this.values.advBusinessAssetValue;
      this.kintin.callScript.advPotentialValue = this.values.advPotentialValue;
      this.kintin.callScript.advDebtValue = this.values.advDebtValue;
      this.kintin.callScript.advHteStateValue = this.values.advHteStateValue;
      this.$emit("save-callscript");
    },

    async addRelativeHandler() {
      if (!this.kintin) {
        return;
      }

      const person = CreatePerson();

      try {
        await UpdatePersonForm({
          person,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create person",
          },
        });
      } catch {
        person.$delete({
          profile: true,
          relationToPrimaryPerson: true,
          relationToSecondaryPerson: true,
        });
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.person.create({
      person.kintin = this.kintin;
      await this.$actionBus.person.CreatePerson(person);

      this.renderKey++;
    },
    loadPeople() {
      if (!this.kintin) {
        return;
      }

      this.people = Person.$findBy({
        id: NotIn(
          [
            this.kintin.$data.primaryPerson,
            this.kintin.$data.secondaryPerson,
          ].filter(Boolean),
        ),
        kintin: {
          id: this.kintin.id,
        },
      });
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
.estate-value-calculator {
  position: sticky;
  height: fit-content;
  top: 5rem;
}
</style>
