var i = Object.defineProperty;
var t = (o, r) => i(o, "name", { value: r, configurable: !0 });
import { ThemeIconName as c, ThemeIconStyle as e } from "../../theme/prop/icon.js";
import { ThemeSize as n } from "../../theme/prop/size.js";
import { defineComponent as l } from "vue";
const p = l({
  name: "IconComponent",
  props: {
    /**
     * Name of the preconfigured icon you want to use
     */
    icon: {
      type: [String, Object],
      required: !0,
      value: c
    },
    /**
     * Name of the predefined size to scale the component with
     */
    size: {
      type: String,
      default: "is-normal",
      validator: /* @__PURE__ */ t((o) => Object.values(n).includes(o), "validator"),
      value: n
    },
    /**
     * Override the primary color used to style the icon
     */
    color: {
      type: String,
      default: null,
      value: "color"
    },
    /**
     * Override the secondary color used to style the icon
     */
    secondaryColor: {
      type: String,
      default: null,
      value: "color"
    },
    /**
     * The style of the icon to use
     */
    iconStyle: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((o) => Object.keys(e).includes(o), "validator"),
      value: Object.keys(e)
    },
    /**
     * Force the icon to inherit the color of its parent
     */
    inheritColor: {
      type: Boolean,
      default: !1
    },
    /**
     * Number to display on the icon
     */
    count: {
      type: Number,
      default: null
    }
  },
  emits: ["click"],
  computed: {
    isDuotone() {
      return this.iconStyle !== null ? this.iconStyle === "Duotone" : this.computedIcon === null ? !1 : this.computedIcon.style === "Duotone";
    },
    computedIcon() {
      if (typeof this.icon == "string") {
        if (!(this.icon in window.Kernel.Theme.Icons))
          throw new Error(`Icon ${this.icon} not found`);
        return window.Kernel.Theme.Icons[this.icon];
      }
      return this.icon;
    },
    computedIconStyle() {
      return this.iconStyle !== null ? e[this.iconStyle] : this.computedIcon === null ? null : e[this.computedIcon.style];
    },
    computedColor() {
      return this.inheritColor ? "inherit" : this.color !== null ? this.color in window.Kernel.Theme.namedColors ? window.Kernel.Theme.namedColors[this.color] : this.color : this.computedIcon === null ? null : "color" in this.computedIcon ? this.computedIcon.color : "primaryColor" in this.computedIcon ? this.computedIcon.primaryColor : null;
    },
    computedSecondaryColor() {
      return this.inheritColor ? "inherit" : this.secondaryColor !== null ? this.secondaryColor in window.Kernel.Theme.namedColors ? window.Kernel.Theme.namedColors[this.secondaryColor] : this.secondaryColor : this.computedIcon === null ? null : "color" in this.computedIcon ? this.computedIcon.color : "secondaryColor" in this.computedIcon ? this.computedIcon.secondaryColor : this.secondaryColor === null ? this.computedColor : null;
    },
    computedPrimaryOpacity() {
      return this.computedIcon && "primaryOpacity" in this.computedIcon ? this.computedIcon.primaryOpacity : null;
    },
    computedSecondaryOpacity() {
      return this.computedIcon && "secondaryOpacity" in this.computedIcon ? this.computedIcon.secondaryOpacity : null;
    },
    computedOpacity() {
      return this.computedIcon === null || !("opacity" in this.computedIcon) ? null : this.computedIcon.opacity;
    }
  }
});
export {
  p as _
};
