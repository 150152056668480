var y = Object.defineProperty;
var d = (e, l) => y(e, "name", { value: l, configurable: !0 });
import { _ } from "../../../component.input/file-field/file-field.vue_vue_type_script_lang.js";
import { resolveComponent as r, createElementBlock as i, openBlock as a, mergeProps as v, createVNode as t, withCtx as p, createElementVNode as s, normalizeClass as u, withKeys as m, toDisplayString as n, createCommentVNode as c, TransitionGroup as k, Fragment as C, renderList as F, createBlock as $, createTextVNode as z, withModifiers as w } from "vue";
import { _ as B } from "../../../_plugins/_plugin-vue_export-helper.js";
const I = ["disabled", "readonly", "multiple", "accept"], N = { class: "file-uploader__instruction" }, V = { class: "file-uploader__files-container" }, D = {
  key: "placeholder",
  class: "file-uploader__placeholder"
}, E = { class: "file-uploader__file__name" };
function S(e, l, T, j, G, H) {
  const f = r("Icon"), h = r("Button"), g = r("Field");
  return a(), i("div", v(e.$data.$cypress, {
    class: ["file-uploader__wrapper input-field", e.wrapperClasses]
  }), [
    t(g, {
      label: e.label,
      messages: e.computedMessages,
      "has-addons": e.computedhasAddons,
      "is-grouped": e.computedIsGrouped,
      size: e.size
    }, {
      default: p(() => [
        s("div", {
          class: u(["file-uploader", [
            e.inputClasses,
            e.controlClasses,
            { "is-compact": e.isCompact },
            e.color
          ]])
        }, [
          s("label", {
            ref: "refDropZone",
            class: u(["file-uploader__label", { "has-drag-entered": e.hasDragEntered }]),
            tabindex: "0",
            onKeyup: [
              l[1] || (l[1] = m(() => e.input.click(), ["enter"])),
              l[2] || (l[2] = m(() => e.input.click(), ["space"]))
            ]
          }, [
            s("input", {
              ref: "input",
              class: "file-uploader__input js-file-input",
              type: "file",
              disabled: e.state === "disabled",
              readonly: e.state === "readonly",
              tabindex: "-1",
              multiple: !e.isSingle,
              accept: e.allowedFileTypes.join(", "),
              onInput: l[0] || (l[0] = (...o) => e.inputHandler && e.inputHandler(...o))
            }, null, 40, I),
            s("div", N, [
              t(f, {
                icon: "Upload",
                size: "is-medium"
              }),
              s("span", null, n(e.instruction), 1)
            ])
          ], 34),
          s("div", V, [
            !e.hasFiles && e.placeholder ? (a(), i("div", D, n(e.placeholder), 1)) : c("", !0),
            t(k, {
              name: "transition-list",
              tag: "div"
            }, {
              default: p(() => [
                (a(!0), i(C, null, F(e.files, (o, b) => (a(), i("div", {
                  key: `file-${o.name}-${o.size}`,
                  class: "file-uploader__file"
                }, [
                  s("span", E, [
                    z(n(o.name) + " ", 1),
                    s("small", null, n(e.getFileSize(o)), 1)
                  ]),
                  e.hasFiles ? (a(), $(h, {
                    key: 0,
                    "icon-left": "Remove",
                    "aria-label": `Remove ${o.name}`,
                    color: "is-plain",
                    class: "file-name-delete",
                    onClick: w(() => e.clear(b), ["prevent"])
                  }, null, 8, ["aria-label", "onClick"])) : c("", !0)
                ]))), 128))
              ]),
              _: 1
            })
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["label", "messages", "has-addons", "is-grouped", "size"])
  ], 16);
}
d(S, "_sfc_render");
const L = /* @__PURE__ */ B(_, [["render", S]]);
export {
  L as default
};
