<template>
  <div class="introducer-fee-master-list">
    <PageHeader htag="h1" text="Introducer Fees" />
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:value="selected"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      :columns="columns"
      :rows="data"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        createdAt: 'Created',
        paidAt: 'Paid',
      }"
      :row-classes="
        (line: any) => ({
          'has-background-success-light':
            (line.row as ReadKintinIntroducerFeeResponse['data'][number]).data
              .order.feesConfirmed === true,
        })
      "
      :tracking-field="
        (row: ReadKintinIntroducerFeeResponse['data'][number]) =>
          row.data.order.id
      "
      click-to-expand
      @refresh="refresh"
    >
      <template #tableControls>
        <!-- <Button :text="`Download`" size="is-small" @click="downloadCsv" /> -->
        <Button
          :text="`Bulk Reconcile ${
            selected.length > 0 ? `(${selected.length})` : ''
          }`"
          :color="selected.length > 0 ? 'is-success' : null"
          size="is-small"
          :is-disabled="selected.length === 0"
          @click="bulkReconcileOrders"
        />
        <Button
          :text="`Add to Payment Run ${
            selected.length > 0 ? `(${selected.length})` : ''
          }`"
          :color="selected.length > 0 ? 'is-positive' : null"
          size="is-small"
          :is-disabled="selected.length === 0"
          @click="bulkAddToPaymentRun"
        />
      </template>
      <template #actions="{ row }">
        <Button
          class="details-button"
          :icon-left="$style.icon.edit.icon"
          aria-label="Edit"
          size="is-small"
          color="is-plain"
          tooltip="Edit"
          @click="updateOrder(row.data.order.id)"
        />
      </template>
      <template #details="{ row }">
        <IntroducerFeeDetails :data="row" />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerFeeMasterListWrapper",
  "route": "IntroducerFeeMasterList",
  "selector": ".introducer-fee-master-list",
  "imports": {
    "ReadIntroducerFeeKintinFormWrapper": "@/module/introducer.fees/form/read-introducer-fee-kintin.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadIntroducerFeeKintinFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadKintinIntroducerFeeResponse } from "@/action-bus/portal/introducer/fees/read-kintin-introducer-fees.action";
import { IntroducerFeesTableColumns } from "@/config/table-columns.config";
import IntroducerFeeDetails from "@/module/introducer.fees/component/IntroducerFeeDetails.vue";
import { AssignIntroducerFeeToPaymentRunForm } from "@/module/introducer.fees/form/assign-introducer-fee-to-payment-run.form";
import { ReadIntroducerFeeKintinForm } from "@/module/introducer.fees/form/read-introducer-fee-kintin.form";
import { IntroducerFeeMasterListRoute } from "@/module/introducer.fees/page";
import { UpdateOrderForm } from "@/module/order/form/update-order.form";
import { StyleService } from "@/service/style.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { In } from "@kinherit/orm/index";
import { IOrder, Order } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: IntroducerFeeMasterListRoute,
  components: { MasterListPage, PageHeader, Button, IntroducerFeeDetails },
  mixins: [
    StyleService.mixin,
    ActionBusMixin(() => window.Kernel.ActionBus2.portal.introducer),
  ],
  data: () => ({
    filters: ReadIntroducerFeeKintinForm(),
    columns: IntroducerFeesTableColumns() as TableColumn<
      ReadKintinIntroducerFeeResponse["data"][0]
    >[],
    data: [] as ReadKintinIntroducerFeeResponse["data"],
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "paidAt" as keyof IOrder,
      direction: "desc" as "desc" | "asc",
    },
    selected: [] as ReadKintinIntroducerFeeResponse["data"],
  }),
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadIntroducerFeeKintinForm>["localData"],
    ): Promise<void> {
      const data = await this.$actionBus.fees.ReadKintinIntroducerFees({
        message: {
          ...formData,
          showOrders: formData.showOrders ?? "any",
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;

      const paymentRunIds = data.data
        .map((line) => [
          line.data.order.introducerFeePaymentRun_id,
          line.data.order.agentFeePaymentRun_id,
          line.data.order.parentAgentFeePaymentRun_id,
        ])
        .flat()
        .filter(Boolean)
        .cast<string>()
        .unique();

      const xeroContactIds = data.data
        .map((line) => [
          line.data.order.xeroAgentBillId,
          line.data.order.xeroParentAgentBillId,
          line.data.order.xeroIntroducerBillId,
        ])
        .flat()
        .filter(Boolean)
        .cast<string>()
        .unique();

      const promises: Promise<any>[] = [];

      if (xeroContactIds.isNotEmpty()) {
        promises.push(
          // window.Kernel.ActionBus.core.select.introducerFeePaymentRun.read({
          window.Kernel.ActionBus2.core.select.ReadIntroducerFeePaymentRun({
            query: {
              id: In(paymentRunIds),
            },
            pagination: false,
          }),
        );
      }

      if (xeroContactIds.isNotEmpty()) {
        promises.push(
          // window.Kernel.ActionBus.core.select.xeroContact.read({
          window.Kernel.ActionBus2.core.select.ReadXeroContact({
            query: {
              id: In(xeroContactIds),
            },
            pagination: false,
          }),
        );
      }

      await Promise.all(promises);

      this.data = data.data;

      this.$forceUpdate();
    },
    async bulkAddToPaymentRun(): Promise<void> {
      await AssignIntroducerFeeToPaymentRunForm(this.selected).dialog({
        dialog: {
          title: "Assign Introducer Fees to Payment Run",
        },
      });
    },
    async bulkReconcileOrders(): Promise<void> {
      const orderIds = this.selected.map((line) => line.data.order.id);

      // const { orders } = await window.Kernel.ActionBus.order.order.read({
      //   query: {
      //     id: In(orderIds),
      //   },
      //   assignedUser: [],
      //   referral: [],
      //   status: [],
      //   paymentType: [],
      //   created: null,
      //   paidWithin: null,
      //   pagination: false,
      //   sort: {
      //     by: "createdAt",
      //     direction: "asc",
      //   },
      // });

      const { order: orders } = await this.$actionBus.fees.order.ReadOrder({
        query: {
          id: In(orderIds),
        },
        pagination: false,
        sort: {
          by: "createdAt",
          direction: "asc",
        },
      });

      await orders.forEachAsync(
        async (order) => {
          order.feesConfirmed = true;
          // await window.Kernel.ActionBus.introducerFees.order.update({
          //   order,
          // });
          await this.$actionBus.fees.order.UpdateOrder(order);
        },
        { parallel: 10 },
      );

      this.selected = [];
      await this.refresh(this.filters.localData);
    },
    async updateOrder(orderId: string) {
      // const orderRecord = await window.Kernel.ActionBus.order.order.record({
      //   order: orderId as string,
      // });
      const orderRecord = await this.$actionBus.fees.order.RecordOrder({
        message: orderId as string,
      });

      if (!orderRecord) {
        return;
      }

      const order = Order.$findOneBy({
        id: orderId,
      });

      if (!order) {
        return;
      }

      try {
        await UpdateOrderForm({
          data: order,
          referralCode: order.kintin?.referral?.referralCode ?? undefined,
        }).dialog({
          dialog: {
            title: "Update Order",
          },
        });
      } catch {
        order.orderItems.forEach((item) => item.$delete());
        // await window.Kernel.ActionBus.order.order.record({
        //   order,
        // });
        await this.$actionBus.fees.order.RecordOrder({
          message: order,
        });
        return;
      }

      order.$persist();

      await window.Kernel.ActionBus2.portal.order.ReplaceOrderItems(order);
      await window.Kernel.ActionBus2.portal.order.UpdateOrder(order);

      order.$delete({
        orderItems: true,
      });

      await window.Kernel.ActionBus2.portal.order.RecordOrder({
        message: orderId,
      });

      await this.refresh(this.filters.localData);
    },
  },
});
</script>
