import { Portal2AdminEmailTemplate } from "@/action-bus/portal/admin/email-template";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Portal2AdminAccountReferralCode } from "./account-referral-code";
import { Portal2AdminAccountReferralCodes } from "./account-referral-codes";
import { Portal2AdminBrandedKinvault } from "./branded-kinvault";
import { Portal2AdminBrandedKinvaults } from "./branded-kinvaults";
import { Portal2AdminCallLogs } from "./call-logs";
import { Portal2AdminEmailTemplates } from "./email-templates";
import { Portal2AdminLog } from "./log";
import { Portal2AdminProduct } from "./product";
import { Portal2AdminTag } from "./tag";
import { Portal2AdminTags } from "./tags";
import { Portal2AdminUser } from "./user";
import { Portal2AdminUsers } from "./users";

export const Portal2Admin = DefineActionBus({
  name: "portal-admin",
  actions: {
    accountReferralCode: Portal2AdminAccountReferralCode,
    accountReferralCodes: Portal2AdminAccountReferralCodes,
    brandedKinvaults: Portal2AdminBrandedKinvaults,
    brandedKinvault: Portal2AdminBrandedKinvault,
    callLogs: Portal2AdminCallLogs,
    emailTemplate: Portal2AdminEmailTemplate,
    emailTemplates: Portal2AdminEmailTemplates,
    log: Portal2AdminLog,
    tag: Portal2AdminTag,
    tags: Portal2AdminTags,
    user: Portal2AdminUser,
    users: Portal2AdminUsers,
    product: Portal2AdminProduct,
  },
});
