<template>
  <MasterListPage
    v-if="$data.filters"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
    :filters="$data.filters"
    :columns="columns"
    :rows="rows"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    :title="title"
    subtitle="Shared Component Document Master List"
    :has-context-menu="hasContextMenu"
    :sort-by-options="{
      createdAt: 'Created',
      title: 'Title',
    }"
    @refresh="refresh"
    @row-clicked="handleClick"
    @context-menu="(event) => $emit('context-menu', event)"
  >
    <template #buttons>
      <slot name="buttons" />
    </template>
  </MasterListPage>
</template>

<cypress-wrapper lang="json">
{
  "name": "KnowledgeBaseDocumentMasterListWrapper",
  "imports": {
    "ReadKnowledgeBaseDocumentFormWrapper": "@/module/knowledge-base/form/read-knowledge-base-document.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadKnowledgeBaseDocumentFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadKnowledgeBaseDocumentForm } from "@/module/knowledge-base/form/read-knowledge-base-document.form";
import {
  KnowledgeBaseDocumentDetailsParams,
  KnowledgeBaseDocumentDetailsRoute,
} from "@/module/knowledge-base/page";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Equal, Like } from "@kinherit/orm/index";
import {
  IKnowledgeBaseDocument,
  KnowledgeBaseCategory,
  KnowledgeBaseDocument,
} from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "KnowledgeBaseDocumentMasterList",
  components: { MasterListPage },
  props: {
    categoryId: {
      type: String,
      required: true,
    },
    parentCategoryId: {
      type: String,
      required: true,
    },
    hasContextMenu: {
      type: Boolean,
      default: false,
    },
    hasCreateButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["context-menu", "create", "update", "delete"],
  data: () => ({
    filters: ReadKnowledgeBaseDocumentForm(),
    columns: [
      {
        title: "Document Name",
        sort: true,
        map: (v: KnowledgeBaseDocument) => v.title,
      },
    ] as Array<TableColumn>,
    rows: Array<KnowledgeBaseDocument>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "title" as keyof IKnowledgeBaseDocument,
      direction: "asc" as "desc" | "asc",
    },
  }),
  computed: {
    category(): KnowledgeBaseCategory | null {
      if (this.categoryId) {
        return KnowledgeBaseCategory.$findOne(this.categoryId);
      }
      return null;
    },
    title(): string {
      if (this.category) {
        return `${this.category.title} Documents`;
      }

      return "Knowledge Base Documents";
    },
  },
  methods: {
    handleClick(
      knowledgebasedocument: KnowledgeBaseDocument,
      event: MouseEvent,
    ): void {
      const params: KnowledgeBaseDocumentDetailsParams = {
        topLevelCategory: this.parentCategoryId,
        document: knowledgebasedocument.id,
        category: this.categoryId,
      };

      window.Kernel.visitRoute(
        {
          name: KnowledgeBaseDocumentDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadKnowledgeBaseDocumentForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.knowledgeBase.document.read({
      //   ...formData,
      //   category: this.categoryId,
      //   sort: this.sort,
      //   pagination: this.pagination,
      // });

      const data =
        await window.Kernel.ActionBus2.portal.knowledgeBase.category.ReadKnowledgeBaseDocument(
          {
            query: [
              {
                title: Like(formData.name),
                createdAt: Between(formData.created),
                categories: {
                  id: Equal(this.categoryId),
                },
              },
              {
                content: Like(formData.name),
                createdAt: Between(formData.created),
                categories: {
                  id: Equal(this.categoryId),
                },
              },
            ],
            sort: this.sort,
            pagination: this.pagination,
          },
        );

      this.rows = data.knowledgeBaseDocument;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
