import { Api, Order, OrderItem } from "@kinherit/sdk";

// OrderItem, Order
export const ReplaceOrderItems = async (order: Order) => {
  await Api.resource("portal", "/v2/portal/order/order-items")
    .method("put")
    .body({
      order: order.id,
      orderItems: order.orderItems.map((orderItem: OrderItem) => ({
        id: orderItem.id,
        price: orderItem.$data.price,
        product: orderItem.$data.product ?? null,
        quantity: orderItem.$data.quantity,
        recurring: orderItem.$data.recurring,
        requiresDd: orderItem.$data.requiresDd,
        text: orderItem.$data.text,
        value: orderItem.$data.value,
        vat: orderItem.$data.vat,
      })),
    })
    .result();
};
