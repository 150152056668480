import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateMfAuthMethod } from "./create-mf-auth-method.action";
import { DeleteMfAuthMethod } from "./delete-mf-auth-method.action";
import { ReadMfAuthMethod } from "./read-mf-auth-method.action";
import { ResendMfAuthMethodCode } from "./resend-mf-auth-method-code.action";
import { UpdateMfAuthMethod } from "./update-mf-auth-method.action";

export const CoreUserMfAuthMethods = DefineActionBus({
  name: "core-user-mf-auth-methods",
  actions: {
    CreateMfAuthMethod,
    ResendMfAuthMethodCode,
    ReadMfAuthMethod,
    UpdateMfAuthMethod,
    DeleteMfAuthMethod,
  },
});
