var s = Object.defineProperty;
var a = (e, t) => s(e, "name", { value: t, configurable: !0 });
import o from "../../_vue/component.display/icon/icon.js";
import l from "../../_vue/component.form/field/field.js";
import { FieldMixin as n } from "../../component.mixin/field-mixin.js";
import { CypressHelper as d } from "../../cypress/component-helpers.js";
import { Environment as f } from "../../service/environment.js";
import { defineComponent as h, ref as p } from "vue";
import u from "../../_vue/component.input/button/button.js";
import { S as m } from "../../_assets/component.input/file-field/file-field.module.js";
const M = h({
  name: "FileFieldComponent",
  components: { Field: l, Icon: o, Button: u },
  mixins: [n()],
  props: {
    /**
     * Array of files selected by the user (v-model:value)
     */
    value: {
      type: Array,
      default: /* @__PURE__ */ a(() => [], "default")
    },
    /**
     * Place holder message to be displayed when the field is empty
     */
    placeholder: {
      type: String,
      default: "- No Files Selected -"
    },
    /**
     * Only allow one file to be selected
     */
    isSingle: {
      type: Boolean,
      default: !1
    },
    /**
     * Use the compact style
     */
    isCompact: {
      type: Boolean,
      default: !1
    },
    /**
     * Allowed file types for selection
     */
    allowedFileTypes: {
      type: Array,
      default: /* @__PURE__ */ a(() => ["*"], "default")
      // By default, allow all file types
    }
  },
  setup() {
    return {
      input: p(null)
    };
  },
  data: /* @__PURE__ */ a((e) => ({
    hasDragEntered: !1,
    files: Array(),
    $cypress: d({ ctx: e, labelField: "label" })
  }), "data"),
  computed: {
    hasFiles() {
      return this.files.length !== 0;
    },
    instruction() {
      return f.webApi.fileReader ? "Select or drag and drop file" : "Select a file";
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("file-field", m);
  },
  mounted() {
    try {
      Array.isArray(this.value) && !this.value.isEmpty() && (this.addFiles(this.value), this.touched = !1);
    } catch {
    }
    ["dragenter", "dragover", "dragleave", "drop"].forEach((e) => {
      this.$refs.refDropZone.addEventListener(
        e,
        this.dragHandler,
        !1
      );
    });
  },
  beforeUnmount() {
    ["dragenter", "dragover", "dragleave", "drop"].forEach((e) => {
      this.$refs.refDropZone.removeEventListener(
        e,
        this.dragHandler,
        !1
      );
    });
  },
  methods: {
    inputHandler(e) {
      this.addFiles(e.target.files || void 0);
    },
    dragHandler(e) {
      var t;
      e.preventDefault(), e.stopPropagation(), this.hasDragEntered = ["dragenter", "dragover"].includes(e.type), e.type === "drop" && this.addFiles((t = e.dataTransfer) == null ? void 0 : t.files);
    },
    addFiles(e) {
      const t = new DataTransfer();
      Array.from(e ?? []).concat(this.files).unique().forEach((i, r) => {
        this.isSingle && r !== ((e == null ? void 0 : e.length) ?? 0) - 1 || t.items.add(i);
      }), this.input.files = t.files, this.computedValue = Array.from(t.files), this.files = Array.from(t.files), this.touched = !0, this.validate(this.files);
    },
    getFileSize(e) {
      const t = e.size;
      if (t == null)
        return null;
      if (t === 0)
        return "0 Byte";
      const i = ["Bytes", "KB", "MB", "GB", "TB"], r = Math.floor(Math.log(t) / Math.log(1024));
      return Math.round(t / Math.pow(1024, r)) + " " + i[r];
    },
    clear(e) {
      const t = new DataTransfer();
      Array.from(this.files).forEach((i, r) => {
        e !== r && t.items.add(i);
      }), this.input.files = t.files, this.computedValue = Array.from(t.files), this.files = Array.from(t.files), this.touched = !0, this.validate(this.files);
    }
  }
});
export {
  M as _
};
