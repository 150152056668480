// DefaultBrandedKinvaultReferralCode

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk/model/default-branded-kinvault-referral-code";

export const DeleteDefaultBrandedKinvaultReferralCode = DefineDeleteAction({
  interface: "portal",
  resource:
    "/v2/portal/admin/branded-kinvault/default-branded-kinvault-referral-code/{defaultBrandedKinvaultReferralCode}",
  method: "delete",
  parse: (
    defaultBrandedKinvaultReferralCode:
      | DefaultBrandedKinvaultReferralCode
      | string,
  ) => ({
    params: {
      defaultBrandedKinvaultReferralCode,
    },
  }),
  after: ({ message }) => {
    DefaultBrandedKinvaultReferralCode.$delete(message);
  },
});
