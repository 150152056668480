var h = Object.defineProperty;
var s = (t, i) => h(t, "name", { value: i, configurable: !0 });
import l from "../../_vue/component.display/progress/progress.js";
import a from "../../_vue/component.input/button/button.js";
import u from "../../component.mixin/default-slot-mixin.js";
import { TranslationMixin as c } from "../../component.mixin/translation.mixin.js";
import { CypressHelper as m } from "../../cypress/component-helpers.js";
import { ThemeColor as S } from "../../theme/prop/color.js";
import { ThemeDirection as n } from "../../theme/prop/direction.js";
import { ThemeSize as p } from "../../theme/prop/size.js";
import { defineComponent as f } from "vue";
import { S as d } from "../../_assets/component.display/wizard/wizard.module.js";
const W = f({
  name: "WizardComponent",
  components: { Progress: l, Button: a },
  mixins: [
    u,
    c({
      defaults: {
        previousStep: "Previous Steps",
        step: /* @__PURE__ */ s((t) => `Step - ${t + 1}`, "step"),
        nextStep: "Next Steps"
      }
    })
  ],
  props: {
    hideProgessBar: {
      type: Boolean,
      default: !1
    },
    /**
     * Fired when the user select a step icon, return false to prevent the use changing to that tab
     */
    canShowStep: {
      type: Function,
      default: null
    },
    /**
     * Theme color to style with wizard with
     */
    color: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ s((t) => Object.values(S).includes(t), "validator")
    },
    /**
     * Configuration settings for the steps
     */
    config: {
      type: Array,
      required: !0
    },
    /**
     * Size of the steps
     */
    size: {
      type: String,
      default: p.isNormal,
      validator: /* @__PURE__ */ s((t) => Object.values(p).includes(t), "validator")
    },
    /**
     * The current step (v-model:step)
     */
    step: {
      type: Number,
      default: null
    },
    direction: {
      type: String,
      default: n.isHorizontal,
      validator: /* @__PURE__ */ s((t) => Object.values(n).includes(t), "validator")
    }
  },
  emits: ["update:step"],
  data: /* @__PURE__ */ s((t) => ({
    $cypress: m({ ctx: t }),
    stepSize: 0,
    //width or height of each step
    visibleSize: 0,
    navStep: 0,
    navButtonSize: 0,
    //width or height of the nav buttons
    numVisibleSteps: 0,
    windowWidth: null
  }), "data"),
  computed: {
    compIsHorizontal() {
      return this.windowWidth === null || this.windowWidth > 769 ? this.direction === n.isHorizontal : !0;
    },
    compIncrement() {
      return this.numVisibleSteps && this.numVisibleSteps > 1 ? Math.floor(this.numVisibleSteps / 2) : 1;
    },
    compNumSteps() {
      return this.config.length;
    },
    compProgressBarStyle() {
      const t = this.stepSize / 2 + "px";
      return this.compIsHorizontal ? {
        top: "",
        bottom: "",
        left: t,
        right: t
      } : {
        right: "",
        left: "",
        top: this.stepSize / 2 + "px"
      };
    },
    compShowNavButtons() {
      return this.compNumSteps > this.numVisibleSteps;
    },
    compWizardStepsStyle() {
      return this.compIsHorizontal ? {
        transform: `translateX(-${this.stepSize * this.navStep}px)`,
        minWidth: this.stepSize * this.compNumSteps + "px",
        marginLeft: this.compShowNavButtons ? this.navButtonSize + "px" : null,
        marginRight: this.compShowNavButtons ? this.navButtonSize + "px" : null
      } : {
        transform: `translateY(-${this.stepSize * this.navStep}px)`,
        //minHeight: this.stepSize * this.compNumSteps + "px",
        marginTop: this.compShowNavButtons ? this.navButtonSize + "px" : null,
        marginBottom: this.compShowNavButtons ? this.navButtonSize + "px" : null
      };
    },
    currentStep: {
      get() {
        return this.step === null ? 0 : this.step;
      },
      set(t) {
        this.$emit("update:step", t);
      }
    },
    slots() {
      return Object.keys(this.$slots).filter((t) => !t.startsWith("_") && t !== "default").map((t) => t);
    },
    classes() {
      var i, e;
      const t = {
        [this.size]: !0,
        [this.color]: !0,
        "is-horizontal": this.compIsHorizontal,
        "is-vertical": !this.compIsHorizontal,
        "last-step-labelled": ![
          (i = this.config.last()) == null ? void 0 : i.title,
          (e = this.config.last()) == null ? void 0 : e.message
        ].includes(void 0)
      };
      return Object.keys(t).filter((o) => t[o]).join(" ");
    }
  },
  watch: {
    compIsHorizontal() {
      this.Setup();
    },
    step() {
      this.Setup();
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("wizard", d);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.Setup);
  },
  mounted() {
    this.Setup(), window.addEventListener("resize", this.Setup);
  },
  methods: {
    clickNext() {
      this.navStep = Math.min(
        this.navStep + this.compIncrement,
        this.compNumSteps - this.numVisibleSteps
      );
    },
    clickPrev() {
      this.navStep = Math.max(0, this.navStep - this.compIncrement);
    },
    goToStep(t) {
      (this.canShowStep === null || this.canShowStep(t)) && (this.currentStep = t);
    },
    GetButtonText(t, i) {
      return this.GetButtonIcon(t, i) !== void 0 || typeof i.icon == "object" ? null : (t + 1).toString();
    },
    GetButtonColor(t, i) {
      const e = this.step >= t;
      return typeof i.color == "string" && e ? i.color ?? void 0 : typeof i.color == "object" && i.color !== null ? e ? i.color.visited ?? void 0 : i.color.default ?? void 0 : typeof i.color == "function" ? i.color() ?? void 0 : e ? this.color : void 0;
    },
    GetButtonIcon(t, i) {
      const e = this.step >= t;
      if (i.icon !== null) {
        if (typeof i.icon == "string")
          return i.icon ?? void 0;
        if (typeof i.icon == "object")
          return e ? i.icon.visited ?? void 0 : i.icon.default ?? void 0;
      }
    },
    async SetVisibleSize() {
      await this.$nextTick();
      const t = this.$refs.refStepsWrapper, i = this.$refs.refWizardStep, e = this.$refs.refPrevButton;
      if (i === void 0) return;
      i.forEach((r) => {
        r.style.width = "", r.style.height = "";
      });
      const o = this.navStep;
      this.navStep = 0, await this.$nextTick(), this.navButtonSize = this.compIsHorizontal ? e ? e.offsetWidth : 0 : e ? e.offsetHeight : 0, this.visibleSize = this.compIsHorizontal ? t.offsetWidth - this.navButtonSize * 2 : t.offsetHeight, this.stepSize = this.compIsHorizontal ? i[0].offsetWidth : i[0].offsetHeight, await this.$nextTick(), this.numVisibleSteps = Math.min(
        Math.floor(this.visibleSize / this.stepSize),
        this.compNumSteps
      ), await this.$nextTick(), this.stepSize = this.visibleSize / this.numVisibleSteps, this.navStep = o;
    },
    async Setup() {
      if (this.windowWidth = window.innerWidth, await this.SetVisibleSize(), this.compNumSteps <= this.numVisibleSteps) {
        this.navStep = 0;
        return;
      }
      if (this.navStep + this.numVisibleSteps - 1 < this.step) {
        this.navStep = this.step - this.numVisibleSteps + 1;
        return;
      }
      this.step < this.navStep && (this.navStep = this.step), this.navStep + this.numVisibleSteps > this.compNumSteps && (this.navStep = this.compNumSteps - this.numVisibleSteps);
    }
  }
});
export {
  W as _
};
