<template>
  <div class="pdf-utils">
    <PageHeader htag="h1" text="PDF Utilities" />
    <Card title="Available PDFs">
      <p v-for="pdf in sortedAttachments" :key="pdf.id">
        <a
          :href="`https://static.kinherit.co.uk/files/salesaids/${pdf.value}`"
          target="_blank"
          >{{ pdf.text }}</a
        >
        <small class="is-block">{{ pdf.value }}</small>
      </p>
    </Card>
  </div>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { PdfOptionsRoute } from ".";

import Card from "@kinherit/framework/component.layout/card";
import { Option } from "@kinherit/sdk";

export default defineComponent({
  name: PdfOptionsRoute,
  components: {
    PageHeader,
    Card,
  },
  mixins: [AuthService.mixin({ sessionRequired: true })],
  data() {
    return {
      attachments: Option.$findBy({
        group: "preloadedEmailAttachments",
      }),
    };
  },
  computed: {
    sortedAttachments() {
      // if text contains [DEPRECATED] put at the bottom, otherwise sort by name
      const sorted = this.attachments;
      return sorted.sort((a, b) => {
        if (
          a.text.includes("[DEPRECATED]") &&
          !b.text.includes("[DEPRECATED]")
        ) {
          return 1;
        } else if (
          !a.text.includes("[DEPRECATED]") &&
          b.text.includes("[DEPRECATED]")
        ) {
          return -1;
        } else {
          return a.text.localeCompare(b.text);
        }
      });
    },
  },
});
</script>
