// IntroducerFeePaymentItem, IntroducerFeePaymentRun, IntroducerFeePayment

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { IntroducerFeePaymentItem } from "@kinherit/sdk/model/introducer-fee-payment-item";

export const DeleteIntroducerFeePaymentItem = DefineDeleteAction({
  interface: "portal",
  resource:
    "/v2/portal/introducer/fees/payment-item/{introducerFeePaymentItem}",
  method: "delete",
  parse: (introducerFeePaymentItem: IntroducerFeePaymentItem | string) => ({
    params: {
      introducerFeePaymentItem,
    },
  }),
  after: ({ message }) => {
    IntroducerFeePaymentItem.$delete(message);
  },
});
