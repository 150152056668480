// IntroducerFeePayment, IntroducerFeePaymentItem, XeroContact

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerFeePayment } from "@kinherit/sdk/model/introducer-fee-payment";

export const ReadIntroducerFeePayment = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/fees/payment",
  method: "get",
  rootModel: IntroducerFeePayment,
  parse: () => ({}),
});
