var O = Object.defineProperty;
var l = (i, r) => O(i, "name", { value: r, configurable: !0 });
import { ValidationType as T } from "../validation/core/validation-type.js";
import { ObjectValidator as V } from "../validation/object-validator.service.js";
const I = /* @__PURE__ */ l((i, r) => {
  Object.values(i.components ?? {}).forEach((b) => {
    Object.values(b).forEach((m) => {
      m.forEach((t) => {
        var a, n, s, p, d, v, c, u, f;
        if (t == null)
          return;
        const o = t.bindings(r, i, {
          incrementTemplateRenderKey: /* @__PURE__ */ l(() => {
          }, "incrementTemplateRenderKey"),
          templateScope: {}
        });
        if ("vIf" in o && o.vIf === !1)
          return;
        const e = t;
        if (((a = e.models) == null ? void 0 : a.value) === void 0 || !((n = e.component) != null && n.name.endsWith("Field")))
          return;
        const h = e.getValue(r, i, {
          incrementTemplateRenderKey: /* @__PURE__ */ l(() => {
          }, "incrementTemplateRenderKey"),
          templateScope: {},
          rebuildTemplateBindings: /* @__PURE__ */ l(() => {
          }, "rebuildTemplateBindings")
        }), g = (typeof ((s = e.props) == null ? void 0 : s.label) == "function" ? (p = e.props) == null ? void 0 : p.label(r) : (d = e.props) == null ? void 0 : d.label) ?? "", y = ((typeof ((v = e == null ? void 0 : e.props) == null ? void 0 : v.validators) == "function" ? (c = e.props) == null ? void 0 : c.validators(r) : (u = e.props) == null ? void 0 : u.validators) ?? []).concat((f = e == null ? void 0 : e.props) == null ? void 0 : f.validators);
        t.internalSettings.hideOverride = new V([
          {
            label: g,
            value: h,
            validators: y
          }
        ]).validate(r)[0].result.filter(
          (E) => E.type === T.Error
        ).length === 0;
      });
    });
  });
}, "hideValidFormFields");
export {
  I as hideValidFormFields
};
