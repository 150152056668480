var b = Object.defineProperty;
var v = (i) => {
  throw TypeError(i);
};
var D = (i, e, t) => e in i ? b(i, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : i[e] = t;
var l = (i, e) => b(i, "name", { value: e, configurable: !0 });
var r = (i, e, t) => D(i, typeof e != "symbol" ? e + "" : e, t), A = (i, e, t) => e.has(i) || v("Cannot " + t);
var S = (i, e, t) => (A(i, e, "read from private field"), t ? t.call(i) : e.get(i)), y = (i, e, t) => e.has(i) ? v("Cannot add the same private member more than once") : e instanceof WeakSet ? e.add(i) : e.set(i, t), k = (i, e, t, o) => (A(i, e, "write to private field"), o ? o.call(i, t) : e.set(i, t), t);
import { DefineActionBus as O } from "../action-bus/bus/index.js";
import { KeepOnScreenDirective as I } from "./directives/keep-on-screen.js";
import { TabIndexDirective as P } from "./directives/tab-index.js";
import { KeyListener as L } from "./key-listener.js";
import { Global as V } from "../global/global.js";
import { CurrencyExchange as x } from "../service/currency-exchange.js";
import { Theme as K } from "../theme/theme.js";
import { Store as T } from "@kinherit/orm";
import { Api as C } from "@kinherit/sdk";
import { Uuid as f } from "@kinherit/ts-common";
import * as m from "@sentry/vue";
import { markRaw as M } from "vue";
import { ComponentOptions as j } from "./component-options.js";
import { FormPlugin as N } from "./dev-tools/form-plugin.js";
import { TimelinePlugin as F } from "./dev-tools/timeline-plugin.js";
import { ClickOutsideDirective as G } from "./directives/click-outside.js";
import { ResizeObserverDirective as U } from "./directives/resize-observer.js";
import { ToolTipDirective as $ } from "./directives/tool-tip.js";
import { KernelModes as c } from "./kernel-mode.js";
import { Logger as _ } from "./logger.js";
var H = { VERSION: "44.9.11" };
const E = {
  ToolTip: $,
  ClickOutside: G,
  KeepOnScreen: I,
  TabIndex: P,
  ResizeObserver: U
};
var p, d;
const a = class a {
  constructor(e) {
    r(this, "Theme");
    /** @internal */
    r(this, "ComponentOptions");
    /** @internal */
    r(this, "_StripeKey");
    /** @internal */
    r(this, "_FrameworkUrl");
    r(this, "_SentryBeforeSend");
    r(this, "Router");
    r(this, "Mode");
    r(this, "Global");
    r(this, "CurrencyExchange");
    r(this, "Store");
    r(this, "GenerateTemplate");
    r(this, "Api");
    r(this, "KeyListener");
    r(this, "FrameworkVersion", H.VERSION);
    y(this, p);
    y(this, d, f.generate());
    r(this, "KeyboardNavigation", !1);
    r(this, "APP_VERSION");
    r(this, "ActionBus");
    r(this, "ActionBus2");
    r(this, "Locale", Intl.DateTimeFormat().resolvedOptions().locale);
    r(this, "sentryRecord");
    /**
     * A guard around console.log to prevent logs being displayed in production mode
     */
    r(this, "Logger", _);
    /**
     * @internal
     */
    r(this, "routerVisitRoute");
    r(this, "injectStylesheet", /* @__PURE__ */ l((e, t) => {
      var o;
      if (a.injectedStylesheets.includes(e)) {
        if (this.Mode !== c.Dev)
          return;
        (o = window.document.querySelector(`style[data-id="${e}"]`)) == null || o.remove();
      }
      this.Logger.log("Core", "debug", `Injecting stylesheet '${e}'`), a.injectedStylesheets.push(e);
      try {
        const h = document.head || document.getElementsByTagName("head")[0], n = document.createElement("style");
        n.setAttribute("data-id", e), n.innerHTML = t, h.appendChild(n);
      } catch {
      }
    }, "injectStylesheet"));
    r(this, "setSiteTitle", /* @__PURE__ */ l((e) => {
      document.title = e;
    }, "setSiteTitle"));
    this.ActionBus = e.ActionBus ?? O({
      name: `Root-${f.generate()}`,
      actions: {}
    }), this.ActionBus2 = e.ActionBus2 ?? O({
      name: `Root-${f.generate()}`,
      actions: {}
    }), this.sentryRecord = e.SentryRecord, this._SentryBeforeSend = e.SentryBeforeSend;
    const t = localStorage.getItem("app-version");
    t !== e.APP_VERSION && t !== null && t !== "undefined" && (e.ResetState ? e.ResetState() : (localStorage.clear(), sessionStorage.clear(), T.purge())), localStorage.setItem("app-version", e.APP_VERSION), this.Store = T, k(this, p, e.SentryDsn), C.additionalHeaders["x-transaction-id"] = S(this, d), e.Mode === c.Live && (this.Logger.silent = !0), this.APP_VERSION = e.APP_VERSION, this.Api = C, this._FrameworkUrl = e.FrameworkUrl, window.Kernel = M(this), this.CurrencyExchange = new x(), this.Theme = new K(), this.Mode = e.Mode, this.Router = e.Router, this.routerVisitRoute = e.Router.push, this.Global = new V(e.Dialog ?? {}), this.ComponentOptions = new j(e.Components), this.GenerateTemplate = null, e.Mode === c.Dev && import("../cypress/generate-template.js").then((o) => {
      this.GenerateTemplate = o.GenerateTemplate;
    }), this._StripeKey = e.StripeKey, this.KeyListener = new L();
  }
  async init(e, t) {
    var h, n;
    m.init({
      app: e,
      dsn: S(this, p),
      enabled: this.Mode === c.Live,
      integrations: [
        m.browserTracingIntegration({
          router: this.Router
        })
      ].concat(
        this.sentryRecord ? [
          m.replayIntegration({
            maskAllText: !1,
            blockAllMedia: !1,
            networkDetailAllowUrls: [
              window.location.origin,
              "api.kinherit.co.uk"
            ]
          })
        ] : []
      ),
      // Tracing
      trackComponents: !0,
      hooks: ["create", "mount"],
      // Performance Monitoring
      tracesSampleRate: 1,
      //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/api.kinherit\.co\.uk\//
      ],
      // Session Replay
      replaysSessionSampleRate: 0,
      // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0.7,
      // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      // https://gist.github.com/impressiver/5092952
      ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Kinherit specific
        "Dialog rejected"
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i,
        // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
      ],
      beforeSend: /* @__PURE__ */ l((s, g) => {
        var w;
        if (this._SentryBeforeSend) {
          const R = this._SentryBeforeSend(s, g, m);
          R && (s = R);
        }
        const u = g.originalException;
        if (u != null && u.isAxiosError)
          switch ((w = u.response) == null ? void 0 : w.status) {
            case 400:
            // Bad Request
            case 401:
            // Unauthorized
            case 403:
            // Access Denied
            case 404:
            // Not Found
            case 410:
              s.level = "info";
              break;
            case 500:
              s.level = "error";
              break;
            default:
              s.level = "warning";
              break;
          }
        return s.transaction = S(this, d), s;
      }, "beforeSend")
    });
    const o = (h = document.getElementById("app")) == null ? void 0 : h.getAttribute("data-datetime");
    (n = m.getCurrentScope()) == null || n.setTags({
      "framework-version": this.FrameworkVersion,
      "app-datetime": o ?? ""
    }), e.use(this.Router), Object.keys(E).forEach((s) => {
      e.directive(s, E[s]);
    }), this.Mode !== c.Live && (new F(e), new N(e)), e.mount("#app"), t ? (await t(), this.ComponentOptions.App.setReady()) : this.ComponentOptions.App.setReady();
  }
  get StripeKey() {
    if (typeof this._StripeKey != "string")
      throw new Error("Please provide the kernel with a StripeKey value");
    return this._StripeKey;
  }
  visitRoute(e, t) {
    if (_.log("Core", "debug", `Visiting route '${e.name}'`), t) {
      const { href: o } = this.Router.resolve(e);
      return window.open(o, "_blank"), Promise.resolve();
    }
    return this.routerVisitRoute(e);
  }
};
p = new WeakMap(), d = new WeakMap(), l(a, "Kernel"), r(a, "injectedStylesheets", []);
let B = a;
export {
  E as Directives,
  B as Kernel
};
