<template>
  <div v-if="kintin">
    <CallScriptSection title="Reason for call and outlined of process">
      <b
        >Great. So let’s talk a bit about process. To sort your lasting powers
        of attorney, we will need to understand who your attorneys will be, and
        discuss how you would like the powers to work. <br /><br />
        This will allow us to produce a set of documents that get signed by your
        you, your attorneys, a certificate provider and witnesses.
        <br /><br />
        We then check that document, as it is not the most straightforward, and
        assuming you want it registered with the Office of the Public Guardian
        we will manage that as well. Does that sound ok?</b
      >
    </CallScriptSection>
    <CallScriptSection title="Customer Details">
      <b>
        <span v-if="isJoint"> So for {{ primarySalutation }}, </span>
        Can I take your date of birth and address?
        <br /><br />
      </b>
      <PersonList
        :key="primaryPersonRenderKey"
        :kintin="kintin"
        :people="[primaryPerson]"
        @reload="primaryPersonRenderKey++"
      />
      <span v-if="isJoint">
        <br /><br />
        <b
          >And for {{ secondarySalutation ?? "your partner" }}, I assume you're
          at the same address - can I just get your date of birth, email,
          telephone</b
        >
        <br /><br />
        <PersonList
          v-if="secondaryPerson"
          :key="secondaryPersonRenderKey"
          :kintin="kintin"
          :people="[secondaryPerson]"
          @reload="secondaryPersonRenderKey++"
        />
      </span>
      <b>
        This is probably obvious, but as you live in [England] it would be an
        [English] law Will that we are talking about
      </b>
      <br /><em>[nb Wales=English law / Scotland=Scottish law]</em> <br /><em
        >[Specialist to add note if Scotland or Northern Ireland]</em
      >
      <br /><br />
      <ConfirmCheck
        :kintin="kintin"
        step="2.4.4b"
        :kintin-checks="kintinChecks"
        :checks="checks"
        is-reversed
        @update="updateCheckValue"
      />
      <br />
      <b>
        And any documents we send will be to your address, so please let us know
        if you're intending to move.
      </b>
      <br /><br />
      <ConfirmCheck
        :kintin="kintin"
        step="2.4.1"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin="kintin"
        step="2.4.2"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin="kintin"
        step="2.4.3"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: "ClientDetailsTab",
  components: {
    CallScriptSection,
    ConfirmCheck,
    PersonList,
  },
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  data: () => ({
    renderKey: 0,
    person2Present: null as boolean | null,
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
});
</script>
