// Order, Kintin, AccountReferral, AccountReferralCode, Profile, User

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Order } from "@kinherit/sdk/model/order";

export const ReadOrder = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/fees/order",
  method: "get",
  rootModel: Order,
  parse: () => ({}),
});
