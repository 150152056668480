import { RouteInterface } from "@kinherit/framework/core/route-config";
import {
  OrderMasterListBreadCrumb,
  OrderRefundRequestMasterListBreadCrumb,
} from "../breadcrumbs";
import { Routes as OrderDetailsRoutes } from "./details";

export const OrderMasterListRoute = "OrderMasterList";
export const OrderRefundRequestMasterListRoute = "OrderRefundRequestMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: OrderMasterListRoute,
    path: "/orders",
    component: () =>
      import(/* webpackChunkName: "OrderMasterList" */ "./OrderMasterList.vue"),
    meta: {
      breadcrumbs: () => [OrderMasterListBreadCrumb],
    },
  },
  {
    name: OrderRefundRequestMasterListRoute,
    path: "/orders/refund-request",
    component: () =>
      import(
        /* webpackChunkName: "OrderRefundRequestMasterList" */ "./OrderRefundRequestMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => OrderRefundRequestMasterListBreadCrumb,
    },
  },
  ...OrderDetailsRoutes,
];
