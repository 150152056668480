import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateExecutor } from "./create-executor.action";
import { DeleteExecutor } from "./delete-executor.action";
import { ReadExecutor } from "./read-executor.action";
import { RecordExecutor } from "./record-executor.action";
import { UpdateExecutor } from "./update-executor.action";

export const Portal2KinvaultExecutor = DefineActionBus({
  name: "portal-kinvault-executor",
  actions: {
    CreateExecutor,
    DeleteExecutor,
    ReadExecutor,
    UpdateExecutor,
    RecordExecutor,
  },
});
