// IntroducerContact, Profile, EmailAddress, PhoneNumber

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";

export const CreateIntroducerContact = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/contact",
  method: "post",
  parse: (target: IntroducerContact) => ({
    body: {
      credential: target.$data.credential,
      profile: {
        newsletterCampaigns: target.profile.$data.newsletterCampaigns,
        firstName: target.profile.$data.firstName,
        lastName: target.profile.$data.lastName,
        jobTitle: target.profile.$data.jobTitle,
        emails: target.profile.emails.map((email) => ({
          email: email.email,
          primary: email.primary,
        })),
        phoneNumbers: target.profile.phoneNumbers.map((phoneNumber) => ({
          tel: phoneNumber.tel,
          primary: phoneNumber.primary,
        })),
      },
      assistantProfile: target.assistantProfile
        ? {
            firstName: target.assistantProfile.$data.firstName,
            lastName: target.assistantProfile.$data.lastName,
            emails: target.assistantProfile.emails.map((email) => ({
              email: email.email,
              primary: email.primary,
            })),
            phoneNumbers: target.assistantProfile.phoneNumbers.map(
              (phoneNumber) => ({
                tel: phoneNumber.tel,
                primary: phoneNumber.primary,
              }),
            ),
          }
        : null,
      status: target.$data.status,
      callStatus: target.$data.callStatus,
      companies: target.companies.pluck("id"),
    },
  }),
  after: ({ message }) => {
    message.$delete({
      profile: {
        emails: true,
        phoneNumbers: true,
      },
    });
  },
});
