<template>
  <div v-if="kintin">
    <CallScriptSection title="Configure Lasting Power of Attorney">
      <span v-if="checkIsNo('4.7.1e')" class="has-text-danger">
        LPAs not enabled<br /><br />
      </span>
      <AttorneysContainer v-if="checkIsYes('4.7.1e')" :kintin="kintin" />
      <b v-if="checkIsYes('4.7.1e')">
        To set up a power of attorney, we will also need someone to vouch for
        you – which means they are confirming you know what you are doing, are
        capable of making decisions, that sort of thing.
        <br /><br />
        There is a long list of people that can't vouch for you, so no relatives
        or employees. They also need to have known you for at least 2 years,
        which is why people normally pick friends or neighbours. It can also be
        a doctor, but that is typically where there is a question-mark about
        capacity. The role is called Certificate Provider … do you have an idea
        who you might use? … it would be great to add it now
        <br /><em>[Add above as appropriate]</em> <br /><br />
      </b>
      <b v-if="checkIsYes('3.0.8a')">
        Just to flag, you mentioned you have business assets. A Finance LPA
        would also cover them, … or do you think you could want different
        Attorneys?
        <br /><em
          >[SPECIALIST: if a business LPA is needed, add note + mention in box
          to output to 4.1]
        </em>
        <br /><br />
      </b>
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1"
        :kintin="kintin"
        @update="updateCheckValue"
      />
      <span v-if="checkIsYes('4.7.1e')">
        <br />
        <b>
          To set you your LPA in the way you want, there are a couple of
          questions:
        </b>
        <br />
      </span>
      <ConfirmCheck
        v-if="checkIsYes('4.7.1e')"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1a"
        :kintin="kintin"
        :on="{
          yes: {
            ask: [
              'We recommend Joint and Several so that your LPA can still work even if one of your attorneys is unable to act. Otherwise everything essentially needs both to confirm which is a lot less practical. ',
            ],
          },
          no: {
            action: [
              'Decide: Jointly for some decisions OR jointly and severally for other decisions: NB this will NOT be reflected in the generated LPA (for now)',
            ],
            ask: [
              'There is a 3rd option to appoint some attorneys jointly on some decisions and severally on others, however the OPG does not recommend this we would want to make sure you took legal advice on it.',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        v-if="checkIsYes('4.7.1e')"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1b"
        :kintin="kintin"
        :on="{
          yes: {
            ask: [
              'If you choose this option, your attorneys can speak to doctors on your behalf as if they were you.',
            ],
          },
          no: {
            ask: [
              'If you choose this option, your doctors will take into account the views of the attorneys and of people who are interested in your welfare as well as any written statement you may have made, where it is practical and appropriate.',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        v-if="checkIsYes('4.7.1e')"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1c"
        :kintin="kintin"
        :on="{
          yes: {
            say: [
              'Giving authority upfront is normal to avoid an Attorney having to prove incapacitation every time, and this option is even encouraged on the LPA form. But for completeness, I need to highlight that there is a risk your Attorneys abuse that trust …. And we do have customers who revoke these powers when circumstances change.',
            ],
          },
          no: {
            ask: [
              'Only when I don’t have mental capacity: Be careful – this can make your LPA a lot less useful. Your attorneys might be asked to prove you do not have mental capacity each time they try to use this LPA.',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        v-if="checkIsYes('4.7.1e')"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1f"
        :kintin="kintin"
        :on="{
          yes: {
            say: ['Can I check the best number to give to the OPG?'],
            action: [
              'Write a note detailing the telephone number(s) and whether one person is paying for both or seperately.',
            ],
          },
          no: {
            say: [
              'Details of who to make the cheque out to will be in the cover letter we send',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <span v-if="checkIsYes('4.7.1e')">
        <em>
          [Specialist be aware: Donors can write additional PREFERENCES or
          INSTRUCTIONS for how powers should be applied. If these exist, write a
          note and make the comments directly onto the LPA output.]
        </em>
      </span>
      <span v-if="checkIsYes('4.7.1e')">
        <b>
          Great. We have enough to structure your documents.
          <br /><br />
          One final point is about process. LPA forms involve quite a lot of
          signing. You need to sign bits, your certificate provider signs, your
          Attorneys all sign, you need to sign again. And all these signings
          need witnessing which is a bit of a pain.
          <br /><br />
          We give detailed signing instructions to follow, but we have found a
          bit of guidance directly to Attorneys can help.
          <br /><br />
          So assuming you are happy with this, when your LPA docs get posted to
          you, we will also send your Attorneys some basic info to help with
          their signing bit.
        </b>
      </span>
      <ConfirmCheck
        v-if="checkIsYes('4.7.1e')"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1i"
        :kintin="kintin"
        @update="updateCheckValue"
      />
      <span v-if="checkIsYes('4.7.1e')">
        <br />
        <em>
          [Specialist to ask customer if they may have to look after someone
          else and would benefit from having an LPA in place. Write a follow up
          note if yes].
        </em>
      </span>
      <ConfirmCheck
        v-if="checkIsYes('4.7.1e')"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.3.1"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <!-- <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advAttorneyenNotes"
      :has-continue="false"
    /> -->
  </div>
</template>

<script lang="ts">
import AttorneysContainer from "@/module/kinvault.kintin/component/address-book/AttorneysContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { KintinDetailsCallScript2Call2LPAConfigureRoute } from "@/module/kinvault.kintin/page/details/call-script-2";
import { defineComponent } from "vue";

export default defineComponent({
  name: KintinDetailsCallScript2Call2LPAConfigureRoute,
  components: {
    ConfirmCheck,
    CallScriptSection,
    // CallScriptSpecialistNotesSection,
    AttorneysContainer,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
});
</script>
