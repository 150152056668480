var I = Object.defineProperty;
var $ = (t, s) => I(t, "name", { value: s, configurable: !0 });
import * as n from "@kinherit/sdk";
const N = /* @__PURE__ */ $((t) => async (s) => {
  var m, c, l, d;
  const o = t.parse(s, {}), b = "params" in o ? o.params : {}, p = n.Api.resource(
    t.interface,
    t.resource,
    b
  ).method(t.method);
  p.body(o.body ?? {});
  const e = await p.result(), r = {};
  e.$pagination && (r.$pagination = {
    currentPage: Number.parseInt(((m = e.$pagination) == null ? void 0 : m.currentPage) ?? "1"),
    lastPage: Number.parseInt(((c = e.$pagination) == null ? void 0 : c.lastPage) ?? "0"),
    count: Number.parseInt(((l = e.$pagination) == null ? void 0 : l.count) ?? "0")
  }), e.$rootIds && (r.$meta = e.$rootIds);
  const u = Object.values(n).pluck("$name").filter(Boolean).cast(), i = {};
  for (const a of u)
    i[a] = n[a];
  for (const a in e)
    u.includes(a.ucFirst()) && (r[a] = i[a.ucFirst()].$inflate(e[a]));
  return await ((d = t.after) == null ? void 0 : d.call(t, {
    message: s,
    response: r
  })), t.map ? t.map(r) : r;
}, "DefineWriteAction");
export {
  N as DefineWriteAction
};
