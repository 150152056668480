import { Api } from "@/service/api.service";
import { IntroducerFeePayment, IntroducerFeePaymentRun } from "@kinherit/sdk";

export interface DownloadIntroducerFeePaymentRemittanceMessage {
  introducerFeePaymentRun: IntroducerFeePaymentRun;
  introducerFeePayment: IntroducerFeePayment;
  downloadType:
    | "comp-adv-cli-pro"
    | "comp-adv-cli"
    | "comp-adv"
    | "comp"
    | "client-by-adviser"
    | "client-only"
    | "amount-only"
    | "flying-colours"
    | "sort-refer";
}

export const DownloadRemittance = async (
  message: DownloadIntroducerFeePaymentRemittanceMessage,
): Promise<void> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer/fees/remittance/{introducerFeePayment}/{downloadType}",
    message,
  ).method("get");

  await request.download({
    encoding: "application/pdf",
    fileName: `Kinherit Remittance ${message.introducerFeePaymentRun.name} - ${message.introducerFeePayment.xeroContact.contactName}.pdf`,
  });
};
