import { EmailCampaignLog } from "@kinherit/sdk";
import { Api } from "@kinherit/sdk/api";

// EmailCampaignLog, FileLog

export const UploadAttachment = async (message: {
  emailCampaignLog: EmailCampaignLog;
  files: Array<File>;
}): Promise<void> => {
  for (const file of message.files) {
    await Api.resource(
      "portal",
      "/v2/portal/email-campaign/log/{emailCampaignLog}/attachment",
      {
        emailCampaignLog: message.emailCampaignLog,
      },
    )
      .method("put")

      .files({
        uploadedFile: file,
      })
      .result();
  }
};
