var m = Object.defineProperty;
var t = (o, e) => m(o, "name", { value: e, configurable: !0 });
import { _ as d } from "../../../component.display/avatar/avatar.vue_vue_type_script_lang.js";
import { resolveComponent as v, resolveDirective as u, createElementBlock as a, openBlock as s, normalizeClass as k, withDirectives as n, createCommentVNode as f, normalizeStyle as i, createTextVNode as y, toDisplayString as c, createVNode as C, renderSlot as $ } from "vue";
import { _ as b } from "../../../_plugins/_plugin-vue_export-helper.js";
const g = {
  key: 2,
  class: "avatar__label"
}, z = {
  key: 3,
  class: "avatar__label"
};
function N(o, e, _, T, D, S) {
  const p = v("Icon"), l = u("tool-tip");
  return s(), a("div", {
    class: k(["avatar", o.classes])
  }, [
    o.computedName !== "" ? n((s(), a("span", {
      key: 0,
      class: "avatar__icon",
      style: i({
        background: o.color,
        color: o.invertColor
      }),
      onClick: e[0] || (e[0] = (r) => o.$emit("click", r))
    }, [
      y(c(o.computedName), 1)
    ], 4)), [
      [l, o.tooltipText]
    ]) : n((s(), a("span", {
      key: 1,
      class: "avatar__icon",
      style: i({
        background: o.color,
        color: o.invertColor,
        paddingTop: 0
      }),
      onClick: e[1] || (e[1] = (r) => o.$emit("click", r))
    }, [
      C(p, {
        class: "empty-icon",
        icon: "Person",
        color: o.invertColor,
        "icon-style": "Regular",
        size: o.size
      }, null, 8, ["color", "size"])
    ], 4)), [
      [l, o.tooltipText]
    ]),
    o.showLabel && o.name ? (s(), a("span", g, c(o.name), 1)) : o.$slots.default ? (s(), a("span", z, [
      $(o.$slots, "default")
    ])) : f("", !0)
  ], 2);
}
t(N, "_sfc_render");
const h = /* @__PURE__ */ b(d, [["render", N]]);
export {
  h as default
};
