// IntroducerFeePaymentItem, IntroducerFeePaymentRun, IntroducerFeePayment

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerFeePaymentItem } from "@kinherit/sdk/model/introducer-fee-payment-item";

export const ReadIntroducerFeePaymentItem = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/fees/payment-item",
  method: "get",
  rootModel: IntroducerFeePaymentItem,
  parse: () => ({}),
});
