<template>
  <div class="product-master-list">
    <PageHeader htag="h1" text="Products">
      <template #buttons>
        <Button
          v-if="hasWriteAccess"
          class="create"
          size="is-normal"
          @click="createProduct"
        >
          Create Product
        </Button>
        <Button
          v-if="hasWriteAccess"
          class="update-prices"
          size="is-normal"
          @click="updatePrices"
        >
          Update Prices
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        createdAt: 'Created At',
        publicText: 'Public Text',
        text: 'Text',
        type: 'Type',
      }"
      @refresh="refresh"
    >
      <template #description="{ row }: { row: Product }">
        <div>{{ row.publicText }}</div>
        <div style="font-size: small" class="has-text-grey">
          {{ row.type.ucFirst() }}
        </div>
      </template>
      <template #dated="{ row }: { row: Product }">
        {{ formatDate(row) }}
      </template>
      <template #actions="{ row }: { row: Product }">
        <Button
          icon-left="CreditCard"
          tooltip="Manage Iterations"
          color="is-primary"
          @click="() => manageProductPrices(row)"
        />
        <Button
          icon-left="Edit"
          tooltip="Edit"
          color="is-success"
          class="ml-2"
          @click="() => editProduct(row)"
        />
        <Button
          color="is-danger"
          tooltip="Delete"
          icon-left="Trash"
          class="ml-2"
          @click="() => deleteProduct(row)"
        />
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { ReadProductForm } from "@/module/admin.product/form/read-products.form";
import { UpdateProductForm } from "@/module/admin.product/form/update-product.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Like } from "@kinherit/orm/index";
import { IProduct, Product } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  ProductManageIterationsParams,
  ProductManageIterationsRoute,
  ProductMasterListRoute,
  ProductUpdatePricesRoute,
} from ".";

export default defineComponent({
  name: ProductMasterListRoute,
  components: { MasterListPage, PageHeader, Button },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal.admin.product),
  ],
  data: () => ({
    filters: ReadProductForm(),
    columns: [
      {
        title: "Description",
        slot: "description",
      },
      {
        title: "Price",
        map: (row: Product) => row.price.format,
      },
      {
        title: "Created",
        map: (row: Product) => row.createdAt.formatYMD,
      },
      {
        title: "Dated",
        slot: "dated",
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn<Product>>,
    rows: Array<Product>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IProduct,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWriteAccess(): boolean {
      return this.$auth.hasPermission("product:write");
    },
  },
  methods: {
    formatDate(product: Product): string {
      if (product.startDate && product.endDate) {
        return `${product.startDate.formatYMD} - ${product.endDate.formatYMD}`;
      }

      if (product.startDate) {
        return `Starts ${product.startDate.formatYMD}`;
      }

      if (product.endDate) {
        return `Ends ${product.endDate.formatYMD}`;
      }

      return "No Date";
    },
    async createProduct(): Promise<void> {
      const form = UpdateProductForm();

      try {
        await form.dialog({
          dialog: {
            title: "Create Product",
          },
        });
      } catch (error) {
        form.localData.$restore();
        throw error;
      }

      await this.$actionBus.CreateProduct(form.localData);

      await this.refresh(this.filters.localData);
    },
    async editProduct(product: Product): Promise<void> {
      try {
        await UpdateProductForm(product).dialog({
          dialog: {
            title: "Edit Product",
          },
        });
      } catch (error) {
        product.$restore();
        throw error;
      }

      await this.$actionBus.UpdateProduct(product);

      await this.refresh(this.filters.localData);
    },
    async refresh(
      formData: ReturnType<typeof ReadProductForm>["localData"],
    ): Promise<void> {
      const data = await this.$actionBus.ReadProduct({
        query: {
          publicText: Like(formData.description),
          createdAt: Between(formData.created),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.product;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async deleteProduct(product: Product): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Product",
          message: `Are you sure you want to delete ${product.publicText}?`,
        },
      });

      await this.$actionBus.DeleteProduct(product);
      await this.refresh(this.filters.localData);
    },
    updatePrices(): void {
      this.$router.push({ name: ProductUpdatePricesRoute });
    },
    async manageProductPrices(product: Product): Promise<void> {
      this.$router.push({
        name: ProductManageIterationsRoute,
        params: { product: product.id } as ProductManageIterationsParams,
      });
    },
  },
});
</script>
