// Property

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Property } from "@kinherit/sdk/model/property";

export const CreateProperty = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/property",
  method: "post",
  parse: (target: Property) => ({
    body: {
      titleDeed: target.$data.titleDeed,
      price: target.$data.price,
      value: target.$data.value,
      lessMortgage: target.$data.lessMortgage,
      notes: target.$data.notes,
      kintin: target.$data.kintin,
      address: target.$data.address,
      howOwned: target.$data.howOwned,
      ownership: target.$data.ownership,
      type: target.$data.type,
      reminder: target.$data.reminder,
    },
    params: {
      kintin: target.$data.kintin,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
