// Lead, Profile, EmailAddress, PhoneNumber, AccountReferral, User, Kintin, IntroducerCompany, AccountReferralCode, IntroducerContact, Website, Address

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Lead } from "@kinherit/sdk/model/lead";

export const RecordLead = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/lead/{lead}",
  method: "get",
  rootModel: Lead,
  parse: (lead: Lead | string) => ({
    params: {
      lead,
    },
  }),
});
