<template>
  <Card :title="name" :title-size="'is-4'" class="beneficiary-summary">
    <DataListWidget
      no-card
      is-compact
      :details="[
        {
          label: 'Class Gift',
          value: {
            text: role.classGiftType?.text,
            bold: false,
          },
          hide: null === role.classGiftType,
        },
        {
          label: 'Appointed By',
          value: role.forPerson.profile.fullName,
        },
        {
          label: {
            text: position,
            bold: false,
          },
          hide: null === position,
        },
        {
          label: '-> ',
          value: {
            text:
              role.reservePerson?.profile.fullName ??
              role.reserveclassGiftType?.text,
            bold: false,
          },
          hide:
            null === role.reservePerson && null === role.reserveclassGiftType,
        },
        {
          label: 'Share',
          value: `${role.sharePercentage} %`,
          hide: null === role.sharePercentage,
        },
        {
          label: 'Share',
          value: role.shareFraction,
          hide: null === role.shareFraction,
        },
        {
          label: 'Share',
          value: 'Equal Share (no specific share percentage or fraction set)',
          hide: null !== role.sharePercentage || null !== role.shareFraction,
        },
        {
          label: 'If Predeceased, Share to Pass to',
          value: role.ifPredeceased?.text ?? {
            color: 'has-text-warning',
            text: 'Not set',
          },
        },
        {
          label: 'Organisation',
          value: role.forPerson.profile.organisationName,
          hide: null === role.forPerson.profile.organisationName,
        },
        {
          label: 'Notes',
          value: role.notes,
          hide: null === role.notes,
        },
      ]"
    />
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit"
        color="is-plain"
        @click="$emit('edit', $event)"
      />
      <Button
        icon-left="Trash"
        aria-label="Delete"
        color="is-plain"
        @click="$emit('delete', $event)"
      />
      <slot name="buttons" />
    </template>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "BeneficiarySummaryWrapper"
}
</cypress-wrapper>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { Beneficiary, Kintin } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BeneficiarySummary",
  components: { Card, DataListWidget, Button },
  props: {
    role: {
      type: Beneficiary as PropType<Beneficiary>,
      required: true,
    },
    kintin: {
      type: Kintin as PropType<Kintin>,
      required: true,
    },
  },
  emits: ["edit", "delete"],
  computed: {
    position(): string | null {
      switch (this.role.position) {
        case 1:
          return "First Line Beneficiary";
        case 2:
          return "Second Line Beneficiary";
        case 3:
          return "Reserve Beneficiary";
      }

      return null;
    },
    showHr(): boolean {
      return ![
        ...(this.role.person?.profile.addresses ?? []),
        ...(this.role.person?.profile.emails ?? []),
        ...(this.role.person?.profile.phoneNumbers ?? []),
        ...(this.role.person?.profile.websites ?? []),
      ].isEmpty();
    },
    name(): string {
      const person = this.role.person;
      const profile = person?.profile;

      if (!person || !profile) {
        return (
          this.role.classGiftType?.text ??
          this.role.reserveclassGiftType?.text ??
          ""
        );
      }

      let name = profile.fullName;

      if (person.profile.knownAs) {
        if (null === name) {
          name = person.profile.knownAs;
        } else {
          name += ` (${person.profile.knownAs})`;
        }
      }
      // NB: Might need to change this.
      if (this.role.position === 3) {
        name += " [Reserve]";
      }

      return name as string;
    },
  },
});
</script>
