import { ReassignIntroducerCompanies } from "@/action-bus/portal/introducer/company/reassign-introducer-companies.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ComputeIntroducerCompanyStats } from "./compute-introducer-company-stats.action";
import { CreateIntroducerCompany } from "./create-introducer-company.action";
import { DeleteIntroducerCompany } from "./delete-introducer-company.action";
import { DownloadIntroducerCompanyStats } from "./download-introducer-company-stats.action";
import { Portal2IntroducerCompanyPartnerPortalPermissions } from "./partner-portal-permissions";
import { ReadIntroducerCompanies } from "./read-introducer-companies.action";
import { RecordIntroducerCompany } from "./record-introducer-company.action";
import { Portal2IntroducerCompanyReferral } from "./referral";
import { ReplaceProfile } from "./replace-profile.action";
import { UpdateIntroducerCompany } from "./update-introducer-company.action";

export const Portal2IntroducerCompany = DefineActionBus({
  name: "portal-introducer-company",
  actions: {
    ReadIntroducerCompanies,
    UpdateIntroducerCompany,
    DeleteIntroducerCompany,
    RecordIntroducerCompany,
    ComputeIntroducerCompanyStats,
    DownloadIntroducerCompanyStats,
    CreateIntroducerCompany,
    UpdateIntroducerCompanyProfile: ReplaceProfile,
    ReassignIntroducerCompanies,
    referral: Portal2IntroducerCompanyReferral,
    partnerPortalPermissions: Portal2IntroducerCompanyPartnerPortalPermissions,
  },
});
