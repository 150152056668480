import { Module } from "@/config/module.config";
import { Routes } from "./page";

Module.register({
  name: "admin.branded-kinvault-cta",
  routes: Routes,
  session: true,
  permissions: [],
});

export default {};
