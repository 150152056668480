import { KinvaultKintinDetailsBreadCrumb } from "@/module/kinvault.kintin/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

import { Routes as AddressBookRoutes } from "./address-book";
import { Routes as CallScriptRoutes } from "./call-script";
import { Routes as CallScript2Routes } from "./call-script-2";
import { Routes as CallsRoutes } from "./calls";
import { Routes as ChangeLogRoutes } from "./change-log";
import { Routes as DocumentsRoutes } from "./documents";
import { Routes as EmailRoutes } from "./email-log";
import { Routes as NotesRoutes } from "./notes";
import { Routes as SummaryRoutes } from "./summary";
import { Routes as WillRoutes } from "./will";

export const KinvaultKintinDetailsRoute = "KinvaultKintinDetails";
const KinvaultKintinDetailsURI = "/kinvault/:kintin";
export type KinvaultKintinDetailsParams = RouteParamObject<
  typeof KinvaultKintinDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinDetailsRoute,
    path: KinvaultKintinDetailsURI,
    component: () =>
      import(/* webpackChunkName: "KintinDetails" */ "./KintinDetails.vue"),
    meta: {
      breadcrumbs: KinvaultKintinDetailsBreadCrumb,
    },
    children: [
      ...SummaryRoutes,
      ...ChangeLogRoutes,
      ...NotesRoutes,
      ...AddressBookRoutes,
      ...CallsRoutes,
      ...DocumentsRoutes,
      ...EmailRoutes,
      ...CallScriptRoutes,
      ...CallScript2Routes,
      ...WillRoutes,
    ],
  },
];
