import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateDefaultBrandedKinvaultReferralCode } from "./create-default-branded-kinvault-referral-code.action";
import { DeleteDefaultBrandedKinvaultReferralCode } from "./delete-default-branded-kinvault-referral-code.action";
import { ReadDefaultBrandedKinvaultReferralCode } from "./read-default-branded-kinvault-referral-code.action";
import { RecordDefaultBrandedKinvaultReferralCode } from "./record-default-branded-kinvault-referral-code.action";
import { UpdateDefaultBrandedKinvaultReferralCode } from "./update-default-branded-kinvault-referral-code.action";

export const Portal2AdminBrandedKinvaultDefaultBrandedKinvaultReferralCode =
  DefineActionBus({
    name: "portal-admin-branded-kinvault-default-branded-kinvault-referral-code",
    actions: {
      CreateDefaultBrandedKinvaultReferralCode,
      ReadDefaultBrandedKinvaultReferralCode,
      RecordDefaultBrandedKinvaultReferralCode,
      UpdateDefaultBrandedKinvaultReferralCode,
      DeleteDefaultBrandedKinvaultReferralCode,
    },
  });
