<template>
  <Card v-if="$data.form" class="kinvault-officer-details-update-profile">
    <FormControls
      :form="$data.form"
      mode="update"
      :title="$data.form.localData.fullName ?? undefined"
      :delete-button="false"
      @cancel="$router.back"
      @save="save"
      @reset="reset"
      @show-validation="$data.form!.options.showValidation = true"
    />
  </Card>
  <PageNotFound v-else-if="!profile" />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultOfficerDetailsUpdateProfileWrapper",
  "route": "KinvaultOfficerDetailsUpdateProfile",
  "selector": ".kinvault-officer-details-update-profile",
  "imports": {
    "UpdateProfileFormWrapper": "@/shared/form/profile/update-profile.form.test"
  },
  "methods": {
    "form": {
      "type": "to-one",
      "selector": "form",
      "wrapper": "UpdateProfileFormWrapper"
    },
    "save": {
      "type": "click",
      "selector": ".save-button"
    },
    "cancel": {
      "type": "click",
      "selector": ".cancel-button"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultOfficerDetailsMixin } from "@/module/kinvault.officer/mixin/kinvault-officer-details.mixin";
import { AuthService } from "@/service/auth.service";
import FormControls from "@/shared/component/misc/FormControls.vue";
import { UpdateProfileForm } from "@/shared/form/profile/update-profile.form";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { Card } from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { Profile } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultOfficerDetailsUpdateProfileRoute } from ".";

export default defineComponent({
  name: KinvaultOfficerDetailsUpdateProfileRoute,
  components: {
    PageNotFound,
    Card,
    FormControls,
  },
  mixins: [
    AuthService.mixin({ sessionRequired: true, permissions: ["kintin:write"] }),
    KinvaultOfficerDetailsMixin,
  ],
  data: () => ({
    form: null as null | FormBuilder<() => Profile, string>,
    profile: null as null | Profile,
  }),
  mounted(): void {
    if (null === this.$data.officer) {
      return;
    }

    this.profile = this.$data.officer.profile;
    this.form = UpdateProfileForm(this.profile as Profile);
  },
  methods: {
    async save(): Promise<void> {
      if (null === this.$data.officer || null === this.profile) {
        return;
      }

      // await window.Kernel.ActionBus.kinvaultOfficer.updateProfile({
      //   officer: this.$data.officer,
      // });
      await this.$actionBus.UpdateOfficerProfile(this.$data.officer);

      this.$router.back();
    },
    cancel(): void {
      this.$router.back();
    },
    reset(): void {
      this.$data.form?.localData.$restore();
    },
  },
});
</script>
