var c = Object.defineProperty;
var s = (t, a) => c(t, "name", { value: a, configurable: !0 });
import { _ as f } from "../../../component.layout/dynamic-layout/dynamic-layout.vue_vue_type_script_lang.js";
import { resolveComponent as $, createBlock as u, openBlock as o, resolveDynamicComponent as h, mergeProps as k, withCtx as y, renderSlot as i, createCommentVNode as e, createElementBlock as n, toDisplayString as C, Fragment as D, renderList as m, createSlots as L } from "vue";
import { _ as v } from "../../../_plugins/_plugin-vue_export-helper.js";
const g = ["innerHTML"], B = { key: 2 };
function S(t, a, H, M, T, _) {
  const p = $("DynamicLayoutComponent");
  return o(), u(h(t.layout.is ?? "div"), k(t.layout.attr, {
    style: {
      display: (!t.layout.is || t.layout.is === "div") && !t.layout.attr ? "contents" : null
    }
  }), {
    default: y(() => [
      t.layout.slot ? i(t.$slots, t.layout.slot, { key: 0 }) : e("", !0),
      t.layout.html ? (o(), n("span", {
        key: 1,
        innerHTML: t.layout.html
      }, null, 8, g)) : e("", !0),
      t.layout.text ? (o(), n("span", B, C(t.layout.text), 1)) : e("", !0),
      (o(!0), n(D, null, m(t.layout.children, (l, d) => (o(), u(p, {
        key: `child-layout-${d}-${t.uuid}`,
        layout: l
      }, L({ _: 2 }, [
        m(t.GetDynamicLayoutSlots(l), (r) => ({
          name: r,
          fn: y(() => [
            i(t.$slots, r)
          ])
        }))
      ]), 1032, ["layout"]))), 128))
    ]),
    _: 3
  }, 16, ["style"]);
}
s(S, "_sfc_render");
const N = /* @__PURE__ */ v(f, [["render", S]]);
export {
  N as default
};
