// Subscription, PhysicalStorageFile, Product, PhysicalStorageFileLog

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { PhysicalStorageSubscription } from "@kinherit/sdk/model/physical-storage-subscription";

export const ReadSubscription = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/physical-storage-subscription",
  method: "get",
  rootModel: PhysicalStorageSubscription,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});
