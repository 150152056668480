var d = Object.defineProperty;
var l = (o, e) => d(o, "name", { value: e, configurable: !0 });
import { _ as r } from "../../../component.input/date-time-field/date-time-field.vue_vue_type_script_lang.js";
import { resolveComponent as s, createBlock as p, openBlock as f, mergeProps as g, createSlots as c, withCtx as a, renderSlot as n, createVNode as h } from "vue";
import { b as t } from "../../../_assets/component.input/date-time-field/date-time-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as v } from "../../../_plugins/_plugin-vue_export-helper.js";
function w(o, e, b, $, k, F) {
  const u = s("DateField"), m = s("TimeField");
  return f(), p(m, g(o.$data.$cypress, {
    value: o.computedTime,
    "onUpdate:value": e[2] || (e[2] = (i) => o.computedTime = i),
    class: `${o.wrapperClasses} date-time-field`,
    label: o.label,
    "show-clear-now-button": !1,
    validators: o.validators,
    message: o.message,
    "message-color": o.messageColor,
    size: o.size,
    color: o.color,
    "is-loading": o.isLoading,
    "icon-right-override": o.computedIconRight,
    state: o.state,
    "show-validation": o.computedShowValidation,
    "left-icon-click": o.leftIconClick,
    "right-icon-click": o.rightIconClick,
    "show-clear-button": o.showClearButton,
    "is-fullwidth": o.isFullwidth,
    "is-grouped": o.isGrouped,
    placeholder: o.placeholder,
    autofocus: o.autofocus,
    autocomplete: o.autocomplete,
    min: o.computedTimeMin,
    max: o.computedTimeMax,
    onFocus: o.focus,
    onBlur: e[3] || (e[3] = (i) => o.$emit("blur")),
    onSubmit: o.keydown
  }), c({
    left: a(() => [
      n(o.$slots, "left", { value: o.computedValue }),
      h(u, {
        value: o.computedDate,
        "onUpdate:value": e[0] || (e[0] = (i) => o.computedDate = i),
        size: o.size,
        "show-clear-now-button": !1,
        color: o.color,
        "is-loading": o.isLoading,
        "icon-left": o.iconLeft,
        state: o.state,
        min: o.min,
        max: o.max,
        "show-clear-button": !1,
        onFocus: o.focus,
        onBlur: e[1] || (e[1] = (i) => o.$emit("blur")),
        onSubmit: o.keydown
      }, null, 8, ["value", "size", "color", "is-loading", "icon-left", "state", "min", "max", "onFocus", "onSubmit"])
    ]),
    _: 2
  }, [
    o.showRightControl ? {
      name: "right",
      fn: a(() => [
        n(o.$slots, "right", { value: o.computedValue })
      ]),
      key: "0"
    } : void 0
  ]), 1040, ["value", "class", "label", "validators", "message", "message-color", "size", "color", "is-loading", "icon-right-override", "state", "show-validation", "left-icon-click", "right-icon-click", "show-clear-button", "is-fullwidth", "is-grouped", "placeholder", "autofocus", "autocomplete", "min", "max", "onFocus", "onSubmit"]);
}
l(w, "_sfc_render");
typeof t == "function" && t(r);
const B = /* @__PURE__ */ v(r, [["render", w]]);
export {
  B as default
};
