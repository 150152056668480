// IntroducerCpd

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerCpd } from "@kinherit/sdk/model/introducer-cpd";

export const CreateIntroducerCpd = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/cpd/introducer/introducer-cpd",
  method: "post",
  parse: (target: IntroducerCpd) => ({
    body: {
      contact: target.$data.contact,
      session: target.$data.session,
      status: target.$data.status,
      type: target.$data.type,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
