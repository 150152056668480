var n = Object.defineProperty;
var e = (o, t) => n(o, "name", { value: t, configurable: !0 });
import i from "../../_vue/component.display/loading/loading.js";
import { defineComponent as a } from "vue";
import { KernelModes as m } from "../kernel-mode.js";
const l = a({
  name: "PragmaticLoadingLoader",
  components: { Loading: i },
  data: /* @__PURE__ */ e(() => ({
    show: !1
  }), "data"),
  computed: {
    isUnitTest() {
      var o;
      return ((o = window.Kernel) == null ? void 0 : o.Mode) === m.UnitTest;
    }
  },
  mounted() {
    window.Kernel.Global.setPragmaticLoadingComponentLoader(this.toggle);
  },
  methods: {
    toggle(o) {
      this.show = o;
    }
  }
});
export {
  l as _
};
