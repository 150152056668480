import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsBreadCrumb } from "../../../breadcrumbs";

/** Call Script 2 */
export const KintinDetailsCallScript2Route = "KinvaultKintinDetailsCall2Script";
export const KintinDetailsCallScript2URI = "/kinvault/:kintin/call-script-2";
export type KintinDetailsCallScript2Params = RouteParamObject<
  typeof KintinDetailsCallScript2URI
>;

/** Call 1 */
export const KintinDetailsCallScript2Call1Route =
  "KintinDetailsCallScript2Call1";
export const KintinDetailsCallScript2Call1URI =
  "/kinvault/:kintin/call-script-2/call-1";
export type KintinDetailsCallScript2Call1Params = RouteParamObject<
  typeof KintinDetailsCallScript2Call1URI
>;

/** Call 2 */
export const KintinDetailsCallScript2Call2Route =
  "KintinDetailsCallScript2Call2";
export const KintinDetailsCallScript2Call2URI =
  "/kinvault/:kintin/call-script-2/call-2";
export type KintinDetailsCallScript2Call2Params = RouteParamObject<
  typeof KintinDetailsCallScript2Call2URI
>;

/** Call 2 Intro */
export const KintinDetailsCallScript2Call2IntroRoute =
  "KintinDetailsCallScript2Call2Intro";
export const KintinDetailsCallScript2Call2IntroURI =
  "/kinvault/:kintin/call-script-2/call-2/intro";
export type KintinDetailsCallScript2Call2IntroParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2IntroURI
>;

/** Call 2 Family */
export const KintinDetailsCallScript2Call2FamilyRoute =
  "KintinDetailsCallScript2Call2Family";
export const KintinDetailsCallScript2Call2FamilyURI =
  "/kinvault/:kintin/call-script-2/call-2/family";
export type KintinDetailsCallScript2Call2FamilyParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2FamilyURI
>;

/** Call 2 Guardians */
export const KintinDetailsCallScript2Call2GuardiansRoute =
  "KintinDetailsCallScript2Call2Guardians";
export const KintinDetailsCallScript2Call2GuardiansURI =
  "/kinvault/:kintin/call-script-2/call-2/guardians";
export type KintinDetailsCallScript2Call2GuardiansParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2GuardiansURI
>;

/** Call 2 Assets */
export const KintinDetailsCallScript2Call2AssetsRoute =
  "KintinDetailsCallScript2Call2Assets";
export const KintinDetailsCallScript2Call2AssetsURI =
  "/kinvault/:kintin/call-script-2/call-2/assets";
export type KintinDetailsCallScript2Call2AssetsParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2AssetsURI
>;

/** Call 2 Beneficiaries */
export const KintinDetailsCallScript2Call2BeneficiariesRoute =
  "KintinDetailsCallScript2Call2Beneficiaries";
export const KintinDetailsCallScript2Call2BeneficiariesURI =
  "/kinvault/:kintin/call-script-2/call-2/beneficiaries";
export type KintinDetailsCallScript2Call2BeneficiariesParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2BeneficiariesURI
>;

/** Call 2 Executors */
export const KintinDetailsCallScript2Call2ExecutorsRoute =
  "KintinDetailsCallScript2Call2Executors";
export const KintinDetailsCallScript2Call2ExecutorsURI =
  "/kinvault/:kintin/call-script-2/call-2/executors";
export type KintinDetailsCallScript2Call2ExecutorsParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2ExecutorsURI
>;

/** Call 2 Trustees */
export const KintinDetailsCallScript2Call2TrusteesRoute =
  "KintinDetailsCallScript2Call2Trustees";
export const KintinDetailsCallScript2Call2TrusteesURI =
  "/kinvault/:kintin/call-script-2/call-2/trustees";
export type KintinDetailsCallScript2Call2TrusteesParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2TrusteesURI
>;

/** Call 2 Gifts */
export const KintinDetailsCallScript2Call2GiftsRoute =
  "KintinDetailsCallScript2Call2Gifts";
export const KintinDetailsCallScript2Call2GiftsURI =
  "/kinvault/:kintin/call-script-2/call-2/gifts";
export type KintinDetailsCallScript2Call2GiftsParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2GiftsURI
>;

/** Call 2 LPAs */
export const KintinDetailsCallScript2Call2LPAsRoute =
  "KintinDetailsCallScript2Call2LPAs";
export const KintinDetailsCallScript2Call2LPAsURI =
  "/kinvault/:kintin/call-script-2/call-2/LPAs";
export type KintinDetailsCallScript2Call2LPAsParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2LPAsURI
>;

/** Call 2 LPAs */
export const KintinDetailsCallScript2Call2LPAConfigureRoute =
  "KintinDetailsCallScript2Call2LPAConfigure";
export const KintinDetailsCallScript2Call2LPAConfigureURI =
  "/kinvault/:kintin/call-script-2/call-2/LPAConfigure";
export type KintinDetailsCallScript2Call2LPAConfigureParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2LPAConfigureURI
>;

/** Call 2 Business */
export const KintinDetailsCallScript2Call2BusinessRoute =
  "KintinDetailsCallScript2Call2Business";
export const KintinDetailsCallScript2Call2BusinessURI =
  "/kinvault/:kintin/call-script-2/call-2/business";
export type KintinDetailsCallScript2Call2BusinessParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2BusinessURI
>;

/** Call 2 Order */
export const KintinDetailsCallScript2Call2OrderRoute =
  "KintinDetailsCallScript2Call2Order";
export const KintinDetailsCallScript2Call2OrderURI =
  "/kinvault/:kintin/call-script-2/call-2/order";
export type KintinDetailsCallScript2Call2OrderParams = RouteParamObject<
  typeof KintinDetailsCallScript2Call2OrderURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCallScript2Route,
    path: KintinDetailsCallScript2URI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScript" */ "./KinvaultKintinDetails.CallScript2.vue"
        ),
      sidebar: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScriptSidebar" */ "./KinvaultKintinDetails.CallScript2.Sidebar.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...KinvaultKintinDetailsBreadCrumb(params),
        {
          text: "Call Script",
          route: { name: KintinDetailsCallScript2Route, params },
        },
      ],
    },
    redirect: { name: KintinDetailsCallScript2Call1Route },
    children: [
      {
        name: KintinDetailsCallScript2Call1Route,
        path: KintinDetailsCallScript2Call1URI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsCallScript2Call1" */ "./KinvaultKintinDetails.CallScript2.Call1.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsBreadCrumb,
        },
      },
      {
        name: KintinDetailsCallScript2Call2Route,
        path: KintinDetailsCallScript2Call2URI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsCallScript2Call2" */ "./KinvaultKintinDetails.CallScript2.Call2.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsBreadCrumb,
        },
        redirect: { name: KintinDetailsCallScript2Call2IntroRoute },
        children: [
          {
            name: KintinDetailsCallScript2Call2IntroRoute,
            path: KintinDetailsCallScript2Call2IntroURI,

            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2Intro" */ "./KinvaultKintinDetails.CallScript2.Call2.Intro.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
          {
            name: KintinDetailsCallScript2Call2FamilyRoute,
            path: KintinDetailsCallScript2Call2FamilyURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2Family" */ "./KinvaultKintinDetails.CallScript2.Call2.Family.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
          {
            name: KintinDetailsCallScript2Call2GuardiansRoute,
            path: KintinDetailsCallScript2Call2GuardiansURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2Guardians" */ "./KinvaultKintinDetails.CallScript2.Call2.Guardians.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
          {
            name: KintinDetailsCallScript2Call2AssetsRoute,
            path: KintinDetailsCallScript2Call2AssetsURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2Assets" */ "./KinvaultKintinDetails.CallScript2.Call2.Assets.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
          {
            name: KintinDetailsCallScript2Call2BeneficiariesRoute,
            path: KintinDetailsCallScript2Call2BeneficiariesURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2Beneficiaries" */ "./KinvaultKintinDetails.CallScript2.Call2.Beneficiaries.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
          {
            name: KintinDetailsCallScript2Call2ExecutorsRoute,
            path: KintinDetailsCallScript2Call2ExecutorsURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2Executors" */ "./KinvaultKintinDetails.CallScript2.Call2.Executors.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
          {
            name: KintinDetailsCallScript2Call2TrusteesRoute,
            path: KintinDetailsCallScript2Call2TrusteesURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2Trustees" */ "./KinvaultKintinDetails.CallScript2.Call2.Trustees.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
          {
            name: KintinDetailsCallScript2Call2GiftsRoute,
            path: KintinDetailsCallScript2Call2GiftsURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2Gifts" */ "./KinvaultKintinDetails.CallScript2.Call2.Gifts.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
          {
            name: KintinDetailsCallScript2Call2LPAsRoute,
            path: KintinDetailsCallScript2Call2LPAsURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2LPAs" */ "./KinvaultKintinDetails.CallScript2.Call2.LPAs.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
          {
            name: KintinDetailsCallScript2Call2LPAConfigureRoute,
            path: KintinDetailsCallScript2Call2LPAConfigureURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2LPAConfigure" */ "./KinvaultKintinDetails.CallScript2.Call2.LPAConfigure.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
          {
            name: KintinDetailsCallScript2Call2BusinessRoute,
            path: KintinDetailsCallScript2Call2BusinessURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2Business" */ "./KinvaultKintinDetails.CallScript2.Call2.Business.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },

          {
            name: KintinDetailsCallScript2Call2OrderRoute,
            path: KintinDetailsCallScript2Call2OrderURI,
            component: () =>
              import(
                /* webpackChunkName: "KintinDetailsCallScript2Call2Order" */ "./KinvaultKintinDetails.CallScript2.Call2.Order.vue"
              ),
            meta: {
              breadcrumbs: KinvaultKintinDetailsBreadCrumb,
            },
          },
        ],
      },
    ],
  },
];
