import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { Api } from "@kinherit/sdk/api";
import { FileLog, Kintin } from "@kinherit/sdk";

//
export const DownloadFile = async (message: {
  file: FileLog | string;
  kintin: Kintin | string;
}) => {
  const { url } = await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/file-log/{file}/download",
    {
      kintin: message.kintin,
      file: message.file,
    },
  )
    .method("get")

    .result();

  OpenAlertDialog({
    dialog: {
      title: "Download Ready",
      html: `
            Click <a href="${url}" target="_blank">here</a> to download the file.
          `,
    },
    button: {
      ok: {
        text: "Close",
      },
      cancel: {
        show: false,
      },
    },
  });

  return {};
};
