<template>
  <div class="introducer-contract-master-list">
    <PageHeader htag="h1" text="Introducer Contracts" />
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        createdAt: 'Created',
        datedTill: 'Dated',
        'company.profile.organisationName': 'Name',
        sentAt: 'Sent',
      }"
      @refresh="refresh"
      @row-clicked="handleClick"
    >
      <template #vat="{ row }: { row: IntroducerContract }">
        {{ row.vat ? row.vat.text : "NET (Assumed)" }}
      </template>
      <template #keyTerms="{ row }: { row: IntroducerContract }">
        <span
          v-if="
            row.type?.value === 'agentintroducer' ||
            row.type?.value === 'parentagentintroducer'
          "
        >
          <!-- Check if both months and percent are not null/empty -->
          <template
            v-if="hasValue(row.ai_5a_months) && hasValue(row.ai_5a_percent)"
          >
            {{ row.ai_5a_months }} months @ {{ row.ai_5a_percent }}%<br />
          </template>
          <!-- Display error if one is empty/null and the other is not -->
          <template
            v-else-if="
              hasValue(row.ai_5a_months) !== hasValue(row.ai_5a_percent)
            "
          >
            <strong class="has-text-danger is-block"
              >Error: Incomplete data for period A</strong
            >
          </template>

          <template
            v-if="hasValue(row.ai_5b_months) && hasValue(row.ai_5b_percent)"
          >
            {{ row.ai_5b_months }} months @ {{ row.ai_5b_percent }}%<br />
          </template>
          <template
            v-else-if="
              hasValue(row.ai_5b_months) !== hasValue(row.ai_5b_percent)
            "
          >
            <strong class="has-text-danger is-block"
              >Error: Incomplete data for period B</strong
            >
          </template>

          <template
            v-if="hasValue(row.ai_5c_months) && hasValue(row.ai_5c_percent)"
          >
            {{ row.ai_5c_months }} months @ {{ row.ai_5c_percent }}
          </template>
          <template
            v-else-if="
              hasValue(row.ai_5c_months) !== hasValue(row.ai_5c_percent)
            "
          >
            <strong class="has-text-danger is-block"
              >Error: Incomplete data for period C</strong
            >
          </template>
        </span>
        <span
          v-else-if="
            row.type?.value === 'introducer' ||
            row.type?.value === 'masternetwork'
          "
        >
          <template
            v-if="hasValue(row.i_4c_months) !== hasValue(row.i_4c_percent)"
          >
            <strong class="has-text-danger is-block"
              >Error: Incomplete data for period</strong
            >
          </template>
          {{ row.i_4c_months }} months @ {{ row.i_4c_percent }}%
          <span v-if="row.i_4c_percent === '0'"> - Zero Rated</span>
        </span>
        <span v-else-if="row.type?.value === 'parentnetwork'">
          <em>Inherited</em>
        </span>
        <span v-else>
          <em>N/A</em>
        </span>
      </template>
      <template #signed="{ row }: { row: IntroducerContract }">
        <Icon
          v-if="row.isSigned"
          icon="Success"
          size="is-small"
          class="is-success"
        />
        <Icon v-else icon="Cross" size="is-small" class="is-warning" />
      </template>
      <template #dated="{ row }: { row: IntroducerContract }">
        <span v-if="row.datedAt">
          {{ row.datedAt.formatDate }} -
          <span v-if="row.datedTill">{{ row.datedTill.formatDate }} </span>
          <i v-else>Perpetual</i>
        </span>
        <i v-else>Not Dated</i>
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerContractMasterListWrapper",
  "route": "IntroducerContractMasterList",
  "imports": {
    "ReadIntroducerContractFormWrapper": "@/module/introducer.contract/form/read-introducer-contract.form.test"
  },
  "selector": ".introducer-contract-master-list",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadIntroducerContractFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import {
  IntroducerCompanyDetailsContractsParams,
  IntroducerCompanyDetailsContractsRoute,
} from "@/module/introducer.company/page/details/contracts";
import { ReadIntroducerContractForm } from "@/module/introducer.contract/form/read-introducer-contract.form";
import { Icon } from "@kinherit/framework/component.display/icon";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Equal, In, IsNotNull, Like } from "@kinherit/orm/index";
import { IIntroducerContract, IntroducerContract } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerContractMasterListRoute } from ".";

export default defineComponent({
  name: IntroducerContractMasterListRoute,
  components: { Icon, MasterListPage, PageHeader },
  mixins: [
    ActionBusMixin(() => window.Kernel.ActionBus2.portal.introducer.contract),
  ],
  data: () => ({
    filters: ReadIntroducerContractForm(),
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v: IntroducerContract) =>
          v.company.profile.organisationName ?? v.company.profile.fullName,
      },
      {
        title: "Type",
        sort: true,
        map: (v: IntroducerContract) => v.type?.text,
      },
      {
        title: "VAT",
        sort: false,
        slot: "vat",
      },
      {
        title: "Key Terms",
        sort: false,
        slot: "keyTerms",
      },
      {
        title: "Sent",
        sort: true,
        map: (v: IntroducerContract) => v.sentAt?.formatDate,
      },
      {
        title: "Dated",
        sort: true,
        slot: "dated",
      },
      {
        title: "Signed",
        sort: true,
        slot: "signed",
      },
    ] as Array<TableColumn>,
    rows: Array<IntroducerContract>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerContract,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    hasValue(value: string | null | undefined | number) {
      return value !== null && value !== "";
    },
    handleClick(
      introducercontract: IntroducerContract,
      event: MouseEvent,
    ): void {
      const params: IntroducerCompanyDetailsContractsParams = {
        introducerCompany: introducercontract.$data.company,
      };

      window.Kernel.visitRoute(
        {
          name: IntroducerCompanyDetailsContractsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadIntroducerContractForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.introducerContract.read({
      //   ...formData,
      //   sort: this.sort,
      //   pagination: this.pagination,
      // });

      const deletedAt =
        formData.contractStatus === "void" ? IsNotNull() : undefined;
      const sentAt =
        formData.contractStatus === "not-sent"
          ? undefined
          : formData.contractStatus === "sent-not-signed" ||
              formData.contractStatus === "sent-signed"
            ? IsNotNull()
            : undefined;
      const isSigned =
        formData.contractStatus === "sent-not-signed"
          ? Equal(false)
          : formData.contractStatus === "sent-signed"
            ? Equal(true)
            : Equal(formData.isSigned);

      const data = await this.$actionBus.ReadIntroducerContract({
        query: [
          {
            company: {
              profile: {
                fullName: Like(formData.name),
              },
            },
            type: {
              id: In(formData.contractType),
            },
            isSigned: isSigned,
            sentAt: sentAt,
            deletedAt: deletedAt,
            createdAt: Between(formData.created),
          },
          {
            company: {
              profile: {
                organisationName: Like(formData.name),
              },
            },
            type: {
              id: In(formData.contractType),
            },
            isSigned: isSigned,
            sentAt: sentAt,
            deletedAt: deletedAt,
            createdAt: Between(formData.created),
          },
        ],
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.introducerContract;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
