import { RouteInterface } from "@kinherit/framework/core/route-config";

export class Module {
  private static _modules: Module[] = [];

  public name = "";
  public routes: RouteInterface[] = [];
  public session = true as boolean | "optional";
  public permissions:
    | string[]
    | {
        some?: string[];
        all?: string[];
      } = [];

  public static register(module: Module) {
    const walkRoutes = (routes: RouteInterface[]) => {
      for (const route of routes) {
        if (route.path.endsWith("/")) {
          route.path = route.path.slice(0, -1);
        }

        if ("children" in route) {
          walkRoutes(route.children ?? []);
        }
      }
    };

    walkRoutes(module.routes);

    Module._modules.push(module);
  }

  private static get modules(): Module[] {
    return Module._modules
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .reverse();
  }

  public static routes(): RouteInterface[] {
    return Module.modules.reduce((acc, module) => {
      return acc.concat(module.routes);
    }, Array<RouteInterface>());
  }

  public static findModuleByRoute(routeName: string): Module | undefined {
    const walk = (routes: RouteInterface[]): RouteInterface | undefined => {
      for (const route of routes) {
        if (route.name === routeName) {
          return route;
        }

        if ("children" in route) {
          const result = walk(route.children ?? []);
          if (result) {
            return result;
          }
        }
      }
      return undefined;
    };

    return this.modules.find((module) => undefined !== walk(module.routes));
  }
}
