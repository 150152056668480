var o = Object.defineProperty;
var t = (e, n) => o(e, "name", { value: n, configurable: !0 });
import r from "../../../_vue/component.display/page-not-found/page-not-found.js";
import i from "../../../_vue/component.layout/breadcrumbs/breadcrumbs.js";
import a from "../../../_vue/component.layout/footer/footer.js";
import s from "../../../_vue/component.layout/header/header.js";
import p from "../../../component.mixin/router-mixin.js";
import m from "../../../_vue/core/internal-components/dialog-loader.js";
import l from "../../../_vue/core/internal-components/loading-overlay-loader.js";
import d from "../../../_vue/core/internal-components/sidebar-loader.js";
import { defineComponent as w, onErrorCaptured as u } from "vue";
import c from "../../../_vue/core/internal-components/context-menu-loader.js";
import h from "../../../_vue/core/internal-components/snack-bar-loader.js";
const L = w({
  name: "AppComponent",
  components: {
    RouterView: null,
    PragmaticDialogLoader: m,
    PragmaticSidebarLoader: d,
    Header: s,
    Footer: a,
    PageNotFound: r,
    PragmaticLoadingLoader: l,
    PragmaticContextMenuLoader: c,
    Breadcrumbs: i,
    PragmaticSnackBarLoader: h
    /* SideMenuComponent, */
  },
  mixins: [p],
  data: /* @__PURE__ */ t(() => ({
    bindings: {
      page: window.Kernel.ComponentOptions.App.getElementBindings("page"),
      content: window.Kernel.ComponentOptions.App.getElementBindings("content"),
      wrapper: window.Kernel.ComponentOptions.App.getElementBindings("wrapper")
    },
    isTouchScreenOnly: console.isTouchScreen(!0),
    kernelMode: window.Kernel.Mode,
    loading: !0,
    renderKey: 0,
    content: null
  }), "data"),
  computed: {
    isReady() {
      return window.Kernel.ComponentOptions.App.ready === !0;
    },
    showNavbar() {
      var e, n;
      return this.hasRoute === !1 ? !1 : ((n = (e = this.$route) == null ? void 0 : e.meta) == null ? void 0 : n.navbar) ?? !0;
    },
    showFooter() {
      var e, n;
      return this.hasRoute === !1 ? !1 : ((n = (e = this.$route) == null ? void 0 : e.meta) == null ? void 0 : n.footer) ?? !0;
    },
    pageClasses() {
      let e = this.showNavbar || this.showFooter ? "" : "is-full-screen";
      return this.hasRoute && (e += ` ${this.$route.name}`), e;
    }
  },
  mounted() {
    u((e) => !("$internal" in e && e.$internal === !0), this.$), window.Kernel.ComponentOptions.App.setRefresh(this.refresh), window.Kernel.ComponentOptions.App.setIncrementRenderKey(
      this.incrementRenderKey
    ), this.wait();
  },
  methods: {
    refresh() {
      this.bindings = {
        page: window.Kernel.ComponentOptions.App.getElementBindings("page"),
        content: window.Kernel.ComponentOptions.App.getElementBindings("content"),
        wrapper: window.Kernel.ComponentOptions.App.getElementBindings("wrapper")
      }, this.content = window.Kernel.ComponentOptions.App.content ?? null, this.$forceUpdate();
    },
    incrementRenderKey() {
      this.renderKey++;
    },
    async wait() {
      await window.Kernel.ActionBus.$waitUntilIdle(), await console.sleep(100), await window.Kernel.ActionBus.$waitUntilIdle(), await window.Kernel.ActionBus2.$waitUntilIdle(), await console.sleep(100), await window.Kernel.ActionBus2.$waitUntilIdle(), this.loading = !1;
    }
  }
});
export {
  L as _
};
