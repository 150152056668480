<template>
  <div class="introducercompany-referrals-page">
    <Tabs :is-fullwidth="false" :config="tabs" />
    <RouterChildView />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsReferralsIndexWrapper",
  "route": "IntroducerCompanyDetailsReferralsRoute",
  "selector": ".introducercompany-referrals-page",
  "imports": {
    "IntroducerCompanyDetailsReferralCodesWrapper": "@/module/introducer.company/page/details/referrals/IntroducerCompanyDetails.ReferralCodes.test",
    "IntroducerCompanyDetailsReferralFormWrapper": "@/module/introducer.company/page/details/referrals/IntroducerCompanyDetails.ReferralForm.test",
    "IntroducerCompanyDetailsReferralsWrapper": "@/module/introducer.company/page/details/referrals/IntroducerCompanyDetails.Referrals.test"
  },
  "methods": {
    "tabs": {
      "type": "tabs",
      "selector": ".tabs",
      "items": ["Referrals", "Referral Codes", "Referral Forms"]
    },
    "referrals": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsReferralsWrapper"
    },
    "referralCodes": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsReferralCodesWrapper"
    },
    "referralForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsReferralFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { StyleService } from "@/service/style.service";
import Tabs from "@kinherit/framework/component.display/tabs";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  IntroducerCompanyDetailsReferralCodesRoute,
  IntroducerCompanyDetailsReferralFormRoute,
  IntroducerCompanyDetailsReferralsIndexRoute,
  IntroducerCompanyDetailsReferralsRoute,
} from ".";

export default defineComponent({
  name: IntroducerCompanyDetailsReferralsIndexRoute,
  components: { Tabs, RouterChildView },
  mixins: [StyleService.mixin],
  data: () => ({
    tabs: [
      {
        label: `Referrals`,
        icon: StyleService.icon.referral.icon,
        route: { name: IntroducerCompanyDetailsReferralsRoute },
      },
      {
        label: `Referral Codes`,
        icon: StyleService.icon.referral.icon,
        route: { name: IntroducerCompanyDetailsReferralCodesRoute },
      },
      {
        label: `Referral Forms`,
        icon: StyleService.icon.add.icon,
        route: { name: IntroducerCompanyDetailsReferralFormRoute },
      },
    ],
  }),
});
</script>
