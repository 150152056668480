import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateOpgRoyalMailOrder } from "./create-opg-royal-mail-order.action";
import { CreateRoyalMailOrder } from "./create-royal-mail-order.action";
import { FillLetterOfAuthority } from "./fill-letter-of-authority.action";
import { FillLpaFinancial } from "./fill-lpa-financial.action";
import { FillLpaHealth } from "./fill-lpa-health.action";
import { FillLRSeveranceForm } from "./fill-lrseverance-form.action";
import { FillNoticeOfSeverance } from "./fill-notice-of-severance.action";
import { FillPackingSlip } from "./fill-packing-slip.action";
import { FillStageEClosingLetter } from "./fill-stage-eclosing-letter.action";
import { FillStageFClosingLetter } from "./fill-stage-fclosing-letter.action";

export const Portal2KinvaultProcess = DefineActionBus({
  name: "portal-kinvault-process",
  actions: {
    FillLetterOfAuthority,
    FillLpaFinancial,
    FillLpaHealth,
    FillLRSeveranceForm,
    FillNoticeOfSeverance,
    FillPackingSlip,
    FillStageEClosingLetter,
    FillStageFClosingLetter,
    CreateRoyalMailOrder,
    CreateOpgRoyalMailOrder,
  },
});
