import { Api, Person } from "@kinherit/sdk";

//
export const SendOfficerLetter = async (officer: Person) => {
  await Api.resource("portal", `/v2/portal/officer/{officer}/send`, {
    officer,
  })
    .method("get")
    .result();
};
