var h = Object.defineProperty;
var s = (e, o) => h(e, "name", { value: o, configurable: !0 });
import { _ as k } from "../../../component.display/panel/panel.vue_vue_type_script_lang.js";
import { resolveComponent as r, createElementBlock as n, openBlock as t, normalizeClass as i, createCommentVNode as p, createElementVNode as c, createVNode as u, createBlock as v, toDisplayString as d, Fragment as b, renderList as C } from "vue";
import { _ as $ } from "../../../_plugins/_plugin-vue_export-helper.js";
const y = {
  key: 0,
  class: "panel-heading"
}, w = { class: "panel-tabs" }, D = ["onClick"], P = { class: "panel-block" };
function V(e, o, B, F, L, N) {
  const m = r("TextField"), g = r("DropDownMenu"), f = r("Pagination");
  return t(), n("article", {
    class: i(`panel ${e.color}`)
  }, [
    e.title ? (t(), n("p", y, d(e.title), 1)) : p("", !0),
    c("p", w, [
      (t(!0), n(b, null, C(e.computedCategories, (l, a) => (t(), n("a", {
        key: `category-${a}`,
        class: i(`${e.selectedCategory === a ? "is-active" : ""}`),
        onClick: /* @__PURE__ */ s(() => e.changeCategory(a), "onClick")
      }, d(l.label), 11, D))), 128))
    ]),
    c("div", P, [
      u(m, {
        value: e.search,
        "onUpdate:value": o[0] || (o[0] = (l) => e.search = l),
        class: "is-fullwidth",
        color: e.color,
        placeholder: e.$t.local.placeholder,
        "icon-left": "Search",
        autofocus: e.autofocus
      }, null, 8, ["value", "color", "placeholder", "autofocus"])
    ]),
    u(g, {
      value: e.computedValue,
      "onUpdate:value": o[1] || (o[1] = (l) => e.computedValue = l),
      class: "panel-block",
      show: !0,
      options: e.paginatedOptions,
      "map-options": {
        label: "label",
        value: "value",
        iconLeft: "iconLeft",
        iconRight: "iconRight",
        line1: "line1",
        line2: "line2"
      },
      "show-more-button": !1
    }, null, 8, ["value", "options"]),
    e.computedOptions.length > 10 ? (t(), v(f, {
      key: 1,
      current: e.currentPage,
      "onUpdate:current": o[2] || (o[2] = (l) => e.currentPage = l),
      class: "panel-block",
      total: e.computedOptions.length / 10
    }, null, 8, ["current", "total"])) : p("", !0)
  ], 2);
}
s(V, "_sfc_render");
const R = /* @__PURE__ */ $(k, [["render", V]]);
export {
  R as default
};
