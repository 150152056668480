var m = Object.defineProperty;
var o = (t, e) => m(t, "name", { value: e, configurable: !0 });
import { Uuid as r } from "@kinherit/ts-common/service/uuid";
import { defineComponent as a } from "vue";
import { GetDynamicLayoutSlots as n } from "./helpers.js";
const s = a({
  name: "DynamicLayoutComponent",
  props: {
    layout: {
      type: Object,
      required: !0
    }
  },
  data: /* @__PURE__ */ o(() => ({
    uuid: r.generate()
  }), "data"),
  methods: {
    GetDynamicLayoutSlots: n
  }
});
export {
  s as _
};
