<template>
  <div v-if="data">
    <h1 class="text-center">MEMORANDUM OF WISHES</h1>
    <p class="text-center">
      <strong>
        This is a confidential document from myself, the Settlor, to the
        Trustees of
        <br />
        <span class="ucase"
          >THE
          <span
            v-if="data.person"
            v-html="helpers.var(data.person.profile.fullName).toUpperCase()"
          />&nbsp;
          <span v-html="helpers.var(trustName)" />
        </span>
      </strong>
    </p>
    <p>
      I appreciate that I cannot fetter your discretion in any way, but I set
      out below the guidelines which I would wish you to adopt in managing and
      distributing any assets held within the Trust after my death:
    </p>
    <p>
      The Trust was set up to give you as Trustees the power to utilise the
      Trust Fund in such shares and on such terms and conditions as you may
      decide, which will allow you to take into account financial, tax and
      family circumstances in the years to come in the administration and
      distribution of the Trust Fund.
    </p>
    <p>
      In order to achieve these aims and to ensure that the Trust Fund remains
      for future generations, then I would like the Trustees to consider the use
      of loans from the Trust (either secured or unsecured as appropriate) in
      preference to absolute distribution or for the trust to buy assets for the
      use or benefit of intended Beneficiaries in whole or in part.
    </p>
    <!--
    First Priority should only be output if there are both firstline and secondline beneficiaries;
    If there are, the firstline should be output as first priority.
  -->
    <FirstPriority
      v-if="
        data.beneficiariesFirstLine.length === 1 &&
        data.beneficiariesSecondLine.length > 0
      "
    />
    <FirstPriorities
      v-if="
        data.beneficiariesFirstLine.length > 1 &&
        data.beneficiariesSecondLine.length > 0
      "
    />
    <BeneficiaryAge v-if="data.outputBeneficiaryAge" />
    <PropertyDirections v-if="data.propertyGiftsMOW.length > 0" />
    <Funds v-if="data.cashGiftsMOW.length > 0" />
    <SpecificGifts v-if="data.specificGiftsMOW.length > 0" />
    <PetGifts v-if="data.petGiftsMOW.length > 0" />
    <BankaccountGifts v-if="data.bankaccountGiftsMOW.length > 0" />
    <SharesGifts v-if="data.sharesGiftsMOW.length > 0" />
    <IsaGifts v-if="data.isaGiftsMOW.length > 0" />
    <!--
    If there are both first and secondline beneficiaries, then this should only output second line beneficiaries.
    Otherwise should output first line beneficiaries.
  -->
    <SharesToBeneficiary v-if="mainBeneficaries.length === 1" />
    <SharesToBeneficiaries v-if="mainBeneficaries.length > 1" />
    <SharesToReserveBeneficiary v-if="data.reserveBeneficiaries.length === 1" />
    <SharesToReserveBeneficiaries v-if="data.reserveBeneficiaries.length > 1" />
    <AppointmentAdditionalTrustees v-if="data.additionalTrustees.length > 0" />
    <SubstituteTrustee v-if="data.reserveTrustees.length > 0" />
    <TrusteeRobinHoodClause v-if="!data.protrusteeOptOut" />
    <CharitiesClause v-if="data.outputCharityClauseMOW" />
    <FinancialSupportGuardians v-if="data.outputFinancialAssistGuardians" />
    <FundsForEducation
      v-if="data.allowFundsForEducation || data.allowFundsForMedical"
    />
    <FundsForTravel v-if="data.allowFundsForTravel" />
    <FinancialAdvice v-if="data.advisers.length > 0" />
    <p>&nbsp;</p>
    <p>
      <strong>
        Signed by the settlor
        <span class="ucase"
          ><span
            v-if="data.person"
            v-html="helpers.var(data.person.profile.fullName)"
        /></span>
      </strong>
    </p>
    <p>&nbsp;</p>
    <p>
      Signature__________________________________ Date ______________________
    </p>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { Beneficiary } from "@kinherit/sdk";
import { defineComponent } from "vue";
import AppointmentAdditionalTrustees from "../mow/AppointmentAdditionalTrustees.vue";
import BankaccountGifts from "../mow/BankaccountGifts.vue";
import BeneficiaryAge from "../mow/BeneficiaryAge.vue";
import CharitiesClause from "../mow/CharitiesClause.vue";
import FinancialAdvice from "../mow/FinancialAdvice.vue";
import FinancialSupportGuardians from "../mow/FinancialSupportGuardians.vue";
import FirstPriorities from "../mow/FirstPriorities.vue";
import FirstPriority from "../mow/FirstPriority.vue";
import Funds from "../mow/Funds.vue";
import FundsForEducation from "../mow/FundsForEducation.vue";
import FundsForTravel from "../mow/FundsForTravel.vue";
import IsaGifts from "../mow/IsaGifts.vue";
import PetGifts from "../mow/PetGifts.vue";
import PropertyDirections from "../mow/PropertyDirections.vue";
import SharesGifts from "../mow/SharesGifts.vue";
import SharesToBeneficiaries from "../mow/SharesToBeneficiaries.vue";
import SharesToBeneficiary from "../mow/SharesToBeneficiary.vue";
import SharesToReserveBeneficiaries from "../mow/SharesToReserveBeneficiaries.vue";
import SharesToReserveBeneficiary from "../mow/SharesToReserveBeneficiary.vue";
import SpecificGifts from "../mow/SpecificGifts.vue";
import SubstituteTrustee from "../mow/SubstituteTrustee.vue";
import TrusteeRobinHoodClause from "../mow/TrusteeRobinHoodClause.vue";

export default defineComponent({
  name: "MowOutput",
  components: {
    FirstPriority,
    FirstPriorities,
    BeneficiaryAge,
    PropertyDirections,
    Funds,
    SpecificGifts,
    PetGifts,
    BankaccountGifts,
    SharesGifts,
    IsaGifts,
    SharesToBeneficiary,
    SharesToBeneficiaries,
    SharesToReserveBeneficiary,
    SharesToReserveBeneficiaries,
    AppointmentAdditionalTrustees,
    SubstituteTrustee,
    TrusteeRobinHoodClause,
    CharitiesClause,
    FinancialSupportGuardians,
    FundsForEducation,
    FundsForTravel,
    FinancialAdvice,
  },
  mixins: [WillBuilderService.mixin],
  props: {
    trustName: {
      type: String,
      required: true,
    },
  },
  computed: {
    mainBeneficaries(): Beneficiary[] {
      return [
        ...(this.data?.beneficiariesFirstLine ?? []),
        ...(this.data?.beneficiariesSecondLine ?? []),
      ];
    },
  },
});
</script>
