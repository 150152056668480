import { SharedFormProps, UserField } from "@/config/form.config";
import { CreateCreditNote } from "@/config/model.config";
import { AuthService } from "@/service/auth.service";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { CreditNote } from "@kinherit/sdk";

import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { DateTime } from "@kinherit/ts-common/dto/date-time";

export const UpdateCreditNoteForm = ({ data }: { data?: CreditNote } = {}) => {
  if (!data) {
    data = CreateCreditNote();
    data.issuedAt = new DateTime();
    data.issuedBy = AuthService.loggedInUser;
    data.note.name = `Credit Note from ${AuthService.loggedInUser?.profile.fullName}`;
  }

  return defineForm({
    name: "update-credit-note-form",
    props: SharedFormProps,
    data: () => data as CreditNote,
    formAreas: (data) => [
      defineFormArea({
        name: "update-credit-note-form-area",
        data,
        template: GridLayout([
          ["issuedBy", "issuedAt"],
          ["initialValue", "remainingValue"],
          {
            is: "hr",
          },
          ["name"],
          ["notes"],
        ]),
        components: () => ({
          issuedBy: [
            UserField({
              props: {
                vModel: "issuedBy",
                label: "Issued By",
                validators: ["required"],
              },
              query: {},
            }),
          ],
          issuedAt: [
            FormDateField({
              props: {
                label: "Issued At",
                validators: ["required"],
              },
              models: {
                value: "issuedAt",
              },
            }),
          ],
          initialValue: [
            FormCurrencyField({
              props: {
                label: "Initial Value",
                validators: ["required"],
              },
              models: {
                value: "initialValue",
              },
            }),
          ],
          remainingValue: [
            FormCurrencyField({
              props: {
                label: "Remaining Value",
                validators: ["required"],
              },
              models: {
                value: "remainingValue",
              },
            }),
          ],
          pinned: [
            FormCheckboxField({
              props: {
                label: "Pinned",
              },
              models: {
                value: "note.pinned",
              },
            }),
          ],
          name: [
            FormTextField({
              props: {
                label: "Name",
                validators: ["required"],
              },
              models: {
                value: "note.name",
              },
            }),
          ],
          notes: [
            FormRichTextField({
              props: {
                label: "Notes",
              },
              models: {
                value: "note.notes",
              },
            }),
          ],
          dueAt: [
            FormDateField({
              props: {
                label: "Due At",
              },
              models: {
                value: "note.dueAt",
              },
            }),
          ],
          completedAt: [
            FormCheckboxField({
              props: {
                label: "Complete",
                reference: "note.completedAt",
              },
              models: {
                value: {
                  get: (data) => data.note.completedAt !== null,
                  set: (value, data) => {
                    data.note.completedAt =
                      true === value ? new DateTime() : null;
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
};
