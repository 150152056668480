var o = Object.defineProperty;
var r = (e, t) => o(e, "name", { value: t, configurable: !0 });
const a = /* @__PURE__ */ r((e, t, n) => {
  e.target && (t.contains(e.target) || n(e));
}, "Listener"), s = /* @__PURE__ */ new Map(), d = {
  mounted(e, t) {
    var i;
    const n = /* @__PURE__ */ r((c) => a(c, e, t.value), "listener");
    s.set(e, n), (i = window.document.getElementById("app")) == null || i.addEventListener("click", n, { passive: !0, capture: !1 });
  },
  beforeUnmount(e) {
    var n;
    const t = s.get(e);
    t && ((n = window.document.getElementById("app")) == null || n.removeEventListener("click", t, {
      capture: !1
    }));
  }
};
export {
  d as ClickOutsideDirective
};
