import { Api } from "@kinherit/sdk/api";
import { Kintin } from "@kinherit/sdk";

// BusinessAsset, CashDebt, Debt, Investment, Kintin, LifeTimeGift, OtherAsset, Pension, Policy, Property, WillReview, WillRevision, FileLog, Person
export const ChangeType = async (data: {
  kintin: Kintin;
  type: "single" | "joint";
  keepSecondaryPerson: boolean;
}): Promise<void> => {
  await Api.resource("portal", "/v2/portal/kinvault/change-type")
    .method("put")
    .body({
      kintin: data.kintin.id,
      type: data.type,
      keepSecondaryPerson: data.keepSecondaryPerson,
    })
    .result();
};
