var a = Object.defineProperty;
var t = (e, i) => a(e, "name", { value: i, configurable: !0 });
import n from "../../_vue/component.display/icon/icon.js";
import { CypressHelper as u } from "../../cypress/component-helpers.js";
import { ThemeColor as l } from "../../theme/prop/color.js";
import { ThemeIconName as s } from "../../theme/prop/icon.js";
import { ThemeShade as d } from "../../theme/prop/shade.js";
import { ThemeSize as o } from "../../theme/prop/size.js";
import { defineComponent as r } from "vue";
const v = r({
  name: "ButtonComponent",
  components: { Icon: n },
  props: {
    type: {
      type: String,
      default: "button",
      value: ["button", "submit", "reset"]
    },
    /**
     * The URL to navigate to when the button is clicked
     */
    href: {
      type: String,
      default: null,
      value: "url"
    },
    /**
     * Alternative method for display text on the button
     */
    text: {
      type: String,
      default: null,
      value: "text"
    },
    /**
     * Label of button to be used where there is an icon with no text
     */
    ariaLabel: {
      type: String,
      default: null
    },
    /**
     * Name of the predefined icon to display to left of text
     */
    iconLeft: {
      type: [String, Object],
      default: null,
      value: s
    },
    /**
     * Name of the predefined icon to display to right of text
     */
    iconRight: {
      type: [String, Object],
      default: null,
      value: s
    },
    /**
     * Number to display on the icon
     */
    count: {
      type: Number,
      default: null
    },
    /**
     * Name of the predefined color to style the component with
     */
    color: {
      type: String,
      default: "is-light-grey",
      validator: /* @__PURE__ */ t((e) => Object.values(l).includes(e), "validator"),
      value: l
    },
    /**
     * Name of the predefined size to scale the component with
     */
    size: {
      type: String,
      default: "is-normal",
      validator: /* @__PURE__ */ t((e) => Object.values(o).includes(e), "validator"),
      value: o
    },
    /**
     * Whether the component should be disabled
     */
    isDisabled: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether the component should span the full width of its container
     */
    isFullwidth: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether a loading animation should be displayed on the component
     */
    isLoading: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether the color will be primaraly used to style the border of the component
     */
    isOutlined: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether the border of the component will be rounded
     */
    isRounded: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether the component should be themed
     */
    isThemed: {
      type: Boolean,
      default: !0
    },
    /**
     * Whether the component should be display as being active
     */
    isActive: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether the component should be display as being compact
     */
    isCompact: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether the component should be display as being focused on
     */
    isFocused: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether the component should be display as being hovered on
     */
    isHovered: {
      type: Boolean,
      default: !1
    },
    /**
     * Href target
     */
    target: {
      type: String,
      default: "_blank",
      value: ["_blank", "_self", "_parent", "_top"]
    },
    /**
     * Whether the component should be focused on when the page is loaded
     */
    autofocus: {
      type: Boolean,
      default: !1
    },
    /**
     * Shade of color to use
     */
    shade: {
      type: String,
      default: null,
      value: d
    },
    /**
     * Tooltip to display on hover
     */
    tooltip: {
      type: String,
      default: null
    }
  },
  emits: ["click", "click:disabled"],
  data: /* @__PURE__ */ t((e) => ({
    $cypress: u({ ctx: e, labelField: ["text"] })
  }), "data"),
  computed: {
    buttonClasses() {
      const e = {
        "is-fullwidth": this.isFullwidth,
        "is-loading": this.isLoading,
        "is-outlined": this.isOutlined,
        "is-compact": this.isCompact,
        "is-rounded": this.isRounded,
        "is-active": this.isActive,
        "is-focused": this.isFocused,
        "is-hovered": this.isHovered,
        "is-themed": this.isThemed,
        "is-icon-button": this.compHasText === !1,
        "has-icon-right": this.iconRight !== null,
        "has-icon-left": this.iconLeft !== null,
        [this.color ?? ""]: this.isThemed,
        [this.size ?? ""]: !0,
        [this.shade ?? ""]: this.isThemed
      };
      return Object.keys(e).filter((i) => e[i]).join(" ");
    },
    compHasText() {
      return this.$slots.default !== void 0 || this.text !== null;
    },
    compToolTip() {
      return this.tooltip;
    },
    compIsLink() {
      return this.href !== null && this.href.length > 0;
    }
  },
  mounted() {
    !this.compHasText && this.ariaLabel === null && this.tooltip === null && console.error(
      "Button has no text. Add text else add ariaLabel or tooltip if it's an icon button.",
      this.$el
    );
  },
  methods: {
    click(e) {
      e instanceof KeyboardEvent && [" ", "Enter"].includes(e.key) && (window.Kernel.KeyboardNavigation = !0), this.isDisabled ? this.$emit("click:disabled", e) : this.href === null && this.$emit("click", e);
    }
  }
});
export {
  v as _
};
