var r = Object.defineProperty;
var c = (e, t, a) => t in e ? r(e, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[t] = a;
var o = (e, t) => r(e, "name", { value: t, configurable: !0 });
var s = (e, t, a) => c(e, typeof t != "symbol" ? t + "" : t, a);
import { ValidationType as d } from "../core/validation-type.js";
const i = "checked", h = /* @__PURE__ */ o((e = d.Error) => {
  var t;
  return t = class {
    constructor(a) {
      this.data = a;
    }
    getResult() {
      return this.data.value !== !0 ? {
        message: "This field should be checked.",
        type: e
      } : null;
    }
  }, s(t, "validatorName", i), t;
}, "CheckedValidatorConstructor");
export {
  h as CheckedValidatorConstructor,
  i as CheckedValidatorName
};
