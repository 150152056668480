// EmailCampaignLog

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { EmailCampaignLog } from "@kinherit/sdk/model/email-campaign-log";

export const DeleteEmailCampaignLog = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/email-campaign/log/{emailCampaignLog}",
  method: "delete",
  parse: (emailCampaignLog: EmailCampaignLog | string) => ({
    params: {
      emailCampaignLog,
    },
  }),
  after: ({ message }) => {
    EmailCampaignLog.$delete(message);
  },
});
