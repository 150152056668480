// Trustee

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Trustee } from "@kinherit/sdk/model/trustee";

export const DeleteTrustee = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/trustee/{trustee}",
  method: "delete",
  parse: (trustee: Trustee) => ({
    params: {
      trustee,
      kintin: trustee.$data.kintin,
    },
  }),
  after: ({ message }) => {
    Trustee.$delete(message);
  },
});
