<template>
  <Card :title="name" :title-size="'is-4'" class="guardian-summary">
    <DataListWidget
      no-card
      is-compact
      :details="[
        {
          label: 'Guardian',
          value: role.forPerson.profile.fullName,
        },
        {
          label: 'Organisation',
          value: role.forPerson.profile.organisationName,
          hide: null === role.forPerson.profile.organisationName,
        },
        {
          label: 'Notes',
          value: role.notes,
          hide: null === role.notes,
        },
      ]"
    />
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit"
        color="is-plain"
        @click="$emit('edit', $event)"
      />
      <Button
        icon-left="Trash"
        aria-label="Delete"
        color="is-plain"
        @click="$emit('delete', $event)"
      />
      <slot name="buttons" />
    </template>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "GuardianSummaryWrapper"
}
</cypress-wrapper>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { Guardian, Kintin } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GuardianSummary",
  components: { Card, DataListWidget, Button },
  props: {
    role: {
      type: Guardian as PropType<Guardian>,
      required: true,
    },
    kintin: {
      type: Kintin as PropType<Kintin>,
      required: true,
    },
  },
  emits: ["edit", "delete"],
  computed: {
    name(): string {
      const person = this.role.person;
      const profile = person?.profile;

      if (!person || !profile) {
        return "";
      }

      let name = profile.fullName;

      if (person.profile.knownAs) {
        if (null === name) {
          name = person.profile.knownAs;
        } else {
          name += ` (${person.profile.knownAs})`;
        }
      }

      return name as string;
    },
  },
});
</script>
