// IntroducerBillingContact

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerBillingContact } from "@kinherit/sdk/model/introducer-billing-contact";

export const UpdateIntroducerBillingContact = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/billing-contacts/{introducerBillingContact}",
  method: "patch",
  parse: (target: IntroducerBillingContact) => ({
    body: {
      notes: target.$data.notes,
      isGlobal: target.$data.isGlobal,
      profile: target.$data.profile,
      company: target.$data.company,
      introducerRemittanceType: target.$data.introducerRemittanceType,
      agentRemittanceType: target.$data.agentRemittanceType,
    },
    params: {
      introducerBillingContact: target,
    },
  }),
});
