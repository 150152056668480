import { Api } from "@kinherit/sdk";

export interface UpdateSortReferCaseHandlerMessage {
  externalId: string;
  userId: string;
}

export const UpdateSortReferCase = async (
  message: UpdateSortReferCaseHandlerMessage,
): Promise<void> => {
  const request = Api.resource(
    "external",
    "/v2/external/sort-refer/case-handler",
  )
    .method("put")
    .body({
      externalId: message.externalId,
      userId: message.userId,
    });

  await request.result();
};
