// Address

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Address } from "@kinherit/sdk/model/address";

export const CreateAddress = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/address",
  method: "post",
  parse: (target: Address) => ({
    body: {
      line1: target.$data.line1,
      line2: target.$data.line2,
      city: target.$data.city,
      state: target.$data.state,
      postcode: target.$data.postcode,
      country: target.$data.country,
      summary: target.$data.summary,
      primaryResidential: target.$data.primaryResidential,
      primaryMailing: target.$data.primaryMailing,
      profile: target.$data.profile,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
