import { Api } from "@/service/api.service";
import { Kintin, SignDoc } from "@kinherit/sdk";

export interface GetStripeIdentityVerificationFileMessage {
  kintin: string | Kintin;
  signDoc: string | SignDoc;
  fileId: string;
}

export interface GetStripeIdentityVerificationFileResponse {
  fileUrl: string;
}

export const ReadIdentityVerificationSessionFile = async (
  message: GetStripeIdentityVerificationFileMessage,
): Promise<GetStripeIdentityVerificationFileResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/sign-doc/{kintin}/sign-docs/{signDoc}/identity-verification-session/files/{fileId}",
    {
      kintin: message.kintin,
      signDoc: message.signDoc,
      fileId: message.fileId,
    },
  ).method("get");
  const response = await request.result();

  console.log("response", response);
  return response;
};
