var o = Object.defineProperty;
var t = (e, l) => o(e, "name", { value: l, configurable: !0 });
import n from "../../_vue/component.display/icon-text/icon-text.js";
import a from "../../_vue/component.display/icon/icon.js";
import i from "../../_vue/component.display/title/title.js";
import { CypressHelper as p } from "../../cypress/component-helpers.js";
import { ThemeFontSize as r } from "../../theme/prop/font-size.js";
import { defineComponent as s } from "vue";
const S = s({
  name: "CardComponent",
  components: { Title: i, IconText: n, Icon: a },
  props: {
    /**
     * Whether or not the overflow menu icon should be displayed
     */
    hasContextMenu: {
      type: Boolean,
      default: !1
    },
    /**
     * Title of the card
     */
    title: {
      type: String,
      default: null
    },
    /**
     * Title Size of the card
     */
    titleSize: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((e) => Object.values(r).includes(e), "validator")
    },
    /**
     * Title heading tag e.g. h2
     */
    titleHeadingTag: {
      type: String,
      default: null
    },
    /**
     * Message of the card
     */
    subtitle: {
      type: String,
      default: null
    },
    /**
     * Show the help icon be displayed
     */
    hasHelp: {
      type: Boolean,
      default: !1
    },
    /**
     * Hide the cards color styling
     */
    isPlain: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether the buttons slot should appear at the bottom of the card under x width
     */
    hasResponsiveButtons: {
      type: Boolean,
      default: !1
    }
  },
  emits: {
    /**
     * Emits when the cards help icon-text has been clicked
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    help: /* @__PURE__ */ t((e) => !0, "help"),
    /**
     * Emits when the overflow menu icon has been clicked
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    "context-menu": /* @__PURE__ */ t((e) => !0, "context-menu")
  },
  data: /* @__PURE__ */ t((e) => ({
    $cypress: p({ ctx: e, labelField: ["title", "subtitle"] })
  }), "data")
});
export {
  S as _
};
