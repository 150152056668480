// CreditNote, Note, User, Profile, Order, Kintin

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { CreditNote } from "@kinherit/sdk/model/credit-note";

export const ReadCreditNote = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/credit-note",
  method: "get",
  rootModel: CreditNote,
  parse: () => ({}),
});
