var n = Object.defineProperty;
var o = (s, r) => n(s, "name", { value: r, configurable: !0 });
import { RouteQueryHelper as a } from "../core/route-query-helper.js";
import { defineComponent as p } from "vue";
const g = /* @__PURE__ */ o((s = !0, r = "query") => p({
  name: "RouteStorageMixin",
  props: {
    /**
     * Update the URL with components state, pass a string to be used as a prefix
     */
    persistState: {
      type: [Boolean, String],
      default: s
    },
    /**
     * The storage method to use for persisting state
     */
    persistMethod: {
      type: String,
      default: r
    }
  },
  methods: {
    getState(t) {
      if (this.persistState === !1)
        return;
      const e = this.persistState === "string" ? `${this.persistState}_` : "";
      if (this.persistMethod === "query")
        return a.getQuery(`${e}${t}`);
      if (this.persistMethod === "session")
        return sessionStorage.getItem(`${e}${t}`);
      if (this.persistMethod === "local")
        return localStorage.getItem(`${e}${t}`);
    },
    setState(t, e) {
      if (this.persistState === !1 || this.getState(t) === e)
        return;
      const i = this.persistState === "string" ? `${this.persistState}_` : "";
      this.persistMethod === "query" && a.setQuery(`${i}${t}`, e), this.persistMethod === "session" && sessionStorage.setItem(`${i}${t}`, e), this.persistMethod === "local" && localStorage.setItem(`${i}${t}`, e);
    }
  }
}), "RouteStorageMixin");
export {
  g as default
};
