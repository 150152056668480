// IntroducerCompany, Profile, IntroducerCompany, Address, EmailAddress, PhoneNumber, Website, IntroducerNote, Note, CompanyPartnerPortalPermissions

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const RecordIntroducerCompany = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/company/{introducerCompany}",
  method: "get",
  rootModel: IntroducerCompany,
  parse: (introducerCompany: IntroducerCompany | string) => ({
    params: {
      introducerCompany,
    },
  }),
});
