import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateUsers } from "./create-users.action";
import { ReadUsers } from "./read-users.action";

export const Portal2AdminUsersStaff = DefineActionBus({
  name: "portal-admin-users-staff",
  actions: {
    ReadUsers,
    CreateUsers,
  },
});
