// AccountReferralCode, Address, Adviser, Attorney, Beneficiary, CallScript, EmailAddress, Exclusion, Executor, Gift, Guardian, Kintin, Person, PhoneNumber, Profile, Trustee, Website

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";

export const AddressBookRecord = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/address-book",
  method: "get",
  rootModel: Kintin,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});
