import { Api } from "@kinherit/sdk/api";

//
export const ReconcileProfiles = async (options: {
  minDate: string;
  maxDate: string;
}) => {
  return await Api.resource(
    "portal",
    "/v2/portal/settings/acuity/reconcile-profiles",
  )
    .method("put")
    .body({
      maxDate: options.maxDate,
      minDate: options.minDate,
    })
    .result();
};
