<template>
  <div class="kinvault-subscription-referral-code-master-list">
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      title="Referral Codes"
      subtitle="Used to track referrals and provide discounts"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        createdAt: 'Created At',
      }"
      @refresh="refresh"
      @row-clicked="handleClick"
    >
      <template #buttons>
        <Button
          icon-left="Plus"
          aria-label="Create Referral Code"
          text="New Referral Code"
          @click="create"
        />
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { ReadKinvaultSubscriptionReferralCodeForm } from "@/module/kinvault.subscription/form/read-kinvault-subscription-referral-code.form";
import { UpdateKinvaultSubscriptionReferralCodeForm } from "@/module/kinvault.subscription/form/update-kinvault-subscription-referral-code.form";
import { KinvaultSubscriptionReferralCodeMasterListRoute } from "@/module/kinvault.subscription/page";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, In } from "@kinherit/orm/index";
import {
  IKinvaultSubscriptionReferralCode,
  KinvaultSubscriptionReferralCode,
} from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: KinvaultSubscriptionReferralCodeMasterListRoute,
  components: { MasterListPage, Button },
  mixins: [
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal.kinvaults.subscription.referralCode,
    ),
  ],
  data: () => ({
    filters: ReadKinvaultSubscriptionReferralCodeForm(),
    columns: [
      {
        title: "Coupon",
        map: (val) => {
          if (!val.coupon) {
            return "";
          }

          let discount = "";

          if (val.coupon.amountOff) {
            discount = ` - £${val.coupon.amountOff}`;
          } else if (val.coupon.percentOff) {
            discount = ` - ${val.coupon.percentOff}%`;
          }

          return `${val.coupon.name}${discount}`;
        },
      },
      {
        title: "Code",
        field: "code",
      },
      {
        title: "Price",
        map: (val) => val.price?.price?.toFormattedString(),
      },
      {
        title: "Introducer Company",
        map: (val) => val.introducerCompany?.profile?.organisationName,
      },
    ] as Array<TableColumn<KinvaultSubscriptionReferralCode>>,
    rows: Array<KinvaultSubscriptionReferralCode>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IKinvaultSubscriptionReferralCode,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    async handleClick(
      kinvaultSubscriptionReferralCode: KinvaultSubscriptionReferralCode,
    ): Promise<void> {
      const form = UpdateKinvaultSubscriptionReferralCodeForm(
        kinvaultSubscriptionReferralCode,
      );

      try {
        await form.dialog({
          dialog: {
            title: "Update Referral Code",
          },
        });
        await this.$actionBus.UpdateKinvaultSubscriptionReferralCode(
          form.localData,
        );
      } catch {
        kinvaultSubscriptionReferralCode.$restore();
      }

      this.refresh(this.filters.localData);
    },
    async create(): Promise<void> {
      const form = UpdateKinvaultSubscriptionReferralCodeForm();

      try {
        await form.dialog({
          dialog: {
            title: "Create Referral Code",
          },
        });
        await this.$actionBus.CreateKinvaultSubscriptionReferralCode(
          form.localData,
        );
      } catch {
        form.localData.$delete();
      }

      this.refresh(this.filters.localData);
    },
    async refresh(
      formData: ReturnType<
        typeof ReadKinvaultSubscriptionReferralCodeForm
      >["localData"],
    ): Promise<void> {
      const data = await this.$actionBus.ReadKinvaultSubscriptionReferralCode({
        query: {
          price: {
            id: In(formData.price),
          },
          coupon: {
            id: In(formData.coupon),
          },
          introducerCompany: {
            id: In(formData.introducerCompany),
          },
          createdAt: Between(formData.created),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.kinvaultSubscriptionReferralCode;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
