<template>
  <div class="history-page">
    <PageHeader htag="h2" :text="title" />
    <MasterListPage
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      is-nested
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :is-clickable="false"
      :sort-by-options="{
        date: 'Created',
      }"
      @refresh="refresh"
    >
      <template #details="{ row }: { row: EntityHistory }">
        <HistoryPageRow :row="row" />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "HistoryPageWrapper",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "imports": {
    "ReadActivityLogFormWrapper": "@/shared/form/activity-log/read-activity-log.form.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadActivityLogFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ActivityLogTableColumns } from "@/config/table-columns.config";
import { ReadActivityLogForm } from "@/shared/form/activity-log/read-activity-log.form";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Equal, In, Like } from "@kinherit/orm/index";
import { EntityHistory } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import HistoryPageRow from "./HistoryPageRow.vue";

export default defineComponent({
  name: "HistoryPage",
  components: { MasterListPage, PageHeader, HistoryPageRow },
  props: {
    type: {
      type: String as PropType<"user" | "kintin" | "lead">,
      default: null,
    },
    id: {
      type: String as PropType<string>,
      default: null,
    },
  },
  data: (ctx) => {
    const forUser = ctx.type === "user";
    const filters = ReadActivityLogForm(forUser);

    return {
      filters: filters,
      columns: ActivityLogTableColumns(filters, forUser),
      rows: Array<EntityHistory>(),
      pagination: {
        currentPage: 1,
        lastPage: 0,
        perPage: 15,
        count: 0,
      },
      sort: {
        by: "date" as const,
        direction: "desc" as "desc" | "asc",
      },
    };
  },
  computed: {
    title(): string {
      switch (this.type) {
        case "user":
          return "Activity Logs";
        default:
          return "Change History";
      }
    },
  },
  methods: {
    async refresh(): Promise<void> {
      switch (this.type) {
        case "user":
          return this.refreshUser();
        case "kintin":
          return this.refreshKintin();
        case "lead":
          return this.refreshLead();
      }
    },
    async refreshUser(): Promise<void> {
      // const { logs, pagination } =
      //   await window.Kernel.ActionBus.adminUser.activity.read({
      //     sort: this.sort,
      //     pagination: this.pagination,
      //     ...formData,
      //     user: this.id,
      //   });
      const { entityHistory, $pagination } =
        await window.Kernel.ActionBus2.portal.admin.user.ReadUserActivity({
          query: {
            entityName: Like(
              this.filters.localData.subject?.replaceAll(" ", ""),
            ),
            action: Equal(this.filters.localData.action),
            date: Between(this.filters.localData.date),
            route: Equal(this.filters.localData.route),
            origin: Equal(this.filters.localData.origin),
            request: Equal(this.filters.localData.request),
            objectId: Equal(this.filters.localData.objectId),
          },
          sort: this.sort,
          pagination: this.pagination,
          message: this.id,
        });

      const userIds = entityHistory?.pluck("$data.user") ?? [];

      if (userIds.length > 0) {
        await window.Kernel.ActionBus2.core.select.ReadUser({
          query: {
            id: In(userIds),
          },
        });
      }

      this.rows = entityHistory;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
    async refreshKintin(): Promise<void> {
      // const { logs, pagination } =
      //   await window.Kernel.ActionBus.kinvaultKintin.history.read({
      //     sort: this.sort,
      //     pagination: this.pagination,
      //     ...formData,
      //     kintin: this.id,
      //   });

      const { entityHistory, $pagination } =
        await window.Kernel.ActionBus2.portal.kinvault.ReadHistory({
          query: {
            entityName: Like(
              this.filters.localData.subject?.replaceAll(" ", ""),
            ),
            action: Equal(this.filters.localData.action),
            date: Between(this.filters.localData.date),
            route: Equal(this.filters.localData.route),
            origin: Equal(this.filters.localData.origin),
            request: Equal(this.filters.localData.request),
            user: Equal(this.filters.localData.user),
            objectId: Equal(this.filters.localData.objectId),
          },
          sort: this.sort,
          pagination: this.pagination,
          message: this.id,
        });

      this.rows = entityHistory;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
    async refreshLead(): Promise<void> {
      // const { logs, pagination } =
      //   await window.Kernel.ActionBus.lead.history.read({
      //     sort: this.sort,
      //     pagination: this.pagination,
      //     ...formData,
      //     lead: this.id,
      //   });

      const { entityHistory, $pagination } =
        await window.Kernel.ActionBus2.portal.lead.ReadHistory({
          query: {
            entityName: Like(
              this.filters.localData.subject?.replaceAll(" ", ""),
            ),
            action: Equal(this.filters.localData.action),
            date: Between(this.filters.localData.date),
            route: Equal(this.filters.localData.route),
            origin: Equal(this.filters.localData.origin),
            request: Equal(this.filters.localData.request),
            user: Equal(this.filters.localData.user),
            objectId: Equal(this.filters.localData.objectId),
          },
          sort: this.sort,
          pagination: this.pagination,
          message: this.id,
        });

      this.rows = entityHistory;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>

<style lang="scss">
.history-page {
  table td {
    display: table-cell !important;
  }
}
</style>
