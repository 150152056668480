var y = Object.defineProperty;
var p = (r) => {
  throw TypeError(r);
};
var C = (r, t, n) => t in r ? y(r, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : r[t] = n;
var a = (r, t) => y(r, "name", { value: t, configurable: !0 });
var d = (r, t, n) => C(r, typeof t != "symbol" ? t + "" : t, n), v = (r, t, n) => t.has(r) || p("Cannot " + n);
var f = (r, t, n) => t.has(r) ? p("Cannot add the same private member more than once") : t instanceof WeakSet ? t.add(r) : t.set(r, n);
var g = (r, t, n) => (v(r, t, "access private method"), n);
import { KernelModes as w } from "../core/kernel-mode.js";
import { findColorLight as P, findColorDark as j, findColorInvert as x, convertColor as O } from "@kinherit/ts-common/service/colors";
var i, h;
const c = class c {
  static apply(t) {
    Object.keys(t.color ?? {}).forEach((n) => {
      const e = t.color[n];
      this.applyColor(n, e);
    }), Object.keys(t.font ?? {}).forEach((n) => {
      const e = t.font[n];
      this.applyFont(n, e.fontUrl, e.fontName);
    });
  }
  /**
   * Set the value of a css color, this will override all
   * variants of the color name (e.g. light, dark, invert)
   * by overriding the individual HSLA variables causing
   * the change to cascade through the stylesheet.
   */
  static applyColor(t, n) {
    const e = g(this, i, h).call(this, {
      colorName: t,
      color: n
    });
    Object.keys(e).forEach((o) => {
      document.documentElement.style.setProperty(
        o,
        e[o]
      );
    });
  }
  static getColor(t, n) {
    let e = t;
    n && (e += `-${n}`);
    try {
      return getComputedStyle(
        document.documentElement.getElementsByTagName("body")[0]
      ).getPropertyValue(e).replace(" ", "");
    } catch {
      return "#000000";
    }
  }
  static async applyFont(t, n, e) {
    if (c._currentFonts[t] = n, window.Kernel.Mode !== w.UnitTest) {
      const o = await new FontFace(
        e,
        n.replace("http:", "https:")
      ).load();
      document.fonts.add(o);
    }
    document.documentElement.getElementsByTagName("body")[0].style.setProperty(`--font-${t}`, `'${e}'`);
  }
  static getFont(t) {
    return c._currentFonts[t] || null;
  }
  /**
   * Set the value of a css variable
   */
  static applyCssVar(t, n) {
    document.documentElement.style.setProperty(t, n);
  }
};
i = new WeakSet(), h = /* @__PURE__ */ a(function({
  colorName: t,
  color: n,
  shades: e
}) {
  e || (e = ["light", "dark", "invert"]);
  const o = {}, l = /* @__PURE__ */ a((s, E) => {
    const u = O({
      color: E,
      target: "hsla"
    }), [F, k, m, b] = u.substring(5, u.length - 1).split(",");
    o[`${s}-h`] = F, o[`${s}-s`] = k, o[`${s}-l`] = m, o[`${s}-a`] = b;
  }, "extractParts");
  return l(t, n), e.includes("light") && l(`${t}-light`, P(n)), e.includes("dark") && l(`${t}-dark`, j(n)), e.includes("invert") && l(`${t}-invert`, x(n)), o;
}, "#extractColor"), f(c, i), a(c, "ThemeCssBridge"), d(c, "_currentFonts", {});
let $ = c;
export {
  $ as ThemeCssBridge
};
