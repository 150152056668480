<template>
  <div
    v-for="(options, title) in items"
    :key="`widget-${title}`"
    class="admin-log-sidebar"
  >
    <div v-if="title" class="admin-log-sidebar__title">
      {{ title }}
    </div>
    <Tabs
      v-if="options.length > 0"
      v-model:value="selected"
      class="sidebar-page__tabs"
      is-vertical
      :config="options"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AdminLogSidebarWrapper",
  "methods": {
    "tabs": {
      "type": "tabs",
      "selector": ".sidebar-page__tabs",
      "items": ["Failed Logins", "Email Log", "Notification Log"]
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import Tabs from "@kinherit/framework/component.display/tabs";
import { defineComponent } from "vue";
import {
  EmailLogMasterListRoute,
  FailedLoginAttemptsMasterListRoute,
  FailedPasswordResetAttemptsMasterListRoute,
  KinvaultAccessRequestsMasterListRoute,
  NotificationLogMasterListRoute,
} from ".";

import { RouteTabOptions } from "@kinherit/framework/component.display/tabs/types";

const items: Record<"Auth" | "Email", RouteTabOptions[]> = {
  Auth: [
    {
      label: "Failed Logins",
      route: {
        name: FailedLoginAttemptsMasterListRoute,
      },
    },
    {
      label: "Failed Password Resets",
      route: {
        name: FailedPasswordResetAttemptsMasterListRoute,
      },
    },
    {
      label: "Kinvault Access Requests",
      route: {
        name: KinvaultAccessRequestsMasterListRoute,
      },
    },
  ],
  Email: [
    {
      label: "Email Log",
      route: {
        name: EmailLogMasterListRoute,
      },
    },
    {
      label: "Notification Log",
      route: {
        name: NotificationLogMasterListRoute,
      },
    },
  ],
};

export default defineComponent({
  name: "AdminLogSidebar",
  components: {
    Tabs,
  },
  data: () => ({
    items,
  }),
  computed: {
    selected: {
      get(): RouteTabOptions {
        return (
          Object.values(items)
            .flat()
            .find((tab) => {
              if (
                "string" !== typeof tab.route &&
                "name" in tab.route &&
                tab.route.name === this.$route.name
              ) {
                return true;
              }

              return false;
            }) ?? items["Auth"][0]
        );
      },
      set({ route }: RouteTabOptions) {
        window.Kernel.visitRoute(route);
      },
    },
  },
});
</script>

<style lang="scss">
.admin-log-sidebar {
  &__title {
    border-right: 1px solid var(--color-border);
    padding: 0.5rem 1rem;
    cursor: default;
    @include font-weight(700);
  }
}
</style>
