var U = Object.defineProperty;
var D = (e, o) => U(e, "name", { value: o, configurable: !0 });
import { _ as V } from "../../../component.layout/footer/footer.vue_vue_type_script_lang.js";
import { resolveComponent as C, createElementBlock as i, openBlock as s, mergeProps as l, createBlock as H, resolveDynamicComponent as K, createElementVNode as t, createCommentVNode as r, Fragment as O, renderList as R, createVNode as S, createTextVNode as A, toDisplayString as n, normalizeProps as I, createStaticVNode as P } from "vue";
import { _ as z } from "../../../_plugins/_plugin-vue_export-helper.js";
const E = {
  key: 0,
  class: "site-footer__inner"
}, M = {
  key: 1,
  class: "site-footer__inner"
}, W = { class: "site-footer__logos" }, Y = {
  key: 0,
  class: "site-footer__logo-left"
}, Q = { class: "site-footer__logo-left-wrapper" }, j = ["src", "width"], q = {
  key: 1,
  class: "site-footer__logo-right"
}, G = { class: "site-footer__logo-right-wrapper" }, J = ["src", "width"], X = { class: "site-footer__client-content--client-social" }, Z = ["innerHTML"], x = {
  key: 1,
  class: "site-footer__client-social"
}, ee = ["href", "title"], te = { class: "site-footer__bottom has-border-top" }, oe = { class: "site-footer__powered-by--links" }, se = { class: "site-footer__powered-by" }, ie = ["src"], ne = { class: "site-footer__links" }, re = {
  href: "https://kinvault.com/privacy/",
  target: "kinherit"
}, le = {
  href: "https://kinvault.com/terms/",
  target: "kinherit"
}, ae = { class: "site-footer__copyright" }, de = {
  key: 2,
  class: "site-footer__inner"
}, ce = { class: "site-footer__logos" }, _e = {
  key: 0,
  class: "site-footer__logo-left"
}, fe = { class: "site-footer__logo-left-wrapper" }, he = ["src", "width"], ge = { class: "site-footer__client-content--client-social" }, pe = { class: "site-footer__client-social" }, ve = ["title"], me = ["title"], ue = ["title"], ke = { class: "site-footer__bottom" }, ye = { class: "site-footer__copyright" };
function we(e, o, be, Le, $e, Be) {
  var d, c, _, f, h, g, p, v, m, u, k, y, w, b, L, $, B, T;
  const F = C("Icon");
  return s(), i("footer", l(e.containerBindings, {
    key: e.renderKey,
    class: {
      "site-footer": !0,
      "has-border-top": ((d = e.content) == null ? void 0 : d.hasBorderTop) === !0
    }
  }), [
    (c = e.$data.content) != null && c.component ? (s(), i("div", E, [
      (s(), H(K(e.$data.content.component)))
    ])) : ((_ = e.content) == null ? void 0 : _.type) === "kinvault" ? (s(), i("div", M, [
      t("div", W, [
        (f = e.content) != null && f.leftLogo ? (s(), i("div", Y, [
          t("div", Q, [
            t("img", l(e.leftLogoBindings, {
              src: (g = (h = e.content) == null ? void 0 : h.leftLogo) == null ? void 0 : g.url,
              width: (p = e.content) == null ? void 0 : p.leftLogo.width
            }), null, 16, j)
          ])
        ])) : r("", !0),
        (v = e.content) != null && v.rightLogo ? (s(), i("div", q, [
          t("div", G, [
            t("img", l(e.rightLogoBindings, {
              src: (m = e.content) == null ? void 0 : m.rightLogo.url,
              width: (u = e.content) == null ? void 0 : u.rightLogo.width
            }), null, 16, J)
          ])
        ])) : r("", !0)
      ]),
      t("div", X, [
        (k = e.content) != null && k.clientText ? (s(), i("div", {
          key: 0,
          class: "site-footer__client-content",
          innerHTML: (y = e.content) == null ? void 0 : y.clientText
        }, null, 8, Z)) : r("", !0),
        (w = e.content) != null && w.links ? (s(), i("div", x, [
          (s(!0), i(O, null, R(e.content.links, (a, N) => (s(), i("a", {
            key: `footer-link-${N}`,
            rel: "noopener noreferrer",
            href: a.href,
            title: a.title,
            target: "_blank"
          }, [
            S(F, {
              size: "is-large",
              icon: a.icon
            }, null, 8, ["icon"])
          ], 8, ee))), 128))
        ])) : r("", !0)
      ]),
      t("div", te, [
        t("div", oe, [
          t("div", se, [
            A(n(e.$t.local.poweredBy) + " ", 1),
            t("img", {
              src: e.isDark ? "/image/Kinvault_Logo_White.svg" : "/image/Kinvault_Logo_Dark.svg",
              alt: "kinvault",
              width: "80",
              style: { "vertical-align": "middle" }
            }, null, 8, ie)
          ]),
          t("div", ne, [
            t("a", re, n(e.$t.local.privacyPolicy), 1),
            o[0] || (o[0] = A(" | ")),
            t("a", le, n(e.$t.local.termsOfUse), 1)
          ])
        ]),
        t("div", ae, [
          t("span", null, " © " + n((/* @__PURE__ */ new Date()).getFullYear()) + " " + n(e.$t.local.kinheritLtdAllRightsReserved), 1)
        ])
      ])
    ])) : (s(), i("div", de, [
      ((b = e.content) == null ? void 0 : b.message) !== void 0 ? (s(), i("div", I(l({ key: 0 }, e.messageBindings)), n((L = e.content) == null ? void 0 : L.message), 17)) : r("", !0),
      t("div", ce, [
        ($ = e.content) != null && $.leftLogo ? (s(), i("div", _e, [
          t("div", fe, [
            t("img", l(e.leftLogoBindings, {
              src: (B = e.content) == null ? void 0 : B.leftLogo.url,
              width: (T = e.content) == null ? void 0 : T.leftLogo.width
            }), null, 16, he)
          ])
        ])) : r("", !0),
        o[1] || (o[1] = t("div", { class: "site-footer__logo-right" }, [
          t("div", { class: "site-footer__logo-right-wrapper" })
        ], -1))
      ]),
      t("div", ge, [
        o[5] || (o[5] = P('<div class="site-footer__client-content"><address> Bradbrooke House<br> Almondsbury Business Centre<br> Woodlands, Bradley Stoke<br> Bristol BS32 4QH<br><a href="mailto:info@kinherit.co.uk"><i class="fa fa-envelope"></i> info@kinherit.co.uk </a>  <a href="tel: +44(0)117 302 1888"><i class="fa fa-phone"></i> +44(0)117 302 1888 </a></address></div>', 1)),
        t("div", pe, [
          t("a", {
            rel: "noopener noreferrer",
            href: "https://www.facebook.com/kinherit/",
            title: e.$t.local.findUsOnFacebook,
            target: "_blank"
          }, o[2] || (o[2] = [
            t("span", { class: "icon is-medium" }, [
              t("i", {
                class: "fa-facebook fa-brands is-unselectable",
                style: { color: "var(--color-info)", opacity: "0.9" }
              })
            ], -1)
          ]), 8, ve),
          t("a", {
            rel: "noopener noreferrer",
            href: "https://twitter.com/kinheritltd",
            title: e.$t.local.findUsOnTwitter,
            target: "_blank"
          }, o[3] || (o[3] = [
            t("span", { class: "icon is-medium" }, [
              t("i", {
                class: "fa-twitter fa-brands is-unselectable",
                style: { color: "var(--color-info)", opacity: "0.9" }
              })
            ], -1)
          ]), 8, me),
          t("a", {
            rel: "noopener noreferrer",
            href: "https://www.linkedin.com/company/kinherit-ltd",
            title: e.$t.local.findUsOnLinkedin,
            target: "_blank"
          }, o[4] || (o[4] = [
            t("span", { class: "icon is-medium" }, [
              t("i", {
                class: "fa-linkedin fa-brands is-unselectable",
                style: { color: "var(--color-info)", opacity: "0.9" }
              })
            ], -1)
          ]), 8, ue)
        ])
      ]),
      t("div", ke, [
        t("div", null, [
          t("span", null, n(e.$t.local.registeredInUK), 1)
        ]),
        t("div", ye, [
          t("span", null, " © " + n((/* @__PURE__ */ new Date()).getFullYear()) + " " + n(e.$t.local.allRightsReserved), 1)
        ])
      ])
    ]))
  ], 16);
}
D(we, "_sfc_render");
const Ne = /* @__PURE__ */ z(V, [["render", we]]);
export {
  Ne as default
};
