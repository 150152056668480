var t = Object.defineProperty;
var n = (e, o) => t(e, "name", { value: o, configurable: !0 });
import i from "../../_vue/component.display/icon/icon.js";
import r from "../../_vue/component.form/form/form.js";
import { TranslationMixin as s } from "../../component.mixin/translation.mixin.js";
import { ThemeCssBridge as d } from "../../theme/bridge.js";
import { isLightColor as m } from "@kinherit/ts-common/service/colors";
import { defineComponent as g } from "vue";
const K = g({
  name: "FooterComponent",
  components: { Icon: i, Form: r },
  mixins: [
    s({
      defaults: {
        poweredBy: "Powered by",
        privacyPolicy: "Privacy Policy",
        termsOfUse: "Terms of Use",
        allRightsReserved: "All rights reserved",
        findUsOnFacebook: "Find us on Facebook",
        findUsOnTwitter: "Find us on Twitter",
        findUsOnLinkedin: "Find us on Linkedin",
        registeredInUK: "Registered in the UK | Company number 11500962",
        kinheritLtdAllRightsReserved: "Kinherit LTD. All rights reserved."
      }
    })
  ],
  data: /* @__PURE__ */ n(() => ({
    addressBindings: window.Kernel.ComponentOptions.Footer.getElementBindings("address"),
    containerBindings: window.Kernel.ComponentOptions.Footer.getElementBindings("container"),
    messageBindings: window.Kernel.ComponentOptions.Footer.getElementBindings("message"),
    leftLogoBindings: window.Kernel.ComponentOptions.Footer.getElementBindings("leftLogo"),
    rightLogoBindings: window.Kernel.ComponentOptions.Footer.getElementBindings("rightLogo"),
    content: window.Kernel.ComponentOptions.Footer.content,
    isDark: !1,
    renderKey: 0
  }), "data"),
  mounted() {
    window.Kernel.ComponentOptions.Footer.setRefresh(this.refresh), window.Kernel.ComponentOptions.Footer.setIncrementRenderKey(
      this.incrementRenderKey
    ), this.refresh();
  },
  methods: {
    refresh() {
      this.addressBindings = window.Kernel.ComponentOptions.Footer.getElementBindings("address"), this.containerBindings = window.Kernel.ComponentOptions.Footer.getElementBindings("container"), this.leftLogoBindings = window.Kernel.ComponentOptions.Footer.getElementBindings("leftLogo"), this.rightLogoBindings = window.Kernel.ComponentOptions.Footer.getElementBindings("rightLogo"), this.content = window.Kernel.ComponentOptions.Footer.content;
      const e = d.getColor("--footer-background");
      e && (this.isDark = m(e) === !1), this.$forceUpdate();
    },
    incrementRenderKey() {
      this.renderKey++;
    }
  }
});
export {
  K as _
};
