var C = Object.defineProperty;
var u = (e, o) => C(e, "name", { value: o, configurable: !0 });
import { _ as f } from "../../../component.input/phone-number-field/phone-number-field.vue_vue_type_script_lang.js";
import { resolveComponent as r, createBlock as a, openBlock as i, mergeProps as b, createSlots as y, withCtx as n, createCommentVNode as h, renderSlot as d, createElementVNode as p, normalizeClass as v, createVNode as k } from "vue";
import { b as m } from "../../../_assets/component.input/phone-number-field/phone-number-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as $ } from "../../../_plugins/_plugin-vue_export-helper.js";
const w = { class: "field has-addons" };
function z(e, o, B, F, S, V) {
  const t = r("Button"), c = r("BaseInputField"), g = r("AutoCompleteField");
  return e.selectingCountry ? (i(), a(g, {
    key: 1,
    value: e.country,
    "onUpdate:value": o[5] || (o[5] = (l) => e.country = l),
    options: e.countries,
    "map-options": {
      label: "name",
      value: "code"
    },
    label: e.label
  }, {
    left: n(() => [
      k(t, {
        color: "is-success",
        "icon-left": "Success",
        "aria-label": e.$t.local.finishSelectingCountry,
        onClick: o[4] || (o[4] = (l) => e.selectingCountry = !1)
      }, null, 8, ["aria-label"])
    ]),
    _: 1
  }, 8, ["value", "options", "label"])) : (i(), a(c, b({ key: 0 }, e.$data.$cypress, {
    ref: "baseInput",
    value: e.computedPhoneNumber,
    "onUpdate:value": o[2] || (o[2] = (l) => e.computedPhoneNumber = l),
    class: `${e.wrapperClasses} phone-number-field`,
    label: e.label,
    "input-tag": "input",
    type: "tel",
    placeholder: e.placeholder,
    validators: e.computedValidators,
    "icon-left": e.iconLeftComputed,
    "icon-right-override": e.computedIconRight,
    message: e.message,
    "message-color": e.messageColor,
    size: e.size,
    color: e.color,
    "is-loading": e.isLoading,
    state: e.state,
    "show-validation": e.computedShowValidation,
    "left-icon-click": e.leftIconClick,
    "right-icon-click": e.rightIconClick,
    "is-fullwidth": e.isFullwidth,
    "auto-collapse-label": e.autoCollapseLabel,
    "is-grouped": e.isGrouped,
    "show-clear-button": e.showClearButton,
    autofocus: e.autofocus,
    autocomplete: e.autocomplete,
    onClear: e.clear,
    onFocus: e.focus,
    onBlur: e.blur,
    onIsValid: o[3] || (o[3] = (l) => e.$emit("is-valid", l)),
    onSubmit: e.keydown
  }), y({
    right: n(() => {
      var l;
      return [
        e.country !== null && !e.hideCountryLookup ? (i(), a(t, {
          key: 0,
          color: "is-light-grey",
          size: e.size,
          "aria-label": (l = e.country) == null ? void 0 : l.name,
          onClick: o[0] || (o[0] = (s) => e.selectingCountry = !0)
        }, {
          default: n(() => {
            var s;
            return [
              p("div", {
                class: v((s = e.country) == null ? void 0 : s.flagCssClass)
              }, null, 2)
            ];
          }),
          _: 1
        }, 8, ["size", "aria-label"])) : e.hideCountryLookup ? h("", !0) : (i(), a(t, {
          key: 1,
          size: e.size,
          "icon-left": "Search",
          color: "is-light-grey",
          "aria-label": e.$t.local.selectCountry,
          onClick: o[1] || (o[1] = (s) => e.selectingCountry = !0)
        }, null, 8, ["size", "aria-label"])),
        d(e.$slots, "right", { value: e.computedValue })
      ];
    }),
    _: 2
  }, [
    e.showLeftControl ? {
      name: "left",
      fn: n(() => [
        p("div", w, [
          d(e.$slots, "left", { value: e.computedValue })
        ])
      ]),
      key: "0"
    } : void 0
  ]), 1040, ["value", "class", "label", "placeholder", "validators", "icon-left", "icon-right-override", "message", "message-color", "size", "color", "is-loading", "state", "show-validation", "left-icon-click", "right-icon-click", "is-fullwidth", "auto-collapse-label", "is-grouped", "show-clear-button", "autofocus", "autocomplete", "onClear", "onFocus", "onBlur", "onSubmit"]));
}
u(z, "_sfc_render");
typeof m == "function" && m(f);
const U = /* @__PURE__ */ $(f, [["render", z]]);
export {
  U as default
};
