// IntroducerFeePaymentRun, IntroducerFeePayment, IntroducerFeePaymentItem

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { IntroducerFeePaymentRun } from "@kinherit/sdk/model/introducer-fee-payment-run";

export const DeleteIntroducerFeePaymentRun = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/fees/payment-run/{introducerFeePaymentRun}",
  method: "delete",
  parse: (introducerFeePaymentRun: IntroducerFeePaymentRun | string) => ({
    params: {
      introducerFeePaymentRun,
    },
  }),
  after: ({ message }) => {
    IntroducerFeePaymentRun.$delete(message);
  },
});
