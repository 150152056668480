var n = Object.defineProperty;
var e = (t, o) => n(t, "name", { value: o, configurable: !0 });
import a from "../../_vue/component.display/title/title.js";
import { defineComponent as s } from "vue";
import { S as r } from "../../_assets/component.layout/page-header/page-header.module.js";
const d = s({
  name: "PageHeaderComponent",
  components: { Title: a },
  props: {
    htag: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ e((t) => ["h1", "h2", "h3", "h4", "h5", "h6"].includes(t), "validator")
    },
    /**
     * Text to display in the title
     */
    text: {
      type: String,
      default: null,
      value: "text"
    }
  },
  data: /* @__PURE__ */ e(() => ({}), "data"),
  computed: {
    compHasButtons() {
      return Object.keys(this.$slots).includes("buttons");
    }
  },
  watch: {},
  beforeMount() {
    window.Kernel.injectStylesheet("page-header", r);
  },
  methods: {}
});
export {
  d as _
};
