// EmailLog, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultAccessRequest } from "@kinherit/sdk";

export const ReadKinvaultAccessRequests = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/log/kinvault-access-requests",
  method: "get",
  rootModel: KinvaultAccessRequest,
  parse: () => ({}),
});
