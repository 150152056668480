var g = Object.defineProperty;
var u = (t, n) => g(t, "name", { value: n, configurable: !0 });
import { _ as C } from "../../../component.display/tabs/tabs.vue_vue_type_script_lang.js";
import { resolveComponent as v, createElementBlock as l, openBlock as s, mergeProps as h, createCommentVNode as i, normalizeClass as r, createVNode as c, Fragment as m, renderList as d, createElementVNode as f, renderSlot as k, createBlock as $, toDisplayString as p, withDirectives as w, vShow as z } from "vue";
import { b as y } from "../../../_assets/component.display/tabs/tabs.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as F } from "../../../_plugins/_plugin-vue_export-helper.js";
const L = ["tabindex", "aria-selected", "data-label", "onClick"], D = { class: "tabs__tab-button__label" }, K = {
  key: 1,
  class: "tabs__tab-content-wrapper"
}, R = ["tabindex", "aria-selected", "data-label", "onClick"], A = { class: "tabs__tab-button__label" };
function B(t, n, N, S, V, E) {
  const b = v("Icon");
  return s(), l("div", h(t.$data.$cypress, {
    class: `tabs ${t.classes}`
  }), [
    ["top", "both"].includes(t.position) ? (s(), l("ul", {
      key: 0,
      role: "tablist",
      class: r(["tabs__control", {
        [t.tabsFontAlign]: !0
      }])
    }, [
      t.iconLeft ? (s(), l("li", {
        key: 0,
        class: "tabs__tab-button is-icon-left",
        onClick: n[0] || (n[0] = (e) => t.$emit("click:left"))
      }, [
        c(b, { icon: t.iconLeft }, null, 8, ["icon"])
      ])) : i("", !0),
      (s(!0), l(m, null, d(t.config, (e, o) => (s(), l("li", {
        key: `tab-button-${o}`,
        class: r(["tabs__tab-button is-unselectable", {
          [`tabs__tab-button-${o}`]: !0,
          "is-active": t.showActive && t.computedCurrentTab === o
        }])
      }, [
        f("a", {
          ref_for: !0,
          ref: "refTab",
          tabindex: t.compFocusedTab === o ? 0 : -1,
          role: "tab",
          "aria-selected": t.computedCurrentTab === o,
          class: r({
            [e.fontColor ?? ""]: !0
          }),
          "data-label": e.label,
          onKeydown: n[1] || (n[1] = (a) => t.onKeyDown(a, !1)),
          onClick: /* @__PURE__ */ u((a) => t.handleClick(o, a, !1), "onClick")
        }, [
          k(t.$slots, "tab-item-left", { tab: e }),
          e.icon ? (s(), $(b, {
            key: 0,
            icon: e.icon,
            size: t.size
          }, null, 8, ["icon", "size"])) : i("", !0),
          f("span", D, p(e.label), 1)
        ], 42, L)
      ], 2))), 128)),
      t.iconRight ? (s(), l("li", {
        key: 1,
        class: "tabs__tab-button is-icon-right",
        onClick: n[2] || (n[2] = (e) => t.$emit("click:right"))
      }, [
        c(b, { icon: t.iconRight }, null, 8, ["icon"])
      ])) : i("", !0)
    ], 2)) : i("", !0),
    t.slots.length > 0 ? (s(), l("div", K, [
      (s(!0), l(m, null, d(t.slots, (e, o) => w((s(), l("div", {
        key: `tab-${o}`,
        class: r([
          "tabs__tab-content",
          `tab-${e}`,
          `is-tab-content-${o}`,
          { "is-active": t.currentTab === o || t.slots.length === 1 }
        ])
      }, [
        k(t.$slots, e)
      ], 2)), [
        [z, t.currentTab === o || t.slots.length === 1]
      ])), 128))
    ])) : i("", !0),
    ["bottom", "both"].includes(t.position) ? (s(), l("ul", {
      key: 2,
      role: "tablist",
      class: r(["tabs__control is-inverted", {
        [t.tabsFontAlign]: !0
      }])
    }, [
      t.iconLeft ? (s(), l("li", {
        key: 0,
        class: "tabs__tab-button is-icon-left",
        onClick: n[3] || (n[3] = (e) => t.$emit("click:left"))
      }, [
        c(b, { icon: t.iconLeft }, null, 8, ["icon"])
      ])) : i("", !0),
      (s(!0), l(m, null, d(t.config, (e, o) => (s(), l("li", {
        key: `tab-button-${o}`,
        class: r(["tabs__tab-button is-unselectable", {
          [`tabs__tab-button-${o}`]: !0,
          "is-active": t.computedCurrentTab === o
        }])
      }, [
        f("a", {
          ref_for: !0,
          ref: "refTab",
          tabindex: t.compFocusedTab === o ? 0 : -1,
          role: "tab",
          "aria-selected": t.computedCurrentTab === o,
          class: r({
            [e.fontColor ?? ""]: !0
          }),
          "data-label": e.label,
          onKeydown: n[4] || (n[4] = (a) => t.onKeyDown(a, !0)),
          onClick: /* @__PURE__ */ u((a) => t.handleClick(o, a, !0), "onClick")
        }, [
          k(t.$slots, "tab-item-left", { tab: e }),
          e.icon ? (s(), $(b, {
            key: 0,
            icon: e.icon,
            size: t.size
          }, null, 8, ["icon", "size"])) : i("", !0),
          f("span", A, p(e.label), 1)
        ], 42, R)
      ], 2))), 128)),
      t.iconRight ? (s(), l("li", {
        key: 1,
        class: "tabs__tab-button is-icon-right",
        onClick: n[5] || (n[5] = (e) => t.$emit("click:right"))
      }, [
        c(b, { icon: t.iconRight }, null, 8, ["icon"])
      ])) : i("", !0)
    ], 2)) : i("", !0)
  ], 16);
}
u(B, "_sfc_render");
typeof y == "function" && y(C);
const q = /* @__PURE__ */ F(C, [["render", B]]);
export {
  q as default
};
