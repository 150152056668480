var u = Object.defineProperty;
var p = (e, t, r) => t in e ? u(e, t, { enumerable: !0, configurable: !0, writable: !0, value: r }) : e[t] = r;
var c = (e, t) => u(e, "name", { value: t, configurable: !0 });
var g = (e, t, r) => p(e, typeof t != "symbol" ? t + "" : t, r);
import { ValidationType as y } from "../core/validation-type.js";
import { DateTime as d } from "@kinherit/ts-common/dto/date-time";
const D = "age-over", N = /* @__PURE__ */ c(({
  age: e,
  type: t = y.Error
}) => {
  var r;
  return r = class {
    constructor(a) {
      this.data = a;
    }
    getResult() {
      var m;
      let a = this.data.value;
      if (d.name === ((m = a == null ? void 0 : a.constructor) == null ? void 0 : m.name))
        a = a.formatYMD;
      else if (typeof a != "string")
        return null;
      const i = new d(), s = a.split("-").map((f) => Number.parseInt(f));
      let o = s[0], n = s[1];
      const l = s[2];
      return [o, n, l].includes(void 0) ? null : (i.getDate().getDate() < l && (n = n + 1), i.getDate().getMonth() + 1 < n && (o = o + 1), i.getDate().getFullYear() - o < 18 ? {
        message: `Minimum age is ${e} years old`,
        type: t
      } : null);
    }
  }, g(r, "validatorName", D), r;
}, "AgeOverValidatorConstructor");
export {
  N as AgeOverValidatorConstructor,
  D as AgeOverValidatorName
};
