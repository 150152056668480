var m = Object.defineProperty;
var i = (e, t) => m(e, "name", { value: t, configurable: !0 });
import { OpenComponentDialog as c } from "../../component.config/component-dialog.js";
import d from "../../_vue/component.input/button/button.js";
import { CypressHelper as p } from "../../cypress/component-helpers.js";
import { ThemeColor as l } from "../../theme/prop/color.js";
import { DateTime as a } from "@kinherit/ts-common/dto/date-time";
import { Uuid as f } from "@kinherit/ts-common/service/uuid";
import { defineComponent as y, ref as D } from "vue";
import g from "../../_vue/component.display/pagination/pagination.js";
import w from "../../_vue/component.display/calendar/calendar-event-modal.js";
import { S as v } from "../../_assets/component.display/calendar/calendar.module.js";
const k = y({
  name: "CalendarComponent",
  components: { Pagination: g, Button: d },
  props: {
    min: {
      type: Object,
      default: null
    },
    max: {
      type: Object,
      default: null
    },
    month: {
      type: Number,
      default: new a().getDate().getMonth()
    },
    year: {
      type: Number,
      default: new a().getDate().getFullYear()
    },
    weekStart: {
      type: Number,
      default: 1
    },
    events: {
      type: Array,
      default: /* @__PURE__ */ i(() => [], "default")
    },
    isAgenda: {
      type: Boolean,
      default: !1
    },
    eventsOnly: {
      type: Boolean,
      default: !0
    },
    isWidget: {
      type: Boolean,
      default: !1
    },
    mergeControls: {
      type: Boolean,
      default: !1
    },
    //single date picker
    isDatePicker: {
      type: Boolean,
      default: !0
    },
    value: {
      type: Object,
      default: void 0
    },
    selectFrom: {
      type: Object,
      default: null,
      value: "datetime"
    },
    selectTo: {
      type: Object,
      default: null,
      value: "datetime"
    },
    selectColor: {
      type: String,
      validate: /* @__PURE__ */ i((e) => Object.values(l).includes(e), "validate"),
      default: l.isPrimary,
      value: l
    },
    hideNextButton: {
      type: Boolean,
      default: !1
    },
    hidePreviousButton: {
      type: Boolean,
      default: !1
    },
    showSuggestions: {
      type: Boolean,
      default: !1
    },
    enableViewSwitcher: {
      type: Boolean,
      default: !0
    }
  },
  emits: [
    "update:month",
    "update:year",
    "open-event",
    "date-hovered",
    "update:value"
  ],
  data: /* @__PURE__ */ i((e) => ({
    $cypress: p({ ctx: e }),
    dMonth: 0,
    dYear: 0,
    dFirstYearofDecade: 0,
    selectedDay: null,
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    monthShorts: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    days: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    dayShorts: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    focus: null,
    refDate: D([]),
    transition: "fade-zoom-out",
    view: "month"
  }), "data"),
  computed: {
    compFirstYearofDecade() {
      return Math.round(this.dYear / 10) * 10;
    },
    compYears() {
      const e = [];
      for (let t = this.compCurrentFirstYearDecade; t <= this.compCurrentFirstYearDecade + 9; t++)
        e.push(t);
      return e;
    },
    computedIsAgenda() {
      return this.isWidget === !0 || this.selectedDay !== null ? !1 : this.isAgenda;
    },
    compPagination: {
      //Works out whether the pagination is for the months, years or decades
      get() {
        switch (this.view) {
          case "year":
            return this.compCurrentYear;
          case "decade":
            return this.compCurrentFirstYearDecade;
          default:
            return this.compCurrentMonth;
        }
      },
      set(e) {
        switch (this.view) {
          case "year":
            this.compCurrentYear = e;
            break;
          case "decade":
            this.compCurrentFirstYearDecade = e;
            break;
          default:
            this.compCurrentMonth = e;
            break;
        }
      }
    },
    compSelectedMonth() {
      return this.value ? this.value.getDate().getMonth() : null;
    },
    compSelectedYear() {
      return this.value ? this.value.getDate().getFullYear() : null;
    },
    hasEvents() {
      const e = this.selectedDay ? new a(this.selectedDay.timestamp) : null, t = e ? e.clone().setTime(0, 0, 0) : this.monthStart.clone(), s = e ? e.clone().setTime(23, 59, 59) : t.clone().moveTo("monthEnd");
      return this.events.find(
        (r) => a.hasOverlap([t, s], [r.start, r.end])
      ) !== void 0;
    },
    monthStart() {
      return a.fromDate(
        /* @__PURE__ */ new Date(
          `${this.compCurrentYear}-${this.compCurrentMonth < 10 ? `0${this.compCurrentMonth}` : this.compCurrentMonth}-01T00:00:00`
        )
      );
    },
    daysHeaders() {
      let e = this.weekStart;
      const t = [];
      for (let s = 0; s <= 6; s++)
        t.push(this.isWidget ? this.dayShorts[e] : this.days[e]), e++, e === 7 && (e = 0);
      return t;
    },
    calendarDays() {
      const e = this.monthStart.clone(), t = [];
      let s = 0;
      this.compBufferStart.length === 0 ? s = -1 : (t[0] = [], this.compBufferStart.forEach(
        () => t[0].push({
          date: f.generate(),
          day: 0,
          events: [],
          isAnotherMonth: !0,
          isToday: !1,
          timestamp: 0,
          label: ""
        })
      ));
      let r = 0;
      for (; e.getDate().getMonth() === this.dMonth; )
        e.getDate().getDay() === this.weekStart && s++, t[s] === void 0 && (t[s] = []), t[s].push({
          timestamp: e.timestamp,
          date: e.getDate().getDate(),
          day: e.getDate().getDay(),
          events: this.events.filter(
            (h) => a.hasOverlap(
              [h.start, h.end],
              [e.clone().setTime(0, 0, 0), e.clone().setTime(23, 59, 59)]
            )
          ),
          isAnotherMonth: !1,
          isToday: e.is("today"),
          label: this.days[e.getDate().getDay()]
        }), e.add(1, "day"), r === e.getDate().getDate() && e.add(1, "day"), r = e.getDate().getDate();
      return t;
    },
    compCurrentMonth: {
      get() {
        return this.dMonth + 1;
      },
      set(e) {
        if (this.selectedDay = null, e === 0) {
          this.dMonth = 11, this.dYear = this.dYear - 1, this.$emit("update:month", 11), this.$emit("update:year", this.dYear);
          return;
        }
        if (e === 13) {
          this.dMonth = 0, this.dYear = this.dYear + 1, this.$emit("update:month", 0), this.$emit("update:year", this.dYear);
          return;
        }
        this.dMonth = e - 1, this.$emit("update:month", e - 1);
      }
    },
    compCurrentMonthName() {
      return this.months[this.dMonth];
    },
    compCurrentYear: {
      get() {
        return this.dYear;
      },
      set(e) {
        this.selectedDay = null, this.dYear = e, this.$emit("update:year", e);
      }
    },
    compCurrentFirstYearDecade: {
      get() {
        return this.dFirstYearofDecade;
      },
      set(e) {
        this.dFirstYearofDecade = e;
      }
    },
    compBufferStart() {
      let t = this.monthStart.clone().getDate().getDay() - this.weekStart;
      for (; t < 0; )
        t = t + 7;
      const s = [];
      for (let r = 0; r < t; r++)
        s.push(r);
      return s;
    },
    computedSelectedDate() {
      return this.selectedDay ? new a(this.selectedDay.timestamp) : null;
    },
    compTodaysDate() {
      return {
        date: new a().getDate().getDate(),
        month: new a().getDate().getMonth() + 1,
        year: new a().getDate().getFullYear()
      };
    }
  },
  watch: {
    month: /* @__PURE__ */ i(function() {
      this.dMonth = this.month;
    }, "month"),
    year: /* @__PURE__ */ i(function() {
      this.dYear = this.year;
    }, "year"),
    selectFrom: /* @__PURE__ */ i(function() {
      this.switchView("month", "fade-zoom-in");
    }, "selectFrom"),
    selectTo: /* @__PURE__ */ i(function() {
      this.switchView("month", "fade-zoom-in");
    }, "selectTo")
  },
  beforeMount() {
    window.Kernel.injectStylesheet("calendar", v), this.dMonth = this.month, this.dYear = this.year, this.dFirstYearofDecade = this.compFirstYearofDecade;
  },
  async mounted() {
    this.value && this.showMonth(this.value);
  },
  methods: {
    async dateSelected(e) {
      if (!this.isWithinBounds(e) || !this.isDatePicker && e.events.isEmpty()) return null;
      if (this.isDatePicker)
        this.emitDate(new a(e.timestamp));
      else
        try {
          await c({
            dialog: {
              type: "width-auto",
              title: `${e == null ? void 0 : e.label} ${e == null ? void 0 : e.date} ${this.compCurrentMonthName} ${this.compCurrentYear}`,
              showFooter: !1
            },
            component: {
              component: w,
              props: {
                events: e.events
              },
              emits: {
                "open-event": /* @__PURE__ */ i((t) => {
                  this.$emit("open-event", t);
                }, "open-event")
              }
            },
            value: ""
          });
        } catch {
        }
    },
    emitDate(e) {
      this.$emit("update:value", e), this.$emit("update:month", e.getDate().getMonth()), this.$emit("update:year", e.getDate().getFullYear());
    },
    dayHover(e) {
      this.$emit("date-hovered", new a(e.timestamp));
    },
    isSelected(e) {
      const t = new a(e.timestamp);
      return this.selectFrom !== null && this.selectTo !== null && t.isBetween(this.selectFrom, this.selectTo) || this.selectFrom !== null && t.sameDayAs(this.selectFrom) || this.selectTo !== null && t.sameDayAs(this.selectTo) || (this.value ? t.sameDayAs(this.value) : !1);
    },
    isFirstSelected(e) {
      var s;
      const t = new a(e.timestamp);
      return ((s = this.selectFrom) == null ? void 0 : s.sameDayAs(t)) === !0 || (this.value ? t.sameDayAs(this.value) : !1);
    },
    isLastSelected(e) {
      var s;
      const t = new a(e.timestamp);
      return (((s = this.selectTo) == null ? void 0 : s.sameDayAs(t)) && (this.selectFrom === null || this.selectFrom.isBefore(this.selectTo)) || (this.value ? t.sameDayAs(this.value) : !1)) === !0;
    },
    keyDown(e) {
      if (this.focus === null) return;
      const t = this.view === "month" ? 7 : 3, s = Object.keys(this.refDate).length - 1;
      function r(n, o) {
        switch (o.code) {
          case "ArrowRight":
            return o.preventDefault(), Math.min(n + 1, s);
          case "ArrowLeft":
            return o.preventDefault(), Math.max(n - 1, 0);
          case "ArrowUp":
            return o.preventDefault(), Math.max(n - t, 0);
          case "ArrowDown":
            return o.preventDefault(), Math.min(n + t, s);
          default:
            return n;
        }
      }
      i(r, "getNewIndex");
      const h = r(this.focus, e), u = this.refDate[h];
      u && u.focus();
    },
    setDate(e) {
      const t = new a().add(e, "day");
      if (!this.isWidget) {
        const s = {
          timestamp: t.timestamp,
          date: t.getDate().getDate(),
          day: t.getDate().getDay(),
          events: this.events.filter(
            (r) => a.hasOverlap(
              [r.start, r.end],
              [t.clone().setTime(0, 0, 0), t.clone().setTime(23, 59, 59)]
            )
          ),
          isAnotherMonth: !1,
          isToday: t.is("today"),
          label: this.days[t.getDate().getDay()]
        };
        this.dateSelected(s);
        return;
      }
      this.showMonth(t), this.emitDate(t);
    },
    showMonth(e) {
      this.compCurrentMonth = e.getDate().getMonth() + 1, this.compCurrentYear = e.getDate().getFullYear();
    },
    setRef(e, t) {
      e !== null ? this.refDate[t] = e : this.refDate.splice(t, 1);
    },
    setTabIndex(e) {
      return this.focus === null ? e === 0 ? 0 : -1 : this.focus === e ? 0 : -1;
    },
    switchView(e, t) {
      this.refDate.splice(0, this.refDate.length), this.focus = null, this.transition = t, this.view = e;
    },
    isWithinBounds(e) {
      return !(this.min && e.timestamp < this.min.timestamp || this.max && e.timestamp > this.max.timestamp);
    }
  }
});
export {
  k as _
};
