import { Portal2EmailCampaignLog } from "@/action-bus/portal/email-campaign/log";
import { Portal2EmailCampaignProfile } from "@/action-bus/portal/email-campaign/profile";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { DeleteEmailCampaign } from "./delete-email-campaign.action";
import { RecordEmailCampaign } from "./record-email-campaign.action";
import { UpdateEmailCampaign } from "./update-email-campaign.action";
import { UploadMembersCsv } from "./upload-members-csv.action";
import { ValidateMembersCsv } from "./validate-members-csv.action";

export const Portal2EmailCampaign = DefineActionBus({
  name: "portal-email-campaign",
  actions: {
    DeleteEmailCampaign,
    UpdateEmailCampaign,
    RecordEmailCampaign,
    UploadMembersCsv,
    ValidateMembersCsv,
    log: Portal2EmailCampaignLog,
    profile: Portal2EmailCampaignProfile,
  },
});
