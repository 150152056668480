import { CreateKinvaultSubscriptionReferralCode } from "@/action-bus/portal/kinvaults/subscription/kinvault-subscription-referral-code/create-kinvault-subscription-referral-code.action";
import { DeleteKinvaultSubscriptionReferralCode } from "@/action-bus/portal/kinvaults/subscription/kinvault-subscription-referral-code/delete-kinvault-subscription-referral-code.action";
import { ReadKinvaultSubscriptionReferralCode } from "@/action-bus/portal/kinvaults/subscription/kinvault-subscription-referral-code/read-kinvault-subscription-referral-code.action";
import { RecordKinvaultSubscriptionReferralCode } from "@/action-bus/portal/kinvaults/subscription/kinvault-subscription-referral-code/record-kinvault-subscription-referral-code.action";
import { UpdateKinvaultSubscriptionReferralCode } from "@/action-bus/portal/kinvaults/subscription/kinvault-subscription-referral-code/update-kinvault-subscription-referral-code.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

export const Portal2KinvaultSubscriptionReferralCode = DefineActionBus({
  name: "portal-kinvaults-subscription-subscription-referral-code",
  actions: {
    ReadKinvaultSubscriptionReferralCode,
    RecordKinvaultSubscriptionReferralCode,
    DeleteKinvaultSubscriptionReferralCode,
    UpdateKinvaultSubscriptionReferralCode,
    CreateKinvaultSubscriptionReferralCode,
  },
});
