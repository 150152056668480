import {
  WillBuilderOrderedSections,
  WillBuilderOverridableSections,
  WillBuilderSections,
  WillBuilderSectionTitles,
} from "@/module/kinvault.kintin/data/will-builder.data";
import { Kintin, KintinCheck, Person, WillRevision } from "@kinherit/sdk";
import { reactive } from "vue";

export class WillBuilderBase {
  static options = reactive({
    person: null,
    partner: null,
    kintin: null,
    willRevision: null,
    type: "primary",
    editing: false,
    selecting: false,
    document: "will",
  }) as {
    kintin: Kintin | null;
    person: Person | null;
    partner: Person | null;
    type: "primary" | "secondary";
    willRevision: null | WillRevision;
    editing: boolean;
    selecting: boolean;
    document: "will" | "mow-business" | "mow-iip" | "mow-nrb" | "mow-res";
  };

  static form = reactive({
    hasBusinessTrust: false,
    hasEstateProtectionTrust: false,
    hasIipTrust: false,
    hasBusinessClause: false,
  });

  static overrides: Partial<Record<WillBuilderSections, string>> = reactive({});

  static sections = reactive({
    titles: WillBuilderSectionTitles,
    ordered: WillBuilderOrderedSections,
    overridable: WillBuilderOverridableSections,
    enabled: null as null | Record<WillBuilderSections, boolean>,
    ids: WillBuilderSections,
  });

  static get optionalSections(): Array<
    "businessclause" | "businesstrust" | "estateprotectiontrust" | "iiptrust"
  > {
    const result: Array<
      "businessclause" | "businesstrust" | "estateprotectiontrust" | "iiptrust"
    > = [];

    if (!this.form) {
      return result;
    }

    if (this.form.hasBusinessClause) {
      result.push("businessclause");
    }

    if (this.form.hasBusinessTrust) {
      result.push("businesstrust");
    }

    if (this.form.hasEstateProtectionTrust) {
      result.push("estateprotectiontrust");
    }

    if (this.form.hasIipTrust) {
      result.push("iiptrust");
    }

    return result;
  }

  static get scheduleSections(): Array<
    "businesstrust" | "estateprotectiontrust" | "iiptrust"
  > {
    // scheduleSections is optional sections without the businessclause
    return this.optionalSections.intersection([
      "businesstrust",
      "estateprotectiontrust",
      "iiptrust",
    ]);
  }

  static checkIsNo = (step: string, steps: KintinCheck[]) =>
    steps.findBy("step", step)?.checkValue === 0;
  static checkValueByStep = (step: string, steps: KintinCheck[]) =>
    steps.findBy("step", step)?.checkValue ?? null;
  static checkIsYes = (step: string, steps: KintinCheck[]) =>
    steps.findBy("step", step)?.checkValue === 1;
}
