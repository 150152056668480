// BrandedKinvault

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { BrandedKinvault } from "@kinherit/sdk/model/branded-kinvault";

export const DeleteBrandedKinvault = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/admin/branded-kinvault/{brandedKinvault}",
  method: "delete",
  parse: (brandedKinvault: BrandedKinvault | string) => ({
    params: {
      brandedKinvault,
    },
  }),
  after: ({ message }) => {
    BrandedKinvault.$delete(message);
  },
});
