import { Portal2KinvaultUser } from "@/action-bus/portal/kinvault/user";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Portal2KinvaultAccountReferral } from "./account-referral";
import { AddressBookRecord } from "./address-book-record.action";
import { Portal2KinvaultAdviser } from "./adviser";
import { Portal2KinvaultAttorney } from "./attorney";
import { Portal2KinvaultBeneficiary } from "./beneficiary";
import { Portal2KinvaultCallScript } from "./call-script";
import { ChangeType } from "./change-type.action";
import { CreateAddress } from "./create-address.action";
import { CreateKintin } from "./create-kintin.action";
import { CreatePersonFromIntroducerContact } from "./create-person-from-introducer-contact.action";
import { CreatePhysicalStorageFileLog } from "./create-physical-storage-file-log.action";
import { DuplicateKintin } from "./duplicate-kintin.action";
import { Portal2KinvaultExclusion } from "./exclusion";
import { Portal2KinvaultExecutor } from "./executor";
import { Portal2KinvaultFileLog } from "./file-log";
import { Portal2KinvaultGift } from "./gift";
import { Portal2KinvaultGuardian } from "./guardian";
import { JoinDefaultBrandedKinvault } from "./join-default-branded-kinvault.action";
import { Portal2KinvaultKintinAccess } from "./kintin-access";
import { Portal2KinvaultKintinCheck } from "./kintin-check";
import { Portal2KinvaultKintinCheckFollowUp } from "./kintin-check-follow-up";
import { Portal2KinvaultOrder } from "./order";
import { Portal2KinvaultPerson } from "./person";
import { Portal2KinvaultPhysicalStorageSubscription } from "./physical-storage-subscription";
import { Portal2KinvaultProcess } from "./process";
import { Portal2KinvaultProperty } from "./property";
import { ReadHistory } from "./read-history.action";
import { RecordEmailLog } from "./record-email-log.action";
import { RecordKintin } from "./record-kintin.action";
import { RecordLead } from "./record-lead.action";
import { SendProcessLetter } from "./send-process-letter.action";
import { Portal2KinvaultSignDoc } from "./sign-doc";
import { Portal2KinvaultStatus } from "./status";
import { SwitchAccountHolders } from "./switch-account-holders.action";
import { Portal2KinvaultTrustee } from "./trustee";
import { UpdateAccountReferral } from "./update-account-referral.action";
import { UpdateBrandedKinvaultMembership } from "./update-branded-kinvault-membership.action";
import { UpdateKintin } from "./update-kintin.action";
import { Portal2KinvaultUploadForms } from "./upload-forms";
import { Portal2KinvaultWillRevision } from "./will-revision";

export const Portal2Kinvault = DefineActionBus({
  name: "portal-kinvault",
  actions: {
    accountReferral: Portal2KinvaultAccountReferral,
    adviser: Portal2KinvaultAdviser,
    attorney: Portal2KinvaultAttorney,
    beneficiary: Portal2KinvaultBeneficiary,
    callScript: Portal2KinvaultCallScript,
    exclusion: Portal2KinvaultExclusion,
    executor: Portal2KinvaultExecutor,
    fileLog: Portal2KinvaultFileLog,
    gift: Portal2KinvaultGift,
    guardian: Portal2KinvaultGuardian,
    kintinAccess: Portal2KinvaultKintinAccess,
    kintinCheck: Portal2KinvaultKintinCheck,
    kintinCheckFollowUp: Portal2KinvaultKintinCheckFollowUp,
    order: Portal2KinvaultOrder,
    person: Portal2KinvaultPerson,
    process: Portal2KinvaultProcess,
    property: Portal2KinvaultProperty,
    signDoc: Portal2KinvaultSignDoc,
    physicalStorageSubscription: Portal2KinvaultPhysicalStorageSubscription,
    trustee: Portal2KinvaultTrustee,
    uploadForms: Portal2KinvaultUploadForms,
    willRevision: Portal2KinvaultWillRevision,
    status: Portal2KinvaultStatus,
    user: Portal2KinvaultUser,
    AddressBookRecord,
    ChangeType,
    CreateAddress,
    CreateKintin,
    CreatePersonFromIntroducerContact,
    CreatePhysicalStorageFileLog,
    ReadHistory,
    RecordEmailLog,
    RecordKintin,
    RecordLead,
    SendProcessLetter,
    UpdateAccountReferral,
    UpdateBrandedKinvaultMembership,
    UpdateKintin,
    SwitchAccountHolders,
    DuplicateKintin,
    JoinDefaultBrandedKinvault,
  },
});
