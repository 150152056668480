var c = Object.defineProperty;
var r = (t, e) => c(t, "name", { value: e, configurable: !0 });
import { ComponentOptionsBase as m } from "../../component.config/component-options-base.js";
const o = class o extends m {
  constructor(e = () => ({})) {
    super(e, "Navbar");
  }
};
r(o, "NavbarOptions");
let s = o;
export {
  s as NavbarOptions
};
