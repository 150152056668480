var i = Object.defineProperty;
var a = (o, e) => i(o, "name", { value: e, configurable: !0 });
import { _ as p } from "../../../component.display/icon/icon.vue_vue_type_script_lang.js";
import { createElementBlock as n, createCommentVNode as s, openBlock as r, mergeProps as m, normalizeStyle as c, normalizeClass as l, toDisplayString as u } from "vue";
import { _ as y } from "../../../_plugins/_plugin-vue_export-helper.js";
const d = {
  key: 2,
  class: "icon__count"
};
function f(o, e, $, k, C, I) {
  return o.computedIcon !== null ? (r(), n("span", m({
    key: 0,
    class: ["icon", o.size]
  }, o.$attrs, {
    onClick: e[0] || (e[0] = (t) => o.$emit("click", t))
  }), [
    o.isDuotone ? (r(), n("i", {
      key: 0,
      class: l(`${o.computedIcon.icon} ${o.computedIconStyle}`),
      style: c({
        "--fa-primary-opacity": o.computedPrimaryOpacity,
        "--fa-secondary-opacity": o.computedSecondaryOpacity,
        "--fa-primary-color": o.computedColor,
        "--fa-secondary-color": o.computedSecondaryColor
      })
    }, null, 6)) : (r(), n("i", {
      key: 1,
      class: l(`${o.computedIcon.icon} ${o.computedIconStyle} is-unselectable`),
      style: c({
        color: o.computedColor,
        opacity: o.computedOpacity
      })
    }, null, 6)),
    o.count && o.size !== "is-small" ? (r(), n("span", d, u(o.count > 9 ? "9+" : o.count), 1)) : s("", !0)
  ], 16)) : s("", !0);
}
a(f, "_sfc_render");
const B = /* @__PURE__ */ y(p, [["render", f]]);
export {
  B as default
};
