<template>
  <EmailLogPage
    v-if="brandedKinvault"
    :owner="brandedKinvault"
    no-legacy-emails
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsEmailLogWrapper",
  "route": "BrandedKinvaultDetailsEmailLog",
  "extends": {
    "name": "EmailLogPageWrapper",
    "path": "@/module/core/component/EmailLogPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import EmailLogPage from "@/module/core/component/EmailLogPage.vue";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsEmailLogRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

export default defineComponent({
  name: BrandedKinvaultDetailsEmailLogRoute,
  components: {
    EmailLogPage,
  },
  mixins: [BrandedKinvaultDetailsMixin],
});
</script>
