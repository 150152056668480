// Order, Kintin, AccountReferral, AccountReferralCode, Profile, User

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { OrderRefundRequest } from "@kinherit/sdk";

export const ReadOrderRefundRequest = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/order-refund-request",
  method: "get",
  rootModel: OrderRefundRequest,
  parse: () => ({}),
});
