import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateCpdSession } from "./create-cpd-session.action";
import { ReadCpdSessions } from "./read-cpd-sessions.action";

export const Portal2Cpds = DefineActionBus({
  name: "portal-cpds",
  actions: {
    ReadCpdSessions,
    CreateCpdSession,
  },
});
