// AccountReferralCode, IntroducerCompany, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";

export const RecordAccountReferralCode = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/account-referral-code/{accountReferralCode}",
  method: "get",
  rootModel: AccountReferralCode,
  parse: (accountReferralCode: AccountReferralCode | string) => ({
    params: {
      accountReferralCode,
    },
  }),
});
