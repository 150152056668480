var C = Object.defineProperty;
var r = (e, s) => C(e, "name", { value: s, configurable: !0 });
import { _ as f } from "../../../component.input/address-lookup-field/AddressLookupField.vue_vue_type_script_lang.js";
import { resolveComponent as n, createElementBlock as a, openBlock as o, mergeProps as h, createVNode as t, withCtx as g, createCommentVNode as l, createElementVNode as b, renderSlot as p, normalizeClass as d, createBlock as u, Fragment as z } from "vue";
import { _ as V } from "../../../_plugins/_plugin-vue_export-helper.js";
const $ = {
  key: 0,
  class: "input-field__control-left input-field__control"
}, I = {
  key: 1,
  class: "input-field__control"
};
function v(e, s, y, L, A, F) {
  const i = n("Icon"), c = n("GoogleAddressAutocomplete"), m = n("Field");
  return o(), a("div", h(e.$data.$cypress, {
    class: `${e.wrapperClasses} input-field address-lookup`
  }), [
    t(m, {
      label: e.label,
      "has-addons": e.computedhasAddons,
      "is-grouped": e.computedIsGrouped,
      messages: e.computedMessages,
      size: e.size,
      "auto-collapse-label": e.autoCollapseLabel,
      "has-focus": e.hasFocus,
      "has-value": e.computedHasValue,
      "input-id": e.computedId
    }, {
      default: g(() => [
        e.showLeftControl ? (o(), a("div", $, [
          p(e.$slots, "left", { value: e.computedValue })
        ])) : l("", !0),
        b("div", {
          class: d(`input-field__control ${e.controlClasses} is-expanded`)
        }, [
          e.iconLeft && (!e.autoCollapseLabel || e.autoCollapseLabel && e.hasFocus || e.computedHasValue) ? (o(), u(i, {
            key: 0,
            size: e.size,
            icon: e.iconLeft,
            class: d(`is-left ${e.leftIconClick ? "is-clickable" : ""}`),
            onClick: e.leftIconClick
          }, null, 8, ["size", "icon", "class", "onClick"])) : l("", !0),
          e.isLoading ? l("", !0) : (o(), a(z, { key: 1 }, [
            e.showClearButton && e.computedValue !== null && e.computedValue !== "" ? (o(), u(i, {
              key: 0,
              icon: "Clear",
              size: e.size,
              class: "field-clear-button is-right is-clickable",
              onClick: e.clear
            }, null, 8, ["size", "onClick"])) : e.computedIconRight ? (o(), u(i, {
              key: 1,
              icon: e.computedIconRight,
              size: e.size,
              class: d(`is-right ${e.rightIconClick ? "is-clickable" : ""}`),
              onClick: e.rightIconClick
            }, null, 8, ["icon", "size", "class", "onClick"])) : l("", !0)
          ], 64)),
          t(c, {
            modelValue: e.address,
            "onUpdate:modelValue": s[0] || (s[0] = (k) => e.address = k),
            "api-key": "AIzaSyALxgvOrNdY9xN7wOYKMYg90_KrxDE1D-E",
            "input-class": ["input", e.inputClasses].join(" "),
            placeholder: e.placeholder,
            onCallback: e.callbackFunction
          }, null, 8, ["modelValue", "input-class", "placeholder", "onCallback"])
        ], 2),
        e.showRightControl ? (o(), a("div", I, [
          p(e.$slots, "right", { value: e.computedValue })
        ])) : l("", !0)
      ]),
      _: 3
    }, 8, ["label", "has-addons", "is-grouped", "messages", "size", "auto-collapse-label", "has-focus", "has-value", "input-id"])
  ], 16);
}
r(v, "_sfc_render");
const G = /* @__PURE__ */ V(f, [["render", v]]);
export {
  G as default
};
