import { FormMessage } from "@kinherit/framework/component.display/message";
import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Currency } from "@kinherit/ts-common";
import { reactive } from "vue";

export const PropertyTotalsSectionForm = (callScript: {
  advSavingsValue: null | Currency;
  advPropsValue: null | Currency;
  advPolicyValue: null | Currency;
  advPensionValue: null | Currency;
  advOtherAssetValue: null | Currency;
  advBusinessAssetValue: null | Currency;
  advPotentialValue: null | Currency;
  advDebtValue: null | Currency;
}) => {
  const assetNetTotal = () =>
    (callScript.advSavingsValue?.amount ?? 0) +
    (callScript.advPropsValue?.amount ?? 0) +
    (callScript.advPolicyValue?.amount ?? 0) +
    (callScript.advPensionValue?.amount ?? 0) +
    (callScript.advOtherAssetValue?.amount ?? 0) +
    (callScript.advBusinessAssetValue?.amount ?? 0) +
    (callScript.advPotentialValue?.amount ?? 0) -
    (callScript.advDebtValue?.amount ?? 0);

  const localData = reactive({
    tabs1: 0,
    assetNetTotal: assetNetTotal(),
  });

  const updateAssetNetTotal = () => {
    localData.assetNetTotal = assetNetTotal();
  };

  const form = defineForm({
    name: "property-totals-section",
    data: () => callScript,
    formAreas: (data) => [
      defineFormArea({
        name: "property-totals-section",
        data,
        template: GridLayout([
          ["netValue"],
          ["savings"],
          ["debt"],
          ["policies"],
          ["pensions"],
          ["potential"],
          ["business"],
          ["other"],
          ["total"],
        ]),
        components: () => ({
          netValue: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Property (NET Value)",
                reference: "netValue",
                size: "is-small",
              },
              models: {
                value: {
                  get: (data) => data.advPropsValue,
                  set: (value, data) => {
                    data.advPropsValue = value;
                    localData.assetNetTotal = assetNetTotal();
                  },
                },
              },
            }),
          ],
          savings: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Savings & Investments",
                reference: "savings",
                size: "is-small",
              },
              models: {
                value: {
                  get: (data) => data.advSavingsValue,
                  set: (value, data) => {
                    data.advSavingsValue = value;
                    localData.assetNetTotal = assetNetTotal();
                  },
                },
              },
            }),
          ],
          debt: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Debts",
                reference: "debt",
                size: "is-small",
              },
              models: {
                value: {
                  get: (data) => data.advDebtValue,
                  set: (value, data) => {
                    data.advDebtValue = value;
                    localData.assetNetTotal = assetNetTotal();
                  },
                },
              },
            }),
          ],
          policies: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Policies",
                reference: "policies",
                size: "is-small",
              },
              models: {
                value: {
                  get: (data) => data.advPolicyValue,
                  set: (value, data) => {
                    data.advPolicyValue = value;
                    localData.assetNetTotal = assetNetTotal();
                  },
                },
              },
            }),
          ],
          pensions: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Pensions",
                reference: "pensions",
                size: "is-small",
              },
              models: {
                value: {
                  get: (data) => data.advPensionValue,
                  set: (value, data) => {
                    data.advPensionValue = value;
                    localData.assetNetTotal = assetNetTotal();
                  },
                },
              },
            }),
          ],
          potential: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Potential Inheritance",
                reference: "potential",
                size: "is-small",
              },
              models: {
                value: {
                  get: (data) => data.advPotentialValue,
                  set: (value, data) => {
                    data.advPotentialValue = value;
                    localData.assetNetTotal = assetNetTotal();
                  },
                },
              },
            }),
          ],
          business: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Business",
                reference: "business",
                size: "is-small",
              },
              models: {
                value: {
                  get: (data) => data.advBusinessAssetValue,
                  set: (value, data) => {
                    data.advBusinessAssetValue = value;
                    localData.assetNetTotal = assetNetTotal();
                  },
                },
              },
            }),
          ],
          other: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Other",
                reference: "other",
                size: "is-small",
              },
              models: {
                value: {
                  get: (data) => data.advOtherAssetValue,
                  set: (value, data) => {
                    data.advOtherAssetValue = value;
                    localData.assetNetTotal = assetNetTotal();
                  },
                },
              },
            }),
          ],
          total: [
            FormMessage({
              props: {
                text: () => `TOTAL £${localData.assetNetTotal.format()}`,
                color: "is-plain",
                isBorderless: true,
                class: "is-shadowless mt-4",
              },
            }),
          ],
        }),
      }),
    ],
  });

  return {
    form,
    updateAssetNetTotal,
  };
};
