<template>
  <EmailLogPage v-if="$data.officer" :owner="$data.officer" />
</template>

<cypress-wrapper lang="json">
{
  "name": "OfficerDetailsEmailLogWrapper",
  "route": "OfficerDetailsEmailLog",
  "extends": {
    "name": "EmailLogPageWrapper",
    "path": "@/module/core/component/EmailLogPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import EmailLogPage from "@/module/core/component/EmailLogPage.vue";
import { defineComponent } from "vue";
import { OfficerDetailsEmailLogRoute } from ".";
import { KinvaultOfficerDetailsMixin } from "../../../mixin/kinvault-officer-details.mixin";

export default defineComponent({
  name: OfficerDetailsEmailLogRoute,
  components: {
    EmailLogPage,
  },
  mixins: [KinvaultOfficerDetailsMixin],
});
</script>
