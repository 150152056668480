var t = Object.defineProperty;
var a = (e, s) => t(e, "name", { value: s, configurable: !0 });
import { ThemeSize as l } from "../../theme/prop/size.js";
import { defineComponent as o } from "vue";
import i from "../../_vue/component.form/field-message/field-message.js";
const f = o({
  name: "FieldComponent",
  components: { FieldMessage: i },
  props: {
    /**
     * A list of validation messages and a class to be applied to that message
     */
    messages: {
      type: Array,
      default: null
    },
    /**
     * Left and/or right slot has content OR field made up of multiple components i.e. Date of Birth
     * Show the left and right slots without padding
     */
    hasAddons: {
      type: Boolean,
      default: !1
    },
    /**
     * Show the left and right slots with padding
     */
    isGrouped: {
      type: Boolean,
      default: !1
    },
    /**
     * The label of the field
     */
    label: {
      type: String,
      default: null
    },
    /**
     * The size of the field label
     */
    size: {
      type: String,
      default: "is-normal",
      validator: /* @__PURE__ */ a((e) => Object.values(l).includes(e), "validator"),
      value: l
    },
    /**
     * Collapse the label when the field is empty
     */
    autoCollapseLabel: {
      type: Boolean,
      default: !1
    },
    /**
     * The field is focused
     */
    hasFocus: {
      type: Boolean,
      default: !1
    },
    /**
     * The field has a value
     */
    hasValue: {
      type: Boolean,
      default: !1
    },
    inputId: {
      type: String,
      default: null
    },
    hideLabel: {
      type: Boolean,
      default: !1
    },
    hideMessage: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["click"],
  computed: {
    compFieldContentClasses() {
      let e = "";
      return this.hasAddons && (e += " has-addons"), this.isGrouped && (e += " is-grouped"), e;
    },
    compFieldClasses() {
      let e = "";
      return this.autoCollapseLabel && (e += " auto-collapse-label"), this.hasFocus && (e += " has-focus"), this.hasValue && (e += " has-value"), e;
    }
  }
});
export {
  f as _
};
