import { RouteInterface } from "@kinherit/framework/core/route-config";

export const SignupsReportRoute = "SignupsReportRoute";
const SignupsReportUri = "/reports/kinvault/signups";

export const Routes: Array<RouteInterface> = [
  {
    name: SignupsReportRoute,
    path: SignupsReportUri,
    // @ts-ignore
    components: {
      default: () =>
        import(/* webpackChunkName: "SignupsReport" */ "./Reports.Signups.vue"),
    },
  },
];
