// Kintin, Profile, AccountReferral, AccountReferralCode, KintinAccess, User, Person, KintinCheck, PhoneNumber, EmailAddress, Website, CallScript, Address, IntroducerCompany, IntroducerContact, Property, Lead, BrandedKinvault, BrandedKinvaultMembership, IntroducerNetwork

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Appointment } from "@kinherit/sdk";

export const RecordAppointment = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/appointments/{appointment}",
  method: "get",
  rootModel: Appointment,
  parse: (appointment: Appointment | string) => ({
    params: {
      appointment,
    },
  }),
});
