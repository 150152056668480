var t = Object.defineProperty;
var i = (o, e) => t(o, "name", { value: e, configurable: !0 });
import { _ as n } from "../../../component.input/color-picker-field/color-picker-field.vue_vue_type_script_lang.js";
import { resolveComponent as u, createBlock as d, openBlock as p, mergeProps as m, createSlots as c, withCtx as s, renderSlot as r } from "vue";
import { _ as f } from "../../../_plugins/_plugin-vue_export-helper.js";
function g(o, e, h, v, C, w) {
  const a = u("BaseInputField");
  return p(), d(a, m(o.$data.$cypress, {
    value: o.computedColor,
    "onUpdate:value": e[0] || (e[0] = (l) => o.computedColor = l),
    class: `${o.wrapperClasses} color-field`,
    label: o.label,
    "input-tag": "input",
    type: "color",
    placeholder: o.placeholder,
    state: o.state,
    validators: o.validators,
    message: o.message,
    "message-color": o.messageColor,
    size: o.size,
    color: o.color,
    "is-loading": o.isLoading,
    "icon-left": o.iconLeft,
    "icon-right-override": o.computedIconRight,
    "show-validation": o.computedShowValidation,
    "left-icon-click": o.leftIconClick,
    "right-icon-click": o.rightIconClick,
    "show-clear-button": o.showClearButton,
    "is-fullwidth": o.isFullwidth,
    autofocus: o.autofocus,
    autocomplete: o.autocomplete,
    "is-grouped": o.isGrouped,
    onClear: o.clear,
    onFocus: o.focus,
    onBlur: e[1] || (e[1] = (l) => o.$emit("blur")),
    onSubmit: o.keydown
  }), c({ _: 2 }, [
    o.showLeftControl ? {
      name: "left",
      fn: s(() => [
        r(o.$slots, "left", { value: o.computedColor })
      ]),
      key: "0"
    } : void 0,
    o.showRightControl ? {
      name: "right",
      fn: s(() => [
        r(o.$slots, "right", { value: o.computedColor })
      ]),
      key: "1"
    } : void 0
  ]), 1040, ["value", "class", "label", "placeholder", "state", "validators", "message", "message-color", "size", "color", "is-loading", "icon-left", "icon-right-override", "show-validation", "left-icon-click", "right-icon-click", "show-clear-button", "is-fullwidth", "autofocus", "autocomplete", "is-grouped", "onClear", "onFocus", "onSubmit"]);
}
i(g, "_sfc_render");
const F = /* @__PURE__ */ f(n, [["render", g]]);
export {
  F as default
};
