<template>
  <div class="columns is-centered">
    <div class="column is-6-tablet is-4-desktop">
      <Card title="Reset Password">
        <Form is-borderless :config="$data.form" @submit="handleClick" />
        <div class="buttons is-right">
          <Button
            class="reset-password-button"
            :is-fullwidth="true"
            color="is-positive"
            @click="handleClick"
          >
            Reset
          </Button>
        </div>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { ResetPasswordForm } from "@/module/admin.user/form/reset-password.form";
import { AuthService } from "@/service/auth.service";
import { Form } from "@kinherit/framework/component.form/form";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { defineComponent } from "vue";
import { AuthLoginRoute, AuthResetPasswordParams } from ".";

export default defineComponent({
  name: "ResetPassword",
  components: { Card, Form, Button },
  mixins: [
    ActionBusMixin(() => window.Kernel.ActionBus2.auth),
    AuthService.mixin({
      sessionRequired: false,
    }),
  ],
  data: () => ({
    form: ResetPasswordForm(true),
  }),
  computed: {
    params(): AuthResetPasswordParams {
      return this.$route.params as AuthResetPasswordParams;
    },
  },
  methods: {
    async handleClick(): Promise<void> {
      const valid = this.form.validate?.(this.form.localData) ?? true;

      if (!valid) {
        return;
      }

      // await window.Kernel.ActionBus.auth.changePassword({
      await this.$actionBus.changePassword.ChangeTokenPassword({
        newPassword: this.form.localData.newPassword,
        username: this.form.localData.username,
        token: this.params.token,
      });

      window.Kernel.visitRoute({
        name: AuthLoginRoute,
      });
    },
  },
});
</script>
