// IntroducerFeePaymentItem, IntroducerFeePaymentRun, IntroducerFeePayment

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerFeePaymentItem } from "@kinherit/sdk/model/introducer-fee-payment-item";

export const RecordIntroducerFeePaymentItem = DefineReadAction({
  interface: "portal",
  resource:
    "/v2/portal/introducer/fees/payment-item/{introducerFeePaymentItem}",
  method: "get",
  rootModel: IntroducerFeePaymentItem,
  parse: (introducerFeePaymentItem: IntroducerFeePaymentItem | string) => ({
    params: {
      introducerFeePaymentItem,
    },
  }),
});
