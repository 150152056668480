<template>
  <div
    v-for="(options, title) in items"
    :key="`widget-${title}`"
    class="settings-sidebar"
  >
    <div v-if="title" class="settings-sidebar__title">
      {{ title }}
    </div>
    <Tabs
      v-if="options.length > 0"
      v-model:value="selected"
      class="sidebar-page__tabs"
      is-vertical
      :config="options"
    />
  </div>
</template>

<script lang="ts">
import Tabs from "@kinherit/framework/component.display/tabs";
import { defineComponent } from "vue";
import {
  SettingsAcuityRoute,
  SettingsCacheRoute,
  SettingsCronJobsRoute,
  SettingsEmailRoute,
  SettingsSortReferRoute,
  SettingsXeroRoute,
} from ".";

import { RouteTabOptions } from "@kinherit/framework/component.display/tabs/types";

const items: Record<"General", RouteTabOptions[]> = {
  General: [
    {
      label: "Cache",
      route: {
        name: SettingsCacheRoute,
      },
    },
    {
      label: "Xero",
      route: {
        name: SettingsXeroRoute,
      },
    },
    {
      label: "Acuity",
      route: {
        name: SettingsAcuityRoute,
      },
    },
    {
      label: "SortRefer",
      route: {
        name: SettingsSortReferRoute,
      },
    },
    {
      label: "Emails",
      route: {
        name: SettingsEmailRoute,
      },
    },
    {
      label: "Cron Jobs",
      route: {
        name: SettingsCronJobsRoute,
      },
    },
  ],
};

export default defineComponent({
  name: "SettingsSidebar",
  components: {
    Tabs,
  },
  data: () => ({
    items,
  }),
  computed: {
    selected: {
      get(): RouteTabOptions {
        return (
          Object.values(items)
            .flat()
            .find((tab) => {
              if (
                "string" !== typeof tab.route &&
                "name" in tab.route &&
                tab.route.name === this.$route.name
              ) {
                return true;
              }

              return false;
            }) ?? items["General"][0]
        );
      },
      set({ route }: RouteTabOptions) {
        window.Kernel.visitRoute(route);
      },
    },
  },
});
</script>

<style lang="scss">
.settings-sidebar {
  &__title {
    border-right: 1px solid var(--color-border);
    padding: 0.5rem 1rem;
    cursor: default;
    @include font-weight(700);
  }
}
</style>
