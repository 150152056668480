var u = Object.defineProperty;
var l = (t, e) => u(t, "name", { value: e, configurable: !0 });
import { validate as m } from "./validator.service.js";
import { DashLoader as o } from "@kinherit/ts-common/service/dash-loader";
const a = class a {
  constructor(e) {
    this.validation = e;
  }
  validate(e) {
    return this.validation.map((r) => ({
      ...r,
      result: m({
        label: r.label,
        validator: r.validators,
        value: "value" in r ? r.value : o.get(e, r.path)
      })
    }));
  }
};
l(a, "ObjectValidator");
let s = a;
export {
  s as ObjectValidator
};
