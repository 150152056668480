// BrandedKinvaultInvitation, BrandedKinvaultInviteReferral, Profile, EmailAddress

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Credential } from "@kinherit/sdk";
import { Uuid } from "@kinherit/ts-common";

export const CreateBrandedKinvaultInvitation = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/admin/branded-kinvault/invitation",
  method: "post",
  parse: (message: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    reference: null | string;
    referralCode: null | string;
    adviser: null | string;
    createdBy: Credential;
    brandedKinvault: string;
    isBillable: boolean;
  }) => ({
    body: {
      invite: {
        profile: {
          firstName: message.firstName,
          lastName: message.lastName,
          emails: [{ email: message.emailAddress, primary: true }],
        },
        signUpToken: Uuid.generate(),
        status: "notsent" as const,
        brandedKinvault: message.brandedKinvault,
        createdBy: message.createdBy.id,
        introducerContact: message.adviser,
        externalRef: message.reference,
        isBillable: message.isBillable,
      },
      referralCode: message.referralCode,
    },
  }),
});
