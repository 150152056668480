<template>
  <div v-if="kintin">
    <CallScriptSection title="Recommendation">
      <!--b
        >OK so broadly – we have talked through who you want to get your assets
        when you die, and who would do what, and also how we allow people we
        know to speak for you if you are not able.
      </b>
      <br />
      <br />
      <b
        >Or in other words – what goes into a Will, and how you would set up
        LPAs.
      </b>
      <br /><br />

      <b>
        We have covered the 4 key areas of Planning, and in my professional
        opinion for your situation I recommend that you need;</b
      >
      <br /><br />
      <b
        >Wills - As these are crucial legal documents that say who will get your
        money if you die and who should be in charge of the process.</b
      >
      <br /><br />
      <b
        >An Estate Protection Trust for protecting [Name Beneficiaries]
        inheritance and to achieve [specific other benefit]</b
      >
      <br /><br />
      <b
        >A Business Trust, so that you can ensure that you ae not paying more
        inheritance tax than you need to.</b
      >
      <br /><br />
      <b
        >I always recommend all LPA’s, as these are your insurance policies that
        [name attorneys] can be your voice and make critical decisions if you
        are unable to.</b
      >
      <br /><br />
      <b
        >And finally Document Storage, as there is no point creating all these
        documents if they are not kept safely and are available when they are
        needed. With the added benefit of you being able to redo your planning
        if and when your situation changes {give typical examples}</b
      -->

      <b
        >OK so we’ve made great progress today, and we’ve talked through the
        four areas of planning.</b
      >
      <br /><br /><Button
        text="Add Order"
        color="is-success"
        class="mb-4"
        @click="$emit('create-order')"
      />
      <br />
      <em>
        EPs to cover everything client needs – with hooks relevant to client:
      </em>
      <ul>
        <li>A will / mirror Wills to protect your wishes on death</li>
        <li>
          Estate protection trusts to protect your assets (use personalised
          hook)
        </li>
        <li>Business (relief) planning for the tax and protection benefits</li>
        <li>
          Lasting powers of attorney to protect you in life (use personalised
          hook)
        </li>
        <li>
          Document storage to keep the legal documents safe and readily to hand
          And importantly – the handover services and Kinvault that is so
          important for helping your family on death or incapacitation
        </li>
      </ul>

      <br /><br />
    </CallScriptSection>
    <CallScriptSection title="Order">
      <h5 class="title is-emphasised mb-5">Orders</h5>
      <OrderList :kintin="kintin" />
      <br />
      <div class="buttons">
        <Button
          text="Add Order"
          color="is-success"
          class="mb-4"
          @click="$emit('create-order')"
        />
        <Button
          text="Send Order Payment Email"
          color="is-positive"
          class="mb-4 ml-2"
          @click="sendOrderPaymentEmail"
        />
      </div>
      <br /><em
        >Once you’ve confirmed products and amount (not storage yet)…</em
      >
      <br /><b>“Okay fab, are you happy to make that payment now?”</b>
      <br />
      <br /><em>[yes]</em> <br /><b
        >“Perfect, what I’ll do is email you a link to make the payment.. let me
        know when that comes through”</b
      >
      <br />
      <br /><em>[that’s come through..]</em> <br /><b
        >“If you click on that link, it should give you the option to pay either
        online or via BACS, whichever is easier for you (small pause).. and then
        once you’ve done that, I should get a notification and I can send you
        the next steps for you to complete…… etc”</b
      >
      <br />
      <br /><em>[once payment is received] <br />NOW mention storage –</em>
      <br /><b
        >“Lovely, that’s all gone through. Can I just check that you’re happy we
        store your original signed documents so you’ve got peace of mind
        everything is in a safe place?”</b
      >
      <ConfirmCheck
        v-if="checkIsYes('5.3.11')"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.2.2"
        :kintin="kintin"
        @update="updateCheckValue"
      />
      <br /><em>[yes]</em> <br /><b
        >“Fab, what I’ll do then is include the direct debit set up for the
        <mark>Price</mark> per year in the next steps for you, which I’ll send
        across now but please note we don’t require the <mark>Price</mark> from
        you now – this will be taken once we are physically storing your
        original documents for you”</b
      >
      <br />
      <br /><em
        >And finally.. <br />Mention you’ve sent them the post payment items and
        let them know what to complete, then you can draft, etc.</em
      >
    </CallScriptSection>
    <CallScriptSection title="Post-order tidy-ups ">
      <b>I’ve a couple of tidy-ups: </b>
      <br /><br />
      <b
        >[conditional – if no EPT and kids under 18] <br />
        You also need to name trustees to look after money left to your children
        until they turn [18-25]. Normally people pick their executors. Could
        this work?
      </b>
      <br /><br /><b>
        [conditional – if EPT and/or business trust] <br />
        Your trusts need people to act as trustees, to look after the money in
        trust.
      </b>
      <br /><br /><b>
        It usually starts with the same people you chose as Executors, and
        supported by a professional. Trusts are pretty straightforward to
        manage, but professional input is to ensure that you get the full tax
        benefits, and generally to keep the admin simple for your personal
        trustees.
      </b>
      <br /><br />
      <b> [conditional – configure LPAs]<br />Go to LPA-configure tab</b>
      <br /><br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.6.2"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSection title="Cooling Off Period">
      <b
        >By law we're required to offer a 14 day cooling off period, but this
        doesn't make much sense to us as it just delays you getting your
        documents; if you do want it, let me know, but otherwise I'll assume
        you're happy to waive it. <br
      /></b>
      <em
        >[PAUSE] [if they want the cooling off period, ensure tickbox is
        unchecked in order]</em
      >
      <br />
      <br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="6.6.1"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSection title="Closing">
      <b>
        OK so I think that is everything for today.
        <br /><br />
        Once payment has been confirmed, we’ll send you a link that you can use
        to complete the information that we need to prepare your documents.
        Please do spend a bit of time checking things, as we want everything to
        be fully accurate.
        <br /><br />
        Once we have all this from you, we’ll prepare your documents, and send
        them to you in the post.
        <br /><br />
        One word of caution - the law is very specific on the signing and
        witnessing of documents. We’ll include detailed signing instructions
        with your documents - please read them carefully. [in particular for the
        Lasting powers of Attorney]
        <br /><br />
        Once your information is confirmed we will also send you details to
        access your Kinvault. There is a comprehensive set-up wizard in it, but
        if you are struggling let me know and we can arrange a zoom call.
        <br /><br />
        We’ll also reach out to your trusted people, and ask them to let us know
        if something happens to you. We do this in a way that’s designed to be
        really reassuring, that there is a plan to help them if needed.
        <br /><br />
        Before we end for today, did you have any other questions for me?
        <br />[pause] <br /><br />
        That is everything from me. The payment email will be with you now, and
        as mentioned once that is sorted we move on to confirming information
        and getting you protected.
        <br /><br />
        Thanks again for your time, and we will speak soon. Bye.
      </b>
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { defineComponent } from "vue";
import { KintinDetailsCallScript2Call2OrderRoute } from ".";
import OrderList from "@/module/kinvault.kintin/component/call-script/shared/OrderList.vue";
import { SendOrderPaymentEmailForm } from "@/module/kinvault.kintin/form/call-script.form/send-order-payment-email.form";
import Button from "@kinherit/framework/component.input/button";

export default defineComponent({
  name: KintinDetailsCallScript2Call2OrderRoute,
  components: {
    CallScriptSection,
    ConfirmCheck,
    Button,
    OrderList,
  },
  mixins: [AuthService.mixin(), KinvaultKintinDetailsCallScriptMixin],
  emits: ["create-order"],
  methods: {
    async sendOrderPaymentEmail() {
      if (!this.kintin) {
        return;
      }

      const result = await SendOrderPaymentEmailForm(this.kintin).dialog({
        dialog: {
          title: "Send Order Payment Email",
        },
      });

      // await window.Kernel.ActionBus.kinvaultKintin.order.sendPaymentEmail({
      await this.$actionBus.order.SendOrderPaymentEmail({
        order: result.order as string,
        person: result.person as string,
        kintin: this.$params.kintin,
      });
    },
  },
});
</script>
