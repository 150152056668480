<template>
  <div v-if="kintin">
    <CallScriptSection title="Executors">
      <b>
        Now lets talk about who you trust to help make sure your wishes happen.
        <br /><br />
        Last time I mentioned that it’s better to pick someone you know, – and
        consider the support that they need separately.
        <br />
        <br />- [person 1] … have you thought who you’d pick? … and in terms of
        reserves? <br />- [person 2] … same questions </b
      ><br /><br />
      <ExecutorsContainer :kintin="kintin" />
      <br />
      <b>
        So thinking about the support that they may need … we'd normally suggest
        a clause that asks your personal Executor to consider appointing a
        recognised professional, but ultimately leaving it at their discretion.
        Does this sound sensible?
        <br /><br />
      </b>
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.6.3b"
        :kintin="kintin"
        is-reversed
        @update="updateCheckValue"
      />
      <ConfirmCheck
        v-if="checkIsYes('4.6.3b')"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.6.3c"
        :kintin="kintin"
        yes-label="Encouraged"
        no-label="Considered"
        maybe-label="Required"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.6.1"
        :kintin="kintin"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.6.2"
        :kintin="kintin"
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.6.3"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSection title="Kinvault">
      <b>
        So the reason why we’re being careful to name people you know as
        Executors, is because they know you. But they don’t know
        <em>everything</em> about you. <br /><br />
        So possibly the most valuable thing for your family, aside from having a
        Will, is getting extra information on your affairs. But it can be hard
        to know where to start.
      </b>
      <br /><br />
      <b
        >The good news is that we help you do that. We do this via our Kinvault
        platform, which we built, that helps you build your plan – which we then
        hand over to your next of kin when needed. <br /><br />It even links in
        to your IFA, assuming your happy to do that.
      </b>
      <br /><br />
      <b
        >And the other good news – it’s all included in the price of a Will. So
        you’d get your legal documents, complimentary lifetime access to your
        Kinvault, and a personalised handover. <br /><br />
        If there is one reason that financial advisers recommend their clients
        to come to us, it is for the Kinvault, because it is very powerful.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Document Storage">
      <b>
        Only the original documents are legally valid, so most of our customers
        ask us to keep their documents safe for them.
        <br /><br />
        There is an annual fee for this of £<mark>PRICE</mark>. But this also
        comes with the benefit of an annual rewrite if your situation changes.
        <br /><br />
        <b
          >[If younger clients]: Your situation may well change in the future,
          i.e, children and other life events, which mean you will want to
          update your planning.</b
        >
      </b>
    </CallScriptSection>
    <CallScriptSection title="Estate Protection Trusts">
      <b
        >We have covered your Will – so who’ll get what and who can help that
        happen – but there are actually ways of protecting it after death as
        well. By using trusts.
      </b>
      <br /><br />
      <b>It’s easier to explain with an example. </b>
      <br /><br />
      <b
        >If you leave your money to your children, and they divorce, then your
        money is part of the divorce estate. If you had left your money to trust
        – with your children as beneficiaries – then it would not be in the
        divorce estate.
      </b>
      <br /><br />
      <b
        >It can also be very tax efficient, and flexible. Which is why we
        recommend it for all higher-value estates.
      </b>
      <br /><br />
      <b
        >Have you come across them before? / did you read the document on it
        that I sent?</b
      >
      <br /><br />
      <InfoBlock title="Note">
        <ul class="mt-0">
          <li>
            EPs – try to use relevant examples that are specific to the client,
            and using their family’s names.
          </li>
          <li>
            If children, explain the benefits of EPT and providing provision to
            Guardians …..always
          </li>
        </ul>
      </InfoBlock>
    </CallScriptSection>
    <!-- <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advExecutorNotes"
      :has-continue="false"
    /> -->
  </div>
</template>

<script lang="ts">
import ExecutorsContainer from "@/module/kinvault.kintin/component/address-book/ExecutorsContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
// import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import InfoBlock from "@/module/kinvault.kintin/component/call-script/shared/InfoBlock.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: "ExecutorsTab",
  components: {
    ConfirmCheck,
    CallScriptSection,
    // CallScriptSpecialistNotesSection,
    ExecutorsContainer,
    InfoBlock,
  },
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
});
</script>
