var m = Object.defineProperty;
var u = (t, i) => m(t, "name", { value: i, configurable: !0 });
import p from "../../_vue/component.display/avatar/avatar.js";
import f from "../../_vue/component.input/button/button.js";
import d from "../../_vue/component.layout/navbar-item/navbar-item.js";
import { Uuid as g } from "@kinherit/ts-common/service/uuid";
import { defineComponent as w } from "vue";
const N = w({
  name: "HeaderComponent",
  components: { NavbarItem: d, Avatar: p, Button: f },
  data: /* @__PURE__ */ u(() => ({
    bindings: {
      container: {},
      wrapper: {},
      logo: {},
      title: {},
      mobileMenuButton: {},
      avatar: {},
      search: {}
    },
    content: window.Kernel.ComponentOptions.Navbar.content,
    hasMenuItems: !1,
    mobileMenuOpen: !1,
    isMobile: !1,
    uuid: g.generate(),
    showDropdown: null,
    timeout: null,
    computed: {
      logo: null
    },
    filteredButtons: [],
    filteredMenu: []
  }), "data"),
  computed: {
    route() {
      return this.$route ? `${String(this.$route.name)}${this.$route.fullPath}` : this.uuid;
    },
    hasButtons() {
      return this.filteredButtons.length > 0;
    },
    hasMenu() {
      return this.filteredMenu.length > 0;
    }
  },
  watch: {
    route: {
      deep: !1,
      handler() {
        this.mobileMenuOpen = !1;
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    window.Kernel.ComponentOptions.Navbar.setRefresh(this.refresh), this.refresh(), window.addEventListener("resize", this.resize);
  },
  methods: {
    refresh() {
      var t, i, o, s, r, l, a, h;
      this.bindings = {
        container: window.Kernel.ComponentOptions.Navbar.getElementBindings("container"),
        wrapper: window.Kernel.ComponentOptions.Navbar.getElementBindings("wrapper"),
        logo: window.Kernel.ComponentOptions.Navbar.getElementBindings("logo"),
        title: window.Kernel.ComponentOptions.Navbar.getElementBindings("title"),
        mobileMenuButton: window.Kernel.ComponentOptions.Navbar.getElementBindings(
          "mobileMenuButton"
        ),
        avatar: window.Kernel.ComponentOptions.Navbar.getElementBindings("avatar"),
        search: window.Kernel.ComponentOptions.Navbar.getElementBindings("search")
      }, this.content = window.Kernel.ComponentOptions.Navbar.content, this.hasMenuItems = (((i = (t = this.content) == null ? void 0 : t.menu) == null ? void 0 : i.filter((e) => {
        var n;
        return ((n = e.show) == null ? void 0 : n.call(e)) ?? !0;
      }).length) ?? 0) > 0 || (((s = (o = this.content) == null ? void 0 : o.buttons) == null ? void 0 : s.filter((e) => {
        var n;
        return ((n = e.show) == null ? void 0 : n.call(e)) ?? !0;
      }).length) ?? 0) > 0, this.filteredButtons = ((l = (r = this.content) == null ? void 0 : r.buttons) == null ? void 0 : l.filter((e) => {
        var n;
        return ((n = e.show) == null ? void 0 : n.call(e)) ?? !0;
      })) ?? [], this.filteredMenu = ((h = (a = this.content) == null ? void 0 : a.menu) == null ? void 0 : h.filter((e) => {
        var n;
        return ((n = e.show) == null ? void 0 : n.call(e)) ?? !0;
      })) ?? [], this.setLogo(), this.$forceUpdate();
    },
    resize() {
      this.timeout && clearTimeout(this.timeout), this.timeout = setTimeout(() => {
        this.isMobile = !1, this.mobileMenuOpen = !1, this.$refs.refNavBar && (this.$refs.refNavBar.style.height = "");
      }, 100);
    },
    async toggleMobileMenu() {
      if (this.isMobile || (this.isMobile = !0, await this.$nextTick()), this.mobileMenuOpen) {
        await this.$nextTick();
        const t = this.$refs.refNavBar;
        t.style.height = `${t.scrollHeight}px`;
      }
      if (this.mobileMenuOpen = !this.mobileMenuOpen, this.mobileMenuOpen) {
        await this.$nextTick();
        const t = this.$refs.refNavBar;
        t.style.height = `${t.scrollHeight}px`, this.timeout && clearTimeout(this.timeout), this.timeout = setTimeout(() => {
          t.style.height = "";
        }, 250);
      }
    },
    setLogo() {
      var t, i;
      if (typeof ((t = this.content) == null ? void 0 : t.logo) == "string") {
        this.computed.logo = {
          url: this.content.logo
        };
        return;
      }
      this.computed.logo = {
        ...((i = this.content) == null ? void 0 : i.logo) ?? {}
      };
    }
  }
});
export {
  N as _
};
