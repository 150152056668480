// PhysicalStorageFile, Subscription, Kintin, PhysicalStorageFileLog

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { PhysicalStorageFileLog } from "@kinherit/sdk/model/physical-storage-file-log";

export const ReadPhysicalStorageFileLog = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/storage-requests",
  method: "get",
  rootModel: PhysicalStorageFileLog,
  parse: () => ({}),
});
