// IntroducerBillingContact, Profile, Address, PhoneNumber, EmailAddress, Website

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerBillingContact } from "@kinherit/sdk/model/introducer-billing-contact";

export const ReadIntroducerBillingContacts = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/billing-contacts",
  method: "get",
  rootModel: IntroducerBillingContact,
  parse: () => ({}),
});
