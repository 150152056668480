import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { OrderRefundRequest } from "@kinherit/sdk";

export const UpdateOrderRefundRequest = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/order-refund-request/{orderRefundRequest}",
  method: "patch",
  parse: (orderRefundRequest: OrderRefundRequest) => ({
    body: {
      order: orderRefundRequest.$data.order,
      status: orderRefundRequest.$data.status,
      amount: orderRefundRequest.$data.amount,
      refundReason: orderRefundRequest.$data.refundReason,
      completedAt: orderRefundRequest.$data.completedAt,
      id: orderRefundRequest.$data.id,
    },
    params: {
      orderRefundRequest: orderRefundRequest,
    },
  }),
});
