var i = Object.defineProperty;
var o = (e, t) => i(e, "name", { value: t, configurable: !0 });
import { _ as l } from "../../../component.form/field-message/field-message.vue_vue_type_script_lang.js";
import { createElementBlock as s, openBlock as r, Fragment as _, renderList as c, normalizeClass as d } from "vue";
import { _ as m } from "../../../_plugins/_plugin-vue_export-helper.js";
const f = { class: "field__messages" }, p = ["innerHTML"];
function u(e, t, g, $, M, h) {
  return r(), s("div", f, [
    (r(!0), s(_, null, c(e.filteredMessages, (n, a) => (r(), s("div", {
      key: `message-${a}`,
      class: d(`field__message ${n.class}`),
      innerHTML: n.message
    }, null, 10, p))), 128))
  ]);
}
o(u, "_sfc_render");
const B = /* @__PURE__ */ m(l, [["render", u]]);
export {
  B as default
};
