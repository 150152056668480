// AccountReferralCode

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const ReadAccountReferralCodes = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/company/referral/{introducerCompany}/codes",
  method: "get",
  rootModel: AccountReferralCode,
  parse: (introducerCompany: IntroducerCompany | string) => ({
    params: {
      introducerCompany,
    },
  }),
});
