var D = Object.defineProperty;
var f = (e, o) => D(e, "name", { value: o, configurable: !0 });
import { _ as T } from "../../../component.form/form/form-inner.vue_vue_type_script_lang.js";
import { resolveComponent as p, resolveDirective as R, createBlock as $, openBlock as a, createSlots as v, renderList as s, withCtx as y, createElementBlock as k, Fragment as I, withDirectives as K, resolveDynamicComponent as S, mergeProps as V, createVNode as L, vShow as A } from "vue";
import { _ as N } from "../../../_plugins/_plugin-vue_export-helper.js";
function E(e, o, O, P, j, q) {
  const g = p("FormInner", !0), b = p("DynamicLayout"), w = R("tool-tip");
  return a(), $(b, {
    key: `dynamic-layout-${e.templateRenderKey}`,
    layout: e.layout
  }, v({ _: 2 }, [
    s(e.renderedComponents ?? {}, (F, t) => ({
      name: t,
      fn: y(() => [
        (a(!0), k(I, null, s(F.map((n, r) => [n, r]).filter(([n, r]) => e.bindings[t][r].vIf !== !1), ([n, r]) => {
          var l, u, d;
          return K((a(), $(S(n.component), V({
            key: `form-${e.uuid}-area-${e.formArea.name}-field-${r}`,
            "is-form": !0,
            ref_for: !0
          }, e.bindings[t][r], {
            "show-validation": e.showValidation || ((u = (l = e.form) == null ? void 0 : l.options) == null ? void 0 : u.showValidation) || !1,
            onSubmit: o[4] || (o[4] = (i) => e.$emit("submit")),
            onBlur: o[5] || (o[5] = (i) => e.$emit("field-blur")),
            onFocus: o[6] || (o[6] = (i) => e.$emit("field-focus")),
            onClear: o[7] || (o[7] = (i) => e.$emit("field-clear"))
          }), v({ _: 2 }, [
            s(e.mapNestedComponents(n.slots), (i, B) => ({
              name: B,
              fn: y((C) => [
                L(g, {
                  form: e.form,
                  "form-area": e.formArea,
                  components: i.components ?? {},
                  layout: i.template,
                  "form-data": e.formData,
                  uuid: e.uuid,
                  scope: C,
                  "show-validation": e.showValidation,
                  "is-dialog": e.isDialog,
                  onFieldBlur: o[0] || (o[0] = (m) => e.$emit("field-blur")),
                  onFieldFocus: o[1] || (o[1] = (m) => e.$emit("field-focus")),
                  onFieldClear: o[2] || (o[2] = (m) => e.$emit("field-clear")),
                  onSubmit: o[3] || (o[3] = (m) => e.$emit("submit")),
                  onIncrementRenderKey: e.incrementRenderKey,
                  onRebuildTemplateBindings: e.setBindings
                }, null, 8, ["form", "form-area", "components", "layout", "form-data", "uuid", "scope", "show-validation", "is-dialog", "onIncrementRenderKey", "onRebuildTemplateBindings"])
              ])
            }))
          ]), 1040, ["show-validation"])), [
            [
              A,
              [void 0, !1].includes((d = n.internalSettings) == null ? void 0 : d.hideOverride)
            ],
            [w, e.getToolTipText(n), e.getToolTipConfig(n, r)]
          ]);
        }), 128))
      ])
    }))
  ]), 1032, ["layout"]);
}
f(E, "_sfc_render");
const M = /* @__PURE__ */ N(T, [["render", E]]);
export {
  M as default
};
