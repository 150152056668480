// KintinAccess

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk";
import { KintinAccess } from "@kinherit/sdk/model/kintin-access";

export const ReadKintinAccess = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/kintin-access",
  method: "get",
  rootModel: KintinAccess,
  parse: (kintin: Kintin) => ({
    params: {
      kintin,
    },
  }),
});
