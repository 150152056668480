import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateKintinAccess } from "./create-kintin-access.action";
import { DeleteKintinAccess } from "./delete-kintin-access.action";
import { ReadKintinAccess } from "./read-kintin-access.action";
import { UpdateKintinAccess } from "./update-kintin-access.action";

export const Portal2AdminUserKintinAccess = DefineActionBus({
  name: "portal-admin-user-kintin-access",
  actions: {
    CreateKintinAccess,
    DeleteKintinAccess,
    ReadKintinAccess,
    UpdateKintinAccess,
  },
});
