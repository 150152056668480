import {
  IntroducerContactDetailsParams,
  IntroducerContactDetailsRoute,
} from "@/module/introducer.contact/page/details";
import { AuthService } from "@/service/auth.service";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { IntroducerContact } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const IntroducerContactDetailsMixin = defineComponent({
  name: "IntroducerContactDetailsMixin",
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.introducer)],
  computed: {
    $params(): IntroducerContactDetailsParams {
      return this.$route.params as any;
    },
    introducerContact(): IntroducerContact | null {
      return IntroducerContact.$findOne(this.$params.introducerContact);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("introducer-contact:write");
    },
  },
  methods: {
    visitIntroducerContactDetails(): void {
      window.Kernel.Router.push({
        name: IntroducerContactDetailsRoute,
        params: { introducerContact: this.introducerContact?.id },
      });
    },
  },
});
