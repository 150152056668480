var u = Object.defineProperty;
var a = (o, e) => u(o, "name", { value: e, configurable: !0 });
import d from "../../_vue/component.display/icon/icon.js";
import m from "../../_vue/component.display/panel/panel.js";
import p from "../../_vue/component.form/form/form.js";
import h from "../../_vue/component.input/auto-complete-field/auto-complete-field.js";
import y from "../../_vue/component.input/text-field/text-field.js";
import w from "../../_vue/component.layout/dialog/dialog.js";
import { GenerateFormValidation as q } from "../../form-builder/validate-form.js";
import { ValidationType as v } from "../../validation/core/validation-type.js";
import { validate as b } from "../../validation/validator.service.js";
import { DashLoader as D } from "@kinherit/ts-common";
import { defineComponent as T } from "vue";
import { KernelModes as g } from "../kernel-mode.js";
import { InternalError as R } from "./internal-error.js";
const $ = T({
  name: "PragmaticDialogLoader",
  components: { Dialog: w, TextField: y, AutoCompleteField: h, Panel: m, Form: p, Icon: d },
  data: /* @__PURE__ */ a(() => ({
    isAnimating: !1,
    dialogRequests: Array()
  }), "data"),
  computed: {
    isUnitTest() {
      var o;
      return ((o = window.Kernel) == null ? void 0 : o.Mode) === g.UnitTest;
    }
  },
  mounted() {
    var o, e;
    window.Kernel.Global.setPragmaticDialogComponentLoader(this.openDialog), (e = (o = this.$router) == null ? void 0 : o.beforeEach) == null || e.call(o, (t, i, s) => {
      const n = this.dialogRequests.reverse();
      for (let l = n.length - 1; l >= 0; l--)
        n[l].request.config.dialog.dismissOnRouteChange !== !1 && this.reject(l);
      s();
    });
  },
  methods: {
    setFirstStackClass() {
      let o = this.dialogRequests.slice().reverse().findIndex((e) => e.request.config.dialog.type === "stacked");
      o !== -1 && (o = this.dialogRequests.length - 1 - o, this.dialogRequests.forEach((e, t) => {
        const i = this.findDialogByIndex(t);
        t === o ? i == null || i.classList.add("is-stacked-first") : i == null || i.classList.remove("is-stacked-first");
      }));
    },
    async openDialog(o) {
      var i, s;
      for (; this.isAnimating; )
        await console.sleep(10);
      window.Kernel.Logger.log("Global", "info", "Opening dialog", {
        button: o.config.button,
        dialog: o.config.dialog,
        input: o.config.input,
        list: o.config.list,
        type: o.config.contentType
      }), o.config.dialog.type || (o.config.contentType === "dialog-form" ? o.config.dialog.type = "stacked" : o.config.dialog.type = "alert"), this.dialogRequests.push({
        request: o,
        value: ((i = o.config.form) == null ? void 0 : i.localData) ?? o.config.value,
        originalValue: ((s = o.config.form) == null ? void 0 : s.localData) ?? o.config.value,
        okButtonDisabled: this.disableOkButton(o)
      });
      const e = document.activeElement;
      e && e.blur(), await this.$nextTick(), window.Kernel.Mode !== "Test" && await console.sleep(1), this.setFirstStackClass();
      const t = this.findDialogByIndex(this.dialogRequests.length - 1);
      if (t == null || t.classList.add("is-showing"), o.config.dialog.type === "width-auto" && t !== null) {
        const n = t.querySelector(".modal-card"), l = n.offsetWidth, r = n.offsetHeight, c = window.Kernel.Global.mousePosition.x, f = window.Kernel.Global.mousePosition.y;
        n.style.left = `${c - l / 2}px`, n.style.top = `${f - r / 2}px`;
      }
    },
    disableOkButton(o) {
      var t;
      const e = (t = o.config.input) == null ? void 0 : t.validation;
      return ["dialog-text", "dialog-autocomplete"].includes(
        o.config.contentType
      ) && Array.isArray(e) ? b({
        label: "",
        validator: e,
        value: o.config.value
      }).filter((i) => v.Error === i.type).length > 0 : !1;
    },
    remove(o) {
      const e = this.findDialogByIndex(o);
      e == null || e.classList.remove("is-showing"), window.Kernel.Mode !== "Test" && (this.isAnimating = !0), this.$nextTick(async () => {
        var i, s;
        if (window.Kernel.Mode !== "Test" && await console.sleep(190), this.dialogRequests = this.dialogRequests.filter((n, l) => l !== o), this.setFirstStackClass(), o - 1 >= 0) {
          const n = this.findDialogByIndex(o - 1);
          n == null || n.classList.add("is-showing");
        }
        this.isAnimating = !1;
        const t = this.dialogRequests.last();
        t !== void 0 && ["dialog-autocomplete", "dialog-text", "dialog-panel"].includes(
          t.request.config.contentType
        ) && ((s = (i = this.findDialogByIndex(this.dialogRequests.length - 1)) == null ? void 0 : i.querySelector("input")) == null || s.focus());
      });
    },
    findDialogByIndex(o) {
      var e;
      return ((e = window.Kernel) == null ? void 0 : e.Mode) === g.UnitTest ? null : this.$el.closest("body").querySelectorAll(".pragmatic-container .dialog-component")[o];
    },
    async resolve(o) {
      const e = this.dialogRequests[o];
      if (e.request.config.contentType === "dialog-form" && e.request.config.form.isValid(
        e.request.config.form.localData
      ) === !1) {
        e.request.config.form.options === void 0 && (e.request.config.form.options = {}), e.request.config.form.options.showValidation = !0, window.Kernel.Logger.log(
          "Validation",
          "warn",
          "Form is not valid",
          q(
            e.request.config.form,
            e.request.config.form.localData
          )
        );
        return;
      }
      window.Kernel.Logger.log("Global", "info", "Dialog resolved", {
        config: {
          button: e.request.config.button,
          dialog: e.request.config.dialog,
          input: e.request.config.input,
          list: e.request.config.list,
          contentType: e.request.config.contentType
        },
        value: e.value
      }), this.remove(o), e.request.resolve(e.value);
    },
    async reject(o) {
      const e = this.dialogRequests[o];
      window.Kernel.Logger.log("Global", "info", "Dialog rejected", {
        config: {
          button: e.request.config.button,
          dialog: e.request.config.dialog,
          input: e.request.config.input,
          list: e.request.config.list,
          contentType: e.request.config.contentType
        },
        value: e.value
      }), this.remove(o), e.request.reject(
        new R({
          message: "Dialog rejected",
          data: e.originalValue
        })
      );
    },
    async leftClick(o, e) {
      var i, s;
      const t = this.dialogRequests[e];
      window.Kernel.Logger.log("Global", "info", "Dialog left button clicked", {
        config: {
          button: t.request.config.button,
          dialog: t.request.config.dialog,
          input: t.request.config.input,
          list: t.request.config.list,
          contentType: t.request.config.contentType
        },
        value: t.value
      }), ((s = (i = t.request.config.button) == null ? void 0 : i.left) == null ? void 0 : s.click) !== void 0 && await t.request.config.button.left.click(
        this.dialogRequests[e].value,
        {
          dismissDialog: /* @__PURE__ */ a(async (n) => {
            n ? await this.resolve(e) : await this.reject(e);
          }, "dismissDialog")
        }
      );
    },
    mapField(o, e) {
      return e ? typeof e == "function" ? e(o) : D.get(o, e.split(".")) : null;
    },
    backgroundClick(o) {
      const e = this.dialogRequests[o];
      e.request.config.dialog.type === "width-auto" && (window.Kernel.Logger.log("Global", "info", "Dialog rejected", {
        config: {
          button: e.request.config.button,
          dialog: e.request.config.dialog,
          input: e.request.config.input,
          list: e.request.config.list,
          contentType: e.request.config.contentType
        },
        value: e.value
      }), this.remove(o), e.request.reject(e.originalValue));
    }
  }
});
export {
  $ as _
};
