import { DangerDialog } from "@/config/dialog.config";
import {
  OptionsAutoCompleteField,
  SharedFormProps,
} from "@/config/form.config";
import { CreateProfile } from "@/config/model.config";
import { StyleService } from "@/service/style.service";
import { FormTable } from "@kinherit/framework/component.display/table";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import { Controls } from "@kinherit/framework/form-builder/core/component-wrapper";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import {
  Address,
  EmailAddress,
  Kintin,
  PhoneNumber,
  Profile,
  Website,
} from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common";
import { UpdateAddressForm } from "../address/update-address.form";
import { UpdateEmailAddressForm } from "../email-address/update-email-address.form";
import { UpdatePhoneNumberForm } from "../phone-number/update-phone-number.form";
import { UpdateWebsiteForm } from "../website/update-website.form";

const RemoveContactDetail = async (
  payload: { id: string; $name: string },
  data: Profile,
  controls: Controls,
) => {
  await DangerDialog({
    dialog: {
      title: `Delete ${payload.$name}`,
      message: `Are you sure you want to delete this ${payload.$name}?`,
    },
  });

  switch (payload.$name) {
    case Address.$name:
      data.removeAddresses({ id: payload.id } as Address);
      Address.$findOneOrThrow(payload.id).$delete();
      break;
    case EmailAddress.$name:
      data.removeEmails({ id: payload.id } as EmailAddress);
      EmailAddress.$findOneOrThrow(payload.id).$delete();
      break;
    case PhoneNumber.$name:
      data.removePhoneNumbers({ id: payload.id } as PhoneNumber);
      PhoneNumber.$findOneOrThrow(payload.id).$delete();
      break;
    case Website.$name:
      data.removeWebsites({ id: payload.id } as Website);
      Website.$findOneOrThrow(payload.id).$delete();
      break;
  }

  controls.rebuildForm();
  controls.emit("update:data", data);

  return data;
};

export const FormContactDetailButtonSection = (
  data: Profile,
  kintin?: Kintin,
) => [
  FormAddAddressButton(data, kintin),
  FormAddEmailButton(data),
  FormAddPhoneButton(data),
  FormAddWebsiteButton(data),
];

const FormAddAddressButton = (data: Profile, kintin?: Kintin) => {
  return FormButton({
    props: {
      iconLeft: StyleService.icon.addressBook.icon,
      color: "is-positive",
      shade: "is-light",
      class: "create-address",
      size: "is-small",
      text: "Add Address",
      reference: "addAddress",
    },
    emits: {
      click: async (_payload, _data, controls) => {
        const address = new Address({
          id: Uuid.generate(),
          line1: "",
          line2: "",
          city: "",
          state: "",
          postcode: "",
          country: "GB",
          summary: "",
          primaryMailing: data.addresses
            .filter((a) => a.primaryMailing)
            .isEmpty(),
          primaryResidential: data.addresses
            .filter((a) => a.primaryResidential)
            .isEmpty(),
          createdAt: new DateTime().formatMachine,
        });

        try {
          await UpdateAddressForm({
            kintin,
            data: address,
            isNew: true,
          }).dialog();
        } catch {
          address.$delete();
          return;
        }

        data.addAddresses(address);
        controls.rebuildForm();
        controls.emit("update:data", data);
      },
    },
  });
};

export const FormAddressSection = (data: Profile, kintin?: Kintin) => [
  FormTable({
    props: {
      compressEmptyMessage: true,
      columns: [
        {
          map: (a: Address) => `${a.summary}, ${a.country}`,
        },
        {
          slot: "actions",
          class: "buttons is-right",
        },
      ],
      rows: data.addresses,
      class: "address-table",
      showFooterControls: false,
      isNarrow: true,
      showHeaderControls: false,
      reference: "addresses",
      title: "Addresses",
    },
    slots: {
      actions: [
        FormButton({
          props: {
            ariaLabel: "Remove",
            class: "remove",
            color: "is-plain",
            size: "is-small",
            iconLeft: StyleService.icon.delete.icon,
            reference: "remove",
          },
          emits: {
            click: async (row, _data, controls) => {
              await RemoveContactDetail(
                controls.templateScope.row as Address,
                data,
                controls,
              );
            },
          },
        }),
        FormButton({
          props: {
            color: "is-plain",
            size: "is-small",
            ariaLabel: "Edit",
            iconLeft: StyleService.icon.edit.icon,
            reference: "edit",
          },
          emits: {
            click: async (row, _data, controls) => {
              const address = controls.templateScope.row as Address;
              try {
                await UpdateAddressForm({ data: address, kintin }).dialog();
              } catch {
                address.$restore();
                return;
              }

              // address.$persist();

              controls.rebuildForm();
              controls.emit("update:data", data);
            },
          },
        }),
        FormButton({
          props: {
            text: "Mailing",
            size: "is-small",
            color: (_, controls) =>
              (controls.templateScope.row as Address).primaryMailing
                ? "is-highlight"
                : "is-light-grey",
            reference: "primaryMailing",
          },
          emits: {
            click: async (_, _data, controls) => {
              const row = controls.templateScope.row as Address;
              row.primaryMailing = true;
              // row.$persist();

              data.addresses.forEach((a) => {
                if (a.id === row.id) {
                  return;
                }
                a.primaryMailing = false;
                // a.$persist();
              });

              controls.rebuildForm();
              controls.emit("update:data", data);
            },
          },
        }),
        FormButton({
          props: {
            text: "Residential",
            size: "is-small",
            color: (_, controls) =>
              (controls.templateScope.row as Address).primaryResidential
                ? "is-highlight"
                : "is-light-grey",
            reference: "primaryResidential",
          },
          emits: {
            click: async (_, _data, controls) => {
              const row = controls.templateScope.row as Address;
              row.primaryResidential = true;
              // row.$persist();

              data.addresses.forEach((a) => {
                if (a.id === row.id) {
                  return;
                }
                a.primaryResidential = false;
                // a.$persist();
              });

              controls.rebuildForm();
              controls.emit("update:data", data);
            },
          },
        }),
      ],
    },
  }),
];

const FormAddEmailButton = (data: Profile) => {
  return FormButton<any>({
    props: {
      iconLeft: StyleService.icon.email.icon,
      color: "is-positive",
      shade: "is-light",
      class: "create-email",
      size: "is-small",
      text: "Add Email",
      reference: "addEmail",
    },
    emits: {
      click: async (_payload, _data, controls) => {
        const email = new EmailAddress({
          id: Uuid.generate(),
          email: "",
          primary: data.emails.filter((a) => a.primary).isEmpty(),
          createdAt: new DateTime().formatMachine,
        });

        try {
          data.addEmails(
            await UpdateEmailAddressForm({ data: email, isNew: true }).dialog(),
          );
        } catch {
          email.$delete();
          return;
        }

        controls.rebuildForm();
        controls.emit("update:data", data);
      },
    },
  });
};

export const FormEmailSection = (data: Profile) => [
  FormTable({
    props: {
      compressEmptyMessage: true,
      columns: [
        {
          field: "email",
        },
        {
          slot: "actions",
          class: "buttons is-right",
        },
      ],
      rows: data.emails,
      class: "email-table",
      showFooterControls: false,
      isNarrow: true,
      reference: "emailAddresses",
      showHeaderControls: false,
      title: "Email Addresses",
    },
    slots: {
      actions: [
        FormButton({
          props: {
            ariaLabel: "Remove",
            class: "remove",
            color: "is-plain",
            size: "is-small",
            iconLeft: StyleService.icon.delete.icon,
            reference: "remove",
          },
          emits: {
            click: async (row, _data, controls) => {
              await RemoveContactDetail(
                controls.templateScope.row as EmailAddress,
                data,
                controls,
              );
            },
          },
        }),
        FormButton({
          props: {
            ariaLabel: "Edit",
            color: "is-plain",
            size: "is-small",
            iconLeft: StyleService.icon.edit.icon,
            reference: "edit",
          },
          emits: {
            click: async (row, _data, controls) => {
              const email = controls.templateScope.row as EmailAddress;
              try {
                await UpdateEmailAddressForm({ data: email }).dialog();
              } catch {
                email.$restore();
                return;
              }

              // email.$persist();

              controls.rebuildForm();
              controls.emit("update:data", data);
            },
          },
        }),
        FormButton({
          props: {
            text: "Primary",
            size: "is-small",
            color: (_, controls) =>
              (controls.templateScope.row as EmailAddress).primary
                ? "is-highlight"
                : "is-light-grey",
            reference: "primary",
          },
          emits: {
            click: async (_, _data, controls) => {
              const row = controls.templateScope.row as EmailAddress;
              row.primary = true;
              // row.$persist();

              data.emails.forEach((a) => {
                if (a.id === row.id) {
                  return;
                }
                a.primary = false;
                // a.$persist();
              });

              controls.rebuildForm();
              controls.emit("update:data", data);
            },
          },
        }),
      ],
    },
  }),
];

const FormAddPhoneButton = (data: Profile) => {
  return FormButton<any>({
    props: {
      iconLeft: StyleService.icon.phone.icon,
      color: "is-positive",
      shade: "is-light",
      class: "create-phone-number",
      size: "is-small",
      text: "Add Number",
      reference: "addPhoneNumber",
    },
    emits: {
      click: async (_payload, _data, controls) => {
        // data.addPhoneNumbers(await UpdatePhoneNumberForm().dialog());

        const phoneNumber = new PhoneNumber({
          id: Uuid.generate(),
          tel: "",
          primary: data.phoneNumbers.filter((a) => a.primary).isEmpty(),
          createdAt: new DateTime().formatMachine,
        });

        try {
          data.addPhoneNumbers(
            await UpdatePhoneNumberForm({
              data: phoneNumber,
              isNew: true,
            }).dialog(),
          );
        } catch {
          phoneNumber.$delete();
          return;
        }

        controls.rebuildForm();
        controls.emit("update:data", data);
      },
    },
  });
};

export const FormPhoneSection = (data: Profile) => [
  FormTable({
    props: {
      reference: "phoneNumbers",
      compressEmptyMessage: true,
      columns: [
        {
          field: "tel",
        },
        {
          slot: "actions",
          class: "buttons is-right",
        },
      ],
      rows: data.phoneNumbers,
      class: "phone-table",
      showFooterControls: false,
      isNarrow: true,
      showHeaderControls: false,
      title: "Phone Numbers",
    },
    slots: {
      actions: [
        FormButton({
          props: {
            ariaLabel: "Remove",
            class: "remove",
            color: "is-plain",
            size: "is-small",
            iconLeft: StyleService.icon.delete.icon,
            reference: "remove",
          },
          emits: {
            click: async (row, _data, controls) => {
              await RemoveContactDetail(
                controls.templateScope.row as PhoneNumber,
                data,
                controls,
              );
            },
          },
        }),
        FormButton({
          props: {
            ariaLabel: "Edit",
            color: "is-plain",
            size: "is-small",
            iconLeft: StyleService.icon.edit.icon,
            reference: "edit",
          },
          emits: {
            click: async (row, _data, controls) => {
              const phoneNumber = controls.templateScope.row as PhoneNumber;
              try {
                await UpdatePhoneNumberForm({ data: phoneNumber }).dialog();
              } catch {
                phoneNumber.$restore();
                return;
              }

              // phoneNumber.$persist();

              controls.rebuildForm();
              controls.emit("update:data", data);
            },
          },
        }),
        FormButton({
          props: {
            text: "Primary",
            size: "is-small",
            color: (_, controls) =>
              (controls.templateScope.row as PhoneNumber).primary
                ? "is-highlight"
                : "is-light-grey",
            reference: "primary",
          },
          emits: {
            click: async (_, _daata, controls) => {
              const row = controls.templateScope.row as PhoneNumber;
              row.primary = true;
              // row.$persist();

              data.phoneNumbers.forEach((a) => {
                if (a.id === row.id) {
                  return;
                }
                a.primary = false;
                // a.$persist();
              });

              controls.rebuildForm();
              controls.emit("update:data", data);
            },
          },
        }),
      ],
    },
  }),
];

const FormAddWebsiteButton = (data: Profile) => {
  return FormButton<any>({
    props: {
      iconLeft: StyleService.icon.company.icon,
      color: "is-positive",
      shade: "is-light",
      class: "create-website",
      size: "is-small",
      text: "Add Website",
      reference: "addWebsite",
    },
    emits: {
      click: async (_payload, _data, controls) => {
        // data.addWebsites(await UpdateWebsiteForm().dialog());

        const website = new Website({
          id: Uuid.generate(),
          url: "",
          createdAt: new DateTime().formatMachine,
        });

        try {
          data.addWebsites(
            await UpdateWebsiteForm({
              data: website,
              isNew: true,
            }).dialog(),
          );
        } catch {
          website.$delete();
          return;
        }

        controls.rebuildForm();
        controls.emit("update:data", data);
      },
    },
  });
};

export const FormWebsiteSection = (data: Profile) => [
  FormTable<any>({
    props: {
      reference: "websites",
      compressEmptyMessage: true,
      columns: [
        {
          map: (a: Website) => a.url,
        },
        {
          slot: "actions",
          class: "buttons is-right",
        },
      ],
      rows: data.websites,
      class: "website-table",
      showFooterControls: false,
      isNarrow: true,
      showHeaderControls: false,
      title: "Websites",
    },
    slots: {
      actions: [
        FormButton({
          props: {
            ariaLabel: "Remove",
            class: "remove",
            color: "is-plain",
            size: "is-small",
            iconLeft: StyleService.icon.delete.icon,
            reference: "remove",
          },
          emits: {
            click: async (row, _data, controls) => {
              await RemoveContactDetail(
                controls.templateScope.row as Website,
                data,
                controls,
              );
            },
          },
        }),
        FormButton({
          props: {
            ariaLabel: "Edit",
            color: "is-plain",
            size: "is-small",
            iconLeft: StyleService.icon.edit.icon,
            reference: "edit",
          },
          emits: {
            click: async (row, _data, controls) => {
              const website = controls.templateScope.row as Website;
              try {
                await UpdateWebsiteForm({ data: website }).dialog();
              } catch {
                website.$restore();
                return;
              }

              // phoneNumber.$persist();

              controls.rebuildForm();
              controls.emit("update:data", data);
            },
          },
        }),
      ],
    },
  }),
];

export const UpdateProfileForm = (profile: Profile = CreateProfile()) => {
  return defineForm({
    name: "update-profile-form",
    props: SharedFormProps,
    data: () => profile,
    formAreas: (data) => [
      defineFormArea({
        name: "update-profile-personal-area",
        data,
        show: () => !profile.introducerCompany,
        template: GridLayout([
          ["title", "knownAs", "jobTitle"],
          ["firstName", "middleNames", "lastName"],
        ]),
        components: () => ({
          title: [
            OptionsAutoCompleteField({ vModel: "title", group: "titles" }),
          ],
          knownAs: [
            FormTextField({
              props: {
                label: "Known as",
              },
              models: {
                value: "knownAs",
              },
            }),
          ],
          jobTitle: [
            FormTextField({
              props: {
                label: "Job Title",
              },
              models: {
                value: "jobTitle",
              },
            }),
          ],
          firstName: [
            FormTextField({
              props: {
                label: "First Name",
              },
              models: {
                value: "firstName",
              },
            }),
          ],
          middleNames: [
            FormTextField({
              props: {
                label: "Middle Names",
              },
              models: {
                value: "middleNames",
              },
            }),
          ],
          lastName: [
            FormTextField({
              props: {
                label: "Last Name",
              },
              models: {
                value: "lastName",
              },
            }),
          ],
        }),
      }),
      defineFormArea({
        name: "update-profile-branded-kinvault-area",
        data,
        template: GridLayout([["organisationName", "organisationNumber"]]),
        components: () => ({
          organisationName: [
            FormTextField({
              props: {
                label: "Organisation Name",
              },
              models: {
                value: "organisationName",
              },
            }),
          ],
          organisationNumber: [
            FormTextField({
              props: {
                label: "Organisation Number",
              },
              models: {
                value: "organisationNumber",
              },
            }),
          ],
        }),
      }),
      defineFormArea({
        name: "update-profile-contact-area",
        data,
        template: {
          children: [
            {
              slot: "buttons",
            },
            {
              slot: "addresses",
            },
            {
              slot: "emailAddresses",
            },
            {
              slot: "phoneNumbers",
            },
            {
              slot: "websites",
            },
          ],
        },
        components: (data) => ({
          buttons: FormContactDetailButtonSection(data),
          addresses: FormAddressSection(data),
          emailAddresses: FormEmailSection(data),
          phoneNumbers: FormPhoneSection(data),
          websites: FormWebsiteSection(data),
        }),
      }),
    ],
  });
};
