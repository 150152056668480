// SignDoc, Kintin, Person, Profile, Address, PhoneNumber, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { SignDoc } from "@kinherit/sdk/model/sign-doc";

export const RecordSignDoc = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/sign-doc/{signDoc}/contacts",
  method: "get",
  rootModel: SignDoc,
  parse: (message: { kintin: Kintin | string; signDoc: SignDoc | string }) => ({
    params: {
      kintin: message.kintin,
      signDoc: message.signDoc,
    },
  }),
});
