// KinvaultSubscriptionReferralCode

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultSubscriptionReferralCode } from "@kinherit/sdk";

export const ReadKinvaultSubscriptionReferralCode = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/kinvault-subscription-referral-code",
  method: "get",
  rootModel: KinvaultSubscriptionReferralCode,
  parse: () => ({}),
});
