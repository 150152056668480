<template>
  <div class="branded-kinvault-invites-page">
    <Message
      v-if="hasWarnings"
      title="Warning"
      :color="'is-warning'"
      size="is-small"
      class="mb-4 mt-4"
    >
      <span v-if="!useBrandedKinvaultInvitations" class="is-block">
        <b>Invitations are not enabled for this branded kinvault.</b>
        <br />
        <small>
          To enable invitations, update the branded kinvault settings
        </small>
      </span>
      <span v-if="!hasXeroSetup" class="is-block">
        <b>A Xero contact has not been assigned.</b>
        <br />
        <small>
          To add the Xero Contact Id, update the branded kinvault settings
        </small>
      </span>
      <span v-if="!hasAddress" class="is-block">
        <b>An address has not been added to the profile</b>
        <br />
        <small>
          An address is required to generate a PDF invoice: update the branded
          kinvault profile
        </small>
      </span>
    </Message>
    <Tabs :config="tabs" class="branded-kinvault-invites-tabs" />
    <RouterChildView />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsInvitationsIndexWrapper",
  "route": "AdminBrandedKinvaultDetailsInvitationsIndexRoute",
  "selector": ".branded-kinvault-invites-page",
  "imports": {
    "BrandedKinvaultDetailsInvitationBulkUploadWrapper": "@/module/admin.branded-kinvault/page/details/invitations/BrandedKinvaultDetails.BulkUpload.test",
    "BrandedKinvaultDetailsInvitationsWrapper": "@/module/admin.branded-kinvault/page/details/invitations/BrandedKinvaultDetails.Invitations.test",
    "BrandedKinvaultDetailsInvitationsBillingWrapper": "@/module/admin.branded-kinvault/page/details/invitations/BrandedKinvaultDetails.InviteBilling.test"
  },
  "methods": {
    "tabs": {
      "type": "tabs",
      "selector": ".branded-kinvault-invites-tabs",
      "items": ["Invitations", "Billing"]
    },
    "invitations": {
      "type": "to-one",
      "selector": "",
      "wrapper": "BrandedKinvaultDetailsInvitationsWrapper"
    },
    "billing": {
      "type": "to-one",
      "selector": "",
      "wrapper": "BrandedKinvaultDetailsInvitationsBillingWrapper"
    },
    "bulkUpload": {
      "type": "to-one",
      "selector": "",
      "wrapper": "BrandedKinvaultDetailsInvitationBulkUploadWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { BrandedKinvaultDetailsMixin } from "@/module/admin.branded-kinvault/mixin/branded-kinvault-details.mixin";
import Message from "@kinherit/framework/component.display/message";
import Tabs from "@kinherit/framework/component.display/tabs";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  AdminBrandedKinvaultDetailsInvitationsBillingRoute,
  AdminBrandedKinvaultDetailsInvitationsIndexRoute,
  AdminBrandedKinvaultDetailsInvitationsRoute,
} from ".";

export default defineComponent({
  name: AdminBrandedKinvaultDetailsInvitationsIndexRoute,
  components: { Tabs, RouterChildView, Message },
  mixins: [BrandedKinvaultDetailsMixin],
  data: () => ({
    tabs: [
      {
        label: `Invitations`,
        route: { name: AdminBrandedKinvaultDetailsInvitationsRoute },
      },
      {
        label: `Billing`,
        route: { name: AdminBrandedKinvaultDetailsInvitationsBillingRoute },
      },
    ],
  }),
  computed: {
    hasWarnings() {
      return (
        !this.useBrandedKinvaultInvitations ||
        !this.hasXeroSetup ||
        !this.hasAddress
      );
    },
    useBrandedKinvaultInvitations() {
      return this.brandedKinvault?.useBrandedKinvaultInvitations;
    },
    hasXeroSetup() {
      return this.brandedKinvault?.xeroContact;
    },
    hasAddress() {
      return this.brandedKinvault?.profile?.addresses?.length;
    },
  },
});
</script>
