// IntroducerContact, Profile, IntroducerCompany, Address, EmailAddress, PhoneNumber, Website, IntroducerNote, Note, ContactPartnerPortalPermissions

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";
import { Lead } from "@kinherit/sdk/model/lead";

export const RecordIntroducerContact = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/lead/{lead}/introducer-contact/{introducerContact}",
  method: "get",
  rootModel: IntroducerContact,
  parse: (message: {
    lead: Lead | string;
    introducerContact: IntroducerContact | string;
  }) => ({
    params: {
      lead: message.lead,
      introducerContact: message.introducerContact,
    },
  }),
});
