// Subscription, PhysicalStorageFile, Product, PhysicalStorageFileLog

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { PhysicalStorageSubscription } from "@kinherit/sdk";

export const CreateSubscription = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/physical-storage-subscription",
  method: "post",
  parse: (target: PhysicalStorageSubscription) => ({
    body: {
      billingDate: target.$data.billingDate,
      procXero: target.$data.procXero,
      procDd: target.$data.procDd,
      notes: target.$data.notes,
      subscriptionValue: target.$data.subscriptionValue,
      billingEndDate: target.$data.billingEndDate,
      xeroRepeatingInvoiceID: target.$data.xeroRepeatingInvoiceID,
      fileCount: target.$data.fileCount,
      kintin: target.$data.kintin,
      status: target.$data.status,
      productSku: target.$data.productSku,
      provider: target.$data.provider,
      xeroContact: target.$data.xeroContact,
    },
    params: {
      kintin: target.$data.kintin,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
