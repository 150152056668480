import { defineComponent as e } from "vue";
import { S as o } from "../../_assets/component.display/loading/loading.module.js";
const i = e({
  name: "LoadingComponent",
  beforeMount() {
    window.Kernel.injectStylesheet("loading", o);
  }
});
export {
  i as _
};
