var w = Object.defineProperty;
var s = (c, t) => w(c, "name", { value: t, configurable: !0 });
const o = class o {
  static getQuery(t) {
    var n, e, r;
    return (r = (e = (n = window.Kernel.Router) == null ? void 0 : n.currentRoute) == null ? void 0 : e.value) == null ? void 0 : r.query[t];
  }
  static setQuery(t, n) {
    var i, u;
    if (window.Kernel.Mode === "Test")
      return;
    const e = (u = (i = window.Kernel.Router) == null ? void 0 : i.currentRoute) == null ? void 0 : u.value;
    if (!e)
      return;
    e.query || (e.query = {});
    const r = new URL(window.location.href);
    r.searchParams.set(t, n), window.Kernel.Router.options.history.replace(
      r.toString().replace(r.origin, "")
    );
  }
};
s(o, "RouteQueryHelper");
let a = o;
export {
  a as RouteQueryHelper
};
