import { RouteInterface } from "@kinherit/framework/core/route-config";
import { SettingsBreadCrumb } from "../breadcrumbs";

export const SettingsIndexRoute = "Settings";
export const SettingsCacheRoute = "SettingsCache";
export const SettingsXeroRoute = "SettingsXero";
export const SettingsAcuityRoute = "SettingsAcuity";
export const SettingsEmailRoute = "SettingsEmail";
export const SettingsSortReferRoute = "SettingsSortRefer";
export const SettingsCronJobsRoute = "SettingsCronJobs";

export const Routes: Array<RouteInterface> = [
  {
    name: SettingsIndexRoute,
    path: "/settings",
    component: () => import(/* webpackChunkName: "Settings" */ "./Index.vue"),
    meta: {
      breadcrumbs: () => [SettingsBreadCrumb],
    },
    children: [
      {
        name: SettingsCacheRoute,
        path: "/settings/cache",
        component: () =>
          import(
            /* webpackChunkName: "Settings.Cache" */ "./Settings.Cache.vue"
          ),
      },
      {
        name: SettingsXeroRoute,
        path: "/settings/xero",
        component: () =>
          import(/* webpackChunkName: "Settings.Xero" */ "./Settings.Xero.vue"),
      },
      {
        name: SettingsAcuityRoute,
        path: "/settings/acuity",
        component: () =>
          import(
            /* webpackChunkName: "Settings.Acuity" */ "./Settings.Acuity.vue"
          ),
      },
      {
        name: SettingsSortReferRoute,
        path: "/settings/sortrefer",
        component: () =>
          import(
            /* webpackChunkName: "Settings.Email" */ "./Settings.SortRefer.vue"
          ),
      },
      {
        name: SettingsEmailRoute,
        path: "/settings/email",
        component: () =>
          import(
            /* webpackChunkName: "Settings.Email" */ "./Settings.Email.vue"
          ),
      },
      {
        name: SettingsCronJobsRoute,
        path: "/settings/cron-jobs",
        component: () =>
          import(
            /* webpackChunkName: "Settings.CronJobs" */ "./Settings.CronJobs.vue"
          ),
      },
    ],
  },
  {
    name: "file-log",
    path: "/settings/file-log",
    component: () => import(/* webpackChunkName: "FileLog" */ "./FileLog.vue"),
    meta: {
      breadcrumbs: () => [SettingsBreadCrumb],
    },
  },
];
