var h = Object.defineProperty;
var i = (e, o) => h(e, "name", { value: o, configurable: !0 });
import { _ as p } from "../../../component.input/checkbox-field/checkbox-field.vue_vue_type_script_lang.js";
import { resolveComponent as d, createElementBlock as a, openBlock as t, mergeProps as k, createVNode as n, normalizeClass as s, withCtx as v, createCommentVNode as r, createElementVNode as l, renderSlot as u, withDirectives as C, vModelCheckbox as _ } from "vue";
import { b as c } from "../../../_assets/component.input/checkbox-field/checkbox-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as $ } from "../../../_plugins/_plugin-vue_export-helper.js";
const g = {
  key: 0,
  class: "input-field__control"
}, w = ["disabled", "autofocus", "autocomplete"], V = ["innerHTML"], y = {
  key: 1,
  class: "input-field__control"
};
function z(e, o, M, S, F, G) {
  const m = d("Icon"), f = d("Field");
  return t(), a("div", k(e.$data.$cypress, {
    class: ["input-field", e.wrapperClasses]
  }), [
    n(f, {
      class: s({
        "checkbox-field": !0,
        [e.size]: !0,
        [`is-${e.computedState}`]: !!e.computedState
      }),
      "has-addons": e.computedhasAddons,
      "is-grouped": e.computedIsGrouped,
      messages: e.computedMessages,
      size: e.size
    }, {
      default: v(() => [
        e.showLeftControl ? (t(), a("div", g, [
          u(e.$slots, "left", { value: e.computedValue })
        ])) : r("", !0),
        l("div", {
          class: s(`input-field__control ${e.controlClasses} is-expanded`)
        }, [
          l("label", {
            class: s(["checkbox-field__wrapper label", {
              "is-clickable": e.state !== "disabled"
            }])
          }, [
            C(l("input", {
              "onUpdate:modelValue": o[0] || (o[0] = (b) => e.valueGuard = b),
              class: "checkbox-field__input",
              type: "checkbox",
              disabled: e.computedState === "disabled",
              autofocus: e.autofocus,
              autocomplete: e.autocomplete ?? void 0
            }, null, 8, w), [
              [_, e.valueGuard]
            ]),
            n(m, {
              class: s(["checkbox-field__icon", `${e.inputClasses}`]),
              icon: "Tick"
            }, null, 8, ["class"]),
            l("span", {
              class: "checkbox-field__label is-unselectable",
              innerHTML: e.label
            }, null, 8, V)
          ], 2)
        ], 2),
        e.showRightControl ? (t(), a("div", y, [
          u(e.$slots, "right", { value: e.computedValue })
        ])) : r("", !0)
      ]),
      _: 3
    }, 8, ["class", "has-addons", "is-grouped", "messages", "size"])
  ], 16);
}
i(z, "_sfc_render");
typeof c == "function" && c(p);
const E = /* @__PURE__ */ $(p, [["render", z]]);
export {
  E as default
};
