// Address, Appointment, BrandedKinvault, BrandedKinvaultInvitation, CpdSessionRegistration, EmailAddress, IntroducerBillingContact, IntroducerCompany, IntroducerContact, Lead, Person, PhoneNumber, Profile, User, Website
import { Api } from "@/service/api.service";
import {
  Address,
  Appointment,
  BrandedKinvault,
  BrandedKinvaultInvitation,
  CpdSessionRegistration,
  EmailAddress,
  IntroducerCompany,
  IntroducerContact,
  IProfile,
  Lead,
  Person,
  PhoneNumber,
  Profile,
  User,
  Website,
} from "@kinherit/sdk";

export interface ReadSearchProfileMessage {
  name: string | null;
  address: string | null;
  emailAddress: string | null;
  phoneNumber: string | null;
  website: string | null;
  pagination?: {
    currentPage: number;
    perPage: number;
  };
  sort?: {
    by: keyof IProfile;
    direction: "asc" | "desc";
  };
}

export interface ReadSearchProfileResponse {
  addresses: Array<Address>;
  appointments: Array<Appointment>;
  brandedKinvaults: Array<BrandedKinvault>;
  brandedKinvaultInvitations: Array<BrandedKinvaultInvitation>;
  cpdSessionRegistrations: Array<CpdSessionRegistration>;
  emailAddresses: Array<EmailAddress>;
  introducerCompanies: Array<IntroducerCompany>;
  introducerContacts: Array<IntroducerContact>;
  leads: Array<Lead>;
  person: Array<Person>;
  phoneNumbers: Array<PhoneNumber>;
  profiles: Array<Profile>;
  users: Array<User>;
  websites: Array<Website>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadProfiles = async (
  message: ReadSearchProfileMessage,
): Promise<ReadSearchProfileResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/dashboard/search/profile",
  )
    .method("get")
    .sort(message.sort)
    .params({
      name: message.name ?? undefined,
      address: message.address ?? undefined,
      emailAddress: message.emailAddress ?? undefined,
      phoneNumber: message.phoneNumber ?? undefined,
      website: message.website ?? undefined,
    })
    .paginate(message.pagination)

    .result();
  return {
    addresses: Address.$inflate(response.address),
    appointments: Appointment.$inflate(response.appointment),
    brandedKinvaults: BrandedKinvault.$inflate(response.brandedKinvault),
    brandedKinvaultInvitations: BrandedKinvaultInvitation.$inflate(
      response.brandedKinvaultInvitation,
    ),
    cpdSessionRegistrations: CpdSessionRegistration.$inflate(
      response.cpdSessionRegistration,
    ),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    introducerCompanies: IntroducerCompany.$inflate(response.introducerCompany),
    introducerContacts: IntroducerContact.$inflate(response.introducerContact),
    leads: Lead.$inflate(response.lead),
    person: Person.$inflate(response.person),
    phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
    profiles: Profile.$inflate(response.profile),
    users: User.$inflate(response.user),
    websites: Website.$inflate(response.website),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
