var _ = Object.defineProperty;
var r = (t, a) => _(t, "name", { value: a, configurable: !0 });
import { _ as p } from "../../../component.display/branded-kinvault-cta/default.vue_vue_type_script_lang.js";
import { resolveComponent as y, createElementBlock as n, openBlock as s, createElementVNode as i, normalizeStyle as o, toDisplayString as f, Fragment as c, renderList as d, normalizeClass as u, createVNode as k } from "vue";
import { _ as v } from "../../../_plugins/_plugin-vue_export-helper.js";
const $ = { class: "branded-kinvault-cta default" }, h = { class: "columns" }, b = ["innerHTML"], B = ["onClick"];
function C(t, a, z, g, L, S) {
  const m = y("Button");
  return s(), n("div", $, [
    i("h2", {
      style: o(t.content.title.styles)
    }, f(t.title), 5),
    i("div", h, [
      (s(!0), n(c, null, d(t.content.texts, (e, l) => (s(), n("div", {
        key: `text-${l}`,
        style: o(e.styles),
        class: u([`is-size-${e.fontSize}`]),
        innerHTML: e.text
      }, null, 14, b))), 128))
    ]),
    i("div", {
      class: u(["buttons", t.content.buttons.side]),
      style: o(t.content.buttons.styles)
    }, [
      (s(!0), n(c, null, d(t.content.buttons.items, (e, l) => (s(), n("span", {
        key: `button-${l}`,
        style: { display: "contents" },
        onClick: /* @__PURE__ */ r((D) => t.$emit("button-clicked", e.uuid), "onClick")
      }, [
        k(m, {
          style: o(e.styles),
          text: e.text,
          href: e.link,
          color: e.color,
          target: "_blank",
          class: "branded-kinvault-cta__button"
        }, null, 8, ["style", "text", "href", "color"])
      ], 8, B))), 128))
    ], 6)
  ]);
}
r(C, "_sfc_render");
const T = /* @__PURE__ */ v(p, [["render", C]]);
export {
  T as default
};
