var k = Object.defineProperty;
var p = (e, d) => k(e, "name", { value: d, configurable: !0 });
import { _ as y } from "../../../component.display/wizard/wizard.vue_vue_type_script_lang.js";
import { resolveComponent as u, createElementBlock as s, openBlock as t, mergeProps as $, createElementVNode as l, createCommentVNode as i, createVNode as a, normalizeStyle as c, Fragment as m, renderList as f, normalizeClass as S, withKeys as v, toDisplayString as h, withDirectives as b, renderSlot as z, vShow as g } from "vue";
import { b as w } from "../../../_assets/component.display/wizard/wizard.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as B } from "../../../_plugins/_plugin-vue_export-helper.js";
const C = ["step-total", "step-current"], N = { class: "wizard__steps-container" }, P = {
  ref: "refStepsWrapper",
  class: "wizard__steps-wrapper"
}, I = {
  key: 0,
  ref: "refPrevButton",
  class: "wizard__prev"
}, W = ["onClick"], H = { class: "wizard__step__info" }, T = {
  key: 0,
  class: "wizard__step__title"
}, V = {
  key: 1,
  class: "mb-0"
}, D = { key: 2 }, G = {
  key: 1,
  class: "wizard__next"
}, K = {
  key: 0,
  class: "wizard__step__content"
};
function E(e, d, L, A, F, R) {
  const n = u("Button"), _ = u("Progress");
  return t(), s("div", $(e.$data.$cypress, {
    class: `wizard ${e.classes}`,
    "step-total": e.compNumSteps,
    "step-current": e.currentStep
  }), [
    l("div", N, [
      l("div", P, [
        e.compShowNavButtons ? (t(), s("div", I, [
          a(n, {
            "icon-left": e.compIsHorizontal ? "ChevronLeft" : "ChevronUp",
            "aria-label": e.$t.local.previousStep,
            "is-disabled": e.navStep === 0,
            onClick: e.clickPrev
          }, null, 8, ["icon-left", "aria-label", "is-disabled", "onClick"])
        ], 512)) : i("", !0),
        l("div", {
          class: "wizard__steps",
          style: c(e.compWizardStepsStyle)
        }, [
          a(_, {
            ref: "refProgressBar",
            style: c([
              `${e.hideProgessBar ? "visibility:hidden;" : ""}`,
              e.compProgressBarStyle
            ]),
            direction: e.compIsHorizontal ? "is-horizontal" : "is-vertical",
            color: e.color,
            min: 0,
            max: e.compNumSteps - 1,
            progress: e.currentStep
          }, null, 8, ["style", "direction", "color", "max", "progress"]),
          (t(!0), s(m, null, f(e.config, (r, o) => (t(), s("div", {
            key: `step-button-${o}`,
            ref_for: !0,
            ref: "refWizardStep",
            class: S([
              `wizard__step step-${o}`,
              { "is-current-step": e.currentStep === o }
            ]),
            style: c(`width: ${e.compIsHorizontal ? e.stepSize + "px" : null}`),
            onClick: /* @__PURE__ */ p(() => e.goToStep(o), "onClick")
          }, [
            a(n, {
              ref_for: !0,
              ref: "refWizardStepButton",
              class: S(["wizard__step__circle", `${e.currentStep === o ? "is-active" : ""} ${e.currentStep >= o ? "visited" : ""}`]),
              size: null,
              color: e.GetButtonColor(o, r),
              "icon-left": e.GetButtonIcon(o, r),
              text: e.GetButtonText(o, r),
              "aria-label": r.title ?? e.$t.local.step(o),
              "is-disabled": e.canShowStep !== null ? !e.canShowStep(o) : !1,
              onKeyup: [
                v(() => e.goToStep(o), ["enter"]),
                v(() => e.goToStep(o), ["space"])
              ]
            }, null, 8, ["class", "color", "icon-left", "text", "aria-label", "is-disabled", "onKeyup"]),
            l("div", H, [
              r.title ? (t(), s("div", T, h(r.title), 1)) : (t(), s("div", V, " ")),
              r.message ? (t(), s("small", D, h(r.message), 1)) : i("", !0)
            ])
          ], 14, W))), 128))
        ], 4),
        e.compShowNavButtons ? (t(), s("div", G, [
          a(n, {
            "icon-left": e.compIsHorizontal ? "ChevronRight" : "ChevronDown",
            "aria-label": e.$t.local.nextStep,
            "is-disabled": e.navStep >= e.compNumSteps - e.numVisibleSteps,
            onClick: e.clickNext
          }, null, 8, ["icon-left", "aria-label", "is-disabled", "onClick"])
        ])) : i("", !0)
      ], 512)
    ]),
    (t(!0), s(m, null, f(e.slots, (r, o) => b((t(), s("div", {
      key: `step-${o}`,
      class: "wizard__step__content"
    }, [
      z(e.$slots, r)
    ])), [
      [g, e.currentStep === o]
    ])), 128)),
    e.defaultSlot ? (t(), s("div", K, [
      z(e.$slots, "default")
    ])) : i("", !0)
  ], 16, C);
}
p(E, "_sfc_render");
typeof w == "function" && w(y);
const O = /* @__PURE__ */ B(y, [["render", E]]);
export {
  O as default
};
