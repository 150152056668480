import { TranslationMixin as t } from "../../component.mixin/translation.mixin.js";
import { defineComponent as n } from "vue";
const r = n({
  name: "CalendarEventModal",
  mixins: [
    t({
      defaults: {
        starts: "Starts",
        ends: "Ends"
      }
    })
  ],
  props: {
    events: {
      type: Array,
      required: !0
    }
  },
  emits: ["open-event", "submit"],
  methods: {
    clickHandler(e) {
      this.$emit("open-event", e), this.$emit("submit", e);
    }
  }
});
export {
  r as _
};
