// EmailLog, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EmailLog } from "@kinherit/sdk/model/email-log";

export const ReadEmailLog = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/log/email-log",
  method: "get",
  rootModel: EmailLog,
  parse: () => ({}),
});
