<template>
  <div class="order-refund-request-master-list">
    <PageHeader htag="h1" text="Order Refund Requests">
      <template #buttons>
        <Button
          icon-left="Plus"
          aria-label="Create Refund Request"
          @click="createRefundRequest"
        />
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        createdAt: 'Created',
        amount: 'Amount',
        status: 'Status',
      }"
      @refresh="refresh"
    >
      <template #actions="{ row }: { row: OrderRefundRequest }">
        <div class="buttons is-right">
          <Button
            v-if="canApprove && row.status.value === 'pendingApproval'"
            text="Approve"
            @click="approveRefundRequest(row)"
          />
          <Button
            v-if="canApprove && row.status.value === 'approved'"
            text="Mark Complete"
            @click="completeRefundRequest(row)"
          />
          <Button
            icon-left="Edit"
            aria-label="Update Refund Request"
            @click="updateRefundRequest(row)"
          />
          <Button
            v-if="row.status.value !== 'completed'"
            icon-left="Trash"
            aria-label="Delete Refund Request"
            @click="deleteRefundRequest(row)"
          />
        </div>
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { ReadOrderRefundRequestForm } from "@/module/order/form/read-order-refund-request.form";
import { UpdateOrderRefundRequestForm } from "@/module/order/form/update-refund-request.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenDangerDialog } from "@kinherit/framework/global/dialog";
import { In, Like } from "@kinherit/orm/index";
import { IOrderRefundRequest, Option, OrderRefundRequest } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { OrderRefundRequestMasterListRoute } from ".";

export default defineComponent({
  name: OrderRefundRequestMasterListRoute,
  components: { MasterListPage, PageHeader, Button },
  mixins: [
    ActionBusMixin(() => window.Kernel.ActionBus2.portal.orders.refundRequest),
    AuthService.mixin(),
  ],
  data: () => ({
    filters: ReadOrderRefundRequestForm(),
    columns: [
      {
        title: "Client",
        map: (val) => val.order.kintin?.friendlyName,
      },
      {
        title: "Amount",
        map: (val) => val.amount.toFormattedString(),
      },
      {
        title: "Status",
        mapHtml: (val) =>
          `<span class="badge is-${
            {
              pendingApproval: "warning",
              approved: "info",
              completed: "success",
            }[val.status.value]
          }">${val.status.text}</span>`,
      },
      {
        title: "Created",
        map: (val) => val.createdAt.formatDate,
      },
      {
        title: "Requested By",
        map: (val) => val.requestedBy?.profile?.fullName ?? "-",
      },
      {
        title: "Completed",
        map: (val) => val.completedAt?.formatDate ?? "-",
      },
      {
        slot: "actions",
      },
    ] as TableColumn<OrderRefundRequest>[],
    rows: Array<OrderRefundRequest>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IOrderRefundRequest,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    canApprove(): boolean {
      return this.$auth.hasRole("admin");
    },
  },
  methods: {
    async refresh(): Promise<void> {
      const data = await this.$actionBus.ReadOrderRefundRequest({
        query: {
          order: {
            kintin: {
              friendlyName: Like(this.filters.localData.search),
            },
          },
          status: In(this.filters.localData.status),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.orderRefundRequest;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async createRefundRequest(): Promise<void> {
      const form = UpdateOrderRefundRequestForm();

      try {
        await form.dialog({
          dialog: {
            title: "Create Refund Request",
          },
        });
      } catch {
        form.localData.$delete();
        return;
      }

      await this.$actionBus.CreateOrderRefundRequest(form.localData);

      await this.refresh();
    },
    async updateRefundRequest(
      orderRefundRequest: OrderRefundRequest,
    ): Promise<void> {
      const form = UpdateOrderRefundRequestForm(orderRefundRequest);

      const isCompleted = orderRefundRequest.status.value === "completed";

      try {
        await form.dialog({
          dialog: {
            title: "Update Refund Request",
          },
          button: isCompleted
            ? {
                cancel: {
                  show: false,
                },
              }
            : undefined,
        });
      } catch {
        form.localData.$restore();
        return;
      }

      if (isCompleted) {
        return;
      }

      await this.$actionBus.UpdateOrderRefundRequest(form.localData);

      await this.refresh();
    },
    async deleteRefundRequest(
      orderRefundRequest: OrderRefundRequest,
    ): Promise<void> {
      const friendlyName = orderRefundRequest.order.kintin?.friendlyName;
      await OpenDangerDialog({
        dialog: {
          title: "Delete Refund Request",
          message: `Are you sure you want to delete the refund request for ${friendlyName}?`,
        },
      });

      await this.$actionBus.DeleteOrderRefundRequest(orderRefundRequest);

      await this.refresh();
    },
    async approveRefundRequest(
      orderRefundRequest: OrderRefundRequest,
    ): Promise<void> {
      await OpenDangerDialog({
        dialog: {
          title: "Approve Refund Request",
          message: `Are you sure you want to approve the refund request for ${orderRefundRequest.order.kintin?.friendlyName}?`,
        },
      });

      orderRefundRequest.status = Option.$findOneByOrThrow({
        group: "orderRefundRequestStatus",
        value: "approved",
      });

      await this.$actionBus.UpdateOrderRefundRequest(orderRefundRequest);

      await this.refresh();
    },
    async completeRefundRequest(
      orderRefundRequest: OrderRefundRequest,
    ): Promise<void> {
      await OpenDangerDialog({
        dialog: {
          title: "Complete Refund Request",
          message: `Are you sure you want to complete the refund request for ${orderRefundRequest.order.kintin?.friendlyName}?`,
        },
      });

      orderRefundRequest.status = Option.$findOneByOrThrow({
        group: "orderRefundRequestStatus",
        value: "completed",
      });
      orderRefundRequest.completedAt = new DateTime();

      await this.$actionBus.UpdateOrderRefundRequest(orderRefundRequest);

      await this.refresh();
    },
  },
});
</script>
