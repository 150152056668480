var S = Object.defineProperty;
var l = (e, n) => S(e, "name", { value: n, configurable: !0 });
import { _ as z } from "../../../component.display/calendar/calendar.vue_vue_type_script_lang.js";
import { resolveComponent as c, createElementBlock as i, openBlock as t, mergeProps as V, createElementVNode as d, createBlock as g, createCommentVNode as b, createVNode as $, normalizeClass as f, withCtx as v, withModifiers as p, createTextVNode as u, toDisplayString as a, Fragment as m, Transition as M, renderList as y, withKeys as k, withDirectives as T, normalizeStyle as F, vShow as K, renderSlot as P } from "vue";
import { _ as W } from "../../../_plugins/_plugin-vue_export-helper.js";
const Y = { class: "calendar-controls-holder" }, B = { class: "columns calendar-controls" }, E = { class: "column" }, A = {
  key: 0,
  class: "column"
}, N = {
  key: 0,
  class: "calendar__header"
}, j = ["tabindex", "onKeyup", "onFocus", "onClick"], R = ["tabindex", "onKeyup", "onFocus", "onClick"], H = ["week"], L = ["day"], U = ["tabindex", "role", "onFocus", "onClick", "onKeyup", "onMouseover"], O = { class: "calendar__date__number" }, q = { class: "calendar__date__label" }, G = { key: 1 }, J = ["onClick"], Q = { class: "event-label label" }, X = { class: "event-date" }, Z = { class: "event-start" }, I = { class: "event-end" }, _ = {
  key: 2,
  class: "calendar__sugestions buttons"
}, x = {
  key: 3,
  class: "calendar__sugestions buttons"
};
function ee(e, n, se, ne, oe, te) {
  const h = c("Button"), C = c("Pagination");
  return t(), i("div", V({
    class: ["calendar is-unselectable", {
      "only-show-events": e.eventsOnly,
      "is-agenda": e.computedIsAgenda,
      "has-day-selected": e.selectedDay !== null,
      "is-widget": e.isWidget
    }]
  }, { ...e.$attrs, ...e.$data.$cypress }), [
    d("div", Y, [
      d("div", B, [
        d("div", E, [
          $(C, {
            current: e.compPagination,
            "onUpdate:current": [
              n[3] || (n[3] = (o) => e.compPagination = o),
              n[4] || (n[4] = (o) => {
                e.refDate = [], e.focus = null;
              })
            ],
            "increment-by": e.view === "decade" ? 10 : 1,
            class: f(["month-pagination is-fullwidth", { "is-small": e.isWidget }]),
            total: 1 / 0,
            "hide-other-pages": "",
            "hide-next-button": e.hideNextButton,
            "hide-previous-button": e.hidePreviousButton
          }, {
            default: v(() => [
              e.enableViewSwitcher && e.view === "decade" ? (t(), g(h, {
                key: 0,
                class: "js-view-switcher",
                color: "is-plain",
                onClick: n[0] || (n[0] = p((o) => e.switchView("month", "fade-zoom-in"), ["stop"]))
              }, {
                default: v(() => [
                  u(a(e.compCurrentFirstYearDecade + "-" + (e.compCurrentFirstYearDecade + 9)), 1)
                ]),
                _: 1
              })) : e.enableViewSwitcher && e.view === "year" ? (t(), g(h, {
                key: 1,
                class: "js-view-switcher",
                color: "is-plain",
                onClick: n[1] || (n[1] = p((o) => e.switchView("decade", "fade-zoom-out"), ["stop"]))
              }, {
                default: v(() => [
                  u(a(e.compCurrentYear), 1)
                ]),
                _: 1
              })) : e.enableViewSwitcher && e.isWidget ? (t(), g(h, {
                key: 2,
                class: "js-view-switcher",
                color: "is-plain",
                onClick: n[2] || (n[2] = p((o) => e.switchView("year", "fade-zoom-out"), ["stop"]))
              }, {
                default: v(() => [
                  u(a(e.months[e.compCurrentMonth - 1]) + " " + a(e.compCurrentYear), 1)
                ]),
                _: 1
              })) : e.enableViewSwitcher ? (t(), i(m, { key: 4 }, [
                u(a(e.months[e.compCurrentMonth - 1]), 1)
              ], 64)) : (t(), i(m, { key: 3 }, [
                u(a(e.months[e.compCurrentMonth - 1]) + " " + a(e.compCurrentYear), 1)
              ], 64))
            ]),
            _: 1
          }, 8, ["current", "increment-by", "class", "hide-next-button", "hide-previous-button"])
        ]),
        e.isWidget ? b("", !0) : (t(), i("div", A, [
          $(C, {
            current: e.compCurrentYear,
            "onUpdate:current": [
              n[5] || (n[5] = (o) => e.compCurrentYear = o),
              n[6] || (n[6] = (o) => {
                e.refDate = [], e.focus = null;
              })
            ],
            class: f(["year-pagination is-fullwidth", { "is-small": e.isWidget }]),
            total: 1 / 0,
            "hide-other-pages": "",
            "enable-next": "",
            "enable-previous": ""
          }, {
            default: v(() => [
              u(a(e.compCurrentYear), 1)
            ]),
            _: 1
          }, 8, ["current", "class"])
        ]))
      ])
    ]),
    e.computedIsAgenda ? (t(), i("div", G, [
      (t(!0), i(m, null, y(e.events, (o) => (t(), i("div", {
        key: `calendar-event-${o.id}`,
        class: "calendar__event",
        onClick: /* @__PURE__ */ l((r) => e.$emit("open-event", o), "onClick")
      }, [
        d("div", Q, a(o.label), 1),
        d("div", X, [
          d("div", Z, [
            n[13] || (n[13] = d("span", { class: "has-text-weight-bold" }, "Starts: ", -1)),
            u(a(o.start.formatDateTime), 1)
          ]),
          d("div", I, [
            n[14] || (n[14] = d("span", { class: "has-text-weight-bold" }, "Ends: ", -1)),
            u(a(o.end.formatDateTime), 1)
          ])
        ])
      ], 8, J))), 128))
    ])) : (t(), g(M, {
      key: 0,
      name: e.transition,
      mode: "out-in"
    }, {
      default: v(() => [
        (t(), i("div", {
          key: e.view,
          class: "calendar__header--body"
        }, [
          e.view === "month" ? (t(), i("div", N, [
            (t(!0), i(m, null, y(e.daysHeaders, (o, r) => (t(), i("div", {
              key: `calendar-header-${r}`,
              class: "calendar__header__day"
            }, a(o), 1))), 128))
          ])) : b("", !0),
          d("div", {
            class: f(["calendar__body tab-index-parent", `is-${e.view}`])
          }, [
            e.view === "decade" ? (t(!0), i(m, { key: 0 }, y(e.compYears, (o, r) => (t(), i("span", {
              key: `calendar-year-${r}`,
              ref_for: !0,
              ref: /* @__PURE__ */ l((s) => e.setRef(s, r), "ref"),
              class: f(["calendar__year", {
                "is-selected": e.compSelectedYear === o,
                "is-current": e.compTodaysDate.year === o
              }]),
              tabindex: e.setTabIndex(r),
              onKeydown: n[7] || (n[7] = (s) => e.keyDown(s)),
              onKeyup: [
                k(p((s) => {
                  e.switchView("year", "fade-zoom-in"), e.dYear = o;
                }, ["prevent"]), ["space"]),
                k(p((s) => {
                  e.switchView("year", "fade-zoom-in"), e.dYear = o;
                }, ["prevent"]), ["enter"])
              ],
              onFocus: /* @__PURE__ */ l((s) => e.focus = r, "onFocus"),
              onClick: /* @__PURE__ */ l((s) => {
                e.switchView("year", "fade-zoom-in"), e.dYear = o;
              }, "onClick")
            }, a(o), 43, j))), 128)) : e.view === "year" ? (t(!0), i(m, { key: 1 }, y(e.monthShorts, (o, r) => (t(), i("span", {
              key: `calendar-month-${r}`,
              ref_for: !0,
              ref: /* @__PURE__ */ l((s) => e.setRef(s, r), "ref"),
              class: f(["calendar__month", {
                "is-selected": e.compCurrentMonth === r + 1 && e.compCurrentYear === e.compSelectedYear,
                "is-current": e.compTodaysDate.year === e.compPagination && e.compTodaysDate.month === r + 1
              }]),
              tabindex: e.setTabIndex(r),
              onKeydown: n[8] || (n[8] = (s) => e.keyDown(s)),
              onKeyup: [
                k(p((s) => {
                  e.switchView("month", "fade-zoom-in"), e.compCurrentMonth = r + 1;
                }, ["prevent"]), ["space"]),
                k(p((s) => {
                  e.switchView("month", "fade-zoom-in"), e.compCurrentMonth = r + 1;
                }, ["prevent"]), ["enter"])
              ],
              onFocus: /* @__PURE__ */ l((s) => e.focus = r, "onFocus"),
              onClick: /* @__PURE__ */ l((s) => {
                e.switchView("month", "fade-zoom-in"), e.compCurrentMonth = r + 1;
              }, "onClick")
            }, a(o), 43, R))), 128)) : (t(!0), i(m, { key: 2 }, y(e.calendarDays, (o, r) => (t(), i("div", {
              key: `calendar-week-${r}`,
              class: f("calendar__week"),
              week: r
            }, [
              (t(!0), i(m, null, y(o, (s, D) => (t(), i("div", {
                key: `calendar-day-${D}`,
                class: f(["calendar__day", {
                  "has-events": !s.events.isEmpty(),
                  "calendar-buffer": s.isAnotherMonth,
                  "is-today": s.isToday,
                  "is-selected": e.isSelected(s),
                  [e.selectColor]: e.isSelected(s),
                  "is-first": e.isFirstSelected(s),
                  "is-last": e.isLastSelected(s),
                  "out-of-bounds": !e.isWithinBounds(s),
                  "is-clickable": s.isAnotherMonth || s.events.isEmpty() ? null : e.isDatePicker
                }]),
                day: s.day
              }, [
                T(d("div", {
                  ref_for: !0,
                  ref: /* @__PURE__ */ l((w) => e.setRef(w, s.date - 1), "ref"),
                  tabindex: e.setTabIndex(s.date - 1),
                  class: "calendar__date",
                  role: e.isDatePicker ? "button" : void 0,
                  onKeydown: n[9] || (n[9] = (w) => e.keyDown(w)),
                  onFocus: /* @__PURE__ */ l((w) => e.focus = s.date - 1, "onFocus"),
                  onClick: /* @__PURE__ */ l((w) => e.dateSelected(s), "onClick"),
                  onKeyup: [
                    k(p((w) => e.dateSelected(s), ["prevent"]), ["enter"]),
                    k(p((w) => e.dateSelected(s), ["prevent"]), ["space"])
                  ],
                  onMouseover: /* @__PURE__ */ l(() => e.dayHover(s), "onMouseover")
                }, [
                  d("span", O, a(s.date), 1),
                  d("span", q, a(s.label), 1),
                  e.isWidget ? b("", !0) : (t(), i("div", {
                    key: 0,
                    style: F(`${s.events.length > 0 ? "" : "visibility:hidden;"}`),
                    class: "calendar__date__event-count"
                  }, a(s.events.length) + " Event" + a(s.events.length > 1 ? "s" : ""), 5))
                ], 40, U), [
                  [K, !s.isAnotherMonth]
                ])
              ], 10, L))), 128))
            ], 8, H))), 128))
          ], 2)
        ]))
      ]),
      _: 1
    }, 8, ["name"])),
    e.$slots.suggestions ? (t(), i("div", _, [
      P(e.$slots, "suggestions")
    ])) : b("", !0),
    e.showSuggestions && e.isDatePicker ? (t(), i("div", x, [
      $(h, {
        "is-outlined": "",
        size: e.isWidget ? "is-small" : null,
        onClick: n[10] || (n[10] = (o) => e.setDate(0))
      }, {
        default: v(() => n[15] || (n[15] = [
          u(" Today ")
        ])),
        _: 1
      }, 8, ["size"]),
      $(h, {
        "is-outlined": "",
        size: e.isWidget ? "is-small" : null,
        onClick: n[11] || (n[11] = (o) => e.setDate(1))
      }, {
        default: v(() => n[16] || (n[16] = [
          u(" +1 ")
        ])),
        _: 1
      }, 8, ["size"]),
      $(h, {
        "is-outlined": "",
        size: e.isWidget ? "is-small" : null,
        onClick: n[12] || (n[12] = (o) => e.setDate(2))
      }, {
        default: v(() => n[17] || (n[17] = [
          u(" +2 ")
        ])),
        _: 1
      }, 8, ["size"])
    ])) : b("", !0)
  ], 16);
}
l(ee, "_sfc_render");
const de = /* @__PURE__ */ W(z, [["render", ee]]);
export {
  de as default
};
