var R = Object.defineProperty;
var c = (e, t) => R(e, "name", { value: t, configurable: !0 });
import { KernelModes as w } from "../kernel-mode.js";
import { ResizeObserverManager as y } from "../../global/resize-observer.js";
const S = [], d = {}, p = /,?[\s\t]*([^,\n]*?)((?:\[[\s\t]*?(?:min|max)-(?:width|height)[\s\t]*?[~$^]?=[\s\t]*?"[^"]*?"[\s\t]*?])+)([^,\n\s{]*)/gim, O = /\[[\s\t]*?(min|max)-(width|height)[\s\t]*?[~$^]?=[\s\t]*?"([^"]*?)"[\s\t]*?]/gim;
function z() {
  const e = [];
  for (let t = 0; t < document.styleSheets.length; t++) {
    const n = document.styleSheets[t];
    n instanceof CSSStyleSheet && e.push(...n.cssRules);
  }
  return e;
}
c(z, "getRulesFromStyleSheets");
function x(e) {
  return S.includes(e) || S.push(e), S.indexOf(e);
}
c(x, "getElementIndex");
function $(e, t, n, s) {
  const i = x(e);
  d[i] = d[i] || {};
  const r = d[i] || {};
  r[n] = r[n] || {};
  const o = r[n];
  o[t] = o[t] || [];
  const a = o[t], h = parseInt(s);
  a.includes(h) || (a.push(h), a.sort((u, l) => u - l));
}
c($, "queueQuery");
function v(e, t) {
  let n, s, i;
  for (e = e.replace(/'/g, '"'); (n = p.exec(e)) !== null; )
    for (s = n[2]; (i = O.exec(s)) !== null; )
      $(
        t,
        i[1],
        i[2],
        i[3]
      );
}
c(v, "extractQueries");
function g(e, t) {
  let n = "";
  if (t)
    if (typeof t == "string")
      t = t.toLowerCase(), (t.indexOf("min-width") !== -1 || t.indexOf("max-width") !== -1) && v(t, e);
    else
      for (let s = 0, i = t.length; s < i; s++)
        if (t[s].constructor.name === "CSSStyleRule") {
          if (n = t[s].selectorText, !["min-height", "max-height", "min-width", "max-width"].some(
            (o) => n.includes(o)
          ))
            continue;
          const r = n.replace(
            /\[[\s\t]*?(min|max)-(width|height)[\s\t]*?[~$^]?=[\s\t]*?"[^"]*?"[\s\t]*?][\s\t]*?/gim,
            ""
          );
          if (!e.matches(r) && !e.querySelector(r))
            continue;
          v(n, e);
        } else t[s].constructor.name === "CSSMediaRule" ? g(e, t[s].cssRules || t[s].rules) : t[s].constructor.name === "CSSImportRule" && Object.prototype.hasOwnProperty.call(
          t[s].styleSheet,
          "cssRules"
        ) && g(e, t[s].styleSheet.cssRules);
}
c(g, "readRules");
const q = /* @__PURE__ */ c((e, t) => {
  y.getInstance(
    e,
    (s) => {
      s.forEach((i) => {
        const r = i.borderBoxSize[0].inlineSize, o = i.borderBoxSize[0].blockSize;
        e.dataset.width = r.toString(), e.dataset.height = o.toString();
        const a = x(e), h = d[a] ?? {};
        Object.keys(h).forEach((u) => {
          const l = h[u];
          Object.keys(l).forEach((f) => {
            const b = l[f].filter((m) => f === "max" ? m >= r : m <= r).map((m) => `${m}px`).join(" ");
            b.length > 0 ? e.setAttribute(`${f}-${u}`, b) : e.removeAttribute(`${f}-${u}`);
          });
        });
      });
    }
  ).observe(e), t && new ResizeObserver((i) => {
    const r = i.find((o) => o.target === e);
    r && t(r);
  }).observe(e);
}, "AddResizeObserver"), I = {
  beforeUnmount(e) {
    if (window.Kernel.Mode === w.Test)
      return;
    y.getInstance(e).unobserve(e);
  },
  mounted(e, t) {
    if (window.Kernel.Mode === w.Test)
      return;
    const n = z();
    g(e, n), q(e, t.value);
  }
};
export {
  I as ResizeObserverDirective
};
