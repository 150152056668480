// AccountReferralCode

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";

export const UpdateAccountReferralCode = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/admin/account-referral-code/{accountReferralCode}",
  method: "patch",
  parse: (target: AccountReferralCode) => ({
    body: {
      code: target.$data.code,
      name: target.$data.name,
      isForUsers: target.$data.isForUsers,
      isFeeSacrifice: target.$data.isFeeSacrifice,
      isSalarySacrifice: target.$data.isSalarySacrifice,
      isFriendsAndFamily: target.$data.isFriendsAndFamily,
      notes: target.$data.notes,
      referralCount: target.$data.referralCount,
      company: target.$data.company,
      category: target.$data.category,
    },
    params: {
      accountReferralCode: target,
    },
  }),
});
