// Person, Profile, Address, EmailAddress, PhoneNumber, Website

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Person } from "@kinherit/sdk/model/person";

export const CreatePerson = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/person",
  method: "post",
  parse: (target: Person) => ({
    body: {
      dateOfBirth: target.$data.dateOfBirth,
      dateOfDeath: target.$data.dateOfDeath,
      hasFormerAddresses: target.$data.hasFormerAddresses,
      requiresCare: target.$data.requiresCare,
      formerNames: target.$data.formerNames,
      nationalInsuranceNumber: target.$data.nationalInsuranceNumber,
      socialSecurityNumber: target.$data.socialSecurityNumber,
      notifications: target.$data.notifications,
      type: target.$data.type,
      draftPerson: target.$data.draftPerson,
      draftProfile: target.$data.draftProfile,
      draftAddresses: target.$data.draftAddresses,
      draftEmailAddresses: target.$data.draftEmailAddresses,
      draftPhoneNumbers: target.$data.draftPhoneNumbers,
      isTrusted: target.$data.isTrusted,
      notificationDate: target.$data.notificationDate,
      under18ForPlanningPurposes: target.$data.under18ForPlanningPurposes,
      notifyOnDeath: target.$data.notifyOnDeath,
      kintin: target.$data.kintin,
      profile: {
        title: target.profile.title?.id,
        firstName: target.profile.firstName,
        middleNames: target.profile.middleNames,
        lastName: target.profile.lastName,
        knownAs: target.profile.knownAs,
        newsletterCampaigns: target.profile.newsletterCampaigns,
        suffix: target.profile.suffix,
        organisationName: target.profile.organisationName,
        organisationNumber: target.profile.organisationNumber,
        jobTitle: target.profile.jobTitle,
        addresses: target.profile.addresses.map((address) => ({
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          country: address.country,
          state: address.state,
          postcode: address.postcode,
          primaryMailing: address.primaryMailing,
          primaryResidential: address.primaryResidential,
        })),
        emails: target.profile.emails.map((email) => ({
          email: email.email,
          primary: email.primary,
        })),
        phoneNumbers: target.profile.phoneNumbers.map((phoneNumber) => ({
          tel: phoneNumber.tel,
          primary: phoneNumber.primary,
        })),
        websites: target.profile.websites.map((website) => ({
          url: website.url,
        })),
      },
      gender: target.$data.gender,
      maritalStatus: target.$data.maritalStatus,
      officerCallStatus: target.$data.officerCallStatus,
      credential: target.$data.credential,
      relationToPrimaryPerson: target.$data.relationToPrimaryPerson,
      relationToSecondaryPerson: target.$data.relationToSecondaryPerson,
    },
    params: {
      kintin: target.$data.kintin!,
    },
  }),
  after: ({ message }) => {
    message.$delete({
      profile: {
        addresses: true,
        emails: true,
        phoneNumbers: true,
        websites: true,
      },
    });
  },
});
