<template>
  <div class="page-header block">
    <Level>
      <template #left>
        <Avatar
          v-if="avatarName"
          hide-tooltip
          size="is-large"
          :name="avatarName"
        />
        <Title v-if="name || title" is-spaced>
          {{ name ?? title }}
        </Title>
        <Title v-if="(name || title) && subtitle" is-subtitle>
          {{ subtitle }}
        </Title>
      </template>
      <template #right>
        <Button
          v-if="hasContextMenu"
          icon-left="Horizontalellipsis"
          color="is-plain"
          class="js-page-header-context-menu"
          aria-label="Menu"
          @click="(event) => $emit('context-menu', event)"
        />
      </template>
    </Level>
  </div>
</template>

<script lang="ts">
import { StyleService } from "@/service/style.service";
import { Avatar } from "@kinherit/framework/component.display/avatar";
import { Title } from "@kinherit/framework/component.display/title";
import { Button } from "@kinherit/framework/component.input/button";
import { Level } from "@kinherit/framework/component.layout/level";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "PageHeader",
  components: {
    Avatar,
    Title,
    Level,
    Button,
  },
  mixins: [StyleService.mixin],
  props: {
    hasContextMenu: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String as PropType<string | null>,
      default: null,
    },
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    avatarName: {
      type: String as PropType<string | null>,
      default: null,
    },
    name: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  emits: ["context-menu"],
});
</script>
