var t = Object.defineProperty;
var c = (e, o, i) => o in e ? t(e, o, { enumerable: !0, configurable: !0, writable: !0, value: i }) : e[o] = i;
var r = (e, o) => t(e, "name", { value: o, configurable: !0 });
var l = (e, o, i) => c(e, typeof o != "symbol" ? o + "" : o, i);
import { GoogleFonts as d } from "../service/google-fonts.js";
import { ThemeCssBridge as f } from "./bridge.js";
import { stringToColor as y, findColorLight as g, findColorInvert as u, findColorDark as h } from "@kinherit/ts-common/service/colors";
const n = class n {
  constructor() {
    /** Color */
    l(this, "namedColors", {
      "is-primary": "var(--color-primary)",
      "is-positive": "var(--color-positive)",
      "is-info": "var(--color-info)",
      "is-success": "var(--color-success)",
      "is-warning": "var(--color-warning)",
      "is-danger": "var(--color-danger)",
      "is-highlight": "var(--color-highlight)",
      "is-light-grey": "var(--color-light-grey)",
      "is-grey": "var(--color-grey)",
      "is-plain": "",
      "is-dark-grey": "var(--color-dark-grey)",
      "is-red": "var(--color-red)",
      "is-green": "var(--color-green)",
      "is-blue": "var(--color-blue)",
      "is-pink": "var(--color-pink)",
      "is-purple": "var(--color-purple)",
      "is-brown": "var(--color-brown)",
      "is-teal": "var(--color-teal)",
      "is-link": "var(--color-link)",
      "is-white": "var(--color-white)",
      "is-kinherit-navy": "var(--color-kinherit-navy)",
      "is-kinherit-aqua": "var(--color-kinherit-aqua)",
      "is-kinherit-ochre": "var(--color-kinherit-ochre)",
      "is-kinherit-air-force": "var(--color-kinherit-air-force)",
      "is-kinherit-pebble": "var(--color-kinherit-pebble)",
      "is-kinherit-lime": "var(--color-kinherit-lime)",
      "is-kinherit-petrol": "var(--color-kinherit-petrol)",
      "is-kinherit-sienna": "var(--color-kinherit-sienna)",
      "is-single": "var(--color-single)",
      "is-joint": "var(--color-joint)"
    });
    /**
     * @internal
     */
    l(this, "icon", {
      PersonFriend: {
        icon: "fa-user-friends",
        style: "Regular"
      },
      Person: {
        icon: "fa-user",
        style: "Regular"
      },
      Plus: {
        icon: "fa-plus",
        style: "Regular"
      },
      Minus: {
        icon: "fa-minus",
        style: "Regular"
      },
      Success: {
        icon: "fa-check",
        color: this.namedColors["is-success"],
        style: "Solid"
      },
      Tick: {
        icon: "fa-check",
        style: "Solid"
      },
      Warning: {
        icon: "fa-exclamation",
        style: "Solid"
      },
      WarningTriangle: {
        icon: "fa-exclamation-triangle",
        primaryColor: "white",
        secondaryColor: this.namedColors["is-warning"],
        primaryOpacity: 1,
        secondaryOpacity: 1,
        style: "Duotone"
      },
      Danger: {
        icon: "fa-circle-exclamation",
        primaryColor: "white",
        secondaryColor: this.namedColors["is-danger"],
        primaryOpacity: 1,
        secondaryOpacity: 1,
        style: "Duotone"
      },
      Info: {
        icon: "fa-circle-info",
        primaryColor: "white",
        secondaryColor: this.namedColors["is-info"],
        primaryOpacity: 1,
        secondaryOpacity: 1,
        style: "Duotone"
      },
      Certificate: {
        icon: "fa-file-certificate",
        style: "Regular"
      },
      ChevronRight: {
        icon: "fa-chevron-right",
        style: "Regular"
      },
      ChevronLeft: {
        icon: "fa-chevron-left",
        style: "Regular"
      },
      ChevronUp: {
        icon: "fa-chevron-up",
        style: "Regular"
      },
      ChevronDown: {
        icon: "fa-chevron-down",
        style: "Regular"
      },
      Email: {
        icon: "fa-envelope",
        style: "Regular"
      },
      EmailTick: {
        icon: "fa-envelope-circle-check",
        style: "Regular"
      },
      Ban: {
        icon: "fa-ban",
        style: "Regular"
      },
      Upload: {
        icon: "fa-cloud-arrow-up",
        style: "Solid"
      },
      Cog: {
        icon: "fa-cog",
        style: "Regular"
      },
      Edit: {
        icon: "fa-edit",
        style: "Regular"
      },
      Horizontalellipsis: {
        icon: "fa-ellipsis-h",
        style: "Solid"
      },
      ListOrdered: {
        icon: "fa-list-ol",
        style: "Solid"
      },
      ListUnordered: {
        icon: "fa-list-ul",
        style: "Solid"
      },
      Table: {
        icon: "fa-table",
        style: "Solid"
      },
      TextBold: {
        icon: "fa-bold",
        style: "Solid"
      },
      TextItalic: {
        icon: "fa-italic",
        style: "Solid"
      },
      TextLink: {
        icon: "fa-link",
        style: "Solid"
      },
      Trash: {
        icon: "fa-trash",
        style: "Regular"
      },
      Phone: {
        icon: "fa-phone",
        style: "Solid"
      },
      Exchange: {
        icon: "fa-cash-register",
        style: "Duotone"
      },
      Filter: {
        icon: "fa-filter-list",
        style: "Solid"
      },
      Reset: {
        icon: "fa-rotate-left",
        style: "Solid"
      },
      Cross: {
        icon: "fa-times",
        color: this.namedColors["is-danger"],
        style: "Solid"
      },
      Empty: {
        icon: "fa-horizontal-rule",
        color: this.namedColors["is-info"],
        style: "Solid"
      },
      Error: {
        icon: "fa-times-circle",
        color: this.namedColors["is-danger"],
        style: "Solid"
      },
      Delete: {
        icon: "fa-times-circle",
        color: this.namedColors["is-danger"],
        style: "Solid"
      },
      Remove: {
        icon: "fa-times-circle",
        color: "var(--color-icon)",
        style: "Solid"
      },
      Search: {
        icon: "fa-search",
        color: "var(--color-icon)",
        style: "Solid"
      },
      Clear: {
        icon: "fa-times-circle",
        style: "Solid"
      },
      Clock: {
        icon: "fa-clock",
        style: "Regular"
      },
      Facebook: {
        icon: "fa-facebook",
        color: this.namedColors["is-link"],
        style: "Brands"
      },
      Twitter: {
        icon: "fa-twitter",
        color: this.namedColors["is-link"],
        style: "Brands"
      },
      Linkedin: {
        icon: "fa-linkedin",
        color: this.namedColors["is-link"],
        style: "Brands"
      },
      PDF: {
        icon: "fa-file-pdf",
        style: "Regular"
      },
      Word: {
        icon: "fa-file-word",
        style: "Regular"
      },
      AddressBook: {
        icon: "fa-address-book",
        style: "Regular"
      },
      SignFile: {
        icon: "fa-file-signature",
        style: "Regular"
      },
      CreditCard: {
        icon: "fa-credit-card",
        style: "Regular"
      },
      PersonTie: {
        icon: "fa-user-tie",
        style: "Regular"
      },
      People: {
        icon: "fa-users",
        style: "Regular"
      },
      Lock: {
        icon: "fa-lock",
        style: "Solid"
      },
      Unlock: {
        icon: "fa-unlock",
        style: "Solid"
      },
      Tag: {
        icon: "fa-tag",
        style: "Regular"
      },
      Copy: {
        icon: "fa-copy",
        style: "Regular"
      },
      Close: {
        icon: "fa-times",
        style: "Solid"
      },
      File: {
        icon: "fa-file-lines",
        style: "Regular"
      },
      Files: {
        icon: "fa-files",
        style: "Regular"
      },
      Help: {
        icon: "fa-question",
        style: "Regular"
      },
      Note: {
        icon: "fa-memo",
        style: "Regular"
      },
      Logout: {
        icon: "fa-arrow-right-from-bracket",
        style: "Solid"
      },
      KinvaultDashboard: {
        icon: "fa-gauge",
        style: "Solid"
      },
      KinvaultLegal: {
        icon: "fa-file-certificate",
        style: "Regular"
      },
      KinvaultAssets: {
        icon: "fa-clipboard-list",
        style: "Regular"
      },
      KinvaultContacts: {
        icon: "fa-address-book",
        style: "Regular"
      },
      KinvaultAdmin: {
        icon: "fa-house",
        style: "Solid"
      },
      KinvaultCare: {
        icon: "fa-hands-holding-heart",
        style: "Regular"
      },
      KinvaultDeath: {
        icon: "fa-candle-holder",
        style: "Regular"
      },
      KinvaultMisc: {
        icon: "fa-folder-grid",
        style: "Regular"
      },
      KinvaultSettings: {
        icon: "fa-cog",
        style: "Regular"
      },
      KinvaultHome: {
        icon: "fa-home",
        style: "Regular"
      },
      SignPeople: {
        icon: "fa-address-book",
        style: "Duotone"
      },
      SignDisclaimer: {
        icon: "fa-file-signature",
        style: "Duotone"
      },
      SignDirectDebit: {
        icon: "fa-credit-card",
        style: "Duotone"
      },
      SignIdentity: {
        icon: "fa-user",
        style: "Duotone"
      },
      YouTube: {
        icon: "fa-youtube",
        style: "Brands"
      }
    });
    /**
     * @internal
     */
    l(this, "GoogleFonts");
    l(this, "Colors", {
      findColorDark: h,
      findColorInvert: u,
      findColorLight: g,
      stringToColor: y
    });
    l(this, "editDialog", /* @__PURE__ */ r(async () => {
      const { EditThemeForm: o } = await import("./bridge/edit-theme-form.js");
      (await o()).dialog({
        dialog: {
          title: "Edit Theme"
        }
      });
    }, "editDialog"));
    l(this, "setCssVars", /* @__PURE__ */ r((o) => {
      o.forEach(({ key: i, value: a }) => {
        f.applyCssVar(i, a);
      });
    }, "setCssVars"));
    this.GoogleFonts = new d();
  }
  /**
   * @internal
   */
  get Icons() {
    return this.icon;
  }
  addIcon(o, i, a = !1) {
    if (!a && o in this.icon)
      throw new Error(`Icon ${o} has already been defined`);
    this.icon[o] = i;
  }
  setFavicon(o) {
    if (!o)
      return;
    let i = document.querySelector("link[rel~='icon']");
    i || (i = document.createElement("link"), i.rel = "icon", document.head.appendChild(i)), i.href = o;
  }
};
r(n, "Theme");
let s = n;
export {
  s as Theme
};
