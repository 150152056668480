// BrandedKinvault, Profile, ThemeKinvault, ThemeKinvaultFooter, ThemeKinvaultHeader, ThemeKinvaultLogin

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvault } from "@kinherit/sdk/model/branded-kinvault";

export const CreateBrandedKinvault = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/admin/branded-kinvaults",
  method: "post",
  parse: (target: BrandedKinvault) => ({
    body: {
      frontendUrl: target.$data.frontendUrl,
      notes: target.$data.notes,
      companyUrl: target.$data.companyUrl,
      systemEmail: target.$data.systemEmail,
      emailLogo: target.$data.emailLogo,
      restrictUserEmailDomain: target.$data.restrictUserEmailDomain,
      redirectUrl: target.$data.redirectUrl,
      useBrandedKinvaultInvitations: target.$data.useBrandedKinvaultInvitations,
      useUserInvitations: target.$data.useUserInvitations,
      useSetupWizard: target.$data.useSetupWizard,
      allowPublicRegistration: target.$data.allowPublicRegistration,
      allowDefaultAssetAccess: target.$data.allowDefaultAssetAccess,
      profile: {
        organisationName: target.profile.$data.organisationName,
        organisationNumber: target.profile.$data.organisationNumber,
      },
      kinvaultTheme: {
        brand: target.kinvaultTheme.$data.brand,
        favIcon: target.kinvaultTheme.$data.favIcon,
        footer: {
          class: target.kinvaultTheme.footer.$data.class,
          leftLogoClass: target.kinvaultTheme.footer.$data.leftLogoClass,
          textLine1: target.kinvaultTheme.footer.$data.textLine1,
          textLine2: target.kinvaultTheme.footer.$data.textLine2,
          leftLogo: target.kinvaultTheme.footer.$data.leftLogo,
          twitter: target.kinvaultTheme.footer.$data.twitter,
          facebook: target.kinvaultTheme.footer.$data.facebook,
          linkedIn: target.kinvaultTheme.footer.$data.linkedIn,
          youtube: target.kinvaultTheme.footer.$data.youtube,
          rightLogo: target.kinvaultTheme.footer.$data.rightLogo,
          rightLogoClass: target.kinvaultTheme.footer.$data.rightLogoClass,
          footerStrap: target.kinvaultTheme.footer.$data.footerStrap,
          hasBorderTop: target.kinvaultTheme.footer.$data.hasBorderTop,
        },
        header: {
          type: target.kinvaultTheme.header.$data.type,
          defaultLogo: target.kinvaultTheme.header.$data.defaultLogo,
          defaultLogoClass: target.kinvaultTheme.header.$data.defaultLogoClass,
          loginLogo: target.kinvaultTheme.header.$data.loginLogo,
          loginLogoClass: target.kinvaultTheme.header.$data.loginLogoClass,
          strap: target.kinvaultTheme.header.$data.strap,
        },
        login: {
          screenImg: target.kinvaultTheme.login.$data.screenImg,
          customScreenImage: target.kinvaultTheme.login.$data.customScreenImage,
        },
      },
      type: target.$data.type,
      clientPoolType: target.$data.clientPoolType,
      xeroContact: target.$data.xeroContact,
    },
  }),
  after: ({ message }) => {
    message.$delete({
      profile: true,
      kinvaultTheme: {
        header: true,
        footer: true,
        login: true,
      },
    });
  },
});
