<template>
  <Tabs v-model:tab="computedTab" :config="tabs" :is-fullwidth="false" />
</template>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { TabOptions, Tabs } from "@kinherit/framework/component.display/tabs";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WillBuilderPersonTabs",
  components: {
    Tabs,
  },
  mixins: [KinvaultKintinDetailsMixin, WillBuilderService.mixin],
  props: {
    tab: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:tab"],
  computed: {
    computedTab: {
      get(): number {
        if (this.options.editing) {
          return 0;
        }

        return this.tab;
      },
      set(value: number) {
        if (this.options.editing) {
          return;
        }

        this.$emit("update:tab", value);
      },
    },
    tabs(): TabOptions[] {
      const primaryTab = {
        label: this.kintin?.primaryPerson?.profile.fullName
          ? `${this.kintin?.primaryPerson?.profile.fullName} (Primary)`
          : "Primary",
      };

      const secondaryTab = {
        label: this.kintin?.secondaryPerson?.profile.fullName
          ? `${this.kintin?.secondaryPerson?.profile.fullName} (Secondary)`
          : "Secondary",
      };

      if (this.options.editing) {
        if (this.tab === 0) {
          return [primaryTab];
        }

        return [secondaryTab];
      }

      if (this.kintin?.type.value === "joint") {
        return [primaryTab, secondaryTab];
      }

      return [primaryTab];
    },
  },
});
</script>
