import { UserField } from "@/config/form.config";
import { FormTable } from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { FormElement } from "@kinherit/framework/component.form/element";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In } from "@kinherit/orm/index";
import { IntroducerCompany, User } from "@kinherit/sdk";

export const ReassignIntroducerCompaniesForm = (
  introducerCompanies: IntroducerCompany[],
  filterCount: number,
) =>
  defineForm({
    name: "reassign-introducer-companies",
    data: () => ({
      introducerCompanies,
      assignedTo: null as null | User,
      defaultLegalAssistant: null as null | User,
      seniorAdviser: null as null | User,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "reassign-introducer-companies",
        data,
        template: GridLayout([
          ["seniorAdviser", "assignedTo", "defaultLegalAssistant"],
          ["introducerCompanies"],
        ]),
        components: () => ({
          seniorAdviser: [
            UserField({
              props: {
                label: "New Sales Team",
                vModel: "seniorAdviser",
                isMultiSelect: false,
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
              simplifyData: true,
            }),
          ],
          assignedTo: [
            UserField({
              props: {
                label: "New Estate Planner",
                vModel: "assignedTo",
                isMultiSelect: false,
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
              simplifyData: true,
            }),
          ],
          defaultLegalAssistant: [
            UserField({
              props: {
                label: "New Legal Assistant",
                vModel: "defaultLegalAssistant",
                isMultiSelect: false,
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
              simplifyData: true,
            }),
          ],
          introducerCompanies: [
            FormTable({
              props: {
                vIf: (data) => data.introducerCompanies.length > 0,
                columns: [
                  {
                    title: "Name",
                    map: (introducerCompany) =>
                      introducerCompany.profile.fullName,
                  },
                  {
                    title: "Sales Team",
                    map: (introducerCompany) =>
                      introducerCompany.seniorAdviser?.profile.fullName,
                  },
                  {
                    title: "Estate Planner",
                    map: (introducerCompany) =>
                      introducerCompany.defaultEstatePlanner?.profile.fullName,
                  },
                  {
                    title: "Legal Assistant",
                    map: (introducerCompany) =>
                      introducerCompany.defaultLegalAssistant?.profile.fullName,
                  },
                ] as TableColumn<IntroducerCompany>[],
                rows: (data) => data.introducerCompanies,
                reference: "introducer-companies",
              },
            }),
            FormElement({
              props: {
                vIf: (data) => data.introducerCompanies.length === 0,
                html: `<h3 style='text-align:center' class='mt-5'>Changes will be applied to the <span class="has-text-danger">${filterCount?.format?.()}</span>  ${
                  filterCount > 1 ? "Companies" : "Company"
                } that match the search criteria</h3>`,
              },
            }),
          ],
        }),
      }),
    ],
  });
