// IntroducerCompany, Profile, User, AccountReferral, CompanyPartnerPortalPermissions, IntroducerNetwork

import { DefineCustomAction } from "@kinherit/framework/action-bus/actions/custom";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";
import { User } from "@kinherit/sdk/model/user";

export const ReassignIntroducerCompanies = DefineCustomAction({
  interface: "portal",
  resource: "/v2/portal/introducer/company/reassign",
  method: "put",
  rootModel: IntroducerCompany,
  parse: ({
    assignedTo,
    defaultLegalAssistant,
    seniorAdviser,
  }: {
    assignedTo: string | User | null;
    defaultLegalAssistant: string | User | null;
    seniorAdviser: string | User | null;
  }) => ({
    body: {
      assignedTo: assignedTo?.toString() ?? null,
      defaultLegalAssistant: defaultLegalAssistant?.toString() ?? null,
      seniorAdviser: seniorAdviser?.toString() ?? null,
    },
  }),
});
