<template>
  <p>
    {{ selected.pluck("id") }}
  </p>
  <Table
    v-model:value="selected"
    :rows="rows"
    :columns="columns"
    tracking-field="id"
    checkbox-field
  >
    <template #actions="{ row }">
      <Button @click="download(row)"> Download </Button>
    </template>
  </Table>
</template>

<script lang="ts">
import Table from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { Api, FileLog, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { SettingsIndexRoute } from ".";
export default defineComponent({
  name: SettingsIndexRoute,
  components: { Table, Button },
  data: () => ({
    acuityMinDate: "",
    acuityMaxDate: "",
    rows: [] as FileLog[],
    selected: [] as FileLog[],
    columns: [
      {
        title: "File Name",
        field: "fileName",
      },
      {
        title: "Created At",
        map: (row) => row.createdAt?.formatDateTime,
      },
      {
        title: "Updated At",
        map: (row) => row.updatedAt?.formatDateTime,
      },
      {
        title: "Path",
        // @ts-ignore
        map: (row) => row.$data.s3Location,
      },
      {
        title: "Client",
        map: (row) => row.kintin?.friendlyName,
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn<FileLog>>,
  }),
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      // @ts-ignore
      const response = await Api.resource("", "/v2/list-files")
        .method("GET")
        .result();

      // @ts-ignore
      Kintin.$inflate(response.kintin);

      // @ts-ignore
      this.rows = FileLog.$inflate(response.fileLog).sortBy(
        (row: any) => row.createdAt,
        "desc",
      );

      this.selected = this.$data.rows.filter(
        (row) =>
          row.updatedAt &&
          row.createdAt.clone().add({ second: 3 }).isAfter(row.updatedAt),
      );
    },
    async download(row: FileLog) {
      // @ts-ignore
      const { url } = await Api.resource("", "/v2/file/{fileLog}/download", {
        fileLog: row.id,
      })
        .method("GET")
        .result();

      OpenAlertDialog({
        dialog: {
          title: "Download Ready",
          html: `
          Click <a href="${url}" target="_blank">here</a> to download the file.
        `,
        },
        button: {
          ok: {
            text: "Close",
          },
          cancel: {
            show: false,
          },
        },
      });
    },
  },
});
</script>
