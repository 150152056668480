var o = Object.defineProperty;
var t = (e, i) => o(e, "name", { value: i, configurable: !0 });
import r from "../../_vue/component.display/icon/icon.js";
import a from "../../_vue/component.form/field/field.js";
import { FieldMixin as d } from "../../component.mixin/field-mixin.js";
import { CypressHelper as l } from "../../cypress/component-helpers.js";
import { defineComponent as s } from "vue";
const b = s({
  name: "CheckboxFieldComponent",
  components: { Field: a, Icon: r },
  mixins: [d()],
  props: {
    alignWithForm: {
      type: Boolean,
      default: !0
    }
  },
  emits: ["update:value"],
  data: /* @__PURE__ */ t((e) => ({
    $cypress: l({ ctx: e, labelField: "label" })
  }), "data"),
  computed: {
    valueGuard: {
      get() {
        return this.value;
      },
      set(e) {
        ["disabled", "readonly"].includes(this.state) || this.$emit("update:value", !!e);
      }
    },
    computedState() {
      return ["disabled", "readonly"].includes(this.state) ? "disabled" : void 0;
    }
  }
});
export {
  b as _
};
