<template>
  <EmailLogPage v-if="introducerContact" :owner="introducerContact" />
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerContactDetailsEmailLogWrapper",
  "route": "IntroducerContactDetailsEmailLog",
  "extends": {
    "name": "EmailLogPageWrapper",
    "path": "@/module/core/component/EmailLogPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import EmailLogPage from "@/module/core/component/EmailLogPage.vue";
import { defineComponent } from "vue";
import { IntroducerContactDetailsEmailLogRoute } from ".";
import { IntroducerContactDetailsMixin } from "../../../mixin/introducer-contact-details.mixin";

export default defineComponent({
  name: IntroducerContactDetailsEmailLogRoute,
  components: {
    EmailLogPage,
  },
  mixins: [IntroducerContactDetailsMixin],
});
</script>
