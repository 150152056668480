import { Api, Kintin } from "@kinherit/sdk";

//
export const DownloadClientSummary = async (message: {
  content: string;
  kintin: Kintin;
  password: boolean;
}) => {
  const { kintin, content } = message;

  const ref = kintin.ref;
  const name = kintin.friendlyName;

  await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/call-script/client-summary",
  )
    .method("post")
    .body({
      content,
      kintin: kintin.id,
      password: message.password,
    })

    .download({
      encoding: "application/pdf",
      fileName: `${ref} Client Summary - ${name}`,
    });

  return {};
};
