// Gift

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk";
import { Gift } from "@kinherit/sdk/model/gift";

export const ReadGift = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/gift",
  method: "get",
  rootModel: Gift,
  parse: (kintin: Kintin) => ({
    params: {
      kintin,
    },
  }),
});
