var o = Object.defineProperty;
var s = (i, t, a) => t in i ? o(i, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : i[t] = a;
var e = (i, t) => o(i, "name", { value: t, configurable: !0 });
var l = (i, t, a) => s(i, typeof t != "symbol" ? t + "" : t, a);
import { ValidationType as n } from "../core/validation-type.js";
const u = "file-limit", c = /* @__PURE__ */ e(({
  numberOfFiles: i,
  type: t = n.Error
}) => {
  var a;
  return a = class {
    constructor(r) {
      this.data = r;
    }
    getResult() {
      return this.data.input === void 0 ? null : Array.from(this.data.input.files || []).length > i ? {
        message: `Maximum of ${i} can be uploaded.`,
        type: t
      } : null;
    }
  }, l(a, "validatorName", u), a;
}, "FileLimitValidatorConstructor");
export {
  c as FileLimitValidatorConstructor,
  u as FileLimitValidatorName
};
