import { Api } from "@kinherit/sdk/api";
import { Order } from "@kinherit/sdk";

// Order
export const PushOrderToXero = async (order: Order) => {
  await Api.resource("portal", "/v2/portal/order/{order}/push-to-xero", {
    order,
  })
    .method("put")
    .result();
};
