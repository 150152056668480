var s = Object.defineProperty;
var a = (o, t) => s(o, "name", { value: t, configurable: !0 });
import { _ as r } from "../../../component.chart/bar-chart/bar-chart.vue_vue_type_script_lang.js";
import { resolveComponent as n, createBlock as p, openBlock as i } from "vue";
import { _ as l } from "../../../_plugins/_plugin-vue_export-helper.js";
function c(o, t, d, m, u, f) {
  const e = n("Bar");
  return i(), p(e, {
    options: o.computedChartOptions,
    data: {
      labels: o.labels,
      datasets: o.computedDatasets
    },
    "dataset-id-key": "bar-chart",
    plugins: o.plugins
  }, null, 8, ["options", "data", "plugins"]);
}
a(c, "_sfc_render");
const $ = /* @__PURE__ */ l(r, [["render", c]]);
export {
  $ as default
};
