import o from "../../_vue/component.display/calendar/calendar.js";
import { FormComponentWrapper as r } from "../../form-builder/core/component-wrapper.js";
import a from "../../_vue/component.display/calendar/calendar-field.js";
const t = r(o), d = r(a);
export {
  o as Calendar,
  t as FormCalendar,
  d as FormCalendarField,
  o as default
};
