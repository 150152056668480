import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";

export const ReadKinvaultCouponForm = (): FormBuilder<
  () => {
    name: null | string;
    stripeCouponId: null | string;
    created: null | [number, number];
  },
  string
> => {
  return MasterListFiltersForm({
    name: "filter-kinvault-coupon-form",
    data: () => ({
      name: null as null | string,
      stripeCouponId: null as null | string,
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Name",
          },
          models: {
            value: "name",
          },
        }),
      ],
      stripeCouponId: [
        FormTextField({
          props: {
            label: "Stripe Coupon ID",
          },
          models: {
            value: "stripeCouponId",
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
