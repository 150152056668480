<template>
  <EmailLogPage v-if="lead" :owner="lead" />
</template>

<cypress-wrapper lang="json">
{
  "name": "LeadDetailsEmailLogWrapper",
  "route": "LeadDetailsEmailLog",
  "extends": {
    "name": "EmailLogPageWrapper",
    "path": "@/module/core/component/EmailLogPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import EmailLogPage from "@/module/core/component/EmailLogPage.vue";
import { defineComponent } from "vue";
import { LeadDetailsEmailLogRoute } from ".";
import { LeadDetailsMixin } from "../../../mixin/lead-details.mixin";

export default defineComponent({
  name: LeadDetailsEmailLogRoute,
  components: {
    EmailLogPage,
  },
  mixins: [LeadDetailsMixin],
});
</script>
