var v = Object.defineProperty;
var t = (r, l) => v(r, "name", { value: l, configurable: !0 });
import { Uuid as x } from "@kinherit/ts-common/service/uuid";
import { FormIcon as S } from "../component.display/icon/index.js";
import { FormElement as g } from "../component.form/element/index.js";
import { GetDynamicLayoutSlots as f } from "../component.layout/dynamic-layout/helpers.js";
import { defineForm as N, defineFormArea as $ } from "../form-builder/define-form.js";
const w = /* @__PURE__ */ t((r) => (r ? Array.isArray(r) || (r = Array.from(
  r.querySelectorAll(
    "[cy-component]:not([cy-component] [cy-component])"
  )
)) : r = Array.from(
  document.querySelectorAll(
    "[cy-component]:not([cy-component] [cy-component])"
  )
), r.map((l) => {
  const p = console.getSelector(l), e = w(
    Array.from(
      l.querySelectorAll(
        `[cy-component]:not(${p} [cy-component] [cy-component])`
      )
    )
  ), n = l.getAttribute("cy-label"), s = l.getAttribute("cy-component"), i = l.getAttribute("cy-ref");
  return {
    component: `${s}Wrapper`,
    label: n,
    ref: i,
    slot: x.generate(),
    attr: {
      style: {
        marginLeft: "10px",
        borderLeft: "1px solid #ccc",
        paddingLeft: "5px"
      }
    },
    children: e
  };
})), "BuildComponentTree"), A = /* @__PURE__ */ t((r, l, p = []) => {
  for (const e of l)
    if (e.slot === r)
      return {
        ...e,
        fullLabel: [...p.pluck("label"), e.label].join("_"),
        fullRef: [
          ...p.map((n) => {
            const s = ["", null].includes(n.ref) ? null : `[cy-ref='${n.ref}']`, i = ["", null].includes(n.label) ? null : `[cy-label='${n.label}']`, b = ["", null].includes(n.component) ? null : `[cy-component='${n.component}']`;
            return s ?? i ?? b;
          }),
          (() => {
            const n = ["", null].includes(e.ref) ? null : `[cy-ref='${e.ref}']`, s = ["", null].includes(e.label) ? null : `[cy-label='${e.label}']`, i = ["", null].includes(e.component) ? null : `[cy-component='${e.component}']`;
            return n ?? s ?? i;
          })()
        ].join(" "),
        ref: (() => {
          const n = ["", null].includes(e.ref) ? null : `[cy-ref='${e.ref}']`, s = ["", null].includes(e.label) ? null : `[cy-label='${e.label}']`, i = ["", null].includes(e.component) ? null : `[cy-component='${e.component}']`;
          return n ?? s ?? i;
        })()
      };
  for (const e of l) {
    const n = A(r, e.children, [...p, e]);
    if (n !== null)
      return n;
  }
  return null;
}, "MatchTree"), E = /* @__PURE__ */ t(async (r) => {
  var n, s, i, b, F;
  const l = w(r), p = (((F = (b = (i = (s = (n = window.Kernel) == null ? void 0 : n.Router) == null ? void 0 : s.currentRoute) == null ? void 0 : i.value) == null ? void 0 : b.name) == null ? void 0 : F.toString()) ?? "Unknown").replaceAll(".", " ").toPascalCase(), { FormCheckboxField: e } = await import("../component.input/checkbox-field/index.js");
  N({
    name: "generate-template-form",
    data: /* @__PURE__ */ t(() => ({
      useFullNames: !1,
      included: Array()
    }), "data"),
    formAreas: /* @__PURE__ */ t((y) => [
      $({
        name: "generate-template-instructions-form-area",
        data: y,
        template: {
          children: [
            {
              html: "Select the components you want to include in the template. The template will be generated below. Use <b>cy-label</b> and <b>cy-ref</b> attributes to label and reference the components. Where possible, this information will be assumed."
            },
            {
              slot: "default"
            }
          ]
        },
        components: /* @__PURE__ */ t(() => ({
          default: [
            e({
              props: {
                label: "Toggle All"
              },
              models: {
                value: {
                  get: /* @__PURE__ */ t((o) => o.included.length === f({
                    children: l
                  }).length, "get"),
                  set: /* @__PURE__ */ t((o, c, u) => {
                    c.included = o ? f({
                      children: l
                    }) : [], u.rebuildForm();
                  }, "set")
                }
              }
            }),
            e({
              props: {
                label: "Full labels & references"
              },
              models: {
                value: {
                  get: /* @__PURE__ */ t((o) => o.useFullNames, "get"),
                  set: /* @__PURE__ */ t((o, c, u) => {
                    c.useFullNames = o, u.rebuildForm();
                  }, "set")
                }
              }
            })
          ]
        }), "components")
      }),
      $({
        name: "generate-template-setup-form-area",
        data: y,
        template: {
          children: l
        },
        components: /* @__PURE__ */ t(() => Object.fromEntries(
          f({
            children: l
          }).map((o) => {
            const c = A(o, l);
            if (c === null)
              throw new Error();
            const u = f(c), m = u.intersection(y.included).length > 0;
            return [
              o,
              [
                S({
                  props: {
                    class: "is-clickable",
                    size: "is-small",
                    icon: m ? "Minus" : "Plus",
                    style: {
                      marginLeft: "4px"
                    }
                  },
                  emits: {
                    click: /* @__PURE__ */ t((d, a, h) => {
                      m ? a.included = a.included.remove(...u) : a.included = a.included.concat(u).unique(), h.rebuildForm();
                    }, "click")
                  }
                }),
                e({
                  props: {
                    label: `${c.label} <small class="has-text-weight-normal">(${c.component})</small>`,
                    style: {
                      display: "inline-block",
                      paddingLeft: "6px",
                      paddingBottom: "6px"
                    }
                  },
                  models: {
                    value: {
                      get: /* @__PURE__ */ t((d) => d.included.includes(o), "get"),
                      set: /* @__PURE__ */ t((d, a, h) => {
                        d ? a.included.push(o) : a.included = a.included.remove(o), h.rebuildForm();
                      }, "set")
                    }
                  }
                })
              ]
            ];
          })
        ), "components")
      }),
      $({
        name: "generate-template-code-form-area",
        data: y,
        options: {
          element: {
            is: "code",
            style: {
              whiteSpace: "pre"
            }
          }
        },
        template: {
          children: [{ slot: "pre" }, { slot: "default" }, { slot: "post" }]
        },
        components: /* @__PURE__ */ t((o) => ({
          pre: [
            g({
              props: {
                is: "div",
                html: `export const ${p}Wrapper = defineComponentWrapper({
  defaultSelector: "",
  methods: {`
              }
            })
          ],
          default: f({
            children: l
          }).filter((c) => o.included.includes(c)).map((c, u) => {
            const m = A(c, l);
            if (m === null)
              throw new Error();
            const d = o.useFullNames ? m.fullRef : m.ref;
            return g({
              props: {
                is: "div",
                html: `${u === 0 ? "    " : `
    `}${o.useFullNames ? m.fullLabel : m.label}() {
      return ${m.component}({ selector: [...this._options.selector, "${d ?? "*no-selector-found*"}"] });
    },`
              }
            });
          }),
          post: [
            g({
              props: {
                is: "div",
                html: `  }
})`
              }
            })
          ]
        }), "components")
      })
    ], "formAreas")
  }).dialog({
    dialog: {
      title: `${p.ucFirst()} Page Wrapper`
    }
  });
}, "GenerateTemplate");
export {
  E as GenerateTemplate
};
