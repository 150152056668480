// EmailCampaignLog, EmailTemplate, FileLog

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EmailCampaignLog } from "@kinherit/sdk/model/email-campaign-log";

export const RecordEmailCampaignLog = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/email-campaign/log/{emailCampaignLog}",
  method: "get",
  rootModel: EmailCampaignLog,
  parse: (emailCampaignLog: EmailCampaignLog | string) => ({
    params: {
      emailCampaignLog,
    },
  }),
});
