var l = Object.defineProperty;
var r = (e, t) => l(e, "name", { value: t, configurable: !0 });
import p from "../../_vue/component.display/icon/icon.js";
import m from "../../_vue/component.form/field/field.js";
import { FieldMixin as u } from "../../component.mixin/field-mixin.js";
import { OptionsMixin as a } from "../../component.mixin/options-mixin.js";
import { CypressHelper as c } from "../../cypress/component-helpers.js";
import { defineComponent as d } from "vue";
import { S as f } from "../../_assets/component.input/select-field/select-field.module.js";
const g = d({
  name: "SelectFieldComponent",
  components: { Icon: p, Field: m },
  mixins: [u(), a()],
  props: {
    /**
     * Option to hide the fields built in clear button
     */
    showClearButton: {
      type: Boolean,
      default: !0
    },
    selectClass: {
      type: String,
      default: null
    },
    /**
     * Split the options into groups
     */
    groups: {
      type: Object,
      default: null
    }
  },
  emits: ["blur", "update:value"],
  data: /* @__PURE__ */ r((e) => ({
    $cypress: c({ ctx: e, labelField: "label" }),
    optionsFromPromise: {}
  }), "data"),
  computed: {
    includePlaceholder() {
      if (!this.placeholder)
        return !1;
      const t = this.computedOptions.pluck("label");
      return !t.includes(this.placeholder) && !t.includes("");
    },
    computedGroupedOptions() {
      var n;
      const e = [];
      (n = this.groups) == null || n.forEach((o) => {
        e.push({
          groupName: o.name,
          items: this.computedOptions.filter((s) => o.isMember(s.value)).map((s) => s)
        });
      });
      let t = [];
      e.forEach((o) => {
        t.push(...o.items.pluck("value")), t = t.unique();
      });
      const i = this.computedOptions.filter((o) => !t.includes(o.value)).map((o) => o);
      return Object.keys(i).isNotEmpty() && e.unshift({
        items: i
      }), e;
    }
  },
  mounted() {
    if (this.mapOptions && !Array.isArray(this.options))
      throw new Error(
        "SelectFieldComponent: mapOptions can only be used with an array of options"
      );
  },
  beforeMount() {
    window.Kernel.injectStylesheet("select-field", f);
  }
});
export {
  g as _
};
