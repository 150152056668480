// SignDoc

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { SignDoc } from "@kinherit/sdk/model/sign-doc";

export const DeleteSignDoc = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/sign-doc/{signDoc}",
  method: "delete",
  parse: (message: { kintin: Kintin | string; signDoc: SignDoc | string }) => ({
    params: {
      kintin: message.kintin,
      signDoc: message.signDoc,
    },
  }),
  after: ({ message }) => {
    SignDoc.$delete(message.signDoc);
  },
});
