var l = Object.defineProperty;
var i = (e, t) => l(e, "name", { value: t, configurable: !0 });
import { defineComponent as m } from "vue";
import { FieldMixin as n } from "../../component.mixin/field-mixin.js";
import { CypressHelper as u } from "../../cypress/component-helpers.js";
import { DateTime as r } from "@kinherit/ts-common/dto/date-time";
import { Time as s } from "@kinherit/ts-common/dto/time";
import o from "../../_vue/component.input/date-field/date-field.js";
import a from "../../_vue/component.input/time-field/time-field.js";
import { S as p } from "../../_assets/component.input/date-time-field/date-time-field.module.js";
const v = m({
  name: "DateTimeFieldComponent",
  components: { DateField: o, TimeField: a },
  mixins: [n()],
  props: {
    /**
     * v-model:value
     *
     * @values null, DateTime()
     */
    value: {
      type: Object,
      default: null
    },
    /**
     * Minimum date-time
     */
    min: {
      type: Object,
      default: null,
      value: "time"
    },
    /**
     * Maximum date-time
     */
    max: {
      type: Object,
      default: null,
      value: "time"
    }
  },
  emits: [
    /**
     * Emits the new value
     *
     * @property {any}
     */
    "update:value",
    /**
     * Emits when the input field is blurred
     */
    "blur"
  ],
  data: /* @__PURE__ */ i((e) => ({
    $cypress: u({ ctx: e, labelField: "label" })
  }), "data"),
  computed: {
    computedDate: {
      get() {
        return this.value === null ? null : this.value;
      },
      set(e) {
        if (e === null) {
          this.$emit("update:value", e);
          return;
        }
        const t = this.computedTime;
        t !== null && e.setTime(t.hours, t.minutes, t.seconds), this.$emit("update:value", e);
      }
    },
    computedTime: {
      get() {
        const e = this.value;
        return this.value === null ? null : s.fromString(e.formatTime);
      },
      set(e) {
        if (e === null) {
          this.$emit("update:value", null);
          return;
        }
        let t = this.computedDate;
        t === null && (t = new r()), t.setTime(e.hours, e.minutes, e.seconds), this.$emit("update:value", t);
      }
    },
    computedTimeMin() {
      const e = this.computedDate;
      if (e === null)
        return null;
      const t = this.min;
      return t === null ? null : e.sameDayAs(t) ? t.getTime() : null;
    },
    computedTimeMax() {
      const e = this.computedDate;
      if (e === null)
        return null;
      const t = this.max;
      return t === null ? null : e.sameDayAs(t) ? t.getTime() : null;
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("date-time-field", p);
  }
});
export {
  v as _
};
