import { SyncKinvaultCoupons } from "@/action-bus/external/cron-jobs/sync-kinvault-coupons.action";
import { SyncKinvaultPrices } from "@/action-bus/external/cron-jobs/sync-kinvault-prices.action";
import { SyncKinvaultSubscriptions } from "@/action-bus/external/cron-jobs/sync-kinvault-subscriptions.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

export const ExternalCronJobs = DefineActionBus({
  name: "external-cron-jobs",
  actions: {
    SyncKinvaultCoupons,
    SyncKinvaultPrices,
    SyncKinvaultSubscriptions,
  },
});
