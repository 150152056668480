import { SharedFormProps } from "@/config/form.config";
import { FormMessage } from "@kinherit/framework/component.display/message";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { EmailAddress } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";
import { Uuid } from "@kinherit/ts-common/service/uuid";

export const UpdateEmailAddressForm = <
  Data extends EmailAddress = EmailAddress,
>({
  data,
  isNew,
}: {
  data?: Data;
  isNew?: boolean;
} = {}) => {
  isNew = undefined === isNew ? !data : isNew;

  if (!data) {
    data = new EmailAddress({
      id: Uuid.generate(),
      email: "",
      primary: false,
      createdAt: new DateTime().formatMachine,
    }) as Data;
  }
  return defineForm({
    name: "update-email-address-form",
    props: SharedFormProps,
    data: () => data as Data,
    formAreas: (data) => [
      defineFormArea({
        name: "update-email-address-form-area",
        data,
        template: !isNew
          ? GridLayout(["warning", "email"])
          : GridLayout([["email"]]),
        components: () => ({
          warning: [
            FormMessage({
              props: {
                vIf: !isNew,
                color: "is-warning",
                title: "Warning",
                text: "Changes to this email address will be propagated throughout the system.",
              },
            }),
          ],
          email: [
            FormTextField({
              props: {
                label: "Email",
                validators: ["email", "required"],
              },
              models: {
                value: "email",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
