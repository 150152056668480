// Person, Profile, Address, EmailAddress, PhoneNumber, Website, Trustee, Executor, Attorney, Guardian, Kintin

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Person } from "@kinherit/sdk/model/person";

export const RecordPerson = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/officer/{officer}/{person}",
  method: "get",
  rootModel: Person,
  parse: (person: Person | string) => ({
    params: {
      person,
      officer: person,
    },
  }),
});
