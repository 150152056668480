var f = Object.defineProperty;
var i = (o, e) => f(o, "name", { value: e, configurable: !0 });
import { _ as C } from "../../../component.display/snackbar/snack-bar.vue_vue_type_script_lang.js";
import { resolveComponent as c, withDirectives as b, createElementBlock as a, openBlock as n, normalizeClass as u, renderSlot as v, createCommentVNode as r, createBlock as d, Fragment as h, createElementVNode as y, createVNode as B, toDisplayString as s, withCtx as m, createTextVNode as k, vShow as T } from "vue";
import { _ as $ } from "../../../_plugins/_plugin-vue_export-helper.js";
const w = ["role"], N = { class: "snack-bar-text" };
function S(o, e, V, g, D, E) {
  const p = c("Icon"), l = c("Button");
  return b((n(), a("div", {
    class: u(["snack-bar is-themed", o.color, o.position, o.shade]),
    role: o.actionText ? "alertdialog" : "alert",
    onMouseenter: e[0] || (e[0] = (...t) => o.pause && o.pause(...t)),
    onMouseleave: e[1] || (e[1] = (...t) => o.resume && o.resume(...t))
  }, [
    o.$slots.default ? v(o.$slots, "default", { key: 0 }) : o.text ?? o.icon ? (n(), a(h, { key: 1 }, [
      o.icon ? (n(), a("div", {
        key: 0,
        class: u(["snack-bar-icon", o.iconColor])
      }, [
        B(p, {
          icon: o.icon,
          "inherit-color": ""
        }, null, 8, ["icon"])
      ], 2)) : r("", !0),
      y("div", N, s(o.text), 1)
    ], 64)) : r("", !0),
    o.cancelText ? (n(), d(l, {
      key: 2,
      class: "snack-bar-cancel-button",
      color: o.cancelColor,
      onClick: o.close
    }, {
      default: m(() => [
        k(s(o.cancelText), 1)
      ]),
      _: 1
    }, 8, ["color", "onClick"])) : r("", !0),
    o.actionText ? (n(), d(l, {
      key: 3,
      class: "snack-bar-confirm-button",
      color: o.actionColor,
      onClick: o.action
    }, {
      default: m(() => [
        k(s(o.actionText), 1)
      ]),
      _: 1
    }, 8, ["color", "onClick"])) : r("", !0)
  ], 42, w)), [
    [T, o.show]
  ]);
}
i(S, "_sfc_render");
const j = /* @__PURE__ */ $(C, [["render", S]]);
export {
  j as default
};
