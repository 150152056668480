var l = Object.defineProperty;
var e = (t, s) => l(t, "name", { value: s, configurable: !0 });
import { ThemeFontSize as i } from "../../theme/prop/font-size.js";
import { defineComponent as a } from "vue";
const u = a({
  name: "TitleComponent",
  props: {
    /**
     * htag of component
     */
    htag: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ e((t) => ["h1", "h2", "h3", "h4", "h5", "h6"].includes(t), "validator")
    },
    /**
     * Use the subtitle styling
     * Should never be used on its own, only with a title
     * Provides additional context or information about what follows in the section.
     */
    isSubtitle: {
      type: Boolean,
      default: !1
    },
    /**
     * Change the size of the title
     */
    size: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ e((t) => Object.values(i).includes(t), "validator"),
      value: i
    },
    /**
     * Add additional margin below the title
     */
    isSpaced: {
      type: Boolean,
      default: !1
    },
    /**
     * Emphasise the title
     */
    isEmphasised: {
      type: Boolean,
      default: !1
    },
    /**
     * Text to display in the title
     */
    text: {
      type: String,
      default: null,
      value: "text"
    }
  },
  computed: {
    compHtag() {
      return this.htag && this.htag.trim() !== "" ? this.htag : null;
    },
    titleClasses() {
      let t = this.isSubtitle ? "subtitle" : "title";
      return this.isSpaced && (t += " is-spaced"), this.compHtag === null && this.size && (t += ` ${this.size}`), this.isEmphasised && (t += " is-emphasised"), t;
    }
  }
});
export {
  u as _
};
