import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateEmailCampaign } from "./create-email-campaign.action";
import { ReadEmailCampaigns } from "./read-email-campaigns.action";

export const Portal2EmailCampaigns = DefineActionBus({
  name: "portal-email-campaigns",
  actions: {
    ReadEmailCampaigns,
    CreateEmailCampaign,
  },
});
