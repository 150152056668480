var r = Object.defineProperty;
var t = (e, o) => r(e, "name", { value: o, configurable: !0 });
import { _ as l } from "../../../component.input/address-lookup-field/Vue3GoogleAddressAutocomplete.vue_vue_type_script_lang.js";
import { createElementBlock as p, openBlock as s, normalizeClass as a } from "vue";
import { _ as c } from "../../../_plugins/_plugin-vue_export-helper.js";
const n = ["placeholder"];
function m(e, o, d, f, i, u) {
  return s(), p("input", {
    ref: "autocompleteInput",
    type: "text",
    autocomplete: "off",
    placeholder: e.placeholder ?? void 0,
    class: a(e.inputClass)
  }, null, 10, n);
}
t(m, "_sfc_render");
const x = /* @__PURE__ */ c(l, [["render", m]]);
export {
  x as default
};
