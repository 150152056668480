// Lead, Profile, EmailAddress, PhoneNumber, AccountReferral

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Lead } from "@kinherit/sdk/model/lead";

export const CreateLead = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/leads",
  method: "post",
  parse: (target: Lead) => ({
    body: {
      assignedTo: target.$data.assignedTo,
      description: target.$data.description,
      ownedBy: target.$data.ownedBy,
      status: target.$data.status,
      primaryProfile: {
        firstName: target.primaryProfile.$data.firstName,
        lastName: target.primaryProfile.$data.lastName,
        phoneNumbers: target.primaryProfile.phoneNumbers.map((phoneNumber) => ({
          tel: phoneNumber.tel,
        })),
        emails: target.primaryProfile.emails.map((email) => ({
          email: email.email,
          primary: email.primary,
        })),
      },
      secondaryProfile: target.secondaryProfile
        ? {
            firstName: target.secondaryProfile.$data.firstName,
            lastName: target.secondaryProfile.$data.lastName,
            phoneNumbers: target.secondaryProfile.phoneNumbers.map(
              (phoneNumber) => ({
                tel: phoneNumber.tel,
              }),
            ),
            emails: target.secondaryProfile.emails.map((email) => ({
              email: email.email,
              primary: email.primary,
            })),
          }
        : null,
      referral: {
        contact: target.referral?.contact?.$data.id,
        referralCode: target.referral?.referralCode?.$data.id,
        externalId: target.referral?.$data.externalId,
      },
    },
  }),
  after: ({ message }) => {
    message.$delete({
      primaryProfile: {
        emails: true,
        phoneNumbers: true,
      },
      secondaryProfile: {
        emails: true,
        phoneNumbers: true,
      },
      referral: true,
    });
  },
});
