import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const ProductMasterListRoute = "ProductMasterList";
export const ProductUpdatePricesRoute = "ProductUpdatePrices";

export const ProductManageIterationsRoute = "ProductManageIterations";
const ProductManageIterationsUri = "/admin/products/:product/manage-iterations";
export type ProductManageIterationsParams = RouteParamObject<
  typeof ProductManageIterationsUri
>;

export const Routes: Array<RouteInterface> = [
  {
    name: ProductMasterListRoute,
    path: "/admin/products",
    component: () =>
      import(
        /* webpackChunkName: "ProductMasterList" */ "./ProductMasterList.vue"
      ),
  },
  {
    name: ProductUpdatePricesRoute,
    path: "/admin/products/update-prices",
    component: () =>
      import(
        /* webpackChunkName: "ProductUpdatePrices" */ "./ProductUpdatePrices.vue"
      ),
  },
  {
    name: ProductManageIterationsRoute,
    path: ProductManageIterationsUri,
    component: () =>
      import(
        /* webpackChunkName: "ProductManageIterations" */ "./ProductManageIterations.vue"
      ),
  },
];
