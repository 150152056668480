<template>
  <component
    :is="!noCard ? 'Card' : 'div'"
    v-if="$slots.above || $slots.below || filteredDetails.length > 0"
    :title="!noCard ? title : null"
    :class="[
      'data-list-widget',
      {
        'is-fullwidth': isFullWidth,
        'is-horizontal': direction === 'is-horizontal',
        'is-vertical': direction === 'is-vertical',
      },
    ]"
  >
    <slot name="above" />
    <div class="data-list-widget-content">
      <template
        v-for="(detail, index) in filteredDetails"
        :key="`data-list-widget-detail-${index}`"
      >
        <hr v-if="detail.break" />
        <div v-else class="data-list-widget__row">
          <template
            v-for="(data, index2) in [
              detail.label,
              detail.value,
              detail.valueOnly,
            ]"
            :key="`data-list-widget-detail-${index2}`"
          >
            <div
              v-if="undefined !== data"
              :class="{
                'data-list-widget__row__label': index === 0,
                'data-list-widget__row__value': index === 1,
                'data-list-widget__row__value-only': index === 2,
              }"
            >
              <span v-if="typeof data === 'string'" v-bind="bindings(data)">
                {{ data }}
              </span>
              <a v-else-if="data?.href" v-bind="bindings(data)" @click.stop>
                {{ data?.text }}
              </a>
              <a
                v-else-if="data?.to"
                v-bind="bindings(data)"
                @click.stop="visitRoute(data.to, $event)"
              >
                {{ data?.text }}
              </a>
              <span
                v-else-if="data?.html"
                v-bind="bindings(data)"
                v-html="data?.html"
              />
              <span v-else v-bind="bindings(data)">
                {{ data?.text }}
              </span>
            </div>
          </template>
        </div>
      </template>
    </div>
    <slot name="below" />
  </component>
</template>

<cypress-wrapper lang="json">
{
  "name": "DataListWidgetWrapper",
  "methods": {
    "rows": {
      "type": "to-many",
      "selector": ".data-list-widget-content .data-list-widget__row"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { Icon } from "@kinherit/framework/component.display/icon";
import { Card } from "@kinherit/framework/component.layout/card";
import {
  ThemeIconNameType,
  ThemeIconType,
} from "@kinherit/framework/theme/prop/icon";
import { defineComponent, type PropType } from "vue";
import { RouteLocationRaw } from "vue-router";
import { DataListWidgetDetail, Details } from "./data-list-widget";

export default defineComponent({
  name: "DataListWidget",
  components: {
    Card,
    Icon,
  },
  props: {
    noCard: {
      type: Boolean as PropType<boolean | null>,
      default: false,
    },
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    details: {
      type: Array as PropType<Array<DataListWidgetDetail>>,
      required: true,
    },
    isCompact: {
      type: Boolean as PropType<boolean | null>,
      default: false,
    },
    isFullWidth: {
      type: Boolean as PropType<boolean | null>,
      default: true,
    },
    icon: {
      type: String as PropType<ThemeIconNameType | ThemeIconType | null>,
      default: null,
    },
    direction: {
      type: String as PropType<"is-horizontal" | "is-vertical" | null>,
      default: "is-vertical",
    },
  },
  computed: {
    filteredDetails(): Array<DataListWidgetDetail> {
      return this.details.filter((detail) => true !== detail.hide);
    },
  },
  methods: {
    visitRoute(to: RouteLocationRaw, event: MouseEvent) {
      event.preventDefault();
      window.Kernel.visitRoute(to, event.ctrlKey);
    },
    bindings(data: Details | string | undefined | null) {
      if (undefined === data || null === data) {
        return {};
      }

      if (typeof data === "string") {
        return {};
      }

      return {
        class: {
          "has-text-weight-bold": data.bold,
          [data.color ?? ``]: data.color,
        },
        href: data.href,
        target: data.href ? "_blank" : undefined,
      };
    },
  },
});
</script>
