import { SharedFormProps } from "@/config/form.config";
import { FormMessage } from "@kinherit/framework/component.display/message";
import { FormPhoneNumberField } from "@kinherit/framework/component.input/phone-number-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { PhoneNumber } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";
import { Uuid } from "@kinherit/ts-common/service/uuid";

export const UpdatePhoneNumberForm = <Data extends PhoneNumber = PhoneNumber>({
  data,
  isNew,
}: {
  data?: Data;
  isNew?: boolean;
} = {}) => {
  isNew = undefined === isNew ? !data : isNew;

  if (!data) {
    data = new PhoneNumber({
      id: Uuid.generate(),
      tel: "",
      primary: false,
      createdAt: new DateTime().formatMachine,
    }) as Data;
  }

  return defineForm({
    name: "update-phone-number-form",
    props: SharedFormProps,
    data: () => data as Data,
    formAreas: (data) => [
      defineFormArea({
        name: "update-phone-number-form-area",
        data,
        template: !isNew
          ? GridLayout(["warning", "tel"])
          : GridLayout([["tel"]]),
        components: () => ({
          warning: [
            FormMessage({
              props: {
                vIf: !isNew,
                color: "is-warning",
                title: "Warning",
                text: "Changes to this phone number will be propagated throughout the system.",
              },
            }),
          ],
          tel: [
            FormPhoneNumberField({
              props: {
                label: "Phone Number",
                validators: ["required"],
              },
              models: {
                value: "tel",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
