// EmailTemplate

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EmailTemplate } from "@kinherit/sdk/model/email-template";

export const RecordEmailTemplate = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/email-template/{emailTemplate}",
  method: "get",
  rootModel: EmailTemplate,
  parse: (emailTemplate: EmailTemplate | string) => ({
    params: {
      emailTemplate,
    },
  }),
});
