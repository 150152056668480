// Exclusion

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Exclusion } from "@kinherit/sdk/model/exclusion";

export const RecordExclusion = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/exclusion/{exclusion}",
  method: "get",
  rootModel: Exclusion,
  parse: (exclusion: Exclusion) => ({
    params: {
      exclusion,
      kintin: exclusion.$data.kintin,
    },
  }),
});
