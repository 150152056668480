var n = Object.defineProperty;
var o = (t, e) => n(t, "name", { value: e, configurable: !0 });
import i from "../../_vue/component.display/icon/icon.js";
import { TranslationMixin as r } from "../../component.mixin/translation.mixin.js";
import { CypressHelper as a } from "../../cypress/component-helpers.js";
import { defineComponent as s } from "vue";
import { S as u } from "../../_assets/component.display/pagination/pagination.module.js";
const c = s({
  name: "PaginationComponent",
  components: { Icon: i },
  mixins: [
    r({
      defaults: {
        containerLabel: "Pagination",
        previousButtonLabel: "Go to previous page",
        nextButtonLabel: "Go to next page",
        goToPageLabel: /* @__PURE__ */ o((t) => `Go to page ${t}`, "goToPageLabel")
      }
    })
  ],
  props: {
    /**
     * The page you are currently on, starting from one
     */
    current: {
      type: Number,
      required: !0
    },
    /**
     * The total number of pages
     */
    total: {
      type: Number,
      required: !0
    },
    /**
     * Always show the next button as enabled
     */
    enableNext: {
      type: Boolean,
      default: !1
    },
    /**
     * Always show the previous button as enabled
     */
    enablePrevious: {
      type: Boolean,
      default: !1
    },
    /**
     * Option to hide the Previous button (used in date range)
     */
    hidePreviousButton: {
      type: Boolean,
      default: !1
    },
    /**
     * Option to hide the Next button (used in date range)
     */
    hideNextButton: {
      type: Boolean,
      default: !1
    },
    /**
     * Option to the other pages
     */
    hideOtherPages: {
      type: Boolean,
      default: !1
    },
    incrementBy: {
      type: Number,
      default: 1
    }
  },
  emits: [
    /**
     * Emits the page number the has been selected
     */
    "update:current"
  ],
  data: /* @__PURE__ */ o((t) => ({
    $cypress: a({ ctx: t })
  }), "data"),
  computed: {
    computedCurrent: {
      get() {
        return this.current;
      },
      set(t) {
        this.total !== 1 / 0 && t < 1 && !this.enablePrevious || this.total < t && !this.enableNext || this.$emit("update:current", t);
      }
    },
    compPages() {
      const t = [];
      if (this.total !== 1 / 0)
        for (let e = 1; e <= this.total; e++)
          e <= 1 || //first page
          e === this.computedCurrent || //current page
          e === this.total || //last page
          e === this.computedCurrent - this.incrementBy || //previous page
          e === this.computedCurrent + this.incrementBy || //next page
          this.computedCurrent <= 4 && e <= 5 || this.computedCurrent >= this.total - 3 && e >= this.total - 4 || this.total <= 7 ? t.push(e) : t[t.length - 1] !== null && t.push(null);
      return t;
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("pagination", u);
  }
});
export {
  c as _
};
