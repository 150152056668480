import { RouteInterface } from "@kinherit/framework/core/route-config";

export const KinvaultPhysicalStorageSubscriptionMasterListRoute =
  "KinvaultPhysicalStorageSubscriptionMasterList";

const KinvaultSubscriptionMasterListBreadCrumb = {
  text: "Physical Storage Subscriptions",
  route: { name: KinvaultPhysicalStorageSubscriptionMasterListRoute },
};

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultPhysicalStorageSubscriptionMasterListRoute,
    path: "/physical-storage-subscriptions",
    component: () =>
      import(
        /* webpackChunkName: "PhysicalStorageSubscriptionMasterList" */ "./PhysicalStorageSubscriptionMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [KinvaultSubscriptionMasterListBreadCrumb],
    },
  },
];
