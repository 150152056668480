import { defineComponent as t } from "vue";
const o = t({
  computed: {
    defaultSlot() {
      return this.$slots.default !== void 0;
    }
  }
});
export {
  o as default
};
