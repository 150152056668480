var p = Object.defineProperty;
var a = (o, e) => p(o, "name", { value: e, configurable: !0 });
import { _ as r } from "../../../component.input/email-field/email-field.vue_vue_type_script_lang.js";
import { resolveComponent as i, createBlock as m, openBlock as f, mergeProps as g, createSlots as v, withCtx as s, renderSlot as n, createElementVNode as b, createVNode as c } from "vue";
import { b as t } from "../../../_assets/component.input/email-field/email-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as w } from "../../../_plugins/_plugin-vue_export-helper.js";
const h = ["value", "placeholder", "disabled", "readonly", "autofocus"];
function k(o, e, $, C, y, B) {
  const u = i("Button"), d = i("BaseInputField");
  return f(), m(d, g(o.$data.$cypress, {
    value: o.computedEmail,
    "onUpdate:value": e[4] || (e[4] = (l) => o.computedEmail = l),
    class: `${o.wrapperClasses} email-field`,
    label: o.label,
    "input-tag": "input",
    type: "email",
    placeholder: o.placeholder,
    validators: o.computedValidators,
    "icon-left": o.iconLeftComputed,
    "icon-right-override": o.computedIconRight,
    message: o.message,
    "message-color": o.messageColor,
    size: o.size,
    color: o.color,
    "is-loading": o.isLoading,
    state: o.state,
    "show-validation": o.computedShowValidation,
    "left-icon-click": o.leftIconClick,
    "right-icon-click": o.rightIconClick,
    "auto-collapse-label": o.autoCollapseLabel,
    "is-fullwidth": o.isFullwidth,
    "is-grouped": o.isGrouped,
    "show-clear-button": o.showClearButton,
    autofocus: o.autofocus,
    autocomplete: o.autocomplete,
    onClear: o.clear,
    onFocus: o.focus,
    onBlur: e[5] || (e[5] = (l) => o.$emit("blur")),
    onIsValid: e[6] || (e[6] = (l) => o.$emit("is-valid", l)),
    onSubmit: o.keydown
  }), v({ _: 2 }, [
    o.showLeftControl || o.isNamed ? {
      name: "left",
      fn: s(() => [
        n(o.$slots, "left", { value: o.computedValue }),
        b("input", {
          ref: "input",
          value: o.computedName,
          class: "input email-field__name-input",
          placeholder: o.$t.local.placeholder,
          disabled: o.isNameDisabled,
          readonly: o.state === "readonly",
          autofocus: o.autofocus,
          onInput: e[0] || (e[0] = (l) => o.computedName = l.target.value),
          onFocus: e[1] || (e[1] = (...l) => o.focus && o.focus(...l)),
          onBlur: e[2] || (e[2] = (l) => o.$emit("blur")),
          onKeydown: e[3] || (e[3] = (...l) => o.keydown && o.keydown(...l))
        }, null, 40, h)
      ]),
      key: "0"
    } : void 0,
    o.showRightControl || o.showGenerateButton ? {
      name: "right",
      fn: s(() => [
        c(u, {
          "icon-left": {
            icon: "fa-question-circle",
            color: "var(--color-text)",
            opacity: 1,
            style: "Light"
          },
          size: o.size,
          "aria-label": o.$t.local.generateLabel,
          onClick: o.generateEmailAddress
        }, null, 8, ["size", "aria-label", "onClick"]),
        n(o.$slots, "right", { value: o.computedValue })
      ]),
      key: "1"
    } : void 0
  ]), 1040, ["value", "class", "label", "placeholder", "validators", "icon-left", "icon-right-override", "message", "message-color", "size", "color", "is-loading", "state", "show-validation", "left-icon-click", "right-icon-click", "auto-collapse-label", "is-fullwidth", "is-grouped", "show-clear-button", "autofocus", "autocomplete", "onClear", "onFocus", "onSubmit"]);
}
a(k, "_sfc_render");
typeof t == "function" && t(r);
const N = /* @__PURE__ */ w(r, [["render", k]]);
export {
  N as default
};
