// KintinCheck, Note

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk";
import { KintinCheck } from "@kinherit/sdk/model/kintin-check";

export const ReadKintinCheck = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/kintin-check",
  method: "get",
  rootModel: KintinCheck,
  parse: (kintin: Kintin) => ({
    params: {
      kintin,
    },
  }),
});
