import o from "qrcode-vue3";
import { defineComponent as t } from "vue";
const n = t({
  name: "QrCode",
  components: {
    QRCode: o
  },
  props: {
    value: {
      type: String,
      required: !0
    }
  },
  computed: {
    bindings() {
      return {
        value: this.value,
        width: 300,
        height: 300,
        margin: 0,
        qrOptions: {},
        imageOptions: {},
        dotsOptions: {
          color: "#00a19b",
          gradient: {
            type: "linear",
            rotation: 0,
            colorStops: [
              { offset: 0, color: "#00a19b" },
              { offset: 1, color: "#00a19b" }
            ]
          },
          type: "classy"
        },
        backgroundOptions: {},
        cornersDotOptions: {
          color: "#002A3A",
          gradient: {
            type: "radial",
            rotation: 0,
            colorStops: [
              { offset: 0, color: "#002A3A" },
              { offset: 1, color: "#002A3A" }
            ]
          },
          type: "dot"
        },
        cornersSquareOptions: {
          color: "#002A3A",
          gradient: {
            type: "linear",
            rotation: 0,
            colorStops: [
              { offset: 0, color: "#002A3A" },
              { offset: 1, color: "#002A3A" }
            ]
          },
          type: "dot"
        }
      };
    }
  }
});
export {
  n as _
};
