<template>
  <div v-if="kintin">
    <CallScriptSecuritySection
      start="Great. So before we start, I need to do a quick security check if that’s OK?"
      :kintin="kintin"
      step="2.1.1"
      :checks="checks"
      :kintin-checks="kintinChecks"
    />
    <CallScriptSalutationSection
      :kintin="kintin"
      :checks="checks"
      :kintin-checks="kintinChecks"
    />
    <CallScriptSection title="Other People">
      <b>
        For privacy reasons can I check, Is anyone else able to overhear the
        conversation?
      </b>
      <br />
      <em>[If other than partner, make note]</em>
      <br /><br />
      <b>
        Thank you. To protect you and your plan all of our calls are recorded is
        that ok?
      </b>
      <br />
      <br />

      <ConfirmCheck
        v-if="isJoint"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="2.1.3"
        :kintin="kintin"
        @update="updateCheckValue"
      />
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSalutationSection from "@/module/kinvault.kintin/component/call-script/shared/SalutationSection.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSecuritySection from "@/module/kinvault.kintin/component/call-script/shared/SecuritySection.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SecurityTab",
  components: {
    CallScriptSection,
    CallScriptSecuritySection,
    CallScriptSalutationSection,
    ConfirmCheck,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  data: () => ({
    renderKey: 0,
    person2Present: null as boolean | null,
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
});
</script>
