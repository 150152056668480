import { RouteInterface } from "@kinherit/framework/core/route-config";

export const AgreementsReportRoute = "AgreementsReportRoute";
const AgreementsReportUri = "/reports/sales/agreements";

export const CompaniesReportRoute = "CompaniesReportRoute";
const CompaniesReportUri = "/reports/sales/companies";

export const CompanyLeadboardReportRoute = "CompanyLeadboardReportRoute";
const CompanyLeadboardReportUri = "/reports/sales/companies/leaderboard";

export const DownloadReportRoute = "DownloadReportRoute";
const DownloadReportUri = "/reports/sales/download";

export const Routes: Array<RouteInterface> = [
  {
    name: AgreementsReportRoute,
    path: AgreementsReportUri,
    component: () => import("./Reports.Agreements.vue"),
  },
  {
    name: CompaniesReportRoute,
    path: CompaniesReportUri,
    component: () => import("./Reports.Companies.vue"),
  },
  {
    name: CompanyLeadboardReportRoute,
    path: CompanyLeadboardReportUri,
    component: () => import("./Reports.CompanyLeadboard.vue"),
  },
  {
    name: DownloadReportRoute,
    path: DownloadReportUri,
    component: () => import("./Reports.Download.vue"),
  },
];
