// Appointment

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Appointment } from "@kinherit/sdk/model/appointment";

export const UpdateAppointment = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/appointments/{appointment}",
  method: "patch",
  parse: (target: Appointment) => ({
    body: {
      acuityId: target.$data.acuityId,
      calendar: target.$data.calendar,
      appointmentAt: target.$data.appointmentAt,
      cancelled: target.$data.cancelled,
      notes: target.$data.notes,
      kintin: target.$data.kintin,
      lead: target.$data.lead,
      type: target.$data.type,
      outcome: target.$data.outcome,
      specialist: target.$data.specialist,
      profile: target.$data.profile,
    },
    params: {
      appointment: target,
    },
  }),
  after: ({ message }) => {
    message.$persist();
  },
});
