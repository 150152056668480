<template>
  <Tabs
    class="kintin-call-script-2-sidebar"
    size="is-normal"
    :config="items"
    is-compact
    is-vertical
  >
    <template #tab-item-left="{ tab }">
      <div style="min-width: 1.6em; text-align: right">
        <Badge
          is-compact
          size="is-small"
          :color="tab.count > 0 ? 'is-light-grey' : 'is-white'"
          class="m-0"
        >
          {{ tab.count }}
        </Badge>
      </div>
    </template>
  </Tabs>
</template>

<!--@Todo-->
<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsCallScript2Sidebar",
  "methods": {
    "tabs": {
      "type": "tabs",
      "selector": "",
      "items": []
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import {
  KintinDetailsCallScript2Call1Route,
  KintinDetailsCallScript2Call2AssetsRoute,
  KintinDetailsCallScript2Call2BeneficiariesRoute,
  KintinDetailsCallScript2Call2BusinessRoute,
  KintinDetailsCallScript2Call2ExecutorsRoute,
  KintinDetailsCallScript2Call2FamilyRoute,
  KintinDetailsCallScript2Call2GiftsRoute,
  KintinDetailsCallScript2Call2GuardiansRoute,
  KintinDetailsCallScript2Call2IntroRoute,
  KintinDetailsCallScript2Call2LPAConfigureRoute,
  KintinDetailsCallScript2Call2LPAsRoute,
  KintinDetailsCallScript2Call2OrderRoute,
  KintinDetailsCallScript2Call2TrusteesRoute,
} from "@/module/kinvault.kintin/page/details/call-script-2";
import Badge from "@kinherit/framework/component.display/badge";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { defineComponent } from "vue";

export default defineComponent({
  name: "KinvaultKintinDetailsCallScript2Sidebar",
  components: {
    Tabs,
    Badge,
  },
  mixins: [KinvaultKintinDetailsMixin],
  computed: {
    items() {
      return [
        {
          label: "Discovery Call",
          count: "1",
          route: {
            name: KintinDetailsCallScript2Call1Route,
            params: this.$params,
          },
        },
        {
          label: "Planning Call",
          count: "2a",
          route: {
            name: KintinDetailsCallScript2Call2IntroRoute,
            params: this.$params,
          },
        },
        {
          label: "Family",
          count: "2b",
          route: {
            name: KintinDetailsCallScript2Call2FamilyRoute,
            params: this.$params,
          },
        },
        {
          label: "Guardians",
          count: "2c",
          route: {
            name: KintinDetailsCallScript2Call2GuardiansRoute,
            params: this.$params,
          },
        },
        {
          label: "Assets",
          count: "2d",
          route: {
            name: KintinDetailsCallScript2Call2AssetsRoute,
            params: this.$params,
          },
        },
        {
          label: "Beneficiaries",
          count: "2e",
          route: {
            name: KintinDetailsCallScript2Call2BeneficiariesRoute,
            params: this.$params,
          },
        },
        {
          label: "Gifts & Excls",
          count: "2f",
          route: {
            name: KintinDetailsCallScript2Call2GiftsRoute,
            params: this.$params,
          },
        },
        {
          label: "Executors",
          count: "2g",
          route: {
            name: KintinDetailsCallScript2Call2ExecutorsRoute,
            params: this.$params,
          },
        },
        {
          label: "LPAs",
          count: "2h",
          route: {
            name: KintinDetailsCallScript2Call2LPAsRoute,
            params: this.$params,
          },
        },
        {
          label: "Business",
          count: "2i",
          route: {
            name: KintinDetailsCallScript2Call2BusinessRoute,
            params: this.$params,
          },
        },
        // {
        //   label: "IFAs",
        //   count: "2k",
        //   route: {
        //     name: KintinDetailsCallScript2Call2IFAsRoute,
        //     params: this.$params,
        //   },
        // },
        {
          label: "Order",
          count: "2j",
          route: {
            name: KintinDetailsCallScript2Call2OrderRoute,
            params: this.$params,
          },
        },
        {
          label: "Trustees",
          count: "2k",
          route: {
            name: KintinDetailsCallScript2Call2TrusteesRoute,
            params: this.$params,
          },
        },
        {
          label: "LPA Configure",
          count: "2l",
          route: {
            name: KintinDetailsCallScript2Call2LPAConfigureRoute,
            params: this.$params,
          },
        },
      ];
    },
  },
});
</script>

<style lang="scss">
.kintin-call-script-sidebar {
  .tabs__tab-content {
    display: none;
  }
}
</style>
