<template>
  <SidebarPage title="Kinvault Subscriptions">
    <template #sidebar>
      <KinvaultSubscriptionSideBar />
    </template>

    <template #default>
      <RouterChildView />
    </template>
  </SidebarPage>
</template>

<!--@Todo-->
<cypress-wrapper lang="json">
{
  "name": "KinvaultSubscriptionSidebar",
  "methods": {
    "tabs": {
      "type": "tabs",
      "selector": "",
      "items": []
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { KinvaultSubscriptionIndexRoute } from "./";
import KinvaultSubscriptionSideBar from "./KinvaultSubscriptions.Sidebar.vue";

export default defineComponent({
  name: KinvaultSubscriptionIndexRoute,
  components: {
    KinvaultSubscriptionSideBar,
    RouterChildView,
    SidebarPage,
  },
});
</script>
