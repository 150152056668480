var a = Object.defineProperty;
var t = (e, r) => a(e, "name", { value: r, configurable: !0 });
import l from "../../_vue/component.display/table/table.js";
import s from "../../_vue/component.layout/card/card.js";
import u from "../../_vue/component.layout/page-header/page-header.js";
import { defineComponent as i } from "vue";
import n from "../../_vue/component.page/master-list-page/master-list-filters.js";
const y = i({
  name: "MasterListPage",
  components: { Card: s, Table: l, MasterListFilters: n, PageHeader: u },
  props: {
    persistMethod: {
      type: String,
      default: "query"
    },
    persistState: {
      type: Boolean,
      default: !0
    },
    hasCreateButton: {
      type: Boolean,
      default: !1
    },
    trackingField: {
      type: [String, Function],
      default: null
    },
    hideTitle: {
      type: Boolean,
      default: !1
    },
    isExpanded: {
      type: Boolean,
      default: !1
    },
    title: {
      type: String,
      default: null
    },
    columns: {
      type: Array,
      default: /* @__PURE__ */ t(() => [], "default")
    },
    rows: {
      type: Array,
      default: /* @__PURE__ */ t(() => [], "default")
    },
    filters: {
      type: Object,
      default: null
    },
    sortBy: {
      type: String,
      default: "createdAt"
    },
    sortDirection: {
      type: String,
      default: "desc",
      validator: /* @__PURE__ */ t((e) => ["asc", "desc"].includes(e), "validator")
    },
    rowClasses: {
      type: Function,
      default: /* @__PURE__ */ t(() => ({}), "default")
    },
    currentPage: {
      type: Number,
      default: null
    },
    perPage: {
      type: Number,
      default: 15
    },
    lastPage: {
      type: Number,
      default: null
    },
    count: {
      type: Number,
      default: 0
    },
    sortByOptions: {
      type: Object,
      default: /* @__PURE__ */ t(() => ({
        createdAt: "Created"
      }), "default")
    },
    hasContextMenu: {
      type: Boolean,
      default: !1
    },
    subtitle: {
      type: String,
      default: null
    },
    isNested: {
      type: Boolean,
      default: !0
    },
    isPlain: {
      type: Boolean,
      default: !1
    },
    enablePerPage: {
      type: Boolean,
      default: !0
    },
    isClickable: {
      type: Boolean,
      default: !0
    },
    clickToExpand: {
      type: Boolean,
      default: !1
    },
    value: {
      type: Array,
      default: /* @__PURE__ */ t(() => [], "default")
    },
    emptyMessage: {
      type: String,
      default: "No records found"
    },
    isFixed: {
      type: Boolean,
      default: !1
    },
    showFilters: {
      type: Boolean,
      default: !0
    }
  },
  emits: {
    "row-clicked": null,
    refresh: null,
    "update:current-page": null,
    "update:per-page": null,
    "context-menu": null,
    create: null,
    "update:value": null,
    "update:sort-by": null,
    "update:sort-direction": null
  },
  data: /* @__PURE__ */ t(() => ({
    loading: !0
  }), "data"),
  computed: {
    computedTitle() {
      return this.hideTitle ? null : this.title;
    },
    computedCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(e) {
        e !== this.currentPage && (this.$emit("update:current-page", e), this.refresh());
      }
    },
    computedPerPage: {
      get() {
        return this.perPage;
      },
      set(e) {
        e !== this.perPage && (this.$emit("update:per-page", e), this.refresh());
      }
    },
    slots() {
      return Object.keys(this.$slots).filter((e) => !e.startsWith("_") && e !== "buttons").map((e) => e);
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("update:value", e);
      }
    },
    computedSortBy: {
      get() {
        return this.sortBy;
      },
      set(e) {
        this.$emit("update:sort-by", e), this.refresh();
      }
    },
    computedSortDirection: {
      get() {
        return this.sortDirection;
      },
      set(e) {
        this.$emit("update:sort-direction", e), this.refresh();
      }
    }
  },
  watch: {
    loading: {
      handler(e) {
        e || this.refresh();
      }
    }
  },
  methods: {
    async search() {
      this.computedCurrentPage = 1, await this.refresh();
    },
    async refresh() {
      var r;
      if (this.loading)
        return;
      const e = ((r = this.filters) == null ? void 0 : r.localData) ?? {};
      this.$emit("refresh", e);
    }
  }
});
export {
  y as _
};
