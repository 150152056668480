var a = Object.defineProperty;
var t = (e, o) => a(e, "name", { value: o, configurable: !0 });
import { _ as m } from "../../../component.display/icon-text/icon-text.vue_vue_type_script_lang.js";
import { resolveComponent as p, createElementBlock as f, openBlock as n, normalizeClass as i, createBlock as r, createCommentVNode as c, createElementVNode as u, toDisplayString as k } from "vue";
import { _ as z } from "../../../_plugins/_plugin-vue_export-helper.js";
function $(e, o, d, g, h, C) {
  const s = p("Icon");
  return n(), f("span", {
    class: i(e.classes),
    onClick: o[0] || (o[0] = (l) => e.$emit("click", l))
  }, [
    e.iconLeft ? (n(), r(s, {
      key: 0,
      size: e.size,
      class: i(`${e.iconLeft} icon-left`),
      icon: e.iconLeft,
      "inherit-color": ""
    }, null, 8, ["size", "class", "icon"])) : c("", !0),
    u("span", null, k(e.text), 1),
    e.iconRight ? (n(), r(s, {
      key: 1,
      size: e.size,
      class: i(`${e.iconRight} icon-right`),
      icon: e.iconRight,
      "inherit-color": ""
    }, null, 8, ["size", "class", "icon"])) : c("", !0)
  ], 2);
}
t($, "_sfc_render");
const R = /* @__PURE__ */ z(m, [["render", $]]);
export {
  R as default
};
