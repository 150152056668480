import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { CallScript, Check, KintinCheck, Person, Profile } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const KinvaultKintinDetailsCallScriptMixin = defineComponent({
  name: "CallScriptMixin",
  mixins: [KinvaultKintinDetailsMixin],
  data: () => ({
    checkValues: {} as Record<
      `${string}.${string}.${string}`,
      number | undefined | null
    >,
    callScript: {} as CallScript,
    checkChangeRenderKey: 0,
  }),
  computed: {
    primaryPerson(): Person {
      return this.kintin!.primaryPerson;
    },
    primaryProfile(): Profile {
      return this.primaryPerson.profile;
    },
    primarySalutation: {
      get(): string | null {
        return this.primaryProfile.knownAs ?? this.primaryProfile.firstName;
      },
      set(value: string | null) {
        this.primaryProfile.knownAs = value;
      },
    },
    secondaryPerson(): Person | null {
      return this.kintin!.secondaryPerson;
    },
    secondaryProfile(): Profile | null {
      return this.secondaryPerson?.profile ?? null;
    },
    secondarySalutation: {
      get(): string | null {
        return (
          this.secondaryProfile?.knownAs ??
          this.secondaryProfile?.firstName ??
          "your partner"
        );
      },
      set(value: string | null) {
        if (this.secondaryProfile) {
          this.secondaryProfile.knownAs = value;
        }
      },
    },
    kintinChecks(): KintinCheck[] {
      return this.kintin!.checks;
    },
    isJoint(): boolean {
      return this.kintin!.type.value === "joint";
    },
    children(): Person[] {
      return this.kintin!.people.filter(
        (person) => person.under18ForPlanningPurposes,
      );
    },
    dependents(): Person[] {
      return this.kintin!.people.filter((person) => person.requiresCare);
    },
    hasChildrenOrDependents(): boolean {
      return this.children.length > 0 || this.dependents.length > 0;
    },
    childOrDependentList(): Person[] {
      return [...this.children, ...this.dependents];
    },
    marriedOrCivilPartnership(): boolean {
      return ["married", "civil"].includes(
        this.primaryPerson.maritalStatus?.value ?? "",
      ) &&
        ["married", "civil"].includes(
          this.secondaryPerson?.maritalStatus?.value ?? "",
        )
        ? true
        : false;
    },
    checks(): Check[] {
      return Check.$all();
    },
  },
  mounted() {
    this.callScript = this.kintin!.callScript!;
  },
  methods: {
    updateCheckValue(step: `${string}.${string}.${string}`, value?: number) {
      if (value === undefined && this.checkValues[step] !== undefined) {
        return;
      }

      if (value === undefined) {
        this.checkValues[step] = KintinCheck.$findOneBy({
          kintin: {
            id: this.kintin!.id,
          },
          step: step,
        })?.checkValue;
        return;
      }

      this.checkValues[step] = value;
      this.checkChangeRenderKey++;
    },
    checkIsYes(step: `${string}.${string}.${string}`): boolean {
      this.updateCheckValue(step);

      return this.checkValues[step] === 1;
    },
    checkIsNo(step: `${string}.${string}.${string}`): boolean {
      this.updateCheckValue(step);

      return this.checkValues[step] === 0;
    },
    checkIsUnknown(step: `${string}.${string}.${string}`): boolean {
      this.updateCheckValue(step);

      return (
        this.checkValues[step] === undefined || this.checkValues[step] === null
      );
    },
    checkIsMaybe(step: `${string}.${string}.${string}`): boolean {
      this.updateCheckValue(step);

      return this.checkValues[step] === 2;
    },
  },
});
