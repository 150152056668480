import { Model } from "@kinherit/orm";
import {
  Api,
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

export const SendEmailLogHandler = async (message: {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Array<File>;
  requiredFiles: Record<string, File>;
}): Promise<void> => {
  const routes = {
    [IntroducerCompany.$name]:
      "/v2/portal/introducer/company/{owner}/email-log/{emailLog}",
    [IntroducerContact.$name]:
      "/v2/portal/introducer/contact/{owner}/email-log/{emailLog}",
    [Kintin.$name]: "/v2/portal/kinvault/{owner}/email-log/{emailLog}",
    [Lead.$name]: "/v2/portal/lead/{owner}/email-log/{emailLog}",
    [Person.$name]: "/v2/portal/officer/{owner}/email-log/{emailLog}",
    [BrandedKinvault.$name]:
      "/v2/portal/admin/branded-kinvault/{owner}/email-log/{emailLog}",
  } as const;

  const route = routes[message.owner.$name];

  await Api.resource("portal", `${route}/send`, {
    owner: message.owner,
    emailLog: message.emailLog,
  })
    .method("post")
    .result();

  message.emailLog.$delete();
};
