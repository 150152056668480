import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { LeadDetailsBreadCrumb } from "../../../breadcrumbs";

export const LeadDetailsIfaCallScript2025Route = "LeadDetailsIfaCallScript2025";
export const LeadDetailsIfaCallScript2025URI =
  "/lead/:lead/ifa-call-script-2025";
export type LeadDetailsIfaCallScriptParams = RouteParamObject<
  typeof LeadDetailsIfaCallScript2025URI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: LeadDetailsIfaCallScript2025Route,
    path: LeadDetailsIfaCallScript2025URI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "LeadDetailsIfaCallScript" */ "./LeadDetails.IfaCallScript2025.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...LeadDetailsBreadCrumb(params),
        {
          text: "Call Script (2025)",
          route: { name: LeadDetailsIfaCallScript2025Route, params },
        },
      ],
    },
  },
];
