// Guardian

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Guardian } from "@kinherit/sdk/model/guardian";

export const RecordGuardian = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/guardian/{guardian}",
  method: "get",
  rootModel: Guardian,
  parse: (guardian: Guardian) => ({
    params: {
      guardian,
      kintin: guardian.$data.kintin,
    },
  }),
});
