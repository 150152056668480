var N = Object.defineProperty;
var b = (e, r) => N(e, "name", { value: r, configurable: !0 });
import * as n from "@kinherit/sdk";
const P = /* @__PURE__ */ b((e) => async (r) => {
  var c, p, $, y;
  const m = "message" in r ? r.message : {}, s = e.parse(m, {}), q = "params" in s ? s.params : {}, u = n.Api.resource(
    e.interface,
    e.resource,
    q
  ).method(e.method), f = "rawQuery" in s ? s.rawQuery : {};
  u.params(f), u.paginate(r.pagination ?? !1), r.sort && u.sort(r.sort);
  const i = u.buildQuery(e.rootModel);
  "query" in s && i.where(s.query), "query" in r && i.where(r.query);
  const a = await u.result(), o = {};
  o.$pagination = {
    currentPage: Number.parseInt(((c = a.$pagination) == null ? void 0 : c.currentPage) ?? "1"),
    lastPage: Number.parseInt(((p = a.$pagination) == null ? void 0 : p.lastPage) ?? "0"),
    count: Number.parseInt((($ = a.$pagination) == null ? void 0 : $.count) ?? "0")
  }, a.$rootIds && (o.$rootIds = a.$rootIds);
  const l = Object.values(n).pluck("$name").filter(Boolean).cast(), d = {};
  for (const t of l)
    d[t] = n[t];
  for (const t in a) {
    let w, I;
    t.ucFirst() === e.rootModel.$name && (w = a.$rootIds, I = r.sort), l.includes(t.ucFirst()) ? o[t] = d[t.ucFirst()].$inflate(
      a[t],
      I,
      w
    ) : o[t] = a[t];
  }
  return await ((y = e.after) == null ? void 0 : y.call(e, {
    message: m,
    response: o
  })), e.map ? e.map(a) : o;
}, "DefineReadAction");
export {
  P as DefineReadAction
};
