// WillRevision, Kintin, User, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { WillRevision } from "@kinherit/sdk/model/will-revision";

export const ReadWillRevision = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/will-revision",
  method: "get",
  rootModel: WillRevision,
  parse: () => ({}),
});
