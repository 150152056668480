import {
  KintinField,
  OptionsAutoCompleteField,
  OrderField,
} from "@/config/form.config";
import { CreateOrderRefundRequest } from "@/config/model.config";
import { AuthService } from "@/service/auth.service";
import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, IsNotNull } from "@kinherit/orm/index";
import { OrderRefundRequest } from "@kinherit/sdk";
import { reactive } from "vue";

export const UpdateOrderRefundRequestForm = (
  orderRefundRequest: OrderRefundRequest = CreateOrderRefundRequest(),
) => {
  const localData = reactive({
    kintin: orderRefundRequest?.order?.kintin ?? null,
  });

  return defineForm({
    props:
      orderRefundRequest.status.value === "pendingApproval"
        ? {}
        : { state: "disabled" },
    name: "update-order-refund-request-form",
    data: () => orderRefundRequest,
    formAreas: (data) => [
      defineFormArea({
        name: "order-refund-request",
        data,
        template: GridLayout([
          ["kintin", "order"],
          ["amount", "status"],
          ["refundReason"],
          ["completedAt"],
        ]),
        components: () => ({
          kintin: [
            KintinField({
              props: {
                reference: "kintin",
                label: "Account",
                vModel: {
                  get: () => localData.kintin,
                  set: (value, _, controls) => {
                    localData.kintin = value;

                    controls.rebuildForm();
                  },
                },
                validators: ["required"],
              },
            }),
          ],
          order: [
            OrderField({
              props: {
                reference: "order",
                vModel: "order",
                state: () => {
                  if (!localData.kintin) {
                    return "disabled";
                  }

                  if (data.status.value !== "pendingApproval") {
                    return "disabled";
                  }

                  return undefined;
                },
                validators: ["required"],
              },
              query: {
                kintin: {
                  id: Equal(localData.kintin?.id),
                  orders: {
                    id: IsNotNull(),
                  },
                },
              },
            }),
          ],
          amount: [
            FormCurrencyField({
              props: {
                label: "Amount to Refund",
                validators: ["required"],
              },
              models: {
                value: "amount",
              },
            }),
          ],
          status: [
            OptionsAutoCompleteField({
              group: "orderRefundRequestStatus",
              props: {
                reference: "status",
                label: "Status",
                validators: ["required"],
                state: "disabled",
              },
              vModel: "status",
            }),
          ],
          completedAt: [
            FormDateField({
              props: {
                label: "Completed At",
                state: AuthService.hasRole("admin") ? undefined : "disabled",
              },
              models: {
                value: "completedAt",
              },
            }),
          ],
          refundReason: [
            FormTextField({
              props: {
                label: "Refund Reason",
                isTextArea: true,
                validators: ["required"],
              },
              models: {
                value: "refundReason",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
