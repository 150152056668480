import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { RevokeAccess } from "./revoke-access.action";
import { RevokeBrandedKinvaultAccess } from "./revoke-branded-kinvault-access.action";
import { VoidOutstandingOrders } from "./void-outstanding-orders.action";

export const Portal2KinvaultStatus = DefineActionBus({
  name: "portal-kinvault-status",
  actions: {
    RevokeAccess,
    RevokeBrandedKinvaultAccess,
    VoidOutstandingOrders,
  },
});
