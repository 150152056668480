import { Api } from "@/service/api.service";
import { FileLog, KnowledgeBaseDocument } from "@kinherit/sdk";

interface CreateKnowledgeBaseDocumentFileLogMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
  file: File;
}

export const CreateFileLog = async (
  message: CreateKnowledgeBaseDocumentFileLogMessage,
): Promise<void> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/knowledge-base/category/document/file-log/{knowledgeBaseDocument}/document",
  )
    .method("post")
    .body({
      knowledgeBaseDocument: message.knowledgeBaseDocument.id,
      fileExtension: message.file.name.split(".").pop() as string,
      fileName: message.file.name,
    })
    .result();

  const fileLog = FileLog.$inflate(response.fileLog).first();

  if (!fileLog) {
    throw new Error("Failed to create knowledge base document");
  }

  await Api.resource(
    "portal",
    "/v2/portal/knowledge-base/category/document/file-log/{knowledgeBaseDocument}/document/{fileLog}/upload",
    {
      knowledgeBaseDocument: message.knowledgeBaseDocument.id,
      fileLog: fileLog.id,
    },
  )
    .method("put")
    .files({
      uploadedFile: message.file,
    })
    .result();
};
