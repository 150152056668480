<template>
  <SidebarPage title="Reports" :tabs="sectionTabs" :tab="currentTabIndex">
    <template #sidebar>
      <ReportSidebar :section="currentSection" />
    </template>

    <template #default>
      <RouterChildView />
    </template>
  </SidebarPage>
</template>

<script lang="ts">
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { RouteTabOptions } from "@kinherit/framework/component.display/tabs/types";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { CoreReportsRoute, KinvaultReportsRoute, SalesReportsRoute } from ".";
import ReportSidebar from "../component/Reports.Sidebar.vue";

const sectionTabs: RouteTabOptions[] = [
  {
    label: "Core",
    route: { name: CoreReportsRoute },
    icon: StyleService.icon.summary.icon,
  },
  {
    label: "Sales",
    route: { name: SalesReportsRoute },
    icon: StyleService.icon.order.icon,
  },
  {
    label: "Kinvault",
    route: { name: KinvaultReportsRoute },
    icon: StyleService.icon.file.icon,
  },
];

export default defineComponent({
  name: "ReportsPage",
  components: {
    SidebarPage,
    ReportSidebar,
    RouterChildView,
  },
  data: () => ({
    sectionTabs,
  }),
  computed: {
    currentSection(): "core" | "sales" | "kinvault" {
      const currentRoute = this.$route;
      const path = currentRoute?.path || "";
      if (path.includes("/reports/core")) return "core";
      if (path.includes("/reports/sales")) return "sales";
      if (path.includes("/reports/kinvault")) return "kinvault";
      return "core";
    },
    currentTabIndex(): number {
      return this.sectionTabs.findIndex((tab) => {
        const tabRoute = tab.route as { name: string };
        return (
          tabRoute.name ===
          `${
            this.currentSection.charAt(0).toUpperCase() +
            this.currentSection.slice(1)
          }ReportsRoute`
        );
      });
    },
  },
});
</script>

<style lang="scss">
.reports-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__section-tabs {
    padding: 1rem 1rem 0;
  }
}
</style>
