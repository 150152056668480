import {
  BrandedKinvaultField,
  CreatedAtField,
  RoleField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";

import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadUserForm = () => {
  return MasterListFiltersForm({
    name: "filter-user-form",
    data: () => ({
      search: null as string | null,
      role: Array<string>(),
      brandedKinvault: Array<string>(),
      created: null as null | [number, number],
      lastLoggedIn: null as null | [number, number],
      isDisabled: null as null | boolean,
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      search: [
        FormTextField({
          props: {
            reference: "search",
            label: "Search",
            placeholder: "Name or email",
            size: "is-small",
          },
          models: {
            value: {
              get: (data) => data.search ?? "",
              set: (value, data) => (data.search = value ?? undefined),
            },
          },
        }),
      ],
      role: [
        RoleField({
          props: {
            vModel: "role",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      brandedKinvault: [
        BrandedKinvaultField({
          props: {
            vModel: "brandedKinvault",
            isMultiSelect: true,
          },
          query: {},
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
    advancedComponents: () => ({
      lastLoggedIn: [
        CreatedAtField({
          props: {
            vModel: "lastLoggedIn",
          },
          simplifyData: true,
        }),
      ],
      isDisabled: [
        FormSelectField({
          props: {
            label: "Is Disabled",
            options: {
              null: "Any",
              yes: "Yes",
              no: "No",
            },
            showClearButton: false,
            reference: "isDisabled",
          },
          models: {
            value: {
              get: (data) => {
                if (data.isDisabled === null) {
                  return `null`;
                }

                return data.isDisabled ? "yes" : "no";
              },
              set: (value, data) => {
                data.isDisabled =
                  value === "yes" ? true : value === "no" ? false : null;
              },
            },
          },
        }),
      ],
    }),
  });
};
