<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
      'call-script-who-gets-what': true,
    }"
  >
    <PageHeader htag="h2" text="Who Gets What">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <Button text="Save" color="is-positive" @click="saveHandler" />
      </template>
    </PageHeader>
    <Tabs
      v-model:tab="tab"
      position="both"
      is-lazy
      :config="
        ['Beneficiaries', 'R2R', 'Gifts', 'Exclusions', 'Outro'].map((x) => ({
          label: x,
        }))
      "
    >
      <!-- Tab Beneficiaries-->
      <template #beneficiaries>
        <BeneficiariesTab />
      </template>
      <!-- Tab R2R-->
      <template #r2r>
        <R2RTab @save-and-continue="saveAndContinueHandler" />
      </template>
      <!-- Tab Gifts-->
      <template #gifts>
        <GiftsTab />
      </template>
      <!-- Tab Exclusions-->
      <template #exclusions>
        <ExclusionsTab @save-and-continue="saveAndContinueHandler" />
      </template>
      <!-- Tab Outro-->
      <template #outro>
        <OutroTab />
      </template>
    </Tabs>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsCallScriptWhoGetsWhatWrapper",
  "route": "KintinDetailsCallScriptWhoGetsWhat",
  "seletor": ".call-script-who-gets-what"
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { KintinDetailsCallScriptWhoGetsWhatRoute } from "@/module/kinvault.kintin/page/details/call-script/who-gets-what";
import { SnackBarService } from "@/service/snack-bar.service";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { KintinDetailsCallScriptParams } from "..";
import { KintinDetailsCallScriptWhoDoesWhatRoute } from "../who-does-what";
import BeneficiariesTab from "./BeneficiariesTab.vue";
import ExclusionsTab from "./ExclusionsTab.vue";
import GiftsTab from "./GiftsTab.vue";
import OutroTab from "./OutroTab.vue";
import R2RTab from "./R2RTab.vue";

export default defineComponent({
  name: KintinDetailsCallScriptWhoGetsWhatRoute,
  components: {
    PageHeader,
    Button,
    Tabs,
    BeneficiariesTab,
    R2RTab,
    GiftsTab,
    ExclusionsTab,
    OutroTab,
  },
  mixins: [SnackBarService.mixin, KinvaultKintinDetailsCallScriptMixin],
  data: () => ({
    isFocused: false,
    tab: 0,
  }),
  mounted(): void {
    if (!this.kintin) {
      return;
    }
  },
  methods: {
    async saveAndContinueHandler() {
      if (!this.kintin) {
        return;
      }

      await this.saveHandler();

      this.$snackBar.success.saved();
      window.Kernel.visitRoute({
        name: KintinDetailsCallScriptWhoDoesWhatRoute,
        params: { kintin: this.kintin.id } as KintinDetailsCallScriptParams,
      });
    },
    async saveHandler() {
      if (!this.kintin) {
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.callScript.update({
      await this.$actionBus.callScript.UpdateCallScript(this.kintin.callScript);

      this.$snackBar.success.saved();
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
