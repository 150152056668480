import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ChangeCompany } from "./change-company.action";
import { ComputeIntroducerContactStats } from "./compute-introducer-contact-stats.action";
import { CreateIntroducerContact } from "./create-introducer-contact.action";
import { DeleteIntroducerContact } from "./delete-introducer-contact.action";
import { Portal2IntroducerContactKintinAccess } from "./kintin-access";
import { Portal2IntroducerContactPartnerPortalPermissions } from "./partner-portal-permissions";
import { ReadIntroducerContact } from "./read-introducer-contact.action";
import { ReadIntroducerCpds } from "./read-introducer-cpds.action";
import { RecordIntroducerContact } from "./record-introducer-contact.action";
import { Portal2IntroducerContactReferral } from "./referral";
import { ReplaceProfile } from "./replace-profile.action";
import { UpdateIntroducerContact } from "./update-introducer-contact.action";

export const Portal2IntroducerContact = DefineActionBus({
  name: "portal-introducer-contact",
  actions: {
    UpdateIntroducerContact,
    DeleteIntroducerContact,
    RecordIntroducerContact,
    ComputeIntroducerContactStats,
    CreateIntroducerContact,
    ReadIntroducerContact,
    UpdateIntroducerContactProfile: ReplaceProfile,
    ReadIntroducerCpds,
    ChangeCompany,
    kintinAccess: Portal2IntroducerContactKintinAccess,
    referral: Portal2IntroducerContactReferral,
    partnerPortalPermissions: Portal2IntroducerContactPartnerPortalPermissions,
  },
});
