var k = Object.defineProperty;
var u = (e, o) => k(e, "name", { value: o, configurable: !0 });
import { _ as h } from "../../../component.display/calendar/calendar-field.vue_vue_type_script_lang.js";
import { resolveComponent as n, createElementBlock as a, openBlock as s, mergeProps as p, createVNode as c, withCtx as g, createCommentVNode as l, createElementVNode as $, renderSlot as m, normalizeClass as r, createBlock as t } from "vue";
import { _ as v } from "../../../_plugins/_plugin-vue_export-helper.js";
const z = {
  key: 0,
  class: "input-field__control"
}, y = {
  key: 1,
  class: "input-field__control"
};
function I(e, o, b, V, w, B) {
  const i = n("Icon"), C = n("Calendar"), f = n("Field");
  return s(), a("div", p(e.$data.$cypress, {
    class: `${e.wrapperClasses} input-field rich-text-field`
  }), [
    c(f, {
      label: e.label,
      "has-addons": e.computedhasAddons,
      "is-grouped": e.computedIsGrouped,
      messages: e.computedMessages,
      size: e.size
    }, {
      default: g(() => [
        e.showLeftControl ? (s(), a("div", z, [
          m(e.$slots, "left", { value: e.computedValue })
        ])) : l("", !0),
        $("div", {
          class: r(`input-field__control ${e.controlClasses} is-expanded`)
        }, [
          e.iconLeft ? (s(), t(i, {
            key: 0,
            size: e.size,
            icon: e.iconLeft,
            class: r(`is-left ${e.leftIconClick ? "is-clickable" : ""}`),
            onClick: e.leftIconClick
          }, null, 8, ["size", "icon", "class", "onClick"])) : l("", !0),
          e.showClearButton && e.computedValue !== null ? (s(), t(i, {
            key: 1,
            icon: "Cross",
            size: e.size,
            class: "is-right is-clickable",
            onClick: e.clear
          }, null, 8, ["size", "onClick"])) : e.computedIconRight ? (s(), t(i, {
            key: 2,
            icon: e.computedIconRight,
            size: e.size,
            class: r(`is-right ${e.rightIconClick ? "is-clickable" : ""}`),
            onClick: e.rightIconClick
          }, null, 8, ["icon", "size", "class", "onClick"])) : l("", !0),
          c(C, p({
            ref: "input",
            value: e.computedValue,
            "onUpdate:value": o[0] || (o[0] = (d) => e.computedValue = d),
            class: e.inputClasses,
            placeholder: e.placeholder ?? void 0,
            style: {
              "pointer-events": e.state === "disabled" || e.state === "readonly" ? "none" : "auto",
              minHeight: "auto"
            },
            "is-date-picker": !0,
            "is-widget": ""
          }, e.propsCalendar, {
            onFocus: e.focus,
            onBlur: o[1] || (o[1] = (d) => e.$emit("blur"))
          }), null, 16, ["value", "class", "placeholder", "style", "onFocus"])
        ], 2),
        e.showRightControl ? (s(), a("div", y, [
          m(e.$slots, "right", { value: e.computedValue })
        ])) : l("", !0)
      ]),
      _: 3
    }, 8, ["label", "has-addons", "is-grouped", "messages", "size"])
  ], 16);
}
u(I, "_sfc_render");
const E = /* @__PURE__ */ v(h, [["render", I]]);
export {
  E as default
};
