<template>
  <div class="appointment-master-list">
    <PageHeader htag="h1" :text="title" />
    <Card>
      <MasterListFilters
        v-if="null !== $data.filters && showFilters"
        :filters="$data.filters"
        is-nested
        @submit="search"
        @change="change"
      />
      <div v-if="showFilters" class="buttons">
        <Button
          v-for="(count, key) in callTypeCounts"
          :key="key"
          :color="`is-${callTypeColors[key as AppointmentTypes]}`"
          :class="{
            'js-badge-filter mr-1': true,
            'is-outlined': !filters?.localData?.selected?.includes(key),
          }"
          :text="`${displayLabels[key as AppointmentTypes] || key} (${count})`"
          size="is-small"
          @click="filterCallType(key as AppointmentTypes)"
        />
      </div>
      <Table
        v-for="(rows, date) in rowGroups"
        :key="`row-group-${date.replace(` `, ``)}`"
        v-model:page="pagination.currentPage"
        v-model:per-page="pagination.perPage"
        v-model:sort-by="sort.by"
        v-model:sort-direction="sort.direction"
        :title="date"
        is-scrollable
        enable-per-page
        :show-header-controls="date === firstGroup"
        :show-footer-controls="date === lastGroup"
        :total-pages="pagination.lastPage"
        :total-records="pagination.count"
        :columns="columns"
        :rows="rows"
        :row-classes="rowClasses"
        is-fullwidth
        is-narrow
        is-hoverable
        :sort-by-options="{
          createdAt: 'Created',
          appointmentAt: 'Date',
        }"
        :empty-message="'No appointments found'"
        :persist-state="persistState"
        @update:page="() => refresh('table', date, 'bottom')"
        @update:per-page="() => refresh('table', date, 'top')"
        @update:sort-by="() => refresh('table', date, 'top')"
        @update:sort-direction="() => refresh('table', date, 'top')"
      >
        <template #type="{ row }: { row: AppointmentCustomQueryRow }">
          <Badge
            :text="row.type.text"
            :color="
              row.cancelled
                ? 'is-danger'
                : (('is-' +
                    callTypeColors[
                      row.type.group as AppointmentTypes
                    ]) as ThemeColorType)
            "
            size="is-small"
            class="is-block"
            is-compact
          />
          <span
            v-if="row.kintinAccountReferralCode || row.leadAccountReferralCode"
            class="badge is-compact is-light-grey is-small is-block"
            >{{
              row.kintinAccountReferralCode ?? row.leadAccountReferralCode
            }}</span
          >
        </template>
        <!--template #notes="{ row }: { row: AppointmentCustomQueryRow }">
          <ShowNotesButton
            v-if="row.kintinId"
            :has-write-permission="hasWritePermission"
            :kintin="row.kintinId"
            is-communication-note
            :count="0"
            :show-account-button="true"
            tooltip="View Notes"
          />
        </template-->
        <template #clientName="{ row }: { row: AppointmentCustomQueryRow }">
          <span
            v-if="row.kintinId"
            :class="{
              'client-link is-block is-clickable': true,
            }"
            @click="(e) => handleAccountClick(row.kintinId, e)"
          >
            <strong
              :class="{
                'has-text-danger': row.cancelled,
                'has-text-info': !row.cancelled,
              }"
              >{{ row.clientName }}</strong
            > </span
          ><span
            v-else-if="row.leadId"
            class="client-link is-block is-clickable"
            @click="(e) => handleLeadClick(row.leadId, e)"
          >
            <strong
              :class="{
                'has-text-danger': row.cancelled,
                'has-text-info': !row.cancelled,
              }"
              >{{ row.clientName }}</strong
            >
          </span>
          <span v-else class="is-block has-text-weight-bold">{{
            row.clientName
          }}</span>
          <span v-if="row.kintinFriendlyName" class="is-block">{{
            row.kintinFriendlyName
          }}</span>

          <span>{{ row.email }}</span>
        </template>
        <template #outcome="{ row }: { row: AppointmentCustomQueryRow }">
          <Button
            :text="row.outcome.text"
            :color="('is-' + row.outcome.class) as ThemeColorType"
            size="is-small"
            @click="() => handleSetOutcomeClick(row)"
          />
        </template>
        <template #specialist="{ row }: { row: AppointmentCustomQueryRow }">
          <Avatar size="is-small" :name="row.calendar" />
        </template>
        <template #tel="{ row }: { row: AppointmentCustomQueryRow }">
          <span v-if="row.phoneNumber && null !== row.phoneNumber">
            <!-- can be a comma delimted list -->
            <a
              v-for="phoneNumber in row.phoneNumber.split(', ')"
              :key="phoneNumber"
              :href="'tel:' + phoneNumber"
              class="mr-1 is-block"
            >
              {{ phoneNumber }}
            </a>
          </span>
          <span v-else>No Number Supplied</span>
        </template>
      </Table>
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AppointmentMasterListWrapper",
  "route": "AppointmentMasterList",
  "selector": ".appointment-master-list",
  "imports": {
    "ReadAppointmentFormWrapper": "@/module/appointment/form/read-appointment.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadAppointmentFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { AppointmentCustomQueryRow } from "@/action-bus/portal/appointments/read-test.action";
import { ReadAppointmentForm } from "@/module/appointment/form/read-appointment.form";
// import ShowNotesButton from "@/module/core/component/note/ShowNotesButton.vue";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import {
  LeadDetailsParams,
  LeadDetailsRoute,
} from "@/module/lead/page/details";
import { AuthService } from "@/service/auth.service";
import { Avatar } from "@kinherit/framework/component.display/avatar";
import { Badge } from "@kinherit/framework/component.display/badge";
import { Table } from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ThemeColorType } from "@kinherit/framework/theme/prop/color";
import { Appointment, IAppointment, Option, Tag } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";
import { defineComponent, type PropType } from "vue";
import { AppointmentMasterListRoute } from ".";
import { UpdateAppointmentOutcomeForm } from "../form/update-appointment-outcome.form";

type AppointmentTypeCounts = {
  enquiry: number;
  cancelled: number;
  options: number;
  info: number;
  other: number;
  kinvault: number;
  ["will-review"]: number;
};

type AppointmentTypes = keyof AppointmentTypeCounts;

export default defineComponent({
  name: AppointmentMasterListRoute,
  components: {
    Card,
    Table,
    MasterListFilters,
    Avatar,
    // ShowNotesButton,
    Badge,
    Button,
    PageHeader,
  },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal.appointments),
  ],
  props: {
    persistState: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: Object as PropType<Tag>,
      default: null,
    },
    specialist: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    dateRange: {
      type: String as PropType<
        | "today_end-of-week"
        | "today_tomorrow"
        | "today"
        | "tomorrow"
        | "this-week"
        | "next-week"
        | "last-week"
        | "custom"
        | null
      >,
      default: null,
    },
  },
  data: () => ({
    filters: ReadAppointmentForm(),
    columns: [
      {
        title: "EP",
        slot: "specialist",
        width: "3em",
      },
      {
        title: "Time",
        sort: true,
        map(row: AppointmentCustomQueryRow): string {
          return new DateTime(row.appointmentAt).format("HH:II");
        },
        width: "4em",
      },
      {
        title: "Type",
        slot: "type",
        width: "12em",
      },
      {
        title: "Client",
        sort: true,
        slot: "clientName",
      },
      {
        title: "Tel",
        sort: true,
        slot: "tel",
        width: "12em",
      },
      // {
      //   title: "Notes",
      //   slot: "notes",
      //   width: "5em",
      // },
      {
        title: "Outcome",
        slot: "outcome",
        width: "14em",
      },
    ] as Array<TableColumn>,
    rowGroups: {} as Record<string, Array<AppointmentCustomQueryRow>>,
    callTypeCounts: {
      enquiry: 0,
      cancelled: 0,
      options: 0,
      info: 0,
      other: 0,
      kinvault: 0,
      "will-review": 0,
    } as AppointmentTypeCounts,
    callTypeColors: {
      enquiry: "brown" as const,
      info: "blue" as const,
      options: "purple" as const,
      kinvault: "teal" as const,
      "will-review": "green" as const,
      other: "grey" as const,
      cancelled: "red" as const,
    },
    firstGroup: "" as string,
    lastGroup: "" as string,
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 100,
      count: 0,
    },
    sort: {
      by: "appointmentAt" as keyof IAppointment,
      direction: "asc" as "desc" | "asc",
    },
    displayLabels: {
      enquiry: "Enquiry",
      cancelled: "Cancelled",
      options: "Planning",
      info: "Discovery",
      other: "Other",
      kinvault: "Kinvault",
      "will-review": "Will Review",
    },
  }),
  computed: {
    title(): string | null {
      if (this.hideTitle) {
        return null;
      }

      return "Appointments";
    },
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("appointment:write");
    },
  },
  mounted(): void {
    if (this.tag) {
      this.filters.localData.tags = [this.tag.id];
    }
    if (this.specialist.length > 0) {
      this.filters.localData.specialist = this.specialist;
    }
    if (this.dateRange) {
      this.filters.localData.dateRange = this.dateRange;
    }
    this.refresh("component");
  },
  methods: {
    handleLeadClick(leadId: string, event: MouseEvent): void {
      if (!leadId) {
        return;
      }
      const params: LeadDetailsParams = { lead: leadId };

      window.Kernel.visitRoute(
        {
          name: LeadDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    handleAccountClick(kintinId: string, event: MouseEvent): void {
      if (!kintinId) {
        return;
      }
      const params: KinvaultKintinDetailsParams = { kintin: kintinId };

      window.Kernel.visitRoute(
        {
          name: KinvaultKintinDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    rowClasses({
      row,
    }: {
      index: number;
      row: unknown;
    }): Record<string, boolean> {
      const appointment = row as Appointment;
      return {
        "has-background-warning-danger is-cancelled": appointment.cancelled,
      };
    },
    async change(): Promise<void> {
      if (this.pagination.perPage <= 25) {
        this.pagination.currentPage = 1;
        await this.refresh("component");
      }
    },
    async search(): Promise<void> {
      this.pagination.currentPage = 1;
      await this.refresh("component");
    },
    async refresh(
      caller: "table" | "component",
      date?: string,
      position?: "top" | "bottom",
    ): Promise<void> {
      if (
        caller === "table" &&
        position === "top" &&
        date !== Object.keys(this.rowGroups).first()
      ) {
        return;
      }

      if (
        caller === "table" &&
        position === "bottom" &&
        date !== Object.keys(this.rowGroups).last()
      ) {
        return;
      }

      const { appointments, pagination, typeCounts } =
        await this.$actionBus.ReadAppointments({
          ...(this.filters?.localData ?? {}),
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rowGroups = {};

      appointments.forEach((appointment: AppointmentCustomQueryRow) => {
        const date = appointment.appointmentDate;

        if (!this.rowGroups[date]) {
          this.rowGroups[date] = [];
        }
        this.rowGroups[date].push(appointment);
      });

      if (Object.keys(this.rowGroups).isEmpty()) {
        this.rowGroups[new DateTime().formatDate] = [];
      }

      this.callTypeCounts = {
        enquiry: 0,
        info: 0,
        options: 0,
        kinvault: 0,
        "will-review": 0,
        other: 0,
        cancelled: 0,
      };

      typeCounts.forEach((typeCount) => {
        this.callTypeCounts[
          typeCount.typeGroup as keyof AppointmentTypeCounts
        ] += typeCount.count;
        if (typeCount.cancelled === true) {
          this.callTypeCounts.cancelled += typeCount.count;
        }
      });

      this.firstGroup = Object.keys(this.rowGroups).first() ?? "";
      this.lastGroup = Object.keys(this.rowGroups).last() ?? "";

      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.$forceUpdate();
    },
    filterCallType(callType: AppointmentTypes): void {
      const callTypes: Array<Option> = Option.$findBy({
        data: {
          group: callType,
        },
        group: "appointmentType",
      });

      if (!this.filters || !this.filters.localData) {
        return;
      }

      if (this.filters.localData.selected?.includes(callType)) {
        this.filters.localData.type = [];
        this.filters.localData.selected = null;
        this.filters.localData.cancelled = false;
        this.refresh("component");
        return;
      }

      this.filters.localData.type = callTypes.pluck("id");
      this.filters.localData.selected = callType;
      this.filters.localData.cancelled = "cancelled" === callType;
      this.filters.controls.incrementFormRenderKey();

      this.refresh("component");
    },
    async handleSetOutcomeClick(row: AppointmentCustomQueryRow): Promise<void> {
      // Get appointment from API
      // await window.Kernel.ActionBus.appointment.record({
      await this.$actionBus.RecordAppointment({ message: row.id });

      const appointment = Appointment.$findOneByOrThrow({
        id: row.id,
      });

      try {
        await UpdateAppointmentOutcomeForm(appointment).dialog({
          dialog: {
            title: "Set Appointment Outcome",
          },
        });

        // await window.Kernel.ActionBus.appointment.update({
        await this.$actionBus.UpdateAppointment(appointment);
        this.refresh("component");
      } catch {
        appointment.$restore();
      }
    },
  },
});
</script>
