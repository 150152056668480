<template>
  <NotePage
    v-if="introducerContact"
    :has-write-permission="hasWritePermission"
    :introducer-contact="introducerContact"
    is-introducer-note
    :is-note="false"
    :note-types="['note', 'introducerNote']"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerContactDetailsNotesWrapper",
  "route": "IntroducerContactDetailsNotes",
  "extends": {
    "name": "NotePageWrapper",
    "path": "@/module/core/component/note/NotePage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import NotePage from "@/module/core/component/note/NotePage.vue";
import { IntroducerContactDetailsMixin } from "@/module/introducer.contact/mixin/introducer-contact-details.mixin";
import { AuthService } from "@/service/auth.service";
import { defineComponent } from "vue";
import { IntroducerContactDetailsNotesRoute } from ".";

export default defineComponent({
  name: IntroducerContactDetailsNotesRoute,
  components: { NotePage },
  mixins: [IntroducerContactDetailsMixin],
  computed: {
    hasWritePermission(): boolean {
      return AuthService.hasPermission("introducer-contact:write");
    },
  },
});
</script>
