var n = Object.defineProperty;
var t = (e, s) => n(e, "name", { value: s, configurable: !0 });
import a from "../../_vue/component.display/icon/icon.js";
import { ThemeColor as r } from "../../theme/prop/color.js";
import { ThemeIconName as i } from "../../theme/prop/icon.js";
import { ThemeShade as l } from "../../theme/prop/shade.js";
import { ThemeSize as o } from "../../theme/prop/size.js";
import { defineComponent as u } from "vue";
import { S as d } from "../../_assets/component.display/icon-text/icon-text.module.js";
const b = u({
  name: "IconTextComponent",
  components: { Icon: a },
  props: {
    /**
     * color variant
     */
    shade: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((e) => Object.values(l).includes(e), "validator"),
      value: l
    },
    /**
     * The text to be displayed next to the icon
     */
    text: {
      type: String,
      required: !0,
      value: "text"
    },
    /**
     * Name of the perdefined icon the display to the user
     */
    color: {
      type: String,
      default: "is-info",
      value: r
    },
    /**
     * Name of the perdefined icon the display to the user
     */
    size: {
      type: String,
      default: "is-normal",
      validator: /* @__PURE__ */ t((e) => Object.values(o).includes(e), "validator"),
      value: o
    },
    /**
     * Name of the perdefined icon the display to the user
     */
    iconLeft: {
      type: String,
      default: null,
      value: i
    },
    /**
     * Name of the perdefined icon the display to the user
     */
    iconRight: {
      type: String,
      default: null,
      value: i
    },
    /**
     * Whether or not the element should use the outlined style
     */
    isOutlined: {
      type: Boolean,
      default: !0
    },
    /**
     * Whether or not the element expend on hover
     */
    isHoverable: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether or not the element is clikable
     */
    isClickable: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether or not the element is rounded
     */
    isRounded: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether or not the element is bold
     */
    isBold: {
      type: Boolean,
      default: !1
    }
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    click: /* @__PURE__ */ t((e) => !0, "click")
  },
  computed: {
    classes() {
      return {
        "icon-text": !0,
        [this.color]: !0,
        [this.size]: !0,
        "is-outlined": this.isOutlined,
        "is-hoverable": this.isHoverable,
        "is-rounded": this.isRounded,
        "is-clickable": this.isClickable,
        [`${this.shade}`]: this.shade !== null,
        "is-bold": this.isBold,
        "is-themed": !this.isOutlined
      };
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("icon-text", d);
  }
});
export {
  b as _
};
