var s = Object.defineProperty;
var a = (n, t) => s(n, "name", { value: t, configurable: !0 });
import p from "../../_vue/component.display/icon/icon.js";
import { defineComponent as d } from "vue";
const f = d({
  name: "JsonComponent",
  components: { Icon: p },
  props: {
    title: {
      type: [String, Number],
      default: "Scope"
    },
    data: {
      type: [
        Object,
        Array,
        Date,
        Number,
        String,
        Boolean,
        Function
      ],
      default: null
    },
    isOpen: {
      type: Boolean,
      default: !1
    },
    nested: {
      type: Number,
      default: 0
    }
  },
  data: /* @__PURE__ */ a(() => ({
    isExpanded: !1
  }), "data"),
  computed: {
    depth() {
      return "  ".repeat(this.nested);
    },
    isExpandable() {
      return ["object", "array", "function"].includes(typeof this.data);
    },
    computedKeys() {
      const n = this.data, t = Object.getOwnPropertyNames(this.data);
      let e = n;
      for (; Object.getPrototypeOf(e) !== Object.prototype; )
        e = Object.getPrototypeOf(e), t.push(...Object.getOwnPropertyNames(e));
      return t.sort((o, r) => o < r ? -1 : o > r ? 1 : 0), t;
    }
  },
  mounted() {
    this.nested === 1 && (this.isExpanded = !0);
  }
});
export {
  f as _
};
