// Person, Trustee, Executor, Guardian, Beneficiary, Attorney, Adviser, Profile, EmailAddress

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { Person } from "@kinherit/sdk/model/person";

export const RecordEmailLog = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/email-log",
  method: "get",
  rootModel: Person,
  parse: (kintin: Kintin | string) => ({
    params: {
      kintin,
    },
  }),
});
