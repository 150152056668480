// Tag

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Tag } from "@kinherit/sdk/model/tag";

export const CreateTag = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/admin/tag",
  method: "post",
  parse: (target: Tag) => ({
    body: {
      name: target.$data.name,
      description: target.$data.description,
    },
  }),
  after: ({ message }) => {
    Tag.$delete(message);
  },
});
