<template>
  <div v-if="kintin">
    <CallScriptSection title="Gifts">
      <b class="mb-4 is-block">
        We've talked about the bulk of your estate and your main beneficiaries,
        but have you got anything more specific you want to give to anyone? This
        could be a specific item or a specific amount of money? Is there
        anything that springs to mind?
      </b>
      <GiftRecipientsContainer :kintin="kintin" />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.2.1"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <!-- <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advGiftNotes"
      :has-continue="false"
    /> -->
    <CallScriptSection title="Exclusions">
      <b>
        Moving on to exclusions. The purpose of excluding people is to protect
        your Will from challenge that they had been accidentally forgotten. Is
        there anyone, typically ex-partners or family members, that you think
        should be listed?
      </b>
      <br /><br /><em>[If no response prompt with]</em>
      <b>
        <br />It is to make sure no one contests your Will, so if you do think
        of anything let me know.
      </b>
      <br />
      <br />
      <ExclusionsContainer :kintin="kintin" />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.5.1"
        @update="updateCheckValue"
      />
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import GiftRecipientsContainer from "@/module/kinvault.kintin/component/address-book/GiftRecipientsContainer.vue";
import ExclusionsContainer from "@/module/kinvault.kintin/component/address-book/ExclusionsContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GiftsTab",
  components: {
    CallScriptSection,
    ConfirmCheck,
    GiftRecipientsContainer,
    ExclusionsContainer,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
});
</script>
