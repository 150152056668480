// AccountReferralCode

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";

export const ReadAccountReferralCode = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/account-referral-codes",
  method: "get",
  rootModel: AccountReferralCode,
  parse: () => ({}),
});
