var i = Object.defineProperty;
var d = (r, e, s) => e in r ? i(r, e, { enumerable: !0, configurable: !0, writable: !0, value: s }) : r[e] = s;
var o = (r, e) => i(r, "name", { value: e, configurable: !0 });
var t = (r, e, s) => d(r, typeof e != "symbol" ? e + "" : e, s);
const n = class n {
  static map(e) {
    const s = {};
    return e.forEach((a) => {
      s[a] = () => this[this.language][a];
    }), s;
  }
};
o(n, "Dictionary"), t(n, "language", "english"), t(n, "english", {
  lifeTimeGiftTypes: {
    lumpsum: "Lump Sum",
    property: "Property",
    regulargift: "Regular Gift (below threshold)"
  },
  referralSources: {
    partnercode: "* Referral: Partner (With Code)",
    existing_user: "* Referral: Existing User",
    none: "None"
  },
  types: {
    single: "Just for Myself",
    joint: "For Myself and my Partner",
    parent: "On behalf of an individual (as relative or agent)",
    parents: "On behalf of a couple (as relative or agent)"
  },
  titles: ["Mr", "Mrs", "Miss", "Ms", "Dr", "Revd", "Sir"],
  genders: ["Male", "Female", "Other"],
  maritalStatuses: [
    "Single",
    "Married",
    "Civil Partnership",
    "Divorced",
    "Widowed"
  ],
  businessTypes: {
    sole_trader: "Sole Trader",
    limited: "Limited",
    llp: "LLP",
    partnership: "Partnership",
    other: "Other"
  },
  businessAssetTypes: {
    business: "Business Asset",
    agricultural: "Agricultural Asset"
  },
  investmentTypes: {
    bonds: "Bonds",
    inv_bonds: "Investment Bonds",
    non_cash_isas: "Non Cash ISAs",
    nsandi: "NS&I (all types)",
    oeic: "OEIC",
    other: "Other",
    othercoll: "Other Collected Investments",
    pbonds: "Premium Bonds",
    shares: "Shares",
    unit_trust: "Unit Trust"
  },
  locationTypes: {
    within_uk: "Within UK",
    outside_uk: "Outside UK"
  },
  pensionTypes: {
    workplace: "Workplace (employed)",
    workplace_ex: "Workplace (no longer employed)",
    personal: "Personal Pension",
    stakeholder: "Stakeholder Pension",
    finalsalary: "Final Salary / Defined Benefits",
    sipp: "SIPP",
    sass: "SASS",
    other: "Other"
  },
  giftTypes: {
    business: "Business or Agricultural Property",
    property: "Gifts of Property",
    fixedamount: "Cash Fixed Amount / Monetary",
    bankaccount: "Gift of Bank Account",
    chattels: "Memorandum for Personal Chattels",
    item: "A Specific Item (Golf Clubs, a Painting etc..)",
    pet: "Pet(s)",
    charityclause: "10% Charity Clause",
    percentageofestate: "Percentage of Estate (Caution not in legacy)",
    financial: "Result of Financial Product (Caution not in legacy)"
  },
  cashDebtTypes: {
    cash_current: "Cash in Current Accounts",
    cash_isas: "Cash ISAs",
    cash_savings: "Cash in Savings Accounts",
    cash_other: "Other Arbitary Cash Assets",
    debt_loans: "Loans, excluding Mortgages",
    debt_ccards: "Credit Card Debts",
    debt_other: "Other Arrears"
  },
  policyTypes: {
    serious_illness: "Serious Illness",
    critical_illness: "Critical Illness",
    whole_of_life: "Whole of Life",
    life_critical: "Life and Critical Illness",
    term_assurance: "Term Assurance",
    income_protection: "Income Protection",
    mortgage_protection: "Mortgage Protection",
    mort_critical: "Mortgage protection with Critical Illness",
    fib: "FIB (Family Income Benefits)",
    menu_plan: "Menu Plan",
    other: "Other"
  },
  policyPayOutTypes: {
    decreasing: "Decreasing/Mortage Protection",
    lumpsum: "Level Lump Sum",
    annual: "Annual Regular Payment",
    monthly: "Monthly Regular Payment",
    other: "Other"
  },
  propertyTypes: {
    residential: "Residential",
    buytolet: "Buy to Let",
    commercial: "Commercial",
    holidayhome: "Holiday Home",
    housefamilymember: "Housing for Family Member"
  },
  ownershipTypes: {
    sole: "Sole Ownership",
    joint_tenants: "Joint Tenants",
    tenants_in_common: "Tenants in Common",
    unsure: "Unsure"
  },
  otherAssetTypes: {
    expectedinheritance: "Potential Inheritance",
    specificitem: "Specific Item (ie. Painting, Watch etc)",
    other: "Other"
  },
  ownedTypes: {
    primary: "Just Me",
    secondary: "My Partner",
    both: "Both of us"
  },
  howOwnedTypes: {
    primary: "Owned by Me",
    secondary: "Owned by my Partner",
    both: "Owned by Both of us",
    primary_others: "Owned by Me & Others",
    secondary_others: "Owned by my Partner & Others",
    both_others: "Owned by Both of us & Others"
  },
  fileTypes: {
    "identity-passport": "Identity A: Passport",
    "identity-dl": "Identity A: Drivers Licence",
    "identity-utility": "Identity B: Utility Bill",
    policy: "Policy or Investment Document",
    funeral: "Funeral Plan",
    propertydeed: "Property Deed",
    other: "Other Document"
  },
  fileLocations: {
    "legal-before": "Legal > Before-death Documents",
    "legal-after": "Legal > Will & Related Documents",
    "legal-other": "Legal > Other Documents",
    "assets-other": "Assets > Other",
    contacts: "Contacts > Other",
    "admin-utilities": "Life Admin > Utilities",
    "admin-vehicles": "Life Admin > Vehicles",
    "admin-contractors": "Life Admin > Contractors",
    "admin-pets": "Life Admin > Pets",
    "admin-work": "Life Admin > Work",
    "admin-other": "Life Admin > Other",
    "care-plan": "Advance Care Plan",
    "after-death-funeral": "After Death Wishes > Funeral Plans",
    "after-death-personal": "After Death Wishes > Personal Notes",
    "after-death-other": "After Death Wishes > Other Requests",
    other: "Misc > Other"
  },
  filepolicytypes: {
    "life-insurance": "Life Insurance",
    "critical-illness": "Critical Illness Insurance ",
    "income-protection": "Income Protection Insurance",
    pension: "Pensions",
    investment: "Investments",
    isa: "ISAs",
    "other-policy": "Other Policy",
    menu_plan: "Menu Plan"
  },
  ifPredeceasedTypes: {
    surviving: "Surviving Beneficiaries",
    theirconcern: "Their Concern / Issue",
    other: "Other"
  },
  ifPredeceasedGiftTypes: {
    theirconcern: "Their Concern / Issue",
    nosubstitution: "No Substitution",
    other: "Other"
  },
  classGiftTypes: {
    mychildren: "My Children",
    mygrandchildren: "My Grand Children",
    mystepchildren: "My Step Children",
    myneicesandnephews: "My Nieces and Nephews",
    mynephews: "My Nephews",
    myneices: "My Nieces",
    mybrothersandsisters: "My Brothers and Sisters",
    mygodchildren: "My Godchildren",
    myparents: "My Parents",
    mygreatgrandchildren: "My Great-Grandchildren",
    mygreatgreatgrandchildren: "My Great-Great-Grandchildren",
    myparentsinlaw: "My Parents-inlaw",
    mybrothers: "My Brothers",
    mysisters: "My Sisters",
    myhusbandsnephews: "My Husbands Nephews",
    myhusbandsneices: "My Husbands Nieces",
    myhusbandssisters: "My Husbands Sisters",
    myhusbandsbrothers: "My Husbands Brothers",
    mywifesnephews: "My Wifes Nephews",
    mywifesneices: "My Wifes Nieces",
    mywifessisters: "My Wifes Sisters",
    mywifesbrothers: "My Wifes Brothers",
    mypartnersneices: "My Partners Nieces",
    mypartnerssisters: "My Partners Sisters",
    mypartnersbrothers: "My Partners Brothers",
    mywifesneicesandnephews: "My Wifes Nieces and Nephews",
    myhusbandsneicesandnephews: "My Husbands Nieces and Nephews",
    mysons: "My Sons",
    mydaughters: "My Daughters",
    mystepsons: "My Step-Sons",
    mystepdaughters: "My Step-Daughters",
    mypartnerssons: "My Partners Sons",
    mypartnersdaughters: "My Partners Daughters",
    mygrandchildrenandmystepgrandchildren: "My Grandchildren and my Step-Grandchildren",
    mychildrenandmystepchildren: "My Children and my Step-Children",
    myfosterchildren: "My Foster Children",
    myhusbandsgrandchildren: "My Husbands Grandchildren",
    mywifesgrandchildren: "My Wifes Grandchildren",
    myspousesgrandchildren: "My Spouses Grandchildren",
    mychildrensgodchildren: "My Childrens Godchildren"
  },
  relationship: {
    "": "Please Confirm",
    brother: "Brother",
    sister: "Sister",
    father: "Father",
    mother: "Mother",
    son: "Son",
    daughter: "Daughter",
    wife: "Wife",
    husband: "Husband",
    partner: "Partner",
    fiance: "Fiancé",
    fiancee: "Fiancée",
    relative: "Relative",
    friend: "Friend",
    neighbour: "Neighbour",
    acquaintance: "Acquaintance",
    adviser: "Adviser",
    charity: "Charity",
    accountant: "Accountant",
    aunt: "Aunt",
    "brother-in-law": "Brother-in-law",
    "brothers-partner": "Brother's Partner",
    "business-partner": "Business Partner",
    boyfriend: "Boyfriend",
    girlfriend: "Girlfriend",
    carer: "Carer",
    "civil-partner": "Civil Partner",
    councilor: "Councilor",
    cousin: "Cousin",
    doctor: "Doctor",
    "daughter-in-law": "Daughter-in-law",
    "daughters-partner": "Daughter’s Partner",
    "estranged-husband": "Estranged Husband",
    "estranged-wife": "Estranged Wife",
    "ex-brother-in-law": "Ex Brother-in-law",
    "ex-daughter-in-law": "Ex Daughter-in-law",
    "ex-husband": "Ex Husband",
    "ex-sister-in-law": "Ex Sister-in-law",
    "ex-son-in-law": "Ex Son-in-law",
    "ex-wife": "Ex Wife",
    executor: "Executor",
    "ex-partner": "Ex-Partner",
    "father-in-law": "Father-in-law",
    "financial-adviser": "Financial Adviser",
    "foster-daughter": "Foster Daughter",
    "foster-son": "Foster Son",
    goddaughter: "God Daughter",
    "goddaughters daughter": "God Daughter’s Daughter",
    "goddaughters son": "God Daughter’s Son",
    godfather: "God Father",
    godmother: "God Mother",
    godson: "God Son",
    "godsons-daughter": "God Son’s Daughter",
    "godsons-son": "God Son’s Son",
    granddaughter: "Granddaughter",
    grandfather: "Grandfather",
    grandmother: "Grandmother",
    grandson: "Grandson",
    "great-granddaughter": "Great Granddaughter",
    "great-grandson": "Great Grandson",
    "great-nephew": "Great Nephew",
    "great-niece": "Great Niece",
    "half-brother": "Half Brother",
    "half-sister": "Half Sister",
    "half-brother-in-law": "Half-Brother-in-law",
    "half-sister-in-law": "Half-Sister-in-law",
    "husbands-aunt": "Husbands Aunt",
    "husbands-brother-in-law": "Husbands Brother-in-law",
    "husbands-cousin": "Husbands Cousin",
    "husbands-daughter": "Husbands Daughter",
    "husbands-daughter-in-law": "Husbands Daughter-in-law",
    "husbands-ex-partner": "Husbands Ex-Partner",
    "husbands-ex-wife": "Husbands Ex-Wife",
    "husbands-friend": "Husbands Friend",
    "husbands-goddaughter": "Husbands God Daughter",
    "husbands-godson": "Husbands God Son",
    "husbands-nephew": "Husbands Nephew",
    "husbands-nephews-daughter": "Husbands Nephew’s Daughter",
    "husbands-nephews-son": "Husbands Nephew’s Son",
    "husbands-niece": "Husbands Niece",
    "husbands-nieces-son": "Husbands Niece’s Son",
    "husbands-nieces-daughter": "Husbands Niece’s Daughter",
    "husbands-sister-in-law": "Husbands Sister-in-law",
    "husbands-son": "Husbands Son",
    "husbands-son-in-law": "Husbands Son-in-law",
    "husbands-stepbrother": "Husbands Stepbrother",
    "husbands-stepsister": "Husbands Stepsister",
    "husbands-uncle": "Husbands Uncle",
    "mother-in-law": "Mother-in-law",
    nephew: "Nephew",
    "nephew-in-law": "Nephew-in-law",
    "nephews-husband": "Nephew’s Husband",
    "nephews-wife": "Nephew’s Wife",
    niece: "Niece",
    "niece-in-law": "Niece-in-law",
    "nieces-husband": "Niece’s Husband",
    "partners-aunt": "Partner’s Aunt",
    "partners-brother": "Partner’s Brother",
    "partners-brother-in-law": "Partner’s Brother-in-law",
    "partners-cousin": "Partner’s Cousin",
    "partners-daughter": "Partner’s Daughter",
    "partners-daughter-in-law": "Partner’s Daughter-in-law",
    "partners-ex-husband": "Partner’s Ex Husband",
    "partners-ex-wife": "Partner’s Ex Wife",
    "partners-ex-daughter-in-law": "Partner’s Ex-Daughter-in-law",
    "partners-father": "Partner’s Father",
    "partners-friend": "Partner’s Friend",
    "partners-god-daughter": "Partner’s God Daughter",
    "partners-god-son": "Partner’s God Son",
    "partners-granddaughter": "Partner’s Granddaughter",
    "partners-grandson": "Partner’s Grandson",
    "partners-great nephew": "Partner’s Great Nephew",
    "partners-great niece": "Partner’s Great Niece",
    "partners-mother": "Partner’s Mother",
    "partners-nephew": "Partner’s Nephew",
    "partners-niece": "Partner’s Niece",
    "partners-sister": "Partner’s Sister",
    "partners-sister-in-law": "Partner’s Sister-in-law",
    "partners-son": "Partner’s Son",
    "partners-son-in-law": "Partner’s Son-in-law",
    "partners-step-granddaughter": "Partner’s Step Granddaughter",
    "partners-step-grandson": "Partner’s Step Grandson",
    "partners-stepdaughter": "Partner’s Stepdaughter",
    "partners-stepfather": "Partner’s Stepfather",
    "partners-stepmother": "Partner’s Stepmother",
    "partners-stepson": "Partner’s Stepson",
    "partners-uncle": "Partner’s Uncle",
    "second cousin": "Second Cousin",
    "sister-in-law": "Sister-in-law",
    "sisters-partner": "Sister’s Partner",
    "social-worker": "Social worker",
    solicitor: "Solicitor",
    "son-in-law": "Son-in-law",
    "sons-partner": "Son’s Partner",
    spouse: "Spouse",
    "step-cousin": "Step Cousin",
    "step-daughters-partner": "Step Daughter’s Partner",
    "step-granddaughter": "Step Granddaughter",
    "step-grandson": "Step Grandson",
    "step-half-brother": "Step Half Brother",
    "step-half-sister": "Step Half Sister",
    "step-nephew": "Step Nephew",
    "step-niece": "Step Niece",
    "step-brother": "Stepbrother",
    "step-daughter": "Stepdaughter",
    "step-daughter-in-law": "Stepdaughter-in-law",
    "step-father": "Stepfather",
    "step-father-in-law": "Stepfather-in-law",
    "step-mother": "Stepmother",
    "step-mother-in-law": "Stepmother-in-law",
    "step-sister": "Stepsister",
    "step-son": "Stepson",
    "step-sons-partner": "Stepson’s Partner",
    "step-son-in-law": "Stepson-in-law",
    teacher: "Teacher",
    uncle: "Uncle",
    "wifes-aunt": "Wife's Aunt",
    "wifes-brother": "Wife's Brother",
    "wifes-brother-in-law": "Wife's Brother-in-law",
    "wifes-cousin": "Wife's Cousin",
    "wifes-daughter": "Wife's Daughter",
    "wifes-daughter-in-law": "Wife's Daughter-in-law",
    "wifes-ex-husband": "Wife's Ex Husband",
    "wifes-friend": "Wife's Friend",
    "wifes-god-son": "Wife's God Son",
    "wifes-god-daughter": "Wife's Goddaughter",
    "wifes-nephew": "Wife's Nephew",
    "wifes-nephews-daughter": "Wife's Nephew's Daughter",
    "wifes-nephews-son": "Wife's Nephew's Son",
    "wifes-niece": "Wife's Niece",
    "wifes-nieces-daughter": "Wife's Niece's Daughter",
    "wifes-nieces-son": "Wife's Niece's Son",
    "wifes-sister": "Wife's Sister",
    "wifes-sister-in-law": "Wife's Sister-in-law",
    "wifes-son": "Wife's Son",
    "wifes-son-in-law": "Wife's Son-in-law",
    "wifes-step-brother": "Wife's Stepbrother",
    "wifes-step-sister": "Wife's Stepsister",
    "wifes-uncle": "Wife's Uncle"
  },
  kintinAccessTypes: {
    owner: "Owner",
    edit: "Edit",
    readonly: "Read Only",
    adviser: "Adviser"
  },
  granularityTypes: {
    day: "Day",
    week: "Week",
    month: "Month",
    quarter: "Quarter",
    year: "Year"
  }
});
let h = n;
export {
  h as Dictionary
};
