<template>
  <div class="columns account-referral-code-details-summary">
    <div class="column">
      <DataListWidget title="Summary" :details="codeSummary" />
    </div>
    <div class="column">
      <ReferralQRCode
        :title="referralCode?.name"
        :code="referralCode?.code ?? ''"
      />
    </div>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AccountReferralCodeDetailsSummaryWrapper",
  "route": "AccountReferralCodeDetailsSummary",
  "selector": ".account-referral-code-details-summary"
}
</cypress-wrapper>

<script lang="ts">
import { IntroducerCompanyDetailsReferralsRoute } from "@/module/introducer.company/page/details/referrals";
import { AuthService } from "@/service/auth.service";
import ReferralQRCode from "@/shared/component/misc/ReferralQRCode.vue";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import { AccountReferralCodeDetailsSummaryRoute } from ".";
import { AccountReferralCodeDetailsMixin } from "../../../mixin/account-referral-code-details.mixin";

export default defineComponent({
  name: AccountReferralCodeDetailsSummaryRoute,
  components: {
    DataListWidget,
    ReferralQRCode,
  },
  mixins: [
    AuthService.mixin({ sessionRequired: true }),
    AccountReferralCodeDetailsMixin,
  ],
  computed: {
    codeSummary(): DataListWidgetDetail[] {
      if (!this.referralCode) return [];
      return [
        {
          label: "Name",
          value: this.referralCode.name,
        },
        {
          label: "Code",
          value: this.referralCode.code,
        },
        {
          label: "Company",
          hide: !this.referralCode.company,
          value: {
            text:
              this.referralCode.company?.profile?.organisationName ?? "None",
            to: {
              name: IntroducerCompanyDetailsReferralsRoute,
              params: {
                introducerCompany: this.referralCode.company?.$data.id,
              },
            },
          },
        },
        {
          label: "Category",
          value: this.referralCode.category?.text,
        },
        {
          label: "Is For Users",
          value: this.referralCode.isForUsers ? "Yes" : "No",
        },
        {
          label: "Referral Count @todo: check this",
          value: this.referralCode.$data.referralCount.toString(),
        },
        {
          label: "Sacrificing Fees",
          value: this.referralCode.isFeeSacrifice ? "Yes" : "No",
        },
        {
          label: "Salary Sacrifice",
          value: this.referralCode.isSalarySacrifice ? "Yes" : "No",
        },
        {
          label: "Friends & Family",
          value: this.referralCode.isFriendsAndFamily ? "Yes" : "No",
        },
        {
          label: "Notes",
          value: this.referralCode.notes ?? "None",
        },
        {
          label: "Created at",
          value: this.referralCode.createdAt.formatDateTime,
        },
      ];
    },
  },
});
</script>
