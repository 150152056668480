// MfAuthMethod

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Credential } from "@kinherit/sdk/model/credential";
import { MfAuthMethod } from "@kinherit/sdk/model/mf-auth-method";

export const ReadMfAuthMethod = DefineReadAction({
  interface: "core",
  resource: "/v2/core/user/{user}/mf-auth-methods/{credential}/mf-auth-methods",
  method: "get",
  rootModel: MfAuthMethod,
  parse: (credential: Credential) => ({
    params: {
      credential,
      user: credential.user!,
    },
  }),
});
