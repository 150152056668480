// FileLog

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KnowledgeBaseDocument } from "@kinherit/sdk";
import { FileLog } from "@kinherit/sdk/model/file-log";

export const UpdateFileLog = DefineWriteAction({
  interface: "portal",
  resource:
    "/v2/portal/knowledge-base/category/document/file-log/{knowledgeBaseDocument}/document/{fileLog}",
  method: "patch",
  parse: ({
    fileLog,
    knowledgeBaseDocument,
  }: {
    fileLog: FileLog;
    knowledgeBaseDocument: KnowledgeBaseDocument;
  }) => ({
    body: {
      tab: fileLog.$data.tab,
      fileName: fileLog.$data.fileName,
      s3Location: fileLog.$data.s3Location,
      fileExtension: fileLog.$data.fileExtension,
      fileSize: fileLog.$data.fileSize,
      notes: fileLog.$data.notes,
      isRichText: fileLog.$data.isRichText,
      email: fileLog.$data.email,
      campaignLog: fileLog.$data.campaignLog,
      kintin: fileLog.$data.kintin,
      form: fileLog.$data.form,
      type: fileLog.$data.type,
      subType: fileLog.$data.subType,
      businessAsset: fileLog.$data.businessAsset,
      cashDebt: fileLog.$data.cashDebt,
      debt: fileLog.$data.debt,
      investment: fileLog.$data.investment,
      otherAsset: fileLog.$data.otherAsset,
      pension: fileLog.$data.pension,
      policy: fileLog.$data.policy,
      property: fileLog.$data.property,
      knowledgeBaseDocument: fileLog.$data.knowledgeBaseDocument,
      reminder: fileLog.$data.reminder,
    },
    params: {
      fileLog: fileLog,
      knowledgeBaseDocument: knowledgeBaseDocument,
    },
  }),
});
