var r = Object.defineProperty;
var i = (t, a) => r(t, "name", { value: a, configurable: !0 });
import { FieldMixin as u } from "../../component.mixin/field-mixin.js";
import { CypressHelper as o } from "../../cypress/component-helpers.js";
import { ThemeColor as l } from "../../theme/prop/color.js";
import { ThemeIconName as n } from "../../theme/prop/icon.js";
import { defineComponent as s } from "vue";
import m from "../../_vue/component.input/base-input-field/base-input-field.js";
import h from "../../_vue/component.input/button/button.js";
import { S as p } from "../../_assets/component.input/number-field/number-field.module.js";
const F = s({
  name: "NumberFieldComponent",
  components: { Button: h, BaseInputField: m },
  mixins: [u()],
  props: {
    /**
     * Color of the plus button
     */
    plusColor: {
      type: String,
      default: "is-light-grey",
      validator: /* @__PURE__ */ i((t) => Object.values(l).includes(t), "validator"),
      value: l
    },
    /**
     * Color of the minus button
     */
    minusColor: {
      type: String,
      default: "is-light-grey",
      validator: /* @__PURE__ */ i((t) => Object.values(l).includes(t), "validator"),
      value: l
    },
    /**
     * v-model:value
     *
     * @values null, number
     */
    value: {
      type: Number,
      default: null
    },
    /**
     * Minimum value
     */
    min: {
      type: Number,
      default: -1 / 0
    },
    /**
     * Maximum value
     */
    max: {
      type: Number,
      default: 1 / 0
    },
    /**
     * Step to change value by
     */
    step: {
      type: Number,
      default: null
    },
    /**
     * Hide control buttons
     */
    hideControls: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["blur", "update:value"],
  data: /* @__PURE__ */ i((t) => ({
    stringValue: "",
    icon: {
      chevronLeft: n.ChevronLeft,
      chevronRight: n.ChevronRight
    },
    $cypress: o({ ctx: t, labelField: "label" })
  }), "data"),
  computed: {
    compClassMaxLength() {
      return this.max !== null && this.max !== 1 / 0 ? `is-maxlength-${this.max.toString().length}` : null;
    },
    computedValue: {
      get() {
        const t = this.stringValue;
        return [null, ""].includes(t) ? this.value : Number.parseFloat(t) !== Number.parseFloat(this.value) ? Number.parseFloat(this.value) : t;
      },
      set(t) {
        if (this.stringValue = (t == null ? void 0 : t.toString()) ?? "", [null, ""].includes(t)) {
          this.$emit("update:value", null), this.validate(null);
          return;
        }
        t = Number.parseFloat(t.toString()), this.$emit("update:value", t), this.validate(t);
      }
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("number-field", p);
  },
  methods: {
    increase() {
      let t = this.computedValue === null ? 1 : this.computedValue.toString().toFloat() + 1;
      t = Math.min(t, this.max), t = Math.max(t, this.min), this.computedValue = t;
    },
    decrease() {
      let t = this.computedValue === null ? 0 : this.computedValue.toString().toFloat() - 1;
      t = Math.min(t, this.max), t = Math.max(t, this.min), this.computedValue = t;
    },
    blur(t) {
      this.$emit("blur", t);
      const a = this.computedValue;
      let e = this.computedValue;
      e !== null && (e = Math.min(e.toString().toFloat(), this.max), e = Math.max(e, this.min), this.$emit("update:value", e), this.validate(e), a !== e && this.$nextTick(() => {
        this.$forceUpdate();
      }));
    }
  }
});
export {
  F as _
};
