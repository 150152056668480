import {
  KnowledgeBaseCategoryDetailsRoute,
  KnowledgeBaseDocumentDetailsRoute,
  KnowledgeBaseTopLevelCategoryDetailsRoute,
} from "@/module/knowledge-base/page";
import { KnowledgeBaseCategory, KnowledgeBaseDocument } from "@kinherit/sdk";

export const KnowledgeBaseCategoryMasterListBreadCrumb = {
  text: "Knowledge Base",
  route: { name: "KnowledgeBase" },
};
export const KnowledgeBaseTopLevelCategoryBreadCrumb = (params: any) => ({
  text: KnowledgeBaseCategory.$findOne(params.topLevelCategory)?.title ?? "",
  route: { name: KnowledgeBaseTopLevelCategoryDetailsRoute, params },
});

export const KnowledgeBaseCategoryBreadCrumb = (params: any) => ({
  text: KnowledgeBaseCategory.$findOne(params.category)?.title ?? "",
  route: { name: KnowledgeBaseCategoryDetailsRoute, params },
});

export const KnowledgeBaseDocumentBreadCrumb = (params: any) => ({
  text: KnowledgeBaseDocument.$findOne(params.document)?.title ?? "",
  route: { name: KnowledgeBaseDocumentDetailsRoute, params },
});
