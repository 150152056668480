// Subscription, Kintin, Product

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { PhysicalStorageSubscription } from "@kinherit/sdk";

export const ReadSubscription = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/physical-storage-subscriptions",
  method: "get",
  rootModel: PhysicalStorageSubscription,
  parse: () => ({}),
});
