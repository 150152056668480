import { Api, Kintin, SignDoc } from "@kinherit/sdk";

// SignDoc
export const GenerateDisclaimer = async (message: {
  signDoc: SignDoc;
  kintin: Kintin | string;
}) => {
  const { signDoc, kintin } = message;

  await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/sign-doc/sign-docs/{signDoc}/disclaimer",
    {
      kintin: kintin,
      signDoc: signDoc,
    },
  )
    .method("put")
    .result();

  message.signDoc.$delete();

  return {
    signDoc,
  };
};
