var o = Object.defineProperty;
var u = (t, r) => o(t, "name", { value: r, configurable: !0 });
import { FieldMixin as a } from "../../component.mixin/field-mixin.js";
import { CypressHelper as p } from "../../cypress/component-helpers.js";
import { convertColor as e, removeOpacity as l, setOpacity as i, getOpacity as s } from "@kinherit/ts-common/service/colors";
import { defineComponent as m } from "vue";
import n from "../../_vue/component.input/base-input-field/base-input-field.js";
const g = m({
  name: "ColorPickerFieldComponent",
  components: { BaseInputField: n },
  mixins: [a()],
  props: {
    value: {
      type: String,
      default: null,
      value: "color"
    },
    outputFormat: {
      type: String,
      default: null,
      value: ["hex", "hexa", "hsl", "rgb", "rgba", "hsla"]
    }
  },
  emits: ["blur", "update:value"],
  data: /* @__PURE__ */ u((t) => ({
    $cypress: p({ ctx: t, labelField: "label" })
  }), "data"),
  computed: {
    computedColor: {
      get() {
        return this.value === null ? null : e({
          color: this.value,
          target: "hex"
        });
      },
      set(t) {
        if (t === null) {
          this.$emit("update:value", null);
          return;
        }
        if (this.computedHasOpacity) {
          this.$emit(
            "update:value",
            e({
              color: i(t, this.computedOpacity),
              target: this.computedFullType
            })
          );
          return;
        }
        this.$emit(
          "update:value",
          e({
            color: l(t),
            target: this.computedFullType
          })
        );
      }
    },
    computedOpacity: {
      get() {
        return this.value === null ? 1 : s(this.value);
      },
      set(t) {
        this.value === null && this.$emit(
          "update:value",
          e({
            color: l(this.value),
            target: this.computedFullType
          })
        ), this.$emit(
          "update:value",
          e({
            color: i(this.value, t),
            target: this.computedFullType
          })
        );
      }
    },
    computedHasOpacity() {
      return this.computedFullType === "rgba" || this.computedFullType === "hsla";
    },
    computedFullType() {
      return this.outputFormat ? this.outputFormat : !this.value || this.value.startsWith("#") ? "hex" : this.value.substring(0, this.value.indexOf("("));
    },
    computedType() {
      const t = this.computedFullType;
      return t === "rgba" || t === "hsla" ? t.substring(0, 3) : t;
    }
  }
});
export {
  g as _
};
