// IntroducerFeePaymentRun, IntroducerFeePayment, IntroducerFeePaymentItem

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerFeePaymentRun } from "@kinherit/sdk/model/introducer-fee-payment-run";

export const ReadIntroducerFeePaymentRun = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/fees/payment-run",
  method: "get",
  rootModel: IntroducerFeePaymentRun,
  parse: () => ({}),
});
