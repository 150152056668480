<template>
  <div v-if="kintin">
    <CallScriptSection title="Main Beneficiaries">
      <Message title="Reminders" title-size="is-4" class="mb-4">
        <ul class="mt-0 mb-0">
          <li v-if="checkIsYes('2.5.0b')">
            Has children from prior relationship.
          </li>
          <li v-if="checkIsYes('3.0.0a')">Owns own property</li>
          <li v-if="checkIsYes('3.0.0d')">
            Someone else lives in the main property
          </li>
          <li v-if="checkIsNo('3.0.0d')">
            No one else lives in the main property
          </li>
        </ul>
      </Message>
      <b> OK so let’s talk about where your estate should go. </b>
      <br /><br />
      <b
        >We’ll come to specific gifts later, and we’ll also need to check how
        your assets are owned.
      </b>
      <br /><br />
      <b
        >But for now and in simple terms – when you die, who do you want the
        main beneficiary to be?
      </b>
      <br /><br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1i"
        is-reversed
        :yes-label="isJoint ? 'Partner then Children' : 'Yes'"
        :no-label="isJoint ? 'Other' : 'No'"
        :on="{
          yes: {
            action: [
              'Enter Details: Take particular care where kids from prior relationship, to set 1st/second line etc.',
            ],
          },
        }"
        @update="updateCheckValue"
      /><br />
      <span v-if="isJoint && checkIsNo('4.3.1i')">
        <b>
          Ok, so, {{ primarySalutation }} if you die, what happens to your
          assets? <br /><em>[Add Person 1 beneficiary]</em>
        </b>
        <br /><br />
        <b>
          And {{ secondarySalutation }} if you die, what happens to your assets?
          <br /><em>[Add Person 2 Beneficiary]</em>
        </b>
        <br /><br />
        <b> And finally, when you both die. What happens? </b>
        <br /><em>[Add second line beneficiary]</em>
      </span>
      <span v-if="!isJoint">
        <b>
          So when you die, who would be the main beneficiary of your estate?
        </b>
        <br /><em>[Add beneficiary]</em>
      </span>
      <br /><br />
      <BeneficiariesContainer :kintin="kintin" />
      <br />
      <span>
        <em>
          [Specialist reconfirm the choices here to make sure we have them right
          and then ask]
        </em>
        <br />
        <b>
          Now, we have to cover a scenario where one of your (reserve)
          beneficiaries dies before you do. Normally, their share would go to
          their children, although if you’d prefer we could redistribute it to
          other beneficiaries
        </b>
        <br />
        <br />
      </span>
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSection title="Right to Reside and Ownership complexity">
      <Message title="Reminders" title-size="is-4" class="mb-4">
        <ul class="mt-0 mb-0">
          <li v-if="checkIsYes('2.5.0b')">
            Has children from prior relationship
          </li>
          <li v-if="checkIsYes('3.0.0a')">Owns own property</li>
          <li v-if="checkIsYes('3.0.0d')">
            Someone else lives in the main property
          </li>
          <li v-if="checkIsNo('3.0.0d')">
            No one else lives in the main property
          </li>
        </ul>
      </Message>
      <b
        >One of the more common things we're seeing in wills is a Right to
        Reside Clause.</b
      >
      <b v-if="checkIsYes('3.0.0d') || checkIsYes('2.5.0b')">
        You have mentioned someone is living at your home/you have children from
        a previous relationship. If you were to die, would you want them to stay
        in the family home?
      </b>
      <br />
      <br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        is-reversed
        step="4.3.1a"
        :on="{
          yes: {
            action: [
              'Add Note detailing how R2R clause might work (eg live there for X years / get £Y or Z% of property value if/when its sold)',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <b v-if="checkIsNo('4.3.1a')">
        <br />
        <br />
        It sounds like this isn't relevant, so we can probably skip this - but
        do shout if there's anything we've missed.
      </b>
      <span v-if="checkIsYes('4.3.1a')">
        <b>
          So just to confirm, a right to reside clause can be used to allow a
          non-beneficiary to stay in a property after the owner dies.
          <br /><br />
          It needs a trust but can be really quite flexible – depending how long
          you want it to exist, and maybe allow things like downsizing, and with
          a conditions like the payment of bills.
          <br /><br />
          So thinking about your situation – how would you like it to work?
        </b>
        <br /><em
          >[Discuss Situation and add notes] <br />[Legacy Requires:]
          <br /><br />A. Is the property the client's main residence <br />B.
          Who is the tenant <br />C. Is it a <br />
          - a. Right for life [DEFAULT] <br />
          - b. or defined years <br />
          - c. or until specified age [NB this seems a weird one as don't know
          when testator will die] <br />D. Should tenant be allowed to
          move/downsize <br />
          - a. If Yes – how does excess get treated? Ie for use by tenant or
          tobeneficiaries per will [DEFAULT=YES] <br />E. Does right end if
          cohabiting or remarry? [DEFAULT NO] <br />F. When right ends, is
          property to be treated <br />
          - a. as per original Will [DEFAULT] <br />
          - b. go to newly specified people</em
        >
        <br /><br />
      </span>
      <b v-if="checkIsYes('3.0.0c') && checkIsYes('4.3.1a')">
        Regarding the co-owners of your home, have you thought about what might
        happen?
      </b>
      <b v-if="!checkIsYes('3.0.0c') && checkIsYes('4.3.1a')">
        And just to confirm, you said there were no other owners.
      </b>
      <ConfirmCheck
        v-if="checkIsYes('4.3.1a')"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        is-reversed
        step="4.3.1d"
        :on="{
          yes: {
            action: [
              'Explain they need to think It through and get comfortable with the risks. Add notes',
            ],
          },
        }"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSection title="Inheritance before 18-25">
      <em>[If any beneficiaries could be under 25 at time of receipt]</em><br />
      <b>
        Legally, people cannot inherit until they turn 18, Often people think
        that’s a bit young.
      </b>
      <br /><em>[discuss]</em> <br /><br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1e"
        is-reversed
        @update="updateCheckValue"
      />
      <NumberField
        v-if="checkIsYes('4.3.1e')"
        v-model:value="callScript.beneficiaryAge"
        label="Inheritance Age"
        :min="18"
      />
      <Button
        v-if="checkIsYes('4.3.1e')"
        text="Save"
        color="is-positive"
        class="mt-2 mb-2"
        @click="saveInheritanceAgeHandler"
      />
      <br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1f"
        is-reversed
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1g"
        is-reversed
        @update="updateCheckValue"
      />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1h"
        is-reversed
        :on="{
          yes: {
            say: [
              'Travel typically needs an Estate Protection trust, but I can put a flag down and we can come back to that later.',
            ],
            action: ['Add note so precise language can be checked later'],
          },
        }"
        @update="updateCheckValue"
      />
      <br />
      <ConfirmCheck
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.2"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <!-- <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advBeneficiaryNotes"
      :has-continue="false"
    /> -->
  </div>
</template>

<script lang="ts">
import BeneficiariesContainer from "@/module/kinvault.kintin/component/address-book/BeneficiariesContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
// import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { KintinDetailsCallScript2Call2BeneficiariesRoute } from "@/module/kinvault.kintin/page/details/call-script-2";
import Message from "@kinherit/framework/component.display/message";
import Button from "@kinherit/framework/component.input/button";
import NumberField from "@kinherit/framework/component.input/number-field";
import { defineComponent } from "vue";

export default defineComponent({
  name: KintinDetailsCallScript2Call2BeneficiariesRoute,
  components: {
    BeneficiariesContainer,
    CallScriptSection,
    ConfirmCheck,
    // CallScriptSpecialistNotesSection,
    NumberField,
    Message,
    Button,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  emits: { "save-callscript": null },
  methods: {
    async saveInheritanceAgeHandler() {
      this.$emit("save-callscript");
    },
  },
});
</script>
