var g = Object.defineProperty;
var a = (e, t) => g(e, "name", { value: t, configurable: !0 });
import { _ as b } from "../../../component.input/drop-down-menu/drop-down-menu.vue_vue_type_script_lang.js";
import { resolveComponent as d, resolveDirective as $, createElementBlock as s, openBlock as l, mergeProps as C, createCommentVNode as n, createElementVNode as i, createVNode as B, normalizeClass as h, Fragment as S, renderList as L, withDirectives as M, createBlock as c, renderSlot as _, toDisplayString as r } from "vue";
import { b as k } from "../../../_assets/component.input/drop-down-menu/drop-down-menu.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as D } from "../../../_plugins/_plugin-vue_export-helper.js";
const z = {
  key: 0,
  class: "dropdown-menu__close-button"
}, N = ["data-tracking", "onClick"], E = { class: "level-left" }, F = {
  key: 0,
  class: "level-item"
}, I = {
  key: 1,
  class: "level-item"
}, R = {
  key: 0,
  class: "dropdown-menu__item__title"
}, V = {
  key: 1,
  class: "dropdown-menu__item__line1"
}, O = {
  key: 2,
  class: "dropdown-menu__item__line2"
}, j = {
  key: 0,
  class: "level-right"
}, P = {
  key: 0,
  class: "dropdown-menu__item level"
}, q = { class: "level-item" }, A = { class: "level-left" }, G = {
  key: 1,
  class: "dropdown-menu__divider"
}, H = { class: "level-item" }, J = { class: "level-left" };
function K(e, t, Q, T, U, W) {
  const f = d("Button"), w = d("CheckboxField"), u = d("Icon"), y = $("tab-index");
  return l(), s("div", C({
    class: [
      {
        "dropdown-menu": !0,
        "hide-shadow": e.isStatic,
        "is-scrollable": e.isScrollable,
        "is-block": e.show,
        "is-static": e.isStatic
      },
      e.size
    ],
    role: "menu"
  }, e.$data.$cypress), [
    e.showCloseButton || e.keyboardNavigation() ? (l(), s("div", z, [
      B(f, {
        class: "js-close-button",
        "icon-left": "Close",
        "aria-label": e.$t.local.closeButtonLabel,
        color: "is-plain",
        onClick: t[0] || (t[0] = (o) => e.$emit("close"))
      }, null, 8, ["aria-label"])
    ])) : n("", !0),
    i("div", {
      class: h({
        "dropdown-menu__content": !0,
        "is-scrollable": e.isScrollable
      })
    }, [
      (l(!0), s(S, null, L(e.computedOptions, (o, p) => {
        var m, v;
        return M((l(), s("div", {
          key: `item-${p}`,
          ref_for: !0,
          ref: "refMenuItem",
          class: h(["dropdown-menu__item level", {
            "is-selected": e.isSelected(o),
            "force-background": e.forceBackground && e.isSelected(o),
            [((v = e.rowClasses) == null ? void 0 : v.call(
              e,
              (m = e.optionsMixin.normalized.findBy("computed.value", o.value)) == null ? void 0 : m.original
            )) ?? ""]: !0
          }]),
          "data-tracking": o.value,
          role: "button",
          onClick: /* @__PURE__ */ a((X) => e.onClick(o), "onClick")
        }, [
          i("div", E, [
            e.showCheckbox || o.iconLeft || e.$slots.left ? (l(), s("div", F, [
              e.showCheckbox ? (l(), c(w, {
                key: 0,
                type: "checkbox",
                value: e.isSelected(o),
                style: { "pointer-events": "none" },
                size: "is-small"
              }, null, 8, ["value"])) : n("", !0),
              o.iconLeft ? (l(), c(u, {
                key: 1,
                class: "dropdown-menu__icon-left is-normal",
                icon: o.iconLeft
              }, null, 8, ["icon"])) : n("", !0),
              _(e.$slots, "left", { item: o })
            ])) : n("", !0),
            o.label || o.line1 || o.line2 ? (l(), s("div", I, [
              o.label ? (l(), s("header", R, r(o.label), 1)) : n("", !0),
              o.line1 ? (l(), s("small", V, r(o.line1), 1)) : n("", !0),
              o.line2 ? (l(), s("small", O, r(o.line2), 1)) : n("", !0)
            ])) : n("", !0)
          ]),
          o.iconRight || e.$slots.right && e.$slots.right.length > 0 ? (l(), s("div", j, [
            o.iconRight ? (l(), c(u, {
              key: 0,
              class: "dropdown-menu__icon-right is-normal",
              icon: o.iconRight
            }, null, 8, ["icon"])) : n("", !0),
            _(e.$slots, "right", { item: o })
          ])) : n("", !0)
        ], 10, N)), [
          [y, p]
        ]);
      }), 128)),
      e.computedOptions.length === 0 ? (l(), s("div", P, [
        i("div", q, [
          i("div", A, r(e.computedEmptyMessage), 1)
        ])
      ])) : n("", !0),
      e.showMoreButton && !e.isScrollable ? (l(), s("hr", G)) : n("", !0),
      e.showMoreButton && !e.isScrollable ? (l(), s("div", {
        key: 2,
        class: "dropdown-menu__item level mb-0 dropdown-menu__item-more",
        onClick: t[1] || (t[1] = (o) => e.$emit("more"))
      }, [
        i("div", H, [
          i("div", J, r(e.$t.local.moreButtonLabel), 1)
        ])
      ])) : n("", !0)
    ], 2)
  ], 16);
}
a(K, "_sfc_render");
typeof k == "function" && k(b);
const le = /* @__PURE__ */ D(b, [["render", K]]);
export {
  le as default
};
