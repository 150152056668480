import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";

interface ComputeTimeToFirstCallReportMessage {
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  compareTo: string | null;
  referrerCategory: string | null;
}

interface ComputeTimeToFirstCallReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string | number>;
  results: Array<{
    year: number;
    week: number;
    month: number;
    quarter: number;
    avgDaysDifference: number;
    minDaysDifference: number;
    maxDaysDifference: number;
    totalAppointments: number;
  }>;
}
export type TTimeToFirstCallReportResult = {
  year: number;
  week: number;
  month: number;
  quarter: number;
  avgDaysDifference: number;
  medianDaysDifference: number;
  minDaysDifference: number;
  maxDaysDifference: number;
  totalAppointments: number;
};
export const ComputeTimeToFirstCallReportHandler = async (
  message: ComputeTimeToFirstCallReportMessage,
): Promise<ComputeTimeToFirstCallReportResponse> => {
  const { year, created, granularity, compareTo, referrerCategory } =
    message || {};

  const calculatedDates = calculateStartEndDates(year, created, compareTo);

  const response = await Api.resource(
    "portal",
    "/v2/portal/report/time-to-first-call",
    {},
  )
    .method("get")
    .params({
      startDate: calculatedDates.startDate,
      endDate: calculatedDates.endDate,
      granularity: granularity,
      compareToStartDate: calculatedDates.compareToStartDate,
      compareToEndDate: calculatedDates.compareToEndDate,
      referrerCategory: referrerCategory,
    })

    .result();

  const datasets: {
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }[] = [];

  const coloursAvg = ["#0373fc", "#0256bd"];
  const coloursMedian = ["#d98e16", "#a36a11"];

  response.datasets.forEach((dataset: any, index: number) => {
    datasets.push({
      label: "Avg",
      stack: "Stack 1",
      backgroundColor: coloursAvg[index],
      data: dataset.datasets.map((i: any) => {
        return i.avgDaysDifference;
      }),
    });
  });

  response.datasets.forEach((dataset: any, index: number) => {
    datasets.push({
      label: "Median",
      stack: "Stack 2",
      backgroundColor: coloursMedian[index],
      data: dataset.datasets.map((i: any) => {
        return i.medianDaysDifference;
      }),
    });
  });

  return {
    datasets: datasets,
    labels: response.labels,
    results:
      response.datasets.length > 1
        ? mergeArrays(response.datasets.pluck("datasets"))
        : response.datasets[0].datasets,
  };
};
