var r = Object.defineProperty;
var o = (e, t) => r(e, "name", { value: t, configurable: !0 });
import { _ as l } from "../../../component.layout/page-header/page-header.vue_vue_type_script_lang.js";
import { resolveComponent as c, createElementBlock as s, openBlock as a, normalizeClass as m, createVNode as p, createCommentVNode as d, renderSlot as u } from "vue";
import { _ as i } from "../../../_plugins/_plugin-vue_export-helper.js";
const _ = {
  key: 0,
  class: "buttons"
};
function f(e, t, h, g, $, B) {
  const n = c("Title");
  return a(), s("header", {
    class: m(["page-header", e.compHasButtons ? "has-buttons" : null])
  }, [
    p(n, {
      text: e.text,
      htag: e.htag
    }, null, 8, ["text", "htag"]),
    e.compHasButtons ? (a(), s("div", _, [
      u(e.$slots, "buttons")
    ])) : d("", !0)
  ], 2);
}
o(f, "_sfc_render");
const v = /* @__PURE__ */ i(l, [["render", f]]);
export {
  v as default
};
