import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateWillRevision } from "./create-will-revision.action";
import { DeleteWillRevision } from "./delete-will-revision.action";
import { GeneratePdf } from "./generate-pdf.action";
import { ReadKintinChecks } from "./read-kintin-checks.action";
import { ReadWillData } from "./read-will-data.action";
import { ReadWillRevision } from "./read-will-revision.action";
import { RecordWillRevision } from "./record-will-revision.action";
import { UpdateWillRevision } from "./update-will-revision.action";

export const Portal2KinvaultWillRevision = DefineActionBus({
  name: "portal-kinvault-will-revision",
  actions: {
    CreateWillRevision,
    ReadWillRevision,
    UpdateWillRevision,
    RecordWillRevision,
    GeneratePdf,
    ReadWillData,
    DeleteWillRevision,
    ReadKintinChecks,
  },
});
