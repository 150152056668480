import { DefineCustomAction } from "@kinherit/framework/action-bus/actions/custom";
import { StripeCoupon } from "@kinherit/sdk";

export const SyncKinvaultPrices = DefineCustomAction({
  interface: "external",
  rootModel: StripeCoupon,
  resource: "/v2/external/cron-job/stripe/price",
  method: "put",
  parse: () => ({}),
});
