import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { DeleteOrder } from "./delete-order.action";
import { DownloadInvoice } from "./download-invoice.action";
import { PushOrderToXero } from "./push-order-to-xero.action";
import { RecordKintin } from "./record-kintin.action";
import { RecordOrder } from "./record-order.action";
import { ReplaceOrderItems } from "./replace-order-items.action";
import { UpdateOrder } from "./update-order.action";

export const Portal2Order = DefineActionBus({
  name: "portal-order",
  actions: {
    RecordKintin,
    UpdateOrder,
    RecordOrder,
    DownloadInvoice,
    PushOrderToXero,
    ReplaceOrderItems,
    DeleteOrder,
  },
});
