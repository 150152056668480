// IntroducerBillingContact, Profile, Address, PhoneNumber, EmailAddress, Website

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { IntroducerBillingContact } from "@kinherit/sdk/model/introducer-billing-contact";

export const RecordIntroducerBillingContact = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/billing-contacts/{introducerBillingContact}",
  method: "get",
  rootModel: IntroducerBillingContact,
  parse: (introducerBillingContact: IntroducerBillingContact | string) => ({
    params: {
      introducerBillingContact,
    },
  }),
});
