import {
  CreatedAtField,
  IntroducerCompanyField,
  KinvaultCouponField,
  KinvaultPriceField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";

export const ReadKinvaultSubscriptionReferralCodeForm = (): FormBuilder<
  () => {
    price: string[];
    coupon: string[];
    code: string;
    introducerCompany: string[];
    created: null | [number, number];
  },
  string
> => {
  return MasterListFiltersForm({
    name: "filter-kinvault-subscription-referral-code-form",
    data: () => ({
      price: [],
      coupon: [],
      code: "",
      introducerCompany: [],
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      code: [
        FormTextField({
          props: {
            label: "Code",
          },
          models: {
            value: "code",
          },
        }),
      ],
      price: [
        KinvaultPriceField({
          props: {
            label: "Price",
            vModel: "price",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      coupon: [
        KinvaultCouponField({
          props: {
            label: "Coupon",
            vModel: "coupon",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      introducerCompany: [
        IntroducerCompanyField({
          props: {
            label: "Introducer Company",
            vModel: "introducerCompany",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
