// EmailTemplate

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EmailTemplate } from "@kinherit/sdk/model/email-template";

export const ReadEmailTemplate = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/email-templates",
  method: "get",
  rootModel: EmailTemplate,
  parse: () => ({}),
});
