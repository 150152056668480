var n = Object.defineProperty;
var o = (t, s) => n(t, "name", { value: s, configurable: !0 });
import r from "../../_vue/component.display/icon/icon.js";
import p from "../../_vue/component.form/field/field.js";
import { FieldMixin as m } from "../../component.mixin/field-mixin.js";
import { CypressHelper as h } from "../../cypress/component-helpers.js";
import { defineComponent as c, ref as u } from "vue";
import { S as d } from "../../_assets/component.input/pin-field/pin-field.module.js";
const w = c({
  name: "PinFieldComponent",
  components: { Field: p, Icon: r },
  mixins: [m()],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  emits: ["blur", "submit"],
  setup() {
    const t = u(), s = u(), e = u(), i = u(), a = u(), l = u();
    return {
      input0: t,
      input1: s,
      input2: e,
      input3: i,
      input4: a,
      input5: l
    };
  },
  data: /* @__PURE__ */ o((t) => ({
    $cypress: h({ ctx: t, labelField: "label" })
  }), "data"),
  computed: {
    computedValue0: {
      get() {
        return this.getPin(0);
      },
      set(t) {
        this.setComputedValue(t, 0);
      }
    },
    computedValue1: {
      get() {
        return this.getPin(1);
      },
      set(t) {
        this.setComputedValue(t, 1);
      }
    },
    computedValue2: {
      get() {
        return this.getPin(2);
      },
      set(t) {
        this.setComputedValue(t, 2);
      }
    },
    computedValue3: {
      get() {
        return this.getPin(3);
      },
      set(t) {
        this.setComputedValue(t, 3);
      }
    },
    computedValue4: {
      get() {
        return this.getPin(4);
      },
      set(t) {
        this.setComputedValue(t, 4);
      }
    },
    computedValue5: {
      get() {
        return this.getPin(5);
      },
      set(t) {
        this.setComputedValue(t, 5);
      }
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("pin-field", d);
  },
  methods: {
    setComputedValue(t, s) {
      this.computedValue = this.setPin(t, s);
    },
    setPin(t, s) {
      const e = this.value || "";
      return (e.slice(0, s) + t + e.slice(s + 1)).slice(0, 6);
    },
    getPin(t) {
      return (this.computedValue || "").slice(t, t + 1) ?? "";
    },
    onInput(t, s) {
      if (t.inputType === "insertFromPaste") return;
      let e = t.data;
      isNaN(parseInt(t.data)) && (e = ""), this.setComputedValue(e, s);
    },
    onKeyDown(t, s) {
      var e;
      if (t.key === "Backspace") {
        if (this.getPin(s).trim() !== "")
          return this.setComputedValue(" ", s), t.preventDefault();
        {
          const i = Math.max(0, s - 1);
          this.setComputedValue(" ", i), (e = this[`input${i}`]) == null || e.focus();
        }
      } else if (t.key === "Delete")
        this.setComputedValue(" ", s);
      else if (!isNaN(parseInt(t.key)))
        return this.setComputedValue(t.key, s), t.preventDefault();
      t.key === "Enter" && s === 5 && this.$emit("submit");
    },
    onKeyUp(t, s) {
      var i, a;
      let e = s;
      t.key === "ArrowLeft" ? e = Math.max(0, e - 1) : t.key === "ArrowRight" ? e = Math.min(5, e + 1) : t.key === "ArrowUp" ? e = Math.max(0, e - 5) : t.key === "ArrowDown" ? e = Math.min(5, e + 5) : t.key.length === 1 && !isNaN(parseInt(t.key)) && (e = s + 1), e > (((i = this.computedValue) == null ? void 0 : i.length) ?? 0) && (e = this.computedValue.length), e = Math.min(5, e), e = Math.max(0, e), (a = this[`input${e}`]) == null || a.focus();
    },
    onPaste(t) {
      var e;
      const s = ((e = t.clipboardData) == null ? void 0 : e.getData("text/plain")) || "";
      this.computedValue = s.replace(/\D/g, "").substring(0, 6);
    }
  }
});
export {
  w as _
};
