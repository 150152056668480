import { FormElement } from "@kinherit/framework/component.form/element";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { CSSProperties } from "@kinherit/ts-common/css-types";

export const UpdateStylesForm = (styles: CSSProperties) =>
  defineForm({
    name: "update-styles",
    data: () => ({
      styles,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "styles",
        data,
        template: GridLayout([["controls"], ["styles"]]),
        components: (data) => ({
          controls: [
            FormButton({
              props: {
                reference: "add-style",
                iconLeft: "Plus",
                ariaLabel: "Add style",
                color: "is-primary",
                style: {
                  float: "right",
                },
              },
              emits: {
                click: (_, data, controls) => {
                  // @ts-ignore
                  data.styles[""] = "";
                  controls.rebuildForm();
                },
              },
            }),
          ],
          styles: Object.keys(data.styles).map((_key) => {
            let currentKey = _key as keyof CSSProperties;

            return FormElement({
              props: {
                reference: "style",
                style: {
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "end",
                },
              },
              slots: {
                default: [
                  FormTextField({
                    props: {
                      reference: "key",
                      label: "Key",
                      style: {
                        flexGrow: 1,
                      },
                    },
                    models: {
                      value: {
                        get: () => currentKey,
                        set: (newKey, data) => {
                          data.styles[newKey] = data.styles[currentKey];
                          delete data.styles[currentKey];
                          currentKey = newKey;
                        },
                      },
                    },
                  }),
                  FormTextField({
                    props: {
                      reference: "value",
                      label: "Value",
                      style: {
                        flexGrow: 1,
                      },
                    },
                    models: {
                      value: {
                        get: () => data.styles[currentKey],
                        set: (newValue, data) => {
                          data.styles[currentKey] = newValue;
                        },
                      },
                    },
                  }),
                  FormButton({
                    props: {
                      reference: "remove-style",
                      iconLeft: "Trash",
                      ariaLabel: "Remove style",
                      color: "is-danger",
                    },
                    emits: {
                      click: (_, data, controls) => {
                        delete data.styles[currentKey];
                        controls.rebuildForm();
                      },
                    },
                  }),
                ],
              },
            });
          }),
        }),
      }),
    ],
  });
