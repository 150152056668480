var c = Object.defineProperty;
var s = (t, e) => c(t, "name", { value: e, configurable: !0 });
import { ComponentOptionsBase as m } from "../../component.config/component-options-base.js";
const r = class r extends m {
  constructor(e = () => ({})) {
    super(e, "Footer");
  }
};
s(r, "FooterOptions");
let o = r;
export {
  o as FooterOptions
};
