<template>
  <div v-if="kintin">
    <CallScriptSection v-if="kintin" title="Guardians Intro">
      <Message
        :color="hasChildrenOrDependents ? 'is-warning' : 'is-success'"
        :text="
          hasChildrenOrDependents
            ? 'There are children or dependents under 18 or requiring special care'
            : 'No children or dependents under 18 or requiring special care'
        "
      />
      <br />

      <b v-if="checkIsYes('2.5.0a') && checkIsYes('2.5.0d')">
        Have you thought about who might act as Guardian?
      </b>
    </CallScriptSection>
    <CallScriptSection
      v-if="
        kintin &&
        checkIsYes('2.5.0a') &&
        checkIsYes('2.5.0d') &&
        hasChildrenOrDependents
      "
      title="Listing Guardians"
    >
      <GuardiansContainer :kintin="kintin" />
      <span v-if="checkIsYes('2.5.0b')">
        <b>
          Couples usually appoint their own Guardians on second death, but for
          children from a prior relationship you would appoint on first death.
        </b>
      </span>
      <b v-if="checkIsNo('2.5.0b')">
        As you're a couple, we would appoint Guardians on second death.
      </b>
      <span v-if="!isJoint">
        <b> It would be normal for you to name a Guardian upon your death. </b>
      </span>
      <ConfirmCheck
        :kintin="kintin"
        step="4.0.0c"
        :kintin-checks="kintinChecks"
        :checks="checks"
        is-reversed
        @update="updateCheckValue"
      />
      <br />
      <br />
      <b>
        If your Guardian is in a long term relationship, it can help if their
        partner is co-guardian (for as long as they're together)
      </b>
      <br />
      <ConfirmCheck
        :kintin="kintin"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.0.0a"
        :on="{
          yes: {
            action: [
              'Ensure you list the name of the partner in the notes section',
            ],
          },
        }"
        @update="updateCheckValue"
      />
      <br />
      <br />
      <b> Thinking about your main guardian if you’re not around… </b>
      <br />
      <ConfirmCheck
        :kintin="kintin"
        step="4.0.2"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <CallScriptSection
      v-if="
        kintin &&
        checkIsYes('2.5.0a') &&
        checkIsYes('2.5.0d') &&
        hasChildrenOrDependents
      "
      title="Guardians Financial Assistance"
    >
      <b>
        Obviously there is a cost to raising children. Have you thought about
        giving [Guardians] some form of financial support?
      </b>
      <br />
      <br /><em>[PAUSE and count to 5.]</em> <br /><br />
      <b>
        The UK government gives just &pound;18 per week to Guardians, which
        obviously isn’t much… If you would like, we can allow for additional
        finance to be given to the guardians through the Will. Should I put a
        note for us to come back to this and consider extra financial support?
      </b>
      <br />
      <br />
      <mark>Fix note saving below</mark>
      <ConfirmCheck
        :kintin="kintin"
        step="4.0.0b"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
      <br />
      <RichTextField
        v-if="checkIsYes('4.0.0b')"
        v-model:value="callScript.mowGuardians"
        label="Memorandum of Wishes guardian section"
      />
      <!--Button
        v-if="checkIsYes('4.0.0b')"
        text="Pre-populate with placeholder values"
        color="is-plain"
      /-->
      <Button
        v-if="checkIsYes('4.0.0b')"
        text="Save"
        color="is-positive"
        class="mt-4 mb-4"
        @click="saveHandler"
      />
      <ConfirmCheck
        :kintin="kintin"
        step="4.0.3"
        :kintin-checks="kintinChecks"
        :checks="checks"
        @update="updateCheckValue"
      />
    </CallScriptSection>
    <!-- <SectionNotes
      v-if="callScript"
      v-model:value="callScript.advGuardianNotes"
      :kintin="kintin"
      has-save-continue
      @save="saveHandler"
      @save-continue="saveAndContinueHandler"
    /> -->
  </div>
</template>

<script lang="ts">
import GuardiansContainer from "@/module/kinvault.kintin/component/address-book/GuardiansContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
// import SectionNotes from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { KintinDetailsCallScript2Call2GuardiansRoute } from "@/module/kinvault.kintin/page/details/call-script-2";
import Message from "@kinherit/framework/component.display/message";
import Button from "@kinherit/framework/component.input/button";
import RichTextField from "@kinherit/framework/component.input/rich-text-field";
import { defineComponent } from "vue";

export default defineComponent({
  name: KintinDetailsCallScript2Call2GuardiansRoute,
  components: {
    CallScriptSection,
    ConfirmCheck,
    GuardiansContainer,
    Message,
    // SectionNotes,
    RichTextField,
    Button,
  },
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  emits: ["save", "save-and-continue"],
  methods: {
    saveAndContinueHandler() {
      this.$emit("save-and-continue");
    },
    saveHandler() {
      this.$emit("save");
    },
  },
});
</script>
