// KnowledgeBaseCategory

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KnowledgeBaseDocument } from "@kinherit/sdk";
import { KnowledgeBaseCategory } from "@kinherit/sdk/model/knowledge-base-category";

export const AddDocument = DefineWriteAction({
  interface: "portal",
  resource:
    "/v2/portal/knowledge-base/category/{knowledgeBaseCategory}/documents/{knowledgeBaseDocument}/add",
  method: "post",
  parse: ({
    category,
    document,
  }: {
    category: KnowledgeBaseCategory | string;
    document: KnowledgeBaseDocument | string;
  }) => ({
    params: {
      knowledgeBaseCategory: category,
      knowledgeBaseDocument: document,
    },
  }),
});
