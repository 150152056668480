var y = Object.defineProperty;
var v = (c, e, t) => e in c ? y(c, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : c[e] = t;
var s = (c, e) => y(c, "name", { value: e, configurable: !0 });
var f = (c, e, t) => v(c, typeof e != "symbol" ? e + "" : e, t);
import { FormElement as u } from "../component.form/element/index.js";
import { FormDateOfBirthField as M } from "../component.input/date-of-birth-field/index.js";
import { FormPasswordField as F } from "../component.input/password-field/index.js";
import { FormPinField as R } from "../component.input/pin-field/index.js";
import { FormRadioField as b } from "../component.input/radio-field/index.js";
import { KernelModes as C } from "../core/kernel-mode.js";
import { defineForm as m, defineFormArea as p } from "../form-builder/define-form.js";
import { OpenFormDialog as g } from "../global/dialog.js";
import { ThemeSize as w } from "../theme/prop/size.js";
import { Api as P } from "@kinherit/sdk";
import { reactive as x } from "vue";
const l = class l {
  static init(e) {
    this.config = e, P.catch("TokenExpiredException", this.hook.bind(this));
  }
  static async hook() {
    if (console.log("LoginDialogService.hook"), window.Kernel.Mode === C.Test)
      return this.config.logout(), !1;
    try {
      await this.queueAuthRequest();
    } catch (e) {
      return console.error(e), this.config.logout(), !1;
    }
    return !0;
  }
  static async queueAuthRequest() {
    console.log("LoginDialogService.queueAuthRequest"), await new Promise((e, t) => {
      this.queuedAuthRequests.push({
        resolve: e,
        reject: t
      }), this.queuedAuthRequests.length === 1 && this.processAuthRequest({
        start: !0
      });
    });
  }
  static async processAuthRequest({
    reason: e,
    method: t,
    start: i
  } = {}) {
    if (console.log("LoginDialogService.processAuthRequest"), i || e === "incorrect-password") {
      let o;
      try {
        o = await this.getPassword(e === "incorrect-password");
      } catch (d) {
        return console.error(d), await this.reject();
      }
      if (!await this.config.login(o))
        return await this.processAuthRequest({ reason: "incorrect-password" });
    }
    if (await this.config.isMfaRequired() === !1)
      return this.resolve();
    const r = await this.config.getMFAMethods();
    if (!t)
      try {
        t = await this.chooseMFAMethod(r);
      } catch (o) {
        return console.error(o), await this.reject();
      }
    let { multiFactorSessionId: n, codeStart: a } = await this.config.sendMfaCode(t);
    try {
      const o = await this.getMfaCode(
        t,
        e === "incorrect-mfa-code",
        n,
        a,
        r.length
      );
      a = o.code, n = o.multiFactorSessionId;
    } catch (o) {
      return console.error(o), r.length === 1 ? await this.reject() : await this.processAuthRequest();
    }
    return await this.config.validateMfaCode(
      t,
      a,
      n
    ) ? await this.resolve() : this.processAuthRequest({ method: t, reason: "incorrect-mfa-code" });
  }
  static async getPassword(e) {
    console.log("LoginDialogService.getPassword");
    const t = await this.config.getUsersName(), { password: i } = await g({
      dialog: {
        type: "alert",
        title: `Welcome back, ${t}!`
      },
      button: {
        ok: {
          text: "Continue"
        },
        cancel: {
          text: "Logout"
        }
      },
      form: m({
        name: "password-form",
        data: /* @__PURE__ */ s(() => ({
          password: ""
        }), "data"),
        formAreas: /* @__PURE__ */ s((r) => [
          p({
            name: "password",
            data: r,
            components: /* @__PURE__ */ s(() => ({
              default: [
                u({
                  props: {
                    vIf: e,
                    is: "p",
                    html: this.config.passwordType === "dateOfBirth" ? "The date of birth you entered is incorrect. Please try again." : "The password you entered is incorrect. Please try again.",
                    class: ["text-red-500 mt-0"]
                  }
                }),
                u({
                  props: {
                    vIf: !e,
                    is: "p",
                    html: this.config.passwordType === "dateOfBirth" ? "Please enter your date of birth to continue." : "Please enter your password to continue.",
                    class: ["mt-0"]
                  }
                }),
                M({
                  props: {
                    vIf: this.config.passwordType === "dateOfBirth",
                    reference: "password",
                    validators: ["required"],
                    size: w.isMedium,
                    showClearButton: !1
                  },
                  models: {
                    value: "password"
                  }
                }),
                F({
                  props: {
                    vIf: this.config.passwordType === "password",
                    reference: "password",
                    validators: ["required"],
                    size: w.isMedium,
                    showClearButton: !1
                  },
                  models: {
                    value: "password"
                  }
                })
              ]
            }), "components")
          })
        ], "formAreas")
      })
    });
    return i;
  }
  static async chooseMFAMethod(e) {
    if (console.log("LoginDialogService.getMFAMethod"), e.length === 1)
      return e[0];
    const { methodId: t } = await g({
      dialog: {
        type: "alert",
        title: "Choose a method"
      },
      button: {
        ok: {
          text: "Continue"
        },
        cancel: {
          text: "Logout"
        }
      },
      form: m({
        name: "mfa-method-form",
        data: /* @__PURE__ */ s(() => ({
          methodId: null
        }), "data"),
        formAreas: /* @__PURE__ */ s((r) => [
          p({
            name: "mfa-method",
            data: r,
            components: /* @__PURE__ */ s(() => ({
              default: [
                u({
                  props: {
                    is: "p",
                    html: "Please choose a multi-factor authentication method.",
                    class: ["mt-0"]
                  }
                }),
                b({
                  props: {
                    label: "Method",
                    options: Object.fromEntries(
                      e.map((n) => [
                        n.id,
                        n.alias ?? "Authenticator"
                      ]) ?? []
                    ),
                    validators: ["required"],
                    direction: "is-vertical"
                  },
                  models: {
                    value: "methodId"
                  }
                })
              ]
            }), "components")
          })
        ], "formAreas")
      })
    }), i = e.find((r) => r.id === t);
    if (!i)
      throw new Error("No method selected");
    return i;
  }
  static async getMfaCode(e, t, i, r, n) {
    console.log("LoginDialogService.getMfaCode");
    let a;
    e.type === "email" && !t ? a = `A code has been sent to your email address ${e.alias ?? e.id}. Please enter it below.` : e.type === "sms" && !t ? a = `A code has been sent to your phone number ${e.alias ?? e.id}. Please enter it below.` : e.type === "totp" && !t ? a = "Please enter the six digit code from your authenticator app to continue." : t && (a = "The code you entered is incorrect. Please try again.");
    const o = x({
      code: r
    }), { code: d } = await g({
      dialog: {
        type: "alert",
        title: "Enter your code"
      },
      button: {
        ok: {
          text: "Continue"
        },
        cancel: {
          text: n === 1 ? "Logout" : "Change method"
        },
        left: e.type === "email" || e.type === "sms" ? {
          text: "Resend code",
          click: /* @__PURE__ */ s(async () => {
            const {
              multiFactorSessionId: h,
              codeStart: A
            } = await this.config.sendMfaCode(e);
            i = h, o.code = A;
          }, "click")
        } : void 0
      },
      form: m({
        name: "mfa-email-code-form",
        data: /* @__PURE__ */ s(() => o, "data"),
        formAreas: /* @__PURE__ */ s((h) => [
          p({
            name: "mfa-email-code",
            data: h,
            components: /* @__PURE__ */ s(() => ({
              default: [
                u({
                  props: {
                    is: "p",
                    html: a,
                    class: ["text-red-500 mt-0"]
                  }
                }),
                R({
                  props: {
                    reference: "code",
                    validators: ["required"],
                    size: w.isMedium,
                    showClearButton: !1
                  },
                  models: {
                    value: "code"
                  }
                })
              ]
            }), "components")
          })
        ], "formAreas")
      })
    });
    if (d === null)
      throw new Error("No code entered");
    return { code: d.toString().toNumber(), multiFactorSessionId: i };
  }
  static async reject() {
    console.log("LoginDialogService.reject"), this.queuedAuthRequests.forEach(({ reject: e }) => e()), this.queuedAuthRequests = [];
  }
  static async resolve() {
    console.log("LoginDialogService.resolve"), this.queuedAuthRequests.forEach(({ resolve: e }) => e(void 0)), this.queuedAuthRequests = [];
  }
};
s(l, "LoginDialogService"), f(l, "config"), f(l, "queuedAuthRequests", []);
let q = l;
export {
  q as LoginDialogService
};
