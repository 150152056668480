// UploadForm

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { UploadForm } from "@kinherit/sdk/model/upload-form";

export const CreateUploadForm = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/upload-form/{accountReferral}",
  method: "post",
  parse: (target: UploadForm) => ({
    body: {
      name: target.$data.name,
      legacySignkey: target.$data.legacySignkey,
      validTill: target.$data.validTill,
      submissionCount: target.$data.submissionCount,
      accountReferral: target.$data.accountReferral,
    },
    params: {
      accountReferral: target.$data.accountReferral,
    },
  }),
  after: ({ message }) => {
    UploadForm.$delete(message);
  },
});
