<template>
  <Button
    class=""
    :text="officer.officerCallStatus?.text ?? `Unknown`"
    :color="color"
    @click.stop="handleOfficerStatusClick"
  />
</template>

<script lang="ts">
import { Button } from "@kinherit/framework/component.input/button";
import { ThemeColorType } from "@kinherit/framework/theme/prop/color";
import { Person } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { UpdateOfficerCallStatusForm } from "../form/update-officer-call-status.form";

export default defineComponent({
  name: "OfficerCallStatusWidget",
  components: { Button },
  props: {
    officer: {
      type: Object as PropType<Person>,
      required: true,
    },
  },
  emits: ["reload"],
  computed: {
    color(): ThemeColorType {
      return `is-${
        this.officer.officerCallStatus?.class ?? `grey`
      }` as ThemeColorType;
    },
  },
  methods: {
    async handleOfficerStatusClick(): Promise<void> {
      await UpdateOfficerCallStatusForm(this.officer).dialog({
        dialog: {
          title: "Update Officer Call Status",
        },
      });
      this.officer.$persist();

      // await window.Kernel.ActionBus.kinvaultOfficer.updateCallStatus({
      //   person: this.officer,
      // });
      await window.Kernel.ActionBus2.portal.officer.UpdatePerson(this.officer);

      this.$forceUpdate();
      this.$emit("reload");
    },
  },
});
</script>
