var i = Object.defineProperty;
var h = (n, e, t) => e in n ? i(n, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : n[e] = t;
var c = (n, e) => i(n, "name", { value: e, configurable: !0 });
var o = (n, e, t) => h(n, typeof e != "symbol" ? e + "" : e, t);
const r = class r {
  constructor(e, t) {
    o(this, "content");
    o(this, "refresh");
    o(this, "incrementRenderKey");
    this.config = e, this.componentName = t, this.content = this.config().content;
  }
  getElementBindings(e) {
    return (this.config().elements ?? {})[e] ?? {};
  }
  setRefresh(e) {
    this.refresh = e;
  }
  callRefresh() {
    try {
      this.content = this.config().content, this.refresh(), window.Kernel.Logger.log(
        "ComponentConfig",
        "info",
        `Refreshed component ${this.componentName}`
      );
    } catch {
      window.Kernel.Logger.log(
        "ComponentConfig",
        "warn",
        `Failed to refresh component ${this.componentName}`
      );
    }
  }
  setIncrementRenderKey(e) {
    this.incrementRenderKey = e;
  }
  callIncrementRenderKey() {
    try {
      this.incrementRenderKey(), window.Kernel.Logger.log(
        "ComponentConfig",
        "info",
        `Incremented render key for component ${this.componentName}`
      );
    } catch {
      window.Kernel.Logger.log(
        "ComponentConfig",
        "warn",
        `Failed to increment render key for component ${this.componentName}`
      );
    }
  }
  // public content(): ReturnType<Config>["content"] {
  //   return this.config().content;
  // }
  updateConfig(e) {
    this.config = e, this.callRefresh();
  }
};
c(r, "ComponentOptionsBase");
let s = r;
export {
  s as ComponentOptionsBase
};
