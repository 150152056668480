import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { Api, FileLog, Kintin } from "@kinherit/sdk";

//
export const DownloadUploadFormSubmission = async (message: {
  submission: FileLog | string;
  kintin: Kintin | string;
}) => {
  const { url } = await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/upload-forms/submission/{submission}/download",
    {
      submission: message.submission,
      kintin: message.kintin,
    },
  )
    .method("get")
    .result();

  OpenAlertDialog({
    dialog: {
      title: "Download Ready",
      html: `
            Click <a href="${url}" target="_blank">here</a> to download the file.
          `,
    },
    button: {
      ok: {
        text: "Close",
      },
      cancel: {
        show: false,
      },
    },
  });

  return {};
};
