import { Api } from "@kinherit/sdk/api";
import { Kintin, Person } from "@kinherit/sdk";

//
export const FillLetterOfAuthority = async (message: {
  kintin: Kintin;
  person: Person;
  relationship: string;
  name: string;
  companyName?: string;
  address?: string;
}) => {
  await Api.resource(
    "portal",
    "/v2/portal/kinvault/{kintin}/process/letter-of-authority",
    {
      kintin: message.kintin,
    },
  )
    .method("post")
    .body({
      kintin: message.kintin.id,
      person: message.person.id,
      relationship: message.relationship,
      name: message.name,
      companyName: message.companyName,
      address: message.address,
    })
    .download({
      encoding: "application/pdf",
      fileName: `${message.kintin.ref} - Letter of Authority`,
    });
};
