<template>
  <div class="introducer-outsource-master-list">
    <PageHeader htag="h1" text="Outbound Referrals">
      <template #buttons>
        <!--
        // v-if="hasWritePermission"
      -->
        <Button class="create" @click="createIntroducerOutsource">
          Create Entry
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :row-classes="rowClasses"
      :sort-by-options="{
        datedAt: 'Referral Date',
        createdAt: 'Created',
      }"
      :persist-state="true"
      @row-clicked="handleClick"
      @refresh="refresh"
    >
      <template #assignedTo="{ row }: { row: IntroducerOutsource }">
        <Avatar
          :key="`assigned-to-${row.id}-assignedTo`"
          :name="row.assignedTo?.profile?.fullName"
          size="is-small"
          class="ml-1"
        />
      </template>
      <template #status="{ row }: { row: IntroducerOutsource }">
        <Badge
          :class="{
            'is-success': row.status?.value === 'completed',
            'is-info': row.status?.value === 'inprogress',
            'is-danger': row.status?.value === 'qualifiedout',
            'is-plain': row.status?.value === 'new',
            'is-warning': row.status?.value === 'chase',
            'is-purple': row.status?.value === 'awaitingpayment',
          }"
          is-compact
          is-rounded
        >
          {{ row.status?.text }}
        </Badge>
      </template>
      <template #nextAction="{ row }: { row: IntroducerOutsource }">
        <span
          v-if="!['qualifiedout', 'completed'].includes(row.status?.value)"
          :class="{
            'has-text-danger has-text-weight-bold': row.nextActionAt?.isBefore(
              new DateTime(),
            ),
          }"
          is-compact
          >{{
            row.nextActionAt ? row.nextActionAt?.formatDate : "Not Set"
          }}</span
        >
      </template>
      <template #payment="{ row }: { row: IntroducerOutsource }">
        <span v-if="['qualifiedout'].includes(row.status?.value)">-</span>
        <span v-else>
          <!-- Always output expected value if > 0; if paid value is set, display both if they are different --->
          <span
            v-if="row.paidValue"
            class="has-text-weight-bold has-text-success-dark is-block"
            >{{ row.paidValue.toFormattedString() }} Paid
            {{ row.paidAt ? row.paidAt?.formatDate : "" }}</span
          >
          <span
            v-else-if="row.expectedValue && row.expectedValue?.amount > 0"
            class="has-text-weight-bold has-text-purple is-block"
            >{{ row.expectedValue.toFormattedString() }} Expected</span
          >
          <span
            v-if="
              row.expectedValue &&
              row.paidValue &&
              row.expectedValue.amount !== row.paidValue.amount
            "
            class="has-text-weight-bold has-text-purple is-block"
            >{{ row.expectedValue.toFormattedString() }} Expected</span
          >
        </span>
      </template>
      <template #case="{ row }: { row: IntroducerOutsource }">
        <strong class="is-block">{{ row.clientName }}</strong>
        <small v-if="row.sourceKintin" class="is-block"
          >{{ row.sourceKintin?.friendlyName ?? "" }}
          {{ row.sourceKintin?.ref ?? "" }}</small
        >
        <small v-if="row.postcode" class="is-block">{{ row.postcode }}</small>
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerOutsourceMasterListWrapper",
  "route": "IntroducerOutsourceMasterList",
  "selector": ".introducer-outsource-master-list",
  "imports": {
    "ReadIntroducerOutsourceFormWrapper": "@/module/introducer.outsource/form/read-introducer-outsource.form.test",
    "IntroducerOutsourceDetailsWrapper": "@/module/introducer.outsource/page/details/IntroducerOutsourceDetails.test",
    "CreateIntroducerOutsourceFormWrapper": "@/module/introducer.outsource/form/create-introducer-outsource.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadIntroducerOutsourceFormWrapper"
    },
    "details": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerOutsourceDetailsWrapper"
    },
    "createIntroducerOutsource": {
      "type": "click",
      "selector": ".create"
    },
    "createIntroducerOutsourceForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "CreateIntroducerOutsourceFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadIntroducerOutsourceForm } from "@/module/introducer.outsource/form/read-introducer-outsource.form";
import { AuthService } from "@/service/auth.service";
import Avatar from "@kinherit/framework/component.display/avatar";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IIntroducerOutsource, IntroducerOutsource } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { IntroducerOutsourceMasterListRoute } from ".";
import { CreateIntroducerOutsourceForm } from "../form/create-introducer-outsource.form";
import {
  IntroducerOutsourceDetailsParams,
  IntroducerOutsourceDetailsRoute,
} from "./details";

export default defineComponent({
  name: IntroducerOutsourceMasterListRoute,
  components: { MasterListPage, PageHeader, Button, Badge, Avatar },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal.introducer.outsources),
  ],
  data: () => ({
    DateTime,
    filters: ReadIntroducerOutsourceForm(),
    columns: [
      {
        title: "Referral Date",
        sort: true,
        map: (v: IntroducerOutsource) => v.datedAt?.formatDate,
      },
      {
        slot: "assignedTo",
      },
      {
        title: "Case",
        sort: true,
        slot: "case",
      },
      {
        title: "Company",
        sort: true,
        map: (v: IntroducerOutsource) =>
          v.company?.profile?.organisationName
            ? v.company?.profile?.organisationName
            : "Not Set",
      },
      {
        title: "Type",
        sort: true,
        map: (v: IntroducerOutsource) => v.type?.text,
      },
      {
        title: "Status",
        sort: true,
        slot: "status",
      },
      {
        title: "Payment",
        slot: "payment",
        // map: (v: IntroducerOutsource) =>
        //   ["qualifiedout"].includes(v.status?.value)
        //     ? "-"
        //     : v.paidValue
        //       ? `${v.paidValue.toFormattedString()} Paid ${v.paidAt ? v.paidAt?.formatDate : ""}`
        //       : v.expectedValue && v.expectedValue?.amount > 0
        //         ? `${v.expectedValue.toFormattedString()} Expected`
        //         : "-",
      },
      {
        title: "Next Action",
        sort: true,
        slot: "nextAction",
        // map: (v: IntroducerOutsource) =>
        //   v.nextActionAt ? v.nextActionAt?.formatDate : "Not Set",
      },
    ] as Array<TableColumn>,
    rows: Array<IntroducerOutsource>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "datedAt" as keyof IIntroducerOutsource,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("introducer-outsource:write");
    },
  },
  methods: {
    rowClasses(scope: any): Record<string, boolean> {
      return {
        "has-background-danger-light":
          scope.row.status.value === "qualifiedout",
        "has-background-success-light": scope.row.status.value === "completed",
        "has-background-purple-light":
          scope.row.status.value === "awaitingpayment",
        "has-background-info-light": scope.row.status.value === "inprogress",
        "has-background-warning-light": scope.row.status.value === "chase",
      };
    },
    handleClick(
      introduceroutsource: IntroducerOutsource,
      event: MouseEvent,
    ): void {
      const params: IntroducerOutsourceDetailsParams = {
        introducerOutsource: introduceroutsource.id,
      };

      window.Kernel.visitRoute(
        {
          name: IntroducerOutsourceDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadIntroducerOutsourceForm>["localData"],
    ): Promise<void> {
      const data = await this.$actionBus.ReadIntroducerOutsource({
        message: formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.introducerOutsource;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async createIntroducerOutsource(): Promise<void> {
      const introducerOutsource = await CreateIntroducerOutsourceForm().dialog({
        dialog: {
          title: "Create Entry",
        },
      });

      // await window.Kernel.ActionBus.introducerOutsource.create({
      //   introducerOutsource,
      // });
      await this.$actionBus.CreateIntroducerOutsource(introducerOutsource);

      this.refresh(this.filters.localData);
    },
  },
});
</script>
