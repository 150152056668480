// Order

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Order } from "@kinherit/sdk/model/order";

export const UpdateOrder = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/fees/order/{order}",
  method: "patch",
  parse: (target: Order) => ({
    body: {
      extrasPrice: target.$data.extrasPrice,
      discountValue: target.$data.discountValue,
      discountPercentage: target.$data.discountPercentage,
      coolingOff: target.$data.coolingOff,
      paidAt: target.$data.paidAt,
      invoiceNumber: target.$data.invoiceNumber,
      notes: target.$data.notes,
      paymentSignKey: target.$data.paymentSignKey,
      stripePaymentIntentId: target.$data.stripePaymentIntentId,
      overrideListPrice: target.$data.overrideListPrice,
      feesPayOutIntro: target.$data.feesPayOutIntro,
      feesPayoutAgent: target.$data.feesPayoutAgent,
      feesPayOutIntroDate: target.$data.feesPayOutIntroDate,
      feesPayOutAgentDate: target.$data.feesPayOutAgentDate,
      feesPayOutParentAgent: target.$data.feesPayOutParentAgent,
      feesPayOutParentAgentDate: target.$data.feesPayOutParentAgentDate,
      feesConfirmed: target.$data.feesConfirmed,
      xeroIntroducerBillId: target.$data.xeroIntroducerBillId,
      xeroAgentBillId: target.$data.xeroAgentBillId,
      billingName: target.$data.billingName,
      billingAddress1: target.$data.billingAddress1,
      billingAddress2: target.$data.billingAddress2,
      billingCity: target.$data.billingCity,
      billingRegion: target.$data.billingRegion,
      billingPostalCode: target.$data.billingPostalCode,
      xeroParentAgentBillId: target.$data.xeroParentAgentBillId,
      orderItemCount: target.$data.orderItemCount,
      isTrustReg: target.$data.isTrustReg,
      kintin: target.$data.kintin,
      status: target.$data.status,
      paymentType: target.$data.paymentType,
      discountReason: target.$data.discountReason,
      createdBy: target.$data.createdBy,
    },
    params: {
      order: target,
    },
  }),
  after: ({ message }) => {
    message.$persist();
  },
});
