import { defineComponent as t } from "vue";
const n = t({
  name: "BlankElementComponent",
  props: {
    is: {
      type: String,
      default: "span"
    },
    html: {
      type: String,
      default: void 0
    },
    text: {
      type: String,
      default: void 0
    }
  },
  emits: ["click"]
});
export {
  n as _
};
