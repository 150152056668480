// AccountReferralCode

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { AccountReferralCode } from "@kinherit/sdk/model/account-referral-code";

export const DeleteAccountReferralCode = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/admin/account-referral-code/{accountReferralCode}",
  method: "delete",
  parse: (accountReferralCode: AccountReferralCode | string) => ({
    params: {
      accountReferralCode,
    },
  }),
  after: ({ message }) => {
    AccountReferralCode.$delete(message);
  },
});
