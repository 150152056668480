import { IntroducerOutsourceDetailsParams } from "@/module/introducer.outsource/page/details";
import { AuthService } from "@/service/auth.service";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { IntroducerOutsource } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const IntroducerOutsourceDetailsMixin = defineComponent({
  mixins: [
    ActionBusMixin(() => window.Kernel.ActionBus2.portal.introducer.outsources),
  ],
  computed: {
    $params(): IntroducerOutsourceDetailsParams {
      return this.$route.params as IntroducerOutsourceDetailsParams;
    },
    introducerOutsource() {
      return IntroducerOutsource.$findOne(this.$params.introducerOutsource);
    },
    hasWritePermission() {
      return AuthService.hasPermission("introducer-outsource:write");
    },
  },
});
