var u = Object.defineProperty;
var l = (t, e) => u(t, "name", { value: e, configurable: !0 });
import n from "../../_vue/component.input/base-input-field/base-input-field.js";
import r from "../../_vue/component.input/drop-down-menu/drop-down-menu.js";
import { FieldMixin as c } from "../../component.mixin/field-mixin.js";
import { OptionsMixin as h } from "../../component.mixin/options-mixin.js";
import { CypressHelper as p } from "../../cypress/component-helpers.js";
import { OpenPanelDialog as d, OpenAutocompleteDialog as f } from "../../global/dialog.js";
import { ThemeColor as o } from "../../theme/prop/color.js";
import { ThemeIconName as s } from "../../theme/prop/icon.js";
import { defineComponent as m, ref as a } from "vue";
import { S as g } from "../../_assets/component.input/auto-complete-field/auto-complete-field.module.js";
const k = m({
  name: "AutoCompleteFieldComponent",
  components: { BaseInputField: n, DropDownMenu: r },
  mixins: [c(), h()],
  props: {
    /**
     * Option to hide the fields built in clear button
     */
    showClearButton: {
      type: Boolean,
      default: !0
    },
    /**
     * Force the drop down menu to be shown
     */
    showDropDown: {
      type: Boolean,
      default: !1
    },
    /**
     * Show the "More..." button at the bottom of the drop down menu
     */
    showMoreButton: {
      type: Boolean,
      default: !0
    },
    /**
     * Input box place holder
     */
    placeholder: {
      type: String,
      default: "Search..."
    },
    /**
     * Catagories to split the options into
     */
    categories: {
      type: Array,
      default: null
    },
    /**
     * Whether or not the left button on the dialog should be displayed
     */
    showLeftDailogButton: {
      type: Boolean,
      default: !1
    },
    /**
     * Left dailog button color
     */
    leftDialogButtonColor: {
      type: String,
      default: "is-primary",
      validator: /* @__PURE__ */ l((t) => Object.values(o).includes(t), "validator")
    },
    /**
     * Left dialog button label
     */
    leftDialogButtonLabel: {
      type: String,
      default: "New"
    },
    /**
     * Ok dialog button color
     */
    okDialogButtonColor: {
      type: String,
      default: "is-positive",
      validator: /* @__PURE__ */ l((t) => Object.values(o).includes(t), "validator")
    },
    /**
     * Ok dialog button label
     */
    okDialogButtonLabel: {
      type: String,
      default: "Ok"
    },
    /**
     * Cancel dailog button color
     */
    cancelDialogButtonColor: {
      type: String,
      default: void 0,
      validator: /* @__PURE__ */ l((t) => Object.values(o).includes(t), "validator")
    },
    /**
     * Cancel dialog button label
     */
    cancelDialogButtonLabel: {
      type: String,
      default: "Cancel"
    },
    /**
     * Make the dropdown menu's overflow scrollable
     */
    isScrollable: {
      type: Boolean,
      default: !1
    },
    /**
     * Force the focus event to open a dialog
     */
    forceDialog: {
      type: Boolean,
      default: !1
    },
    debugDropdown: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["click:left", "update:value", "focus", "blur", "clear"],
  setup() {
    const t = a(null), e = a(null);
    return {
      input: t,
      dropdown: e
    };
  },
  data: /* @__PURE__ */ l((t) => ({
    hovered: !1,
    asyncFilterTimeout: null,
    $cypress: p({ ctx: t, labelField: "label" })
    // dropdownPosition: "below" as "above" | "below",
  }), "data"),
  computed: {
    dropdownComputedValue: {
      get() {
        return this.computedValue;
      },
      set(t) {
        var e;
        this.computedValue = t, this.isMultiSelect || (this.hasFocus = !1), this.input ? (e = this.input) == null || e.validate(t ?? null) : this.$nextTick(() => {
          var i;
          (i = this.input) == null || i.validate(t ?? null);
        });
      }
    },
    computedSearchValue: {
      get() {
        return this.hasFocus || this.computedSearch !== null ? this.computedSearch : this.computedValue !== null ? Array.isArray(this.computedValue) ? this.computedValue.map((t) => t.label).join(", ", " and ") : this.computedValue.label : null;
      },
      set(t) {
        this.computedSearch = t;
      }
    },
    computedPlaceHolder() {
      return this.autoCollapseLabel && !this.hasFocus && !this.computedHasValue ? null : this.placeholder;
    },
    computedLeftIcon() {
      if (this.iconLeft !== null)
        return this.iconLeft;
      const t = this.computedValue;
      return Array.isArray(t) ? null : (t == null ? void 0 : t.iconLeft) ?? null;
    },
    autocompleteRightIcon() {
      return (this.computedPlaceHolder === null || this.isMultiSelect && Array.isArray(this.value) && this.value.isEmpty()) && this.showMoreButton === !0 ? s.Search : (!Array.isArray(this.computedValue) && this.computedValue !== null || Array.isArray(this.computedValue) && this.computedValue.isNotEmpty()) && this.showClearButton === !0 ? s.Clear : null;
    }
  },
  watch: {
    computedValue: {
      handler() {
        this.isMultiSelect || (this.hasFocus = !1, this.hovered = !1, this.computedSearchValue = null);
      },
      immediate: !0
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("auto-complete-field", g);
  },
  mounted() {
    window.Kernel.KeyListener.addListener({
      code: "Tab",
      listener: this.onTab
    });
  },
  beforeUnmount() {
    window.Kernel.KeyListener.removeListener({
      code: "Tab",
      listener: this.onTab
    });
  },
  methods: {
    clearSelected() {
      this.$emit("update:value", this.isMultiSelect ? [] : null), this.$emit("clear");
    },
    focusDropDown() {
    },
    async localFocus(t) {
      this.focus(t), (console.isTouchScreen(!0) || this.forceDialog) && !this.showDropDown ? this.openDialog() : (this.$loadOptions(), this.$emit("focus"), this.hasFocus = !0);
    },
    async openDialog() {
      if (this.state)
        return;
      this.hasFocus = !1;
      let t = null;
      this.categories ? t = await d({
        value: this.value,
        dialog: {
          title: this.label
        },
        list: {
          options: /* @__PURE__ */ l(() => this.options, "options"),
          mapOptions: this.mapOptions,
          isMultiSelect: this.isMultiSelect
        },
        categories: this.categories,
        input: {
          placeholder: this.placeholder
        },
        button: {
          ok: {
            color: this.okDialogButtonColor,
            text: this.okDialogButtonLabel
          },
          cancel: {
            color: this.cancelDialogButtonColor,
            text: this.cancelDialogButtonLabel
          },
          left: {
            show: this.showLeftDailogButton,
            color: this.leftDialogButtonColor,
            text: this.leftDialogButtonLabel,
            click: /* @__PURE__ */ l(async (e) => this.$emit("click:left", e), "click")
          }
        }
      }) : t = await f({
        value: this.value,
        contentType: "dialog-autocomplete",
        dialog: {
          title: this.label
        },
        list: {
          options: /* @__PURE__ */ l(() => this.options, "options"),
          mapOptions: this.mapOptions,
          isMultiSelect: this.isMultiSelect,
          sort: this.sort
        },
        asyncFilter: this.asyncFilter,
        input: {
          placeholder: this.placeholder
        },
        button: {
          ok: {
            color: this.okDialogButtonColor,
            text: this.okDialogButtonLabel
          },
          cancel: {
            color: this.cancelDialogButtonColor,
            text: this.cancelDialogButtonLabel
          },
          left: {
            show: this.showLeftDailogButton,
            color: this.leftDialogButtonColor,
            text: this.leftDialogButtonLabel,
            click: /* @__PURE__ */ l(async (e) => this.$emit("click:left", e), "click")
          }
        }
      }), this.$emit("update:value", t);
    },
    autocompleteRightIconClick() {
      this.computedPlaceHolder === null || this.isMultiSelect && this.value.isEmpty() ? this.openDialog() : this.computedSearch ? (this.computedSearchValue = null, this.$loadOptions()) : (this.$emit("update:value", this.isMultiSelect ? [] : null), this.$emit("clear"));
    },
    async blur() {
      this.hasFocus && (this.hasFocus = !1, this.showDropDown || this.input.$refs.input.blur(), this.computedSearchValue = null, this.$emit("blur"));
    },
    onTab() {
      this.hasFocus && this.blur();
    }
    // computeDropdownPosition(): void {
    //   const input = this.input.$el as HTMLElement;
    //   if (!input || this.isComponentDialog) {
    //     this.dropdownPosition = "below";
    //     return;
    //   }
    //   const inputRect = input.getBoundingClientRect();
    //   const windowHeight = window.innerHeight;
    //   const isAbove = inputRect.top > windowHeight / 2;
    //   this.dropdownPosition = isAbove ? "above" : "below";
    // },
  }
});
export {
  k as _
};
