// Notification

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Notification } from "@kinherit/sdk/model/notification";

export const UpdateNotification = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/dashboard/notifications/{notification}",
  method: "patch",
  parse: (target: Notification) => ({
    body: {
      statusChangedAt: target.$data.statusChangedAt,
      notes: target.$data.notes,
      user: target.$data.user,
      note: target.$data.note,
      status: target.$data.status,
    },
    params: {
      notification: target,
    },
  }),
});
