<template>
  <span class="split-badge">
    <span class="has-background-info">{{ infoCount || "-" }}</span>
    <span class="has-background-purple">{{ optionsCount || "-" }}</span>
  </span>
</template>

<script lang="ts">
export default {
  props: {
    infoCount: {
      type: Number,
      required: true,
    },
    optionsCount: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.split-badge {
  display: flex;
  gap: 0rem;
  border-radius: 0.5rem; // Rounded corners
  overflow: hidden;

  span {
    display: inline-block;
    width: 2rem;
    text-align: center;
    color: white;
    padding: 3px;
  }

  span:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  span:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
</style>
