<template>
  <div :key="renderKey" class="account-holder-list">
    <AccountHolderSummary
      v-if="$data.primaryPerson"
      class="primary-account-holder-summary"
      :kintin="kintin"
      :person="$data.primaryPerson"
      @edit="edit($data.primaryPerson)"
    />
    <AccountHolderSummary
      v-if="$data.secondaryPerson"
      class="secondary-account-holder-summary"
      :kintin="kintin"
      :person="$data.secondaryPerson"
      @edit="edit($data.secondaryPerson)"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AccountHolderListWrapper",
  "imports": {
    "AccountHolderSummaryWrapper": "@/module/kinvault.kintin/component/summary-cards/AccountHolderSummary.test"
  },
  "methods": {
    "primaryPerson": {
      "type": "to-one",
      "selector": ".primary-account-holder-summary",
      "wrapper": "AccountHolderSummaryWrapper"
    },
    "secondaryPerson": {
      "type": "to-one",
      "selector": ".secondary-account-holder-summary",
      "wrapper": "AccountHolderSummaryWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import AccountHolderSummary from "@/module/kinvault.kintin/component/summary-cards/AccountHolderSummary.vue";
import { UpdatePersonForm } from "@/module/kinvault.kintin/form/update-person.form";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { Kintin, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AccountHolderList",
  components: { AccountHolderSummary },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.kinvault)],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
  },
  data: () => ({
    renderKey: 0,
    primaryPerson: null as Person | null,
    secondaryPerson: null as Person | null,
  }),
  mounted() {
    this.filter();
  },
  methods: {
    async edit(person: Person): Promise<void> {
      try {
        await UpdatePersonForm({
          person: person,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: `Edit Person: ${person.profile.firstName} ${person.profile.lastName}`,
          },
          button: {
            ok: {
              text: "Update",
            },
          },
        });
      } catch {
        person.profile.$restore();
        person.$restore();
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.person.update({
      //   person: person,
      //   kintin: this.kintin,
      // });
      await this.$actionBus.person.UpdatePerson(person);

      // await window.Kernel.ActionBus.kinvaultKintin.person.updateProfile({
      //   person: person,
      //   kintin: this.kintin,
      // });
      await this.$actionBus.person.UpdateProfile(person);

      this.filter();
    },
    filter() {
      this.primaryPerson = Person.$findOne(this.kintin.primaryPerson.id);
      if (this.kintin.secondaryPerson) {
        this.secondaryPerson = Person.$findOne(this.kintin.secondaryPerson.id);
      }

      this.renderKey++;
    },
  },
});
</script>
