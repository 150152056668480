var r = Object.defineProperty;
var t = (e, o) => r(e, "name", { value: o, configurable: !0 });
import n from "../../component.mixin/chart-mixin.js";
import { Chart as a, Title as i, Tooltip as s, Legend as p, SubTitle as l, BarElement as m, CategoryScale as u, LinearScale as d, PointElement as c, LineElement as g, LineController as C } from "chart.js";
import { defineComponent as f } from "vue";
import { Bar as h } from "vue-chartjs";
a.register(
  i,
  s,
  p,
  l,
  m,
  u,
  d,
  c,
  g,
  C
);
const O = f({
  name: "BarChart",
  components: { Bar: h },
  mixins: [n],
  props: {
    plugins: {
      type: Array,
      default: /* @__PURE__ */ t(() => [], "default")
    },
    options: {
      type: Object,
      default: /* @__PURE__ */ t(() => ({}), "default")
    },
    labels: {
      type: Array,
      default: /* @__PURE__ */ t(() => [], "default")
    },
    datasets: {
      type: Array,
      default: /* @__PURE__ */ t(() => [], "default")
    }
  },
  data: /* @__PURE__ */ t(() => ({}), "data"),
  computed: {
    computedChartOptions() {
      return Object.assign(
        {
          responsive: !0,
          maintainAspectRatio: !0,
          plugins: this.computedPluginOptions
        },
        this.options
      );
    },
    computedDatasets() {
      return this.datasets.map((e) => ({
        ...e,
        backgroundColor: e.backgroundColor ?? window.Kernel.Theme.Colors.stringToColor(
          e.label ?? Math.random().toString()
        )
      }));
    }
  }
});
export {
  O as _
};
