var p = Object.defineProperty;
var t = (e, r) => p(e, "name", { value: r, configurable: !0 });
import { _ as f } from "../../../core/internal-components/sidebar-loader.vue_vue_type_script_lang.js";
import { resolveComponent as c, createElementBlock as d, openBlock as a, Fragment as _, renderList as u, createBlock as i, Teleport as g, createVNode as $, normalizeClass as h, withCtx as b, createElementVNode as v, createCommentVNode as k } from "vue";
import { _ as U } from "../../../_plugins/_plugin-vue_export-helper.js";
const w = { class: "pragmatic-sidebar-loader" }, y = { class: "content" };
function C(e, r, T, B, F, N) {
  const l = c("Form"), m = c("Sidebar");
  return a(), d("div", w, [
    (a(!0), d(_, null, u(e.$data.sidebarRequests, (o, n) => (a(), i(g, {
      key: `sidebar-${n}`,
      to: ".pragmatic-container",
      disabled: e.isUnitTest
    }, [
      $(m, {
        class: h(`${o.request.config.contentType}`),
        show: !0,
        "onUpdate:show": /* @__PURE__ */ t((s) => e.close(!1, n), "onUpdate:show")
      }, {
        default: b(() => [
          v("div", y, [
            o.request.config.contentType === "sidebar-form" ? (a(), i(l, {
              key: 0,
              data: o.value,
              "onUpdate:data": /* @__PURE__ */ t((s) => o.value = s, "onUpdate:data"),
              "is-borderless": "",
              config: o.request.config.form
            }, null, 8, ["data", "onUpdate:data", "config"])) : k("", !0)
          ])
        ]),
        _: 2
      }, 1032, ["class", "onUpdate:show"])
    ], 8, ["disabled"]))), 128))
  ]);
}
t(C, "_sfc_render");
const z = /* @__PURE__ */ U(f, [["render", C]]);
export {
  z as default
};
