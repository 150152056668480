import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadEmailLog } from "./read-email-log.action";
import { ReadFailedLoginAttempt } from "./read-failed-login-attempt.action";
import { ReadFailedPasswordResetAttempt } from "./read-failed-password-reset-attempt.action";
import { ReadKinvaultAccessRequests } from "./read-kinvault-access-request.action";
import { ReadNotifications } from "./read-notifications.action";
import { RecordUser } from "./record-user.action";

export const Portal2AdminLog = DefineActionBus({
  name: "portal-admin-log",
  actions: {
    ReadFailedLoginAttempt,
    ReadEmailLog,
    RecordUser,
    ReadNotifications,
    ReadKinvaultAccessRequests,
    ReadFailedPasswordResetAttempt,
  },
});
