var l = Object.defineProperty;
var t = (e, o) => l(e, "name", { value: o, configurable: !0 });
import r from "../../_vue/component.input/button/button.js";
import { CypressHelper as a } from "../../cypress/component-helpers.js";
import { ThemeColor as n } from "../../theme/prop/color.js";
import { defineComponent as s } from "vue";
import { S as i } from "../../_assets/component.layout/dialog/dialog.module.js";
const m = s({
  name: "DialogComponent",
  components: { Button: r },
  props: {
    /**
     * Disable ok button
     */
    okButtonDisabled: {
      type: Boolean,
      default: !1
    },
    /**
     * Dialog title
     */
    title: {
      type: String,
      default: ""
    },
    /**
     * OK button label
     */
    okButtonLabel: {
      type: String,
      default: "Ok"
    },
    /**
     * dialog style
     */
    type: {
      type: String,
      default: "is-alert"
    },
    /**
     * Whether the dialog should fill the screen (width & height)
     */
    fillScreen: {
      type: Boolean,
      default: !1
    },
    /**
     * Left button label
     */
    leftButtonLabel: {
      type: String,
      default: "New"
    },
    /**
     * Cancel button label
     */
    cancelButtonLabel: {
      type: String,
      default: "Cancel"
    },
    /**
     * Ok button color
     */
    okButtonColor: {
      type: String,
      default: "is-positive",
      validator: /* @__PURE__ */ t((e) => Object.values(n).includes(e), "validator")
    },
    /**
     * Left button color
     */
    leftButtonColor: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((e) => Object.values(n).includes(e), "validator")
    },
    /**
     * Cancel button color
     */
    cancelButtonColor: {
      type: String,
      default: "is-plain",
      validator: /* @__PURE__ */ t((e) => Object.values(n).includes(e), "validator")
    },
    /**
     * Whether or not the cancel button should be displayed
     */
    showCancelButton: {
      type: Boolean,
      default: !0
    },
    /**
     * Whether or not the ok button should be displayed
     */
    showOkButton: {
      type: Boolean,
      default: !0
    },
    /**
     * Whether or not the left button should be displayed
     */
    showLeftButton: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether or not to show the dialog
     */
    show: {
      type: Boolean,
      default: !1
    },
    /**
     * Whether or not to show the footer of the dialog
     */
    showFooter: {
      type: Boolean,
      default: !0
    },
    /**
     * Whether or not to show the overlay behind the dialog
     */
    showModalBackground: {
      type: Boolean,
      default: !0
    },
    /**
     * Whether or not to show the overlay above the loading overlay
     */
    isAboveLoading: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["update:show", "resolve", "reject", "click:left", "click:background"],
  data: /* @__PURE__ */ t((e) => ({
    $cypress: a({ ctx: e, labelField: "title" })
  }), "data"),
  computed: {
    computedClasses() {
      const e = {
        "is-active": this.show,
        [`is-${this.type}`]: !0
      };
      return Object.keys(e).filter((o) => e[o]).join(" ");
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("dialog", i);
  },
  mounted() {
    window.Kernel.KeyListener.addListener({
      code: "Enter",
      preventDefault: !0,
      ignoreInput: !0,
      listener: this.enterKey
    }), window.Kernel.KeyListener.addListener({
      code: "Escape",
      preventDefault: !0,
      ignoreInput: !0,
      listener: this.escapeKey
    });
  },
  beforeUnmount() {
    window.Kernel.KeyListener.removeListener({
      code: "Enter",
      listener: this.enterKey
    }), window.Kernel.KeyListener.removeListener({
      code: "Escape",
      listener: this.escapeKey
    });
  },
  methods: {
    enterKey() {
      this.$emit("resolve");
    },
    escapeKey() {
      this.$emit("reject");
    }
  }
});
export {
  m as _
};
