// Lead, Kintin, Profile, IntroducerContact, AccountReferral, AccountReferralCode

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { AccountReferral } from "@kinherit/sdk/model/account-referral";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const ReadAccountReferrals = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/introducer/company/referral/{introducerCompany}",
  method: "get",
  rootModel: AccountReferral,
  parse: (introducerCompany: IntroducerCompany | string) => ({
    params: {
      introducerCompany,
    },
  }),
});
