var C = Object.defineProperty;
var u = (e, o) => C(e, "name", { value: o, configurable: !0 });
import { _ as c } from "../../../component.input/rich-text-field/rich-text-field.vue_vue_type_script_lang.js";
import { resolveComponent as t, createElementBlock as i, openBlock as l, mergeProps as h, createVNode as y, withCtx as $, createCommentVNode as a, createElementVNode as b, renderSlot as p, normalizeClass as n, createBlock as d, withDirectives as g, vModelText as V } from "vue";
import { b as m } from "../../../_assets/component.input/rich-text-field/rich-text-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as v } from "../../../_plugins/_plugin-vue_export-helper.js";
const z = {
  key: 0,
  class: "input-field__control"
}, I = ["placeholder", "disabled", "readonly"], w = ["innerHTML"], B = {
  key: 1,
  class: "input-field__control"
};
function F(e, o, L, M, T, E) {
  const r = t("Icon"), f = t("Editor"), k = t("Field");
  return l(), i("div", h(e.$data.$cypress, {
    class: `${e.wrapperClasses} input-field rich-text-field`
  }), [
    y(k, {
      label: e.label,
      "has-addons": e.computedhasAddons,
      "is-grouped": e.computedIsGrouped,
      messages: e.computedMessages,
      size: e.size
    }, {
      default: $(() => [
        e.showLeftControl ? (l(), i("div", z, [
          p(e.$slots, "left", { value: e.computedValue })
        ])) : a("", !0),
        b("div", {
          class: n(`input-field__control ${e.controlClasses} is-expanded`)
        }, [
          e.iconLeft ? (l(), d(r, {
            key: 0,
            size: e.size,
            icon: e.iconLeft,
            class: n(`is-left ${e.leftIconClick ? "is-clickable" : ""}`),
            onClick: e.leftIconClick
          }, null, 8, ["size", "icon", "class", "onClick"])) : a("", !0),
          e.showClearButton && e.computedValue !== null ? (l(), d(r, {
            key: 1,
            icon: "Cross",
            size: e.size,
            class: "is-right is-clickable",
            onClick: e.clear
          }, null, 8, ["size", "onClick"])) : e.computedIconRight ? (l(), d(r, {
            key: 2,
            icon: e.computedIconRight,
            size: e.size,
            class: n(`is-right ${e.rightIconClick ? "is-clickable" : ""}`),
            onClick: e.rightIconClick
          }, null, 8, ["icon", "size", "class", "onClick"])) : a("", !0),
          e.kernelMode === "Test" ? g((l(), i("textarea", {
            key: 3,
            ref: "input",
            "onUpdate:modelValue": o[0] || (o[0] = (s) => e.computedValue = s),
            class: n([e.inputClasses, "textarea"]),
            placeholder: e.placeholder ?? void 0,
            disabled: e.state === "disabled",
            readonly: e.state === "readonly",
            onFocus: o[1] || (o[1] = (...s) => e.focus && e.focus(...s)),
            onBlur: o[2] || (o[2] = (s) => e.$emit("blur"))
          }, null, 42, I)), [
            [V, e.computedValue]
          ]) : e.state !== null ? (l(), i("div", {
            key: 4,
            innerHTML: e.computedValue
          }, null, 8, w)) : (l(), d(f, {
            key: 5,
            ref: "input",
            modelValue: e.computedValue,
            "onUpdate:modelValue": o[3] || (o[3] = (s) => e.computedValue = s),
            init: e.init,
            "license-key": "gpl",
            class: n(`textarea ${e.inputClasses}`),
            placeholder: e.placeholder,
            disabled: e.state === "disabled",
            readonly: e.state === "readonly",
            onFocus: e.focus,
            onBlur: o[4] || (o[4] = (s) => e.$emit("blur"))
          }, null, 8, ["modelValue", "init", "class", "placeholder", "disabled", "readonly", "onFocus"]))
        ], 2),
        e.showRightControl ? (l(), i("div", B, [
          p(e.$slots, "right", { value: e.computedValue })
        ])) : a("", !0)
      ]),
      _: 3
    }, 8, ["label", "has-addons", "is-grouped", "messages", "size"])
  ], 16);
}
u(F, "_sfc_render");
typeof m == "function" && m(c);
const D = /* @__PURE__ */ v(c, [["render", F]]);
export {
  D as default
};
