var r = Object.defineProperty;
var t = (e, l) => r(e, "name", { value: l, configurable: !0 });
import i from "../../_vue/component.display/title/title.js";
import n from "../../_vue/component.input/button/button.js";
import u from "../../component.mixin/default-slot-mixin.js";
import { TranslationMixin as d } from "../../component.mixin/translation.mixin.js";
import { ThemeColor as o } from "../../theme/prop/color.js";
import { ThemeFontSize as m } from "../../theme/prop/font-size.js";
import { ThemeShade as a } from "../../theme/prop/shade.js";
import { ThemeSize as s } from "../../theme/prop/size.js";
import { defineComponent as f } from "vue";
import { S as p } from "../../_assets/component.display/message/message.module.js";
const T = f({
  name: "MessageComponent",
  components: { Title: i, Button: n },
  mixins: [
    u,
    d({
      defaults: {
        close: "Close"
      }
    })
  ],
  props: {
    /**
     * Override the border option
     */
    isBorderless: {
      type: Boolean,
      default: !1
    },
    /**
     * The title the be displayed in the message
     */
    title: {
      type: String,
      default: null,
      value: "text"
    },
    /**
     * Title Size of the card
     */
    titleSize: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((e) => Object.values(m).includes(e), "validator")
    },
    /**
     * Title heading tag e.g. h2
     */
    titleHeadingTag: {
      type: String,
      default: null
    },
    /**
     * The text the be displayed in the message
     */
    text: {
      type: String,
      default: null,
      value: "text"
    },
    hasBorderLeft: {
      type: Boolean,
      default: !0
    },
    /**
     * The html the be displayed in the message
     */
    hasHeadingBackgroundColor: {
      type: Boolean,
      default: !0
    },
    /**
     * Name of the predefined color to style the message with
     */
    color: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((e) => Object.values(o).includes(e), "validator"),
      value: o
    },
    /**
     * Display the close button
     */
    closeButton: {
      type: Boolean,
      default: !1
    },
    /**
     * Classes to be applied to be body
     */
    bodyClasses: {
      type: String,
      default: "",
      value: "text"
    },
    /**
     * Classes to be applied to be header
     */
    headerClasses: {
      type: String,
      default: "",
      value: "text"
    },
    /**
     * Change the font size
     */
    size: {
      type: String,
      default: "is-normal",
      validator: /* @__PURE__ */ t((e) => Object.values(s).includes(e), "validator"),
      value: s
    },
    /**
     * color variant
     */
    shade: {
      type: String,
      default: null,
      validator: /* @__PURE__ */ t((e) => Object.values(a).includes(e), "validator"),
      value: a
    },
    dropShadow: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["close"],
  computed: {
    computedBodyClasses() {
      const e = {
        "is-borderless": this.isBorderless,
        "has-background-white": !0
      };
      return Object.keys(e).filter((l) => e[l]).join(" ");
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("message", p);
  }
});
export {
  T as _
};
