import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerCpd } from "./create-introducer-cpd.action";
import { DeleteIntroducerCpd } from "./delete-introducer-cpd.action";
import { DownloadIntroducerCpdCsv } from "./download-introducer-cpd-csv.action";
import { GenerateCpdCertificate } from "./generate-cpd-certificate.action";
import { UpdateIntroducerCpd } from "./update-introducer-cpd.action";

export const Portal2CpdIntroducer = DefineActionBus({
  name: "portal-cpd-introducer",
  actions: {
    CreateIntroducerCpd,
    DeleteIntroducerCpd,
    GenerateCpdCertificate,
    UpdateIntroducerCpd,
    DownloadIntroducerCpdCsv,
  },
});
