import { Portal2Cpd } from "@/action-bus/portal/cpd";
import { Portal2CreditNote } from "@/action-bus/portal/credit-note";
import { Portal2EmailCampaign } from "@/action-bus/portal/email-campaign";
import { Portal2Reports } from "@/action-bus/portal/report";
import { Portal2SortRefer } from "@/action-bus/portal/sort-refer";
import { Portal2UploadForm } from "@/action-bus/portal/upload-form";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Portal2Admin } from "./admin";
import { Portal2Appointments } from "./appointments";
import { Portal2Cpds } from "./cpds";
import { Portal2Dashboard } from "./dashboard";
import { Portal2EmailCampaigns } from "./email-campaigns";
import { Portal2Introducer } from "./introducer";
import { Portal2Kinvault } from "./kinvault";
import { Portal2Kinvaults } from "./kinvaults";
import { Portal2KnowledgeBase } from "./knowledge-base";
import { Portal2Lead } from "./lead";
import { Portal2Leads } from "./leads";
import { Portal2Officer } from "./officer";
import { Portal2Order } from "./order";
import { Portal2Orders } from "./orders";
import { Portal2Settings } from "./settings";
import { Portal2StorageRequests } from "./storage-requests";
import { Portal2Subscriptions } from "./subscriptions";
import { Portal2TrustedPeople } from "./trusted-people";
import { Portal2WillRevision } from "./will-revision";

export const Portal = DefineActionBus({
  name: "portal",
  actions: {
    admin: Portal2Admin,
    appointments: Portal2Appointments,
    cpd: Portal2Cpd,
    cpds: Portal2Cpds,
    creditNote: Portal2CreditNote,
    dashboard: Portal2Dashboard,
    emailCampaign: Portal2EmailCampaign,
    emailCampaigns: Portal2EmailCampaigns,
    introducer: Portal2Introducer,
    kinvault: Portal2Kinvault,
    kinvaults: Portal2Kinvaults,
    knowledgeBase: Portal2KnowledgeBase,
    reports: Portal2Reports,
    lead: Portal2Lead,
    leads: Portal2Leads,
    officer: Portal2Officer,
    order: Portal2Order,
    orders: Portal2Orders,
    settings: Portal2Settings,
    storageRequests: Portal2StorageRequests,
    subscriptions: Portal2Subscriptions,
    trustedPoeple: Portal2TrustedPeople,
    uploadForm: Portal2UploadForm,
    willRevision: Portal2WillRevision,
    sortRefer: Portal2SortRefer,
  },
});
