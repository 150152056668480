<template>
  <Card title="Agreements" class="agreements-report">
    <p>Agreement additions</p>
    <MasterListFilters
      :filters="$data.filters"
      :track-changes="false"
      @submit="load"
    />
    <div
      v-if="datasets.length > 0"
      class="chart-container"
      style="position: relative; height: 40vh"
    >
      <BarChart
        :datasets="datasets"
        :labels="labels"
        :options="{
          maintainAspectRatio: false,
        }"
      />
    </div>
    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="columns"
      :row-classes="rowClasses"
      :rows="results"
      @loaded="load"
    >
      <!-- Whilst this can be shared amongst report, it feels the wrong place for it-->
      <template #date="{ row }">
        {{ row.year }}
        <span v-if="$data.filters?.localData?.granularity === 'week'">
          W{{ row.week }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'month'">
          <!-- Best way to get month name? -->
          {{ row.month }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'quarter'">
          {{ row.quarter }}
        </span>
      </template>
    </Table>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "AgreementsReport",
  "route": "AgreementsReportRoute",
  "selector": ".agreements-report"
}
</cypress-wrapper>

<script lang="ts">
import BarChart from "@kinherit/framework/component.chart/bar-chart";
import Table from "@kinherit/framework/component.display/table";
import { defineComponent } from "vue";
import { AgreementsReportRoute } from ".";
import {
  hasCurrentRowHighlighting,
  quickPercentage,
} from "../../data/helper-functions";

import { TAgreementReportResult } from "@/action-bus/portal/report/compute-agreements-report.action";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { ReadAgreementsReportFilters } from "../../form/read-agreements-report.form";

export default defineComponent({
  name: AgreementsReportRoute,
  components: {
    Card,
    MasterListFilters,
    BarChart,
    Table,
  },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal.reports)],
  data: () => ({
    datasets: Array<{
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }>(),
    labels: Array<string>(),
    results: [] as any[],
    filters: ReadAgreementsReportFilters(),
    columns: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "Date",
        slot: "date",
      },
      {
        title: "Added",
        class: "has-text-weight-bold",
        map: (results: TAgreementReportResult) => results.agreementCount,
      },
      {
        title: "Signed", // the number of leads that are in some sort of pending status, i.e new bld nurt rebk pot inp inp2 wr
        mapHtml: (results: TAgreementReportResult) => {
          return quickPercentage(results.signedCount, results.agreementCount);
        },
      },
      {
        title: "Total Signed",
        class: "has-text-weight-bold",
        map: (results: TAgreementReportResult) => results.culmativeSignedCount,
      },
      {
        title: "Total Issued",
        class: "has-text-weight-bold",
        map: (results: TAgreementReportResult) => results.culmativeCount,
      },
    ],
  }),
  methods: {
    async load() {
      // const result = await window.Kernel.ActionBus.report.agreements(
      //   this.filters.localData,
      // );
      const result = await this.$actionBus.Agreements(this.filters.localData);

      this.datasets = result.datasets;
      this.labels = result.labels.map((label) => label.toString());
      this.results = result.results;
    },
    rowClasses({
      row,
    }: {
      index: number;
      row: unknown;
    }): Record<string, boolean> {
      return {
        "has-background-highlight-light": hasCurrentRowHighlighting(
          this.filters.localData.granularity,
          row,
        ),
      };
    },
  },
});
</script>
