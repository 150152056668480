// EmailTemplate

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { EmailTemplate } from "@kinherit/sdk/model/email-template";

export const UpdateEmailTemplate = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/admin/email-template/{emailTemplate}",
  method: "patch",
  parse: (target: EmailTemplate) => ({
    body: {
      name: target.$data.name,
      subject: target.$data.subject,
      body: target.$data.body,
      allowAttachments: target.$data.allowAttachments,
      strictAttachments: target.$data.strictAttachments,
      notes: target.$data.notes,
      requiredAttachments: target.$data.requiredAttachments,
      type: target.$data.type,
      includeTo: target.$data.includeTo,
      preloadedFiles: target.$data.preloadedFiles,
    },
    params: {
      emailTemplate: target,
    },
  }),
});
