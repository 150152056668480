var m = Object.defineProperty;
var s = (o, e) => m(o, "name", { value: e, configurable: !0 });
import { _ as u } from "../../../component.input/currency-field/currency-field.vue_vue_type_script_lang.js";
import { resolveComponent as r, createBlock as f, openBlock as g, mergeProps as h, withCtx as l, renderSlot as t, createVNode as v, createTextVNode as C, toDisplayString as b } from "vue";
import { b as n } from "../../../_assets/component.input/currency-field/currency-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as k } from "../../../_plugins/_plugin-vue_export-helper.js";
function w(o, e, y, B, $, V) {
  var a, i;
  const d = r("Button"), p = r("BaseInputField");
  return g(), f(p, h({ ref: "refInput" }, o.$data.$cypress, {
    value: o.computedValue,
    "onUpdate:value": e[0] || (e[0] = (c) => o.computedValue = c),
    class: `${o.wrapperClasses} currency-field`,
    label: o.label,
    "input-tag": "input",
    placeholder: "0",
    step: 0.01,
    "icon-left": o.iconLeft,
    "icon-right-override": o.computedIconRight,
    validators: o.validators,
    message: o.computedMessage,
    "message-color": o.computedMessageColor,
    size: o.size,
    color: o.color,
    "is-loading": o.isLoading,
    state: o.state,
    "show-validation": o.computedShowValidation,
    "left-icon-click": o.leftIconClick,
    "right-icon-click": o.rightIconClick,
    "is-fullwidth": o.isFullwidth,
    "is-grouped": o.isGrouped,
    autofocus: o.autofocus,
    autocomplete: o.autocomplete,
    "show-clear-button": o.showClearButton || ((a = o.value) == null ? void 0 : a.amount) !== null && ((i = o.value) == null ? void 0 : i.amount) !== void 0,
    onClear: o.clear,
    onFocus: o.focus,
    onBlur: o.handleBlur,
    onSubmit: o.keydown
  }), {
    left: l(() => [
      t(o.$slots, "left", { value: o.computedValue }),
      v(d, {
        "aria-label": o.computedCurrencyName,
        size: o.size,
        color: "is-light-grey",
        onClick: o.exchange
      }, {
        default: l(() => [
          C(b(o.computedCurrencySymbol), 1)
        ]),
        _: 1
      }, 8, ["aria-label", "size", "onClick"])
    ]),
    right: l(() => [
      t(o.$slots, "right", { value: o.computedValue })
    ]),
    _: 3
  }, 16, ["value", "class", "label", "icon-left", "icon-right-override", "validators", "message", "message-color", "size", "color", "is-loading", "state", "show-validation", "left-icon-click", "right-icon-click", "is-fullwidth", "is-grouped", "autofocus", "autocomplete", "show-clear-button", "onClear", "onFocus", "onBlur", "onSubmit"]);
}
s(w, "_sfc_render");
typeof n == "function" && n(u);
const L = /* @__PURE__ */ k(u, [["render", w]]);
export {
  L as default
};
