<template>
  <Message
    :title="title"
    size="is-small"
    color="is-grey"
    shade="is-light"
    drop-shadow
    title-heading-tag="h6"
    title-size="is-6"
  >
    <slot />
  </Message>
</template>

<script lang="ts">
import Message from "@kinherit/framework/component.display/message";
import { defineComponent } from "vue";

export default defineComponent({
  name: "InfoBlock",
  components: { Message },
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
});
</script>
