import { Api } from "@kinherit/sdk/api";
import { IntroducerFeePaymentRun } from "@kinherit/sdk";

// IntroducerFeePaymentRun, IntroducerFeePayment
export const PublishIntroducerFeePaymentRun = async (
  introducerFeePaymentRun: IntroducerFeePaymentRun,
) => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer/fees/payment-run/{introducerFeePaymentRun}/publish",
    {
      introducerFeePaymentRun: introducerFeePaymentRun,
    },
  )
    .method("post")
    .result();
};
