<template>
  <UploadFormPage v-if="accountReferral" :account-referral="accountReferral" />
</template>

<cypress-wrapper lang="json">
{
  "name": "LeadDetailsUploadFormWrapper",
  "route": "LeadDetailsUploadForm",
  "extends": {
    "name": "UploadFormPageWrapper",
    "path": "@/shared/component/upload-form/UploadFormPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import { LeadDetailsMixin } from "@/module/lead/mixin/lead-details.mixin";
import UploadFormPage from "@/shared/component/upload-form/UploadFormPage.vue";
import { AccountReferral } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { LeadDetailsUploadFormRoute } from ".";

export default defineComponent({
  name: LeadDetailsUploadFormRoute,
  components: {
    UploadFormPage,
  },
  mixins: [LeadDetailsMixin],
  computed: {
    accountReferral(): AccountReferral | null {
      return this.lead?.referral ?? null;
    },
  },
});
</script>
