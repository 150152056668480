var u = Object.defineProperty;
var l = (e, t) => u(e, "name", { value: t, configurable: !0 });
import { defineComponent as d, createVNode as o, createTextVNode as n } from "vue";
import p from "../../../../../../../../../../../../_vue/component.display/icon/icon.js";
import f from "../../../../../../../../../../../../_vue/component.display/pagination/pagination.js";
import c from "../../../../../../../../../../../../_vue/component.input/checkbox-field/checkbox-field.js";
import h from "../../../../../../../../../../../../_vue/component.input/select-field/select-field.js";
import m from "../../../../../../../../../../../../component.mixin/route-storage-mixin.js";
import { TranslationMixin as g } from "../../../../../../../../../../../../component.mixin/translation.mixin.js";
import { KernelModes as y } from "../../../../../../../../../../../../core/kernel-mode.js";
import { CypressHelper as S } from "../../../../../../../../../../../../cypress/component-helpers.js";
import { ClipBoardService as B } from "../../../../../../../../../../../../service/clip-board-service.js";
import { S as k } from "../../../../../../../../../../../../_assets/component.display/table/table.module.js";
var r = /* @__PURE__ */ function(e) {
  return e[e.SortBy = 0] = "SortBy", e[e.SortDirection = 1] = "SortDirection", e[e.Page = 2] = "Page", e[e.PerPage = 3] = "PerPage", e;
}(r || {});
const E = /* @__PURE__ */ d({
  name: "TableComponent",
  components: {
    Pagination: f,
    CheckboxField: c,
    Icon: p,
    SelectField: h
  },
  mixins: [m(!1), g({
    defaults: {
      summary: /* @__PURE__ */ l((e, t, i) => e ? o("span", null, [e.format(), n(" Record"), e > 0 ? "s" : "", n(" Returned"), t ? ` - Page ${t}` : "", i && t ? ` of ${i}` : ""]) : "", "summary"),
      selected: /* @__PURE__ */ l((e) => e ? o("span", null, [e.format(), n(" Selected")]) : "", "selected"),
      copyToClipboard: "Copy to clipboard",
      sortBy: "Sort By",
      orderBy: "Order By",
      emptyMessage: "No Records Found"
    }
  })],
  props: {
    emptyMessage: {
      type: String,
      default: "No Records Found"
    },
    size: {
      type: String,
      default: "is-normal"
    },
    isClickable: {
      type: Boolean,
      default: !1
    },
    /**
     * Force the details slot to alway be expanded
     */
    isExpanded: {
      type: Boolean,
      default: !1
    },
    /**
     * Force the table columns to be evenly spaced
     */
    isFixed: {
      type: Boolean,
      default: !1
    },
    /**
     * Show the checkbox field
     */
    checkboxField: {
      type: Boolean,
      default: !1
    },
    /**
     * Show a loading animation over the top of the component
     */
    isSkeleton: {
      type: Boolean,
      default: !1
    },
    /**
     * Current page the table is on (pagination)
     */
    page: {
      type: Number,
      default: null
    },
    /**
     * Show the footer controls including Sort by and Order by select boxes
     */
    showFooterControls: {
      type: Boolean,
      default: !0
    },
    /**
     * Show header controls
     */
    showHeaderControls: {
      type: Boolean,
      default: !0
    },
    /**
     * Total number of pages available to this table (pagination)
     */
    totalPages: {
      type: Number,
      default: null
    },
    /**
     * Total number of records available to this table
     */
    totalRecords: {
      type: Number,
      default: null
    },
    /**
     * Configuration settings for the tables columns
     */
    columns: {
      type: Array,
      required: !0
    },
    /**
     * Whether to repeat the table's headers in the footer of the table as well
     */
    footer: {
      type: Array,
      default: /* @__PURE__ */ l(() => [], "default")
    },
    /**
     * The rows of data to display to the user
     */
    rows: {
      type: Array,
      default: /* @__PURE__ */ l(() => [], "default")
    },
    /**
     * Should borders be added to the table
     */
    isBordered: {
      type: Boolean,
      default: !1
    },
    /**
     * Remove cell padding to make the table smaller
     */
    isNarrow: {
      type: Boolean,
      default: !1
    },
    /**
     * Is the user able to hover and click on table items
     */
    isHoverable: {
      type: Boolean,
      default: !1
    },
    /**
     * Should the table match the full width of its container
     */
    isFullwidth: {
      type: Boolean,
      default: !0
    },
    /**
     * Should the table scroll if it no longer fits its container
     */
    isScrollable: {
      type: Boolean,
      default: !1
    },
    /**
     * Should the table be striped
     */
    isStriped: {
      type: Boolean,
      default: !1
    },
    /**
     * Force the table to render in the mobile style
     */
    isMobile: {
      type: Boolean,
      default: !1
    },
    /**
     * Field to use to decided if an row has been checked
     */
    trackingField: {
      type: [String, Function],
      default: null
    },
    /**
     * The rows that have been checked
     */
    value: {
      type: Array,
      default: null
    },
    /**
     * v-model:perPage
     */
    perPage: {
      type: Number,
      default: 15
    },
    /**
     * Enabled the items per page select box
     */
    enablePerPage: {
      type: Boolean,
      default: !0
    },
    /**
     * v-model:sortBy
     */
    sortBy: {
      type: String,
      default: null
    },
    /**
     * v-model:sortDirection
     */
    sortDirection: {
      type: String,
      default: "asc"
    },
    /**
     * Enable the sort by & direction select boxes
     */
    sortByOptions: {
      type: Object,
      default: /* @__PURE__ */ l(() => ({}), "default")
    },
    /**
     * Classes to apply to the table rows
     */
    rowClasses: {
      type: Function,
      default: /* @__PURE__ */ l(() => "", "default")
    },
    /**
     * The title of the table
     */
    title: {
      type: String,
      default: null
    },
    /**
     * The subtitle of the table
     */
    subtitle: {
      type: String,
      default: null
    },
    /**
     * Display the copy to clipboard
     */
    copyToClipboard: {
      type: Boolean,
      default: !1
    },
    /**
     * Compress the empty message
     */
    compressEmptyMessage: {
      type: Boolean,
      default: !1
    },
    /**
     * Clicking on the row will expand the details slot
     */
    clickToExpand: {
      type: Boolean,
      default: !1
    },
    /**
     * Align the tables text to the middle
     */
    isAlignedMiddle: {
      type: Boolean,
      default: !1
    }
  },
  emits: {
    /**
     * Emits the table row that has been selected
     */
    click: null,
    /**
     * Emits the page number the has been selected
     */
    "update:page": null,
    /**
     * Emits the rows of the table that have been checked
     */
    "update:value": null,
    /**
     * Emit the selected number of items per a page to be displayed
     */
    "update:perPage": null,
    /**
     * Emit the selected column to sort by
     */
    "update:sortBy": null,
    /**
     * Emit the selected sort directed
     */
    "update:sortDirection": null,
    /**
     * Emit when the component has finished loading peristed data
     */
    loaded: null
  },
  data: /* @__PURE__ */ l((e) => ({
    expanded: Array(),
    sort: {
      column: null,
      ascending: !0
    },
    $cypress: S({
      ctx: e,
      labelField: ["title", "subtitle"]
    })
  }), "data"),
  computed: {
    computedEmptyMessage() {
      return this.emptyMessage ?? this.$t.local.emptyMessage;
    },
    computedFooterColumns() {
      return this.footer.filter((e, t) => this.columns[t] && !this.shouldHide(this.columns[t].hidden)).map((e) => typeof e == "string" || typeof e == "number" ? {
        value: e
      } : e === null ? {
        value: null,
        hidden: !0
      } : e);
    },
    compEnableSortByOrderBy() {
      return Object.keys(this.sortByOptions).length > 0;
    },
    compShowPagination() {
      return this.totalPages !== null && this.computedPage !== null;
    },
    computedSortBy: {
      get() {
        return this.sortBy;
      },
      set(e) {
        this.$emit("update:sortBy", e), this.setState(r.SortBy, e);
      }
    },
    computedSortDirection: {
      get() {
        return this.sortDirection;
      },
      set(e) {
        this.$emit("update:sortDirection", e ?? "asc"), this.setState(r.SortDirection, e ?? "asc");
      }
    },
    computedPerPage: {
      get() {
        return this.perPage.toString();
      },
      set(e) {
        this.$emit("update:perPage", Number.parseInt(e)), this.setState(r.PerPage, e);
      }
    },
    computedColumns() {
      if (this.isSkeleton === !0)
        return [{
          title: "",
          field: "x"
        }];
      const e = ["map", "component", "mapHtml", "slot", "field"];
      return this.columns.filter((t) => !this.shouldHide(t.hidden)).map((t) => (e.forEach((i) => {
        const s = i;
        return s in t && t[s] === void 0 && delete t[s], t;
      }), t));
    },
    computedRows() {
      if (this.isSkeleton === !0)
        return [{
          x: ""
        }, {
          x: ""
        }, {
          x: ""
        }, {
          x: ""
        }, {
          x: ""
        }, {
          x: ""
        }, {
          x: ""
        }, {
          x: ""
        }, {
          x: ""
        }, {
          x: ""
        }];
      let e = this.rows;
      const t = this.sort.column === null ? null : this.columns[this.sort.column];
      if (t !== null && "field" in t) {
        const i = t.field;
        e = this.rows.clone().sort((s, a) => s[i].localeCompare(a[i]));
      } else if (t !== null && "map" in t) {
        const i = t.map;
        e = this.rows.clone().sort((s, a) => (i(s) ?? "").toString().localeCompare((i(a) ?? "").toString()));
      }
      return this.sort.ascending !== !0 && (e = e.reverse()), e;
    },
    computedPage: {
      get() {
        return this.page;
      },
      set(e) {
        this.page !== e && (this.expanded = []), this.$emit("update:page", e), this.setState(r.Page, e);
      }
    },
    isTestMode() {
      return window.Kernel.Mode === y.Test;
    },
    hasDetailsSlot() {
      return Object.keys(this.$slots).includes("details");
    },
    hasExpendSlot() {
      return Object.keys(this.$slots).includes("expand");
    },
    showCheckboxColumn() {
      return this.checkboxField && this.trackingField !== null;
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("table", k);
  },
  mounted() {
    const e = this.getState(r.Page);
    e !== void 0 && Number.parseInt(e) !== this.page && this.$emit("update:page", Number.parseInt(e));
    const t = this.getState(r.PerPage);
    t !== void 0 && Number.parseInt(t) !== this.perPage && this.$emit("update:perPage", Number.parseInt(t));
    const i = this.getState(r.SortBy);
    i !== void 0 && i !== this.sortBy && this.$emit("update:sortBy", i);
    const s = this.getState(r.SortDirection);
    s !== void 0 && s !== this.sortDirection && this.$emit("update:sortDirection", s), this.$emit("loaded");
  },
  methods: {
    getStyle(e) {
      return e.style;
    },
    shouldHide(e) {
      return typeof e == "boolean" ? e : (e == null ? void 0 : e()) ?? !1;
    },
    getBinding(e, t) {
      return typeof e == "function" ? e(t) : e;
    },
    sortColumn(e) {
      const t = this.columns[e];
      !("sort" in t) || t.sort !== !0 || (this.sort.column === e && (this.sort.ascending = !this.sort.ascending), this.sort.column = e);
    },
    compareItems(e, t) {
      return this.trackingField === null ? !1 : this.trackingValue(e) === this.trackingValue(t);
    },
    trackingValue(e) {
      return this.trackingField === null ? null : typeof this.trackingField == "function" ? this.trackingField(e) : e[this.trackingField];
    },
    isChecked(e) {
      if (this.trackingField === null || this.value === null)
        return !1;
      if (e === void 0) {
        const t = this.value.map((s) => this.trackingValue(s)), i = this.rows.map((s) => this.trackingValue(s));
        return t.intersection(i).length === i.length;
      }
      return this.value.map((t) => this.trackingValue(t)).includes(this.trackingValue(e));
    },
    clickRow(e, t, i) {
      this.$emit("click", t, i), this.clickToExpand && (this.expanded.includes(e) ? this.expanded = this.expanded.remove(e) : this.expanded.push(e));
    },
    toggleRow(e) {
      let t = null;
      if (e === void 0) {
        this.value !== null ? t = this.value : t = [];
        const i = !this.isChecked();
        this.rows.forEach((s) => {
          i !== this.isChecked(s) && (i ? t.push(s) : t = t.filter((a) => this.compareItems(a, s)));
        }), this.$emit("update:value", t);
        return;
      }
      this.value === null ? t = [e] : this.isChecked(e) ? t = this.value.filter((i) => !this.compareItems(i, e)) : t = this.value.concat(e), this.$emit("update:value", t);
    },
    getRowClasses(e) {
      var s;
      const t = this.rows[e], i = (s = this.rowClasses) == null ? void 0 : s.call(this, {
        index: e,
        row: t,
        isChecked: this.isChecked(t),
        isExpanded: this.expanded.includes(e)
      });
      return i ?? "";
    },
    async doCopyToClipBoard() {
      const e = [];
      this.computedColumns.forEach((i) => {
        "field" in i ? e.push([i.title, ...this.rows.map((s) => s[i.field])]) : "map" in i ? e.push([i.title, ...this.rows.map((s) => i.map(s) ?? void 0)]) : "mapHtml" in i && e.push([i.title, ...this.rows.map((s) => i.mapHtml(s) ?? void 0)]);
      });
      const t = e[0].map((i, s) => e.map((a) => a[s]));
      await B.copyTableToClipBoard(t);
    }
  }
});
export {
  E as _
};
