import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { CompanyPartnerPortalPermissions } from "@kinherit/sdk";

export const IntroducerCompanyPartnerPortalSettingsForm = (
  permissions: CompanyPartnerPortalPermissions,
) =>
  defineForm({
    name: "introducer-company-partner-portal-settings",
    data: () => permissions,
    formAreas: (data) => [
      defineFormArea({
        name: "company-details",
        data,
        template: GridLayout([
          ["allowPartnerPortal"],
          ["enableClients"],
          ["viewOrderProducts"],
          ["enableStandaloneKinvault"],
          ["allowKinvaultAccess"],
          ["enableFees"],
        ]),
        components: () => ({
          allowPartnerPortal: [
            FormCheckboxField({
              reactive: true,
              props: {
                label: "Allow Partner Portal",
                message: "Enable the Partner Portal for this company",
              },
              models: {
                value: "allowPartnerPortal",
              },
            }),
          ],
          enableClients: [
            FormCheckboxField({
              props: {
                label: "Enable Clients",
                message: "Allow access to the main client module",
              },
              models: {
                value: "enableClients",
              },
            }),
          ],
          viewOrderProducts: [
            FormCheckboxField({
              props: {
                label: "View Order Products",
                message:
                  "Warning: Allows Introducers to view what products were ordered. ONLY enable on request, and for FCA regulated entities which have a direct client relationship.",
                messageColor: "has-text-warning-dark",
              },
              models: {
                value: "viewOrderProducts",
              },
            }),
          ],
          enableFees: [
            FormCheckboxField({
              props: {
                label: "Enable Fees",
                message:
                  "Allow access to the fees module: reserved for future use",
              },
              models: {
                value: "enableFees",
              },
            }),
          ],
          enableStandaloneKinvault: [
            FormCheckboxField({
              props: {
                label: "Enable Standalone Kinvault",
                message: "Allow access to the standalone Kinvault module",
              },
              models: {
                value: "enableStandaloneKinvault",
              },
            }),
          ],
          allowKinvaultAccess: [
            FormCheckboxField({
              props: {
                label: "Allow Kinvault Access",
                message:
                  "Allow access to the client's Kinvaults: Warning: Allows an IFA to request access to a user's Kinvault Asset Register. ONLY enable for FCA regulated entities which have a direct client relationship.",
                messageColor: "has-text-warning-dark",
              },
              models: {
                value: "allowKinvaultAccess",
              },
            }),
          ],
        }),
      }),
    ],
  });
