import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ComputeFees } from "./compute-fees.action";
import { DownloadInvoice } from "./download-invoice.action";
import { PushToXero } from "./push-to-xero.action";

export const Portal2AdminBrandedKinvaultInvitationFees = DefineActionBus({
  name: "portal-admin-branded-kinvault-invitation-fees",
  actions: {
    ComputeFees,
    DownloadInvoice,
    PushToXero,
  },
});
