var a = Object.defineProperty;
var d = (e, m) => a(e, "name", { value: m, configurable: !0 });
import { _ as u } from "../../../component.layout/level/level.vue_vue_type_script_lang.js";
import { createElementBlock as l, openBlock as s, normalizeClass as v, createCommentVNode as r, Fragment as i, renderList as o, renderSlot as n } from "vue";
import { _ as p } from "../../../_plugins/_plugin-vue_export-helper.js";
const c = {
  key: 0,
  class: "level-left"
}, f = {
  key: 1,
  class: "level-item"
}, y = {
  key: 2,
  class: "level-right"
};
function k(e, m, h, S, $, _) {
  return s(), l("div", {
    class: v({
      level: !0,
      "is-mobile": e.isMobile
    })
  }, [
    e.leftSlots.isEmpty() ? r("", !0) : (s(), l("div", c, [
      (s(!0), l(i, null, o(e.leftSlots, (t) => (s(), l("div", {
        key: t,
        class: "level-item"
      }, [
        n(e.$slots, t)
      ]))), 128))
    ])),
    e.middleSlots.isEmpty() ? r("", !0) : (s(), l("div", f, [
      (s(!0), l(i, null, o(e.middleSlots, (t) => (s(), l("div", {
        key: t,
        class: "level-item"
      }, [
        n(e.$slots, t)
      ]))), 128))
    ])),
    e.rightSlots.isEmpty() ? r("", !0) : (s(), l("div", y, [
      (s(!0), l(i, null, o(e.rightSlots, (t) => (s(), l("div", {
        key: t,
        class: "level-item"
      }, [
        n(e.$slots, t)
      ]))), 128))
    ]))
  ], 2);
}
d(k, "_sfc_render");
const C = /* @__PURE__ */ p(u, [["render", k]]);
export {
  C as default
};
