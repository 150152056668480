import { IntroducerFeePaymentRunDetailsBreadCrumb } from "@/module/introducer.fees/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { Routes as IntroducerFeePaymentRunDetailsItemsRoutes } from "./items/index";

export const IntroducerFeePaymentRunDetailsRoute =
  "IntroducerFeePaymentRunDetails";
const IntroducerFeePaymentRunDetailsUri =
  "/introducer/fees/payment-run/:introducerFeePaymentRun";
export type IntroducerFeePaymentRunDetailsParams = RouteParamObject<
  typeof IntroducerFeePaymentRunDetailsUri
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerFeePaymentRunDetailsRoute,
    path: IntroducerFeePaymentRunDetailsUri,
    component: () =>
      import(
        /* webpackChunkName: "IntroducerFeePaymentRunDetails" */ "./IntroducerFeePaymentRunDetails.vue"
      ),
    meta: {
      breadcrumbs: IntroducerFeePaymentRunDetailsBreadCrumb,
    },
    children: [...IntroducerFeePaymentRunDetailsItemsRoutes],
  },
];
