var a = Object.defineProperty;
var i = (e, t) => a(e, "name", { value: t, configurable: !0 });
import { FieldMixin as l } from "../../component.mixin/field-mixin.js";
import { TranslationMixin as s } from "../../component.mixin/translation.mixin.js";
import { CypressHelper as r } from "../../cypress/component-helpers.js";
import { ThemeIconName as u } from "../../theme/prop/icon.js";
import { Uuid as n } from "@kinherit/ts-common";
import { defineComponent as m } from "vue";
import d from "../../_vue/component.input/base-input-field/base-input-field.js";
import o from "../../_vue/component.input/button/button.js";
const y = m({
  name: "EmailFieldComponent",
  components: { BaseInputField: d, Button: o },
  mixins: [
    l({ validators: ["email"] }),
    s({
      defaults: {
        placeholder: "Email",
        generateLabel: "Generate email address"
      }
    })
  ],
  props: {
    /**
     * v-model:value
     *
     * @values null, string
     */
    value: {
      type: String,
      default: null
    },
    /**
     * Name of the email address
     */
    isNamed: {
      type: Boolean,
      default: !1
    },
    /**
     * Placeholder text
     */
    placeholder: {
      type: String,
      default: "Email"
    },
    /**
     * Domain name for the automatically generated email address
     */
    generatedPostfix: {
      type: String,
      default: "temp.kinherit.co.uk"
    },
    /**
     * The start of the automatically generated email address
     */
    generatedPrefix: {
      type: String,
      default: null
    },
    /**
     * Show the generate button
     */
    showGenerateButton: {
      type: Boolean,
      default: !1
    }
  },
  emits: [
    /**
     * Emits whether or not the input value has passed its validators
     *
     * @property {boolean}
     */
    "is-valid",
    /**
     * Emits when the input field is blurred
     */
    "blur",
    /**
     * Emits when value is changed
     */
    "update:value"
  ],
  data: /* @__PURE__ */ i((e) => ({
    $cypress: r({ ctx: e, labelField: "label" })
  }), "data"),
  computed: {
    computedValidators() {
      return this.validators.concat(["email"]);
    },
    iconLeftComputed() {
      return this.iconLeft !== null ? this.iconLeft : u.Email;
    },
    // value as `${string} <${string}>` | string
    computedEmail: {
      get() {
        return this.isNamed ? this.value === null ? "" : this.value.includes("<") ? this.value.split("<")[1].split(">")[0].trim() : this.value : this.value;
      },
      set(e) {
        var t;
        if (!this.isNamed) {
          this.$emit("update:value", e);
          return;
        }
        if (e === "" && this.computedName === "") {
          this.$emit("update:value", "");
          return;
        }
        (t = this.value) != null && t.includes("<") ? this.$emit("update:value", `${this.value.split("<")[0]} <${e}>`) : this.$emit("update:value", e);
      }
    },
    computedName: {
      get() {
        var e;
        return this.value === null ? "" : (e = this.value) != null && e.includes("<") ? this.value.split("<")[0].trim() : "";
      },
      set(e) {
        if (e === "" && this.computedEmail === "") {
          this.$emit("update:value", "");
          return;
        }
        this.value.includes("<") ? this.$emit("update:value", `${e} <${this.value.split("<")[1]}`) : this.$emit("update:value", `${e} <${this.computedEmail}>`);
      }
    },
    isNameDisabled() {
      return ["", null, void 0].includes(this.computedEmail) ? !0 : this.state === "disabled";
    }
  },
  methods: {
    generateEmailAddress() {
      const e = [null, void 0, ""].includes(
        this.generatedPrefix
      ) ? "temp" : this.generatedPrefix, t = n.generate().substring(0, 6);
      this.computedEmail = `${e}-${t}@${this.generatedPostfix}`;
    }
  }
});
export {
  y as _
};
