var p = Object.defineProperty;
var n = (o, e) => p(o, "name", { value: e, configurable: !0 });
import { _ as i } from "../../../component.input/context-menu/context-menu.vue_vue_type_script_lang.js";
import { resolveComponent as r, createBlock as m, openBlock as a } from "vue";
import { b as t } from "../../../_assets/component.input/context-menu/context-menu.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as u } from "../../../_plugins/_plugin-vue_export-helper.js";
function d(o, e, f, c, w, $) {
  const s = r("DropDownMenu");
  return a(), m(s, {
    class: "context-menu",
    "is-static": o.isStatic,
    "show-more-button": o.showMoreButton,
    "show-close-button": !o.hideCancelButton,
    show: !0,
    options: o.computedItems,
    "map-options": {
      label: o.titleField,
      line1: o.line1Field,
      line2: o.line2Field,
      iconLeft: o.iconLeftField,
      iconRight: o.iconRightField,
      value: o.trackingField
    },
    "empty-message": o.emptyMessage,
    onClose: e[0] || (e[0] = (l) => o.$emit("close")),
    "onUpdate:value": o.onClick,
    onMore: e[1] || (e[1] = (l) => o.$emit("more"))
  }, null, 8, ["is-static", "show-more-button", "show-close-button", "options", "map-options", "empty-message", "onUpdate:value"]);
}
n(d, "_sfc_render");
typeof t == "function" && t(i);
const M = /* @__PURE__ */ u(i, [["render", d]]);
export {
  M as default
};
