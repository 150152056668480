import { Api } from "@kinherit/sdk";

export interface UpdateSortReferCaseReferenceMessage {
  externalId: string;
  ref: string;
}

export const UpdateSortReferCaseReference = async (
  message: UpdateSortReferCaseReferenceMessage,
): Promise<void> => {
  const request = Api.resource(
    "external",
    "/v2/external/sort-refer/case-reference",
  )
    .method("put")
    .body({
      externalId: message.externalId,
      ref: message.ref,
    });

  await request.result();
};
