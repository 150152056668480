// IntroducerNetwork

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerNetwork } from "@kinherit/sdk/model/introducer-network";

export const CreateIntroducerNetwork = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/introducer/networks",
  method: "post",
  parse: (target: IntroducerNetwork) => ({
    body: {
      name: target.$data.name,
      companyCount: target.$data.companyCount,
    },
  }),
});
