var u = Object.defineProperty;
var l = (o, e) => u(o, "name", { value: e, configurable: !0 });
import { _ as t } from "../../../component.input/time-field/time-field.vue_vue_type_script_lang.js";
import { resolveComponent as p, createBlock as d, openBlock as m, mergeProps as c, createSlots as f, withCtx as a, renderSlot as s } from "vue";
import { b as i } from "../../../_assets/component.input/time-field/time-field.vue_vue_type_cypress-wrapper_index_0_lang.js";
import { _ as h } from "../../../_plugins/_plugin-vue_export-helper.js";
function g(o, e, v, w, b, k) {
  const r = p("BaseInputField");
  return m(), d(r, c(o.$data.$cypress, {
    value: o.computedValue,
    "onUpdate:value": e[0] || (e[0] = (n) => o.computedValue = n),
    class: `${o.wrapperClasses} time-field`,
    label: o.label,
    "input-tag": "input",
    type: o.hasFocus || o.computedHasValue ? "time" : "text",
    placeholder: o.placeholder,
    min: o.computedMin,
    max: o.computedMax,
    step: o.computedStep,
    validators: o.validators,
    message: o.message,
    "message-color": o.messageColor,
    size: o.size,
    color: o.color,
    "is-loading": o.isLoading,
    "icon-left": o.iconLeft,
    "icon-right-override": o.computedIconRight,
    state: o.state,
    "show-validation": o.computedShowValidation,
    "left-icon-click": o.leftIconClick,
    "right-icon-click": o.rightIconClick,
    "show-clear-button": o.showClearButton,
    "is-fullwidth": o.isFullwidth,
    "is-grouped": o.isGrouped,
    autofocus: o.autofocus,
    autocomplete: o.autocomplete,
    "auto-collapse-label": o.autoCollapseLabel,
    onClear: o.clear,
    onFocus: o.focus,
    onBlur: o.blur,
    onSubmit: o.keydown
  }), f({ _: 2 }, [
    o.showLeftControl ? {
      name: "left",
      fn: a(() => [
        s(o.$slots, "left", { value: o.computedValue })
      ]),
      key: "0"
    } : void 0,
    o.showRightControl ? {
      name: "right",
      fn: a(() => [
        s(o.$slots, "right", { value: o.computedValue })
      ]),
      key: "1"
    } : void 0
  ]), 1040, ["value", "class", "label", "type", "placeholder", "min", "max", "step", "validators", "message", "message-color", "size", "color", "is-loading", "icon-left", "icon-right-override", "state", "show-validation", "left-icon-click", "right-icon-click", "show-clear-button", "is-fullwidth", "is-grouped", "autofocus", "autocomplete", "auto-collapse-label", "onClear", "onFocus", "onBlur", "onSubmit"]);
}
l(g, "_sfc_render");
typeof i == "function" && i(t);
const S = /* @__PURE__ */ h(t, [["render", g]]);
export {
  S as default
};
