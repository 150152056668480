<template>
  <SidebarPage
    v-if="lead && !loading"
    :key="key"
    class="lead-details"
    :name="lead.friendlyName"
    :tabs="computedTabs"
    :has-context-menu="showContextMenu"
    @context-menu="contextMenu"
  >
    <template #below>
      <div class="level mt-3 mb-4">
        <div class="level">
          <div>
            <Avatar
              v-if="lead.ownedBy"
              hide-tooltip
              size="is-small"
              :name="lead.ownedBy?.profile.fullName ?? undefined"
            />
            <Avatar
              v-if="lead.assignedTo"
              hide-tooltip
              size="is-small"
              class="ml-1"
              :name="lead.assignedTo?.profile.fullName ?? undefined"
            />
          </div>
          <DataListWidget
            :details="leadContactDetails"
            class="lead-contact-details"
            direction="is-horizontal"
            :is-full-width="false"
            no-card
          />
          <Icon
            icon="Edit"
            class="is-clickable ml-1"
            tooltip="Edit Profile"
            @click="editProfile"
          />
        </div>
      </div>
    </template>
    <template #sidebar>
      <RouterChildView name="sidebar">
        <LeadDetailsSidebar
          @update-settings="updateSettings"
          @manage-referral="manageReferral"
        />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView name="default">
        <LeadDetailsNotes />
      </RouterChildView>
    </template>
  </SidebarPage>
  <PageNotFound v-else-if="!loading" />
</template>

<cypress-wrapper lang="json">
{
  "name": "LeadDetailsWrapper",
  "route": "LeadDetails",
  "selector": ".lead-details",
  "imports": {
    "LeadDetailsNotesWrapper": "@/module/lead/page/details/notes/LeadDetails.Notes.test",
    "LeadDetailsEmailLogWrapper": "@/module/lead/page/details/email-log/LeadDetails.EmailLog.test",
    "LeadDetailsIfaCallScriptWrapper": "@/module/lead/page/details/ifa-call-script/LeadDetails.IfaCallScript.test",
    "LeadDetailsCallScriptWrapper": "@/module/lead/page/details/call-script/LeadDetails.CallScript.test",
    "LeadDetailsUploadFormWrapper": "@/module/lead/page/details/upload-forms/LeadDetails.UploadForms.test",
    "LeadDetailsChangeLogWrapper": "@/module/lead/page/details/change-log/LeadDetails.ChangeLog.test",
    "LeadDetailsSidebarWrapper": "@/module/lead/page/details/LeadDetails.Sidebar.test"
  },
  "methods": {
    "contextMenu": {
      "type": "context-menu",
      "selector": ".js-page-header-context-menu",
      "items": [
        "Create Note",
        "Convert to Kinvault",
        "Edit Profile",
        "Manage Referral",
        "Update Settings",
        "Delete Lead"
      ]
    },
    "tabs": {
      "type": "tabs",
      "items": [
        "Notes",
        "Emails",
        "IFA Call Script",
        "Call Script",
        "Upload Forms",
        "Changes"
      ]
    },
    "notes": {
      "type": "to-one",
      "selector": "",
      "wrapper": "LeadDetailsNotesWrapper"
    },
    "emailLog": {
      "type": "to-one",
      "selector": "",
      "wrapper": "LeadDetailsEmailLogWrapper"
    },
    "ifaCallScript": {
      "type": "to-one",
      "selector": "",
      "wrapper": "LeadDetailsIfaCallScriptWrapper"
    },
    "callScript": {
      "type": "to-one",
      "selector": "",
      "wrapper": "LeadDetailsCallScriptWrapper"
    },
    "uploadForms": {
      "type": "to-one",
      "selector": "",
      "wrapper": "LeadDetailsUploadFormWrapper"
    },
    "changeLog": {
      "type": "to-one",
      "selector": "",
      "wrapper": "LeadDetailsChangeLogWrapper"
    },
    "sidebar": {
      "type": "to-one",
      "selector": "",
      "wrapper": "LeadDetailsSidebarWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import { LeadDetailsMixin } from "@/module/lead/mixin/lead-details.mixin";
import { LeadDetailsSortReferRoute } from "@/module/lead/page/details/external";
import { LeadDetailsUploadFormRoute } from "@/module/lead/page/details/upload-forms";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { UpdateNoteForm } from "@/shared/form/note/update-note.form";
import { UpdateReferralForm } from "@/shared/form/referral/update-referral.form";
import Avatar from "@kinherit/framework/component.display/avatar";
import Icon from "@kinherit/framework/component.display/icon";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { defineComponent } from "vue";
import { LeadDetailsParams, LeadDetailsRoute } from ".";
import { LeadMasterListRoute } from "..";
import { ConvertLeadToKintinForm } from "../../form/convert-lead-to-kintin.form";
import { UpdateLeadSettingsForm } from "../../form/update-lead-settings.form";
import LeadDetailsSidebar from "./LeadDetails.Sidebar.vue";
import { LeadDetailsCallScriptRoute } from "./call-script";
import { LeadDetailsChangeLogRoute } from "./change-log";
import { LeadDetailsEmailLogRoute } from "./email-log";
import { LeadDetailsIfaCallScriptRoute } from "./ifa-call-script";
import { LeadDetailsIfaCallScript2025Route } from "./ifa-call-script-2025";
import { LeadDetailsNotesRoute } from "./notes";
import LeadDetailsNotes from "./notes/LeadDetails.Notes.vue";
import { LeadDetailsUpdateProfileRoute } from "./update-profile";
export default defineComponent({
  name: LeadDetailsRoute,
  components: {
    PageNotFound,
    RouterChildView,
    LeadDetailsSidebar,
    SidebarPage,
    LeadDetailsNotes,
    Icon,
    Avatar,
    DataListWidget,
  },
  mixins: [LeadDetailsMixin],
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    const params = to.params as LeadDetailsParams;

    // await window.Kernel.ActionBus.lead.lead.record(
    await window.Kernel.ActionBus2.portal.lead.RecordLead({
      message: params.lead,
    });
  },
  data: (ctx) => ({
    ctx,
    key: 0,
    loading: true,
    tab: 0,
  }),
  computed: {
    $params(): LeadDetailsParams {
      return this.$route.params as LeadDetailsParams;
    },
    showContextMenu(): boolean {
      return AuthService.hasPermission("lead:write");
    },
    computedTabs() {
      const tabs = [
        {
          label: "Notes",
          route: {
            name: LeadDetailsNotesRoute,
            params: this.ctx.$params,
          },
          icon: StyleService.icon.notes.icon,
        },
        {
          label: "Emails",
          route: {
            name: LeadDetailsEmailLogRoute,
            params: this.ctx.$params,
          },
          icon: StyleService.icon.email.icon,
        },
        {
          label: "Call Script (2025)",
          route: {
            name: LeadDetailsIfaCallScript2025Route,
            params: this.ctx.$params,
          },
          icon: StyleService.icon.phone.icon,
        },
        {
          label: "IFA Call Script",
          route: {
            name: LeadDetailsIfaCallScriptRoute,
            params: this.ctx.$params,
          },
          icon: StyleService.icon.phone.icon,
        },
        {
          label: "Call Script",
          route: {
            name: LeadDetailsCallScriptRoute,
            params: this.ctx.$params,
          },
          icon: StyleService.icon.phone.icon,
        },
        {
          label: "Upload Forms",
          route: {
            name: LeadDetailsUploadFormRoute,
            params: this.ctx.$params,
          },
          icon: StyleService.icon.uploadForm.icon,
        },
        {
          label: "Changes",
          route: {
            name: LeadDetailsChangeLogRoute,
            params: this.ctx.$params,
          },
          icon: StyleService.icon.history.icon,
        },
      ];

      // External Integations

      if (this.externalIntegrations.includes("sortrefer")) {
        tabs.push({
          label: "SortRefer",
          route: {
            name: LeadDetailsSortReferRoute,
            params: this.ctx.$params,
          },
          icon: StyleService.icon.settings.icon,
        });
      }
      return tabs;
    },
    leadContactDetails(): Array<DataListWidgetDetail> {
      const result = [];

      let primaryOrSecondaryPhoneNumber = this.lead?.primaryProfile
        .primaryPhoneNumber?.tel
        ? this.lead?.primaryProfile.primaryPhoneNumber?.tel
        : this.lead?.secondaryProfile?.primaryPhoneNumber?.tel;

      if (!primaryOrSecondaryPhoneNumber) {
        // try for any other number
        const firstPrimaryPhoneNumber =
          this.lead?.primaryProfile.phoneNumbers.first();
        const firstSecondaryPhoneNumber =
          this.lead?.secondaryProfile?.phoneNumbers.first();
        primaryOrSecondaryPhoneNumber =
          firstPrimaryPhoneNumber?.tel ?? firstSecondaryPhoneNumber?.tel;
      }

      if (primaryOrSecondaryPhoneNumber) {
        result.push({
          label: "Main Phone",
          value: {
            text: primaryOrSecondaryPhoneNumber,
            href: `tel:${primaryOrSecondaryPhoneNumber}`,
          },
        });
      } else {
        result.push({
          label: "Main Phone",
          value: "No phone number",
        });
      }

      let primaryOrSecondaryEmailAddress = this.lead?.primaryProfile
        .primaryEmailAddress?.email
        ? this.lead?.primaryProfile.primaryEmailAddress?.email
        : this.lead?.secondaryProfile?.primaryEmailAddress?.email;

      if (!primaryOrSecondaryEmailAddress) {
        // try for any other email
        const firstPrimaryEmailAddress =
          this.lead?.primaryProfile.emails.first();
        const firstSecondaryEmailAddress =
          this.lead?.secondaryProfile?.emails.first();
        primaryOrSecondaryEmailAddress =
          firstPrimaryEmailAddress?.email ?? firstSecondaryEmailAddress?.email;
      }

      if (primaryOrSecondaryEmailAddress) {
        result.push({
          label: "Main Email",
          value: {
            text: primaryOrSecondaryEmailAddress,
            href: `mailto:${primaryOrSecondaryEmailAddress}`,
          },
        });
      } else {
        result.push({
          label: "Main Email",
          value: "No email address",
        });
      }
      return [...result];
    },
  },
  async mounted(): Promise<void> {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      if (!this.lead) {
        return;
      }

      const convertOptions = ["converted"].includes(
        this.lead.status?.value as string,
      )
        ? []
        : [
            {
              title: "Convert to Kinvault",
              line1: `Convert ${this.lead.friendlyName} into a Kinvault`,
              icon: StyleService.icon.kintin.icon,
              permissions: "lead:write",
              action: this.convertToKintin,
            },
          ];

      const deleteOptions = ["converted"].includes(
        this.lead.status?.value as string,
      )
        ? []
        : [
            {
              title: "Delete Lead",
              line1: `Delete ${this.lead.friendlyName}`,
              icon: StyleService.icon.delete.icon,
              permissions: "lead:delete",
              action: this.deleteLead,
            },
          ];

      const secondaryProfileOptions = !this.lead.secondaryProfile
        ? [
            {
              title: "Enable Secondary Profile",
              line1: `Enable ${this.lead.friendlyName}'s secondary profile`,
              icon: StyleService.icon.profile.icon,
              action: this.enableSecondaryProfile,
            },
          ]
        : [
            {
              title: "Disable Secondary Profile",
              line1: `Disable ${this.lead.friendlyName}'s secondary profile`,
              icon: StyleService.icon.profile.icon,
              action: this.disableSecondaryProfile,
            },
          ];

      await AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Create Note",
              icon: StyleService.icon.notes.icon,
              line1: "Create a new note",
              action: this.createNote,
              premissions: ["lead:write"],
            },
            ...convertOptions,
            {
              title: "Edit Profile",
              line1: `Edit ${this.lead.friendlyName}'s profile`,
              icon: StyleService.icon.profile.icon,
              permissions: "lead:write",
              action: this.editProfile,
            },
            ...secondaryProfileOptions,
            {
              title: "Manage Referral",
              line1: `Manage ${this.lead.friendlyName}'s referral`,
              icon: StyleService.icon.referral.icon,
              permissions: "lead:write",
              action: this.manageReferral,
            },
            {
              title: "Update Settings",
              line1: `Update ${this.lead.friendlyName}'s settings`,
              icon: StyleService.icon.settings.icon,
              permissions: "lead:write",
              action: this.updateSettings,
            },
            ...deleteOptions,
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
        },
        path: "permissions",
        filter: "all",
        callback: (item) => item.action(),
      })();
    },
    async enableSecondaryProfile(): Promise<void> {
      if (!this.lead) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Enable Secondary Profile",
          message: `Are you sure you want to enable ${this.lead.friendlyName}'s secondary profile?`,
        },
      });

      await this.$actionBus.ToggleSecondaryProfile({
        lead: this.lead,
        state: "enable",
      });

      window.location.reload();
    },
    async disableSecondaryProfile(): Promise<void> {
      if (!this.lead) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Disable Secondary Profile",
          message: `Are you sure you want to delete ${this.lead.friendlyName}'s secondary profile?`,
        },
      });

      await this.$actionBus.ToggleSecondaryProfile({
        lead: this.lead,
        state: "disable",
      });

      window.location.reload();
    },
    editProfile(): void {
      window.Kernel.Router.push({
        name: LeadDetailsUpdateProfileRoute,
        params: this.$params,
      });
    },
    async createNote(): Promise<void> {
      if (!this.lead) {
        return;
      }

      const note = await UpdateNoteForm({
        data: "create-note",
        lead: this.lead,
      }).dialog({
        dialog: {
          title: "Create note",
        },
        button: {
          ok: {
            text: "Save",
          },
        },
      });

      await window.Kernel.ActionBus2.core.notes.CreateNote({
        ...note,
        lead: this.lead,
      });

      this.key++;
    },
    async convertToKintin(): Promise<void> {
      if (!this.lead) {
        return;
      }

      const emailToCheck = this.lead.primaryProfile.primaryEmailAddress?.email;

      if (!emailToCheck) {
        await OpenAlertDialog({
          dialog: {
            title: "Convert to Kintin",
            message: `This lead does not have a primary email address.`,
          },
          button: {
            ok: {
              text: "Return to Lead",
              color: "is-primary",
            },
          },
        });

        return;
      }

      await window.Kernel.ActionBus2.auth.UniqueUsername(emailToCheck);

      const data = await ConvertLeadToKintinForm(this.lead).dialog({
        dialog: {
          title: "Convert to Kintin",
        },
        button: {
          ok: {
            text: "Convert",
          },
        },
      });

      // const { kintin } =
      //   await window.Kernel.ActionBus.lead.convertToKintin.convert({
      //     lead: this.lead,
      //     ...data,
      //   });
      const { kintin } = await this.$actionBus.CreateKintinFromLead({
        lead: this.lead,
        ...data,
      });

      await OpenAlertDialog({
        dialog: {
          title: "Convert to Kintin",
          message: `Successfully converted ${this.lead.friendlyName} into a Kintin`,
        },
        button: {
          ok: {
            text: "View Kintin",
            color: "is-success",
          },
          cancel: {
            text: "Return to Lead",
            color: "is-primary",
          },
        },
      });

      window.Kernel.Router.push({
        name: KinvaultKintinDetailsRoute,
        params: { kintin: kintin.id } as KinvaultKintinDetailsParams,
      });
    },
    async updateSettings(): Promise<void> {
      if (!this.lead) {
        return;
      }

      await UpdateLeadSettingsForm(this.lead).dialog({
        dialog: {
          title: "Update Settings",
        },
        button: {
          ok: {
            text: "Save",
          },
        },
      });

      this.lead.$persist();

      // await window.Kernel.ActionBus.lead.settings.update({
      //   lead: this.lead,
      // });
      await this.$actionBus.UpdateLead(this.lead);

      this.key++;
    },
    async deleteLead(): Promise<void> {
      if (!this.lead) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Lead",
          message: `Are you sure you want to delete ${this.lead.friendlyName}?`,
        },
      });

      await this.$actionBus.DeleteLead(this.lead);

      window.Kernel.Router.push({
        name: LeadMasterListRoute,
      });
    },
    async manageReferral(): Promise<void> {
      if (!this.lead) {
        return;
      }

      const referral = this.lead.referral;

      try {
        await UpdateReferralForm({ data: referral }).dialog({
          dialog: {
            title: `Manage ${this.lead.friendlyName} referral`,
          },
        });
      } catch (e) {
        referral.$restore();
        throw e;
      }

      referral.$persist();
      // await window.Kernel.ActionBus.core.accountReferral.update({
      //   referral,
      // });
      await this.$actionBus.accountReferral.UpdateAccountReferral(referral);

      this.lead.$restore();
      this.key++;
    },
  },
});
</script>
