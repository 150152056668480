import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";

import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { yearOptions } from "../data/filter-options";

// Sets the default year to the current year
const thisYear = new Date().getFullYear();

// Sets the default start and end dates to the first and last days of the current year
const defaultStartDate = new Date(thisYear, 0, 1).getTime();
const defaultEndDate = new Date(thisYear, 11, 31).getTime();

export const ReadCompaniesLeaderboardReportFilters = () =>
  MasterListFiltersForm({
    name: "read-companies-leaderboard-report-filters",
    data: () => ({
      referrerCategory: null as string | null,
      created: [defaultStartDate, defaultEndDate] as [number, number] | null,
      year: thisYear.toString() as string,
      direction: "desc" as "asc" | "desc",
      orderBy: "referralCount" as
        | "referralCount"
        | "totalOrderValue"
        | "leadInitialConversionRate"
        | "totalAccounts"
        | "accountDropoutRate",
    }),
    props: SharedFilterProps,
  })({
    basicComponents: () => ({
      year: [
        FormSelectField({
          props: {
            label: "Year",
            options: yearOptions,
            size: "is-small",
            reference: "year",
            showClearButton: false,
          },
          models: {
            value: {
              get: (data) => data.year,
              set: (value, data, controls) => {
                data.year = value;
                controls.incrementFormRenderKey();
              },
            },
          },
        }),
        CreatedAtField({
          props: {
            vModel: "created",
            label: "Date Range",
            vIf: (data) => data.year === "custom",
          },
          simplifyData: true,
        }),
      ],
      orderBy: [
        FormSelectField({
          props: {
            label: "Order By",
            options: {
              referralCount: "Leads Created",
              leadInitialConversionRate: "Lead Conversion %",
              totalAccounts: "Accounts Created",
              accountDropoutRate: "Account Dropout %",
              totalOrderValue: "Total Order Value",
              averageOrderValue: "Average Order Value",
            },
          },
          models: {
            value: "orderBy",
          },
        }),
      ],
      direction: [
        FormSelectField({
          props: {
            label: "Direction",
            options: {
              asc: "Asc",
              desc: "Desc",
            },
          },
          models: {
            value: "direction",
          },
        }),
      ],
      referrerCategory: [
        OptionsAutoCompleteField({
          group: "referralSources",
          vModel: "referrerCategory",
          props: {
            label: "Referral Category",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
    }),
  });
