// KintinAccess, Kintin

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { User } from "@kinherit/sdk";
import { KintinAccess } from "@kinherit/sdk/model/kintin-access";

export const ReadKintinAccess = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/user/{user}/kintin-access",
  method: "get",
  rootModel: KintinAccess,
  parse: (user: User) => ({
    params: {
      user: user.$data.id,
    },
  }),
});
