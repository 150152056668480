// PhysicalStorageFile

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Kintin, PhysicalStorageSubscription } from "@kinherit/sdk";
import { PhysicalStorageFile } from "@kinherit/sdk/model/physical-storage-file";

export const CreatePhysicalStorageFile = DefineWriteAction({
  interface: "portal",
  resource:
    "/v2/portal/kinvault/{kintin}/physical-storage-subscription/{subscription}/storage-request",
  method: "post",
  parse: (message: {
    physicalStorageFile: PhysicalStorageFile;
    kintin: string | Kintin;
    subscription: string | PhysicalStorageSubscription;
  }) => ({
    body: {
      title: message.physicalStorageFile.$data.title,
      length: message.physicalStorageFile.$data.length,
      notes: message.physicalStorageFile.$data.notes,
      subscription: message.physicalStorageFile.$data.subscription,
      file: message.physicalStorageFile.$data.file,
      kintin: message.physicalStorageFile.$data.kintin,
    },
    params: {
      kintin: message.kintin,
      subscription: message.subscription,
    },
  }),
  after: ({ message }) => {
    PhysicalStorageFile.$delete(message.physicalStorageFile);
  },
});
