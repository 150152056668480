var n = Object.defineProperty;
var o = (e, a) => n(e, "name", { value: a, configurable: !0 });
import { FormComponentWrapper as t } from "../form-builder/core/component-wrapper.js";
import { defineForm as m, defineFormArea as l } from "../form-builder/define-form.js";
const f = /* @__PURE__ */ o(async (e) => await m({
  name: `${e.component.component.name ?? "field"}-dialog`,
  data: /* @__PURE__ */ o(() => ({
    value: e.value
  }), "data"),
  validate: e.validate,
  formAreas: /* @__PURE__ */ o((a) => [
    l({
      name: "default",
      data: a,
      template: {
        slot: "default"
      },
      components: /* @__PURE__ */ o(() => ({
        default: [
          t(e.component.component)({
            ...e.component,
            models: {
              value: "value"
            }
          })
        ]
      }), "components")
    })
  ], "formAreas")
}).dialog({
  dialog: {
    ...e.dialog
  },
  button: e.button
}).then(({ value: a }) => a), "OpenComponentDialog");
export {
  f as OpenComponentDialog
};
