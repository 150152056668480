import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultPrice } from "@kinherit/sdk";

export const ReadKinvaultPrice = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/kinvaults/prices",
  method: "get",
  parse: () => ({}),
  rootModel: KinvaultPrice,
});
