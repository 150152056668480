<template>
  <Card
    v-if="brandedKinvault"
    title="Activity"
    class="branded-kinvault-activity"
  >
    <p>
      @TODO: Add Log files filtered by frontend - i.e, logins, password resets,
      etc.
    </p>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsActivityWrapper",
  "route": "BrandedKinvaultDetailsActivity",
  "selector": ".branded-kinvault-activity"
}
</cypress-wrapper>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsActivityRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

export default defineComponent({
  name: BrandedKinvaultDetailsActivityRoute,
  components: {
    Card,
  },
  mixins: [BrandedKinvaultDetailsMixin],
});
</script>
