// EntityHistory

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { EntityHistory } from "@kinherit/sdk/model/entity-history";
import { User } from "@kinherit/sdk/model/user";

export const ReadUserActivity = DefineReadAction({
  interface: "portal",
  resource: "/v2/portal/admin/user/{user}/activity",
  method: "get",
  rootModel: EntityHistory,
  parse: (user: User | string) => ({
    params: {
      user,
    },
  }),
});
