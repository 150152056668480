<template>
  <div class="kinvault-coupon-master-list">
    <MasterListPage
      v-if="$data.filters"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :filters="$data.filters"
      title="Stripe Coupons"
      subtitle="As synced from Stripe"
      :columns="columns"
      :rows="rows"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :sort-by-options="{
        createdAt: 'Created At',
      }"
      @refresh="refresh"
    >
      <template #name="{ row }: { row: KinvaultCoupon }">
        {{ row.name }}<br />
        {{ row.stripeCouponId }}
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { ReadKinvaultCouponForm } from "@/module/kinvault.subscription/form/read-kinvault-coupon.form";
import { KinvaultCouponMasterListRoute } from "@/module/kinvault.subscription/page";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Like } from "@kinherit/orm/index";
import { IKinvaultCoupon, KinvaultCoupon } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: KinvaultCouponMasterListRoute,
  components: { MasterListPage },
  mixins: [
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal.kinvaults.subscription.coupon,
    ),
  ],
  data: () => ({
    filters: ReadKinvaultCouponForm(),
    columns: [
      {
        title: "name",
        slot: "name",
      },
      {
        title: "Currency",
        field: "currency",
      },
      {
        title: "Discount",
        map: (val) => {
          if (val.amountOff) {
            return `£${val.amountOff / 100}`;
          } else if (null != val.percentOff) {
            return `${val.percentOff}%`;
          } else {
            return "N/A";
          }
        },
      },
      {
        title: "Created",
        map: (val) => val.createdAt.formatYMD,
      },
    ] as Array<TableColumn<KinvaultCoupon>>,
    rows: Array<KinvaultCoupon>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IKinvaultCoupon,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    handleClick(kinvaultCoupon: KinvaultCoupon, event: MouseEvent): void {
      console.log({
        kinvaultCoupon,
        event,
      });
    },
    async refresh(
      formData: ReturnType<typeof ReadKinvaultCouponForm>["localData"],
    ): Promise<void> {
      const data = await this.$actionBus.ReadKinvaultCoupon({
        query: {
          name: Like(formData.name),
          stripeCouponId: Like(formData.stripeCouponId),
          createdAt: Between(formData.created),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.kinvaultCoupon;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>
