import { Api } from "@kinherit/sdk/api";

//
export const Reconcile = async (options: {
  minDate: string;
  maxDate: string;
}) => {
  await Api.resource("portal", "/v2/portal/settings/acuity/reconcile")
    .method("put")
    .body({
      minDate: options.minDate,
      maxDate: options.maxDate,
    })
    .result();
};
