// SignDoc

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { SignDoc } from "@kinherit/sdk/model/sign-doc";

export const CreateSignDoc = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/sign-doc",
  method: "post",
  parse: (target: SignDoc) => ({
    body: {
      person: target.$data.person,
      data: target.$data.data,
      kintin: target.$data.kintin,
      type: target.$data.type,
      ipAddress: target.$data.ipAddress,
      userAgent: target.$data.userAgent,
      signedAt: target.$data.signedAt,
      stripeSessions: target.$data.stripeSessions,
      clauses: target.$data.clauses,
      conditions: target.$data.conditions,
      products: target.$data.products,
    },
    params: {
      kintin: target.$data.kintin,
    },
  }),
  after: ({ message }) => {
    SignDoc.$delete(message);
  },
});
