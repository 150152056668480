var a = Object.defineProperty;
var i = (e, o) => a(e, "name", { value: o, configurable: !0 });
import { _ as n } from "../../../component.display/loading/loading.vue_vue_type_script_lang.js";
import { createBlock as r, openBlock as s, Transition as d, withCtx as c, withDirectives as l, createElementVNode as t, vShow as _ } from "vue";
import { _ as f } from "../../../_plugins/_plugin-vue_export-helper.js";
const p = { class: "loading loading-overlay" };
function m(e, o, u, v, g, x) {
  return s(), r(d, { name: "fade" }, {
    default: c(() => [
      l(t("div", p, o[0] || (o[0] = [
        t("div", { class: "loading-background" }, null, -1),
        t("div", { class: "loading-icon" }, [
          t("div")
        ], -1)
      ]), 512), [
        [_, !0]
      ])
    ]),
    _: 1
  });
}
i(m, "_sfc_render");
const B = /* @__PURE__ */ f(n, [["render", m]]);
export {
  B as default
};
