var _ = Object.defineProperty;
var c = (t, o) => _(t, "name", { value: o, configurable: !0 });
const a = /* @__PURE__ */ c((t, o) => {
  const r = t.__vccOpts || t;
  for (const [e, s] of o)
    r[e] = s;
  return r;
}, "_export_sfc");
export {
  a as _
};
