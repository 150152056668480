// CpdSessionRegistration

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { CpdSessionRegistration } from "@kinherit/sdk/model/cpd-session-registration";

export const UpdateCpdSessionRegistration = DefineWriteAction({
  interface: "portal",
  resource: "/v2/portal/cpd/register/{cpdSessionRegistration}",
  method: "patch",
  parse: (target: CpdSessionRegistration) => ({
    body: {
      isMatched: target.$data.isMatched,
      session: target.$data.session,
      profile: target.$data.profile,
    },
    params: {
      cpdSessionRegistration: target,
    },
  }),
});
