import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateUser } from "./create-user.action";
import { RecordUser } from "./record-user.action";

export const Portal2KinvaultUser = DefineActionBus({
  name: "portal-kinvault-user",
  actions: {
    CreateUser,
    RecordUser,
  },
});
