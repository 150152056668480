<template>
  <ChildrenContainer v-if="kintin" :kintin="kintin" @reload="$emit('reload')" />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsAddressBookChildrenWrapper",
  "route": "KinvaultKintinDetailsAddressBookChildren",
  "extends": {
    "name": "ChildrenContainerWrapper",
    "path": "@/module/kinvault.kintin/component/address-book/ChildrenContainer.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreatePerson } from "@/config/model.config";
import { UpdatePersonForm } from "@/module/kinvault.kintin/form/update-person.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { StyleService } from "@/service/style.service";

import ChildrenContainer from "@/module/kinvault.kintin/component/address-book/ChildrenContainer.vue";
import RouteStorageMixin from "@kinherit/framework/component.mixin/route-storage-mixin";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookChildrenRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookChildrenRoute,
  components: {
    ChildrenContainer,
  },
  mixins: [KinvaultKintinDetailsMixin, RouteStorageMixin(true, "query")],
  emits: ["reload"],
  data: () => ({
    filtered: {
      people: Array<Person>(),
    },
    selectedTab: 0,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  computed: {
    computedShowTabs(): boolean {
      return (
        "string" === typeof this.kintin?.$data.primaryPerson &&
        "string" === typeof this.kintin?.$data.secondaryPerson
      );
    },
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [
        this.kintin?.primaryPerson?.profile.fullName,
        this.kintin?.secondaryPerson?.profile.fullName,
      ].filter((p) => p);

      if (
        this.kintin?.$data.primaryPerson &&
        this.kintin?.$data.secondaryPerson
      ) {
        names.push("People");
      }

      return names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      this.filtered.people = Person.$findBy({
        kintin: {
          id: this.kintin?.id,
        },
      })
        .unique("id")
        .sortBy("profile.fullName")
        .filter(Boolean);

      this.filtered.people = this.filtered.people
        .filter((p) => {
          if (selectedPerson?.kintinPersonType === "primary") {
            return p.relationToPrimaryPerson?.data?.isChild === true;
          } else if (selectedPerson?.kintinPersonType === "secondary") {
            return p.relationToSecondaryPerson?.data?.isChild === true;
          }

          return true;
        })
        .cast<Person>();
    },
    async createPerson() {
      if (!this.kintin) {
        return;
      }

      const person = CreatePerson();

      try {
        await UpdatePersonForm({
          person,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create person",
          },
        });
      } catch {
        person.$delete({
          profile: true,
          relationToPrimaryPerson: true,
          relationToSecondaryPerson: true,
        });
        return;
      }

      // await window.Kernel.ActionBus.kinvaultKintin.person.create({
      person.kintin = this.kintin;
      await this.$actionBus.person.CreatePerson(person);

      this.$emit("reload");
    },
  },
});
</script>
