import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { DownloadRemittance } from "./download-remittance.action";
import { SendIntroducerRemittanceEmails } from "./send-introducer-remittance-emails.action";

export const Portal2IntroducerFeesRemittance = DefineActionBus({
  name: "portal-introducer-fees-remittance",
  actions: {
    SendIntroducerRemittanceEmails,
    DownloadRemittance,
  },
});
