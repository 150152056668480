// Guardian

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Guardian } from "@kinherit/sdk/model/guardian";

export const DeleteGuardian = DefineDeleteAction({
  interface: "portal",
  resource: "/v2/portal/kinvault/{kintin}/guardian/{guardian}",
  method: "delete",
  parse: (guardian: Guardian) => ({
    params: {
      guardian,
      kintin: guardian.$data.kintin,
    },
  }),
  after: ({ message }) => {
    Guardian.$delete(message);
  },
});
