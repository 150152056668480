var i = Object.defineProperty;
var t = (e, l) => i(e, "name", { value: l, configurable: !0 });
import r from "../../_vue/component.input/drop-down-menu/drop-down-menu.js";
import a from "../../_vue/component.input/text-field/text-field.js";
import { OptionsMixin as o } from "../../component.mixin/options-mixin.js";
import { TranslationMixin as n } from "../../component.mixin/translation.mixin.js";
import { ThemeColor as s } from "../../theme/prop/color.js";
import { defineComponent as u } from "vue";
import c from "../../_vue/component.display/pagination/pagination.js";
import { S as p } from "../../_assets/component.display/panel/panel.module.js";
const v = u({
  name: "PanelComponent",
  components: { DropDownMenu: r, Pagination: c, TextField: a },
  mixins: [
    o(),
    n({
      defaults: {
        placeholder: "Search...",
        categoryLabel: {
          all: "All",
          selected: "Selected"
        }
      }
    })
  ],
  props: {
    /**
     * Auto focus the search field
     */
    autofocus: {
      type: Boolean,
      default: !1
    },
    /**
     * The predefined color to style the component with
     */
    color: {
      type: String,
      default: "is-primary",
      validator: /* @__PURE__ */ t((e) => Object.values(s).includes(e), "validator")
    },
    /**
     * The title of the panel
     */
    title: {
      type: String,
      default: null
    },
    /**
     * Catagories to split the options into
     */
    categories: {
      type: Array,
      default: /* @__PURE__ */ t(() => [], "default")
    },
    /**
     * Select only one item
     */
    isMultiSelect: {
      type: Boolean,
      default: !1
    }
  },
  emits: ["update:value"],
  data: /* @__PURE__ */ t(() => ({
    selectedCategory: 0,
    search: "",
    currentPage: 1
  }), "data"),
  computed: {
    builtInCategories() {
      return [
        {
          label: this.$t.local.categoryLabel.all,
          filter: /* @__PURE__ */ t((e) => e, "filter")
        },
        {
          label: this.$t.local.categoryLabel.selected,
          filter: null
        }
      ];
    },
    computedCategories() {
      return [...this.builtInCategories, ...this.categories];
    },
    paginatedOptions() {
      return this.computedOptions.filter(
        (e, l) => 10 * (this.currentPage - 1) <= l && l < 10 * this.currentPage
      );
    },
    computedValue: {
      get() {
        return this.value === null ? [] : this.isMultiSelect ? this.value : [this.value];
      },
      set(e) {
        if (this.isMultiSelect) {
          this.$emit("update:value", e);
          return;
        }
        this.$emit("update:value", e.last() ?? null);
      }
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("panel", p);
  },
  methods: {
    changeCategory(e) {
      this.selectedCategory = e, this.currentPage = 1;
    }
  }
});
export {
  v as _
};
