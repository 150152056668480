// EmailCampaignLog

import { Api } from "@kinherit/sdk/api";
import { EmailCampaignLog } from "@kinherit/sdk";

export interface UpdateEmailCampaignLogMessage {
  emailCampaignLog: EmailCampaignLog;
  files: Array<File>;
}

export interface UpdateEmailCampaignLogResponse {
  emailCampaignLog: Array<EmailCampaignLog>;
}

export const UpdateEmailCampaignLog = async (
  message: UpdateEmailCampaignLogMessage,
): Promise<UpdateEmailCampaignLogResponse> => {
  const localAttachments =
    message.emailCampaignLog.attachments.pluck("id") ?? [];

  const request = Api.resource(
    "portal",
    "/v2/portal/email-campaign/log/{emailCampaignLog}",
    message,
  )
    .method("patch")
    .body({
      campaign: message.emailCampaignLog.$data.campaign,
      scheduledToSendAt: message.emailCampaignLog.$data.scheduledToSendAt,
      body: message.emailCampaignLog.$data.body,
      title: message.emailCampaignLog.$data.title,
      subject: message.emailCampaignLog.$data.subject,
      template: message.emailCampaignLog.$data.template,
      data: message.emailCampaignLog.$data.data,
      from: message.emailCampaignLog.$data.from,
      status: message.emailCampaignLog.$data.status,
    });

  const response = await request.result();

  const emailCampaignLog = EmailCampaignLog.$inflate(
    response.emailCampaignLog,
  ).first();

  if (!emailCampaignLog) {
    throw new Error("Failed to update email log");
  }

  // Unnamed attachments
  const remoteAttachments = emailCampaignLog.attachments.pluck("id");
  const deletedAttachments = remoteAttachments.remove(...localAttachments);

  await window.Kernel.ActionBus2.portal.emailCampaign.log.UploadAttachment({
    emailCampaignLog,
    files: message.files,
  });

  for (const fileLog of deletedAttachments) {
    await window.Kernel.ActionBus2.portal.emailCampaign.log.DeleteAttachment({
      emailCampaignLog,
      fileLog,
    });
  }

  message.emailCampaignLog.$persist();

  return {
    emailCampaignLog: EmailCampaignLog.$inflate(
      response.emailCampaignLog,
      undefined,
      response.$rootIds,
    ),
  };
};
