import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk";

export const UpdateDefaultBrandedKinvaultReferralCodeForm = (
  data: DefaultBrandedKinvaultReferralCode,
) =>
  defineForm({
    name: `update-default-branded-kinvault-referral-code`,
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: `update-default-branded-kinvault-referral-code`,
        data,
        template: GridLayout([["name"], ["code"]]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: `Name`,
                validators: ["required"],
              },
              models: {
                value: `name`,
              },
            }),
          ],
          code: [
            FormTextField({
              props: {
                label: `Code`,
                validators: [`required`],
              },
              models: {
                value: `code`,
              },
            }),
          ],
        }),
      }),
    ],
  });
