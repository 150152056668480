import { Api } from "@kinherit/sdk/api";
import { User } from "@kinherit/sdk";

// Profile, EmailAddress, PhoneNumber, Website, Address
export const ReplaceProfile = async (user: User) => {
  const profile = user.profile;

  await Api.resource(
    "portal",
    "/v2/portal/admin/user/{user}/profile/{profile}/replace",
    {
      user: user,
      profile: user.profile,
    },
  )
    .method("put")
    .body({
      title: profile.title?.id,
      firstName: profile.firstName,
      middleNames: profile.middleNames,
      lastName: profile.lastName,
      knownAs: profile.knownAs,
      newsletterCampaigns: profile.newsletterCampaigns,
      suffix: profile.suffix,
      organisationName: profile.organisationName,
      organisationNumber: profile.organisationNumber,
      jobTitle: profile.jobTitle,
      addresses: profile.addresses.map((address) => ({
        id: address.id,
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        country: address.country,
        state: address.state,
        postcode: address.postcode,
        primaryMailing: address.primaryMailing,
        primaryResidential: address.primaryResidential,
      })),
      emails: profile.emails.map((email) => ({
        id: email.id,
        email: email.email,
        primary: email.primary,
      })),
      phoneNumbers: profile.phoneNumbers.map((phoneNumber) => ({
        id: phoneNumber.id,
        tel: phoneNumber.tel,
        primary: phoneNumber.primary,
      })),
      websites: profile.websites.map((website) => ({
        id: website.id,
        url: website.url,
      })),
    })
    .result();

  user.profile.$persist({
    addresses: true,
    emails: true,
    phoneNumbers: true,
    websites: true,
  });
};
