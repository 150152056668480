var m = Object.defineProperty;
var u = (t) => {
  throw TypeError(t);
};
var c = (t, e) => m(t, "name", { value: e, configurable: !0 });
var p = (t, e, r) => e.has(t) || u("Cannot " + r);
var n = (t, e, r) => (p(t, e, "read from private field"), r ? r.call(t) : e.get(t)), d = (t, e, r) => e.has(t) ? u("Cannot add the same private member more than once") : e instanceof WeakSet ? e.add(t) : e.set(t, r);
var s, l;
const w = class w {
  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    d(this, s, /* @__PURE__ */ new Map());
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    d(this, l, /* @__PURE__ */ new WeakMap());
    window.addEventListener(
      "keydown",
      (e) => {
        const r = n(this, s).get(e.code);
        if (window.Kernel.Logger.log(
          "Core",
          "debug",
          `Keydown event for ${e.code}`
        ), !r)
          return;
        Array.from(r).reverse().some((i) => {
          var g;
          const o = n(this, l).get(i);
          if (o != null && o.ignoreInput) {
            const f = (g = e.target) == null ? void 0 : g.tagName;
            if (f === "INPUT" || f === "SELECT" || f === "TEXTAREA")
              return;
          }
          return i(e), o != null && o.once && r.delete(i), o != null && o.preventDefault || e.defaultPrevented ? (window.Kernel.Logger.log(
            "Core",
            "debug",
            `Calling key listener for ${e.code}`,
            i
          ), e.preventDefault(), e.stopPropagation(), e.stopImmediatePropagation(), !0) : !1;
        });
      },
      {
        passive: !1
      }
    );
  }
  addListener({
    code: e,
    listener: r,
    once: a = !1,
    ignoreInput: i = !1,
    preventDefault: o = !1
  }) {
    window.Kernel.Logger.log("Core", "info", `Adding key listener for ${e}`), n(this, s).has(e) || n(this, s).set(e, /* @__PURE__ */ new Set());
    const g = n(this, s).get(e);
    g && (g.add(r), n(this, l).set(r, {
      once: a,
      ignoreInput: i,
      preventDefault: o
    }));
  }
  removeListener({
    code: e,
    listener: r
  }) {
    window.Kernel.Logger.log(
      "Core",
      "info",
      `Removing key listener for ${e}`
    );
    const a = n(this, s).get(e);
    if (!a) {
      window.Kernel.Logger.log(
        "Core",
        "warn",
        `No key listener found for ${e}`
      );
      return;
    }
    a.delete(r);
  }
};
s = new WeakMap(), l = new WeakMap(), c(w, "KeyListener");
let h = w;
export {
  h as KeyListener
};
