import { BrandedKinvault } from "@kinherit/sdk";

export const BrandedKinvaultMasterListBreadCrumb = {
  text: "Branded Kinvaults",
  route: { name: "BrandedKinvaultMasterList" },
};

export const BrandedKinvaultDetailsBreadCrumb = (params: any) => {
  const profile = BrandedKinvault.$findOne(params.brandedKinvault)?.profile;
  return [
    BrandedKinvaultMasterListBreadCrumb,
    {
      text:
        profile?.fullName ?? profile?.organisationName ?? "Branded Kinvault",
      route: { name: "BrandedKinvaultDetails", params },
    },
  ];
};

export const AdminBrandedKinvaultDetailsEmailLogBreadCrumb = (params: any) => [
  ...BrandedKinvaultDetailsBreadCrumb(params),
  {
    text: "Email Log",
    route: {
      name: "BrandedKinvaultDetailsEmailLog",
      params,
    },
  },
];

export const AdminBrandedKinvaultDetailsMembersBreadCrumb = (params: any) => [
  ...BrandedKinvaultDetailsBreadCrumb(params),
  {
    text: "Members",
    route: {
      name: "BrandedKinvaultDetailsMembers",
      params,
    },
  },
];

export const AdminBrandedKinvaultDetailsUpdateProfileBreadCrumb = (
  params: any,
) => [
  ...BrandedKinvaultDetailsBreadCrumb(params),
  {
    text: "Profile",
    route: { name: "BrandedKinvaultDetailsUpdateProfile", params },
  },
];

export const AdminBrandedKinvaultDetailsDefaultReferralBreadCrumb = (
  params: any,
) => [
  ...BrandedKinvaultDetailsBreadCrumb(params),
  {
    text: "Profile",
    route: { name: "BrandedKinvaultDetailsDefaultReferral", params },
  },
];

export const AdminBrandedKinvaultDetailsTeamBreadCrumb = (params: any) => [
  ...BrandedKinvaultDetailsBreadCrumb(params),
  {
    text: "Team",
    route: { name: "BrandedKinvaultDetailsTeams", params },
  },
];

export const AdminBrandedKinvaultDetailsThemeBreadCrumb = (params: any) => [
  ...BrandedKinvaultDetailsBreadCrumb(params),
  {
    text: "Theme",
    route: { name: "BrandedKinvaultDetailsTheme", params },
  },
];

export const AdminBrandedKinvaultDetailsSummaryBreadCrumb = (params: any) =>
  BrandedKinvaultDetailsBreadCrumb(params);

export const AdminBrandedKinvaultDetailsSubscriptionsBreadCrumb = (
  params: any,
) => [
  ...BrandedKinvaultDetailsBreadCrumb(params),
  {
    text: "Subscriptions",
    route: {
      name: "BrandedKinvaultDetailsSubscriptions",
      params,
    },
  },
];

export const AdminBrandedKinvaultDetailsSubscriptionsReferralCodesBreadCrumb = (
  params: any,
) => [
  ...AdminBrandedKinvaultDetailsSubscriptionsBreadCrumb(params),
  {
    text: "Referral Codes",
    route: {
      name: "BrandedKinvaultDetailsSubscriptionsReferralCodes",
      params,
    },
  },
];

export const AdminBrandedKinvaultDetailsActivityBreadCrumb = (params: any) => [
  ...BrandedKinvaultDetailsBreadCrumb(params),
  {
    text: "Activity",
    route: {
      name: "BrandedKinvaultDetailsActivity",
      params,
    },
  },
];

export const AdminBrandedKinvaultDetailsInvitationBreadCrumb = (
  params: any,
) => [
  ...BrandedKinvaultDetailsBreadCrumb(params),
  {
    text: "Invitations",
    route: { name: "AdminBrandedKinvaultDetailsInvitations" },
  },
];

export const AdminBrandedKinvaultDetailsInvitationBulkUploadBreadCrumb = (
  params: any,
) => [
  ...AdminBrandedKinvaultDetailsInvitationBreadCrumb(params),
  {
    text: "Upload",
    route: {
      name: "AdminBrandedKinvaultDetailsInvitationBulkUpload",
      params,
    },
  },
];

export const AdminBrandedKinvaultDetailsInvitationsBillingBreadCrumb = (
  params: any,
) => [
  ...AdminBrandedKinvaultDetailsInvitationBreadCrumb(params),
  {
    text: "Billing",
    route: {
      name: "BrandedKinvaultDetailsInvitationsBilling",
      params,
    },
  },
];

export const AdminBrandedKinvaultDetailsCallToActionsBreadCrumb = (
  params: any,
) => [
  ...BrandedKinvaultDetailsBreadCrumb(params),
  {
    text: "Call To Actions",
    route: {
      name: "BrandedKinvaultDetailsCallToActions",
      params,
    },
  },
];
