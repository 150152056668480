import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Portal2IntroducerFeesOrder } from "./order";
import { Portal2IntroducerFeesPayment } from "./payment";
import { Portal2IntroducerFeesPaymentItem } from "./payment-item";
import { Portal2IntroducerFeesPaymentRun } from "./payment-run";
import { ReadIntroducerCompanyFees } from "./read-introducer-company-fees.action";
import { ReadKintinIntroducerFees } from "./read-kintin-introducer-fees.action";
import { Portal2IntroducerFeesRemittance } from "./remittance";

export const Portal2IntroducerFees = DefineActionBus({
  name: "portal-introducer-fees",
  actions: {
    order: Portal2IntroducerFeesOrder,
    paymentRun: Portal2IntroducerFeesPaymentRun,
    remittance: Portal2IntroducerFeesRemittance,
    payment: Portal2IntroducerFeesPayment,
    paymentItem: Portal2IntroducerFeesPaymentItem,
    ReadIntroducerCompanyFees,
    ReadKintinIntroducerFees,
  },
});
