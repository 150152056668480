var l = Object.defineProperty;
var i = (t, e) => l(t, "name", { value: e, configurable: !0 });
import u from "../../_vue/component.display/icon/icon.js";
import a from "../../_vue/component.form/field/field.js";
import { FieldMixin as p } from "../../component.mixin/field-mixin.js";
import { KernelModes as n } from "../../core/kernel-mode.js";
import { defineComponent as d, ref as m } from "vue";
const b = d({
  name: "BaseFieldComponent",
  components: { Icon: u, Field: a },
  mixins: [p()],
  props: {
    /**
     * Only display the drop down field
     */
    dropdownOnly: {
      type: Boolean,
      default: !1
    },
    /**
     * Be a textarea or input box
     *
     * @values input, textarea
     */
    inputTag: {
      type: String,
      default: "input"
    },
    /**
     * Input field type
     *
     * @values text, email, date, tel, number, url, text, range, week, month, datetime-local, color
     */
    type: {
      type: String,
      default: "text",
      validator: /* @__PURE__ */ i((t) => [
        "text",
        "email",
        "date",
        "tel",
        "number",
        "url",
        "range",
        "password",
        "search",
        "week",
        "month",
        "time",
        "datetime-local",
        "color"
      ].includes(t), "validator")
    },
    /**
     * For number/range input types
     */
    min: {
      type: [Number, String],
      default: null
    },
    /**
     * For number/range input types
     */
    max: {
      type: [Number, String],
      default: null
    },
    /**
     * For number/range input types
     */
    step: {
      type: Number,
      default: null
    },
    /**
     * Set focus on mount
     */
    setFocus: {
      type: Boolean,
      default: !1
    }
  },
  emits: [
    /**
     * Emits whether or not the input value has passed its validators
     *
     * @property {boolean}
     */
    "is-valid",
    /**
     * When the input field in blured
     */
    "blur",
    /**
     * When the value is updated
     */
    "update:value"
  ],
  setup() {
    return {
      input: m(null)
    };
  },
  data: /* @__PURE__ */ i(() => ({
    dropdownPosition: "below"
  }), "data"),
  computed: {
    computedCyRef() {
      const t = this.type;
      if (this.$attrs.id)
        return `${this.$attrs.id}-${t}`;
      if (this.inputId)
        return `${this.inputId}-${t}`;
      let e = this.$attrs.name ?? this.label ?? null;
      if (e)
        return e = e.trim().replace(/ +/g, "_").toLocaleLowerCase().replace(/[^a-zA-Z0-9_]+/g, "") + "-" + t, e;
    },
    compClassMaxLength() {
      return this.max !== null && this.max !== 1 / 0 ? `is-maxlength-${this.max.toString().length}` : null;
    },
    onFocusString() {
      var t;
      return [n.Live, n.Dev].includes((t = window.Kernel) == null ? void 0 : t.Mode) && this.state && !["readonly", "disabled"].includes(this.state) ? "this.showPicker?.()" : "";
    }
  },
  mounted() {
    this.setFocus && this.$refs.input.focus({ preventScroll: !0 });
  },
  methods: {
    computeDropdownPosition(t) {
      const e = this.input;
      if (!e || this.isComponentDialog) {
        this.dropdownPosition = "below", this.focus(t);
        return;
      }
      const o = e.getBoundingClientRect(), s = window.innerHeight, r = o.top > s / 2;
      this.dropdownPosition = r ? "above" : "below", this.focus(t);
    },
    clear() {
      this.computedValue = null, this.$emit("update:value", null);
    }
  }
});
export {
  b as _
};
