var i = Object.defineProperty;
var c = (n, o) => i(n, "name", { value: o, configurable: !0 });
import { OpenDangerDialog as r } from "../global/dialog.js";
import { OpenDangerSnackBar as s } from "../global/snack-bar.js";
import { Api as t } from "@kinherit/sdk";
const e = /* @__PURE__ */ c(async (n) => {
  let o = "An unknown error has occurred";
  return "message" in n && (o = n.message), r({
    dialog: {
      title: "Error",
      message: o,
      isAboveLoading: !0
    },
    button: {
      cancel: {
        show: !1
      }
    }
  }), !1;
}, "RejectErrorHandler");
t.catch("UnauthorizedException", e);
t.catch("PermissionDeniedException", e);
t.catch("Error", e);
t.catch("HttpException", e);
t.catch("IncorrectCredentialsException", e);
t.catch("MaxLoginAttemptsExceededException", e);
t.catch("EntityNotFoundError", e);
t.catch("NotFoundException", e);
t.catch("UnknownException", e);
const a = /* @__PURE__ */ c(async (n) => (s({
  text: n.message
}), !1), "BadRequestHandler");
t.catch("BadRequestException", a);
t.catch("ValidationFailedException", a);
t.catch("ConnectionException", async () => {
  try {
    return await r({
      dialog: {
        title: "Connection Error",
        message: "Unable to connect to the server",
        isAboveLoading: !0
      },
      button: {
        ok: {
          text: "Retry"
        }
      }
    }), !0;
  } catch {
    return !1;
  }
});
const u = {};
export {
  u as default
};
