var u = Object.defineProperty;
var p = (o, t) => u(o, "name", { value: t, configurable: !0 });
import { defineComponent as m, ref as g, onMounted as f, nextTick as w, onBeforeUnmount as y } from "vue";
const M = m({
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    apiKey: {
      type: String,
      required: !0
    },
    placeholder: {
      type: String,
      default: ""
    },
    inputClass: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue", "callback"],
  setup(o, { emit: t }) {
    const l = g(null);
    let r = !1;
    function i() {
      return new Promise((e, a) => {
        if (window.google && window.google.maps && window.google.maps.places)
          e();
        else if (!r) {
          r = !0;
          const n = document.createElement("script"), c = new URLSearchParams({
            key: o.apiKey,
            libraries: "places",
            callback: "initMap",
            loading: "async"
          });
          n.setAttribute(
            "src",
            `https://maps.googleapis.com/maps/api/js?${c.toString()}`
          ), window.initMap = () => {
            e();
          }, n.onerror = (d) => {
            a(d);
          }, document.head.appendChild(n);
        }
      });
    }
    p(i, "loadGoogleMapsScript"), f(async () => {
      try {
        await i(), await w(), s();
      } catch (e) {
        console.error("Failed to load Google Maps API", e);
      }
    }), y(() => {
      delete window.initMap;
    });
    function s() {
      if (l.value) {
        const e = new google.maps.places.Autocomplete(
          l.value,
          {
            types: ["address"]
          }
        );
        e.addListener("place_changed", () => {
          const a = e.getPlace();
          t("update:modelValue", a.formatted_address), t("callback", a);
        });
      }
    }
    return p(s, "setupAutocomplete"), { autocompleteInput: l };
  }
});
export {
  M as _
};
