import { Api } from "@kinherit/sdk/api";
import { DateTime } from "@kinherit/ts-common";

//
export const Reconcile = async (options: {
  minDate: DateTime;
  maxDate: DateTime;
}) => {
  await Api.resource("portal", "/v2/portal/appointments/reconcile")
    .method("put")
    .body({
      minDate: options.minDate.formatYMD,
      maxDate: options.maxDate.formatYMD,
    })
    .result();
};
