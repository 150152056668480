import { CreateSortReferNote } from "@/action-bus/portal/sort-refer/create-sort-refer-note.action";
import { GetSortReferCase } from "@/action-bus/portal/sort-refer/get-sort-refer-case.action";
import { UpdateSortReferCase } from "@/action-bus/portal/sort-refer/update-sort-refer-case.action";
import { UpdateSortReferMilestoneByStage } from "@/action-bus/portal/sort-refer/update-sort-refer-milestone-by-stage.action";
import { UpdateSortReferMilestone } from "@/action-bus/portal/sort-refer/update-sort-refer-milestone.action";
import { UpdateSortReferStatus } from "@/action-bus/portal/sort-refer/update-sort-refer-status.action";
import { UpdateSortReferCaseReference } from "@/shared/action/external/sort-refer/update-sort-refer-case-reference.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

export const Portal2SortRefer = DefineActionBus({
  name: "portal-sort-refer",
  actions: {
    getCase: GetSortReferCase,
    updateCase: UpdateSortReferCase,
    updateCaseReference: UpdateSortReferCaseReference,
    updateMilestoneByStage: UpdateSortReferMilestoneByStage,
    updateMilestone: UpdateSortReferMilestone,
    updateStatus: UpdateSortReferStatus,
    createNote: CreateSortReferNote,
  },
});
