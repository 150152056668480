import { defineComponent as e } from "vue";
import { S as l } from "../../_assets/component.layout/grid/grid.module.js";
const o = e({
  name: "GridComponent",
  props: {
    fullhd: {
      type: String,
      default: "one-third"
    },
    widescreen: {
      type: String,
      default: "half"
    },
    desktop: {
      type: String,
      default: "half"
    },
    tablet: {
      type: String,
      default: "full"
    },
    mobile: {
      type: String,
      default: "full"
    }
  },
  computed: {
    slots() {
      return Object.keys(this.$slots).filter((t) => !t.startsWith("_")).map((t) => t);
    }
  },
  beforeMount() {
    window.Kernel.injectStylesheet("grid", l);
  }
});
export {
  o as _
};
