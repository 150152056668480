var o = Object.defineProperty;
var f = (c, r) => o(c, "name", { value: r, configurable: !0 });
const g = /* @__PURE__ */ f((c, r) => Object.assign(
  {
    is: "div",
    attr: {
      class: {
        columns: !0,
        "is-vcentered": (r == null ? void 0 : r.isCentered) === !0
      }
    },
    children: c.map(
      (d) => Object.assign(
        {
          is: "div",
          slot: d,
          attr: { class: "column" }
        },
        (r == null ? void 0 : r.column) ?? {}
      )
    )
  },
  (r == null ? void 0 : r.columns) ?? {}
), "ColumnLayout"), s = /* @__PURE__ */ f((c, r = !1) => {
  const d = typeof r == "boolean" ? r : r.isColumns ?? !1, a = typeof r == "boolean" ? !1 : r.isCentered ?? !1, u = typeof r == "boolean" ? !1 : r.isFull ?? !1, i = c.map((t) => typeof t == "string" ? {
    is: "div",
    slot: t,
    attr: { class: "column" }
  } : Array.isArray(t) ? s(t, {
    isColumns: !d,
    isCentered: a && !d,
    isFull: u && !d
  }) : t);
  return {
    is: "div",
    attr: d || a === !0 ? {
      class: {
        columns: d,
        "is-vcentered": a === !0,
        "is-full": u === !0
      }
    } : void 0,
    children: i
  };
}, "GridLayout"), n = /* @__PURE__ */ f(({
  slots: c,
  options: { isBordered: r = !0, isFullwidth: d = !0, elements: a } = {}
}) => {
  var i;
  const u = c.map((t, l) => {
    const v = t.map((b) => {
      var y;
      return typeof b == "string" ? {
        is: "td",
        attr: {
          ...(a == null ? void 0 : a.td) ?? {},
          style: {
            ...((y = a == null ? void 0 : a.td) == null ? void 0 : y.style) ?? {},
            borderTop: l === 0 && r ? "1px solid #dbdbdb" : void 0,
            borderBottom: r ? "1px solid #dbdbdb" : void 0
          }
        },
        slot: b
      } : (b.is = "td", b);
    });
    return {
      is: "tr",
      attr: a == null ? void 0 : a.tr,
      children: v
    };
  });
  return {
    is: "table",
    attr: {
      ...(a == null ? void 0 : a.table) ?? {},
      style: {
        ...((i = a == null ? void 0 : a.table) == null ? void 0 : i.style) ?? {},
        width: d ? "100%" : void 0
      }
    },
    children: u
  };
}, "TableLayout");
export {
  g as ColumnLayout,
  s as GridLayout,
  n as TableLayout
};
