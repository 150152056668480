var r = Object.defineProperty;
var n = (o, e) => r(o, "name", { value: e, configurable: !0 });
import { ThemeIconName as i } from "../theme/prop/icon.js";
var g = /* @__PURE__ */ ((o) => (o.Text = "dialog-text", o.AutoComplete = "dialog-autocomplete", o.Form = "dialog-form", o.Alert = "dialog-alert", o.Panel = "dialog-panel", o))(g || {});
const m = /* @__PURE__ */ n((o) => new Promise((e, l) => {
  window.Kernel.Global.loadDialogComponent({
    config: {
      contentType: "dialog-text",
      ...o
    },
    resolve: /* @__PURE__ */ n((t) => e(t), "resolve"),
    reject: l
  });
}), "OpenTextDialog"), p = /* @__PURE__ */ n((o) => new Promise((e, l) => {
  window.Kernel.Global.loadDialogComponent({
    config: {
      contentType: "dialog-autocomplete",
      ...o
    },
    resolve: /* @__PURE__ */ n((t) => e(t), "resolve"),
    reject: l
  });
}), "OpenAutocompleteDialog"), s = /* @__PURE__ */ n((o) => new Promise((e, l) => {
  window.Kernel.Global.loadDialogComponent({
    config: {
      contentType: "dialog-panel",
      ...o
    },
    resolve: /* @__PURE__ */ n((t) => e(t), "resolve"),
    reject: l
  });
}), "OpenPanelDialog"), w = /* @__PURE__ */ n((o) => new Promise((e, l) => {
  window.Kernel.Global.loadDialogComponent({
    config: {
      contentType: "dialog-form",
      ...o
    },
    resolve: e,
    reject: l
  });
}), "OpenFormDialog"), u = /* @__PURE__ */ n((o) => new Promise((e, l) => {
  window.Kernel.Global.loadDialogComponent({
    config: {
      contentType: "dialog-alert",
      ...o
    },
    resolve: /* @__PURE__ */ n(() => e(), "resolve"),
    reject: l
  });
}), "OpenAlertDialog"), D = /* @__PURE__ */ n((o = {}) => new Promise((e, l) => {
  var t, a;
  window.Kernel.Global.loadDialogComponent({
    config: {
      ...o,
      contentType: "dialog-alert",
      dialog: {
        title: "Danger",
        icon: i.Cross,
        message: "Are you sure you want to continue?",
        ...o.dialog ?? {}
      },
      button: {
        ok: {
          text: "OK",
          color: "is-danger",
          ...((t = o.button) == null ? void 0 : t.ok) ?? {}
        },
        cancel: {
          text: "Cancel",
          ...((a = o.button) == null ? void 0 : a.cancel) ?? {}
        }
      }
    },
    resolve: /* @__PURE__ */ n(() => e(), "resolve"),
    reject: l
  });
}), "OpenDangerDialog");
export {
  g as DialogType,
  u as OpenAlertDialog,
  p as OpenAutocompleteDialog,
  D as OpenDangerDialog,
  w as OpenFormDialog,
  s as OpenPanelDialog,
  m as OpenTextDialog
};
