var a = Object.defineProperty;
var r = (n, o) => a(n, "name", { value: o, configurable: !0 });
const s = /* @__PURE__ */ r((n) => new Promise((o, e) => {
  window.Kernel.Global.loadSnackBarComponent({
    config: n,
    resolve: o,
    reject: e
  });
}), "OpenSnackBar"), i = /* @__PURE__ */ r((n) => new Promise((o, e) => {
  window.Kernel.Global.loadSnackBarComponent({
    config: {
      text: "An error has occurred",
      color: "is-danger",
      shade: "is-light",
      actionColor: "is-danger",
      ...n
    },
    resolve: o,
    reject: e
  });
}), "OpenDangerSnackBar"), l = /* @__PURE__ */ r((n) => new Promise((o, e) => {
  window.Kernel.Global.loadSnackBarComponent({
    config: {
      text: "Success",
      color: "is-success",
      shade: "is-light",
      actionColor: "is-success",
      ...n
    },
    resolve: o,
    reject: e
  });
}), "OpenSuccessSnackBar");
export {
  i as OpenDangerSnackBar,
  s as OpenSnackBar,
  l as OpenSuccessSnackBar
};
